<template>
    <section class="hero section">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-three-quarters">
              <h1 class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow">{{ title }}</h1>
            </div>
            <div class="column is-flex-mobile" v-if="hasSearch || hasFilters">
              <div class="field" v-if="hasSearch" :class="{ 'is-hidden-tablet': hasSearchMobileOnly }">
                <div class="control has-icons-left has-icons-right">
                  <input class="input" type="text" :placeholder="placeholder" :value="$parent.search" @change="$emit('update:search', $event.target.value)" @keyup.enter="$emit('update:search', $event.target.value)">
                  <span class="icon is-small is-left" @click="$emit('update:search', $parent.search)">
                    <LoupeIcon/>
                  </span>
                </div>
              </div>
              <a href="#" @click.prevent="$emit('update:showFilters', true)" target="_blank" class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button is-hidden-tablet" v-if="hasFilters">
                <span class="icon is-small">
                  <FilterIcon width="18" height="13" viewBox="0 1 12 5"/>
                </span>
                <span>filtruj</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import LoupeIcon from "@/assets/svg/loupe.svg";
import FilterIcon from "@/assets/svg/filter.svg";

export default {
  name: "BaseHeroSearch",
  components: {
    LoupeIcon,
    FilterIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String
    },
    hasSearch: {
      type: Boolean
    },
    hasSearchMobileOnly: {
      type: Boolean
    },
    hasFilters: {
      type: Boolean
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.hero {
  &-body {
    .field {
      width: 50%;
      margin-bottom: 0;
      @include tablet {
        width: auto;
        margin-right: 0;
      }
      @include desktop {
        margin-right: 10px;
      }
      .control {
        @include tablet {
          max-width: 278px;
          float: right;
        }
        input {
          background: $white;
          box-shadow: none;
          border-radius: 4px;
          border: solid 1px $grey-lighter;
          padding: rem(10px 10px 10px 50px);
          margin-right: rem(30px);
          @include tablet {
            background: transparent;
          }
        }
        .icon {
          margin: rem(4px 7px);
          cursor: pointer;
          pointer-events: all;
        }
      }
      & + .button {
        margin-left: 15px;
        @include tablet {
          margin-left: 10px;
        }
      }
    }
    .button {
      min-height: 45px;
      width: 100%;
      @include tablet {
        margin-left: 10px;
      }
    }
  }
}
</style>