<template>
  <article class="card" @click="$emit('goToUrlHandler')">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
            <div class="columns">
                <div class="column is-two-fifths is-paddingless">
                    <figure class="image">
                        <img v-if="image" v-bind:src="image" :alt="title" :title="title">
                    </figure>
                </div>          
                <div class="column">
                    <p class="is-size-8 is-uppercase has-text-primary-hover has-text-weight-bold has-text-left">{{ categoryName }}</p>
                    <h2 class="is-size-2 has-text-left">{{ title }}</h2>
                    <p class="is-size-7 has-text-weight-light has-text-left">{{ subtitle }}</p>
                    <RightArrowSmallIcon class="right-arrow-small-icon" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";

export default {
  name: "BaseCardLeftImage",
  components: {
    RightArrowSmallIcon
  },
  props: {
    image: {
      type: String
    },
    categoryName: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

h2 {
  margin: rem(10px 0 15px);
  color: $black;
}

.card {
  cursor: pointer;
  overflow: hidden;
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
    }
  }
  &-content {
    padding: rem(20px);
    padding-right: rem(50px);
    @include tablet {
      padding: rem(30px);
      padding-right: rem(50px);
    }
    figure {
      height: calc(100% + 40px);
      margin: -20px;
      padding-right: 40px;
      img {
        height: 100%;
      }
    }
  }
}
</style>
