<template>
    <main>
        <!--<BaseHero >-->
        <div class="live-lecture-form small">


            <div class="task-form-form column">
                <div class="card">
                    <div class="header">{{exam.Title}}</div>
                    
                    <form class="form-wrap" @submit.prevent="submitForm">
                        <div class="taskContainer" v-for="task in  exam.ExamQueries" :key="task.Id">
                            <p class=" has-text-left" v-html="task.Text"></p>
                            <div class="answerContainer">
                                <input class="radio" type="radio" :value="task.FirstAnswer" :id="task.Id+'first'" v-model="task.Response" disabled  :class="{right : task.RightAnswerNumber==1}">
                                <label :for="task.Id+'first'">{{task.FirstAnswer}}</label>
                            </div>

                            <div class="answerContainer">
                                <input class="radio" type="radio" :value="task.SecondAnswer" :id="task.Id+'second'" v-model="task.Response" disabled :class="{right : task.RightAnswerNumber==2}" >
                                <label :for="task.Id+'second'">{{task.SecondAnswer}}</label>
                            </div>

                            <div class="answerContainer">
                                <input class="radio" type="radio" :value="task.ThirdAnswer" :id="task.Id+'third'"  v-model="task.Response" disabled :class="{right : task.RightAnswerNumber==3}">
                                <label :for="task.Id+'third'">{{task.ThirdAnswer}}</label>
                            </div>

                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import global from "@/mixins/global.js";
    export default {
        name: "ExamDisplay",
        mixins: [global],
        components: {
         
        },

        data() {
            return {
                exam: {},
            };
        },
        props: {
            userId: {
                type: Number
            },
            examId: {
                type: Number
            }

        },
        methods: {

            loadExam() {
                let self = this;
                self.$http
                    .get(`spa/ImplementationPrograms/Exam/${self.examId}/user/${self.userId}`)
                    .then(response => {

                        self.exam = response.data;

                    })
                    .catch((e) => { console.log(e); });
            },
        },
        created: function () {
            this.loadExam();
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .right + label  {
    border:solid 1px green !important;
    }
    .taskContainer {
        padding: 20px 120px;
        background-color: #FFFFFF;
        margin-bottom: 15px;

        p {
            font-size: 16px;
            font-weight: bold;
        }

        > p{
            margin-bottom: 25px;
        } 

        
    }

    .answerContainer {
        margin: auto;
        overflow: auto;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;

        label {
            font-size: 14px;
            margin-left: 18px;
        }
    }

    .header {
        background-color: #3AAB59;
        color: $white;
        min-height: 56px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        padding: 1.25rem;
        margin-bottom: 15px;
    }


    .task-form-form {
        
        .card
        {
            margin: 4rem 0;
            border: 1px solid #f2f2f2;
            padding: 2.5rem 0 1.85rem;
            background-color: transparent;
            box-shadow: none;

            @include tablet {
                padding: 2.5rem 0 1.85rem;
            }

            p
            {
                color: #4a4a4a;
            }



            .error {
                color: red;
                text-align: center;
            }

            .form-wrap {
        
                input
                {
                    width: 100%;
                    display: block;
                    border: 1px solid #c8c8c8;
                    border-radius: 4px;
                    height: 35px;
                    padding: 1rem;
                    font-size: 1rem;
                    color: #686868;
                }

                .radio {
                    display: inline;
                    width: 18px;
                }

                textarea {
                    width: 100%;
                    display: block;
                    border: 1px solid #c8c8c8;
                    border-radius: 4px;
                    height: 56px;
                    margin-bottom: 1.25rem;
                    padding: 1rem;
                    font-size: 1rem;
                    color: #686868;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                }



                button[type='submit'] {
                    color: $white;
                    /*margin-top: 5rem;*/
                    width: 20%;
                    display: block;
                    border: 1px solid #c8c8c8;
                    border-radius: 4px;
                    height: 56px;
                    margin-bottom: 1.25rem;
                    padding: 1rem;
                    font-size: 1rem;
                    margin-left: auto;
                    margin-right: auto;
                }
             }

          }
        }
</style>
