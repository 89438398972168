<template>
	<div class="column is-full is-flex has-text-left">
		<div class="card-table">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<IconCalendar />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Lista zaplanowanych warsztatów
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						v-if="expandAvailable"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->
			</div>
			<table class="table">
				<thead class="is-hidden-mobile">
					<tr
						class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
					>
						<th class="has-text-grey-light">Nazwa warsztatu</th>
						<th class="has-text-grey-light">Data warsztatu</th>
						<th class="has-text-grey-light">Miasto</th>
						<th v-if="showActions" class="has-text-grey-light">Zarządzaj</th>
					</tr>
					<tr v-if="isLoading">
						<th :colspan="showActions ? 4 : 3" class="is-progress-bar-table">
							<progress class="progress is-primary" max="100"></progress>
						</th>
					</tr>
				</thead>

				<tbody v-if="workshops.length">
					<tr
						v-for="(workshop, index) in expanded
							? workshops
							: workshops.slice(0, 5)"
						:key="index"
					>
						<td class="has-text-black">
							<span>{{ workshop.Name }}</span>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Data</span
								>
								<span>{{ workshop.Date }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Miasto</span
								>
								<span>{{ workshop.Place }}</span>
							</div>
						</td>
						<td v-if="showActions" class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>certyfikat</span
								>
								<span>
									<button
										:style="{ textDecoration: 'none' }"
										class="button is-text has-text-weight-semibold has-text-left has-text-primary"
										@click="openModal(workshop.Id)"
									>
										Odwołaj udział >
									</button>
								</span>
							</div>
						</td>
					</tr>
				</tbody>

				<tbody v-else-if="!workshops.length && !isLoading">
					<tr>
						<td colspan="5" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>
		</div>

		<b-modal
			:active="isModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="$emit('close-modal')"
			></button>

			<!-- Dialog -->
			<div class="columns">
				<div class="column is-full">
					<p class="is-size-2 has-text-centered has-text-weight-light">
						Czy na pewno chcesz wypisać użytkownika z tego warsztatu?
					</p>
				</div>
			</div>

			<div class="columns">
				<!-- Buttons -->
				<div class="column is-6 has-text-right-desktop">
					<a
						class="button is-fullwidth is-small is-danger is-uppercase has-text-weight-bold has-glow-button"
						@click="cancelAttendance"
					>
						<span>TAK</span>
					</a>
				</div>
				<div class="column is-6 has-text-left-desktop">
					<a
						class="button is-fullwidth is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
						@click="isModalActive = false"
					>
						<span>NIE</span>
					</a>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';

// Vue components
import IconCalendar from '@/assets/svg/small-calendar-white.svg';

export default {
	components: {
		IconCalendar,
	},

	data() {
		return {
			userId: null,
			isLoading: true,
			expanded: false,
			isModalActive: false,
			workshopToCancel: 0,
		};
	},

	computed: {
		...mapState('screenedUser', {
			workshops: state => state.scheduledWorkshops,
		}),

		...mapState('currentUser', {
			workPosition: state => state.workPosition,
		}),

		/**
		 * @returns {Boolean} Indicates whether the table is expandable.
		 */
		expandAvailable() {
			return this.workshops ? this.workshops.length > 5 : false;
		},

		/**
		 * @description This property will tell you whether
		 * the actions column is to be shown.
		 * @returns {Boolean}
		 */
		showActions() {
			return this.workPosition === 'owner'
				? true
				: this.workPosition === 'partner'
				? true
				: this.workPosition === 'shop-manager';
		},
	},

	methods: {
		getWorkshops() {
			this.userId = this.$route.params.id;

			this.$store
				.dispatch('screenedUser/fetchScheduledWorkshops', this.userId)
				.finally(() => {
					this.isLoading = false;
				});
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.expanded = false;
		},

		openModal(workshopId) {
			this.workshopToCancel = workshopId;
			this.isModalActive = true;
		},

		/**
		 * @description This method will call the API with the infromation that
		 * the user does not want to attend the given workshop any more.
		 * @returns {void}
		 */
		cancelAttendance() {
			this.$store
				.dispatch(
					'screenedUser/cancelWorkshopAttendance',
					this.workshopToCancel
				)
				.then(() => {
					this.isModalActive = false;
					this.isLoading = true;
					this.getWorkshops();
				});
		},
	},

	beforeMount() {
		this.getWorkshops();
	},

	activated() {
		this.getWorkshops();
	},
};
</script>

<style lang="scss" scoped></style>
