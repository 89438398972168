<template>
	<section v-if="conference !== null" class="conference section">
		<div class="container small">
			<div class="column is-full">
				<h1
					class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow"
				>
					{{ conference == null ? '' : conference.Title }}
				</h1>
			</div>
		</div>
		<div class="conference-general-info">
			<div class="container small">
				<div class="conference-general-info-box">
					<div class="columns">
						<div class="column">
							<div class="single-row">
								<p class="info-type has-text-weight-semibold is-size-7">
									Miejsce
								</p>
								<p class="info-field">
									{{ conference == null ? '' : conference.Localization }}
								</p>
							</div>
							<div class="single-row">
								<p class="info-type has-text-weight-semibold is-size-7">
									Termin
								</p>
								<p class="info-field" v-text="dateFormat"></p>
							</div>
						</div>
						<div class="column conference-buttons">
							<div
								class="conf-past"
								v-if="conference != null && conference.IsCompleted"
							>
								<NoSpace />
								<span>Rejestracja na wydarzenie już się zakończyła</span>
							</div>
							<div class="conf-sign-btn">
								<a
									href="#Zapisz"
									class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
									v-if="checkConditions == 'canSign'"
								>
									<span class="icon is-small">
										<AddIcon />
									</span>
									<span>Zapisz się</span>
								</a>
								<p
									v-else-if="checkConditions == 'info'"
									class="contact-employer button light is-size-8 is-fullwidth is-uppercase has-text-weight-bold"
								>
									<span
										>W CELU ZAPISANIA SIĘ NA KONGRES SKONTATKUJ SIĘ ZE SWOIM
										PRZEŁOŻONYM</span
									>
								</p>
								<p
									v-cloak
									v-else-if="checkConditions == 'signed'"
									class="congress_signed"
								>
									<AlreadyIn />
									<span>
										Dokonałeś/-łaś już rejestracji na kongres. Sprawdź swoją
										skrzynkę e-mailową, na którą wysłaliśmy potwierdzenie
										rejestracji.
										<br />W przypadku pytań, prosimy o kontakt z infolinią: 502
										004 024.
									</span>
								</p>
								<p v-else></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="conference-intro">
			<div class="container small">
				<h2 class="is-size-2">
					{{ conference == null ? '' : conference.DescriptionTitle }}
				</h2>
				<div
					class="conference-start"
					v-if="conference != null && !conference.IsCompleted"
				>
					<p class="is-size-2 has-text-weight-bold">Do kongresu pozostało:</p>
					<counterToDate
						v-if="conference.Id > 0"
						:starttime="Date.now()"
						:endtime="conference.Date"
					/>
				</div>
				<div class="conference-bck">
					<img
						:src="conference != null && conference.ListImage"
						:alt="conference == null ? '' : conference.Title"
						:title="conference == null ? '' : conference.Title"
					/>
				</div>
				<div class="conference-intro-txt" v-html="conference.Description"></div>
			</div>
		</div>
		<div
			v-if="
				conference != null &&
				(conference.Agenda.length > 0 || conference.IsCompleted)
			"
			class="conference-schedule"
		>
			<div class="container">
				<h2
					class="is-size-2"
					v-if="conference != null && conference.IsCompleted"
				>
					Materiały z konferencji
				</h2>
				<h2 class="is-size-2" v-else-if="conference.Agenda.length > 0">
					Program konferencji
				</h2>
				<div class="conference-schedule-wrap">
					<div
						class="day-block"
						v-for="(lectureDay, index) in conference.Agenda"
						:key="index"
					>
						<h3 class="has-text-weight-light is-size-1 has-text-centered">
							{{ lectureDay.Title }}
						</h3>
						<div
							class="card single-lecture"
							v-for="(lectureBlock, index) in lectureDay.ConferenceDayPoints"
							:key="index"
						>
							<div class="lecture-header">
								<p
									class="lecture-time is-size-12 is-size-9-tablet"
									v-text="time(lectureBlock)"
								></p>
								<p class="lecture-title has-text-weight-bold">
									{{ lectureBlock.Description }}
								</p>
							</div>
							<div
								class="lecture-content"
								v-if="lectureBlock.Speaker.NameAndSurname"
							>
								<div class="lecture-img">
									<img
										:src="lectureBlock.Speaker.Image"
										:alt="lectureBlock.Speaker.NameAndSurname"
										:title="lectureBlock.Speaker.NameAndSurname"
									/>
								</div>
								<div class="lecture-txt">
									<h3 class="is-size-12">
										{{ lectureBlock.Speaker.NameAndSurname }}
									</h3>
									<p
										class="is-size-7 has-text-weight-light"
										v-html="lectureBlock.Speaker.Description"
									></p>
									<!--<button class="show-more" @click="isOpen = !isOpen" v-text="isOpen ? 'Zwiń' : 'Przeczytaj więcej'"></button>
                                                  <b-collapse :open.sync="isOpen">
                                                      <p>{{lectureBlock.Speaker.Description}}</p>
                                    </b-collapse>-->
									<div class="download-see is-size-4">
										<p v-if="lectureBlock.Presentation">
											le
											<a v-bind:href="lectureBlock.Presentation" download
												>Pobierz prezentację</a
											>
										</p>
										<p v-if="lectureBlock.Video">
											<a v-bind:href="lectureBlock.Video" target="_blank"
												>Obejrzyj video z wystąpienia</a
											>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="conference-videos"
					v-if="conference != null && conference.IsCompleted"
				>
					<h2 class="is-size-2">Videorelacja z konferencji</h2>
					<div class="conf-video-wrap">
						<div
							class="single-conf-video"
							v-for="(movie, index) in conference.Videos"
							:key="index"
						>
							<div
								class="has-play-button"
								@click="playVideoHandler"
								:class="{ 'is-playing': isVideoPlaying }"
							>
								<div>
									<img src="/assets/2018/svg/play.svg" />
									<p class="is-size-4 has-text-centered has-text-white">
										Obejrzyj wideo wprowadzające
									</p>
								</div>
								<youtube
									:video-id="movie.Url"
									:player-vars="playerVars"
									@ended="endVideoHandler"
									resize="true"
									ref="youtube"
								></youtube>
								<img
									:src="
										'http://i3.ytimg.com/vi/' + movie.Url + '/hqdefault.jpg'
									"
								/>
							</div>
							<p>{{ movie.Title }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="conf-sign"
			id="Zapisz"
			v-if="
				!conference.IsCompleted &&
				conference.IsUserAllowedToSignIn &&
				!conference.IsUserAleradySubscribed
			"
		>
			<div class="container small">
				<div
					class="conf-for-who"
					v-if="
						conference.ForWorkersOptions &&
						conference.ForWorkersOptions.length > 0
					"
				>
					<h2 class="is-size-2">
						<!--Udział w konferencji dla Franczyzobiorców, Kierowników Sklepów, Zastępców Kierowników oraz Pracowników Sklepów<br />
            jest BEZPŁATNY.<br />-->
						Rejestracja na kongres trwa do <b>{{ dateFormatFormTo }} r.</b>
					</h2>
					<h2 class="is-size-2">Do udziału w kongresie zapraszamy:</h2>
					<div class="columns">
						<div
							class="column"
							v-for="(who, index) in conference.ForWorkersOptions"
							:key="index"
						>
							<div class="card" v-if="who === 'Właściciel Sklepu'">
								<img src="/assets/2018/svg/employee-woman.svg" />
								Właścicieli sklepów
							</div>
							<div class="card" v-else-if="who === 'Kierownik Sklepu'">
								<img src="/assets/2018/svg/employee-man.svg" />
								Kierowników sklepów
							</div>
							<div class="card" v-else-if="who === 'Pracownik Sklepu'">
								<img src="/assets/2018/svg/employee-young-woman.svg" />
								Pracowników sklepów
							</div>
							<div class="card" v-else-if="who === 'Operacja'">
								<img src="/assets/2018/svg/employee-woman.svg" />
								Operacja
							</div>
						</div>
					</div>
				</div>

				<p v-if="conference.IsShortForm" class="is-size-5 has-text-danger">
					UWAGA! FORMULARZ MOŻESZ WYPEŁNIĆ TYLKO RAZ
				</p>
				<p v-else class="is-size-5 has-text-danger">
					UWAGA! FORMULARZ MOŻESZ WYPEŁNIĆ TYLKO RAZ, WIĘC ZAPISZ OD RAZU
					WSZYSTKICH SWOICH PRACOWNIKÓW
				</p>

				<div class="conf-sign-form">
					<!--<h2 class="is-size-2">
            Udział w Kongresie dla przedsiębiorców zrzeszonych w sieciach franczyzowych i partnerskich Grupy Eurocash jest bezpłatny. Rejestracja na Kongres trwa do 19.08.2019 r.
        </h2>-->
					<div class="card">
						<form class="form-wrap" @submit="submitForm">
							<p class="is-size-16 is-size-1-tablet has-text-left">
								Zapisz się:
							</p>

							<div
								v-if="
									typeof yourself.ShopType != 'undefined' &&
									yourself.ShopType != null &&
									yourself.ShopType != ''
								"
							>
								<div class="sign-you">
									<input
										:class="{ 'has-error': $v.yourself.Name.$error }"
										type="text"
										title="Imię"
										placeholder="Imię"
										value
										v-model="yourself.Name"
										@blur="$v.yourself.Name.$touch()"
									/>
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.Name
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>
									<input
										:class="{ 'has-error': $v.yourself.Surname.$error }"
										title="Nazwisko"
										type="text"
										placeholder="Nazwisko"
										value
										v-model="yourself.Surname"
										@blur="$v.yourself.Surname.$touch()"
									/>
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.Surname
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>
									<!--<select :class="{ 'has-error': $v.yourself.WorkPosition.$error }"
                                title="Stanowisko"
                                v-model="yourself.WorkPosition"
                                @blur="$v.yourself.WorkPosition.$touch()">
                            <option value disabled selected>Stanowisko</option>
                            <option>Właściciel</option>
                            <option v-if="!(yourself.ShopType===ShopType.Franchise)">Kierownik sklepu</option>
                        </select>
                        <div class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.WorkPosition)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<input v-if="yourself.ShopType===ShopType.Own" :class="{ 'has-error': $v.yourself.SapNo.$error }"
                               title=" Numer SAP"
                               type="text"
                               placeholder=" Numer SAP"
                               v-model="yourself.SapNo"
                               @blur="$v.yourself.SapNo.$touch()" />
                        <div v-if="yourself.ShopType===ShopType.Own" class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.SapNo)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<input v-if="yourself.ShopType===ShopType.Franchise" :class="{ 'has-error': $v.yourself.OwnerName.$error }"
                               title=" Imię właściciela"
                               type="text"
                               placeholder=" Imię właściciela"
                               v-model="yourself.OwnerName"
                               @blur="$v.yourself.OwnerName.$touch()" />
                        <div  v-if="yourself.ShopType===ShopType.Franchise" class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.OwnerName)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<input v-if="yourself.ShopType===ShopType.Franchise" :class="{ 'has-error': $v.yourself.OwnerSurname.$error }"
                               title=" Nazwisko właściciela"
                               type="text"
                               placeholder=" Nazwisko właściciela"
                               v-model="yourself.OwnerSurname"
                               @blur="$v.yourself.OwnerSurname.$touch()" />
                        <div v-if="yourself.ShopType===ShopType.Franchise" class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.OwnerSurname)"
                                  :key="index">{{ error }}</span>
                        </div>-->

									<input
										v-if="!conference.IsShortForm"
										numeric
										:class="{ 'has-error': $v.yourself.NIP.$error }"
										title="NIP firmy"
										min="0"
										placeholder="NIP firmy"
										value
										v-model="yourself.NIP"
										@blur="$v.yourself.NIP.$touch()"
									/>
									<div v-if="!conference.IsShortForm" class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.NIP
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>

									<input
										v-if="!conference.IsShortForm"
										:class="{ 'has-error': $v.yourself.CompanyName.$error }"
										title=" Nazwa firmy"
										type="text"
										placeholder=" Nazwa firmy"
										v-model="yourself.CompanyName"
										@blur="$v.yourself.CompanyName.$touch()"
									/>
									<div v-if="!conference.IsShortForm" class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.CompanyName
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>

									<!--<input :class="{ 'has-error': $v.yourself.CompanyStreet.$error }"
                               title=" Ulica i numer"
                               type="text"
                               placeholder=" Ulica i numer"
                               v-model="yourself.CompanyStreet"
                               @blur="$v.yourself.CompanyStreet.$touch()" />
                        <div class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.CompanyStreet)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<input :class="{ 'has-error': $v.yourself.CompanyZIP.$error }"
                               title=" Kod pocztowy"
                               type="text"
                               placeholder=" Kod pocztowy"
                               v-model="yourself.CompanyZIP"
                               @blur="$v.yourself.CompanyZIP.$touch()" />
                        <div class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.CompanyZIP)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<input :class="{ 'has-error': $v.yourself.City.$error }"
                               title=" Miejscowość"
                               type="text"
                               placeholder=" Miejscowość"
                               v-model="yourself.City"
                               @blur="$v.yourself.City.$touch()" />
                        <div class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.City)"
                                  :key="index">{{ error }}</span>
                        </div>-->
									<!--<select v-if="yourself.ShopType===ShopType.Franchise" :class="{ 'has-error': $v.yourself.Region.$error }"
                                title="Region"
                                v-model="yourself.Region"
                                @blur="$v.yourself.Region.$touch()">
                            <option value disabled selected>Region</option>
                            <option>Błonie</option>
                            <option>Gdańsk</option>
                            <option>Kraków</option>
                            <option>Krosno</option>
                            <option>Sosnowiec</option>
                        </select>
                        <div v-if="yourself.ShopType===ShopType.Franchise" class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.Region)"
                                  :key="index">{{ error }}</span>
                        </div>-->

									<input
										numeric
										:class="{ 'has-error': $v.yourself.PhoneNumber.$error }"
										title="Numer telefonu"
										placeholder="Numer telefonu"
										value
										v-model="yourself.PhoneNumber"
										@blur="$v.yourself.PhoneNumber.$touch()"
									/>
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.PhoneNumber
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>
									<input
										type="email"
										:class="{ 'has-error': $v.yourself.Email.$error }"
										title="Adres email"
										placeholder="Adres email"
										value
										v-model="yourself.Email"
										@blur="$v.yourself.Email.$touch()"
									/>
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.Email
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>

									<textarea
										:class="{ 'has-error': $v.yourself.Comments.$error }"
										title="Uwagi"
										placeholder="Uwagi"
										value
										v-model="yourself.Comments"
										@blur="$v.yourself.Comments.$touch()"
									/>
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
											v-for="(error, index) in validationMessages(
												$v.yourself.Comments
											)"
											:key="index"
											>{{ error }}</span
										>
									</div>
									<!--<select :class="{ 'has-error': $v.yourself.TransportPrefered.$error }"
                                title="Preferowany środek transportu"
                                v-model="yourself.TransportPrefered"
                                @blur="$v.yourself.TransportPrefered.$touch()">
                            <option value disabled selected>Preferowany środek transportu</option>
                            <option>Samochód osobowy</option>
                            <option>Transport publiczny PKS/PKP</option>
                            <option>Autokar</option>
                        </select>
                        <div class="input-msg">
                            <span class="is-size-8 has-text-weight-light has-text-danger"
                                  v-for="(error, index) in validationMessages($v.yourself.TransportPrefered)"
                                  :key="index">{{ error }}</span>
                        </div>-->
								</div>
								<div v-if="!conference.IsShortForm" class="sing-worker">
									<p class="is-size-16 is-size-1-tablet has-text-left">
										Zapisz pracowników
									</p>
									<!--<p v-if="yourself.ShopType===ShopType.Franchise" class="is-size-16 is-size-1-tablet has-text-left">Zapisz kierwoników</p>-->
									<div class="input-msg">
										<span
											class="is-size-8 has-text-weight-light has-text-danger"
										>
											Ważne! Jedna zgłoszona osoba = unikatowy adres email i
											unikatowy numer telefonu
											<br />Prosimy nie wpisywać tych samych adresów email i
											numerów telefonów do różnych zgłoszeń, ponieważ na podane
											dane wysyłane będą unikatowe hasła dostępu dla każdej
											osoby. <br />Podanie unikatowych danych kontaktowych dla
											pojedynczego uczestnika (imię, nazwisko, adres e-mail,
											telefon) jest warunkiem koniecznym do prawidłowego
											zarejestrowania się na konferencję. Te dane będą potrzebne
											również do udziału w konkursach. <br />Adres email jest
											automatycznie loginem potrzebnym do udziału w konferencji
											online. Zapamiętaj go.
										</span>
									</div>
									<div
										v-for="(worker, index) in signworkers"
										class="worker-row"
										:key="index"
									>
										<input
											type="text"
											:name="index"
											title="Imię"
											placeholder="Imię"
											value
											v-model="signworkers[index].Name"
										/>
										<input
											type="text"
											:name="index"
											title="Nazwisko"
											placeholder="Nazwisko"
											value
											v-model="signworkers[index].Surname"
											required
										/>

										<input
											v-if="yourself.ShopType === ShopType.Franchise"
											type="email"
											:name="index"
											title="Email"
											placeholder="Adres email"
											value
											v-model="signworkers[index].Email"
											required
										/>

                                        <select :name="index"
                                                v-model="signworkers[index].WorkPosition"
                                                required
                                                title="Stanowisko">
                                            <option value disabled selected>Stanowisko</option>
                                            <option>Właściciel</option>
                                            <option>Kierownik</option>
                                            <option>Zastępca kierownika</option>
                                            <option>Pracownik sklepu</option>
                                            <option>Inne</option>
                                        </select>

                                        <input numeric
                                               min="00000000"
                                               title="Numer telefonu"
                                               placeholder="Numer telefonu"
                                               value
                                               v-model="signworkers[index].PhoneNumber"
                                               required />
                                               
                                    </div>
                                    <div class="worker-btns">
                                        <button type="button"
                                                class="button add-worker light is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
                                                v-on:click="signNewWorker">
                                            <AddFull />
                                            <span>Dodaj pracownika</span>
                                        </button>
                                        <button type="button"
                                                class="button remove-worker light is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
                                                v-if="signworkers.length"
                                                v-on:click="removeNewWorkers">
                                            <RemoveIcon />
                                            <span>Usuń pracownika</span>
                                        </button>
                                    </div>
                                    <p class="signworkers-clause is-size-7" v-if="signworkers && signworkers.length">
                                        Osoba zgłaszająca pracowników na kongres zobowiązuje się do wykonania względem pracowników zgłaszanych do udziału w kongresie, obowiązku informacyjnego zgodnie z art. 14 Rozporządzenia nr 679/2016 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/4/WE, w imieniu współorganizatorów.

                                    </p>
                                </div>
                                <div class="guest-regulation">
                                    <label>
                                        <input type="checkbox"
                                               v-model="yourself.IsRegulationsAgreement"
                                               @change="regulClick" />
                                        <span class="is-size-7">
                                            Akceptuję Regulamin V Kongresu Przedsiębiorców Polskiego Handlu (zapoznaj się
                                            <a href="/assets/pdf/RegulaminKongresAUE.pdf"
                                               target="_blank">TUTAJ>></a>)
                                        </span>
                                    </label>
                                    <br />
                                    <div class="input-msg" v-if="!regulationsVal">
                                        <br />
                                        <span class="is-size-8 has-text-weight-light has-text-danger">Wypełnij wymagane pole.</span>
                                    </div>
                                </div>
                                <br />
                                <div class="guest-regulation regulation-second">
                                    <label>
                                        <input type="checkbox"
                                               v-model="yourself.IsProcessingDataAgreement"
                                               @change="regulClick" />
                                        <span class="is-size-7">
                                            Wyrażam zgodę na przekazywanie przez Akademię Umiejętności Sp. z o.o. z siedzibą w Komornikach (62-052) przy ul. Wiśniowej 11 za pośrednictwem poczty elektronicznej (adres email podany w formularzu powyżej) jednorazowej informacji podsumowującej kongres.
                                            <br />Niezaznaczenie zgody oznacza, że nie otrzymasz materiału podsumowującego kongres, w którym znajdzie się wiele przydanych biznesowo informacji. Informujemy jednocześnie, że w każdej chwili możesz cofnąć udzieloną zgodę na przykład poprzez przesłanie mail na adres: iod_ec@eurocash.pl. Wycofanie zgody pozostaje bez wpływu na zgodność z prawem wysyłanych dotychczas informacji marketingowych.
                                        </span>
                                    </label>
                                </div>
                                <br />

								<p class="is-size-7">
									Współadministratorami Pani/Pana danych osobowych są Akademia
									Umiejętności sp. z o.o. z siedzibą w Komornikach oraz inne
									spółki z Grupy Eurocash, których lista znajduje się w
									Regulaminie (dalej „Współadministratorzy”)
									<br />Pani/Pana dane osobowe będą przetwarzane w celach
									związanym z prawidłową organizacją i przeprowadzeniem
									Konferencji. <br />Dodatkowe informacje o zasadach
									przetwarzania i ochrony Pani/Pana danych osobowych przez
									Współadministratorów oraz o przysługujących Pani/Panu prawach
									dostępne są w Regulaminie Konferencji pod adresem dostępnym
									<a href="/assets/pdf/RegulaminKongresAUE.pdf" target="_blank"
										>TUTAJ>></a
									>
								</p>
								<br />
								<p class="is-size-5 colour-red">
									Adres email podany w zgłoszeniu jest jednocześnie Państwa
									loginem podczas V&nbsp;Kongresu Przedsiębiorców Polskiego
									Handlu. Prosimy sprawdzić poprawność, błędnie podany adres
									mailowy uniemożliwi udział w kongresie.
								</p>
								<button
									:disabled="isSending"
									type="submit"
									class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
								>
									Wyślij zgłoszenie
								</button>
								<p
									class="is-size-8 has-text-weight-light has-text-danger"
									v-if="submitStatus === 'ERROR'"
								>
									Nie wszystkie wymagane pola zostały wypełnione poprawnie.
								</p>
								<div v-if="isSending" class="loading">Wysyłanie...</div>

								<div
									class="success_error column is-full"
									v-if="errorMsg || successMsg"
								>
									<p
										class="is-size-7 has-text-weight-bold has-text-weight-light has-text-danger has-text-centered"
										v-if="errorMsg"
									>
										{{ errorMsg }}
									</p>
									<p
										class="is-size-7 has-text-weight-bold has-text-weight-light has-text-primary has-text-centered"
										v-if="successMsg"
									>
										{{ successMsg }}
									</p>
								</div>
							</div>
						</form>
					</div>
					<div
						class="conf-reg-info has-text-weight-light"
						v-html="conference.RegistrationInformation"
					></div>
				</div>
			</div>
		</div>
		<div class="conf-gallery-materials" v-if="conference.IsCompleted">
			<div class="container">
				<div class="conf-gallery" v-if="images && images.length > 0">
					<h2 class="is-size-2">Galeria zdjęć</h2>
					<div class="gallery-box">
						<div
							class="gallery-half"
							v-for="(imgRow, index) in Math.ceil(images.length / 3)"
							:key="index"
						>
							<div
								class="img-box"
								v-for="(image, index) in images.slice(
									(imgRow - 1) * 3,
									imgRow * 3
								)"
								:key="index"
							>
								<div
									class="conf-gal-img"
									:src="image.src"
									v-bind:style="{ backgroundImage: 'url(' + image.src + ')' }"
									@click="openGallery(image)"
								></div>
							</div>
						</div>
						<!-- <LightBox :images="images" ref="lightbox" :showThumbs="false" :showCaption="false" :show-light-box="false"></LightBox> -->
					</div>
				</div>
				<div class="conf-materials">
					<h2 class="is-size-2">Materiały do pobrania</h2>
					<ul>
						<li
							v-for="(material, index) in conference.DownloadPdfs"
							:key="index"
						>
							<a class="has-text-weight-light" :href="material.Url" download>
								<Material />
								{{ material.Title }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NoSpace from '@/assets/svg/occupied.svg';
import Material from '@/assets/svg/single-folded.svg';
import BaseCardConference from '@/components/BaseCardConference.vue';
import CounterToDate from '@/components/CounterToDate.vue';
import AddIcon from '@/assets/svg/circle-add.svg';
import AddFull from '@/assets/svg/add-full.svg';
import RemoveIcon from '@/assets/svg/circle-remove.svg';
import AlreadyIn from '@/assets/svg/already-in.svg';
import global from '@/mixins/global.js';
import VueLazyLoad from 'vue-lazyload';
import {
	required,
	email,
	numeric,
	minLength,
	maxLength,
} from 'vuelidate/lib/validators';

import { enumMixin } from '@/constance.js';

// import LightBox from "vue-image-lightbox";
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

export default {
	name: 'Conference',
	mixins: [global, enumMixin],
	components: {
		NoSpace,
		Material,
		BaseCardConference,
		CounterToDate,
		AddIcon,
		AddFull,
		RemoveIcon,
		AlreadyIn,
		VueLazyLoad,
		// LightBox
	},
	activated: function () {
		let self = this;
		self.resetData();
		self.$http
			.get(`/spa/conference/getdetails/` + self.$route.params.id)
			.then((response) => {
				self.conference = response.data;
				self.images = [];
				for (let i = 0; i < self.conference.PhotoGallery.length; i++) {
					let photo = {};
					photo.caption = '';
					photo.src = self.conference.PhotoGallery[i];
					photo.thumb = '';
					self.images.push(photo);
				}
				self.$set(this.$store.state, 'breadcrumb', self.conference.Title);
			})
			.catch(() => {});
	},
	data() {
		return {
			isOpen: false,
			isSending: false,
			conference: null,
			submitStatus: null,
			regulationsVal: true,
			playerVars: {
				autoplay: 0,
				showinfo: 0,
				rel: 0,
			},
			images: [],
			isVideoPlaying: false,
			yourself: {
				Name: '',
				Surname: '',
				OwnerName: '',
				OwnerSurname: '',
				WorkPosition: '',
				NIP: '',
				CompanyName: '',
				CompanyStreet: '',
				CompanyZIP: '',
				City: '',
				Region: '',
				PhoneNumber: '',
				Email: '',
				TransportPrefered: '',
				IsRegulationsAgreement: false,
				IsProcessingDataAgreement: false,
				ConferenceId: this.$route.params.id,
				Comments: '',
				ShopType: null,
				ShopCode: '',
			},
			signworkers: [],
			allWorkers: [],
			errorMsg: '',
			successMsg: '',
		};
	},
	validations() {
		return {
			yourself: this.validationYourselfRules,
		};
	},
	methods: {
		findImageIdx(img) {
			let idx = -1;
			$.each(this.images, function (index, data) {
				if (img.src === data.src) {
					idx = index;
					return false;
				}
			});
			return idx;
		},
		regulClick() {
			if (this.yourself.IsRegulationsAgreement) {
				this.regulationsVal = true;
			} else {
				this.regulationsVal = false;
			}
		},
		playVideoHandler() {
			this.isVideoPlaying = true;
			this.player.playVideo();
		},
		endVideoHandler() {
			this.isVideoPlaying = false;
		},
		resetData() {
			let self = this;
			self.conference = null;
		},
		time(cdp) {
			let dateArray = cdp.Hour.split(' ');
			let timeArray = dateArray[1].split(':');
			let str = '';
			return str.concat(timeArray[0], ':', timeArray[1]);
		},
		openGallery(img) {
			let idx = this.findImageIdx(img);
			this.$refs.lightbox.showImage(idx);
		},
		clearForm() {
			for (let field in this.yourself) {
				this.yourself[field] = '';
			}
		},
		signNewWorker: function () {
			let worker = {
				Name: '',
				Surname: '',
				WorkPosition: '',
				NIP: '',
				PhoneNumber: '',
				Email: '',
				TransportPrefered: '',
				ConferenceId: this.$route.params.id,
				ShopCode: '',
			};
			this.signworkers.push(worker);
		},
		removeNewWorkers: function () {
			if (this.signworkers.length > 0) {
				this.signworkers.pop();
			}
		},
		submitForm(evt) {
			evt.preventDefault();
			this.submitStatus = '';
			this.$v.$touch();
			if (!this.yourself.IsRegulationsAgreement) {
				this.regulationsVal = false;
				this.submitStatus = 'ERROR';
			}
			if (this.$v.$invalid || this.submitStatus === 'ERROR') {
				this.submitStatus = 'ERROR';
			} else {
				this.submitStatus = '';
				this.isSending = true;
				this.yourself.isSubscriber = true;
				this.allWorkers.push(this.yourself);
				this.allWorkers = [...this.allWorkers, ...this.signworkers];

				// Send form to server
				this.$http
					.post('/spa/ConferenceSubscription/Add', this.allWorkers)

					.then((response) => {
						if (response.data.IsError) {
							this.errorMsg = response.data.Message;
							this.isSending = false;
						} else {
							this.isSending = false;
							this.successMsg = response.data.Message;
						}

						setTimeout(() => {
							if (this.successMsg !== null && this.successMsg !== '') {
								this.clearForm();
								this.$v.$reset();
								this.regulationsVal = true;
								this.conference.IsUserAleradySubscribed = true;
								this.signworkers = [];
								this.successMsg = '';
								//$('html, body').animate({ scrollTop: 0 }, 'normal');
								$('html,body').scrollTop(0);
							}

							this.errorMsg = '';
						}, 5000);
					})

					.catch(() => {
						this.errorMsg = 'Wystąpił błąd';
						setTimeout(() => {
							this.successMsg = '';
							this.errorMsg = '';
						}, 5000);
					});
			}
		},
	},
	computed: {
		isFranchize() {
			let result = false;
			if (
				typeof this.yourself.ShopType !== 'undefined' &&
				this.yourself.ShopType == this.ShopType.Franchise
			) {
				result = true;
			}
			return result;
		},

		validationYourselfRules() {
			//  console.log(this.ShopType.Own);
			var regulations = {
				ShopType: {
					required,
					minLength: minLength(1),
				},

				Name: {
					required,
					minLength: minLength(2),
				},
				Surname: {
					required,
					minLength: minLength(2),
				},

				//WorkPosition: {
				//    required,
				//},
				PhoneNumber: {
					required,
					numeric,
					minLength: minLength(9),
					maxLength: maxLength(9),
				},
				Email: {
					required,
					email,
				},

				//    ShopCode: {
				//        required,
				//            minLength: minLength(2),
				//},

				//CompanyStreet: {
				//    required,
				//    minLength: minLength(2),
				//},

				//CompanyZIP: {
				//    required,
				//    minLength: minLength(2),
				//},
				//City: {
				//    required,
				//    minLength: minLength(2),
				//},

				//    TransportPrefered: {
				//        required,
				//},
				Comments: {},
			};

			//if (typeof (this.yourself.ShopType) !== 'undefined' && this.yourself.ShopType == this.ShopType.Own) {
			//    regulations["OwnerName"] = {};
			//    regulations["OwnerSurname"] = {};
			//    regulations["NIP"] = {};
			//    regulations["SapNo"] = {
			//        required,
			//        minLength: minLength(3),
			//        //maxLength: maxLength(10),
			//        //numeric,
			//    };
			//    regulations["CompanyName"] = {};
			//    regulations["Region"] = {

			//    };
			//}
			if (
				typeof this.yourself.ShopType !== 'undefined' &&
				this.yourself.ShopType == this.ShopType.Franchise
			) {
				//regulations["OwnerName"] = {
				//    required,
				//    minLength: minLength(2),
				//};
				//regulations["OwnerSurname"] = {
				//    required,
				//    minLength: minLength(2),
				//};
				regulations['OwnerName'] = {};
				regulations['OwnerSurname'] = {};

				if (!this.conference.IsShortForm) {
					regulations['NIP'] = {
						minLength: minLength(10),
						required,
						maxLength: maxLength(10),
						numeric,
					};
					regulations['CompanyName'] = {
						required,
						minLength: minLength(3),
					};
				}

				regulations['SapNo'] = {};

				//regulations["Region"] = {
				//    required,
				//};
			}

			return regulations;
		},

		player() {
			return this.$refs.youtube.player;
		},
		checkConditions() {
			if (this.conference) {
				if (
					!this.conference.IsCompleted &&
					!this.conference.IsUserAleradySubscribed &&
					this.conference.IsUserAllowedToSignIn
				) {
					return 'canSign';
				} else if (
					!this.conference.IsCompleted &&
					!this.conference.IsUserAllowedToSignIn &&
					!this.conference.IsOperation
				) {
					return 'info';
				} else if (
					!this.conference.IsCompleted &&
					this.conference.IsUserAleradySubscribed &&
					this.conference.IsUserAllowedToSignIn
				) {
					return 'signed';
				} else {
					return '';
				}
			}
		},
		dateFormat() {
			if (this.conference)
				if (this.conference.Date) {
					let t = this.conference.Date.indexOf('T');
					let date = this.conference.Date.slice(0, t);
					let dateArr = date.split('-');
					return dateArr[2] + '.' + dateArr[1] + '.' + dateArr[0];
				}
		},
		dateFormatFormTo() {
			if (this.conference)
				if (this.conference.FormVisibilityTo) {
					let t = this.conference.FormVisibilityTo.indexOf('T');
					let date = this.conference.FormVisibilityTo.slice(0, t);
					let dateArr = date.split('-');
					return dateArr[2] + '.' + dateArr[1] + '.' + dateArr[0];
				}
		},
	},
	created() {
		this.yourself.ShopType = this.ShopType.Franchise;
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.conference {
	padding-bottom: 0;
	.container.small {
		@include tablet {
			width: 729px;
		}
	}

	h2 {
		color: $black-real;
		text-align: center;
	}

	.colour-red {
		color: darkred !important;
	}

	.conference-general-info-box {
		background-color: $white;
		padding: 1.875rem;
		border-radius: 4px;
		margin-bottom: 3.125rem;
		.single-row {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin: 0 -10px 10px;
			line-height: 36px;
			position: relative;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			&:last-child {
				margin-bottom: 0;
			}

			&::after {
				content: '';
				position: absolute;
				left: 10px;
				top: 27px;
				border-bottom: 2px dotted #dfe0e0;
				width: 97%;

				@include tablet {
					width: 94%;
				}
			}

			.info-type {
				padding: 0 10px;
			}

			.info-field {
				text-align: right;
				padding: 0 10px;
				color: $green;
				font-weight: 300;
				font-size: 1.125rem;
			}
		}

		.conf-past {
			text-align: center;

			@include tablet {
				text-align: right;
			}

			svg {
				vertical-align: middle;
				margin-right: 15px;
			}

			span {
				display: inline-block;
				vertical-align: middle;
			}
		}

		.conf-sign-btn {
			a {
				height: 50px;
				border: 2px solid #3aab59;
			}
		}
	}

	.conference-intro {
		background-color: $white;
		padding: 2.5rem 1.25rem 2rem;
		margin: 0 -1.25rem;

		@include tablet {
			padding: 3rem 1.25rem 2rem;
		}

		.conference-bck {
			margin: 1.5rem 0;
			img {
				width: 100%;
				height: auto;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}

		.conference-intro-txt {
			color: $black-real;
			font-weight: 300;
			line-height: 1.875rem;
		}

		.conference-start {
			text-align: center;
			margin-top: 1.5rem;
			> p {
				color: $black-real;
				display: inline-block;
				vertical-align: middle;
				margin-right: 1rem;
			}

			.conference-counter {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.conference-schedule {
		margin-top: 2.25rem;
		.conference-schedule-wrap {
			margin-top: 5rem;
			.day-block {
				margin-bottom: 2 rem;
				> h3 {
					color: $black-real;
					margin-bottom: 2rem;
				}
			}

			.single-lecture {
				overflow: hidden;
				margin-bottom: 2.25rem;
				.lecture-header {
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					border-bottom: 1px solid #f2f2f2;

					@include tablet {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
					}

					.lecture-time {
						background-color: $green-lighter;
						color: $white;
						text-align: center;
						padding: 0.5rem;

						@include tablet {
							width: 146px;
							padding: 1rem 0;
						}
					}

					.lecture-title {
						color: $black-real;
						-webkit-box-flex: 1;
						-ms-flex-positive: 1;
						flex-grow: 1;
						padding: 0.5rem 1.8rem;
						text-align: center;

						@include tablet {
							text-align: left;
							padding: 0 2.5rem;
						}
					}
				}

				.lecture-content {
					color: $black-real;
					padding: 0 1.25rem 40px;
					margin-top: 1.25rem;

					@include tablet {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
					}

					@include widescreen {
						padding: 0 1.875rem 40px 146px;
					}

					.lecture-img {
						margin: 0 auto 2rem;
						width: 270px;
						height: 200px;
						max-width: 100%;

						@include tablet {
							margin-right: 40px;
							min-width: 270px;
						}

						img {
							border-radius: 0;
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}

					.lecture-txt {
						text-align: center;

						@include tablet {
							text-align: left;
							-webkit-box-flex: 1;
							-ms-flex-positive: 1;
							flex-grow: 1;
						}
					}

					.download-see {
						margin-top: 1.125rem;
						a {
							text-decoration: underline;
							color: $green-lighter;
							letter-spacing: 0.5px;
							margin-bottom: 0.4rem;
							display: inline-block;
						}
					}
				}
			}
		}

		.conf-video-wrap {
			margin-top: 50px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			.single-conf-video {
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0;
				margin-bottom: 40px;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;

				@include tablet {
					-ms-flex: 0 0 50%;
					flex: 0 0 50%;
					max-width: 50%;
					padding: 0 2rem;
				}

				@include widescreen {
					padding: 0 3.25rem;
				}

				> p {
					background-color: $white;
					padding: 1rem;
				}
			}

			.has-play-button {
				font-size: 0;
				margin-bottom: 2rem;
				> div {
					bottom: auto;
					height: auto;
					top: 50%;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					img {
						max-width: 100px;
					}
				}
			}
		}
	}

	.conf-sign {
		background-color: $white;
		margin: 0 -1.25rem;
		padding: 5rem 1.25rem 2rem;
		.conf-for-who {
			margin-bottom: 3.125rem;
			> h2 {
				margin-bottom: 2rem;
			}

			.card {
				font-size: 1.125rem;
				font-weight: 400;
				text-align: center;
				color: $black;
				padding-top: 1.875rem;
				padding-bottom: 1.5rem;
				padding: 1.875rem 1rem 1.5rem;
				border: 1px solid #f2f2f2;
				img {
					display: block;
					margin: 0 auto 0.75rem;
					border-radius: 0;
				}
			}
		}

		.conf-sign-form {
			.card {
				margin: 4rem 0;
				border: 1px solid #f2f2f2;
				padding: 2.5rem 1rem 1.85rem;

				@include tablet {
					padding: 2.5rem 2.5rem 1.85rem;
				}

				p {
					color: $black-real;
				}

				.form-wrap {
					input {
						width: 100%;
						display: block;
						border: 1px solid #c8c8c8;
						border-radius: 4px;
						height: 56px;
						margin-bottom: 1.25rem;
						padding: 1rem;
						font-size: 1rem;
						color: #686868;
					}

					textarea {
						width: 100%;
						display: block;
						border: 1px solid #c8c8c8;
						border-radius: 4px;
						height: 120px;
						margin-bottom: 1.25rem;
						padding: 1rem;
						font-size: 1rem;
						color: #686868;
						resize: vertical;
					}

					select {
						width: 100%;
						display: block;
						border: 1px solid #c8c8c8;
						border-radius: 4px;
						height: 56px;
						margin-bottom: 1.25rem;
						font-size: 1rem;
						padding: 1rem;
						color: #686868;
					}

					button[type='submit'] {
						color: $white;
						margin-top: 2rem;
						width: 100%;
						display: block;
						border: 1px solid #c8c8c8;
						border-radius: 4px;
						height: 56px;
						margin-bottom: 1.25rem;
						padding: 1rem;
						font-size: 1rem;
					}

					.text-center {
						text-align: center;
					}

					.radio-column {
						display: inline-block;
						width: 50%;
					}

					.radio-input {
						display: inline-block;
						width: 30px;
					}

					.sign-you {
						p {
							margin-bottom: 2rem;
						}
					}

					.sing-worker {
						margin-top: 2rem;
						> p {
							margin-bottom: 2rem;
						}

						.worker-row {
							margin: 2rem 0;
						}

						.worker-btns {
							@include tablet {
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								-webkit-box-align: center;
								-ms-flex-align: center;
								align-items: center;
								-webkit-box-pack: justify;
								-ms-flex-pack: justify;
								justify-content: space-between;
							}

							button {
								display: inline-block;
								width: 100%;
								height: 50px;
								padding: 0 1rem;
								line-height: 44px;
								margin-top: 1rem;
								max-width: 100%;
								transition: 0.5s all ease-in-out;
								cursor: pointer;

								@include tablet {
									width: 290px;
									margin-top: 0.5rem;
								}

								&:hover {
									opacity: 0.7;
								}

								&.add-worker {
									color: $green-lighter;
									border: 2px solid $green-lighter;
								}

								&.remove-worker {
									color: $white;
									border: 2px solid $red;
									background-color: $red;
								}

								svg {
									vertical-align: middle;
									margin-right: 0.6rem;
								}

								span {
									vertical-align: middle;
									display: inline-block;
								}
							}
						}
					}

					.signworkers-clause {
						margin-top: 30px;
					}

					.guest-regulation {
						margin-top: 30px;
						label {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							input {
								height: 14px;
								width: auto;
								margin-right: 15px;
								margin-top: 5px;
								margin-bottom: 0;
							}

							span {
								display: inline-block;
								vertical-align: middle;
							}
						}
					}

					.regulation-second {
						margin-top: 0px;
					}
				}
			}
		}

		.conf-reg-info {
			text-align: center;
			margin: 4rem 0 2rem;
			color: $black-real;
			strong {
				color: $black-real;
			}
		}
	}

	.conf-gallery-materials {
		background-color: $white;
		padding: 2rem 1.25rem;
		margin: 0 -1.25rem;
		.gallery-box {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin-top: 3rem;

			@include tablet {
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}

			.gallery-half {
				width: 100%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				margin: 0 -10px;

				@include tablet {
					width: 50%;
					margin: 0 -25px;
				}

				.img-box {
					-ms-flex: 0 0 33.3333%;
					flex: 0 0 33.3333%;
					max-width: 33.3333%;
					padding: 0 10px;
					margin-bottom: 20px;

					@include tablet {
						-ms-flex: 0 0 50%;
						flex: 0 0 50%;
						max-width: 50%;
						padding: 0 25px;
						margin-bottom: 40px;
					}

					.conf-gal-img {
						width: 100%;
						padding-bottom: 100%;
						background-size: cover;
						background-position: center center;
						cursor: pointer;
					}
				}

				&:nth-child(even) {
					.img-box:first-child {
						@include tablet {
							-ms-flex: 0 0 100%;
							flex: 0 0 100%;
							max-width: 100%;
						}
					}
				}

				&:nth-child(odd) {
					.img-box:last-child {
						@include tablet {
							-ms-flex: 0 0 100%;
							flex: 0 0 100%;
							max-width: 100%;
						}
					}
				}
			}
		}

		.conf-materials {
			text-align: center;
			ul {
				li {
					margin: 20px 0;
					svg {
						stroke: $black-real;
						margin-right: 10px;
					}

					a {
						color: $black-real;
					}

					&:hover {
						a {
							text-decoration: underline;
							color: $green;
							font-weight: normal;
						}

						svg {
							stroke: $green;
						}
					}
				}
			}
		}
	}

	.congress_signed {
		svg {
			margin-right: 5px;
			vertical-align: top;
		}
	}

	.contact-employer {
		height: auto;
		min-height: 50px;
		white-space: normal;
		border: 2px solid $green-lighter;
		color: $green-lighter;
		cursor: default;
		padding: 10px;
	}

	.input-msg {
		position: relative;
		top: -20px;
	}

	input.has-error,
	select.has-error {
		border-color: $red !important;
	}
}

[v-cloak] {
	display: none;
}
</style>
