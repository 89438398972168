<template>
  <main>
    <BaseHeroSearch
      :title="'Właściciele i Kierownicy'"
      :showFilters.sync="showFilters"
      :hasFilters="true"
      :search.sync="search"
      :hasSearch="true"
      :hasSearchMobileOnly="true"
      :placeholder="'Szukaj'"
    />
    <OwnersManagement :search.sync="search" :showFilters.sync="showFilters" />
  </main>
</template>

<script>
import BaseHeroSearch from '@/components/BaseHeroSearch.vue';
import OwnersManagement from '@/components/Containers/ViewOwnersManagement/OwnersManagement.vue';

export default {
	name: 'ViewOwnersManagement',
	components: {
		BaseHeroSearch,
		OwnersManagement,
	},
	data() {
		return {
			search: '',
			showFilters: false,
		};
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';
</style>
