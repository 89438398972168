<template>
	<section class="shops-management section">
		<div class="container">
			<div class="columns">
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full is-block has-text-centered">
							<div class="card-table card-table-has-expand">
								<!-- Search box -->
								<div class="card is-hidden-mobile">
									<div class="card-header">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input
													class="input is-size-4"
													type="text"
													v-model="filters.SearchQuery"
													:placeholder="'Szukaj sklepu'"
													@change="$emit('update:search', $event.target.value)"
												/>
												<span class="icon is-small is-left">
													<LoupeIcon />
												</span>
											</div>
										</div>
									</div>
								</div>
								<!-- END | Search box -->

								<!-- Data table -->
								<table class="table table-shops">
									<thead class="is-hidden-mobile">
										<!-- Headings -->
										<tr
											class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
										>
											<th class="has-text-grey-light">Sieć</th>
											<th class="has-text-grey-light">Kod sklepu</th>
											<th class="has-text-grey-light">Nazwa sklepu</th>
											<th class="has-text-grey-light">Miasto</th>
											<th class="has-text-grey-light">Przyciski akcji</th>
											<th class="has-text-grey-light">
												<!-- This empty column is needed to make the space for the chevron-down button that will toggle the shop's details view  -->
											</th>
										</tr>
										<!-- END | Headings -->

										<!-- Progress bar (loading indicator) -->
										<tr v-if="loading">
											<th colspan="6" class="is-progress-bar-table">
												<progress
													class="progress is-primary"
													max="100"
												></progress>
											</th>
										</tr>
										<!-- END | Progress bar (loading indicator) -->
									</thead>

									<!-- Table body -->
									<tbody>
										<template v-for="(shop, index) in shopsList">
											<tr
												class="shop-row is-size-17 has-text-left has-text-weight-semibold valign-cells-middle"
												:key="index"
												:class="{ 'is-active': shop.isOpen }"
											>
												<!-- Franchise -->
												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Sieć</span
														>
														<span class="has-text-right-mobile">{{
															shop.Network
														}}</span>
													</div>
												</td>
												<!-- END | Franchise -->

												<!-- Code -->
												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Kod sklepu</span
														>
														<span class="has-text-right-mobile">{{
															shop.ShopNumber
														}}</span>
													</div>
												</td>
												<!-- END | Code -->

												<!-- Name -->
												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Nazwa sklepu</span
														>
														<span class="has-text-right-mobile">{{
															shop.Name
														}}</span>
													</div>
												</td>
												<!-- END | Name -->

												<!-- City -->
												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Miasto</span
														>
														<span class="has-text-right-mobile">{{
															shop.City
														}}</span>
													</div>
												</td>
												<!-- END | City -->

												<!-- Action button -->
												<td>
													<div
														class="is-dotted is-block has-text-centered has-text-left-tablet"
													>
														<router-link
															:to="{
																name: 'Lista pracowników',
																params: { shopNumber: shop.ShopNumber },
															}"
															class="has-text-weight-semibold has-text-left is-clickable"
															:class="{
																'has-text-white': shop.isOpen,
																'has-text-primary': !shop.isOpen,
															}"
															>Lista pracowników >></router-link
														>
													</div>
												</td>
												<!-- END | Action button -->

												<!-- Chevron-down -->
												<td
													class="is-hidden-mobile has-text-centered is-clickable"
													@click="shop.isOpen = !shop.isOpen"
												>
													<DwnArrowIcon
														width="12"
														height="24"
														viewBox="0 0 12 20"
														class="dwn-arrow-icon"
													/>
												</td>
												<!-- END | Chevron-down -->
											</tr>

											<transition name="has-fade" :key="index">
												<tr v-if="shop.isOpen" class="b-collapse-container">
													<td colspan="6">
														<b-tabs
															v-model="shop.activeTab"
															@input="loadShopWorkersHandler(shop)"
														>
															<b-tab-item>
																<!-- Tab heading -->
																<template slot="header">
																	<strong>Dane sklepu</strong>
																</template>
																<!-- END | Tab heading -->

																<!-- Shop details -->
																<dl class="shop-details">
																	<Field
																		title="Nazwa sklepu"
																		:value="shop.Name ? shop.Name : 'brak'"
																	/>
																	<Field
																		title="Ulica"
																		:value="shop.Street ? shop.Street : 'brak'"
																	/>
																	<Field
																		title="Kod pocztowy"
																		:value="
																			shop.PostalCode ? shop.PostalCode : 'brak'
																		"
																	/>
																	<Field
																		title="Miasto"
																		:value="shop.City ? shop.City : 'brak'"
																	/>
																	<Field
																		title="Województwo"
																		:value="
																			shop.Province ? shop.Province : 'brak'
																		"
																	/>
																	<Field
																		title="Telefon"
																		:value="
																			shop.PhoneNumber
																				? shop.PhoneNumber
																				: 'brak'
																		"
																	/>
																	<Field
																		title="Numer sklepu"
																		:value="
																			shop.ShopNumber ? shop.ShopNumber : 'brak'
																		"
																	/>
																	<Field
																		title="Sieć sklepów"
																		:value="
																			shop.Network ? shop.Network : 'brak'
																		"
																	/>
																	<Field
																		title="Status"
																		:value="shop.Status ? shop.Status : 'brak'"
																	/>
																	<Field
																		title="Właściciel"
																		:value="
																			shop.OwnerName ? shop.OwnerName : 'brak'
																		"
																	/>
																</dl>
																<!-- END | Shop details -->
															</b-tab-item>
														</b-tabs>
													</td>
												</tr>
											</transition>
										</template>

										<!-- Loading / empty table indicator -->
										<tr v-if="!shopsList.length">
											<td
												colspan="6"
												class="is-size-17 has-text-centered has-text-weight-semibold"
											>
												{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
											</td>
										</tr>
										<!-- END | Loading / empty table indicator -->
									</tbody>
									<!-- END | Table body -->
								</table>
								<!-- END | Data table -->
							</div>

							<!-- Load more button -->
							<a
								class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
								@click="
									filters.Page++;
									loadShops();
								"
								v-if="nextPage"
								>Załaduj więcej treści</a
							>
							<!-- END | Load more button -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import global from '@/mixins/global.js';

import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import DwnArrowIcon from '@/assets/svg/dwn-arrow-large.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';
import PersonIcon from '@/assets/svg/person-small.svg';

import Field from './Field.vue';

export default {
	name: 'ShopsTable',
	mixins: [global],

	components: {
		AddIcon,
		LoupeIcon,
		DwnArrowIcon,
		CogWheelIcon,
		PersonIcon,
		Field,
	},

	props: {
		search: {
			type: String,
		},
	},

	data() {
		return {
			shopsList: [],
			statsList: [],
			filtersList: [],
			filters: {
				Page: 0,
			},
			nextPage: true,
			loading: false,
		};
	},

	methods: {
		loadShops() {
			this.loading = true;

			const userId = this.$route.params.userId;

			this.$http
				.post(
					`spa/managment/shops/getlist`,
					userId ? { ...this.filters, MemberId: userId } : this.filters
				)
				.then(response => {
					let data = response.data.Data;

					data.forEach(
						item => (
							(item.isOpen = false),
							(item.edit = false),
							(item.workers = ''),
							(item.loading = false)
						)
					);

					if (this.filters.Page) {
						this.shopsList = [...this.shopsList, ...data];
						this.nextPage = response.data.NextPage;
					} else {
						this.shopsList = data;
						this.nextPage = response.data.NextPage;
					}

					if (!this.$store.state.shop) {
						this.$set(this.$store.state, 'shop', this.shopsList[0]);
						this.$store.commit('UPDATE_STATE');
					}

					this.loading = false;
				})
				.catch(error => console.error(error));
		},
	},

	watch: {
		search: function() {
			this.filters.SearchQuery = this.search;
			this.filters.Page = 0;
			this.loadShops();
		},
	},

	beforeMount() {
		this.loadShops();
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Vertically align the content
// in <td>s within the selected <tr>.
.valign-cells-middle > td {
	vertical-align: middle;
}

// Bulma's buttons have 12px padding on the left.
// This class, added to any element, will add
// the very same 12px left padding to align
// its text's left end with the left end of the button.
.reconcile-button-padding {
	padding-left: 12px;
}

// Hide the dots for the cell with the
// "Lista pracowników >>" router-link on mobile.
td:nth-last-child(2) .is-dotted::before {
	@include mobile {
		display: none !important;
	}
}

.shop-row {
	border: 1px solid #dfe0e0;
	transition: all 300ms ease-in-out;

	// Remove the globally defined green borders.
	td {
		border: none !important;
	}

	@include tablet {
		// Remove the white background from the <span>s with text content.
		span,
		a {
			background: transparent;
		}
	}

	&.is-active {
		background-color: #3aab59;
		border-color: #3aab59;

		span {
			color: white;
		}

		// Rotate the chevron-down icon (b-collapse toggler).
		.dwn-arrow-icon {
			transform: rotate(270deg);
			transition: all 300ms ease-in-out;
		}
	}
}

.b-collapse-container {
	border: 1px solid #3aab59 !important;
}

.shop-details {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-row-gap: 15px;

	// For larger screens.
	@media (min-width: 480px) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;

		grid-auto-flow: column;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(5, 1fr);
		grid-column-gap: 70px;
	}
}
</style>
