<template>
	<main>
		<!--<BaseHero :title="'Ekspert radzi'" />-->
		<section class="description-task section">
			<div class="container is-container-thinnest">
				<div class="columns is-variable is-8 is-multiline">
					<div class="column is-full">
						<h1 class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow">{{ task.Title }}</h1>
					</div>
				</div>
			</div>
			<div class="container is-fluid">
				<div class="container is-container-thinnest">
					<div class="columns is-variable is-8 is-multiline">
						<div class="column is-full">
							<div class="content" v-html="task.FullText"></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import global from "@/mixins/global.js";
import BaseCardTopImage from "@/components/BaseCardTopImage.vue";

export default {
	name: "ViewOnboardingDescriptionTask",

	mixins: [global],

	components: {
		BaseHero,
		BaseCardTopImage
	},

	data() {
		return {
			task: {}
		};
		},

	activated: function () {
		let self = this;
		self.$http
			.get(`spa/Onboarding/${self.$route.params.programId}/Day/${self.$route.params.dayId}/DescriptionTask/${self.$route.params.taskId}`)
			.then(response => {
				self.task = response.data;
			})
			.catch((e) => { console.log(e); });
	},

	mounted() { },

	methods: {
			
	},

	created: function () { },

	computed: {
		player() {
			return this.$refs.youtube.player;
		}
	}
};
</script>


<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.description-task {
	margin-top: rem(30px);
	padding-bottom: 0;
	
	.is-fluid {
		background: $white;
		padding: rem(40px 20px 50px);
		margin: 0 -20px;
	}

	.image {
		margin-bottom: rem(50px);
	}

	.has-play-button {
		margin-bottom: rem(50px);
	}
}
</style>