<template>
  <b-modal
    :active.sync="isActive"
    :width="830"
    :canCancel="['escape', 'x', 'outside']"
    scroll="keep"
    class="modal-dialog"
	id="ViewUserData__ModalInfo"
  >
    <button type="button" class="modal-close is-large" @click="close"></button>

    <!-- Dialog -->
    <div class="columns">
      <div class="column is-full">
        <p class="is-size-2 has-text-centered has-text-weight-light">{{ message }}</p>
      </div>
    </div>

    <!-- Button -->
    <div class="columns is-centered">
      <div class="column is-half">
        <button @click="close" class="button is-fullwidth is-info">{{ buttonLabel }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
	props: {
		message: {
			type: String,
			required: false,
		},
		buttonLabel: {
			type: String,
			required: false,
		},
	},

	data() {
		return {
			isActive: false,
		};
	},

	methods: {
		open() {
			this.isActive = true;
		},

		close() {
			this.isActive = false;
		},
	},
};
</script>

<style lang="scss" scoped>
</style>