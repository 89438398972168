<template>
    <section class="help-contact section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-full has-text-centered">
            <div class="card">
              <div class="card-header">
                  <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
                      Sprawdź listę najczęściej zadawanych pytań i&nbsp;odpowiedzi
                  </p>
              </div>
            </div>
            <!--
            <div class="card">
              <div class="card-header">
                <div class="field">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input is-size-4-tablet" type="text" placeholder="Szukaj w najczęściej zadawanych pytaniach">
                    <span class="icon is-small is-left">
                      <LoupeIcon/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            -->
            <b-collapse class="card" :open="false">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Jak uzyskać login do&nbsp;serwisu Akademia Eurocash
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Jeśli nie&nbsp;posiadacie Państwo loginu niezbędnego do&nbsp;zalogowania się&nbsp;w&nbsp;serwisie Akademia Umiejętności prosimy o&nbsp;kontakt z&nbsp;naszą infolinią 502 004 024 lub&nbsp;wysłanie zapytania na&nbsp;adres <a href="mailto:kontakt@akademiaeurocash.com.pl">kontakt@akademiaeurocash.com.pl</a>
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Nie umiem się&nbsp;zalogować. Co&nbsp;mam&nbsp;robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Proszę się&nbsp;upewnić czy&nbsp;dobrze został wpisywany login (adres&nbsp;e-mail) i&nbsp;hasło. Jeśli hasło nie jest znane, proszę kliknąć „Zapomniałeś hasła?” a&nbsp;następnie podać e-mail wykorzystywany przy logowaniu na&nbsp;Akademię Eurocash.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Podczas logowania do&nbsp;Akademii wyskakuje błąd „Nazwa lub&nbsp;hasło użytkownika są&nbsp;niepoprawne albo nie posiadasz u&nbsp;nas konta”. Co&nbsp;mam robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Proszę się&nbsp;upewnić, że&nbsp;po&nbsp;zarejestrowaniu się&nbsp;do&nbsp;Akademii, konto zostało aktywowane poprzez kliknięcie linka w&nbsp;mailu aktywacyjnym jaki wysłano na&nbsp;adres podany przy rejestracji.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Z&nbsp;kim mogę się&nbsp;skontaktować w&nbsp;przypadku problemów?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      W&nbsp;razie problemów skontaktuj się z&nbsp;nami za&nbsp;pośrednictwem formularza kontaktowego lub&nbsp;numeru telefonu zamieszczonego na&nbsp;stronie.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Nie&nbsp;dostaję e-mail&nbsp;programu: potwierdzenia rejestracji, odzyskiwania hasła oraz innej komunikacji. Co&nbsp;mam zrobić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Najprawdopodobniej nasze wiadomości są&nbsp;przez Twój system pocztowy klasyfikowane jako niechciane i&nbsp;trafiają do folderu&nbsp;‘spam’. Poszukaj w&nbsp;nim e-maili wysłanych z&nbsp;domeny ‘akademiaeurocash.com.pl’. Dodaj także wszelkie adresy z&nbsp;domeny „akademiaeurocash.com.pl” („*@@*.akademiaeurocash.com.pl”) do&nbsp;tzw. whitelisty filtra poczty. Jeśli nie&nbsp;masz folderu ‘spam’ w&nbsp;swoim programie pocztowym, lub nie&nbsp;wiesz, jak dodać domenę do&nbsp;whitelisty, skontaktuj się ze&nbsp;swoim administratorem poczty. Gdyby jednak żaden z powyższych sposób nie pomógł, skorzystaj z&nbsp;formularza kontaktowego na&nbsp;stronie www.akademiaeurocash.com.pl i&nbsp;opisz swój problem, podaj inny adres e-mail, na&nbsp;który moglibyśmy wysyłać wiadomości do&nbsp;Ciebie oraz numer telefonu kontaktowego, na&nbsp;wypadek gdyby i&nbsp;ten sposób nie&nbsp;zadziałał.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Nie otrzymałem maila z&nbsp;loginem, co&nbsp;robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Jeżeli nie&nbsp;otrzymałeś maila z&nbsp;linkiem aktywującym konto, który został wysłany z&nbsp;adresu akademia@akademiaeurocash.com.pl, sprawdź w&nbsp;pierwszej kolejności czy&nbsp;nie trafił do&nbsp;folderu ze&nbsp;spamem. Jeżeli go&nbsp;nie znajdziesz, skontaktuj się z&nbsp;nami za&nbsp;pośrednictwem formularza kontaktowego.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Co&nbsp;mam zrobić, jeśli nie&nbsp;mam&nbsp;dostępu do&nbsp;swojego adresu e-mail który jest zarejestrowany w&nbsp;Akademii Eurocash?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Skontaktuj się z&nbsp;nami za&nbsp;pośrednictwem formularza kontaktowego podając swoje imię, nazwisko oraz pełną nazwę Sklepu, w&nbsp;którym pracujesz.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Strona wyświetla się&nbsp;nieprawidłowo. Co&nbsp;robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Upewnij&nbsp;się, że&nbsp;masz zainstalowaną najnowszą wersję przeglądarki internetowej w&nbsp;Twoim systemie. Aby&nbsp;strona wyświetlała się&nbsp;prawidłowo, korzystaj z&nbsp;Windows Internet Explorer z&nbsp;wyłączonym widokiem zgodności (compatibility view), w&nbsp;wersji co&nbsp;najmniej 11.0, najnowszej wersji Mozilla Firefox,&nbsp;lub Google Chrome.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Czy&nbsp;uczestnicy poprzedniej wersji Akademii Umiejętności muszą się&nbsp;ponownie rejestrować?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Nie musisz rejestrować się&nbsp;ponownie, jeżeli posiadałeś konto w&nbsp;poprzedniej wersji Wystarczy, że&nbsp;zalogujesz się&nbsp;na stronę www.akademiaeurocash.com.pl, używając swojego dotychczasowego loginu. Jeżeli nie&nbsp;pamiętasz hasła, skorzystaj z&nbsp;opcji Zapomniałeś hasła.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Na&nbsp;stronie Akademii Eurocash nie&nbsp;wyświetla się&nbsp;wynik kursu który ukończyłem. Co&nbsp;robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      Wyniki kursów na&nbsp;stronie Akademii Eurocash są&nbsp;aktualizowane co&nbsp;godzinę. Jeżeli Twój wynik nadal się&nbsp;nie wyświetla, prosimy o&nbsp;kontakt poprzez formularz kontaktowy.
                  </div>
              </div>
            </b-collapse>
            <b-collapse class="card" :open="false" v-if="nextPage">
              <div slot="trigger" slot-scope="props" class="card-header">
                  <p class="card-header-title is-size-4-tablet has-text-weight-light has-text-left">
                    <span v-if="props.open">
                      <MinusIcon width="12" height="12" viewBox="0 -11 46 47" class="icon is-large"  />
                    </span>
                    <span v-else>
                      <PlusIcon  width="12" height="12" viewBox="0 -11 46 47" class="icon is-large" /></span>
                      Sklep Został wyrejestrowany. Co&nbsp;robić?
                  </p>
              </div>
              <div class="card-content">
                  <div class="content has-text-left is-size-7 has-text-weight-light">
                      W&nbsp;celu dokonania ponownej rejestracji konieczne jest&nbsp;zgłoszenie sklepu przez&nbsp;Właściciela. Rejestracji sklepu można dokonać poprzez kontakt z&nbsp;infolinią lub wysyłając maila na&nbsp;adres <a href="mailto:kontakt@akademiaeurocash.com.pl">kontakt@akademiaeurocash.com.pl</a>
                  </div>
              </div>
            </b-collapse>
            <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="nextPage = !nextPage" >zobacz {{ nextPage ? 'mniej' : 'więcej' }} pytań i odpowiedzi</a>
          </div>
          <div class="column is-full">
            <h2 class="title is-2 has-text-centered">Nie znalazłeś odpowiedzi? Skontaktuj się z&nbsp;nami</h2>
          </div>
        </div>
      </div>
      <div class="container is-container-thinnest">
        <div class="columns is-multiline">
          <div class="column is-half has-text-left">
            <HelpContactForm/>
          </div>
          <div class="column is-5 is-offset-1 has-text-left help-contact-box is-flex">
            <div>
              <p class="icon icon is-large">
                <CustomerSupportIcon class="icon is-large"/>
              </p>
              <p class="help-contact-infoline has-text-weight-light is-size-4 ">Kontakt z infolinią:</p>
              <a class="is-size-2 has-text-weight-semibold" href="tel:502004024 ">502 004 024 </a>
              <p class="is-size-2 is-black">pn-pt 9:00-13:00</p>
              <div class="has-text-weight-light is-size-4 help-contact-address">
                <p>Akademia Umiejętności Eurocash sp. z o.o</p>
                <p>GRUPA EUROCASH S.A.</p>
                <p>Marynarska Business Park</p>
                <p>ul. Taśmowa 7a</p>
                <p>02-677 Warszawa</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import CustomerSupportIcon from "@/assets/svg/customer-support.svg";
import PlusIcon from "@/assets/svg/circle-plus.svg";
import MinusIcon from "@/assets/svg/circle-minus.svg";
import LoupeIcon from "@/assets/svg/loupe.svg";
import HelpContactForm from "@/components/Containers/ViewHelpContact/HelpContactForm.vue";

export default {
  name: "HelpContact",
  components: {
    HelpContactForm,
    CustomerSupportIcon,
    PlusIcon,
    MinusIcon,
    LoupeIcon
  },
  data() {
    return {
      nextPage: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.help-contact {
  .help-contact-infoline {
    margin-bottom: 20px;
  }
  .help-contact-address {
    margin-top: 20px;
    & > * {
      width: 100%;
    }
  }
  .help-contact-box {
    flex-wrap: wrap;
    align-items: center;
    .icon {
      width: auto;
      height: auto;
      margin-bottom: 10px;
    }
  }
}
.card {
  border-bottom: 1px solid #ececec;
  border-radius: 0;
  &:first-child {
    background: $green-lighter;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    .card-header-title {
      padding: 25px 20px;
    }
  }
  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &-header {
    box-shadow: none;
    .field {
      width: 100%;
      .control {
        input {
          border: 0;
          padding: 20px 60px;
          background: $white-bis;
        }
        span {
          margin: 15px;
        }
      }
    }
  }
  &-content {
    padding-top: 0;
    padding-left: 74px;
    a:hover,
    a:focus {
      text-decoration: underline;
      color: $green-lighter;
    }
  }
  &-header-title {
    padding: 20px 20px;
    span {
      padding: 0 5px 0 0;
    }
  }
  &-content .content {
    line-height: 30px;
    margin-top: -20px;
  }
}
.button.is-light.is-outlined {
  padding: 25px 50px;
  margin: rem(30px 0);
  border-color: $grey-lighter;
  width: 100%;
  max-width: 320px;
  &:hover,
  &:focus {
    background: transparent;
  }
  @include tablet {
    width: auto;
  }
}
</style>
