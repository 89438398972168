<template>
	<section class="workshops-management section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu" v-if="filtersList.length">
						<b-collapse
							:open.sync="filterType.isOpen"
							v-for="(filterType, key) in filtersList.slice(0, -2)"
							:key="key"
						>
							<p
								class="menu-label is-size-7 has-text-black has-border-bottom"
								slot="trigger"
								:class="{ 'is-active': filterType.isOpen }"
							>
								{{ filterType.Title }}
							</p>
							<div class="navbar-content">
								<ul class="menu-list">
									<li
										class="is-flex"
										:class="{
											'is-selected':
												filters[filterType.Key] === Number(filter.Key),
										}"
										v-for="filter in filterType.Options"
										:key="filter.Key"
									>
										<a
											@click="
												(filters[filterType.Key] = Number(filter.Key)),
													(filters.Page = 0),
													loadWorkshopsHandler()
											"
											class="is-size-7"
											>{{ filter.Value }}</a
										>
										<button
											class="delete is-small"
											@click="
												clearFiltersHandler(
													filterType.Key,
													loadWorkshopsHandler
												)
											"
										></button>
									</li>
								</ul>
							</div>
						</b-collapse>
						<p
							class="menu-label is-size-7 has-text-black has-text-weight-semibold"
						>
							{{ filtersList[3].Title }}
						</p>
						<div class="navbar-content">
							<div class="field">
								<div class="control">
									<div class="input-container">
										<v-selector
											:max-height="'300px'"
											label="Key"
											class="filter"
											v-model="province"
											:options="filtersList[3].Options"
											placeholder="Wybierz"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
							</div>
						</div>
						<p
							class="menu-label is-size-7 has-text-black has-text-weight-semibold"
						>
							{{ filtersList[4].Title }}
						</p>
						<div class="navbar-content">
							<div class="field">
								<div class="control">
									<div class="input-container">
										<v-selector
											:max-height="'300px'"
											label="Key"
											class="filter"
											v-model="city"
											:options="filtersList[4].Options"
											placeholder="Wybierz"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
							</div>
						</div>
						<p
							class="menu-label is-size-7 has-text-black has-text-weight-semibold"
						>
							TERMIN
						</p>
						<div class="navbar-content">
							<ul class="menu-list">
								<li>
									<div class="columns">
										<div class="column is-6">
											<div class="field">
												<div class="control">
													<b-datepicker
														:date-formatter="
															(date) => {
																return formatDateHandler(date);
															}
														"
														v-model="dateFrom"
														:month-names="monthNames"
														:max-date="maxDate"
														:min-date="minDate"
														placeholder="Od"
													></b-datepicker>
													<span class="icon is-small is-right">
														<CalendarSmallIcon />
													</span>
												</div>
											</div>
										</div>
										<div class="column is-6">
											<div class="field">
												<div class="control">
													<b-datepicker
														:date-formatter="
															(date) => {
																return formatDateHandler(date);
															}
														"
														v-model="dateTo"
														:month-names="monthNames"
														:max-date="maxDate"
														:min-date="minDate"
														placeholder="Do"
														ref="date-to"
													></b-datepicker>
													<span class="icon is-small is-right">
														<CalendarSmallIcon />
													</span>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</aside>
				</div>
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full">
							<div class="columns is-variable is-3 actions">
								<div class="column is-offset-one-fifth-widescreen">
									<b-dropdown
										hoverable
										class="has-text-left has-text-weight-semibold"
									>
										<button
											class="button has-arrow has-text-left"
											slot="trigger"
										>
											<span class="has-text-grey has-text-weight-bold is-size-7"
												>EKSPORTUJ LISTĘ</span
											>
										</button>
										<b-dropdown-item>ZOBACZ PEŁEN OPIS</b-dropdown-item>
										<b-dropdown-item>EDYTUJ</b-dropdown-item>
										<b-dropdown-item>EWALUACJA</b-dropdown-item>
										<b-dropdown-item>ANKIETA</b-dropdown-item>
										<b-dropdown-item :has-link="true">
											<router-link
												to="/panel-zarzadania/warsztaty/lista-uzytkownikow/1"
												>LISTA UCZESTNIKÓW</router-link
											>
										</b-dropdown-item>
										<b-dropdown-item @click="isWorkshopCancelModalActive = true"
											>ODWOŁAJ</b-dropdown-item
										>
									</b-dropdown>
								</div>
								<div class="column">
									<a
										href="#"
										class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
									>
										<span class="icon is-small">
											<AddIcon />
										</span>
										<span>Dodaj lokalizację</span>
									</a>
								</div>
								<div class="column">
									<a
										href="#"
										class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
									>
										<span class="icon is-small">
											<AddIcon />
										</span>
										<span>Dodaj warsztat</span>
									</a>
								</div>
							</div>
						</div>
						<div class="column is-full is-block has-text-centered">
							<div class="card-table">
								<div class="card is-hidden-mobile">
									<div class="card-header">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input
													class="input is-size-4"
													type="text"
													:placeholder="'Szukaj warsztatu'"
												/>
												<span class="icon is-small is-left">
													<LoupeIcon />
												</span>
											</div>
										</div>
									</div>
								</div>
								<table class="table">
									<thead class="is-hidden-mobile">
										<tr
											class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
										>
											<th class="has-text-grey-light">Dydykowane</th>
											<th class="has-text-grey-light">Nazwa warsztatu</th>
											<th class="has-text-grey-light">Data</th>
											<th class="has-text-grey-light">Lokalizacja</th>
											<th class="has-text-grey-light"></th>
										</tr>
										<tr v-if="loading">
											<th colspan="6" class="is-progress-bar-table">
												<progress
													class="progress is-primary"
													max="100"
												></progress>
											</th>
										</tr>
									</thead>
									<tbody v-if="workshops.length">
										<tr
											class="has-text-left has-text-weight-semibold"
											v-for="workshop in workshops"
											:key="workshop.Id"
										>
											<td class="has-text-black">
												<div class="is-block">
													<span
														class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Dydykowane</span
													>
													<div class="columns is-multiline is-flex">
														<div
															class="column is-4-tablet is-flex"
															v-for="(img, key) in workshop.ForWho.split(',')"
															:key="key"
														>
															<img
																:src="
																	'/assets/2018/images/sieci/' +
																	img.toLowerCase() +
																	'.png'
																"
																v-if="img.toLowerCase() !== 'operacja'"
															/>
															<span class="is-size-10" v-else>{{ img }}</span>
														</div>
													</div>
												</div>
											</td>
											<td class="has-text-black">
												<div class="is-dotted is-block">
													<span
														class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Nazwa warsztatu</span
													>
													<span>{{ workshop.Title }}</span>
												</div>
											</td>
											<td class="has-text-black">
												<div class="is-dotted is-block">
													<span
														class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Data</span
													>
													<span>{{ workshop.Date }}</span>
												</div>
											</td>
											<td class="has-text-black">
												<div class="is-dotted is-block">
													<span
														class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Lokalizacja</span
													>
													<span>{{ workshop.Localization }}</span>
												</div>
											</td>
											<td>
												<div class="is-block is-uppercase">
													<b-dropdown v-model="workshop.Id" hoverable>
														<button
															class="button has-text-left has-arrow"
															slot="trigger"
														>
															<span class="has-text-grey has-text-weight-bold"
																>AKCJE</span
															>
														</button>
														<b-dropdown-item>ZOBACZ PEŁEN OPIS</b-dropdown-item>
														<div v-if="workshop.FullOptions">
															<b-dropdown-item>EDYTUJ</b-dropdown-item>
															<b-dropdown-item>EWALUACJA</b-dropdown-item>
															<b-dropdown-item>ANKIETA</b-dropdown-item>
															<b-dropdown-item :has-link="true">
																<router-link
																	:to="
																		'/panel-zarzadania/warsztaty/lista-uzytkownikow/' +
																		workshop.Id
																	"
																	>LISTA UCZESTNIKÓW</router-link
																>
															</b-dropdown-item>
															<b-dropdown-item
																@click="isWorkshopCancelModalActive = true"
																>ODWOŁAJ</b-dropdown-item
															>
														</div>
													</b-dropdown>
												</div>
											</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td
												colspan="5"
												class="has-text-centered has-text-weight-semibold"
											>
												{{ loading ? 'Wczytywanie' : 'Brak danych' }}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<a
								class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold"
								@click="
									filters.Page++;
									loadWorkshopsHandler();
								"
								v-if="nextPage"
								>Załaduj więcej treści</a
							>
						</div>
					</div>
				</div>
				<!--
          <h2 class="title is-2 has-text-weight-light has-text-left" v-if="!elearnings.length">{{ $parent.search ? 'Nie znaleziono wyników dla ' + $parent.search : 'Brak wyników' }}<a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="$emit('update:search', ''); clearFiltersHandler()">Resetuj wyszukiwanie</a></h2>
        -->
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ<button class="delete is-small"></button>
					</p>
					<ul class="menu-list" v-if="filtersList.length">
						<li>
							<b-collapse
								:open.sync="filterType.isOpen"
								v-for="(filterType, key) in filtersList.slice(0, -2)"
								:key="key"
							>
								<span
									class="has-arrow is-flex"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									<span
										class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
										>{{ filterType.Title }}</span
									>
								</span>
								<div>
									<div class="navbar-content">
										<ul>
											<li
												class="is-flex has-text-left"
												:class="{
													'is-selected':
														filters[filterType.Key] === Number(filter.Key),
												}"
												v-for="filter in filterType.Options"
												:key="filter.Key"
											>
												<a
													@click="
														(filters[filterType.Key] = Number(filter.Key)),
															(filters.Page = 0),
															loadWorkshopsHandler()
													"
													>{{ filter.Value }}</a
												>
												<button
													class="delete is-small"
													@click="
														clearFiltersHandler(
															filterType.Key,
															loadWorkshopsHandler
														)
													"
												></button>
											</li>
										</ul>
									</div>
								</div>
							</b-collapse>
						</li>
						<li>
							<p
								class="is-size-7 is-info has-text-weight-semibold is-uppercase"
							>
								{{ filtersList[3].Title }}
							</p>
							<div class="navbar-content">
								<div class="field">
									<div class="control">
										<div class="input-container">
											<v-selector
												:max-height="'300px'"
												label="Key"
												class="filter"
												v-model="province"
												:options="filtersList[3].Options"
												placeholder="Wybierz"
											></v-selector>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li>
							<p
								class="is-size-7 is-info has-text-weight-semibold is-uppercase"
							>
								{{ filtersList[4].Title }}
							</p>
							<div class="navbar-content">
								<div class="field">
									<div class="control">
										<div class="input-container">
											<v-selector
												:max-height="'300px'"
												label="Key"
												class="filter"
												v-model="city"
												:options="filtersList[4].Options"
												placeholder="Wybierz"
											></v-selector>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li>
							<p
								class="is-size-7 is-info has-text-weight-semibold is-uppercase"
							>
								Termin
							</p>
							<div class="navbar-content">
								<ul class="menu-list">
									<li>
										<div class="columns is-marginless">
											<div class="column is-6">
												<div class="field">
													<div class="control">
														<b-datepicker
															:date-formatter="
																(date) => {
																	return formatDateHandler(date);
																}
															"
															v-model="dateFrom"
															:month-names="monthNames"
															:max-date="maxDate"
															:min-date="minDate"
															placeholder="Od"
														></b-datepicker>
														<span class="icon is-small is-right">
															<CalendarSmallIcon />
														</span>
													</div>
												</div>
											</div>
											<div class="column is-6">
												<div class="field">
													<div class="control">
														<b-datepicker
															:date-formatter="
																(date) => {
																	return formatDateHandler(date);
																}
															"
															v-model="dateTo"
															:month-names="monthNames"
															:max-date="maxDate"
															:min-date="minDate"
															placeholder="Do"
															ref="date-to2"
														></b-datepicker>
														<span class="icon is-small is-right">
															<CalendarSmallIcon />
														</span>
													</div>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
		<b-modal
			:active.sync="isWorkshopCancelModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isWorkshopCancelModalActive = false"
			></button>
			<div class="columns">
				<div class="column is-full">
					<p class="is-size-2 has-text-centered has-text-weight-light">
						Czy na pewno chcesz odwołać warsztat „Stoisko warzywa i owoce”?
					</p>
				</div>
			</div>
			<div class="columns">
				<div class="column is-6 has-text-right-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
						@click="isWorkshopCancelModalActive = false"
						><span>TAK</span></a
					>
				</div>
				<div class="column is-6 has-text-left-desktop">
					<a
						class="button is-small is-danger is-uppercase has-text-weight-bold has-glow-button"
						@click="isWorkshopCancelModalActive = false"
						><span>NIE</span></a
					>
				</div>
				<div class="column is-full" v-if="successMsg">
					<p
						class="is-size-8 has-text-weight-light has-text-primary has-text-centered"
					>
						{{ successMsg }}
					</p>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script>
import { format } from 'date-fns';
import BaseCardCalendar from '@/components/BaseCardCalendar.vue';
import global from '@/mixins/global.js';
import CalendarSmallIcon from '@/assets/svg/small-calendar.svg';
import AddIcon from '@/assets/svg/buttons/add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';

export default {
	name: 'WorkshopsManagement',
	mixins: [global],
	components: {
		BaseCardCalendar,
		CalendarSmallIcon,
		AddIcon,
		LoupeIcon,
	},
	props: {
		search: {
			type: String,
		},
	},
	mounted() {},
	data() {
		return {
			workshops: [],
			filtersList: [],
			filters: {
				Page: 0,
				DateFrom: '',
				DateTo: '',
			},
			nextPage: true,
			isWorkshopCancelModalActive: false,
			successMsg: '',
			monthNames: [
				'Styczeń',
				'Luty',
				'Marzec',
				'Kwiecień',
				'Maj',
				'Czerwiec',
				'Lipiec',
				'Sierpień',
				'Wrzesień',
				'Październik',
				'Listopad',
				'Grudzień',
			],
			dateFrom: null,
			dateTo: null,
			province: null,
			city: null,
			maxDate: null,
			minDate: null,
			loading: false,
		};
	},
	methods: {
		loadWorkshopsHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/trainer/workshops`, self.filters)
				.then((response) => {
					if (self.filters.Page) {
						self.workshops = [...self.workshops, ...response.data.Data];
						self.nextPage = response.data.NextPage;
					} else {
						self.workshops = response.data.Data;
						self.nextPage = response.data.NextPage;
					}
					if (self.filters.Provice === undefined) {
						self.filtersList = response.data.Filters;
						self.filtersList.forEach(function (item) {
							self.$set(item, 'isOpen', false);
							self.$set(self.filters, item.Key, null);
						});
					} else {
						self.filtersList.forEach(function (item, key) {
							item.Options = response.data.Filters[key].Options;
						});
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		formatDateHandler(date) {
			return format(date, 'DD/MM/YY');
		},
	},
	watch: {
		search: function () {
			this.filters.Query = this.search;
			this.filters.Page = 0;
			this.loadWorkshopsHandler();
		},
		'filters.TimeStatus': function () {
			let self = this;
			function resetDates() {
				self.dateFrom = null;
				self.filters.dateFrom = null;
				self.dateTo = null;
				self.filters.DateTo = null;
			}
			if (this.filters.TimeStatus === 1) {
				this.minDate = new Date();
				this.maxDate = null;
				resetDates();
			} else if (this.filters.TimeStatus === 2) {
				this.maxDate = new Date();
				this.minDate = null;
				resetDates();
			}
		},
		dateFrom: function () {
			this.filters.DateFrom = format(this.dateFrom, 'DD/MM/YY');
			if (this.dateTo) {
				if (this.dateFrom > this.dateTo) {
					this.dateTo = null;
					this.filters.DateTo = null;
				}
			}
			this.loadWorkshopsHandler();
		},
		dateTo: function () {
			if (this.dateFrom) {
				if (this.dateFrom > this.dateTo) {
					this.filters.DateTo = null;
					this.dateTo = null;
					this.$refs['date-to']._data.dateSelected = null;
					this.$refs['date-to2']._data.dateSelected = null;
				} else {
					this.filters.DateTo = format(this.dateTo, 'DD/MM/YY');
				}
			}
			this.loadWorkshopsHandler();
		},
		province: function () {
			this.filters.Provice = this.province.Value;
			this.city = null;
			this.filters.City = null;
			this.loadWorkshopsHandler();
		},
		city: function () {
			this.filters.City = this.city.Value;
			this.loadWorkshopsHandler();
		},
	},
	created: function () {
		this.loadWorkshopsHandler();
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.workshops-management {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}
	.container > :first-child > :nth-child(2) {
		@include desktop {
			margin-top: -100px;
		}
	}
	.actions {
		.dropdown {
			width: 100%;
			& > :first-child,
			.button {
				width: inherit;
			}
		}
		.button:not(.has-arrow) {
			color: rgba(58, 171, 89, 0.5);
			height: 50px;
			span {
				color: white;
			}
		}
		.button.has-arrow {
			border-color: transparent;
		}
	}
	.card-table {
		margin-top: 10px;
		overflow: visible;
		.card {
			border-top-right-radius: 4px;
			border-top-left-radius: 4px;
			&-header {
				padding: rem(20px);
				.field {
					width: 100%;
				}
				.input {
					border-color: transparent;
				}
			}
		}
		.table {
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
			thead tr:last-child:not(:first-child) th {
				padding: 0;
			}
			td {
				.columns {
					width: 100%;
					margin-top: 10px;
					@include tablet {
						width: 200px;
						margin-top: 0;
					}
					& > div {
						align-items: center;
						justify-content: center;
						float: left;
						img {
							max-width: 30px;
						}
					}
				}
			}
		}
	}
	.dropdown {
		padding: 0;
		flex: 2;
		width: 100%;
		@include desktop {
			font-size: 14px;
		}
		@include widescreen {
			font-size: 16px;
		}
		& > :first-child,
		.button {
			width: inherit;
		}
		& > :nth-child(2) {
			right: 0;
			left: auto;
		}
		.button {
			box-shadow: none;
			border-color: $grey-light;
			text-decoration: none;
			padding-left: rem(20px);
		}
		& > :last-child {
			padding: 0;
			width: 100%;
			& > :first-child {
				padding: 0;
			}
		}
		&-item,
		.has-link a {
			background: $grey-dark;
			color: $white;
			padding: 15px 20px;
			border-bottom: solid 1px $grey;
			&:hover,
			&:focus {
				background: $green-lighter;
				color: white;
			}
		}
		&-item:first-child {
			&:before {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				right: 20px;
				top: -10px;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid $grey-dark;
				z-index: 1;
			}
			&:hover,
			&:focus {
				&:before {
					border-bottom: 10px solid $green-lighter;
				}
			}
		}
		.has-arrow {
			height: 50px;
			padding-right: 40px;
			@include widescreen {
				min-width: 224px;
			}
			&:before {
				content: '';
				width: 16px;
				height: 16px;
				border-color: $white;
				background: $grey-light;
				position: absolute;
				right: 20px;
				border-radius: 50%;
				top: 16px;
			}
			&:after {
				transform: rotate(-45deg);
				border-color: $white;
				z-index: 1;
				position: absolute;
				right: 10px;
				margin: -4px 15px 0 0;
			}
		}
	}
	.columns .menu,
	.filters {
		text-align: left;
		a {
			padding-left: 0;
			width: 100%;
		}
		.control {
			position: relative;
			.icon {
				position: absolute;
				right: 10px;
				top: 13px;
				z-index: 3;
				color: $grey-light;
				display: none;
				@include desktop {
					display: block;
				}
			}
		}
		&-list {
			li {
				justify-content: space-between;
			}
			.filter-input {
				padding: rem(25px 0);
				margin-bottom: 0;
			}
		}
		& > p {
			padding-top: rem(15px);
		}
	}
	.menu {
		& > div:not(:last-child) {
			.menu-list {
				border-bottom: 1px solid $grey-lighter;
				padding: 20px 0 25px 0;
			}
		}
	}
	.filters {
		.menu-list {
			& > :nth-last-child(2),
			& > :nth-last-child(3) {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
			& > :last-child {
				background: $grey-lightest;
				padding: rem(0 5px);
				p {
					padding: rem(15px 20px 0);
				}
			}
		}
	}
	li {
		letter-spacing: 1px;
		a {
			padding-right: 5px;
		}
	}
	.modal-dialog {
		.modal-content {
			.button {
				min-width: 240px;
			}
		}
	}
}
</style>
