<template>
    <section class="aue-tv section">
        <div class="container">
            <div class="columns">
                <div class="column is-one-quarter is-hidden-mobile">
                    <aside class="menu">
                        <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                            <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': filterType.isOpen }">{{ filterType.Title }}</p>
                            <div class="navbar-content">

                                <FilterMenu  @clearFilter="clearFilter"   @filterClick="filterClick" :type="filterType.Key" :currentlySelected="filters[filterType.Key]" :options="filterType.Options" :level="0"></FilterMenu>

                                <!--<ul class="menu-list">
                                    <li class="is-flex" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                        <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadAuetvHandler()" class="is-size-7">{{ filter.Value }}</a>
                                        <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadAuetvHandler)"></button>
                                    </li>
                                </ul>-->
                            </div>
                        </b-collapse>
                        <div class="navbar-content" v-if="!isUnaffilliated" >
                            <ul class="menu-list">
                                <li class="is-flex has-text-left" :class="{ 'is-selected':  filters.IsDedicatedForNetwork === true}">
                                    <a class="navbar-item has-text-left is-size-7" :class="{ 'has-border-top': isOpen[1] }" @click="filters.IsDedicatedForNetwork = true, filters.Page = 0, loadAuetvHandler()">
                                        Dedykowane dla sieci
                                    </a>
                                    <button class="delete is-small" @click="filters.IsDedicatedForNetwork = false, filters.Page = 0, loadAuetvHandler()"></button>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
                <div class="column">
                    <div class="columns is-paddingless is-multiline">
                        <div class="column is-full" v-for="aue in aueTv" :key="aue.Id">
                            <BaseCardMovie @goToUrlHandler="goToUrlHandler('/aue-tv/' + aue.Id)" :image="aue.Image" :category="aue.Category" :title="aue.Title" :description="aue.Subtitle" :column1="aue.ToLearnColFirst" :column2="aue.ToLearnColSecond" />
                        </div>
                        <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadAuetvHandler()" v-if="nextPage">Załaduj więcej treści</a>
                        <h2 class="title is-2 has-text-weight-light has-text-left" v-if="showNoResult">
                            {{ 'Brak wyników' }}
                            <a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="clearFiltersHandler(false, loadAuetvHandler)">Resetuj wyszukiwanie</a>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
        <nav class="filters is-fixed-top">
            <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
                <span v-touch:tap="showFiltersHandler"></span>
                <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
                    <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
                    <ul class="menu-list">
                        <li>
                            <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                                <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': filterType.isOpen }">
                                    <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">{{ filterType.Title }}</span>
                                </span>
                                <div>
                                    <div class="navbar-content">
                                        <FilterMenuMobile @clearFilter="clearFilter" @filterClick="filterClick" :type="filterType.Key" :currentlySelected="filters[filterType.Key]" :options="filterType.Options" :level="0"></FilterMenuMobile>
                                        
                                        
                                        <ul>
                                            <li class="is-flex has-text-left" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                                <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadAuetvHandler()">{{ filter.Value }}</a>
                                                <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadAuetvHandler)"></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </li>
                        <li>
                            <ul v-if="!isUnaffilliated">
                                <li class="is-flex has-text-left" :class="{ 'is-selected':  filters.IsDedicatedForNetwork === true}">
                                    <a class="navbar-item has-text-left is-size-7 is-uppercase" @click="filters.IsDedicatedForNetwork = true, filters.Page = 0, loadAuetvHandler()">
                                        Dedykowane dla sieci
                                    </a>
                                    <button class="delete is-small" @click="filters.IsDedicatedForNetwork = false, filters.Page = 0, loadAuetvHandler()"></button>
                                </li>
                            </ul>

                        </li>
                    </ul>
                </div>
            </aside>
        </nav>
    </section>
</template>

<script>
    import global from "@/mixins/global.js";
    import BaseCardMovie from "@/components/BaseCardMovie.vue";
    import FilterMenu from "./FilterMenu.vue"
    import FilterMenuMobile from "./FilterMenuMobile.vue"
    export default {
        name: "AueTv",
        mixins: [global],
        components: {
            BaseCardMovie, 
            FilterMenu,
            FilterMenuMobile
        },
        props: {
            search: {
                type: String
            }
        },
        mounted() {},
        data() {
            return {
                aueTv: {},
                filtersList: {},
                isOpen: [false, false],
                showNoResult: false,
                filters: {
                    Page: 0,
                    IsDedicatedForNetwork: false
                },
                nextPage: true,
                isUnaffilliated: true,
            };
        },
        methods: {

            filterClick(data) {

                this.filters[data.type] = data.key;
                this.filters.Page = 0;
                this.loadAuetvHandler()
              
            },

            clearFilter(type) {
                this.filters[type] = 0;
                this.filters.Page = 0;
                this.loadAuetvHandler();
            },

         async   loadAuetvHandler() {
                let self = this;
                self.$http
                    .post(`spa/auetv/list`, self.filters)
                    .then(response => {
                        if (self.filters.Page) {
                            self.aueTv = [
                                ...self.aueTv,
                                ...response.data.Data
                            ];
                            self.counter = self.aueTv.length;
                            self.nextPage = response.data.NextPage;
                        } else {
                            self.aueTv = response.data.Data;
                            self.nextPage = response.data.NextPage;
                        }

                        self.showNoResult = self.aueTv.length === 0;
                    })
                    .catch(() => { });
            },
          async  loadAuetvFiltersHandler() {
                let self = this;
                self.$http
                    .get(`spa/auetv/filters`)
                    .then(response => {

                        this.isUnaffilliated = response.data.isUnaffilliated
                        this.filtersList = response.data.filters;
                        this.filtersList.forEach(function (item) {
                            self.$set(item, "isOpen", false);
                            self.$set(self.filters, item.Key, null);
                        });
                    })
                    .catch(() => { });
            }
        },
        watch: {
            search: function () {
                this.filters.Query = this.search;
                this.filters.Page = 0;
                this.loadAuetvHandler();
            }
        },
        created: function () {
        var t1=  this.loadAuetvHandler();
        var t2=    this.loadAuetvFiltersHandler();         
            Promise.all([t1, t2]);
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";
    .aue-tv.section {
        .button.is-light.is-rounded
    {
        margin: 20px auto 0;
        padding: 20px 60px;
        border-color: #dfe0e0;
    }
    
    .columns {
            .menu-list {
            padding: 20px 0 25px;
            border-bottom: 1px solid $grey-lighter;
              li {
                 letter-spacing: 1px;
                 a {
                    padding-right: 5px;
                    padding-left: 0;
                    width: 100%;
                    text-transform:uppercase;
                    }
                }
                .delete {
                    display: none;
                    align-self: center;
                }
                .menu-label {
                    .delete {
                        display: inline-block;
                    }
                }
                .is-selected {
                    font-weight: bold;
                    .delete {
                        float: right;
                        display: block;
                        background-color: #bebebe;
                        }
                    .delete:before, .delete:after {
                        background-color: white;
                        }
                    }
                }
            }
        }
</style>
