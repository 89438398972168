import { render, staticRenderFns } from "./ViewImplementationProgramTasks.vue?vue&type=template&id=630af552&scoped=true&"
import script from "./ViewImplementationProgramTasks.vue?vue&type=script&lang=js&"
export * from "./ViewImplementationProgramTasks.vue?vue&type=script&lang=js&"
import style0 from "./ViewImplementationProgramTasks.vue?vue&type=style&index=0&id=630af552&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630af552",
  null
  
)

export default component.exports