<template>
  <section class="conference thx section">
    <div class="container">
      <div class="tickets-thx">
        <h1>Dziękujemy za zapisanie się na kongres.</h1>
        <p>Twój bilet zaraz zostanie wygenerowany</p>
      </div>
    </div>

    <div class="ticket-print" ref="printMe">
      <div class="ticket-object" v-for="(bilet, index) in html" :key="index">
        <div v-html="bilet"></div>
      </div>
    </div>
  </section>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
	name: 'ConferenceThanks',
	mixins: [global],
	components: {},
	data() {
		return {
			output: null,
			html: [],
			groupGuid: '',
		};
	},
	mounted() {
		this.getKey();
	},
	watch: {
		html: function(newVal) {
			// watch it
			let self = this;
			if (Array.isArray(newVal) && newVal.length > 0) {
				self.$nextTick(function() {
					$('.ticket-object').each(function(idx, value) {
						self.print(value);
					});
				});
			}
		},
	},
	methods: {
		async html2canvas(el, options = {}) {
			let self = this;

			// eslint-disable-next-line
			const { type } = options;

			// eslint-disable-next-line
			const canvas = await html2canvas(el, options).then(canvas => {
				self.output = canvas.toDataURL('image/jpg');

				let pdfCreator = new jsPDF();

				pdfCreator.addImage(self.output, 'JPG', 5, 5, 221, 175);
				pdfCreator.save('Kongres-bilet.pdf');
			});
		},
		getKey() {
			let self = this;
			self.resetData();
			self.groupGuid = self.$route.query.groupGuid;
			self.$http
				.get('spa/ConferenceSubscription/tickets?groupGuid=' + self.groupGuid)
				.then(response => {
					self.html = response.data;

					for (let i = 0; i < response.data.length; i++) {
						response.data[i] = response.data[i].replace(/(\r\n|\n|\r)/gm, '');
					}

					self.html = response.data;
				})
				.catch(() => {});
		},
		async print(el) {
			// add option type to get the image version
			// if not provided the promise will return
			// the canvas.
			const options = {
				type: 'dataURL',
				scale: 3,
				width: 664,
				height: 526,
			};

			await this.html2canvas(el, options);
			//let img = new Image();
			//img.src = this.output;
		},
		resetData() {
			let self = this;
			self.groupGuid = '';
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.conference.thx {
	background-color: $white;
	overflow-x: hidden;
	padding-top: 300px;

	.tickets-thx {
		border: 5px solid #007f23;
		border-radius: 15px;
		padding: 25px 30px;
		max-width: 600px;
		margin: 0 auto 200px;
		text-align: center;
		color: $green-lighter;
		font-size: 25px;
		line-height: 1.6;
	}

	.ticket-object {
		margin-bottom: 50px;
	}

	.ticket-print {
		position: absolute;
		left: -100%;
	}
}
</style>
