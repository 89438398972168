<template>
    <div class="column is-full is-flex has-text-left" v-if="users.length>0">
        <div class="container">
            <div class="card has-background-info">
                <!-- Card header -->
                <div class="card-header">
                    <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-white">
                        Twoi pracownicy, którzy uczestniczą obecnie w programach wdrożeniowych
                    </p>
                </div>
                <!-- END | Card header -->
            </div>
            <table class="table">
                <thead class="is-hidden-mobile">
                    <tr lass="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
                        <th></th>
                        <th>
                            IMIĘ I NAZWISKO
                        </th>
                        <th>
                            PROGRAM
                        </th>
                        <th>
                            PROGRES
                        </th>
                        <th>
                            Statystyki
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, index) in users"
                        :key="index">
                        <td class="has-text-black"></td>
                        <td class="has-text-black">{{user.FirstName}} {{user.LastName}}</td>
                        <td class="has-text-black">{{user.ProgramTitle}}</td>
                        <td class="has-text-black">
                            <vue-ellipse-progress :progress="user.PercentDone"
                                                  :angle="-90"
                                                  color="#6ddd8c"
                                                  emptyColor="white"
                                                  :size="65"
                                                  :thickness="8"
                                                  emptyThickness="10%"
                                                  lineMode="normal"
                                                  :legend="true"
                                                  :legendValue="0"
                                                  legendClass="legend-custom-style"
                                                  animation="reverse 700 400"
                                                  :noData="false"
                                                  :loading="false"
                                                  fontColor="black"
                                                  :half="false"
                                                  :gap="10"
                                                  dot="10 green"
                                                  fontSize="0.8rem">
                                <span slot="legend-value">%</span>
                            </vue-ellipse-progress>

                        </td>
                        <td class="has-text-black"> <button type="button" class="stats_btn" title="SPRAWDŹ MOJE STATYSTYKI" @click="goToUserStats(user.UserId)"> 
                              </button>
                        </td>
                        <td class="has-text-black"></td>
                    </tr>
                </tbody>
            </table>
            <div class="button_container">
                <button v-if="isNext" class="is-size-6" v-on:click="loadNext"> ZOBACZ WIĘCEJ</button>
            </div>
        </div>
    </div>
</template>

<script>
    // JS modules



    // Vue components
    import IconCap from '@/assets/svg/cap.svg';
    import IconStats from '@/assets/svg/buttons/stats.svg';

    export default {
        components: {
            IconCap,
            IconStats
        },

        data() {
            return {
                users: [],
                page: 1,
                pageSize: 5,
                isNext: true
            };
        },

        computed: {

        },

        methods: {

            goToUserStats(userId) {
                const url = `/panel-zarzadania/dane-uzytkownika/${userId}`;               
                this.$router.push(url);
            },

            loadData() {
                let self = this;
                self.$http
                    .get(`/spa/ImplementationPrograms/mineusersstats/${self.page}/${self.pageSize}`)
                    .then(response => {
                        self.users = [
                            ...self.users,
                            ...response.data.Data
                        ];
                        self.page = response.data.Page;
                        self.isNext = response.data.IsNext;
                    })
                    .catch((e) => { console.log(e); });
            },

            loadNext() {
                this.page++;
                this.loadData();
            }
        },

        created() {
            this.loadData();
        },

        //watch: {
        //    userId() {
        //        this.loadData();
        //    },
        //},
    };
</script>

<style lang="scss" scoped>
    .full-width {
        width: 100%;
    }

    .stats_btn{
        background: url('/img/stats.svg') no-repeat;
        width:18px;
        height:18px;
        display: block;
        margin: auto;
        border: none;
        cursor: pointer;
    }

    .container {
        margin: auto;

        .button_container{
                             background-color: #FFFFFF;
                             padding: 30px 0;
                             button
                            {
                                display: block;
                                margin: auto;
                                padding: 18px 65px;
                                background-color: #3AAB59;
                                border: none;
                                color: #FFFFFF;
                            }
                         }

        .card
        {
            height: 82px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        .card-header
        {
       
            height: 82px;
        }
        .card-header-title
            {
                font-weight: bold;
                margin: 0 60px;
            }
        }
        table{
            width: 100%;
            margin-bottom: 0;
            tr{
                  th
                    {
                        font-size: 14px;
                        color: #BCB9B6;
                    }
                    td {
                        vertical-align: middle;
                        
                        .ep-container
                        {
                            display: block;
                        }
                   }
            }
        }
    }

   

    .week {
        &-header

    {
        padding: 10px 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    &-toggler {
        background: transparent;
        border: none;
        border-radius: 5px;
        color: black;
        padding: 5px 15px;
        margin: auto;
        float: right;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        &:hover

    {
        cursor: pointer;
    }

    .chevron {
        position: relative;
        width: 25px;
        margin-left: 15px;
        color: black;
        &::before, &::after

    {
        content: '';
        position: absolute;
        top: 50%;
        background-color: black;
        width: 15px;
        height: 2px;
        border-radius: 1px;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &::before {
        right: 44%;
        transform: rotate(30deg);
    }

    &::after {
        left: 44%;
        transform: rotate(-30deg);
    }

    &.active {
        &::before

    {
        transform: rotate(-30deg);
    }

    &::after {
        transform: rotate(30deg);
    }

    }
    }
    }
    }
</style>
