<template>
	<section class="EmployeesTable section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<!-- Filters -->
					<aside class="menu">
						<div v-for="(filterType, key) in filtersList" :key="key">
							<b-collapse
								:open.sync="filterType.isOpen"
								v-if="
									filterType.Type === 'select' &&
									filterType.Key !== 'SortBy' &&
									filterType.Key !== 'ShopNumber'
								"
							>
								<p
									class="menu-label is-size-7 has-text-black has-border-bottom"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<ul class="menu-list">
										<li
											class="is-flex"
											:class="{
												'is-selected': filters[filterType.Key] === filter.Key,
											}"
											v-for="filter in filterType.Options"
											:key="filter.Key"
										>
											<a
												@click="
													(filters[filterType.Key] = filter.Key),
														(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
												class="is-size-7"
												>{{ filter.Value }}</a
											>
											<button
												class="delete is-small"
												@click="
													clearFiltersHandler(
														filterType.Key,
														activeTab === 0 ? loadWorkersHandler : null
													)
												"
											></button>
										</li>
									</ul>
								</div>
							</b-collapse>
						</div>
					</aside>
					<!-- END | Filters -->
				</div>
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<!-- Button 'Dodaj pracownika' -->
						<div class="column is-full">
							<div class="columns is-variable is-3 actions">
								<div class="column is-half is-offset-half">
									<div class="columns">
										<div
											class="column is-12-tablet is-8-desktop is-offset-4-desktop is-6-widescreen is-offset-6-widescreen"
										>
											<button
												@click="openModal"
												class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
											>
												<span class="icon is-small">
													<AddIcon />
												</span>
												<span>Dodaj pracownika</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- END | Button 'Dodaj pracownika' -->

						<!-- Table -->
						<div class="column is-full is-block has-text-centered">
							<div class="card-table card-table-has-expand">
								<!-- Search input field -->
								<div class="card is-hidden-mobile">
									<div class="card-header">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input
													class="input is-size-4"
													type="text"
													v-model="filters.SearchPhrase"
													:placeholder="'Szukaj pracownika'"
													@change="$emit('update:search', $event.target.value)"
												/>
												<span class="icon is-small is-left">
													<LoupeIcon />
												</span>
											</div>
										</div>
									</div>
								</div>
								<!-- END | Search input field -->

								<table class="table table-workers">
									<!-- Table header -->
									<thead class="is-hidden-mobile">
										<!-- Headings -->
										<tr
											class="is-size-18 is-uppercase has-text-weight-semibold has-text-left valign-cells-middle"
										>
											<th class="has-text-grey-light">Imię</th>
											<th class="has-text-grey-light">Nazwisko</th>
											<th class="has-text-grey-light">Status</th>
											<th class="has-text-grey-light">Stanowisko</th>
											<th class="has-text-grey-light">Data logowania</th>
											<th class="has-text-grey-light">Zarządzaj</th>
										</tr>
										<!-- END | Headings -->

										<!-- Progress bar (loading indicator) -->
										<tr v-if="loading">
											<th colspan="6" class="is-progress-bar-table">
												<progress
													class="progress is-primary"
													max="100"
												></progress>
											</th>
											<!-- END | Progress bar (loading indicator) -->
										</tr>
									</thead>
									<!-- END | Table header -->

									<tbody v-if="workersList.length">
										<!-- Status legend -->
										<tr class="statuses-legend">
											<td class="has-text-black is-size-18" colspan="6">
												<div class="is-flex">
													<i
														class="is-dot is-inline-block has-background-info"
													></i>
													<span>Aktywny</span>

													<i
														class="is-dot is-inline-block has-background-warning"
													></i>
													<span>Dodany</span>

													<i
														class="is-dot is-inline-block has-background-danger"
													></i>
													<span>Uczestnik warsztatu</span>
												</div>
											</td>
										</tr>
										<!-- END | Status legend -->

										<tr
											class="employee-row is-size-17 has-text-left has-text-weight-semibold valign-cells-middle"
											v-for="(worker, key) in workersList"
											:key="key"
											:class="{ 'is-active': worker.isOpen }"
										>
											<!-- First name -->
											<td>
												<div class="is-dotted is-block">
													<span
														class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Imię</span
													>
													<span>{{ worker.FirstName }}</span>
												</div>
											</td>
											<!-- END | First name -->

											<!-- Last name -->
											<td>
												<div class="is-dotted is-block">
													<span
														class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Nazwisko</span
													>
													<span>{{ worker.LastName }}</span>
												</div>
											</td>
											<!-- END | Last name -->

											<!-- Status -->
											<td>
												<div class="is-dotted is-flex has-text-centered">
													<span
														class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Status</span
													>
													<i
														:class="[
															'is-dot',
															'is-inline-block',
															'is-hidden-mobile',
															getWorkerStatusClass(worker.Status),
														]"
													></i>
													<span class="is-hidden-tablet">{{
														worker.Status
													}}</span>
												</div>
											</td>
											<!-- END | Status -->

											<!-- Position -->
											<td>
												<div class="is-dotted is-block">
													<span
														class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Stanowisko</span
													>
													<span>{{ worker.WorkPosition }}</span>
												</div>
											</td>
											<!-- END | Position -->

											<!-- Last log-in -->
											<td>
												<div class="is-dotted is-block">
													<span
														class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
														>Ostatnie logowanie</span
													>
													<span>{{ worker.LastLoginDate }}</span>
												</div>
											</td>
											<!-- END | Last log-in -->

											<!-- Action button -->
											<td>
												<div
													class="is-dotted is-block has-text-centered has-text-left-tablet"
												>
													<router-link
														:to="{
															name: 'Pracownik sklepu',
															params: {
																shopNumber: $route.params.shopNumber,
																id: worker.Id,
															},
														}"
														class="has-text-weight-semibold has-text-left is-clickable"
														:class="{
															'has-text-white': worker.isOpen,
															'has-text-primary': !worker.isOpen,
														}"
														>Zobacz statystyki i profil >></router-link
													>
												</div>
											</td>
											<!-- END | Action button -->
										</tr>
									</tbody>

									<!-- Loading / empty table indicator -->
									<tbody v-else>
										<tr>
											<td
												colspan="6"
												class="is-size-17 has-text-centered has-text-weight-semibold"
											>
												{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
											</td>
										</tr>
									</tbody>
									<!-- END | Loading / empty table indicator -->
								</table>
							</div>

							<!-- Load more button -->
							<a
								class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
								@click="
									filters.Page++;
									loadWorkersHandler();
								"
								v-if="nextPage"
								>Załaduj więcej treści</a
							>
							<!-- END | Load more button -->
						</div>
						<!-- END | Table -->
					</div>
				</div>
			</div>
		</div>

		<!-- Mobile filters -->
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ
						<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li v-for="(filterType, key) in filtersList" :key="key">
							<div
								v-if="
									filterType.Type === 'select' &&
									filterType.Key !== 'SortBy' &&
									filterType.Key !== 'ShopNumber'
								"
							>
								<b-collapse :open.sync="filterType.isOpen">
									<span
										class="has-arrow is-flex"
										slot="trigger"
										:class="{ 'is-active': filterType.isOpen }"
									>
										<span
											class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
											>{{ filterType.Title }}</span
										>
									</span>
									<div>
										<div class="navbar-content">
											<ul>
												<li
													class="is-flex has-text-left"
													:class="{
														'is-selected':
															filters[filterType.Key] === filter.Key,
													}"
													v-for="filter in filterType.Options"
													:key="filter.Key"
												>
													<a
														@click="
															(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																activeTab === 0 ? loadWorkersHandler() : null
														"
														>{{ filter.Value }}</a
													>
													<button
														class="delete is-small"
														@click="
															clearFiltersHandler(
																filterType.Key,
																activeTab === 0 ? loadWorkersHandler : null
															)
														"
													></button>
												</li>
											</ul>
										</div>
									</div>
								</b-collapse>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
		<!-- END | Mobile filters -->

		<b-modal
			:active.sync="isAddWorkerModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isAddWorkerModalActive = false"
			></button>
			<form>
				<div class="columns is-multiline">
					<div class="column is-full">
						<h2 class="is-size-1 has-text-left has-text-black">
							Dodaj pracownika
						</h2>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{
										'has-error': this.$v.formData.newWorker.ShopNumber.$error,
									}"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											v-model="formData.newWorker.ShopNumber"
											@search="searchShopHandler"
											:options="shopList"
											placeholder="Kod sklepu"
											label="Name"
											:disabled="true"
										>
											<span slot="no-options" class="has-text-left"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.ShopNumber
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Imię',
										height: 56,
										hasContent: formData.newWorker.FirstName ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.FirstName.$error,
									}"
								>
									<input
										v-model="formData.newWorker.FirstName"
										@blur="$v.formData.newWorker.FirstName.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.FirstName
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Nazwisko',
										height: 56,
										hasContent: formData.newWorker.LastName ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.LastName.$error,
									}"
								>
									<input
										v-model="formData.newWorker.LastName"
										@blur="$v.formData.newWorker.LastName.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.LastName
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div
							class="field"
							v-if="
								currentNetwork == 'Detal+' ||
								currentNetwork == 'Eurocash' ||
								currentNetwork == 'CashAndCarry'
							"
						>
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer SAP',
										height: 56,
										hasContent: formData.newWorker.SapNo ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.SapNo.$error,
									}"
								>
									<input
										v-model="formData.newWorker.SapNo"
										@blur="$v.formData.newWorker.SapNo.$touch()"
										type="text"
										class="input"
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.SapNo
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Adres e-mail',
										height: 56,
										hasContent: formData.newWorker.Email ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Email.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Email"
										@blur="$v.formData.newWorker.Email.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Email
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{
										'has-error': this.$v.formData.newWorker.WorkPosition.$error,
									}"
								>
									<div>
										<v-selector
											label="Value"
											v-model="formData.newWorker.WorkPosition"
											:options="workPositionsList"
											placeholder="Stanowisko"
											:max-height="'300px'"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.WorkPosition
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer telefonu',
										height: 56,
										hasContent: formData.newWorker.Phone ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Phone.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Phone"
										@blur="$v.formData.newWorker.Phone.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Phone
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<a
							href="#"
							@click.prevent="submitAddFormHandler"
							class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
							:class="{ 'is-loading': loading }"
						>
							<span class="icon is-small">
								<svg width="17" height="20" viewBox="0 2 17 16">
									<use :xlink:href="'/assets/2018/svg/buttons/add.svg#add'" />
								</svg>
							</span>
							<span>Dodaj pracownika</span>
						</a>
						<p
							class="is-size-8 has-text-weight-light has-text-danger has-text-centered is-block"
							v-if="errorMsg"
						>
							{{ errorMsg }}
						</p>
						<p
							class="is-size-8 has-text-weight-light has-text-primary has-text-centered is-block"
							v-if="successMsg"
						>
							{{ successMsg }}
						</p>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script>
import { debounce } from 'lodash';
import global from '@/mixins/global.js';
import { required, email, numeric } from 'vuelidate/lib/validators';
import FloatingLabel from '@/components/BaseFloatingLabel';
import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';

export default {
	name: 'EnployeesTable',
	mixins: [global],

	components: {
		FloatingLabel,
		AddIcon,
		LoupeIcon,
		CogWheelIcon,
	},

	props: {
		search: {
			type: String,
		},
	},

	data() {
		return {
			workersList: [],
			statsList: [],
			filtersList: [],
			shopList: [],
			statusList: [
				{ Key: 'OCZEKUJE NA AKCEPTACJE', Value: 0 },
				{ Key: 'AKTYWNY', Value: 1 },
				{ Key: 'NIEAKTYWNY', Value: 2 },
				{ Key: 'USUNIĘTY', Value: 3 },
			],
			workPositionsList: [],
			filters: {
				Page: 0,
				ShopNumber: null,
				SearchPhrase: '',
			},
			formData: {
				editWorker: {
					Id: null,
					Network: '',
					FirstName: '',
					LastName: '',
					WorkPosition: '',
					Email: '',
					Phone: '',
					ShopNumber: '',
					StatusId: null,
					SapNo: null,
				},
				newWorker: {
					ShopNumber: '',
					FirstName: '',
					LastName: '',
					Email: '',
					WorkPosition: '',
					Phone: '',
					SapNo: null,
				},
			},
			nextPage: true,
			isAddWorkerModalActive: false,
			isDeleteWorkerModalActive: false,
			isChangeShopWorkerModalActive: false,
			beforeDeltetedStatus: {},
			errorMsg: '',
			successMsg: '',
			loading: false,
			activeTab: 0,
			currentNetwork: this.$store.state.currentUser.data.Network,
		};
	},

	validations: {
		formData: {
			editWorker: {
				FirstName: {
					required,
				},
				LastName: {
					required,
				},
				WorkPosition: {
					required,
				},
				Phone: {
					numeric,
				},
				Email: {
					email,
					required,
				},
				ShopNumber: {
					required,
				},
				StatusId: {
					required,
				},
				SapNo: {},
			},
			newWorker: {
				ShopNumber: {
					required,
				},
				FirstName: {
					required,
				},
				LastName: {
					required,
				},
				Email: {
					required,
					email,
				},
				WorkPosition: {
					required,
				},
				Phone: {
					numeric,
				},
				SapNo: {},
			},
		},
		editWorkerValidationGroup: [
			'formData.editWorker.FirstName',
			'formData.editWorker.LastName',
			'formData.editWorker.WorkPosition',
			'formData.editWorker.Phone',
			'formData.editWorker.ShopNumber',
			'formData.editWorker.StatusId',
			'formData.editWorker.SapNo',
		],
		newWorkerValidationGroup: [
			'formData.newWorker.ShopNumber',
			'formData.newWorker.FirstName',
			'formData.newWorker.LastName',
			'formData.newWorker.Email',
			'formData.newWorker.WorkPosition',
			'formData.newWorker.Phone',
			'formData.newWorker.SapNo',
		],
	},

	methods: {
		/**
		 * @description Get the proper classname corresponding with the worker status.
		 * @param {Object} workerStatus The actual status of the worker.
		 * @returns {String} The classname describing the background colour of the status dot.
		 */
		getWorkerStatusClass(workerStatus) {
			switch (workerStatus) {
				case 'AKTYWNY':
					return 'has-background-info';
					break;

				case 'DODANY':
					return 'has-background-warning';
					break;

				case 'UCZESTNIK WARSZTATU':
					return 'has-background-danger';
					break;

				default:
					break;
			}
		},

		loadWorkersHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/managment/shopworkers/list`, {
					...self.filters,
					ShopNumber: this.$route.params.shopNumber,
				})
				.then((response) => {
					let data = response.data.Data;
					data.forEach((item) => ((item.isOpen = false), (item.edit = false)));
					if (self.filters.Page) {
						self.workersList = [...self.workersList, ...data];
						self.nextPage = response.data.NextPage;
					} else {
						self.workersList = data;
						self.nextPage = response.data.NextPage;
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		loadWorkersFiltersHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/shopworkers/filters`)
				.then((response) => {
					this.filtersList = response.data;
					this.filtersList.forEach(function (item) {
						self.$set(item, 'isOpen', false);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},

		searchShopHandler(search, loading) {
			loading(true);
			this.loadShopsHandler(search, loading, this);
		},
		loadShopsHandler: _.debounce((search, loading, self) => {
			self.$http
				.post(`/spa/managment/shopworkers/getshopdictionary`, {
					Query: search,
					Network:
						self.$store.state.user.Workposition === 'Trener'
							? self.formData.editWorker.Network
							: null,
				})
				.then((response) => {
					self.shopList = response.data;
					loading(false);
				});
		}, 250),
		setWorkerHandler(worker) {
			this.$v.$reset();
			this.workersList.forEach((i) => (i.edit = false));
			worker.edit = true;

			this.formData.editWorker.Id = worker.Id;
			this.formData.editWorker.FirstName = worker.FirstName;
			this.formData.editWorker.LastName = worker.LastName;
			this.formData.editWorker.WorkPosition = this.workPositionsList.find(
				(i) => i.Value === worker.WorkPosition
			);
			this.formData.editWorker.Phone = worker.Phone;
			this.formData.editWorker.ShopNumber = worker.ShopNumber;
			this.formData.editWorker.StatusId = this.statusList.find(
				(i) => i.Value === worker.StatusId
			);
			this.formData.editWorker.Network = worker.Network;
			this.formData.editWorker.Email = worker.Email;
			this.formData.editWorker.SapNo = worker.SapNo;
		},
		submitEditFormHandler: function (worker, reload, moveWorker) {
			let self = this,
				submitForm = (worker, reload) => {
					let formData = { ...this.formData.editWorker };
					formData.WorkPosition = this.formData.editWorker.WorkPosition.Value;
					formData.StatusId = this.formData.editWorker.StatusId.Value;
					formData.ShopNumber = this.formData.editWorker.ShopNumber.ShopNumber;
					self.loading = true;
					this.$http
						.post(`/spa/managment/shopworkers/edit`, formData)
						.then((response) => {
							self.loading = false;
							self.errorMsg = '';
							self.successMsg = response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.successMsg = '';
								if (worker) {
									for (let prop of Object.getOwnPropertyNames(worker))
										formData[prop] ? (worker[prop] = formData[prop]) : null;
									worker.edit = false;
									worker.Status = self.formData.editWorker.StatusId.Key;
								}
							}, 1000);
							if (reload) {
								self.filters.Page = 0;
								self.loadWorkersHandler();
							}
						})
						.catch((error) => {
							self.loading = false;
							self.successMsg = '';
							self.errorMsg = error.response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.errorMsg = '';
							}, 3000);
						});
				};
			self.$v.$touch();

			if (!self.$v.editWorkerValidationGroup.$invalid) {
				if (
					typeof self.formData.editWorker.ShopNumber === 'object' &&
					!moveWorker
				) {
					self.isChangeShopWorkerModalActive = true;
				} else {
					if (self.isDeleteWorkerModalActive) {
						submitForm(worker, reload);
					} else if (!self.$v.formData.editWorker.Email.$invalid) {
						submitForm(worker, reload);
					}
				}
			}
		},
		submitAddFormHandler: function () {
			let self = this;
			self.$v.$touch();

			if (!this.$v.newWorkerValidationGroup.$invalid) {
				let formData = { ...this.formData.newWorker };
				formData.WorkPosition = this.formData.newWorker.WorkPosition.Value;

				self.loading = true;

				this.$http
					.post(`/spa/managment/shopworkers/add`, formData)
					.then((response) => {
						self.loading = false;
						self.errorMsg = '';
						self.successMsg = response.data.Message;
						for (let prop of Object.getOwnPropertyNames(
							self.formData.newWorker
						))
							self.formData.newWorker[prop] = '';
						self.$v.$reset();
						setTimeout(() => {
							self.successMsg = '';
							self.filters.Page = 0;
							self.loadWorkersHandler();
							self.isAddWorkerModalActive = false;
						}, 3000);
					})
					.catch((error) => {
						self.loading = false;
						self.successMsg = '';
						setTimeout(() => {
							self.errorMsg = '';
						}, 3000);
						self.errorMsg = error.response.data.Message;
					});
			}
		},

		loadWorkPositionsList() {
			this.$http
				.get(`spa/managment/shopworkers/getworkpositionsdictionary`)
				.then((response) => {
					this.workPositionsList = response.data;
				});
		},

		openModal() {
			this.isAddWorkerModalActive = true;

			// Set the $dirty flag of the Vuelidate validation model
			// to 'false', recursively
			this.$v.$reset();

			// Load the current shop's number.
			this.formData.newWorker.ShopNumber = this.$route.params.shopNumber;
		},
	},

	watch: {
		search: function () {
			this.filters.SearchPhrase = this.search;
			this.filters.Page = 0;
			if (this.activeTab === 0) this.loadWorkersHandler();
		},
		'formData.editWorker.StatusId': function (newVal, oldVal) {
			this.beforeDeltetedStatus = oldVal;
			if (this.formData.editWorker.StatusId.Value === 3) {
				this.isDeleteWorkerModalActive = true;
			}
		},
	},

	created: function () {
		this.loadWorkersHandler();
		this.loadWorkersFiltersHandler();
		this.loadWorkPositionsList();
	},

	activated: function () {
		switch (this.$route.params.dialog) {
			case 'dodaj-pracownika':
				this.isAddWorkerModalActive = true;
				break;
			case 'statystyki':
				this.activeTab = 1;
				break;
		}

		this.loadWorkersHandler();
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Vertically align the content
// in <td>s within the selected <tr>.
.valign-cells-middle {
	& > td,
	& > th {
		vertical-align: middle;
	}
}

// Hide the dots for the cell with the
// "Lista pracowników >>" router-link on mobile.
td:nth-last-child(2) .is-dotted::before {
	@include mobile {
		display: none !important;
	}
}

.employee-row {
	&:nth-child(2) {
		border-top: 1px solid #dfe0e0;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #dfe0e0;
	}

	transition: all 300ms ease-in-out;

	// Remove the globally defined green borders.
	td {
		border: none !important;
	}

	@include tablet {
		// Remove the white background from the <span>s with text content.
		span,
		a {
			background: transparent;
		}
	}
}

.navbar-content {
	li.is-flex {
		transition: all 200ms ease-in-out;

		.delete {
			margin: auto 0;
			transition: all 200ms ease-in-out;

			&::before,
			&::after {
				transition: all 200ms ease-in-out;
			}
		}

		&:not(.is-selected) {
			.delete {
				&::before,
				&::after {
					display: none;
				}
			}
		}

		&.is-selected {
			font-weight: bold;
			.delete {
				float: right;
				display: block;
				background-color: #bebebe;
			}
			.delete:before,
			.delete:after {
				background-color: $white;
			}
		}
	}
}

.EmployeesTable {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}

	.button.is-info {
		height: 50px;
	}

	.container {
		& > :first-child {
			& > :nth-child(2) {
				@include desktop {
					margin-top: -100px;
				}
			}
		}
	}

	.filters {
		.menu-list {
			.filter-input {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
		}
	}

	.modal {
		& > :nth-child(2) {
			width: 830px;
			padding: rem(40px);
			overflow: auto;
			@include widescreen {
				overflow: visible;
			}
		}

		.field {
			margin-bottom: rem(30px);
			.control {
				.input__container,
				.input-container {
					&.has-error {
						margin-bottom: 0;
					}

					& + .has-text-danger {
						margin-top: 0;
					}
				}
			}
		}

		.field + .button {
			color: rgba(58, 171, 89, 0.5);
			height: 56px;
			width: 100%;
			max-width: none;

			.icon {
				margin-top: 4px;
			}

			span {
				color: $white;
			}
		}

		.button {
			color: rgba(58, 171, 89, 0.5);
			border-radius: 5px;
			height: 50px;
			width: 100%;

			&:not(:last-child) {
				margin-bottom: rem(20px);
			}

			span {
				color: white;
			}

			.icon {
				margin-right: 5px;
			}

			&.is-danger {
				color: rgba(215, 33, 65, 0.5);
			}
		}
	}
}

.statuses-legend {
	> td {
		padding: 10px !important;

		.is-flex > :nth-child(even) {
			margin-right: rem(40px);
		}
	}
}
</style>
