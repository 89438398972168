<template>
	<main class="container">
		<!-- Heading -->
		<div class="columns">
			<div class="column">
				<h1
					class="title is-size-1 has-text-weight-light has-text-left has-text-shadow"
				>
					Programy wdrożeniowe check
				</h1>
			</div>
		</div>
		<!-- END | Heading -->

		<!-- Banner -->
		<section class="__banner is-hidden-mobile columns is-marginless">
			<div class="column is-paddingless">
				<img v-lazy="banner.src" :alt="banner.alt" />
			</div>
		</section>
		<!-- END | Banner -->

		<!-- Sets -->
        <ImplementationPrograms v-if="typeof (implementationProgramSet) !== 'undefined' && implementationProgramSet!== null"
                          :title="implementationProgramSet.Title"
                          :implementationPrograms ="implementationProgramSet.ImplementationPrograms ">
        </ImplementationPrograms>
		<Set
			v-for="set in sets"
			v-if="sets.length > 0"
			:key="set.Title"
			:title="set.Title"
			:sets="set.Paths"
		>
		</Set>
		<!-- END | Sets -->

		<BaseModalInfo v-bind="modalInfo" />
</main>
</template>

<script>
    import Set from '@/components/Containers/ViewWorkshopSets/Set.vue';
    import ImplementationPrograms from '@/components/Containers/ViewWorkshopSets/ImplementationPrograms.vue';
import BaseModalInfo from '@/components/BaseModalInfo.vue';

export default {
	components: {
        Set,
        ImplementationPrograms,
		BaseModalInfo,
	},

	data() {
		return {
			// Array containing the workshop sets.
            sets: [],
            //Set of 
            implementationProgramSet: null,

			// Object describing the top banner.
			banner: {
				src: '',
				alt: '',
			},

			// This property indicates whether the middle section is to be rendered.
			isDc: true,

			// This object is bind to the BaseModalInfo component.
			modalInfo: {
				message: '',
				buttonLabel: '',
				isActive: false,
			},
		};
	},

	methods: {
		/**
		 * @description This method will try to fetch the workshop sets from the API.
		 * If it succeeds, the sets will be printed. If it fails, there will be shown the info modal.
		 */
        getSets() {

            this.$http
                .get('/spa/implementationPrograms')
                .then(response => {
                    this.banner.src = response.data.Banner;
                    this.implementationProgramSet = response.data;
                })
                .catch(() => {
                    this.openModalInfo();
                });
		},

		/**
		 * @description This method will open the modal with the information about the failed sets fetch action.
		 */
		openModalInfo() {
			this.modalInfo = {
				...this.modalInfo,
				message: 'Nie udało się pobrać zestawów. Spróbuj ponownie później.',
				buttonLabel: 'OK',
				isActive: true,
			};
		},

		/**
		 * @description This method will close the info modal and clear its data.
		 */
		closeModalInfo() {
			this.modalInfo = {
				...this.modalInfo,
				message: '',
				buttonLabel: '',
				isActive: false,
			};
		},
	},

	beforeMount() {
		this.getSets();
	},
};
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

main.container {
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 21px;

	@include desktop {
		padding-left: 0;
		padding-right: 0;
		padding-top: 26px;
	}
}

.__banner {
	img {
		width: 100%;

		margin-bottom: 50px;
		@include tablet {
			margin-bottom: 57px;
		}

		// Set the fixed height for the image when it's loading or when it has failed to load.
		&[lazy='error'],
		&[lazy='loading'] {
			height: 200px;
		}

		// Correctly loaded image can set its dimensions by itself (no fixed height).
		&[lazy='loaded'] {
			height: auto;
		}
	}
}
</style>
