export default {
    methods: {
        submitResignFormHandler() {
            let self = this;
            self.$http
                .post(`spa/homepage/workshopresign`, { WorkshopId: self.workshopId })
                .then(response => {
                    self.loadWorkshopsHandler();
                    self.successMsg = response.data.Message;
                    setTimeout(() => {
                        self.isResignModalActive = false;
                        self.successMsg = "";
                    }, 3000);
                })
                .catch(() => { });
        },
        loadWorkshopsHandler() {
            let self = this;
            self.$http
                .get(`spa/homepage/dashboard`)
                .then(response => {
                    self.userData = response.data;
                })
                .catch(() => { });
        }
    }
}
