<template>
  <div class="card" @click="$emit('goToUrlHandler')">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-grey-light">Konferencja</p>
          <h2 class="title is-size-3 has-text-weight-bold has-text-left has-text-primary">24 września 2019 <br />IV Kongres Przedsiębiorców Polskiego Handlu</h2>
          <div class="banner-conf-txt">
              <p>Zarezerwuj już tę datę w kalendarzu !</p>
              <br />
              <p>Temat przewodni: <span class="is-uppercase has-text-weight-bold">Krok w przyszłość. Droga do nowoczesnego przedsiębiorstwa</span></p>
              <p class="has-text-weight-bold">Atlas Arena, Łódź</p>
              <br />
              <p>Szczegóły i program Kongresu już wkrótce!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCardBackgroundImage"
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.card {
  background-image: url("/assets/2018/images/conferences-visual-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40%;
  background-size: 100%;
  background-position: bottom;
  cursor: pointer;
  @include tablet {
    background-image: url("/assets/2018/images/conferences-visual.jpg");
    padding-bottom: 0;
    background-size: cover;
    background-position: center;
  }
  &-content {
    padding: rem(35px 20px);
    @include tablet {
      padding: rem(35px 40px);
    }
  }
  h2 {
    margin-top: 1rem;
    padding: 0;
    margin-left: 0;
    margin-bottom: 0;
    font-weight: normal;
    @include tablet {
      max-width: 40%;
    }
  }
  .banner-conf-txt {
      @include tablet {
      max-width: 40%;
      }

      span {
        color: $green;
        display: block;
      }
  }
}
</style>