var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"NameBox card"},[_c('div',{staticClass:"card-content is-paddingless"},[_c('div',{staticClass:"columns is-marginless"},[_c('div',{staticClass:"NameBox__username column is-one-quarter"},[_c('PersonOvalIcon'),_c('h1',[_c('span',{staticClass:"NameBox__username-work-title"},[_vm._v(_vm._s(_vm.workTitle))]),_c('br'),_c('span',{staticClass:"NameBox__username-name"},[_vm._v(_vm._s((_vm.userFirstName + " " + _vm.userLastName)))])])],1),_c('dl',{staticClass:"NameBox__data column"},[_c('Field',{ref:"FirstName",attrs:{"value":_vm.userData.FirstName
							? _vm.userData.FirstName
							: _vm.editableFields.includes('FirstName')
							? 'podaj imię'
							: '',"is-grayed-out":_vm.userData.FirstName
							? false
							: _vm.editableFields.includes('FirstName')
							? true
							: false,"name":"Imię","is-editable":_vm.isFieldEditable('FirstName'),"id":"FirstName","is-clickable":false}}),_c('Field',{ref:"LastName",attrs:{"value":_vm.userData.LastName
							? _vm.userData.LastName
							: _vm.editableFields.includes('LastName')
							? 'podaj nazwisko'
							: '',"is-grayed-out":_vm.userData.LastName
							? false
							: _vm.editableFields.includes('LastName')
							? true
							: false,"name":"Nazwisko","is-editable":_vm.isFieldEditable('LastName'),"id":"LastName","is-clickable":false}}),_c('Field',{ref:"Email",attrs:{"value":_vm.userData.Email
							? _vm.userData.Email
							: _vm.editableFields.includes('Email')
							? 'podaj adres e-mail'
							: '',"is-grayed-out":_vm.userData.Email
							? false
							: _vm.editableFields.includes('Email')
							? true
							: false,"name":"Adres e-mail","is-editable":_vm.isFieldEditable('Email') && _vm.userData.Email.length === 0,"id":"Email","is-clickable":_vm.userData.Email ? false : _vm.isDataEditable ? false : true},nativeOn:{"click":function($event){return _vm.$emit('email-address-click')}}}),_c('Field',{ref:"Phone",attrs:{"value":_vm.userData.Phone
							? _vm.userData.Phone
							: _vm.editableFields.includes('Phone')
							? 'podaj numer telefonu'
							: '',"is-grayed-out":_vm.userData.Phone
							? false
							: _vm.editableFields.includes('Phone')
							? true
							: false,"name":"Numer telefonu","is-editable":_vm.isFieldEditable('Phone'),"id":"Phone","is-clickable":false}}),_c('Field',{attrs:{"value":_vm.get('WorkTitle'),"name":"Stanowisko","is-editable":_vm.isFieldEditable('WorkTitle'),"id":"WorkTitle"}}),_c('Field',{attrs:{"value":_vm.get('Status'),"name":"Status","is-editable":_vm.isFieldEditable('Status'),"id":"Status"}}),_c('Field',{attrs:{"value":_vm.get('Network'),"name":"Sieć","is-editable":_vm.isFieldEditable('Network'),"id":"Network"}}),(_vm.shouldShowShopDetails)?_c('Field',{attrs:{"value":_vm.get('ShopNumber'),"name":"Kod sklepu","is-editable":_vm.isFieldEditable('ShopNumber'),"id":"ShopNumber"}}):_vm._e(),(_vm.shouldShowShopDetails)?_c('Field',{attrs:{"value":_vm.get('ShopName'),"name":"Adres sklepu","is-editable":_vm.isFieldEditable('ShopName'),"id":"ShopName"}}):_vm._e(),(
						!_vm.isUser([
							'ShopManager',
							'ShopOwner',
							'TrainerUser',
							'NetworkAdminUser',
							'PartnerAdminUser',
							'KOUser',
							'RDOUser',
							'CAUser' ])
					)?_c('Field',{attrs:{"value":_vm.supervisor,"name":"Przełożony","is-editable":_vm.isFieldEditable('Supervisor'),"id":"Supervisor"}}):_vm._e(),(
						!_vm.isUser([
							'NetworkAdminUser',
							'TrainerUser',
							'PartnerAdminUser',
							'KOUser',
							'RDOUser',
							'CAUser' ])
					)?_c('Field',{attrs:{"value":_vm.get('Id'),"name":"Numer ID pracownika","is-editable":_vm.isFieldEditable('Id'),"id":"Id"}}):_vm._e(),(_vm.userData.Network == 'Detal+' ||
					   _vm.userData.Network == 'Eurocash' ||
					   _vm.userData.Network == 'CashAndCarry')?_c('Field',{attrs:{"value":_vm.get('SapNo'),"name":"Sap","id":"Sap"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }