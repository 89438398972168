<template>
  <main>
    <BaseHero :title="'Warsztaty'"/>
    <SpecialOffer/>
    <WorkshopsUser/>
    <router-link class="all-workshops" to="/warsztaty-tematy">Wszystkie dostępne warsztaty</router-link>
    <BaseHeroSearch :title="'Lista warsztatów w twojej okolicy'" :placeholder="'Szukaj warsztatu'" :search.sync="search" :showFilters.sync="showFilters" :hasFilters="true" :hasSearch="true"/>
    <Workshops :search.sync="search" :showFilters.sync="showFilters"/>
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import BaseHeroSearch from "@/components/BaseHeroSearch.vue";
import Workshops from "@/components/Containers/ViewWorkshops/Workshops.vue";
import WorkshopsUser from "@/components/Containers/ViewWorkshops/WorkshopsUser.vue";
import SpecialOffer from "@/components/Containers/ViewSpecialOffer/SpecialOffer.vue";

export default {
  name: "ViewWorkshops",
  components: {
    BaseHero,
    BaseHeroSearch,
    Workshops,
    WorkshopsUser,
    SpecialOffer
  },
  data() {
    return {
      search: "",
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.all-workshops {
    max-width: 90%;
    margin: 3rem auto 0;
    display: block;
    border: 2px solid $green-lighter;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 15px;
    text-align: center;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
    letter-spacing: 0.5px;
    &:hover {
        background-color: $green-lighter;
        color: $white;
    }

    @include tablet {
        width: 670px;
    }
}

</style>
