<template>
    <section v-if="IsAllowToSee && (user.Network.toLowerCase().indexOf('eurocash') !== -1 || user.Network.toLowerCase().indexOf('cashandcarry') !== -1)"
             class="special-offer"> 
        <div v-if="catalogues[0] != null" 
             class="container">
            <header class="special-offer-header">
                <h2 class="is-size-3 has-text-weight-light has-text-white">
                    Oferta specjalna
                </h2>

                <div class="catalogues-container"
                     style="display: flex">
                    <div v-for="catalogue in catalogues"
                         class="catalogue-link">
                        <a :href="catalogue.Catalogue" target="_blank"> <img :src="catalogue.Banner" /> </a>
                    </div>
                </div>
            </header>
            <div class="columns">
                <div class="column is-6 special-col" v-for="special in specialOffers" :key="special.Id">
                    <a :href="'/home/warsztaty/'+special.Id" class="card single-special-offer">
                        <div class="special-offer-bck">
                            <img v-lazy="special.Image" :alt="special.Title" :title="special.Title" />
                        </div>
                        <div class="special-offer-content">
                            <p class="special-offer-title is-uppercase has-text-weight-bold has-text-left has-text-primary-hover" v-html="special.Title"></p>
                            <p class="special-offer-city is-size-8 has-text-left has-text-grey-light-hover"><PinIcon width="20" height="20" viewBox="0 -8 23 24" />{{ special.Localization }}</p>
                            <p class="special-offer-desc has-text-weight-light has-text-left" v-text="descriptionLimited(special.SubTitle)">
                                Jest dostępnych wiele różnych wersji Lorem Ipsum, ale większość zmieniła się pod wpływem dodanego humoru czy przypadkowych słów.
                            </p>
                            <RightArrowSmallIcon class="right-arrow-small-icon" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
    import PinIcon from "@/assets/svg/pin.svg";
    import global from "@/mixins/global.js";
    import { state } from "../../../store-modules/screenedUser";
    import { mapState } from 'vuex';

    export default {
        name: "SpecialOffer",
        mixins: [global],
        components: {
            RightArrowSmallIcon,
            PinIcon,
        },
        data() {
            return {
                specialOffers: [],
                IsAllowToSee: false,
                catalogues: {
                    catalogue: {
                        IsCatalogue: false,
                        Banner: '',
                        Catalogue: ''
                    }
                }
            }
        },
        methods: {
            loadSpecialOffers() {
                let self = this;
                self.$http
                    .get(`spa/Workshop/getspecialoffers`)
                    .then(response => {
                        {
                            self.specialOffers = [...self.specialOffers, ...response.data.Data];
							self.IsAllowToSee = response.data.IsAllowToSee;
                        }
                    })
                    .catch(() => { });
            },

            descriptionLimited(subtitle) {
                if (subtitle) {
                    if (subtitle.length > 140) {
                        return subtitle.substring(0, 138) + '...'
                    } else {
                        return subtitle;
                    }
                } else {
                    return '';
                }
            }
        },

        created: function () {
			this.loadSpecialOffers();
        },

        computed: {
            ...mapState('currentUser', {
                user: state => state.data,
            }),
            columnClasses: function () {
                return {
                    column: this.Wcatalogue.IsCatalogue,
                    'is-6': this.Wcatalogue.IsCatalogue,
                    'special-col': this.Wcatalogue.IsCatalogue,
                }
            }
        },

        mounted() {
            this.$http
                .get((`spa/Workshop/catalogue`))
                .then(response => (this.catalogues = response.data))
                .catch(() => { });
        },

        beforeMount() {
            this.$store.dispatch('currentUser/fetchData');
        },

        activated() {
            this.$store.dispatch('currentUser/fetchData');
        },
    };
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.special-offer {
    margin: 1.875rem 0;

    .special-offer-header {
        padding: 1.875rem;
        background-color: $green-lighter;
        border-radius: 4px 4px 0 0;
    }

    .special-offer-header .catalogue-link {
        text-align:center;
    }

    .columns {
        background-color: $white;
        margin: 0;
        padding: 1.15rem 0.9375rem;
            

        .column {
            padding: 0 0.9375rem;

            .card {
                border: solid 1px #f2f2f2;
                cursor: pointer;

                &:hover, &:focus {
                    background: $green-lighter;
                    transition: background 0.3s ease;

                    .special-offer-content {
                        h2, p, span {
                            color: $white;
                        }

                        .right-arrow-small-icon {
                        color: $white;
                        }

                    }
                }
            }
        }
    }

    .single-special-offer {
        display: block;
        margin: 0.8rem 0;

        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        .special-offer-bck {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            height: 200px;

            @include tablet {
                -ms-flex: 0 0 30%;
                flex: 0 0 30%;
                max-width: 30%;
                height: inherit;
            }

            @include fullhd {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                height: inherit;
            }

            > img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                border-radius: 5px 5px 0 0;

                @include tablet {
                    border-radius: 5px 0 0 5px;
                }
            }

        }

        .special-offer-content {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            padding: 1rem 2.5rem 1rem 1rem;
            position: relative;

            @include tablet {
                padding: 1rem 2.5rem 1rem 1rem;
            }

            @include widescreen {
                padding: 1.5rem 5rem 1.5rem 1.875rem;
            }

            .special-offer-title {
                margin-bottom: 0.375rem;
            }

            .special-offer-title, .special-offer-desc {
                font-size: 0.875rem;

                @include tablet {
                    font-size: 0.675rem;
                }

                @include widescreen {
                    font-size: 0.875rem;
                }
            }

            .special-offer-city {
                margin-bottom: 0.5rem;
            }
        }

        .right-arrow-small-icon {
            margin-top: -11px;
            right: 15px;

            @include tablet {
                right: 15px;
            }

            @include widescreen {
                right: 30px;
            }
        }

    }
}

.catalogue-link {
    flex-grow: 1;
    flex-basis: 0;
    margin: auto;

    img {
        max-height: 400px;
    }
}

.catalogues-container {
    display: flex;
    justify-content: center;
}
</style>
