<template>
    <section class="workshopsall section">
        <div class="container">
            <div class="columns">
                <div class="column is-one-quarter is-hidden-mobile">
                    <aside class="menu">
                        <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                            <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': filterType.isOpen }">{{ filterType.Title }}</p>
                            <div class="navbar-content">
                                <ul class="menu-list">
                                    <li class="is-flex" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                        <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadAllWorkshopsHandler()" class="is-size-7">{{ filter.Value }}</a>
                                        <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadAllWorkshopsHandler)"></button>
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>

                    </aside>
                </div>
                <div class="column">
                    <div class="columns is-paddingless is-multiline">
                        <div class="column is-full" v-for="workshop in workshopsall" :key="workshop.Id">
                            <BaseCardWorkshopAll :url="'/warsztaty-tematy/'+workshop.SubjectId" :image="workshop.Image" :title="workshop.Title" :description="workshop.SubTitle" :benefits="workshop.PurposeAndBenefits" :subjectId="workshop.SubjectId" :rate="workshop.Rate"/>
                        </div>
                    
                    <!--<div class="column is-full">
                        <BaseCardWorkshop @goToUrlHandler="goToExternalUrlHandler(item.ExtrnalUrl)" :category="'obsługa klienta'" :title="'Tytuł warsztatu'" :description="'Lorem ipsum solo domais darasnada elorna teforasero hamidas loremoera ramoto  loremo ipsumona ipsum solo domais darasnada elorna teforas'" :rate="4.3" :date="'15.03.2019'" :city="'Katowice'" />
                    </div>-->
                    </div>
                    <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadAllWorkshopsHandler()" v-if="nextPage">Załaduj więcej treści</a>
                    <h2 class="title is-2 has-text-weight-light has-text-left" v-if="!workshopsall.length">{{ $parent.search ? 'Nie znaleziono wyników dla ' + $parent.search : 'Brak wyników' }}<a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="$emit('update:search', ''); clearFiltersHandler()">Resetuj wyszukiwanie</a></h2>
                </div> 
            </div>
        </div>
        <nav class="filters is-fixed-top">
            <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
                <span v-touch:tap="showFiltersHandler"></span>
                <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
                    <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
                    <ul class="menu-list">
                        <li>
                            <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                                <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': filterType.isOpen }">
                                    <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">{{ filterType.Title }}</span>
                                </span>
                                <div>
                                    <div class="navbar-content">
                                        <ul>
                                            <li class="is-flex has-text-left" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                                <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadAllWorkshopsHandler()">{{ filter.Value }}</a>
                                                <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadAllWorkshopsHandler)"></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
            </aside>
        </nav>
    </section>
</template>

<script>
import BaseCardWorkshopAll from "@/components/BaseCardWorkshopAll.vue";
import StarRating from "vue-star-rating";
import global from "@/mixins/global.js";

export default {
  name: "WorkshopsAll",
  mixins: [global],
  components: {
    BaseCardWorkshopAll,
    StarRating,
  },
  props: {
    search: {
      type: String
    }
  },
  mounted() {},
  data() {
    return {
      workshopsall: [],
      filtersList: {},
      filters: {
        Page: 0
      },
      nextPage: true,
      isOpen: [true, false]
    };
  },
  methods: {
    loadAllWorkshopsHandler() {
      let self = this;
      self.$http
          .post(`spa/workshop/getallworkshoplist`, self.filters)
        .then(response => {
          if (self.filters.Page) {
            self.workshopsall = [...self.workshopsall, ...response.data.Data];
            self.nextPage = response.data.NextPage;
          } else {
            self.workshopsall = response.data.Data;
            self.nextPage = response.data.NextPage;
          }
        })
        .catch(() => {});
    },
    loadAllWorkshopsFiltersHandler() {
      let self = this;
      self.$http
          .get(`spa/workshop/getfiltersallworkshops`)
        .then(response => {
          this.filtersList = response.data;
          this.filtersList.forEach(function(item) {
            self.$set(item, "isOpen", false);
            self.$set(self.filters, item.Key, null);
          });
        })
        .catch(() => {});
    }
  },
  watch: {
    search: function() {
      this.filters.Query = this.search;
      this.filters.Page = 0;
      this.loadAllWorkshopsHandler();
    }
  },
  created: function() {
    this.loadAllWorkshopsHandler();
    this.loadAllWorkshopsFiltersHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.workshopsall {
  padding-bottom: rem(60px);
  .title {
    margin-left: rem(30px);
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }
  .columns {
    .menu {
      text-align: left;
      a {
        padding-left: 0;
        width: 100%;
      }
      &-list {
        li {
          justify-content: space-between;
        }
        &:last-child {
          border-bottom: 1px solid $grey-lighter;
          padding: 20px 0 25px 0;
        }
      }
    }
  }
  li {
    letter-spacing: 1px;
    a {
      padding-right: 5px;
    }
  }
  .delete {
    display: none;
    align-self: center;
  }
  .menu-label {
    .delete {
      display: inline-block;
    }
  }
  .is-selected {
    font-weight: bold;
    .delete {
      float: right;
      display: block;
      background-color: #bebebe;
    }
    .delete:before,
    .delete:after {
      background-color: white;
    }
  }
}
</style>
