<template>
    <article class="card" @click="$emit('goToUrlHandler')">
        <div class="columns-wrap">
            <div class="img-conf">               
                <vue-ellipse-progress 
                                      :progress="day.PercentDone"
                                      :angle="-90"
                                       color="#3AAB59"
                                       emptyColor="#bfe4c9"
                                      :size="65"
                                      :thickness="7"
                                      emptyThickness="7"
                                      lineMode="normal"
                                      :legend="true"
                                      :legendValue="0"
                                      legendClass="legend-custom-style"
                                      animation="reverse 700 400"
                                      :noData="false"
                                      :loading="false"
                                      fontColor="#4A4A4A"
                                      :half="false"
                                      fontSize="1rem">

                    <span slot="legend-value">%</span>
                </vue-ellipse-progress>
            </div>
            <div class="card-content">
                <div class="main-lector" v-if=" day.Title ">
                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-primary-hover">{{ day.Title }}</p>
                </div>
                <div class="title-description">
                    <!--<h2 class="is-size-3 is-size-2-tablet">{{ confPast.Title }}</h2>-->
                    <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc">
                        {{ day.Text }}
                    </p>
                </div>
                <RightArrowSmallIcon class="right-arrow-small-icon" />
            </div>
        </div>
    </article> 
</template>

<script>
    import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
    import global from "@/mixins/global.js";
export default {
        name: "BaseCardImplemenetationProgramDay",
        mixins: [global],
        components: {
        RightArrowSmallIcon,
    },
    props: ['day'],
    computed: {
     
    },
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

    .columns-wrap {
        display: block;
        height: 100%;
        margin: 3px 45px;

        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }

    .has-text-primary-hover{
        color: $grey-dark;
    }

    .img-conf {
        width: 100%;
        min-width: 100%;
        position: relative;
        height: auto;
        overflow: auto;
        margin: auto;

        @include tablet {
            width: 9%;
            min-width: 9%;
            height: auto;
        }
    }

    .card-content {
        padding: 20px 12px;

        @include tablet {
            padding: 20px 12px;
        }

        @include fullhd {
            padding: 20px 12px;
        }

        .title-description {
            padding-right: 2rem;

            @include tablet {
                padding-right: 3rem;
            }
        }

        h2 {
                margin: 0 0 0.625rem;
            }

        .main-lector {
            margin-bottom: 0;
        }

            .cat-name {
                text-transform: uppercase;
                margin-bottom: 1.7em;
                font-weight: 600;
                svg {
                margin-right: 10px;
                position: relative;
                top: 1px;
                }
            }
    .short-desc {
        margin-bottom: 0;

        @include tablet {
            margin-bottom: 1.5625rem;
        }
    }
        .right-arrow-small-icon {
            margin-top: -15px;
            right: 20px;

            @include tablet {
                right: 30px;
            }
        }
    }

.category-full {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5625rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @include tablet {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.card {
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  cursor: pointer;
  color: $black;
  border-radius: 4px;
  box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
  border: solid 1px #f2f2f2;
  &.bottom-image {
    .card-image {
      order: 2;
      align-self: flex-end;
      img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .card-content {
      order: 1;
      img {
        border-radius: 0;
        max-height: 40px;
      }
    }
  }
  .is-3by1 {
    padding-top: 39.3333%;
  }
  &-image,
  &-content {
    width: 100%;
    .media-content {
      & > div {
        padding-right: 70%;
      }
    }
  }
    &:hover,
    &:focus {
        background: rgba(58, 171, 89, 0.1);
        transition: background 0.3s ease;
        .card-content {
      h2,
      p {
        color: $grey-dark;
      }
    .right-arrow-small-icon {
        color: $green-lighter;
    }
      ul {
          color: $white;
      }
      svg {
          color: $white;
      }
      .alert {
          color: $red;
      }
    }
  }
}
</style>
