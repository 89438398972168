import UserService from '@/services/UserService.js';

export const namespaced = true;

export const state = {
	data: {},
	scheduledWorkshops: [],
	completedWorkshops: [],
    completedElearnings: [],
    implementationprograms: [],
	shops: [],
	subservientEmployees: [],
	agreements: {},
	workPosition: '',
};

export const mutations = {
	SET_DATA(state, data) {
		state.data = data;
	},

	SET_SCHEDULED_WORKSHOPS(state, workshops) {
		state.scheduledWorkshops = workshops;
	},

	SET_COMPLETED_WORKSHOPS(state, workshops) {
		state.completedWorkshops = workshops;
	},

	SET_COMPLETED_ELEARNINGS(state, elearnings) {
		state.completedElearnings = elearnings;
	},

    SET_IMPLEMENTATION_PROGRAMS(state, elearnings) {
        state.implementationprograms = elearnings;
    },

	SET_SHOPS(state, shops) {
		state.shops = shops;
	},

	SET_SUBSERVIENT_EMPLOYEES(state, employees) {
		state.subservientEmployees = employees;
	},

	SET_AGREEMENTS(state, agreements) {
		state.agreements = agreements;
	},

	SET_WORK_POSITION(state, numericId) {
		switch (numericId) {
			case 0:
				state.workPosition = 'worker';
				break;

			case 1:
				state.workPosition = 'partner';
				break;

			case 2:
				state.workPosition = 'owner';
				break;

			case 3:
				state.workPosition = 'shop-manager';
				break;
		}
	},
};

export const actions = {
	fetchData({ commit }) {
		return UserService.getCurrentUserData().then(response => {
			commit('SET_DATA', response.data);
		});
	},

	fetchScheduledWorkshops({ commit }, userId) {
		return UserService.getScheduledWorkshops(userId).then(response => {
			commit('SET_SCHEDULED_WORKSHOPS', response.data.Data);
		});
	},

	fetchCompletedWorkshops({ commit }, userId) {
		return UserService.getCompletedWorkshops(userId).then(response => {
			commit('SET_COMPLETED_WORKSHOPS', response.data.Data);
		});
	},

	fetchCompletedElearnings({ commit }, userId) {
		return UserService.getCompletedElearnings(userId).then(response => {
			commit('SET_COMPLETED_ELEARNINGS', response.data.Data);
		});
	},

    fetchUserImplementationPrograms({ commit }, userId) {
        return UserService.getUserImplementationProgramStats(userId).then(response => {
            commit('SET_IMPLEMENTATION_PROGRAMS', response.data);
        });
    },


	fetchShops({ commit }, userId) {
		return UserService.getShops(userId).then(response => {
			commit('SET_SHOPS', response.data);
		});
	},

	fetchSubservientEmployees({ commit }, { userId, userRole }) {
		return UserService.getSubservientEmployees({ userId, userRole }).then(
			response => {
				commit('SET_SUBSERVIENT_EMPLOYEES', response.data);
			}
		);
	},

	fetchAgreements({ commit }) {
		return UserService.getAgreements().then(response => {
			commit('SET_AGREEMENTS', response.data);
			commit('SET_WORK_POSITION', response.data.RegulationWorkPosition);
		});
	},

	updateAgreements({ state, dispatch }, toggledAgreementKey) {
		// Clone the agreements
		const updatedAgreements = {
			...state.agreements,
			IsAccountAgreementDefinition: true,
		};

		// Update the agreement that has been toggled.
		updatedAgreements[toggledAgreementKey] = !updatedAgreements[
			toggledAgreementKey
		];

		// Update the agreements in the database.
		return UserService.updateAgreements(updatedAgreements).then(() => {
			dispatch('fetchAgreements');
		});
	},

	cancelWorkshopAttendance({ state }, workshopId) {
		return UserService.cancelWorkshopAttendance(state.data.Id, workshopId);
	},

	changePassword(context, formData) {
		return UserService.changePassword(formData);
	},
};
