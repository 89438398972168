<template>
  <main>
    <BaseHero title="Moje dane"/>
    <Account />
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import Account from "@/components/Containers/ViewAccount/Account.vue";

export default {
  name: "ViewAccount",
  components: {
    BaseHero,
    Account
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
