<template>
	<div class="column is-full is-flex has-text-left" ref="el">
		<div class="card-table">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<IconCheck />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Zgody prawne
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->

				<div
					class="card-body has-background-white"
					:class="{ expanded: expanded }"
					:style="{ maxHeight: expanded ? '10000px' : bodyMaxHeight }"
					ref="body"
				>
					<template v-if="isLoading">
						<progress class="progress is-primary" max="100"></progress>
					</template>

                    <template v-else-if="!isLoading && workPosition.length">
                        <p>
                            Witamy na platformie edukacyjnej Akademia Umiejętności Eurocash!
                        </p>

                        <p>
                            Cieszymy się, że chcesz korzystać z bogatych zasobów edukacyjnych,
                            jakie dla Ciebie przygotowaliśmy. Jednak zanim zaczniesz,
                            potrzebujemy Twojej akceptacji regulaminu Platformy Edukacyjnej i
                            prosimy o zapoznanie się z informacjami na temat przetwarzania
                            Twoich danych osobowych
                        </p>

                        <div class="single-agreement">
                            <Checkbox :checked="true" :disabled="true" />
                            <p class="single-agreement-text">
                                Akceptuję regulamin Platformy Edukacyjnej Akademii Umiejętności
                                Eurocash,
                                <a v-if="isUnaffilliated" href="../../regulaminniezrzeszeni">Zobacz tutaj >></a>
                                <a v-else href="../../regulamin">Zobacz tutaj >></a>
                            </p>
                        </div>

                        <template v-if="workPosition === 'owner' || workPosition === 'shop-manager'">
                            
                            <p v-if="workPosition === 'shop-manager'">
                                Jednocześnie informujemy, że z poziomu swojego konta masz możliwość definiowania kont dla swoich współpracowników pod warunkiem uzyskania przez Twojego pracodawcę zgód od tych osób na przekazanie danych do Akademii Umiejętności Eurocash Sp. z o.o. – przed podaniem danych osobowych współpracowników upewnij się zatem, czy Twój Pracodawca pozyskał takie zgody. Jeżeli Twój Pracodawca nie dysponuje takimi zgodami, nie możesz podawać danych swoich współpracowników celem definiowania dla nich kont.
                            </p>
                            <p v-else>
                                Masz możliwość przekazania dostępu do platformy edukacyjnej
                                Akademia Umiejętności Eurocash swoim pracownikom. Pamiętaj
                                jednak, że zanim założysz konta swoim pracownikom, powinieneś
                                najpierw odebrać od nich zgodę na przekazanie danych do Akademii
                                Umiejętności.
                            </p>

                            <div class="single-agreement">
                                <Checkbox :checked="true" :disabled="true" />
                                <p class="single-agreement-text">
                                    Oświadczam, że będę definiował/a konta dla moich pracowników
                                    na Platformie Edukacyjnej Akademii Umiejętności Eurocash
                                    wyłącznie wtedy, kiedy uzyskałem/uzyskałam od nich zgody na
                                    przekazanie danych do Akademii Umiejętności Eurocash Sp. z
                                    o.o.
                                </p>
                            </div>

                            <p>
                                W celu uzyskania zgody od pracowników skorzystaj z naszego wzoru
                                <a href="/assets/pdf/zgoda_pracownika.pdf" target="_blank">TUTAJ>></a>
                            </p>
                        </template>

                        <template v-if="workPosition !== 'partner'">
                            <p v-if="isUnaffilliated">
                                Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać informacje o aktualnej ofercie Akademii, to prosimy kliknij w poniższą zgodę:
                            </p>
                            <p v-else>
                                Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać
                                informacje o aktualnej ofercie Akademii, o promocjach i
                                konkursach organizowanych przez nas, spółki z Grupy Eurocash i
                                naszych Partnerów (tj. podmiotów współpracujących z Akademią
                                Umiejętności Eurocash Sp. z o.o., lista aktualnych Partnerów
                                dostępna jest
                                <a :href="rootUrl + 'partnerzy'">TUTAJ>></a>
                                – lista ta jest aktualizowana na bieżąco), to prosimy kliknij w
                                poniższe zgody:
                            </p>


                            <p>
                                Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                Eurocash Sp. z o.o. informacji o najnowszych ofertach
                                interesujących promocjach oraz konkursach organizowanych przez
                                Spółkę:
                            </p>

                            <div class="single-agreement">
                                <Checkbox @change="updateAgreements('IsEmailEurocashGettingInfo')"
                                          :checked="agreements.IsEmailEurocashGettingInfo" />
                                <p class="single-agreement-text">
                                    za pośrednictwem poczty elektronicznej,
                                </p>
                            </div>

                            <div class="single-agreement">
                                <Checkbox @change="updateAgreements('IsSmsEurocashGettingInfo')"
                                          :checked="agreements.IsSmsEurocashGettingInfo" />
                                <p class="single-agreement-text">za pośrednictwem SMS/MMS.</p>
                            </div>

                            <p v-if="!isUnaffilliated">
                                Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                interesujących promocjach oraz konkursach organizowanych przez
                                spółki z Grupy Eurocash – których aktualna lista dostępna jest
                                <a href="https://grupaeurocash.pl/public/assets/media/eurocash_spolki.pdf">TUTAJ>></a>
                                – lista ta jest aktualizowana na bieżąco:
                            </p>

                            <div v-if="!isUnaffilliated" class="single-agreement">
                                <Checkbox @change="updateAgreements('IsEmailEurocashGroupGettingInfo')"
                                          :checked="agreements.IsEmailEurocashGroupGettingInfo" />
                                <p class="single-agreement-text">
                                    za pośrednictwem poczty elektronicznej,
                                </p>
                            </div>

                            <p v-if="!isUnaffilliated">
                                Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                interesujących promocjach oraz konkursach organizowanych przez
                                Partnerów Akademii, których aktualna lista dostępna jest
                                <a :href="rootUrl + 'partnerzy'">TUTAJ>></a>
                                – lista ta jest aktualizowana na bieżąco:
                            </p>

                            <div v-if="!isUnaffilliated" class="single-agreement">
                                <Checkbox @change="
										updateAgreements('IsEmailEurocashPartnersGettingInfo')
									"
                                          :checked="agreements.IsEmailEurocashPartnersGettingInfo" />
                                <p class="single-agreement-text">
                                    za pośrednictwem poczty elektronicznej,
                                </p>
                            </div>

                            <p v-if="!isUnaffilliated">
                                Niezaznaczenie którejkolwiek z powyższych opcji oznacza, że nie
                                będziesz na bieżąco z naszymi najnowszymi ofertami handlowymi,
                                jak również z ofertami handlowymi spółek z Grupy Eurocash oraz z
                                ofertami Partnerów Akademii, które mogą okazać się dla Ciebie
                                bardzo korzystne
                            </p>
                            <p v-else-if="workPosition === 'owner' || workPosition === 'shop-manager'">
                                Niezaznaczenie powyższej opcji oznacza, że nie będziesz na bieżąco z naszymi najnowszymi ofertami handlowymi.
                            </p>
                            <p v-else>
                                Niezaznaczenie powyższej opcji oznacza, że nie będziesz na bieżąco z naszymi najnowszymi ofertami.
                            </p>


                            <p>
                                Informujemy jednocześnie, że w każdej chwili możesz cofnąć każdą
                                z udzielonych nam zgód w zakładce Moje Dane lub poprzez
                                przesłanie nam wiadomości na adres e-mail na adres
                                [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz
                                się>> w otrzymanej od nas informacji o najnowszych ofertach,
                                interesujących promocjach oraz konkursach. Wycofanie zgody nie
                                wpływa na zgodność z prawem przetwarzania dokonanego przed jej
                                wycofaniem.
                            </p>
                        </template>

                        <p ref=rodod v-if="workPosition === 'owner'&& isUnaffilliated">
                            Prosimy również, zapoznaj się z pozostałymi informacjami na temat przetwarzania Twoich danych osobowych:
                        </p>
                        <p v-else ref="rodo">
                            Zapoznaj się z pozostałymi informacjami na temat przetwarzania Twoich danych osobowych:
                        </p>



                        <p>
                            Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                            siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                        </p>

                        <p>
                            Z administratorem można skontaktować się poprzez adres e-mail:
                            eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000 lub
                            pisemnie na adres siedziby.
                        </p>

                        <p>
                            Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła Inspektora
                            Ochrony Danych, z którym Pani / Pan może skontaktować się poprzez
                            e-mail iod_aue@eurocash.pl, pod numerem telefonicznym (0-61)
                            3322277 lub na adres siedziby administratora. Z inspektorem
                            ochrony danych można się kontaktować we wszystkich sprawach
                            dotyczących przetwarzania danych osobowych oraz korzystania z praw
                            związanych z przetwarzaniem danych.
                        </p>

                        <p>Pani / Pana dane będą przetwarzane:</p>

                        <ul>
                            <li>
                                w celu wykonania umowy i umożliwienia korzystania z Platformy
                                Edukacyjnej – podstawą prawną przetwarzania danych jest
                                niezbędność do wykonania umowy,
                            </li>
                            <li>
                                w celu dochodzenia i ochrony przed roszczeniami - podstawą
                                prawną przetwarzania danych jest prawnie uzasadniony interes
                                Administratora polegający na możliwości ochrony interesów
                                Administratora w postępowaniu sądowym lub innym,
                            </li>
                            <li v-if="isUnaffilliated">
                                w celu komunikacji marketingowej Akademii Umiejętności Eurocash - podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora, polegający na marketingu bezpośrednim produktów i usług oferowanych przez Administratora
                            </li>

                            <li v-else>
                                w celu komunikacji marketingowej Akademii Umiejętności Eurocash
                                oraz podmiotów trzecich (spółek z Grupy Eurocash oraz Partnerów
                                Akademii) - podstawą prawną przetwarzania danych jest prawnie
                                uzasadniony interes Administratora, polegający na marketingu
                                bezpośrednim produktów i usług oferowanych przez Administratora
                                oraz podmioty trzecie w związku z wyrażoną zgodą na otrzymywanie
                                informacji marketingowych wybranym kanałem komunikacji.
                            </li>
                        </ul>
                        <br />

                        <p v-if="isUnaffilliated && workPosition === 'owner'">
                            Pani / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w szczególności dostawcom IT.
                        </p>
                        <p v-else-if="isUnaffilliated">
                            Pani / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w szczególności dostawcom IT. Ponadto, Pani / Pana dane osobowe oraz dane dotyczące Pani/Pana aktywności na platformie mogą być udostępnione Pani/Pana Pracodawcy.
                        </p>
                        <p v-else>
                            Pani / Pana dane osobowe mogą być przekazane podmiotom
                            przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp.
                            z o.o., w szczególności dostawcom IT oraz spółkom z Grupy
                            Kapitałowej Eurocash. Ponadto, Pani / Pana dane osobowe oraz dane
                            dotyczące Pani/Pana aktywności na platformie mogą być udostępnione
                            Pani/Pana Franczyzodawcy.
                        </p>
                        <p>
                            Pani / Pana dane osobowe będą przetwarzane przez okres korzystania
                            przez Panią / Pana z usług Platformy Edukacyjnej lub przez okres
                            trwania prawnie uzasadnionego interesu Administratora istniejącego
                            do czasu cofnięcia zgody na otrzymywanie informacji
                            marketingowych. Okres przechowywania danych osobowych może zostać
                            każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli
                            przetwarzanie danych osobowych będzie niezbędne dla dochodzenia
                            ewentualnych roszczeń lub obrony przed takimi roszczeniami i przez
                            Administratora.
                        </p>

                        <p>
                            Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych oraz
                            prawo żądania ich sprostowania, ich usunięcia lub ograniczenia ich
                            przetwarzania.
                        </p>

                        <p>
                            Jako że Pani / Pana dane przetwarzane są w sposób zautomatyzowany
                            na podstawie umowy – przysługuje Pani / Panu także prawo do
                            przenoszenia danych osobowych, które dostarczyła Pani / dostarczył
                            Pan administratorowi tj. do otrzymania od administratora Pani/Pana
                            danych osobowych, w ustrukturyzowanym, powszechnie używanym
                            formacie nadającym się do odczytu maszynowego. Może Pani / Pan
                            przesłać te dane innemu administratorowi danych.
                        </p>

                        <p>
                            W zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                            osobowych jest przesłanka prawnie uzasadnionego interesu
                            administratora, przysługuje Pani / Panu prawo wniesienia sprzeciwu
                            wobec przetwarzania Pani / Pana danych osobowych tj. przysługuje
                            Pani / Panu prawo sprzeciwu wobec przetwarzania danych na potrzeby
                            dochodzenia i ochrony przed roszczeniami oraz w celu komunikacji
                            marketingowej. W zakresie, w jakim podstawą przetwarzania jest
                            udzielona zgoda – przysługuje Pani / Panu prawo do jej wycofania.
                            Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania
                            dokonanego przed jej wycofaniem.
                        </p>

                        <p>
                            Przysługuje Pani / Panu również prawo wniesienia skargi do organu
                            nadzorczego zajmującego się ochroną danych osobowych w państwie
                            członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub
                            miejsca popełnienia domniemanego naruszenia. W Polsce organem tym
                            jest Prezes Urzędu Ochrony Danych Osobowych.
                        </p>

                        <p>
                            Podanie danych jest dobrowolne, jednak bez ich podania korzystanie
                            z Platformy Edukacyjnej będzie niemożliwe.
                        </p>

                        <!-- Bottom toggler -->
                        <button @click="toggle()"
                                class="card-toggler --bottom is-size-2 is-size-4-tablet is-uppercase has-text-weight-semibold">
                            <span class="chevron" :class="{ active: expanded }"></span>
                            {{ expanded ? 'Zwiń' : 'Rozwiń' }}
                            <span class="chevron" :class="{ active: expanded }"></span>
                        </button>
                        <!-- END | Bottom toggler -->
                    </template>

					<!-- In case when the workPosition is not defined -->
					<template v-else>
						<p class="has-text-centered is-marginless">Brak danych</p>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';
import $ from 'jquery';

import IconCheck from '@/assets/svg/check.svg';
import Checkbox from '@/components/Containers/ViewMyAccountData/components/Checkbox.vue';

export default {
	components: {
		IconCheck,
		Checkbox,
	},

	data() {
		return {
			expanded: false,
			isLoading: true,
            bodyMaxHeight: '0px',
            rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/')
		};
	},

	methods: {
		loadData() {
			this.isLoading = true;

			this.$store.dispatch('currentUser/fetchAgreements').finally(() => {
				this.isLoading = false;
			});
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.scrollToBoxTop();

			setTimeout(() => {
				this.expanded = false;
			}, 1000);
		},

		/**
		 * @description This method will tell you if the user is part of the specified role.
		 * @param {String} role The role to check the user against.
		 * @returns {Boolean}
		 */
		isUser(role) {
			return !this.isLoading && this.userRoles.includes(role);
		},

		/**
		 * @description This method will upload the current state of the agreements object to the API.
		 * @param {String} toggledAgreementKey The key of the agreement that has been toggled.
		 * @returns {void}
		 */
		updateAgreements(toggledAgreementKey) {
			this.$store.dispatch('currentUser/updateAgreements', toggledAgreementKey);
		},

		/**
		 * @description This method will scroll to the top of the Agreements box.
		 * @returns {Void}
		 */
		scrollToBoxTop() {
			$([document.documentElement, document.body]).animate(
				{
					scrollTop: $(this.$refs.el).offset().top - 200,
				},
				1000
			);
		},
	},

	computed: {
		...mapState('currentUser', {
			agreements: state => state.agreements,
            workPosition: state => state.workPosition,
            network: state => state.data.Network,
            isUnaffilliated: state => state.data.Network ==='Niezrzeszeni'
		}),
	},

	beforeMount() {
		this.loadData();
	},

	activated() {
		this.loadData();
	},

	updated() {
		if (this.$refs.rodo && this.$refs.el) {
			this.bodyMaxHeight =
				$(this.$refs.rodo).offset().top - $(this.$refs.el).offset().top + 'px';
		} else {
			this.bodyMaxHeight = '10000px';
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/_variables.scss';
@import '~bulma/sass/utilities/mixins';

p:not(.card-header-title) {
	margin-bottom: 20px;
}

.card {
	&-body {
		padding: 25px 30px 100px 30px;
		transition: all 300ms ease-in-out;

		// Add white gradient on the bottom.
		position: relative;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			height: 300px;
			width: 100%;
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 100%
			);
			opacity: 1;
			transition: all 300ms ease-in-out;
			pointer-events: none;
		}

		&.expanded {
			&::after {
				opacity: 0;
			}
		}
	}

	.card-toggler.--bottom {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;

		border-color: $green-lighter;
		color: $green-lighter;

		.chevron {
			&:first-child {
				margin-left: 0;
				margin-right: 15px;
			}

			&::before,
			&::after {
				background-color: $green-lighter;
			}
		}
	}

	.single-agreement {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;

		margin-bottom: 20px;

		&-text {
			padding-left: 20px;
			margin-bottom: 0;
		}
	}

	@keyframes bounceInBefore {
		0% {
			opacity: 0;
			transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
		}
		50% {
			opacity: 0.9;
			transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
		}
		80% {
			opacity: 1;
			transform: scale(0.89) translate(-50%, -50%) rotate(45deg);
		}
		100% {
			opacity: 1;
			transform: scale(1) translate(-50%, -50%) rotate(45deg);
		}
	}

	@keyframes bounceInAfter {
		0% {
			opacity: 0;
			transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
		}
		50% {
			opacity: 0.9;
			transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
		}
		80% {
			opacity: 1;
			transform: scale(0.89) translate(-50%, -50%) rotate(-45deg);
		}
		100% {
			opacity: 1;
			transform: scale(1) translate(-50%, -50%) rotate(-45deg);
		}
	}

	@keyframes bounceInBeforeDont {
		0% {
			opacity: 1;
			transform: scale(1) translate(-50%, -50%) rotate(45deg);
		}
		100% {
			opacity: 0;
			transform: scale(0.3) translate(-50%, -50%) rotate(45deg);
		}
	}

	@keyframes bounceInAfterDont {
		0% {
			opacity: 1;
			transform: scale(1) translate(-50%, -50%) rotate(-45deg);
		}
		100% {
			opacity: 0;
			transform: scale(0.3) translate(-50%, -50%) rotate(-45deg);
		}
	}
}
</style>
