<template>
	<form @submit.prevent="submitFormHandler">
		<div class="columns is-multiline is-variable has-text-centered-mobile">
			<div class="column is-half has-text-right-tablet">Aktualny doradca:</div>
			<div class="column is-half has-text-weight-bold">
				{{ customerAdvisorName }}
			</div>
		</div>
		<hr />
		<div
			class="field"
			v-if="$store.state.user.Workposition !== 'Doradca Klienta'"
		>
			<div class="control has-icons-left">
				<div class="input-container-text">
					<input
						class="input"
						v-model.lazy="search"
						@change="searchCustomerAdvisorHandler"
						type="text"
						placeholder="Wyszukaj doradcę klienta"
					/>
					<span class="icon is-small is-left">
						<LoupeIcon />
					</span>
				</div>
			</div>
		</div>
		<table class="table table-steps" v-if="customerAdvisorList !== ''">
			<thead class="is-hidden-mobile">
				<tr
					class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
				>
					<th class="has-text-grey-light">Imię</th>
					<th class="has-text-grey-light">Nazwisko</th>
					<th class="has-text-grey-light">Email</th>
					<th class="has-text-grey-light">Sieć</th>
					<th class="has-text-grey-light has-text-centered-tablet">Wybierz</th>
				</tr>
			</thead>
			<tbody v-if="customerAdvisorList.length">
				<tr
					v-for="customerAdvisor in customerAdvisorList"
					:key="customerAdvisor.Id"
				>
					<td class="has-text-black is-size-17">
						<div class="is-dotted is-block">
							<span
								class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
								>Imię</span
							>
							<span>{{ customerAdvisor.FirstName }}</span>
						</div>
					</td>
					<td class="has-text-black is-size-17">
						<div class="is-dotted is-block">
							<span
								class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
								>Nazwisko</span
							>
							<span>{{ customerAdvisor.LastName }}</span>
						</div>
					</td>
					<td class="has-text-black is-size-17">
						<div class="is-dotted is-block">
							<span
								class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
								>Email</span
							>
							<span>{{ customerAdvisor.Email }}</span>
						</div>
					</td>
					<td class="has-text-black is-size-17">
						<div class="is-dotted is-block">
							<span
								class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
								>Sieć</span
							>
							<span>{{ customerAdvisor.Network }}</span>
						</div>
					</td>
					<td class="has-text-black is-size-17 has-text-centered">
						<b-field>
							<b-radio-button
								@input="changeShopcustomerAdvisorHandler(customerAdvisor)"
								type="is-info"
								v-model="customerAdvisorId"
								:native-value="customerAdvisor.Id"
								size="is-large"
								class="has-text-centered"
							>
								<CheckedIcon />
							</b-radio-button>
						</b-field>
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td colspan="4" class="has-text-centered">Brak danych</td>
				</tr>
			</tbody>
		</table>

		<BaseModalInfo
			:is-active="modalInfo.isActive"
			:message="modalInfo.message"
			:button-label="modalInfo.buttonLabel"
		/>
	</form>
</template>

<script>
// JS modules.
import UserService from '@/services/UserService';
import ShopService from '@/services/ShopService.js';

// Vue components.
import BaseModalInfo from '@/components/BaseModalInfo.vue';
import LoupeIcon from '@/assets/svg/loupe.svg';
import CheckedIcon from '@/assets/svg/checked.svg';

export default {
	props: [
		'clickedNext',
		'currentStep',
		'submitStatus',
		'isEdit',
		'didSelectExistingShop',
		'existingShopChangeIndicator',
	],
	components: {
		LoupeIcon,
		CheckedIcon,
		BaseModalInfo,
	},

	data() {
		return {
			search: '',
			customerAdvisorList: '',
			customerAdvisorId: this.$store.state.shop.CustomerAdvisorId,
			customerAdvisorName: this.$store.state.shop.CustomerAdvisorName,
			shopId: '',

			modalInfo: {
				isActive: false,
				message: 'Doradca Klienta został zmieniony.',
				buttonLabel: 'Zamknij okno',
			},
		};
	},

	methods: {
		getCaName(caId) {
			UserService.getProfileData(
				caId || this.$store.state.shop.existingShop.CaId
			)
				.then(response => {
					const { FirstName, LastName } = response.data;
					this.customerAdvisorName = `${FirstName || ''} ${LastName || ''}`;
				})
				.catch(() => {
					this.customerAdvisorName = 'brak';
				});
		},

		canContinue() {
			this.$emit('can-continue', { value: true });
		},

		searchCustomerAdvisorHandler() {
			let self = this;

			self.$http
				.post(`spa/managment/shop/searchca`, {
					Query: this.search,
				})
				.then(response => {
					self.customerAdvisorList = response.data;
				})
				.catch(() => {
					self.customerAdvisorList = '';
				});
		},

		changeShopcustomerAdvisorHandler(customerAdvisor) {
			const name = customerAdvisor.FirstName + ' ' + customerAdvisor.LastName,
				id = customerAdvisor.Id;

			this.customerAdvisorId = id;
			this.$store.state.shop.CustomerAdvisorId = id;

			this.customerAdvisorName = name;

			this.$store.commit('UPDATE_STATE');
			this.$emit('select-ca');
		},

		submitFormHandler() {
			if (this.submitStatus.loading) {
				let self = this,
					formData = {
						MemberId: this.customerAdvisorId,
						ShopId: this.shopId,
					},
					submitStatus = { ...self.submitStatus };

				if (this.isEdit) {
					self.$http
						.post(`spa/managment/shop/changeca`, formData)
						.then(response => {
							submitStatus = {
								message: response.data.Message,
								loading: false,
								responseType: 'success',
							};

							self.$store.state.shop.CustomerAdvisorId = this.customerAdvisorId;
							self.$store.state.shop.CustomerAdvisorName = this.customerAdvisorName;
							self.$store.commit('UPDATE_STATE');
							self.$emit('update-submit', submitStatus);

							this.modalInfo.isActive = true;
						})
						.catch(error => {
							submitStatus = {
								message: error.response.data.Message,
								loading: false,
								responseType: 'error',
							};

							self.$emit('update-submit', submitStatus);
						});
				} else {
					self.$store.state.shop.CustomerAdvisorId = self.customerAdvisorId;
					self.$store.state.shop.CustomerAdvisorName = self.customerAdvisorName;
					submitStatus.loading = false;
					self.$emit('update-submit', submitStatus);
				}
				self.$store.commit('UPDATE_STATE');
			}
		},

		closeModalInfo() {
			this.modalInfo.isActive = false;
		},
	},
	watch: {
		'submitStatus.loading': function() {
			this.submitFormHandler();
		},

		didSelectExistingShop() {
			this.getCaName();
		},

		existingShopChangeIndicator() {
			this.getCaName();
		},
	},
	created() {
		if (this.$store.state.user.Workposition === 'Doradca Klienta') {
			this.customerAdvisorId = this.$store.state.user.Id;
			this.customerAdvisorName = this.$store.state.user.FullName;
			this.$store.state.shop.CustomerAdvisorId = this.customerAdvisorId;
			this.$store.state.shop.CustomerAdvisorName = this.customerAdvisorName;
			this.$store.commit('UPDATE_STATE');
		}

		if (this.didSelectExistingShop) {
			this.getCaName();
		}

		if (this.isEdit) {
			ShopService.getShopDetails(this.$route.params.shopId).then(response => {
				const shopDetails = response.data;

				this.customerAdvisorId = shopDetails.CaId;
				this.shopId = this.$route.params.shopId;
				this.getCaName(shopDetails.CaId);
			});
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

form {
	& > div:first-child {
		padding-top: rem(10px);
		& > div {
			padding-top: 5px;
			padding-bottom: 0;
		}
	}
	.field {
		max-width: 500px;
		margin: 0 auto;
	}
	.columns {
		margin-bottom: 0;
	}
	.stepper-box {
		box-shadow: none;
	}
	.input-container-text {
		.icon.is-left {
			margin-top: rem(10px);
			margin-left: rem(10px);
		}
		input {
			height: 56px;
		}
	}
	.control.has-icons-left {
		input {
			padding-left: rem(50px);
		}
	}
}
</style>
