<template>    
    <article class="tile is-parent is-6" v-if="size === 'big'" @click="$emit('goToUrlHandler')">
      <div class="tile is-child is-tile-big is-flex" :style="'background-image: url(' + image + ');'">
        <div class="tile is-child is-6 is-paddingless">
          <div>
            <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left ">{{ category }}</p>
            <p class="is-size-2 has-text-left has-text-black-hover">{{ title }}</p>
            <p class="is-size-8 has-text-left has-text-grey-light-hover">
            <PuzzleIcon class="puzzle-icon has-text-left"/>Szkolenia online</p>
            <RightArrowSmallIcon class="right-arrow-small-icon has-text-right"/>
            <p class="is-size-8 has-text-left has-text-grey-light-hover" v-if="rate">OCENA: <span class="is-size-6 has-text-left has-text-black-hover"> {{ rate }}<span class="has-text-grey-light-hover has-text-weight-light" >/5</span></span>
              <star-rating :star-size="14" :padding="3" :rating="rate" :read-only="true" :increment="0.01" :show-rating="false" :active-color="'#f8d61c'" :inactive-color="'silver'" inline></star-rating>
            </p>
            <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7">{{ description }}</p>
          </div>
          <figure class="image is-1by1">
            <img v-lazy="image" :alt="title" :title="title">
          </figure>
        </div>
      </div>
     </article>
     <article class="tile is-parent" :class="'is-' + col" @click="$emit('goToUrlHandler')" v-else>
        <div class="tile is-child is-flex is-paddingless">
          <div>
            <div class="cat-lang">
                <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left">{{ category }}</p>
                <div v-if="lang">
                    <span v-if="lang === 'Polski'"><flagPL /></span>
                    <span v-else-if="lang === 'Ukrai�ski'"><flagUKR /></span>
                </div> 
            </div>
            <p class="is-size-2 has-text-left has-text-black-hover">{{ title }}</p>
            <p class="is-size-8 has-text-left has-text-grey-light-hover">
            <PuzzleIcon class="puzzle-icon has-text-left"/>Szkolenia online</p>
            <RightArrowSmallIcon class="right-arrow-small-icon has-text-right"/>
            <p class="is-size-8 has-text-left has-text-grey-light-hover" v-if="rate">OCENA: <span class=" is-size-6  has-text-left has-text-black-hover"> {{ rate }}<span class="has-text-grey-light-hover has-text-weight-light" >/5</span></span>
              <star-rating :star-size="14" :padding="3" :rating="rate" :read-only="true" :increment="0.01" :show-rating="false" :active-color="'#f8d61c'" :inactive-color="'silver'" inline></star-rating>
            </p>
            <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7">{{ description }}</p>
          </div>
          <figure class="image is-1by1">
            <img v-lazy="image" :alt="title" :title="title">
          </figure>
        </div>
    </article> 
</template>

<script>
import StarRating from "vue-star-rating";
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
import PuzzleIcon from "@/assets/svg/puzzle.svg";
import flagPL from "@/assets/svg/flags/pl.svg";
import flagUKR from "@/assets/svg/flags/ukr.svg";

export default {
  name: "BaseTile",
  components: {
    StarRating,
    RightArrowSmallIcon,
    PuzzleIcon,
    flagPL,
    flagUKR
  },
  props: {
    category: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    rate: {
      type: Number
    },
    image: {
      type: String
    },
    size: {
      type: String
    },
    col: {
      type: Number
    },
    lang: {
      type: String    
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.tile {
  overflow: hidden;
  p:first-child {
    color: $green;
  }
  &.is-parent {
    position: relative;
    cursor: pointer;
    &:hover,
    &:focus {
      .is-child {
        background-color: $green-lighter;
        h2,
        p,
        span,
        svg {
          color: $white;
        }
        .right-arrow-small-icon {
          color: $white;
        }
      }
    }
  }
  .is-3 {
    width: 100%;
    @include tablet {
      width: 50%;
    }
    @include desktop {
      width: 25%;
    }
  }
  .is-6 {
    width: 100%;
    @include desktop {
      width: 50%;
    }
  }
  &.is-child {
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 7px 11px 0 rgba(0, 0, 0, 0.03);
    padding: rem(25px 30px);
    transition: background 0.3s ease;
    &.is-flex {
      flex-wrap: wrap;
    }
    & > div {
      width: 100%;
      padding: rem(20px 20px);
      padding-right: rem(50px);
      @include tablet {
        padding: rem(20px 30px);
        padding-right: rem(50px);
      }
    }
  }
  &.is-child.is-tile-big {
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include tablet {
      padding-left: 20px;
    }
    .tile.is-child {
      width: 50%;
      min-height: 50%;
      position: relative;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    figure {
      @include tablet {
        display: none;
      }
    }
    .right-arrow-small-icon {
      right: 28px;
    }
  }
  figure {
    width: 100%;
    align-self: flex-end;
  }
  p {
    margin-bottom: 10px;
    width: 100%;
  }
  .right-arrow-small-icon {
    position: absolute;
    top: 10%;
    right: 40px;
    color: $green;
    @include tablet {
      top: 20%;
    }
  }
  .puzzle-icon {
    margin-right: 9px;
  }

  .cat-lang {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    p, div {
        display: inline-block;
        width: auto;
    }

    p {
        margin-right: 8px;
    }

    div {
        font-size: 0;
        svg {
            margin-top: 1px;
        }
    }
  }

}
</style>