<template>
      <section class="error section">
        <div class="container">
          <div class="columns">
            <div class="column is-flex has-text-left">
              <h1 class="title is-14 has-text-weight-bold is-marginless">Ups!</h1>
              <p class="is-size-3">Nie możemy znaleźć strony, której poszukujesz.</p>
              <p class="is-size-3">Spróbuj znaleźć niezbędne informacje tu:</p>
              <ul class="is-size-3">
                <li><router-link to="/" class="has-arrow">Strona główna</router-link></li>
                <li><a href="/" class="has-arrow">Logowanie</a></li>
                <li><router-link to="/kontakt" class="has-arrow">Kontakt i pomoc</router-link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
export default {
  name: "Error",
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.error {
  background-color: white;
  background-image: url("/assets/2018/images/404.png");
  background-position: top right;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  width: 100%;
  height: 100%;
  top: 0;
  .columns {
    .column {
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      height: 100vh;
      & > * {
        width: 100%;
      }
      p {
        line-height: 40px;
      }
      ul {
        margin-top: rem(40px);
        li {
          margin-bottom: rem(10px);
        }
      }
    }
  }
}
</style>