<template>
  <section class="homepage-workshops section" v-if="workshops.length">
        <div class="container">
            <h2 class="title is-2 has-text-weight-light has-text-left">Najbliższe warsztaty&emsp;<router-link class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" to="/warsztaty">Zobacz wszystkie</router-link></h2>
            <div class="columns">
                <div class="column">
                    <div class="columns">
                        <div class="column is-6" v-for="item in workshops" :key="item.Id">
                          <BaseCardCalendar @goToUrlHandler="goToUrlHandler('/warsztaty/' + item.Id)" :category="item.CategoryName" :title="item.Title" :description="item.Description" :rate="item.Rate" :date="item.Date" :city="item.City"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BaseCardCalendar from "@/components/BaseCardCalendar.vue";
import global from "@/mixins/global.js";

export default {
  name: "HomePageWorkshops",
  mixins: [global],
  components: {
    BaseCardCalendar
  },
  data() {
    return {
      workshops: ""
    };
  },
  created: function() {
    let self = this;
    self.$http
      .get(`spa/homepage/workshops`)
      .then(response => {
        self.workshops = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.homepage-workshops {
  padding-bottom: rem(50px);
  background: white;
  .title {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    @include tablet {
      margin-left: rem(30px);
    }
  }
}
</style>