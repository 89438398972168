import axios from 'axios';

const apiClient = axios.create({
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export default {
	getShopDetails(shopId) {
		return apiClient.get(`/umbraco/api/ManagerUsers/GetShopById/${shopId}`);
	},

	deleteShop(shopId) {
		return apiClient.post(`/spa/managment/shop/deleteowner`, { ShopId: shopId });
	},
};
