<template>
    <section class="workshops section">
        <div class="container">
            <div class="columns">
                <div class="column is-one-quarter is-hidden-mobile">
                    <aside class="menu">
                        <b-collapse>
                            <p class="menu-label is-size-7 has-text-black has-border-bottom is-active">WYBIERZ TERMIN</p>
                            <div class="navbar-content">
                                <ul class="menu-list">
                                    <li>
                                        <div class="columns">
                                            <div class="column is-6">
                                                <div class="field">
                                                    <div class="control">
                                                        <b-datepicker :month-names="['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']" placeholder="Od" v-model="filters._dateFrom" v-on:input="loadElearningsHandler"></b-datepicker>
                                                        <span class="icon is-small is-right">
                                                            <CalendarSmallIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column is-6">
                                                <div class="field">
                                                    <div class="control">
                                                        <b-datepicker :month-names="['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']" placeholder="Do" v-model="filters._dateTo" v-on:input="loadElearningsHandler"></b-datepicker>
                                                        <span class="icon is-small is-right">
                                                            <CalendarSmallIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>
                        <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                            <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': filterType.isOpen }">{{ filterType.Title }}</p>
                            <div class="navbar-content">
                                <ul class="menu-list">
                                    <li class="is-flex" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                        <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadElearningsHandler()" class="is-size-7">{{ filter.Value }}</a>
                                        <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadElearningsHandler)"></button>
                                    </li>
                                </ul>
                            </div>
                        </b-collapse>

                    </aside>
                </div>
                <div class="column">
                    <div class="columns is-paddingless is-multiline">
                        <div class="column is-full" v-for="workshop in elearnings" :key="workshop.Id">
                            <BaseCardWorkshop @goToUrlHandler="goToUrlHandler('/warsztaty/' + workshop.Id)" :workShop="workshop" />
                        </div>
                        <!--<div class="column is-full">
                        <BaseCardWorkshop @goToUrlHandler="goToExternalUrlHandler(item.ExtrnalUrl)" :category="'obsługa klienta'" :title="'Tytuł warsztatu'" :description="'Lorem ipsum solo domais darasnada elorna teforasero hamidas loremoera ramoto  loremo ipsumona ipsum solo domais darasnada elorna teforas'" :rate="4.3" :date="'28.02.2019'" :city="'Łódź'" />
                    </div>-->
                    </div>
                    <h2 class="title is-2 has-text-weight-light has-text-left" v-if="!elearnings.length">{{ $parent.search ? 'Nie znaleziono wyników dla ' + $parent.search : 'Brak wyników' }}<a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="$emit('update:search', ''); clearFiltersHandler(); loadElearningsHandler()">Resetuj wyszukiwanie</a></h2>
                    <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadElearningsHandler()" v-if="nextPage">Załaduj więcej treści</a>
                </div>


            </div>
        </div>
        <nav class="filters is-fixed-top">
            <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
                <span v-touch:tap="showFiltersHandler"></span>
                <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
                    <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
                    <ul class="menu-list">
                        <li>
                            <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                                <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': filterType.isOpen }">
                                    <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">{{ filterType.Title }}</span>
                                </span>
                                <div>
                                    <div class="navbar-content">
                                        <ul>
                                            <li class="is-flex has-text-left" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                                <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadElearningsHandler()">{{ filter.Value }}</a>
                                                <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadElearningsHandler())"></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </b-collapse>
                        </li>
                    </ul>
                </div>
            </aside>
        </nav>
        <b-modal :active.sync="introWorkshop" :width="820" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-dialog workshops-modal">
            <button type="button" class="modal-close is-large" @click="introWorkshop = false"></button>
            <p class="has-text-left has-text-weight-light">
                Szanowni Państwo,
                <br /><br />
                W trosce o bezpieczeństwo Państwa i Państwa pracowników, w miesiącach marzec-sierpień 2021, do odwołania, realizowane są warsztaty z trenerem w sklepie.
                <br />
                Trener prowadzi warsztat na pierwszej lub drugiej zmianie w pełnym reżimie sanitarnym.  Warsztaty prowadzone są w grupie max. 5 osób, z zachowaniem środków ochrony indywidualnej tj. maseczek, rękawiczek jednorazowych, z użyciem płynów dezynfekujących.
            </p>






            <!--<p class="has-text-left has-text-weight-light" v-if="formData == 'Delikatesy Centrum'">
            Szanowni Państwo,
            <br /><br />
            <strong>Warsztat z trenerem w sklepie</strong>, czyli szkolenie prowadzone wyłącznie dla Państwa personelu i w Państwa sklepie jest
            <strong>usługą PŁATNĄ</strong>. Koszt warsztatu w Państwa sklepie to <strong>tylko 250 zł netto</strong> – to najtańsza oferta na rynku usług edukacyjnych!
            <br />  <br />
            Korzystanie z innych warsztatów otwartych organizowanych na salach szkoleniowych oraz pozostałych zasobów platformy, w tym kursów e-learningowych, jest <strong>bezpłatne</strong>.
        </p>



        <p class="has-text-left has-text-weight-light" v-else>
            Szanowni Państwo,
            <br /><br />
            Świętując 10-lecie Akademii Umiejętności Eurocash, z radością informujemy, że od 2020 r. <strong>warsztaty prowadzone na salach szkoleniowych</strong> są <strong>BEZPŁATNE!</strong>
            <br /><br />
            <strong>Warsztat z trenerem w sklepie</strong>, czyli szkolenie prowadzone wyłącznie dla Państwa personelu i w Państwa sklepie jest <strong>usługą PŁATNĄ</strong>. Koszt warsztatu w Państwa sklepie to <strong>tylko 250 zł netto</strong> – to najtańsza oferta na rynku usług edukacyjnych!
            <br /><br />
            Korzystanie z pozostałych zasobów platformy, w tym kursów e-learningowych, jest <strong>bezpłatne</strong>.
        </p>-->
            <button type="button" @click="introWorkshop = false" class="intro-close is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold">Zamknij</button>
        </b-modal>


        <b-modal :active.sync="paymentPopup.isPaymentPopup" :width="820" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-dialog ">
            <button type="button" class="modal-close is-large" @click="paymentPopup.isPaymentPopup = false"></button>
            <p v-html="paymentPopup.text" >
            </p>
        </b-modal>

    </section>
</template>

<script>
    import BaseCardWorkshop from "@/components/BaseCardWorkshop.vue";
    import StarRating from "vue-star-rating";
    import global from "@/mixins/global.js";
    import CalendarSmallIcon from "@/assets/svg/small-calendar.svg";

    export default {
        name: "Workshops",
        mixins: [global],
        components: {
            BaseCardWorkshop,
            StarRating,
            CalendarSmallIcon
        },
        props: {
            search: {
                type: String
            }
        },
        activated: function () {
            if (typeof this.filtersList.length === 'undefined' || this.filtersList.length === 0) {
                //filtry załaduj tylko raz
                this.loadElearningsFiltersHandler();
            }
            else {    //this.loadSpecialOffers()
                this.clearFiltersHandler();
                this.loadUrlFilters();
                this.loadElearningsHandler();
            }


        },
        data() {
            return {
                formData: '',
                introWorkshop: true,
                SubjectId: null,
                paymentPopup: { isPaymentPopup: false, text: "" },
                //specialOffers: [],
                elearnings: [],
                filtersList: {},
                filters: {
                    Page: 0,
                    IsAllWorkshops: false,
                    DateFrom: null,
                    DateTo: null,
                    _dateFrom: null,
                    _dateTo: null,
                },
                nextPage: true,
                isOpen: [true, false]
            };
        },
        methods: {

            loadUrlFilters() {
                let self = this;
                if (self.$route.params.subjectId) {
                    self.$set(self.filters, 'SubjectId', self.$route.params.subjectId);

                    //this.filtersList.forEach(function (item) {
                    //    if (item.Key === "CategoryId") {
                    //        //self.$set(self.filters, item.Key, false);
                    //        item.Options.forEach(function (option) {

                    //            if (option.Key.toString() === self.$route.params.categoryId) {
                    //                self.filters[item.Key] = option.Key;
                    //                self.$set(item, "isOpen", true);
                    //            }
                    //        })
                    //    }
                    //});
                }
            },

            loadElearningsHandler() {

                let self = this;
                //if any filter selected clear SubjectId
                if (self.filters.DateFrom || self.filters.DateFrom || self.filters.ProvinceId || self.filters.CategoryId || self.filters.DedicatedWorkPositionId) {
                    self.$set(self.filters, 'SubjectId', null);
                }


                if (self.filters._dateFrom) {
                    var start = this.$moment(self.filters._dateFrom).format('YYYY-MM-DD') + 'T00:00:00Z';
                    self.filters.DateFrom = this.$moment.tz(start, "Europe/Warsaw");
                }
                else {
                    self.filters.DateFrom = null;
                }
                if (self.filters._dateTo) {
                    var x = new Date(self.filters._dateTo);
                    var endDate = new Date(x.setDate(x.getDate() + 1));
                    var end = this.$moment(endDate).format('YYYY-MM-DD') + 'T00:00:00Z';
                    self.filters.DateTo = this.$moment.tz(end, "Europe/Warsaw");
                }
                else {
                    self.filters.DateTo = null
                }



                self.$http
                    .post(`spa/workshop/list`, self.filters)
                    .then(response => {
                        if (self.filters.Page) {
                            self.elearnings = [...self.elearnings, ...response.data.Data];
                            self.nextPage = response.data.NextPage;
                        } else {
                            self.elearnings = response.data.Data;
                            self.nextPage = response.data.NextPage;
                        }
                    })
                    .catch(() => { });
            },
            loadElearningsFiltersHandler() {
                let self = this;
                self.$http
                    .get(`spa/workshop/filters`)
                    .then(response => {
                        this.filtersList = response.data;
                        this.filtersList.forEach(function (item) {
                            self.$set(item, "isOpen", false);
                            self.$set(self.filters, item.Key, null);
                            item.Options.forEach(function (option) {
                                if (option.IsSelected === true && !(self.$route.params.categoryId)) {
                                    self.filters[item.Key] = option.Key;
                                    self.$set(item, "isOpen", true);
                                }
                            })
                        });
                        this.loadUrlFilters();
                        this.loadElearningsHandler();
                    })
                    .catch(() => {

                        this.loadElearningsHandler();
                    });
            }
        },
        watch: {
            search: function () {
                this.filters.Query = this.search;
                this.filters.Page = 0;
                this.loadElearningsHandler();
            }
        },
        created: async function () {

            this.introWorkshop = false;
            let self = this;


        var t1=    self.$http
                .get(`/spa/Workshop/UserPopupType  `)
                .then(response => {
                    self.formData = response.data;
                    if (self.formData !== 'Operacja' && self.formData != 'Detal+') {
                        self.introWorkshop = true;
                    } else {
                        self.introWorkshop = false;
                    }
                })
                .catch(() => { });

        var t2=     self.$http
                .get(`/spa/Workshop/PaymentPopup  `)
                .then(response => {
                    self.paymentPopup = response.data;
                })
                .catch(() => { });


            await Promise.all([t1, t2]);
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .workshops {
        padding-bottom: rem(60px);
        .title

    {
        a

    {
        margin-left: 15px;
    }

    }

    .title {
        margin-left: rem(30px);
        padding-top: rem(10px);
        padding-bottom: rem(10px);
    }

    .button.is-light.is-outlined {
        padding: 25px 50px;
        border-color: $grey-lighter;
        &:hover, &:focus

    {
        background: transparent;
        border-color: $grey-light;
    }

    }

    .columns {
        .menu

    {
        text-align: left;
        a

    {
        padding-left: 0;
        width: 100%;
    }

    &-list {
        li

    {
        justify-content: space-between;
    }

    &:last-child {
        border-bottom: 1px solid $grey-lighter;
        padding: 20px 0 25px 0;
    }

    }
    }
    }

    li {
        letter-spacing: 1px;
        a

    {
        padding-right: 5px;
    }

    }

    .delete {
        display: none;
        align-self: center;
    }

    .menu-label {
        .delete

    {
        display: inline-block;
    }

    }

    .is-selected {
        font-weight: bold;
        .delete

    {
        float: right;
        display: block;
        background-color: #bebebe;
    }

    .delete:before,
    .delete:after {
        background-color: white;
    }

    }

    .control {
        position: relative;
        z-index: 2;
        .icon

    {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 3;
        color: $grey-light;
        display: none;

        @include desktop {
            display: block;
        }
    }

    }

    .workshops-modal {
        .intro-close

    {
        width: 240px;
        display: block;
        padding: 15px;
        border-radius: 4px;
        border: 2px solid $green-lighter;
        background-color: $green-lighter;
        color: $white;
        margin: 20px auto 0;
        -webkit-transition: 0.3s all ease-in-out;
        -o-transition: 0.3s all ease-in-out;
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        &:hover

    {
        background-color: $white;
        color: $green-lighter;
    }

    }
    }
    }
</style>
