<template>
	<section>
		<div class="container header-page">
			<img src="/img/icon-cookie.svg"/>
			<h1 class="text-header">ZARZĄDZAJ COOKIES</h1>
		</div>
		<div class="block-content">
			<div class="block-information">
				<h2 style="padding-bottom: 10px;">Blok informacyjny</h2>
                <p>
                    Poniżej możesz zmienić ustawienia plików cookies naszych i naszych partnerów. Korzystanie przez nas z plików cookie analitycznych, marketingowych oraz funkcjonalnych wymaga Twojej zgody. W ramach cookies marketingowych wydzieliliśmy kategorię plików cookie związanych z wyświetlaniem reklam w związku z korzystaniem ze stron internetowych (reklamowe pliki cookie) oraz plików cookie pozwalających na docieranie do Ciebie ze spersonalizowaną reklamą w portalach społecznościowych (pliki cookie mediów społecznościowych). Więcej informacji o poszczególnych kategoriach plików cookie, które stosujemy w serwisie, znajdziesz poniżej.<br /><br />   

                    Jeżeli chcesz zaakceptować wszystkie pliki cookie, kliknij «Zaakceptuj wszystkie». Jeżeli nie wyrażasz zgody na korzystanie przez nas z plików cookie innych niż niezbędne pliki cookie, kliknij «Odrzuć wszystkie». Aby zmieniać preferencje plików cookie, przesuń suwak przy wybranej kategorii i naciśnij «Zapisz ustawienia i zamknij». Masz prawo do wglądu w swoje ustawienia oraz do ich zmiany w dowolnym czasie. Pamiętaj, że każdą z wyrażonych zgód możesz wycofać w dowolnym momencie, zmieniając wybrane ustawienia i klikając «Zapisz ustawienia i zamknij». Wycofanie poszczególnych zgód nie wpływa na zgodność z prawem przetwarzania dokonanego przed wycofaniem zgody.<br /><br />

                    Korzystanie z plików cookie wiąże się z przetwarzaniem Twoich danych osobowych dotyczących Twojej aktywności w serwisie. Szczegółowe informacje o sposobie, w jaki my oraz nasi partnerzy używamy plików cookie oraz przetwarzamy Twoje dane, a także o przysługujących Ci prawach, znajdziesz w naszej Polityce prywatności.<br />
                </p>
			</div>
            <div class="block-settings">
                <h2 style="padding-bottom: 10px;">Blok ustawień</h2>
                <div class="slider-block" id="space-cookie1">
                    <div class="slider-header">
                        <div style="display: flex;">
                            <label class="checkbox">
                                <input type="checkbox" checked disabled class="checkbox_input" />
                                <div class="checkbox_div"></div>
                            </label>
                            <h3>Niezbędne pliki cookie</h3>
                        </div>
                        <div>
                            <button @click="SliderBlockActive(1)"><img id="icon-slider1" src="/img/arrow-down-sign-to-navigate.svg" /></button>
                        </div>
                    </div>
                    <div class="slider-space">
                        <p id="text-cookie1">
                            Korzystanie przez nas z niezbędnych plików cookie jest konieczne dla prawidłowego funkcjonowania strony internetowej. Pliki te są instalowane w szczególności na potrzeby zapamiętywania sesji logowania lub wypełniania formularzy, a także w celach związanych ustawieniem opcji prywatności.
                        </p>
                    </div>
                </div>
                <div class="slider-block" id="space-cookie2">
                    <div class="slider-header">
                        <div style="display: flex;">
                            <label class="checkbox">
                                <input id="checkbox2" type="checkbox" class="checkbox_input" />
                                <div class="checkbox_div"></div>
                            </label>
                            <h3>Analityczne pliki cookie</h3>
                        </div>
                        <div>
                            <button @click="SliderBlockActive(2)"><img id="icon-slider2" src="/img/arrow-down-sign-to-navigate.svg" /></button>
                        </div>
                    </div>
                    <div class="slider-space">
                        <p id="text-cookie2">
                            Analityczne pliki cookie umożliwiają sprawdzenie liczby wizyt i źródeł ruchu w naszym serwisie. Pomagają nam ustalić, które strony są bardziej, a które mniej popularne, i zrozumieć, jak użytkownicy poruszają się po stronie. Dzięki temu możemy badać statystki i poprawiać wydajność naszej witryny. Informacje zbierane przez te pliki cookie są agregowane, nie mają więc na celu ustalenie Twojej tożsamości. Jeśli nie zezwolisz na stosowanie tych plików cookie, nie będziemy wiedzieć, kiedy odwiedziłeś naszą stronę internetową.
                        </p>
                    </div>
                </div>
                <div class="slider-block" id="space-cookie3">
                    <div class="slider-header">
                        <div style="display: flex;">
                            <label class="checkbox">
                                <input id="checkbox3" type="checkbox" class="checkbox_input" />
                                <div class="checkbox_div"></div>
                            </label>
                            <h3>Funkcjonalne pliki cookie</h3>
                        </div>
                        <div>
                            <button @click="SliderBlockActive(3)"><img id="icon-slider3" src="/img/arrow-down-sign-to-navigate.svg" /></button>
                        </div>
                    </div>
                    <div class="slider-space">
                        <p id="text-cookie3">
                            Funkcjonalne pliki cookie zapamiętują i dostosowują stronę do Twoich wyborów, np. preferencji językowych. Możesz ustawić przeglądarkę tak, aby blokowała lub ostrzegała o niezbędnych i funkcjonalnych plikach cookie, jednak spowoduje to, że niektóre części witryny mogą nie działać prawidłowo.
                        </p>
                    </div>
                </div>
                <div class="slider-block" id="space-cookie4">
                    <div class="slider-header">
                        <div style="display: flex;">
                            <label class="checkbox">
                                <input id="checkbox4" type="checkbox" class="checkbox_input" />
                                <div class="checkbox_div"></div>
                            </label>
                            <h3>Reklamowe pliki cookie</h3>
                        </div>
                        <div>
                            <button @click="SliderBlockActive(4)"><img id="icon-slider4" src="/img/arrow-down-sign-to-navigate.svg" /></button>
                        </div>
                    </div>
                    <div class="slider-space">
                        <p id="text-cookie4">
                            Reklamowe pliki cookie pozwalają na dopasowanie wyświetlanych treści reklamowych do Twoich zainteresowań, nie tylko na naszej witrynie, ale też poza nią. Mogą być instalowane przez partnerów reklamowych za pośrednictwem naszej strony internetowej. Na podstawie informacji z tych plików cookie i aktywności w innych serwisach jest budowany Twój profil zainteresowań. Reklamowe cookies nie przechowują bezpośrednio Twoich danych osobowych, ale identyfikują Twoją przeglądarkę internetową i sprzęt. Jeśli nie zezwolisz na stosowanie tych plików cookie, dalej będziemy mogli wyświetlać Ci reklamy, jednak nie będą one dopasowane do Twoich preferencji.
                        </p>
                    </div>
                </div>
                <div class="slider-block" id="space-cookie5">
                    <div class="slider-header">
                        <div style="display: flex;">
                            <label class="checkbox">
                                <input id="checkbox5" type="checkbox" class="checkbox_input" />
                                <div class="checkbox_div"></div>
                            </label>
                            <h3>Pliki cookie mediów społecznościowych</h3>
                        </div>
                        <div>
                            <button @click="SliderBlockActive(5)"><img id="icon-slider5" src="/img/arrow-down-sign-to-navigate.svg" /></button>
                        </div>
                    </div>
                    <div class="slider-space">
                        <p id="text-cookie5">
                            Te pliki cookie są instalowane przez naszych partnerów, żeby dopasowywać wyświetlane Ci treści reklamowe w mediach społecznościowych, z których korzystasz. Na podstawie informacji z tych cookies i aktywności w innych serwisach lub w mediach społecznościowych jest budowany Twój profil zainteresowań. Dzięki temu wyświetlane treści są dopasowane do Twoich indywidualnych potrzeb. Cookies mediów społecznościowych nie przechowują bezpośrednio Twoich danych osobowych, ale identyfikują przeglądarkę internetową i sprzęt. Jeśli nie zezwolisz na stosowanie tych plików cookie, nie będziemy też mogli zapobiegać wyświetleniu Ci tej samej reklamy lub umożliwić Ci polubienia i udostępniania naszych treści w mediach społecznościowych.
                        </p>
                    </div>
                </div>
                <div class="control-space">
                    <button @click="UpdateCheckBox(true)" class="control-space-btn" style="color: #3AAB59;">ZAAKCEPTUJ WSZYSTKIE </button>
                    <button @click="UpdateCheckBox(false)" class="control-space-btn" style="color: #FF3333;">ODRZUĆ WSZYSTKIE</button>
                    <button @click="SaveProperty" class="button is-info is-uppercase has-text-weight-semibold has-glow-button button-ebook">ZAPISZ USTAWIENIA</button>
                </div>

                <p class="response-cookie">{{responseCookie}}</p>
            </div>
		</div>
	</section>
</template>

<script>
    export default {
        data() {
            return {
                IsActiveSlider: 0,
                IsOldSlider: 0,
                formData: {
                    AnalyticalCookies: false,
                    FunctionalCookies: false,
                    AdsCookies: false,
                    SocialMediaCookies: false,
                },
                responseCookie: "",
            };
        },
        methods: {
            SliderBlockActive(number) {
                if (this.IsActiveSlider == 0) {
                    let iconslider = document.getElementById('icon-slider' + number);
                    let spacetext = document.getElementById('space-cookie' + number);
                    let textid = document.getElementById('text-cookie' + number);
                    iconslider.style.transition = '0.3s';
                    iconslider.style.transform = 'rotate(-180deg)';
                    spacetext.style.transition = '0.3s';
                    spacetext.style.height = textid.clientHeight + 48 + 'px';
                    textid.style.transition = '0.3s';
                    textid.style.opacity = '1';
                    textid.style.visibility = 'visible';
                    this.IsActiveSlider = number;
                    this.IsOldSlider = number;
                    console.log(textid.clientHeight)
                }
                else {
                    let iconslider = document.getElementById('icon-slider' + this.IsActiveSlider);
                    let spacetext = document.getElementById('space-cookie' + this.IsActiveSlider);
                    let textid = document.getElementById('text-cookie' + this.IsActiveSlider);
                    iconslider.style.transform = 'rotate(0deg)';
                    spacetext.style.height = '47px';
                    textid.style.opacity = '0';
                    textid.style.visibility = 'hidden';
                    this.IsActiveSlider = 0;
                    if (this.IsOldSlider != number) {
                        this.SliderBlockActive(number)
                    }
                }
                
            },
            CheckBoxActiveDefault() {
                if (this.formData.AnalyticalCookies == true) {
                    document.getElementById('checkbox2').checked = true;
                }
                if (this.formData.FunctionalCookies == true) {
                    document.getElementById('checkbox3').checked = true;
                }
                if (this.formData.AdsCookies == true) {
                    document.getElementById('checkbox4').checked = true;
                }
                if (this.formData.SocialMediaCookies == true) {
                    document.getElementById('checkbox5').checked = true;
                }
            },
            UpdateCheckBox(value) {
                document.getElementById('checkbox2').checked = value;
                document.getElementById('checkbox3').checked = value;
                document.getElementById('checkbox4').checked = value;
                document.getElementById('checkbox5').checked = value;
            },
            SaveProperty() {
                if (document.getElementById('checkbox2').checked) {
                    this.formData.AnalyticalCookies = true;
                }
                else this.formData.AnalyticalCookies = false;
                if (document.getElementById('checkbox3').checked) {
                    this.formData.FunctionalCookies = true;
                }
                else this.formData.FunctionalCookies = false;
                if (document.getElementById('checkbox4').checked) {
                    this.formData.AdsCookies = true;
                }
                else this.formData.AdsCookies = false;
                if (document.getElementById('checkbox5').checked) {
                    this.formData.SocialMediaCookies = true;
                }
                else this.formData.SocialMediaCookies = false;
                let self = this;
                self.$http
                    .post(`spa/cookies/editcookieproperty`, this.formData)
                    .then(response => {
                        this.responseCookie = response.data;
                        setTimeout(this.ClearResponse, 5000);
                    })
                    .catch(() => { });
            },
            ClearResponse() {
                this.responseCookie = "";
            }
        },
        created: function () {
            let self = this;
            self.$http
                .get(`spa/cookies/getcookieproperty`)
                .then(response => {
                    this.formData = response.data;
                    this.CheckBoxActiveDefault();
                })
                .catch(() => { });
        }
    }
</script>

<style lang="scss">
	.header-page{
		text-align: center;
	}
    .text-header {
        font-family: Open Sans;
        font-style: normal;
        font-size: 30px;
        font-weight: 600;
    }
    .block-content {
        margin-top: 68px;
        display: flex;
        /*justify-content: center;*/
    }
	.block-information{
		margin-left: 232px;
	}
    .block-information h2 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
    }
    .block-information p {
        width: 604px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }
    .block-settings{
        margin-left: 186px;
    }
    .block-settings h2 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
    }
    .slider-block {
        width: 666px;
        height: 47px;
        background-color: white;
        padding-bottom: 10px;
        border: 1px solid #4A4A4A;
        margin-bottom: 10px;
    }
    .slider-header {
        justify-content: space-between;
        width: 666px;
        display: flex;
    }
    .slider-block h3 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        margin-top: 8px;
        margin-left: 23px;
    }
    .slider-block img {
        margin-right: 10px;
        margin-top: 10px;
        width: 19px;
        height: 19px;
    }
    .slider-space p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        width: 489px;
        color: #4A4A4A;
        margin-left: 82px;
        opacity: 0;
        visibility: hidden;
    }
    .checkbox{
        height: 27px;
    }
    .checkbox_div {
        position: relative;
        width: 53px;
        height: 27px;
        border-radius: 53px;
        border: 1px solid #3AAB59;
        margin-top: 6px;
        margin-left: 6px;
        transition: 0.3s;
    }
    .checkbox_input{
        display: none;
    }
    .checkbox_input:checked ~ .checkbox_div {
        background-color: #3AAB59;
    }
    .checkbox_input:checked ~ .checkbox_div::before {
        background-color: #46D86E;
        margin-left: 27px;
    }
    .checkbox_input:disabled ~ .checkbox_div {
        background-color: #68A578;
        cursor: default;
    }

    .checkbox_input:disabled ~ .checkbox_div::before {
        background-color: #9BE0AE;
        margin-left: 27px;
        cursor: default;
    }
    .checkbox_div::before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        background-color: #3AAB59;
        transition: 0.4s;
    }
    .control-space {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }
    .control-space-btn {
        margin-right: 74px;
        margin-top: 6px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }
    .response-cookie {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #3AAB59;
        margin-top: 15px;
        text-align: center;
    }
</style>
