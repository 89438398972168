<template>
	<main>
		<HomePageUser />
		<Calendar />
		<ImplementationProgramMineUsers />
		<OnboardingMineUsers />
		<BannerLiveLecture />
		<HomePageWorkshops />
		<HomePageArticles />
		<HomePageNews />
		<HomePageStats />
		<HomePageElearnings />
		<HomePageConferences imageSrc="/assets/2018/images/baner_kongres_FINAL.jpg" />
	</main>
</template>

<script>
import HomePageUser from '@/components/Containers/ViewHomePage/HomePageUser.vue';
import HomePageStats from '@/components/Containers/ViewHomePage/HomePageStats.vue';
import ImplementationProgramMineUsers from '@/components/Containers/ViewImplementationProgram/ImplementationProgramMineUsers.vue';
import OnboardingMineUsers from '@/components/Containers/ViewOnboarding/OnboardingMineUsers.vue';
import HomePageNews from '@/components/Containers/ViewHomePage/HomePageNews.vue';
import HomePageArticles from '@/components/Containers/ViewHomePage/HomePageArticles.vue';
import HomePageElearnings from '@/components/Containers/ViewHomePage/HomePageElearnings.vue';
import HomePageWorkshops from '@/components/Containers/ViewHomePage/HomePageWorkshops.vue';
import HomePageConferences from '@/components/Containers/ViewHomePage/HomePageConferences.vue';
import BannerLiveLecture from '@/components/BannerLiveLecture.vue';
import Calendar from '@/components/Calendar.vue';

export default {
	name: 'ViewHomePage',
	components: {
		HomePageUser,
		HomePageNews,
		HomePageArticles,
		HomePageElearnings,
		HomePageWorkshops,
		ImplementationProgramMineUsers,
        OnboardingMineUsers,
		HomePageConferences,
		BannerLiveLecture,
		HomePageStats,
		Calendar
	},

	data() {
		return {
			expanded: false,
		};
	},

	beforeCreate: function () {
		let self = this;
		self.$http
			.get(`spa/user/getRedirect`)
			.then((response) => {
				if (
					typeof response.data.IsRedirect != 'undefined' &&
					response.data.IsRedirect != null &&
					response.data.IsRedirect == true
				) {
					// w tym przypadku jeżli to było pobieranie pliku
					window.location = response.data.Redirect;
				}
			})
			.catch((ex) => {
				console.log('wystąpił błąd ' + ex);
			});
	}
};
</script>