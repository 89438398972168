<template>
    <section :class="`__sets`">
        <h2 class="-title is-size-2 has-text-centered">
            {{ title }}
        </h2>

        <ul v-bind:class="{'__grid' : implementationPrograms.length > 1}">
            <li v-for="set in implementationPrograms" :key="set.Title" v-bind:class="{'__grid-item' : implementationPrograms.length > 1}">
                <!-- The [download] attribute won't be present if the isAvailableToDownload key is set to 'false' -->
                <a href="#"
                   v-on:click.prevent="confirmUserAssign(set.Id)"
                   class="__tile">
                    <h3 class="-title is-size-4">
                        {{ set.Title }}
                    </h3>
                    <p class="-description has-text-weight-light">
                        {{ set.Text }}
                    </p>
                </a>
            </li>
        </ul>



        <b-modal :active.sync="showDialog"
                 :canCancel="[]"
                 class="modal-form modal-dialog rodo-else-pop"
                 :width="830"
                 scroll="keep">
            <br />
            <br />
            <p class="msg-start">
                Witamy <br /> w programie szkoleniowo-wdrożeniowym na platformie Akademii Umiejętności Eurocash. Jeśli chcesz przystąpić do programu kliknij ROZPOCZNIJ. Pamiętaj, że Twój czas na realizację programu będzie liczony od momentu rozpoczęcia programu.

            </p>

            <div class="is-flex form-actions">
                <button type="submit"
                          @click="assignToProgram()"
                        class="button is-info  is-uppercase has-text-weight-semibold has-glow-button">

                    <span class="is-size-7-em has-text-white">ROZPOCZNIJ</span>
                </button>
               
                
                <a class="button is-danger is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button"
                  @click="showDialog=false">
                    <span class="is-size-7-em has-text-white">JESZCZE NIE TERAZ</span>
                </a>
            </div>




        </b-modal>
    </section>
</template>

<script>
export default {
	props: ['title', 'implementationPrograms'],

        data() {
            return {
                showDialog: false,
                currentProgramId: 0
            }
        },

	methods: {
		// DELETE ME
		/**
		 * @description This method will get you the hardcoded URL address for the set with a given title.
		 * This is absolutely NOT a good solution, but it's only TEMPORARY.
		 *
		 * @param {String} title The title of the set.
		 * @returns {String|Boolean} The URL address that the <a> should point to
		 * or plain 'false' boolean if the set's title didn't match any of the switch cases.
        */

        confirmUserAssign(implementationProgramId) {
            this.currentProgramId = implementationProgramId;
            this.$http
                .get(`spa/ImplementationPrograms/${implementationProgramId}/isusersubscribed`)
                .then(response => {
                    let isAssigned = response.data;
              
                    if (isAssigned) {
                        this.goToImplementationProgram();
                    }
                    else {
                        this.showDialog = true;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        assignToProgram() {
            if (this.currentProgramId !== 0) {
                this.$http
                    .get(`spa/ImplementationPrograms/${this.currentProgramId}/subscribe`)
                    .then(response => {
                        let isAssigned = response.data;

                        if (isAssigned) {
                            this.goToImplementationProgram();

                        }
                        else {
                            this.showDialog = true;
                    
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },

        goToImplementationProgram() {
            this.$router.push(`/programy-wdrozeniowe/${this.currentProgramId}`);
        }
    },

    mounted() {
        console.log(this.implementationPrograms);
    }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';
    .msg-start {
        text-align: center;
    }
.__sets {
	margin-bottom: 50px;
	@include tablet {
		margin-bottom: 57px;
	}

	> .-title {
		margin-bottom: 20px;
	}
}

.__grid {
	// Mobile:
	// - 1 column
	// - flexible height rows
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto;
	grid-row-gap: 20px;

	// Tablet:
	// - 2 columns
	// - equal height rows
	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		grid-column-gap: 20px;
	}
}

.__tile {
	display: flex;
	height: 100%; // To fill the parent <li> element
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;

	padding-top: 24px;
	padding-bottom: 26px;
	padding-left: 21px;
	padding-right: 60px;

	background-color: white;
	color: $grey-dark;
	border-radius: 4px;
	box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
	transition: all 200ms ease-in-out;

	&:hover {
		color: white;
		background-color: $green-lighter;

		&::before,
		&::after {
			background-color: white;
		}
	}

	position: relative;
	// The ::before and ::after pseudoelements are used to create the chevron pointing right.
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 20px;
		top: 50%;
		transform-origin: center right;

		width: 15px;
		height: 1.4px;

		background-color: $green;
		transition: all 200ms ease-in-out;
	}

	// The amount of degrees that the ::before
	// and ::after pseudoelements are to be rotated.
	$rotate-degree: 50deg;
	// Rotate the ::before pseudoelement by the POSITIVE value.
	&::before {
		transform: rotate($rotate-degree);
	}
	// Rotate the ::after pseudoelement by the NEGATIVE value.
	&::after {
		transform: rotate(-$rotate-degree);
	}

	.-title {
		margin-bottom: 20px;
	}
}

    .modal {
        &-form

    {
        & > :nth-child(2)

    {
        padding: rem(10px 10px 20px);

        @include tablet {
            padding: rem(10px 30px 30px);
        }
    }

    .control {
        margin-bottom: rem(30px);
        .input__container

    {
        margin-bottom: 0;
    }

    }

    .form-actions {
        justify-content: center;
        margin-top: rem(50px);
        & > :first-child

    {
        margin-right: rem(20px);
    }

    .button {
        padding: rem(20px 50px);
        float: left;
    }

    .button {
        padding: 0;
        float:left;
        @include tablet {
            padding: rem(0 30px);
        }
    }

    }

    h2 {
        margin-bottom: rem(5px);
    }

    p {
        line-height: 1.36;
        letter-spacing: 0.3px;
    }

    label + ol {
        padding-left: rem(50px);
        margin: rem(10px 0);
    }

    ol {
        padding-left: rem(20px);
        margin-bottom: rem(10px);
    }

    .input_wraps {
        padding: rem(10px 0);

        @include tablet {
            padding: rem(10px 150px);
        }

        .control

    {
        p

    {
        margin-bottom: 0;
    }

    }
    }

    .rodo-wrap {
        label

    {
        display: block;
        margin: -10px 0 15px;
        input

    {
        margin-right: 10px;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }

    }
    }

    .b-checkbox.checkbox.is-small {
        & > :nth-child(2)

    {
        border: 1px solid $grey-light;
        border-radius: 0;
    }

    }
    }

    .columns {
        flex-wrap: wrap;
    }

    .button {
        color: rgba(58, 171, 89, 0.5);
        border-radius: 5px;
        height: 50px;
        width: 100%;
        margin-right : 20px;
        &:not(:last-child)

    {
        margin-bottom: rem(20px);
    }

    span {
        color: white;
    }

    .icon {
        margin-right: 5px;
    }

    &.is-danger {
        color: rgba(215, 33, 65, 0.5);
    }

    }
    }







</style>
