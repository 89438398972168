<template>
  <main>
    <BaseHeroSearch :title="'Kursy e-learningowe'" :placeholder="'Szukaj szkolenia'" :search.sync="search" :showFilters.sync="showFilters" :hasFilters="true" :hasSearch="true"/>
    <Elearnings :search.sync="search" :showFilters.sync="showFilters"/>
  </main>
</template>

<script>
import BaseHeroSearch from "@/components/BaseHeroSearch.vue";
import Elearnings from "@/components/Containers/ViewElearnings/Elearnings.vue";

export default {
  name: "ViewElearnings",
  components: {
    BaseHeroSearch,
    Elearnings
  },
  data() {
    return {
      search: "",
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
