<template>
    <section v-if="isToDisplay" class="conference-banner section">
        <div class="container">
            <a class="conference-banner-box" :href="'/home/konferencje/'">
                <div class="conf-banner-txt">
                    <div>
                        <h1 class="is-size-2 has-text-weight-bold">{{currentLecture.Title}}</h1>
                        <p class="conf-place has-text-weight-bold">{{currentLecture.Leader}}</p>
                        <p class="main-topic has-text-weight-bold" v-if="currentLecture.CycleTitle"><span>Cykl:</span> {{currentLecture.CycleTitle}}</p>
                        <!--<div class="conf-add-info">
                            <p class="has-text-left" v-if="currentLecture.NewConferenceAdditionalInfoFirst">{{currentLecture.NewConferenceAdditionalInfoFirst}}</p>
                            <p class="has-text-right" v-if="currentLecture.NewConferenceAdditionalInfoSecond">{{currentLecture.NewConferenceAdditionalInfoSecond}}</p>
                        </div>-->
                        <p>Do wykładu pozostało: </p>
                        <counterToDate v-if="currentLecture.Id > 0" :starttime="Date.now()" :endtime="currentLecture.EventDate" />
                    </div>
                </div>
                <div class="conf-banner-bck" v-bind:style="{ backgroundImage: 'url(' + currentLecture.BannerImage + ')' }"></div>
                <div class="conf-grad"></div>
            </a>
        </div>
    </section>
   
</template>

<script>
    import global from "@/mixins/global.js";
    import CounterToDate from "@/components/CounterToDate.vue";
export default {
    name: "BannerLiveLecture",
    mixins: [global],
    components: {
        CounterToDate,
    },
    data() {
        return {
            currentLecture: {},
            isToDisplay: false,
        }
    },
    methods: {
        setConference(conference) {
            this.currentLecture = conference; 
        },

        loadNextLiveLecture()
        {
            let self = this;
            self.$http
                .get(`spa/livelecture/nextlivelecture`)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data !== null) {
                        self.currentLecture = response.data;
                        self.isToDisplay = true;
                    }
                    else {
                        self.isToDisplay = false;
                    }
                   
                })
                .catch(() => {
    self.isToDisplay = false;
    console.log("Błąd ładowania wykładów online")
    });
        }
    },
      created: function () {
           
            this.loadNextLiveLecture();
        }


};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.conference-banner {
    margin: 1.125rem auto 2rem;
    border-radius: 5px;

    .conference-banner-box { 

        @include tablet {
            position: relative;
            min-height: 330px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        &:hover {
            p {
                color: $green;
            }

            .conf-add-info {
                p {
                    color: $grey-dark;
                }
            }
        }

        .conf-banner-txt {
            padding: 1.5rem;
            background-color: $white;

            @include tablet {
                background-color: transparent;
                width: 45%;
                margin: 0 2rem;
                padding: 1rem 0;
                z-index: 2;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }

            > div {
                width: 100%;
            }

            h1 {
                color: $green;
            }

            .conf-place {
                margin-bottom: 1rem;
                color: $grey-dark;
            }

            > p {
                display: none;
                color: $green;

                @include tablet {
                    display: block;
                }
            }

            .main-topic {
                display: none;

                span {
                    display: block;
                    color: $grey-dark;
                    font-weight: normal;
                }
                
                @include desktop {
                    display: block;
                }
            }

            .conf-add-info {
                margin: 0.8rem -0.5rem;
                display: none;
                color: $grey-dark;

                @include desktop {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                }

                p {
                    padding: 0 0.5rem;
                }

            }

        }

        .conf-banner-bck {
            background-size: cover;
            background-position: center;
            width: 100%;
            padding-bottom: 40%;

            @include tablet {
                width: 55%;   
                background-position: right;
                padding-bottom: 0;
            }

            
        }

        .conf-grad {
            display: none;

            @include tablet {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,1);
                background: -moz-linear-gradient(left, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 65%);
                background: -webkit-gradient(left top, right top, color-stop(50%, rgba(255,255,255,1)), color-stop(65%, rgba(255,255,255,0)));
                background: -webkit-linear-gradient(left, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 65%);
                background: -o-linear-gradient(left, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 65%);
                background: -ms-linear-gradient(left, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 65%);
                background: linear-gradient(to right, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 65%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
            }
        }

    }
}

</style>
