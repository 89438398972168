<template>
  <main>
    <BaseHero :title="management.FullName ? management.FullName : ''"/>
    <AdminPanel :management="management" />
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import AdminPanel from "@/components/Containers/ViewAdminPanel/AdminPanel.vue";

export default {
  name: "ViewAdminPanel",
  components: {
    BaseHero,
    AdminPanel
  },
  data() {
    return {
      management: {}
    };
  },
  created: function() {
    let self = this;
    self.$http
      .get(`/spa/navigation/managementpanel`)
      .then(response => {
        self.management = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
