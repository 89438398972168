<template>
	<main class="container">
		<!-- Heading -->
		<div class="columns">
			<div class="column">
				<h1 class="title is-size-1 has-text-weight-light has-text-left has-text-shadow">
					Zestawy szkoleń
				</h1>
			</div>
		</div>
		<!-- END | Heading -->
		<!-- Banner -->
		<section class="__banner is-hidden-mobile columns is-marginless">
			<div class="column is-paddingless">
				<img :src="banner.src" :alt="banner.alt" />
			</div>
		</section>
		<!-- END | Banner -->
		<!-- Sets -->
		<ImplementationPrograms v-if="typeof (implementationProgramSet) !== 'undefined' && implementationProgramSet!== null && implementationProgramSet.ImplementationPrograms !== null && implementationProgramSet.ImplementationPrograms.length>0"
								:title="implementationProgramSet.Title"
								:implementationPrograms="implementationProgramSet.ImplementationPrograms ">
		</ImplementationPrograms>
		<Onboardings v-if="typeof (OnboardingSet) !== 'undefined' && OnboardingSet !== null && OnboardingSet.Onboardings !== null && OnboardingSet.Onboardings.length > 0"
								:title="OnboardingSet.Title"
								:implementationPrograms="OnboardingSet.Onboardings">
		</Onboardings>
		<Set v-for=" set in sets"
			 v-if="sets!== null  && sets.length > 0"
			 :key="set.Title"
			 :title="set.Title"
			 :sets="set.Paths">
		</Set>
		<!-- END | Sets -->

		<BaseModalInfo v-bind="modalInfo" />
	</main>
</template>

<script>
import Set from '@/components/Containers/ViewWorkshopSets/Set.vue';
import ImplementationPrograms from '@/components/Containers/ViewWorkshopSets/ImplementationPrograms.vue';
import Onboardings from '@/components/Containers/ViewWorkshopSets/Onboarding.vue';
import BaseModalInfo from '@/components/BaseModalInfo.vue';

export default {
	components: {
        Set,
		ImplementationPrograms,
		Onboardings,
		BaseModalInfo,
	},

	data() {
		return {
			// Array containing the workshop sets.
            sets: [],
            //Set of 
            implementationProgramSet: null,
            OnboardingSet: null,

			// Object describing the top banner.
			banner: {
				src: '',
				alt: '',
			},

			// This property indicates whether the middle section is to be rendered.
			isDc: true,

			// This object is bind to the BaseModalInfo component.
			modalInfo: {
				message: '',
				buttonLabel: '',
				isActive: false,
			},
		};
	},

	methods: {
		/**
		 * @description This method will try to fetch the workshop sets from the API.
		 * If it succeeds, the sets will be printed. If it fails, there will be shown the info modal.
		 */
        getSets() {
            let self = this;
            self.$http
                .get('/spa/implementationPrograms')
				.then(response => {
                    self.implementationProgramSet = response.data;
                    if (self.implementationProgramSet !== null && self.implementationProgramSet.ImplementationPrograms!== null && self.implementationProgramSet.ImplementationPrograms.length > 0) {
                        self.banner.src = response.data.Banner;
                    }
                })
                .catch(() => {

				});

            self.$http
                .get('/spa/Onboarding')
				.then(response => {
					self.OnboardingSet = response.data;
					if (self.OnboardingSet !== null && self.OnboardingSet.Onboardings  !== null && self.Onboarding.Onboardings.length > 0) {
                        self.banner.src = response.data.Banner;
                    }
                })
                .catch(() => {

                });

			this.$http
				.get('/spa/elearning/paths')
				.then(response => {
					this.banner.src = response.data.Banner;
					this.sets = response.data.Containers;
				})
				.catch(() => {
					this.openModalInfo();
				});
		},

		/**
		 * @description This method will open the modal with the information about the failed sets fetch action.
		 */
		openModalInfo() {
			this.modalInfo = {
				...this.modalInfo,
				message: 'Nie udało się pobrać zestawów. Spróbuj ponownie później.',
				buttonLabel: 'OK',
				isActive: true,
			};
		},

		/**
		 * @description This method will close the info modal and clear its data.
		 */
		closeModalInfo() {
			this.modalInfo = {
				...this.modalInfo,
				message: '',
				buttonLabel: '',
				isActive: false,
			};
		},
	},

	beforeMount() {
		this.getSets();
	},
};
</script>

<style lang="scss" scoped>
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

main.container {
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 21px;

	@include desktop {
		padding-left: 0;
		padding-right: 0;
		padding-top: 26px;
	}
}

.__banner {
	img {
		width: 100%;

		margin-bottom: 50px;
		@include tablet {
			margin-bottom: 57px;
		}

		// Set the fixed height for the image when it's loading or when it has failed to load.
		&[lazy='error'],
		&[lazy='loading'] {
			height: 200px;
		}

		// Correctly loaded image can set its dimensions by itself (no fixed height).
		&[lazy='loaded'] {
			height: auto;
		}
	}
}
</style>
