<template>
	<section class="conferences section">
		<div class="container pw" v-if="week !== null">
			<div class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left pw__container">
				<div class="pw__progress" v-if="(programWeekView.IsCompleted || (!programWeekView.IsCompleted && !programWeekView.IsTimeElapsed))">
					<p class="is-size-7">Postęp w realizacji</p>
					<p class="is-size-7">Onboarding</p>

					<vue-ellipse-progress :progress="programWeekView.PercentDone"
										  :angle="-90"
										  color="white"
										  emptyColor="#6ddd8c"
										  :size="100"
										  :thickness="10"
										  emptyThickness="10"
										  lineMode="normal"
										  :legend="true"
										  :legendValue="0"
										  legendClass="legend-custom-style"
										  animation="reverse 700 400"
										  :noData="false"
										  :loading="false"
										  fontColor="white"
										  :half="false"
										  fontSize="1rem">

						<span slot="legend-value">%</span>
					</vue-ellipse-progress>
				</div>

				<div class="pw__progress fail" v-else>
					<p class="is-size-7">Postęp w realizacji</p>
					<p class="is-size-7">Onboarding</p>
					<vue-ellipse-progress :progress="programWeekView.PercentDone"
										  :angle="-90"
										  color="white"
										  emptyColor="#868686"
										  :size="100"
										  :thickness="10"
										  emptyThickness="10%"
										  lineMode="normal"
										  :legend="true"
										  :legendValue="0"
										  legendClass="legend-custom-style"
										  animation="reverse 700 400"
										  :noData="false"
										  :loading="false"
										  fontColor="white"
										  :half="false"
										  fontSize="1rem">

						<span slot="legend-value">%</span>
					</vue-ellipse-progress>
				</div>

				<div class="pw__data-text">
					<div class="conferences-title-first">
						Programy szkoleniowe on-line
						<input type="button" class="back is-size-7" value="SPRAWDŹ MOJE STATYSTYKI" @click="goToMyStats()" />
					</div>
					<div class="conferences-title">
						{{ programWeekView.Title }}
					</div>
					<div class="conferences-intro">
						<p class="has-text-weight-light">
							{{programWeekView.Text}}
						</p>
					</div>
				</div>
			</div>

			<div v-for="week in programWeekView.Weeks" class="conferences-days" v-if="!programWeekView.IsTimeElapsed" style="padding-bottom: 70px">
				<div class="conferences-week">
					<div class="conferences-week--nav">
						<button v-if="programWeekView.IsNavigationEnabled && programWeekView.PrevWeekId !== 0" @click.prevent="prevWeek()">Poprzedni</button>
						<h2 class="is-size-4 full-width">{{week.Title}}</h2>
						<button v-if="programWeekView.IsNavigationEnabled  && programWeekView.NextWeekId !== 0" @click.prevent="nextWeek()">Następny</button>
					</div>
					<p class="has-text-weight-light">
						{{week.Text}}
					</p>
				</div>
				<div class="columns">
					<div class="column">
						<div class="columns is-paddingless is-multiline">
							<div class="column is-full" v-for="day in week.Days" :key="day.Id">
								<BaseCardOnboardingDay @goToUrlHandler="goToUrlHandler('/onboardings/'+$route.params.id+'/day/' + day.Id)" :day="day" /> <!--TODO: check this-->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<div class="conferences-days--pass" v-if="programWeekView.IsCompleted">
					<img src="/assets/img/layout/courses/pass.svg" />
					<p class="conferences-days--state">Gratulacje!</p>
					<p> Zakończyłeś program szkoleniowo-wdrożeniowy. Jesteś już gotowy do pracy w Delikatesach Centrum. Życzymy sukcesów w dalszej przygodzie w handlu!</p>
				</div>

				<div class="conferences-days--failed" v-else>
					<img src="/assets/img/layout/courses/failed.svg" />
					<p class="conferences-days--state">  KURS NIEUKOŃCZONY</p>
					<p> Upss... nie udało się ukończyć kursu</p>
					<p>Nie poddawaj się i spróbuj rozpocząć nowy kurs</p>
				</div>
			</div>
		</div>

		<b-modal :active.sync="isEndDialog"
				 :width="960"
				 :canCancel="['escape', 'x', 'outside']"
				 scroll="keep"
				 class="modal-dialog">
			<button type="button" class="modal-close is-large" @click="closeEndProgramDialog"></button>
			<br />

			<div class="modal-dialog--content" v-if="programWeekView.IsCompleted">
				<img src="/assets/img/layout/modal/pass.svg" />
				<p class="modal-dialog--result"></p>
				<!--<p class="modal-dialog--result">Próg zaliczenia to: {{examResult.PassPercent}}%</p>-->
			<p class="modal-dialog--info">
				Gratulacje!<br />
				Zakończyłeś program szkoleniowo-wdrożeniowy. Jesteś już gotowy do pracy w Delikatesach Centrum. Życzymy sukcesów w dalszej przygodzie w handlu!
				Zakończyłeś program Onboardingu. Jesteś gotowy do pracy w firmie Eurocash.
			</p>
			</div>

			<div class="modal-dialog--content" v-else>
				<img src="/assets/img/layout/modal/failed.svg" />
				<!--<p class="modal-dialog--result">Twój wynik testu to: {{examResult.PercentDone}}%</p>
				<p class="modal-dialog--result">Próg zaliczenia to: {{examResult.PassPercent}}%</p>-->
			<p class="modal-dialog--info">
				Niestety nie udało Ci się zaliczyć programu Onboardingu. Jednak nic straconego! Możesz poprawić uzyskany wynik.
			</p>
			</div>

			<button :class="{crbutton : true, success: programWeekView.IsCompleted }" type="button" @click="closeEndProgramDialog">Zamknij</button>
		</b-modal>

	</section>
</template>

<script>
	import BaseCardOnboardingDay from "@/components/BaseCardOnboardingDay.vue";
	import global from "@/mixins/global.js";
	import 'vue-multi-select/dist/lib/vue-multi-select.css';


	export default {
		name: "OnboardingProgramWeek",

		mixins: [global],

		components: {
			BaseCardOnboardingDay,
		},

		data() {

			return {
				programWeekView: {},
				weekId: null,
				isEndDialog: false,
			};
		},

		computed: {
			week: function () {
				if (this.programWeekView && this.programWeekView.Weeks) {
					return this.programWeekView.Weeks[0];
				}

				return null;
			}
		},

		methods: {
			closeEndProgramDialog() {
				let self = this;
				const url = `/spa/Onboarding/ConfirmEnd/${self.$route.params.id}`
				
				self.$http.get(url)
					.then(() => {

						this.isEndDialog = false;
					})
					.catch((e) => { console.log(e); });
			},

			goToMyStats() {
				const url = `/moje-dane`;
				this.goToUrlHandler(url);

			},

			prevWeek() {
				this.weekId = this.programWeekView.PrevWeekId;
				this.loadData()
			},

			nextWeek() {
				this.weekId = this.programWeekView.NextWeekId;
				this.loadData();
			},

			loadData() {
				let self = this;
				let url = '';

				if (this.weekId === null) {
					url = `/spa/Onboarding/${self.$route.params.id}/currentweekview`;
				} else {
					url = `/spa/Onboarding/${self.$route.params.id}/currentweekview/${this.weekId}`;
				}

				self.$http
					.get(url)
					.then(response => {
						self.programWeekView = response.data;
						self.isEndDialog = response.data.ShowEndPopup || false;

						self.$set(self.$store.state, 'breadcrumb', self.programWeekView.Title);
						console.log(response)
					})
					.catch((e) => { console.log(e); });
			}
		},

		created: function () {
			this.loadData();
		},
	};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.pw {
	&__container {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}

	&__progress {
		background-color: $green-lighter;
		width: 210px;
		height: 210px;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 20px 10px 0 10px;
		
		p {
			color: #FFFFFF;
		}

		p:not(:first-child) {
			font-weight: bold;
			margin-top: 10px;
		}

	}

	.ep-container {
		margin: auto;
	}

	&__progress.fail {
		background-color: $grey-dark;
	}

	&__data-text {
		background-color: #FFFFFF;
		flex: 1;
		padding: 20px 45px;
			
		.conferences-title-first {
			font-size: 14px;
			font-weight: bold;
			color: #3AAB59;
		}

		.conferences-title {
			font-size: 24px;
			font-weight: bold;
			color: $grey-dark;
			margin: 20px 0 10px;
		}

		.conferences-intro {
			font-size: 14px;
			color: $grey-dark;
		}

	}
}

.conferences.section {
	background-color: #F1F3F4;
	padding: 2.5rem 1.25rem 3rem;
	
	.conferences-week {
	text-align: center;
	color: white;

		&--nav {
			background-color: $green-lighter;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			justify-content: space-between;
			align-items: center;

			button {
				display: block;
				text-transform: uppercase;
				color: white;
				background-color: $green-lighter;
				border: 1px solid white;
				height: 43px;
				width: 171px;
				font-weight: bold;
				font-size: 1.2rem;
			}

			button:first-child {
				margin-left: 1rem;

				&:before {
					content: '<';
					margin-right: 2rem;
				}

			}

			button:last-child {
				margin-right: 1rem;

				&:after {
					content: '>';
					margin-left: 2rem;
				}
			}
		}

		h2 {
			padding: 17px 0;
			display: block;
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
		}

		p {
			color: $grey-dark;
			font-size: 14px;
			margin: 20px 75px;
		}
	}

	.conferences-days {
		background-color: white;
		opacity: 1;

		> .columns {
			> .column {
				margin: 0 60px;
			}
		}

		.column {
			padding: 0.4rem 0.75rem;
		}

		.column.is-full {
			height: 100px
		}

		.column.is-full:last-child {
			margin-bottom: 10px;
		}

		&--pass {
			background-color: $green-lighter;
			text-align: center;
	
			img {
				margin: auto;
				padding: 4rem;
				display: block;
			}

			p {
				color: white;
			}

			> p:last-child {
				padding: 1rem 0 3rem 0;
			}
		}

		&--failed {
			background-color: white;
			text-align: center;

			img {
				margin: auto;
				padding: 3rem 0 1rem 0;
				display: block;
			}

			p {
				color: $grey-dark;
			}

			> p:not(:last-child) {
				padding: 1rem 0 0 0;
			}

			> p:last-child {
				padding: 0 0 3rem 0;
			}

		}

		&--state {
			font-size: 1.5rem;
			font-weight: bold;
		}
	}

	.conferences-more {
		text-align: center;
	}

	.button {
		padding: 25px 50px;
	}

}

.back {
	background-color: $green-lighter;
	border: none;
	color: white;
	font-weight: bold;
	padding: 12px 20px;
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
}

.conf-sign-form {
	.card {
		margin: 4rem 0;
		border: 1px solid #f2f2f2;
		padding: 2.5rem 1rem 1.85rem;

		@include tablet {
			padding: 2.5rem 2.5rem 1.85rem;
		}

		p {
			color: #4a4a4a;
		}

		.form-wrap {
			input {
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				color: #686868;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type=number] {
				-moz-appearance: textfield;
			}

			select {
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				font-size: 1rem;
				padding: 1rem;
				color: #686868;
			}

			button[type='submit'] {
				color: $white;
				/*margin-top: 5rem;*/
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
			}

			.sign-you {
				p {
					margin-bottom: 2rem;
				}

			}

			.sing-worker {
				margin-top: 2rem;

				> p {
					margin-bottom: 2rem;
				}

				.worker-row {
					margin: 2rem 0;
				}

				.worker-btns {
					@include tablet {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-pack: justify;
						-ms-flex-pack: justify;
						justify-content: space-between;
					}

					button {
						display: inline-block;
						width: 100%;
						height: 50px;
						padding: 0 1rem;
						line-height: 44px;
						margin-top: 1rem;
						max-width: 100%;
						transition: 0.5s all ease-in-out;
						cursor: pointer;

						@include tablet {
							width: 290px;
							margin-top: 0.5rem;
						}

						&:hover {
							opacity: 0.7;
						}

						&.add-worker {
							color: $green-lighter;
							border: 2px solid $green-lighter;
						}

						&.remove-worker {
							color: $white;
							border: 2px solid $red;
							background-color: $red;
						}

						svg {
							vertical-align: middle;
							margin-right: 0.6rem;
						}

						span {
							vertical-align: middle;
							display: inline-block;
						}
					}
				}
			}

			.signworkers-clause {
				margin-top: 30px;
			}

			.guest-regulation {
				margin-top: 30px;

				label {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;

					input {
						height: 14px;
						width: auto;
						margin-right: 15px;
						margin-top: 5px;
						margin-bottom: 0;
					}

					span {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}
	}
}

.live-lecture-form {
	max-width: 729px;
}

.select:not(.is-multiple):not(.is-loading)::after {
	display: none;
}

.input-msg {
	position: relative;
	top: -5px;
}

.select {
	width: 100%;
}

.btn-select {
	width: 100% !important;
}

input.has-error,
select.has-error {
	border-color: $red !important;
}

.subPiont {
	margin-left: 20px;
}

.full-width {
	width: 100%;
}

.modal-dialog {
	.modal-close {
		right: 20px !important;
		top: 15px !important;
	}

	.modal-close:before {
		height: 4px !important;
		width: 85% !important;
		background-color: $grey-dark !important;
	}

	.modal-close:after {
		width: 4px !important;
		height: 85% !important;
		background-color: $grey-dark !important;
	}

	img {
		margin-bottom: 1rem;
	}

	&--content {
		width: 800px;
		text-align: center;
	}

	&--info {
		font-weight: bold;
		font-size: 1.5rem;
	}

	&--result {
		margin-bottom: 2.5rem !important;
	}

	button.crbutton {
		display: block;
		margin: auto;
		background-color: $grey-dark;
		color: white;
		font-size: 1rem;
		padding: 18px 92px;
		border: none;
		font-weight: lighter;
		text-transform: uppercase;
	}

	button.crbutton.success {
		background-color: $green-lighter;
	}

}

#multiSelect > button {
	width: 100%;
}

#multiSelect.has-error > button {
	border-color: #de2647;
}
</style>