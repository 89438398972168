<template>
    <div>
        <nav class="is-flex">
            <div class="navbar-brand">
                <span class="navbar-item">
                    <img src="/assets/2018/images/logo.png" alt="Akademia Eurocash">
                </span>
            </div>
            <div class="navbar-menu" v-if="hasBackBtn">
                <div class="navbar-end">
                    <div class="navbar-item">
                        <a class="button is-danger has-text-weight-semibold has-glow-button" @click="$emit('closeModalHandler')">
                            <span class="is-size-7">Powrót<button class="delete"></button></span>
                        </a>
                    </div>
                </div>
            </div>
        </nav>      
    </div>
</template>

<script>
export default {
  name: "BaseModalNav",
  components: {},
  props: {
    hasBackBtn: {
      type: Boolean
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

nav {
  position: fixed;
  z-index: 41;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  .navbar-menu {
    display: block;
    box-shadow: none;
    background: none;
    width: 100%;
    .button {
      width: 100%;
    }
    @include desktop {
      width: auto;
      .button {
        width: auto;
      }
    }
  }
  .navbar-item {
    padding: 20px;
    @include desktop {
      padding: 15px;
    }
    img {
      max-width: 160px;
      max-height: none;
    }
  }
}

.delete {
  min-height: 25px;
  min-width: 25px;
  margin: -1px 2px;
  &:before,
  &:after {
    background-color: $white;
  }
  &:before {
    height: 2px;
  }
  &:after {
    width: 2px;
  }
}

.button.is-danger {
  padding: rem(8px 60px);
}
</style>