<template>
	<div id="app">
		<TheNavBar />
		<transition name="fade" mode="out-in" appear>
			<TheBreadcrumb
				v-if="
					$route.name !== 'view-home-page' &&
						$route.name !== 'Oferta edukacyjna' &&
						$route.name !== 'Konferencja' && 
                        $route.name !== 'Dzień programu wdrożeniowego'
				"
			/>
		</transition>
		<transition name="fade" mode="out-in" appear>
			<keep-alive
				:exclude="[
					'ViewAddEditShop',
					'AddEditShop',
					'ViewUserData',
					'ViewShopsManagement',
                    'ViewImplementationProgram',
                    'ViewImplementationProgramTasks',
					'ViewImplementationProgramExam',
					'ViewImplementationProgramForm',
                    'ViewOnboarding',
                    'ViewOnboardingTasks',
					'ViewOnboardingExam',
					'ViewOnboardingForm',
					'ViewElearning',
				]"
			>
				<router-view :key="$route.path" />
			</keep-alive>
		</transition>
	</div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar.vue';
import TheNavBarLogin from '@/components/TheNavBarLogin.vue';
import TheBreadcrumb from '@/components/TheBreadcrumb.vue';

export default {
	components: {
		TheNavBar,
		TheNavBarLogin,
		TheBreadcrumb,
	},
};
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	width: 100%;
}

#nav {
	padding: 30px;
	a {
		font-weight: bold;
		color: #2c3e50;
		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 1s ease-in-out;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.v-calendar{
	.v-event {
		margin-left: 2.5% !important;

		div {
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
		}
	}

	.v-calendar-weekly__day {
		min-height: 100px;
	}
}

.hide {
	height: 0 !important;
	display: none !important;
}

.collapsed-calendar {
	height: 100px !important;
}

.homepage-news .container .v-application--wrap {
	min-height: 100px !important;
}
</style>
