<template>
    <main>
        <Workshop />
    </main>
</template>

<script>
import Workshop from "@/components/Containers/ViewWorkshop/Workshop.vue";

export default {
  name: "ViewWorkshop",
  components: {
    Workshop
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

</style>
