<template>
    <section class="conferences section">
        <div v-if="liveLecture.isFormEnabled" class="live-lecture-form container small">
            <div class=" is-size-2 has-text-weight-bold has-text-centered">
                <a href="https://akademiaeurocash.com.pl/media/4657/zaproszenie-10-wykladow-aue-final_v2.pdf">POZNAJ PROGRAM WYKŁADÓW TUTAJ >></a>
            </div>
            <div class="conf-sign-form">
                <div class="card">
                    <form class="form-wrap" @submit="submitForm">
                        <div class="sign-you">
                            <p class="is-size-16 is-size-1-tablet has-text-left">Zapisy na wykłady online</p>
                        </div>
                        <div class="sing-worker">

                            <!--<p class="is-size-16 is-size-1-tablet has-text-left">Zapisz pracowników</p>-->
                            <div class="worker-row">
                                <input type="text"
                                       :class="{ 'has-error': !liveLecture.isName }"
                                       title="Imię"
                                       placeholder="Imię"
                                       value
                                       v-model="liveLecture.subscription.Name" />
                                <div v-if="!liveLecture.isName" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Imię jest wymagane</span>
                                </div>

                                <input type="text"
                                       :class="{ 'has-error': !liveLecture.isSurname }"
                                       title="Nazwisko"
                                       placeholder="Nazwisko"
                                       value
                                       v-model="liveLecture.subscription.Surname" />
                                <div v-if="!liveLecture.isSurname" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Nazwisko jest wymagane</span>
                                </div>
                                <input type="email"
                                       :class="{ 'has-error': !liveLecture.isEmail }"
                                       title="Adres email"
                                       placeholder="Adres email"
                                       value
                                       v-model="liveLecture.subscription.Email" />
                                <div v-if="!liveLecture.isEmail" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Email jest wymagany</span>
                                </div>

                                <select v-model="liveLecture.subscription.WorkPosition"
                                        :class="{ 'has-error': !liveLecture.isWorkPosition }"
                                        title="Stanowisko">
                                    <option value disabled selected>Stanowisko</option>
                                    <option>Właściciel</option>
                                    <option>Kierownik sklepu</option>
                                    <option>Pracownik sklepu</option>
                                    <option>Pracownik Grupy Eurocash</option>
                                </select>
                                <div v-if="!liveLecture.isWorkPosition" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Stanowisko jest wymagane</span>
                                </div>

                                <input type="text"
                                       :class="{ 'has-error': !liveLecture.isCompanyName }"
                                       title="Nazwa sklepu lub firmy"
                                       placeholder="Nazwa sklepu lub firmy"
                                       value
                                       v-model="liveLecture.subscription.CompanyName" />
                                <div v-if="!liveLecture.isCompanyName" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Pole wymagane</span>
                                </div>

                                <input type="number"
                                       title="NIP firmy"
                                       placeholder="NIP firmy (jeśli dotyczy)"
                                       value
                                       v-model="liveLecture.subscription.NIP" />

                                <input type="text"
                                       :class="{ 'has-error': !liveLecture.isCity }"
                                       title="Miejscowość"
                                       placeholder="Miejscowość"
                                       value
                                       v-model="liveLecture.subscription.City" />
                                <div v-if="!liveLecture.isCity" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Pole miesjcowość wymagane</span>
                                </div>


                                <select v-model="liveLecture.subscription.NetworkId"
                                        :class="{ 'has-error': !liveLecture.isNetworkId }"
                                        title="Sieć">
                                    <option value="null" disabled selected>Sieć</option>
                                    <option value="6">ABC</option>
                                    <option value="1">Delikatesy Centrum</option>
                                    <option value="5">Euro Sklep</option>
                                    <option value="3">Gama</option>
                                    <option value="4">Groszek</option>
                                    <option value="2">Lewiatan</option>
                                    <option value="9">Duży Ben</option>
                                    <option value="10">Kontigo</option>
                                    <option value="11">Koliber</option>
                                    <option value="0">Inna</option>
                                </select>
                                <div v-if="!liveLecture.isNetworkId" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Pole sieć wymagane</span>
                                </div>

                                <vue-multi-select ref="multiSelect"
                                                  :class="{ 'has-error': !liveLecture.isLiveLecture }"
                                                  v-model="liveLecture.selectedLectures"
                                                  historyButton
                                                  id="multiSelect"
                                                  :options="liveLecture.SelectBox.options"
                                                  :filters="liveLecture.SelectBox.filters"
                                                  :btnLabel="liveLecture.SelectBox.btnLabel"
                                                  @open="open"
                                                  @close="close"
                                                  @select="select"
                                                  :selectOptions="liveLecture.SelectBox.data">
                                    <template v-slot:option="{option}">
                                        <span v-html="option.Value" />
                                    </template>
                                </vue-multi-select>

                                <div v-if="!liveLecture.isLiveLecture" class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Określ na jakie wykłady chcesz się zapisać</span>
                                </div>
                            </div>

                        </div>
                        <div class="guest-regulation is-size-7">

                            <ol>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Administratorem
                                            danych jest Akademia Umiejętności Eurocash sp. z o.o. z siedzibą w
                                            Komornikach (62-052), ulica Wiśniowa 11. Z administratorem można
                                            skontaktować się poprzez adres e-mail: eurocash@eurocash.pl, pod
                                            numerem telefonicznym (0-61) 6583000 lub pisemnie na adres siedziby.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Akademia
                                            Umiejętności Eurocash sp. z o.o. wyznaczyła Inspektora Ochrony
                                            Danych, z którym Pani / Pan może skontaktować się poprzez e-mail
                                            iod_aue@eurocash.pl, pod numerem telefonicznym (0-61) 3322277 lub na
                                            adres siedziby administratora. Z inspektorem ochrony danych można
                                            się kontaktować we wszystkich sprawach dotyczących przetwarzania
                                            danych osobowych oraz korzystania z praw związanych z przetwarzaniem
                                            danych.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Pani
                                            / Pana dane są przetwarzane w celu:
                                        </font>
                                    </p>
                                </li>

                            </ol>
                            <ol type="a">
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            wykonania
                                            umowy realizacji wykładu on-line (webinarów) - podstawą prawną jest
                                            niezbędność przetwarzania danych do wykonania umowy, której Pani /
                                            Pan jest stroną (art. 6 ust. 1 lit. b) RODO).
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            dochodzenia
                                            i ochrony przed roszczeniami &ndash; podstawą prawną przetwarzania
                                            jest prawnie uzasadniony interes Administratora polegający na
                                            możliwości ochrony interesów Administratora w postępowaniu sądowym
                                            lub innym (art. 6 ust. 1 lit. f) RODO).
                                        </font>
                                    </p>
                                </li>
                            </ol>
                            <ol start="4">
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Pani
                                            / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym
                                            dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w
                                            szczególności dostawcom IT oraz spółkom z Grupy Kapitałowej
                                            Eurocash. Pani / Pana dane osobowe mogą być przekazywane także
                                            podmiotom działającym na zlecenie Administratora tj. podmioty
                                            dostarczające platformę do przeprowadzania wykładów on-line.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Pani
                                            / Pana dane osobowe będą przetwarzane przez okres trwania wykładu
                                            on-line lub przez okres trwania prawnie uzasadnionego interesu
                                            Administratora istniejącego do czasu cofnięcia zgody na otrzymywanie
                                            informacji marketingowych. Okres przechowywania danych osobowych
                                            może zostać każdorazowo przedłużony o okres przedawnienia roszczeń,
                                            jeżeli przetwarzanie danych osobowych będzie niezbędne dla
                                            dochodzenia ewentualnych roszczeń lub obrony przed takimi
                                            roszczeniami przez Administratora.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Przysługuje
                                            Pani / Panu prawo dostępu do Pani / Pana danych oraz prawo żądania
                                            ich sprostowania, ich usunięcia lub ograniczenia ich przetwarzania.
                                            Przysługuje Pani / Panu także prawo do przenoszenia danych
                                            osobowych, które dostarczył-a Pani / Pan administratorowi tj. do
                                            otrzymania od administratora Pani Pana danych osobowych, w
                                            ustrukturyzowanym, powszechnie używanym formacie nadającym się do
                                            odczytu maszynowego. Może Pani / Pan przesłać te dane innemu
                                            administratorowi danych.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            W
                                            zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                                            osobowych jest przesłanka prawnie uzasadnionego interesu
                                            administratora, przysługuje Pani / Panu prawo wniesienia sprzeciwu
                                            wobec przetwarzania Pani / Pana danych osobowych
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <font size="2" style="font-size: 10pt">
                                            Przysługuje
                                            Pani / Panu również prawo wniesienia skargi do organu nadzorczego
                                            zajmującego się ochroną danych osobowych w państwie członkowskim
                                            Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia
                                            domniemanego naruszenia. W Polsce organem tym jest Prezes Urzędu
                                            Ochrony Danych Osobowych.
                                        </font>
                                    </p>
                                </li>
                                <li>
                                    <p align="justify" style="margin-bottom: 0.35cm">
                                        <a name="_GoBack"></a>
                                        <font size="2" style="font-size: 10pt">
                                            Podanie danych jest
                                            dobrowolne, jednak bez ich podania uczestnictwo w webinarium będzie
                                            niemożliwe.
                                        </font>
                                    </p>
                                </li>
                            </ol>
                            <p align="justify" style="margin-bottom: 0.28cm; line-height: 108%">
                                <font size="2" style="font-size: 10pt">
                                    Po
                                    pomyślnej rejestracji uczestnik otrzyma przypomnienie o nadchodzącym
                                    wykładzie, na który się zapisał, dzień przed wydarzeniem.
                                </font>
                            </p>







                            <!--     <label>
                    <input type="checkbox"
                           v-model="livelecture.subscription.isregulationsagreement"
                           @change="regulclick" />
                    <span class="is-size-7">
                        akceptuję regulamin  (zapoznaj się

                        /assets/pdf/regulamin_kongres_pph.pdf
                                            <a href="#"
                                               target="_blank">tutaj>></a>)
                                            </span>
                                            </label>-->


                        </div>
                        <br />
                        <!--    <p class="is-size-7">
                    Współadministratorami Pani/Pana danych osobowych są Akademia Umiejętności sp. z o.o. z siedzibą w Komornikach oraz inne spółki z Grupy Eurocash, których lista znajduje się w Regulaminie (dalej „Współadministratorzy”). Pani/Pana dane osobowe będą przetwarzane w celach związanym z prawidłową organizacją i przeprowadzeniem wykładu online.
                </p>-->
                        <button :disabled="liveLecture.isSending"
                                type="submit"
                                class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                            Wyślij zgłoszenie
                        </button>
                        <p class="is-size-8 has-text-weight-light has-text-danger"
                           v-if="liveLecture.submitStatus === 'ERROR'">Nie wszystkie wymagane pola zostały wypełnione poprawnie.</p>
                        <div v-if="liveLecture.isSending" class="loading">Wysyłanie...</div>

                        <div class="success_error column is-full" v-if="liveLecture.errorMsg || liveLecture.successMsg">
                            <br />
                            <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-danger has-text-centered"
                               v-if="liveLecture.errorMsg">{{ liveLecture.errorMsg }}</p>
                            <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-primary has-text-centered"
                               v-if="liveLecture.successMsg">{{ liveLecture.successMsg }}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="container">


            <div class="conferences-intro">
                <h2 class="is-size-2">Dotychczasowe konferencje</h2>
                <p class="has-text-weight-light">
                    Rozpoczynamy tradycję wykładów online. Każdy rok przynosi
                    tak wiele innowacji w świecie wokół nas i nowych trendów w handlu, że nie możemy narzekać na brak wyzwań i tematów do poruszenia. Co roku gościmy coraz więcej przedsiębiorców i prelegentów.
                </p>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="columns is-paddingless is-multiline">
                        <div class="column is-full" v-for="conference in conferencesAll" :key="conference.Id">
                            <BaseCardConference @goToUrlHandler="goToUrlHandler('/konferencje/' + conference.Id)" :confPast="conference" />
                        </div>
                    </div>
                    <!--<a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadAllWorkshopsHandler()" v-if="nextPage">Załaduj więcej treści</a>
                    <h2 class="title is-2 has-text-weight-light has-text-left" v-if="!workshopsall.length">{{ $parent.search ? 'Nie znaleziono wyników dla ' + $parent.search : 'Brak wyników' }}<a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="$emit('update:search', ''); clearFiltersHandler()">Resetuj wyszukiwanie</a></h2>-->
                    <div class="conferences-more">
                        <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadAllConferences()" v-if="nextPage">Załaduj więcej treści</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import BaseCardConference from "@/components/BaseCardConference.vue";
    import global from "@/mixins/global.js";
    import vueMultiSelect from 'vue-multi-select';
    import 'vue-multi-select/dist/lib/vue-multi-select.css';



    export default {
        name: "ConferencesAll",
        mixins: [global],
        components: {
            BaseCardConference,
            vueMultiSelect,
        },
        data() {
            return {

                mt: 0,

                liveLecture: {
                    isFormEnabled: false,
                    submitStatus: null,
                    errorMsg: '',
                    successMsg: '',
                    isSending: false,
                    regulationsVal: true,
                    isLiveLecture: true,
                    isName: true,
                    isSurname: true,
                    isEmail: true,
                    isWorkPosition: true,
                    isCompanyName: true,
                    isCity: true,
                    isNetworkId: true,

                    selectedLectures: [],
                    SelectBox: {
                        btnLabel: values => {
                            if (values.length > 0) {

                                var res = ''

                                for (var i = 0; i < values.length; i++) {
                                    let item = values[i];
                                    res += '<Br/>' + item.Value + '<Br/>' + '<hr>';
                                }

                                return res;
                            } else return 'Wybierz wykład, w którym chcesz wziąć udział (możesz wybrać wszystkie):'
                        },
                        data: [{
                            name: 'first group',
                            list: [
                                { Value: "Opcja 0", Key: 0 },
                                { Value: "Opcja 1", Key: 1 },
                                { Value: "Opcja 2", Key: 2 },
                                { Value: "Opcja 3", Key: 3 }
                            ]
                        }],

                        filters: [{
                            nameAll: 'Zaznacz wszystkie',
                            nameNotAll: 'Odznacz wszystkie',
                            func() {
                                return true;
                            },
                        }],
                        options: {
                            multi: true,
                            groups: true,
                            labelList: 'list',
                            labelName: 'Value',
                            labelValue: 'Key',
                            groupName: 'title',
                            cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : ''),
                            btnClass: 'multiSelectBtn',
                            popoverClass: 'multiSelectPopover',
                        },
                    },



                    subscription: {
                        Name: '',
                        Surname: '',
                        Email: '',
                        NetworkId: null,
                        WorkPosition: '',
                        LiveLectureIds: [],
                        CompanyName: '',
                        NIP: '',
                        City: '',
                        IsRegulationsAgreement: false,
                        IsNewsletterAgreement: false
                    }


                },

                conferencesAll: [],
                nextConference: [],
                filters: {
                    Page: 0
                },
                nextPage: true,
            };
        },
        methods: {

            regulClick() {
                if (this.liveLecture.subscription.IsRegulationsAgreement) {
                    this.liveLecture.regulationsVal = true;
                } else {
                    this.liveLecture.regulationsVal = false;
                }
            },

            submitForm(evt) {
                evt.preventDefault();
                let self = this;

                self.liveLecture.submitStatus = '';

                if (self.liveLecture.selectedLectures.length > 0) {
                    self.liveLecture.subscription.LiveLectureIds = [];
                    self.liveLecture.selectedLectures.forEach(function (item) {
                        self.liveLecture.subscription.LiveLectureIds.push(item.Key);
                    });
                    self.liveLecture.isLiveLecture = true;
                }
                else {
                    self.liveLecture.isLiveLecture = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                //if (!self.liveLecture.subscription.IsRegulationsAgreement) {
                //    self.liveLecture.regulationsVal = false;
                //    self.liveLecture.submitStatus = 'ERROR';
                //}
                //else { self.liveLecture.regulationsVal = true; }
                if (self.liveLecture.subscription.Name == '') {
                    self.liveLecture.isName = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isName = true; }
                if (self.liveLecture.subscription.Surname == '') {
                    self.liveLecture.isSurname = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isSurname = true; }
                if (self.liveLecture.subscription.Email == '') {
                    self.liveLecture.isEmail = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isEmail = true; }
                if (self.liveLecture.subscription.WorkPosition == '') {
                    self.liveLecture.isWorkPosition = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isWorkPosition = true; }

                if (self.liveLecture.subscription.CompanyName == '') {
                    self.liveLecture.isCompanyName = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isCompanyName = true; }

                if (self.liveLecture.subscription.City == '') {
                    self.liveLecture.isCity = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isCity = true; }

                if (self.liveLecture.subscription.NetworkId == null || typeof self.liveLecture.subscription.NetworkId == 'undefined') {
                    self.liveLecture.isNetworkId = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                else { self.liveLecture.isNetworkId = true; }


                if (self.liveLecture.submitStatus != 'ERROR') {
                    self.liveLecture.submitStatus = '';
                    self.liveLecture.isSending = true;


                    // Send form to server
                    this.$http
                        .post('/spa/LiveLecture/Add', this.liveLecture.subscription)

                        .then(response => {
                            if (response.data.IsError) {
                                self.liveLecture.errorMsg = response.data.Message;
                                self.liveLecture.isSending = false;
                            } else {
                                self.liveLecture.isSending = false;
                                self.liveLecture.successMsg = response.data.Message;
                            }

                            setTimeout(() => {
                                if (self.liveLecture.successMsg !== null && this.liveLecture.successMsg !== '') {
                                    self.clearForm();
                                    self.$v.$reset();
                                    self.liveLecture.regulationsVal = true;
                                    self.conference.IsUserAleradySubscribed = true;
                                    self.signworkers = [];
                                    self.liveLecture.successMsg = '';
                                    //$('html, body').animate({ scrollTop: 0 }, 'normal');
                                    $('html,body').scrollTop(0);
                                }

                                self.liveLecture.errorMsg = '';
                                self.liveLecture.isSending = false;
                            }, 5000);
                        })

                        .catch(() => {
                            this.liveLecture.errorMsg = 'Wystąpił błąd';
                            setTimeout(() => {
                                this.liveLecture.successMsg = '';
                                this.liveLecture.errorMsg = '';
                            }, 5000);
                        });
                }
            },

            loadLiveLectures() {
                let self = this;
                self.$http
                    .get(`spa/livelecture/livelecturesselect`).then(response => {
                        self.liveLecture.SelectBox.data[0].list = response.data;
                    })

            },
            enableLiveLecturesForom() {
                let self = this;
                self.$http
                    .get(`spa/LiveLecture/IsEnabledSignUpForm`).then(response => {
                        self.liveLecture.isFormEnabled = response.data;
                    }).catch(() => { self.liveLecture.isFormEnabled = false;})
            },

            loadAllConferences() {
                let self = this;
                self.$http
                    .post(`spa/conference/list`, self.filters)
                    .then(response => {
                        if (self.filters.Page) {
                            self.conferencesAll = [...self.conferencesAll, ...response.data.Data];
                            self.nextConference = [...self.nextConference, ...response.data.NextConference];
                            self.nextPage = response.data.NextPage;
                            self.$parent.$refs.bannerConfComponent.setConference(response.data.NextConference);
                            if (response.data.NextConference === null) {
                                self.$parent.showComponent = true;
                            }
                        } else {
                            self.conferencesAll = response.data.Data;
                            self.nextConference = response.data.NextConference;
                            self.nextPage = response.data.NextPage;
                            self.$parent.$refs.bannerConfComponent.setConference(response.data.NextConference);
                            if (response.data.NextConference === null) {
                                self.$parent.showComponent = true;
                            }
                        }
                    })
                    .catch(() => { });
            },

            //multiSelect

            open() {


                let height = $('.checkboxLayer').height();
                let selectedOptionsHeight = 0;
                if (this.liveLecture.selectedLectures.length > 0) {
                    selectedOptionsHeight = $('#multiSelect > button').height();
                }
                $('.guest-regulation').css('margin-top', this.mt + height + selectedOptionsHeight + 'px');
            },
            select() {
                let mt = parseInt($('.guest-regulation').css('margin-top'));
                let selectedOptionsHeight = $('#multiSelect > button').height();
                let height = $('.checkboxLayer').height();
                $('.guest-regulation').css('margin-top', mt + height + selectedOptionsHeight + 'px');
            },
            close() {
                let selectedOptionsHeight = 0;
                if (this.liveLecture.selectedLectures.length > 0) {
                    selectedOptionsHeight = $('#multiSelect > button').height();
                }
                $('.guest-regulation').css('margin-top', this.mt + selectedOptionsHeight +  'px');
            },
            //multiSelect
        },
        created: function () {


            this.enableLiveLecturesForom();
            this.loadAllConferences();
            this.loadLiveLectures();
        },
        mounted() {
          
            this.$documentReady(() => {
                this.mt = parseInt($('.guest-regulation').css('margin-top'));
            });



            let self = this;
            $('body').on('click', '.historyButton', () => {
                let height = $('.checkboxLayer').height();
                let selectedOptionsHeight = 0;
                if (self.liveLecture.selectedLectures.length > 0) {
                    selectedOptionsHeight = $('#multiSelect > button').height();
                }
                $('.guest-regulation').css('margin-top', this.mt + height + selectedOptionsHeight + 'px');
            });

            $('body').on('click', '.helperButton', () => {
                let height = $('.checkboxLayer').height();
                let selectedOptionsHeight = 0;
                if (self.liveLecture.selectedLectures.length > 0) {
                    selectedOptionsHeight = $('#multiSelect > button').height();
                    self.liveLecture.isLiveLecture = true;
                } else {
                    self.liveLecture.isLiveLecture = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                $('.guest-regulation').css('margin-top', this.mt + height + selectedOptionsHeight + 'px');
            });

            $('body').on('click', '#multiSelect .selectList .selectItem', () => {
                let selectedOptionsHeight = $('#multiSelect > button').height();
                let height = $('.checkboxLayer').height();
                if (self.liveLecture.selectedLectures.length > 0) {
                    self.liveLecture.isLiveLecture = true;
                } else {
                    self.liveLecture.isLiveLecture = false;
                    self.liveLecture.submitStatus = 'ERROR';
                }
                $('.guest-regulation').css('margin-top', this.mt + height + selectedOptionsHeight + 'px');
            });
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .conferences.section {
        background-color: $white;
        padding: 2.5rem 1.25rem 3rem;
        .conferences-intro

    {
        text-align: center;
        color: $black-real;
        margin: 0 auto 3.125rem;

        @include desktop {
            width: 780px;
        }

        h2

    {
        margin-bottom: 1.25rem;
    }

    }

    .conferences-more {
        text-align: center
    }

    .button {
        padding: 25px 50px;
        /*margin: 1.875rem auto 0;*/
    }

    }


    .conf-sign-form {
        .card

    {
        margin: 4rem 0;
        border: 1px solid #f2f2f2;
        padding: 2.5rem 1rem 1.85rem;

        @include tablet {
            padding: 2.5rem 2.5rem 1.85rem;
        }

        p

    {
        color: #4a4a4a;
    }

    .form-wrap {
        input

    {
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        padding: 1rem;
        font-size: 1rem;
        color: #686868;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    select {
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        font-size: 1rem;
        padding: 1rem;
        color: #686868;
    }

    button[type='submit'] {
        color: $white;
        /*margin-top: 5rem;*/
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        padding: 1rem;
        font-size: 1rem;
    }

    .sign-you {
        p

    {
        margin-bottom: 2rem;
    }

    }

    .sing-worker {
        margin-top: 2rem;
        > p

    {
        margin-bottom: 2rem;
    }

    .worker-row {
        margin: 2rem 0;
    }

    .worker-btns {
        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        button

    {
        display: inline-block;
        width: 100%;
        height: 50px;
        padding: 0 1rem;
        line-height: 44px;
        margin-top: 1rem;
        max-width: 100%;
        transition: 0.5s all ease-in-out;
        cursor: pointer;

        @include tablet {
            width: 290px;
            margin-top: 0.5rem;
        }

        &:hover

    {
        opacity: 0.7;
    }

    &.add-worker {
        color: $green-lighter;
        border: 2px solid $green-lighter;
    }

    &.remove-worker {
        color: $white;
        border: 2px solid $red;
        background-color: $red;
    }

    svg {
        vertical-align: middle;
        margin-right: 0.6rem;
    }

    span {
        vertical-align: middle;
        display: inline-block;
    }

    }
    }
    }

    .signworkers-clause {
        margin-top: 30px;
    }

    .guest-regulation {
        margin-top: 30px;
        label

    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        input

    {
        height: 14px;
        width: auto;
        margin-right: 15px;
        margin-top: 5px;
        margin-bottom: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    }
    }
    }
    }
    }

    .live-lecture-form {
        max-width: 729px;
    }

    .select:not(.is-multiple):not(.is-loading)::after {
        display: none;
    }

    .input-msg {
        position: relative;
        top: -5px;
    }

    .select {
        width: 100%;
    }

    .btn-select {
        width: 100% !important;
    }


    input.has-error,
    select.has-error {
        border-color: $red !important;
    }

    .subPiont {
        margin-left: 20px;
    }
</style>
<style>
    #multiSelect > button{
        width: 100%;
    }
    #multiSelect.has-error > button {
        border-color: #de2647;
    }
</style>