/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import * as currentUser from '@/store-modules/currentUser.js';
import * as screenedUser from '@/store-modules/screenedUser.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		currentUser,
		screenedUser,
	},

	state: {},

	mutations: {
		UPDATE_STATE: state => {
			state = state;
		},
	},

	plugins: [createPersistedState()],
});
