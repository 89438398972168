<template>
	<section id="ViewUserData" class="ViewUserData section">
		<div class="container">
			<!-- Heading -->
			<div class="columns is-mobile">
				<div class="column">
					<!-- Main title -->
					<h1
						class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow"
					>
						Dane użytkownika
					</h1>
				</div>

				<!-- Edit buttons -->
				<div v-if="isUserDataEditable" class="column is-one-quarter">
					<div class="columns">
						<div class="column">
							<button
								class="button is-success is-fullwidth is-small is-uppercase"
								@click="saveUserData"
							>
								Zapisz
							</button>
						</div>
						<div class="column">
							<button
								class="button is-danger is-fullwidth is-small is-uppercase"
								@click="cancelUserDataEdition"
							>
								Anuluj
							</button>
						</div>
					</div>
				</div>
				<!-- END | Edit buttons -->

				<div class="column is-one-quarter">
					<ActionsDropdown
						@send-activation-link="sendActivationLink"
						@edit-data="allowUserDataEdition"
						@delete-user="deleteUser"
						@add-shop="addShop"
						:current-user="currentUser"
						:user="user"
					/>
				</div>
			</div>
			<!-- END | Heading -->

			<div
				v-if="!isCurrentUser(['TrainerUser', 'NetworkAdminUser', 'CAUser', 'KOUser', 'RDOUser'])"
				class="columns"
			>
				<div class="column">
					<p class="has-text-left has-text-light is-size-8">
						Żeby edytować dane swojego pracownika skontaktuj się z infolinią
						Akademii.
					</p>
				</div>
			</div>

			<NameBox
				ref="NameBox"
				:is-data-editable="isUserDataEditable"
				:user-data="user"
				:editable-fields="editableFields"
				@email-address-click="handleEmailAddresClick"
			/>

            <div class="columns is-multiline">
                <ShopsList v-if="user.Roles && isUser(['ShopManager', 'ShopOwner'])" />
                <SubservientEmployees v-if="user.Roles && isUser(['ShopManager', 'ShopOwner'])" />
                <ScheduledWorkshops />
                <CompletedElearnings />
                <CompletedWorkshops />
                <ImplementationProgramState v-if="user.Id" :user-id="user.Id" />
				<OnboardingState v-if="user.Id" :user-id="user.Id" />
            </div>
		</div>

		<ModalInfo
			ref="modalInfo"
			:message="modalInfo.message"
			:button-label="modalInfo.buttonLabel"
		/>
		<ModalCancelDataEdition
			ref="modalCancelDataEdition"
			@confirm="handleEditCancelConfirmation"
		/>
		<ModalDeleteUser
			:first-name="user.FirstName"
			:last-name="user.LastName"
			ref="modalDeleteUser"
			@confirm="handleUserDeletionConfirmation"
		/>
	</section>
</template>

<script>
// JS modules
import { mapState } from 'vuex';
import router from '../router';

// Vue components
import NameBox from '@/components/Containers/ViewUserData/cards/NameBox.vue';
import ActionsDropdown from '@/components/Containers/ViewUserData/ActionsDropdown.vue';
import ScheduledWorkshops from '@/components/Containers/ViewUserData/tables/ScheduledWorkshops.vue';
import CompletedWorkshops from '@/components/Containers/ViewUserData/tables/CompletedWorkshops.vue';
import CompletedElearnings from '@/components/Containers/ViewUserData/tables/CompletedElearnings.vue';
import ImplementationProgramState from '@/components/Containers/ViewImplementationProgram/ImplementationProgramState.vue';
import OnboardingState from '@/components/Containers/ViewOnboarding/OnboardingState.vue';
import ShopsList from '@/components/Containers/ViewUserData/tables/ShopsList.vue';
import SubservientEmployees from '@/components/Containers/ViewUserData/tables/SubservientEmployees.vue';
import ModalInfo from '@/components/Containers/ViewUserData/ModalInfo.vue';
import ModalCancelDataEdition from '@/components/Containers/ViewUserData/modals/ModalCancelDataEdition.vue';
import ModalDeleteUser from '@/components/Containers/ViewUserData/modals/ModalDeleteUser.vue';

export default {
	components: {
		NameBox,
		ActionsDropdown,
		ScheduledWorkshops,
		CompletedWorkshops,
        CompletedElearnings,
		ImplementationProgramState,
        OnboardingState,
		ShopsList,
		SubservientEmployees,
		ModalInfo,
		ModalCancelDataEdition,
		ModalDeleteUser,
	},

	data() {
		return {
			secondaryActionButton: {
				isVisible: false,
				text: '',
				currentProcess: '',
			},

			isUserDataEditable: false,

			removalModal: {
				isActive: false,
				subject: 'user',
				isLoading: '',
			},

			modalInfo: {
				message: '',
				buttonLabel: '',
			},

			isEmailBeingEdited: false,

			editableFields: [],
		};
	},

	computed: {
		...mapState('screenedUser', {
			user: state => state.data,
		}),

		...mapState('currentUser', {
			currentUser: state => state.data,
		}),
	},

	beforeMount() {
		this.$store.dispatch('currentUser/fetchData');
		this.$store.dispatch('screenedUser/fetchData', this.$route.params.id);
	},

	activated() {
		this.$store.dispatch('currentUser/fetchData');
		this.$store.dispatch('screenedUser/fetchData', this.$route.params.id);
	},

	beforeUpdate() {
		if (
			this.isCurrentUser(['ShopOwner', 'ShopManager']) &&
			this.user.Email.length === 0
		) {
			this.editableFields = ['Email'];
        } else if (this.isCurrentUser(['NetworkAdminUser', 'TrainerUser', 'CAUser', 'KOUser', 'RDOUser'])) {
			if (this.isEmailBeingEdited) {
				this.editableFields = ['Email'];
			} else {
				this.editableFields = ['FirstName', 'LastName', 'Email', 'Phone'];
			}
		} else {
			this.editableFields = [];
		}
	},

	methods: {
		/**
		 * @description This method will tell you if the user is part of a given users group.
		 * @param {String|Array} roles The role(s) to check the user against.
		 * @returns {Boolean}
		 */
		isUser(roles) {
			if (this.user.Roles) {
				if (typeof roles === 'string') {
					return this.user.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.user.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},

		isCurrentUser(roles) {
			if (this.currentUser.Roles) {
				if (typeof roles === 'string') {
					return this.currentUser.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.currentUser.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},

		openModalInfo(message, buttonLabel) {
			this.modalInfo = {
				message,
				buttonLabel,
			};
			this.$refs.modalInfo.open();
		},

		sendActivationLink() {
			if (this.user) {
				if (this.user.Email && this.user.Email.length > 0) {
					this.$store
						.dispatch('screenedUser/sendActivationLink')
						.then(() => {
							const message = 'Link aktywacyjny został pomyślnie wysłany';
							const buttonLabel = 'Zamknij okno';

							this.openModalInfo(message, buttonLabel);
						})
						.catch(() => {
							const message = 'Wystąpił błąd. Spróbuj ponownie.';
							const buttonLabel = 'Zamknij okno';

							this.openModalInfo(message, buttonLabel);
						});
				} else {
					this.isEmailBeingEdited = true;
					this.isUserDataEditable = true;
				}
			}
		},

		/**
		 * @description This function will allow the edition of the
		 * user data present in the <NameBox /> component.
		 * This is a 1st of the actions available in the <ActionsDropdown /> component.
		 *
		 * @returns {Void}
		 */
		allowUserDataEdition() {
			this.isUserDataEditable = true;
		},

		addShop() {
			this.$router.push({
				name: 'Dodaj sklep',
				params: {
					ownerId: this.user.Id,
				},
			});
		},

		cancelUserDataEdition() {
			this.$refs.modalCancelDataEdition.open();
		},

		handleEditCancelConfirmation() {
			this.isUserDataEditable = false;
			this.$refs.NameBox.revertChanges();
		},

		saveUserData() {
			let ableToProceed = true;

			let firstName = document.querySelector('[data-field-id="FirstName"]')
				.innerText;
			let lastName = document.querySelector('[data-field-id="LastName"]')
				.innerText;
			let email = document.querySelector('[data-field-id="Email"]').innerText;
			let phone = document.querySelector('[data-field-id="Phone"]').innerText;

			if (this.isEmailBeingEdited) {
				if (email === '' || email === 'podaj adres e-mail') {
					ableToProceed = false;

					const message = `Podaj adres e-mail.`;
					const buttonLabel = 'Zamknij okno';
					this.openModalInfo(message, buttonLabel);
				}
			} else {
				if (
					firstName === '' ||
					firstName === 'podaj imię' ||
					lastName === '' ||
					lastName === 'podaj nazwisko' ||
					email === '' ||
					email === 'podaj adres e-mail'
				) {
					ableToProceed = false;

					const message = `Pola 'Imię', 'Nazwisko' i 'Adres e-mail' są polami obowiązkowymi.`;
					const buttonLabel = 'Zamknij okno';
					this.openModalInfo(message, buttonLabel);
				}
			}

			if (ableToProceed) {
				let updatedData = Object.assign({}, this.user);

				if (!this.isEmailBeingEdited) {
					updatedData.Phone = phone === 'podaj numer telefonu' ? '' : phone;
					updatedData.FirstName = firstName;
					updatedData.LastName = lastName;
				}
				updatedData.Email = email;

				this.$store
					.dispatch('screenedUser/updateUserData', updatedData)
					.then(() => {
						this.isUserDataEditable = false;
						this.isEmailBeingEdited = false;

						const message = 'Dane zostały pomyślnie zaktualizowane.';
						const buttonLabel = 'Zamknij okno';

						this.openModalInfo(message, buttonLabel);

						this.$store.dispatch(
							'screenedUser/fetchData',
							this.$route.params.id
						);
					})
					.catch(() => {
						const message = 'Wystąpił błąd. Spróbuj ponownie.';
						const buttonLabel = 'Zamknij okno';
						this.openModalInfo(message, buttonLabel);
						this.isUserDataEditable = true;
					});
			}
		},

		deleteUser() {
			this.$refs.modalDeleteUser.open();
		},

		handleUserDeletionConfirmation() {
			this.$store
				.dispatch('screenedUser/deleteUser')
				.then(() => {
					const message = 'Użytkownik został usunięty poprawnie',
						buttonLabel = 'Zamknij okno';

					this.openModalInfo(message, buttonLabel);

					setTimeout(() => {
						router.go(-1);
						this.$destroy();
					}, 2000);
				})
				.catch(() => {
					const message = 'Nie udało się usunąć użytkownika. Spróbuj ponownie.',
						buttonLabel = 'Zamknij okno';

					this.openModalInfo(message, buttonLabel);
				});
		},

		handleEmailAddresClick() {
			if (!this.user.Email && !this.isUserDataEditable) {
				this.isEmailBeingEdited = true;
				this.isUserDataEditable = true;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Style the tables.
.card-table {
	.card {
		&-header {
			padding: 10px 30px;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
		}

		&-toggler {
			background: transparent;
			border: 2px solid white;
			border-radius: 5px;
			color: white;
			padding: 5px 15px;
			margin: auto;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			// Create the chevron icon.
			.chevron {
				position: relative;
				width: 25px;
				margin-left: 15px;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					background-color: white;
					width: 15px;
					height: 2px;
					border-radius: 1px;
					transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
				}

				&::before {
					right: 44%;
					transform: rotate(30deg);
				}

				&::after {
					left: 44%;
					transform: rotate(-30deg);
				}

				// Animate the chevron on click.
				&.active {
					&::before {
						transform: rotate(-30deg);
					}

					&::after {
						transform: rotate(30deg);
					}
				}
			}
		}
	}
}
</style>
