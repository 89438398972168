<template>
	<div class="column is-full">
		<div class="card-table card-table-employee">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<EmployeesIcon />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Lista podległych pracowników
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						v-if="expandAvailable"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->
			</div>
			<table class="table">
				<thead class="is-hidden-mobile">
					<tr
						class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
					>
						<th class="has-text-grey-light">Nazwisko</th>
						<th class="has-text-grey-light">Imię</th>
						<th class="has-text-grey-light">Miasto</th>
						<th class="has-text-grey-light">Adres e-mail</th>
						<th class="has-text-grey-light">Numer telefonu</th>
						<th class="has-text-grey-light">Zarządzaj</th>
					</tr>
					<tr v-if="isLoading">
						<th colspan="6" class="is-progress-bar-table">
							<progress class="progress is-primary" max="100"></progress>
						</th>
					</tr>
				</thead>

				<tbody v-if="employees.length">
					<tr
						v-for="employee in expanded ? employees : employees.slice(0, 5)"
						:key="employee.Id"
					>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Nazwisko</span
								>
								<span>{{ employee.LastName }}</span>
							</div>
						</td>

						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Imię</span
								>
								<span>{{ employee.FirstName }}</span>
							</div>
						</td>

						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Miasto</span
								>
								<span>{{ employee.ShopCity }}</span>
							</div>
						</td>

						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Adres e-mail</span
								>
								<span>{{ employee.Email }}</span>
							</div>
						</td>

						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Numer telefonu</span
								>
								<span>{{ employee.Phone }}</span>
							</div>
						</td>

						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Zarządzaj</span
								>
								<router-link
									:style="{ textDecoration: 'none' }"
									class="button is-text has-text-weight-semibold has-text-left has-text-primary"
									:to="{
										name: 'Dane użytkownika',
										params: { id: employee.Id },
									}"
									>Szczegóły ></router-link
								>
							</div>
						</td>
					</tr>
				</tbody>

				<tbody v-else-if="!employees.length && !isLoading">
					<tr>
						<td colspan="6" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';

// Vue components
import EmployeesIcon from '@/assets/svg/group.svg';
import NotCheckedIcon from '@/assets/svg/notchecked.svg';

export default {
	components: {
		EmployeesIcon,
		NotCheckedIcon,
	},

	data() {
		return {
			userId: null,
			isLoading: true,
			expanded: false,
		};
	},

	computed: {
		...mapState('screenedUser', {
			employees: state => state.subservientEmployees,
		}),

		/**
		 * @returns {Boolean} Indicates whether the table is expandable.
		 */
		expandAvailable() {
			return this.employees ? this.employees.length > 5 : false;
		},
	},

	methods: {
		loadData() {
			this.userId = this.$route.params.id;
			this.isLoading = true;

			let userRole;

			if (this.$store.state.screenedUser.data.Roles.includes('KOUser')) {
				userRole = 'KOUser';
			} else if (this.$store.state.currentUser.data.Roles.includes('RDOUser')) {
				userRole = 'RDOUser';
			} else {
				userRole = 'default';
			}

			this.$store
				.dispatch('screenedUser/fetchSubservientEmployees', {
					userId: this.userId,
					userRole,
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.expanded = false;
		},
	},

	beforeMount() {
		this.loadData();
	},

	activated() {
		this.loadData();
	},
};
</script>

<style lang="scss" scoped></style>
