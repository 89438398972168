import Vue from 'vue';
import Router from 'vue-router';
import ViewHomePage from '@/views/ViewHomePage.vue';
import ViewMyAccountData from '@/views/ViewMyAccountData.vue';
import ViewHelpContact from '@/views/ViewHelpContact.vue';
import ViewElearnings from '@/views/ViewElearnings.vue';
import ViewElearning from '@/views/ViewElearning.vue';
import ViewWorkshops from '@/views/ViewWorkshops.vue';
import ViewWorkshopsAll from '@/views/ViewWorkshopsAll.vue';
import ViewWorkshop from '@/views/ViewWorkshop.vue';
import ViewKnowledgeBase from '@/views/ViewKnowledgeBase.vue';
import ViewKnowledge from '@/views/ViewKnowledge.vue';
import ViewPartnerAdvises from '@/views/ViewPartnerAdvises.vue';
import ViewPartner from '@/views/ViewPartner.vue';
import ViewAdminPanel from '@/views/ViewAdminPanel.vue';
import ViewWorkshopsManagement from '@/views/ViewWorkshopsManagement.vue';
import ViewWorkshopsUserList from '@/views/ViewWorkshopsUserList.vue';
import ViewWorkersManagement from '@/views/ViewWorkersManagement.vue';
import ViewOwnersManagement from '@/views/ViewOwnersManagement.vue';
import ViewOperationManagement from '@/views/ViewOperationManagement.vue';
import ViewShopsManagement from '@/views/ViewShopsManagement.vue';
import ViewUserData from '@/views/ViewUserData.vue';
import ViewAddEditShop from '@/views/ViewAddEditShop.vue';
import ViewAccount from '@/views/ViewAccount.vue';
import ViewPrivacyPolicy from '@/views/ViewPrivacyPolicy.vue';
import ViewRegulations from '@/views/ViewRegulations.vue';
import ViewStatistics from '@/views/ViewStatistics.vue';
import ViewError from '@/views/ViewError.vue';
import ViewAueTv from '@/views/ViewAueTv.vue';
import ViewAueTvArticle from '@/views/ViewAueTvArticle.vue';
import ViewConferences from '@/views/ViewConferences.vue';
import ViewConference from '@/views/ViewConference.vue';
import ViewOfferEdu from '@/views/ViewOfferEdu.vue';
import ViewOfferEduWorkshops from '@/views/ViewOfferEduWorkshops.vue';
import ViewConferenceGuest from '@/views/ViewConferenceGuest.vue';
import ViewConferenceThanks from '@/views/ViewConferenceThanks.vue';
import ViewMyShopsList from '@/views/ViewMyShopsList.vue';
import ViewStoreEmployees from '@/views/ViewStoreEmployees.vue';
import ViewWorkshopSets from '@/views/ViewWorkshopSets.vue';
import ViewLiveLecture from '@/views/ViewLiveLecture.vue';
import ViewImplementationProgram from '@/views/ViewImplementationProgram.vue';
import ViewImplementationProgramsSets from '@/views/ViewImplementationProgramsSets.vue';
import ViewImplementationProgramTasks from '@/views/ViewImplementationProgramTasks.vue';
import ViewImplementationProgramForm from '@/views/ViewImplementationProgramForm.vue';
import ViewImplementationProgramExam from '@/views/ViewImplementationProgramExam.vue';
import ViewImplementationProgramDescriptionTask from '@/views/ViewImplementationProgramDescriptionTask.vue';
import ViewOnboardingSets from '@/views/ViewOnboardingSets';
import ViewCookieConfiguration from '@/views/ViewCookieConfiguration.vue';
import ViewOnboarding from '@/views/ViewOnboarding.vue';
import ViewOnboardingTasks from '@/views/ViewOnboardingTasks.vue';
import ViewOnboardingForm from '@/views/ViewOnboardingForm.vue';
import ViewOnboardingExam from '@/views/ViewOnboardingExam.vue';
import ViewOnboardingDescriptionTask from '@/views/ViewOnboardingDescriptionTask.vue';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: '/home',
	routes: [
		{
			path: '/',
			name: 'view-home-page',
			component: ViewHomePage,
		},
		{
			path: '/moje-dane',
			name: 'Moje dane i statystyki',
			component: ViewMyAccountData,
		},
		{
			path: '/oferta-edukacyjna',
			name: 'Oferta edukacyjna',
			component: ViewOfferEdu,
		},
		{
			path: '/konferencja',
			name: 'Konferencja',
			component: ViewConferenceGuest,
		},
		{
			path: '/kongres-podziękowania',
			name: 'Kongres podziękowania',
			component: ViewConferenceThanks,
		},
		{
			path: '/oferta-edukacyjna/:id',
			name: 'Pojedyncza oferta edukacyjna',
			component: ViewOfferEduWorkshops,
			meta: {
				breadcrumb: [{ name: 'Oferta edukacyjna', link: '/oferta-edukacyjna' }],
			},
		},
		{
			path: '/kontakt',
			name: 'Pomoc i kontakt',
			component: ViewHelpContact,
		},
		{
			path: '/certyfikacja',
			name: 'Certyfikacja',
		},
		{
			path: '/kursy-e-learningowe',
			name: 'Kursy e-learningowe',
			component: ViewElearnings,
		},
		{
			path: '/kursy-e-learningowe/:id',
			name: '',
			component: ViewElearning,
			meta: {
				breadcrumb: [
					{ name: 'Kursy e-learningowe', link: '/kursy-e-learningowe' },
				],
			},
		},
		{
			path: '/kursy-e-learningowe/:id/program',
			name: '',
			component: ViewElearning,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back-full',
					},
				],
			},
		},
		{
			path: '/konferencje',
			name: 'Konferencje',
			component: ViewConferences,
		},
		{
			path: '/konferencje/:id',
			name: '',
			component: ViewConference,
			meta: {
				breadcrumb: [{ name: 'Konferencje', link: '/konferencje' }],
			},
		},
		{
			path: '/programy-wdrozeniowe',
			name: 'Programy wdrożeniowe',
			component: ViewImplementationProgramsSets,
		},

		{
			path: '/programy-wdrozeniowe/:id',
			name: '',
			component: ViewImplementationProgram,
			meta: {
				breadcrumb: [{ name: 'Zestawy szkoleń', link: '/zestawy-szkolen' }],
			},
		},

		{
			path: '/programy-wdrozeniowe/:id/week/:weekId',
			name: '',
			component: ViewImplementationProgram,
			meta: {
				breadcrumb: [
					{ name: 'Programy wdrozeniowe', link: '/programy-wdrozeniowe' },
				],
			},
		},

		{
			path: '/programy-wdrozeniowe/:programId/day/:dayId',
			name: 'Dzień programu wdrożeniowego',
			component: ViewImplementationProgramTasks,
			//meta: {
			//    breadcrumb: [{ name: 'Zadania na dzień', link: 'go-back' }],
			//},
		},

		{
			path: '/programy-wdrozeniowe/:programId/day/:dayId/form/:formId',
			name: '',
			component: ViewImplementationProgramForm,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back-full',
					},
				],
			},
		},

		{
			path: '/programy-wdrozeniowe/:programId/day/:dayId/exam/:examId',
			name: '',
			component: ViewImplementationProgramExam,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back-full',
					},
				],
			},
		},

		{
			path:
				'/programy-wdrozeniowe/:programId/day/:dayId/descriptionTask/:taskId',
			name: '',
			component: ViewImplementationProgramDescriptionTask,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back',
					},
				],
			},
		},

		{
			path: '/onboardings',
			name: 'Onboarding',
			component: ViewOnboardingSets,
		},

		{
			path: '/onboardings/:id',
			name: '',
			component: ViewOnboarding,
			meta: {
				breadcrumb: [{ name: 'Zestawy szkoleń', link: '/zestawy-szkolen' }],
			},
		},

		{
			path: '/onboardings/:id/week/:weekId',
			name: '',
			component: ViewOnboarding,
			meta: {
				breadcrumb: [
					{ name: 'Onboarding', link: '/onboardings' },
				],
			},
		},

		{
			path: '/onboardings/:programId/day/:dayId',
			name: 'Dzień onboardingu',
			component: ViewOnboardingTasks,
			//meta: {
			//    breadcrumb: [{ name: 'Zadania na dzień', link: 'go-back' }],
			//},
		},

		{
			path: '/onboardings/:programId/day/:dayId/form/:formId',
			name: '',
			component: ViewOnboardingForm,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back-full',
					},
				],
			},
		},

		{
			path: '/onboardings/:programId/day/:dayId/exam/:examId',
			name: '',
			component: ViewOnboardingExam,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back-full',
					},
				],
			},
		},

		{
			path: '/onboardings/:programId/day/:dayId/descriptionTask/:taskId',
			name: '',
			component: ViewOnboardingDescriptionTask,
			meta: {
				breadcrumb: [
					{
						name: 'Zadania na dzień',
						link: 'go-back',
					},
				],
			},
		},

		{
			path: '/warsztaty',
			name: 'Warsztaty',
			component: ViewWorkshops,
		},
		{
			path: '/warsztaty/:id',
			name: 'Pojedynczy warsztat',
			component: ViewWorkshop,
			meta: {
				breadcrumb: [{ name: 'Warsztaty', link: '/warsztaty' }],
			},
		},
		{
			path: '/warsztaty-tematy',
			name: 'Warsztaty-tematy',
			component: ViewWorkshopsAll,
			meta: {
				breadcrumb: [{ name: 'Warsztaty', link: '/warsztaty' }],
			},
		},

		{
			path: '/warsztaty-tematy/:subjectId?',
			name: '',
			component: ViewWorkshops,
			meta: {
				breadcrumb: [{ name: 'Warsztaty', link: '/warsztaty-tematy/' }],
			},
		},
		{
			path: '/baza-wiedzy',
			name: 'Baza wiedzy',
			component: ViewKnowledgeBase,
		},
		{
			path: '/baza-wiedzy/:id',
			name: '',
			component: ViewKnowledge,
			meta: {
				breadcrumb: [{ name: 'Baza wiedzy', link: '/baza-wiedzy' }],
			},
		},
		{
			path: '/ekspert-radzi',
			name: 'ekspert radzi',
			component: ViewPartnerAdvises,
		},
		{
			path: '/ekspert-radzi/:id',
			name: '',
			component: ViewPartner,
			meta: {
				breadcrumb: [{ name: 'Ekspert radzi', link: '/ekspert-radzi' }],
			},
		},
		{
			path: '/aue-tv',
			name: 'AUE TV',
			component: ViewAueTv,
		},
		{
			path: '/aue-tv/:id',
			name: '',
			component: ViewAueTvArticle,
			meta: {
				breadcrumb: [{ name: 'AUE TV', link: '/aue-tv' }],
			},
		},
		{
			path: '/lista-sklepow/:userId?',
			name: 'Lista sklepów',
			component: ViewMyShopsList,
		},
		{
			path: '/panel-zarzadania',
			name: 'Panel zarządzania',
			component: ViewAdminPanel,
		},
		{
			path: '/panel-zarzadania/warsztaty',
			name: 'Zarządzanie warsztatami',
			component: ViewWorkshopsManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/warsztaty/lista-uzytkownikow/:id',
			name: 'Lista użytkowników',
			component: ViewWorkshopsUserList,
			meta: {
				breadcrumb: [
					{ name: 'Panel zarządzania', link: '/panel-zarzadania' },
					{ name: 'Warsztaty', link: '/panel-zarzadania/warsztaty' },
				],
			},
		},
		{
			path: '/panel-zarzadania/pracownicy/:dialog?',
			name: 'Pracownicy',
			component: ViewWorkersManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/pracownicy/szukaj/:employeeFirstName',
			name: 'Szukaj pracownika',
			component: ViewWorkersManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/operacja/:dialog?',
			name: 'Operacja',
			component: ViewOperationManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/wlasciciele',
			name: 'Właściciele i Kierownicy',
			component: ViewOwnersManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/sklepy',
			name: 'Sklepy',
			component: ViewShopsManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/sklepy/szukaj/:shopId',
			name: 'Szukaj sklepu',
			component: ViewShopsManagement,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/dodaj-sklep/:ownerId?',
			name: 'Dodaj sklep',
			component: ViewAddEditShop,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/edytuj-sklep/:shopId?',
			name: 'Edytuj sklep',
			component: ViewAddEditShop,
			meta: {
				breadcrumb: [{ name: 'Panel zarządzania', link: '/panel-zarzadania' }],
			},
		},
		{
			path: '/panel-zarzadania/dane-uzytkownika/:id',
			name: 'Dane użytkownika',
			component: ViewUserData,
		},
		{
			path: '/lista-sklepow/:shopNumber/lista-pracownikow/:id',
			name: 'Pracownik sklepu',
			component: ViewUserData,
			meta: {
				breadcrumb: [
					{ name: 'Lista sklepów', link: '/lista-sklepow' },
					{ name: 'Lista pracowników', link: 'go-back' },
				],
			},
		},
		{
			path: '/moje-konto',
			name: 'Moje konto',
			component: ViewAccount,
		},
		{
			path: '/polityka-prywatnosci',
			name: 'Polityka Prywatności',
			component: ViewPrivacyPolicy,
		},
		{
			path: '/regulamin',
			name: 'Regulamin platformy edukacyjnej',
			component: ViewRegulations,
		},
		{
			path: '/moja-kariera-statystyki',
			name: 'Moje statystyki',
			component: ViewStatistics,
		},
		{
			path: '/lista-sklepow/:shopNumber/lista-pracownikow',
			name: 'Lista pracowników',
			component: ViewStoreEmployees,
			meta: {
				breadcrumb: [{ name: 'Lista sklepów', link: '/lista-sklepow' }],
			},
		},

		{
			path: '/zestawy-szkolen',
			name: 'Zestawy szkoleń',
			component: ViewWorkshopSets,
		},

		{
			path: '/wyklady-live',
			name: 'Wykłady live',
			component: ViewLiveLecture,
		},

		{
			path: '/zarządzaj-cookies',
			name: 'Zarządzaj Cookies',
			component: ViewCookieConfiguration,
		},

		{
			path: '*',
			name: 'view-error',
			component: ViewError,
		},
	],
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

/*
router.beforeEach((to, from, next) => {
  if (to.name === 'view-home-page') next();
  store.state.user.ShowUserDialog ? null : next();
});
*/
export default router;
