import axios from 'axios';

/**
 * @description This function will generate and print the course certificate.
 * @param {Number|String} userId The ID of the user who has completed the given course.
 * @param {Number|String} courseId The ID of the course which certificate we want to generate.
 * @returns {void}
 */
export default function generatePDF(userId, courseId) {
	const $script = require('scriptjs');

	$script(['/scripts/pdfmake.js'], function() {
		$script(['/scripts/vfs_fonts.js'], function() {
			axios
				.get(`/scripts/certificate.txt`)
				.then(response => {
					const certImage = response.data;
					axios
						.get(`spa/myaccount/elearning/getcert/${userId}/${courseId}`)
						.then(response => {
							let user = response.data.UserName,
								title = '„' + response.data.CourseName + '”',
								date = response.data.PassDate,
								marginUser = [],
								marginTitle = [],
								marginDate = [];
							if (user.length < 30) {
								marginUser = [70, 295, 60, 0];
							} else {
								marginUser = [70, 300, 0, 0];
							}
							if (title.length < 30) {
								marginTitle = [70, 80, 140, 0];
							} else {
								marginTitle = [70, 60, 140, 0];
							}
							if (title.length < 30) {
								marginDate = [145, 35, 0, 0];
							} else {
								marginDate = [145, 30, 0, 0];
							}
							let docDefinition = {
								pageSize: 'A4',
								pageMargins: [0, 0, 0, 0],
								info: {
									title: 'Certyfikat',
								},
								background: {
									image: certImage,
									width: 595,
									height: 841.5,
								},
								content: [
									{
										fontSize: 22,
										text: user,
										margin: marginUser,
										color: '#017b2f',
									},
									{
										fontSize: 22,
										text: title,
										margin: marginTitle,
										color: '#017b2f',
									},
									{
										fontSize: 14,
										text: date,
										margin: marginDate,
										color: '#017b2f',
									},
								],
							};
							// eslint-disable-next-line
							pdfMake.createPdf(docDefinition).download('certyfikat');
						});
				})
				.catch(() => {});
		});
	});
}


  function generateWorkshopPDF(userId, workshopId) {
    const $script = require('scriptjs');

    $script(['/scripts/pdfmake.js'], function () {
        $script(['/scripts/vfs_fonts.js'], function () {
            axios
                .get(`/scripts/workshopCert.txt`)
                .then(response => {
                    const certImage = response.data;
                    axios
                        .get(`spa/myaccount/workshop/getworkshopcertificate/${userId}/${workshopId}`)
                        .then(response => {
                            let user = response.data.UserName,
                                title = '„' + response.data.CourseName + '”',
                                date = response.data.PassDate,
                                marginUser = [],
                                marginTitle = [],
                                marginDate = [];
                            if (user.length < 30) {
                                marginUser = [100, 218, 60, 0];
                            } else {
                                marginUser = [100, 218, 60, 0];
                            }
                            if (title.length < 30) {
                                marginTitle = [100, 52, 140, 0];
                            } else {
                                marginTitle = [100, 52, 140, 0];
                            }
                            if (title.length < 30) {
                                marginDate = [52, 74, 0, 0];
                            } else {
                                marginDate = [52, 74, 0, 0];
                            }
                            let docDefinition = {
                                pageSize: 'A5',
                                pageOrientation: 'landscape',
                                pageMargins: [0, 0, 0, 0],
                                info: {
                                    title: 'Certyfikat',
                                },
                                background: {
                                    image: certImage,
                                    width: 593.4566
                                    //height: 841.5,
                                },
                                content: [
                                    {
                                        fontSize: 12,
                                        text: user,
                                        margin: marginUser,
                                        color: '#017b2f',
                                    },
                                    {
                                        fontSize: 12,
                                        text: title,
                                        margin: marginTitle,
                                        color: '#017b2f',
                                    },
                                    {
                                        fontSize: 12,
                                        text: date,
                                        margin: marginDate,
                                        color: '#017b2f',
                                    },
                                ],
                            };
                            // eslint-disable-next-line
                            pdfMake.createPdf(docDefinition).download('certyfikat');
                        });
                })
                .catch(() => { });
        });
    });
}

export { generatePDF, generateWorkshopPDF }