<template>
  <main>
    <Elearning/>
    <!--
      <ElearningTarget/>
      <ElearningOthers/>
    -->
  </main>
</template>

<script>
import Elearning from "@/components/Containers/ViewElearning/Elearning.vue";
import ElearningTarget from "@/components/Containers/ViewElearning/ElearningTarget.vue";
import ElearningOthers from "@/components/Containers/ViewElearning/ElearningOthers.vue";

export default {
  name: "ViewElearning",
  components: {
    Elearning,
    ElearningTarget,
    ElearningOthers
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
