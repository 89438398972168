<template>
	<section class="workers-management section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu">
						<div v-for="(filterType, key) in filtersList" :key="key">
							<b-collapse
								:open.sync="filterType.isOpen"
								v-if="filterType.Type === 'select'"
							>
								<p
									class="menu-label is-size-7 has-text-black has-border-bottom"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<ul class="menu-list">
										<li
											class="is-flex"
											:class="{
												'is-selected': filters[filterType.Key] === filter.Key,
											}"
											v-for="filter in filterType.Options"
											:key="filter.Key"
										>
											<a
												@click="
													(filters[filterType.Key] = filter.Key),
														(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
												class="is-size-7"
												>{{ filter.Value }}</a
											>
											<button
												class="delete is-small"
												@click="
													clearFiltersHandler(
														filterType.Key,
														activeTab === 0 ? loadWorkersHandler : null
													)
												"
											></button>
										</li>
									</ul>
								</div>
							</b-collapse>
							<div v-else>
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold filter-input"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full">
							<div class="columns is-variable is-3 actions">
								<div class="column is-half is-offset-half">
									<div class="columns">
										<div
											class="column is-12-tablet is-8-desktop is-offset-4-desktop is-6-widescreen is-offset-6-widescreen"
										>
											<a
												href="#"
												@click.prevent="
													(isAddWorkerModalActive = true), $v.$reset()
												"
												class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
											>
												<span class="icon is-small">
													<AddIcon />
												</span>
												<span>Dodaj pracownika</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<b-tabs v-model="activeTab">
							<b-tab-item label="Dane">
								<div class="column is-full is-block has-text-centered">
									<div class="card-table card-table-has-expand">
										<div class="card is-hidden-mobile">
											<div class="card-header">
												<div class="field">
													<div class="control has-icons-left has-icons-right">
														<input
															class="input is-size-4"
															type="text"
															v-model="filters.SearchPhrase"
															:placeholder="'Szukaj pracownika'"
															@change="
																$emit('update:search', $event.target.value)
															"
														/>
														<span class="icon is-small is-left">
															<LoupeIcon />
														</span>
													</div>
												</div>
											</div>
										</div>
										<table class="table table-workers">
											<thead class="is-hidden-mobile">
												<tr
													class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
												>
													<th class="has-text-grey-light">Kod sklepu</th>
													<th class="has-text-grey-light">Imię</th>
													<th class="has-text-grey-light">Nazwisko</th>
													<th class="has-text-grey-light">Stanowisko</th>
													<th class="has-text-grey-light">Numer telefonu</th>
													<th class="has-text-grey-light">Status</th>
													<th class="has-text-grey-light"></th>
												</tr>
												<tr v-if="loading">
													<th colspan="7" class="is-progress-bar-table">
														<progress
															class="progress is-primary"
															max="100"
														></progress>
													</th>
												</tr>
											</thead>
											<tbody v-if="workersList.length">
												<!-- Statuses legend -->
												<tr>
													<td class="has-text-black is-size-18" colspan="8">
														<div class="is-flex">
															<i
																class="is-dot is-inline-block has-background-info"
															></i>
															<span>Aktywny</span>

															<i
																class="is-dot is-inline-block has-background-warning"
															></i>
															<span>Dodany</span>

															<i
																class="is-dot is-inline-block has-background-danger"
															></i>
															<span>Uczestnik warsztatu</span>
														</div>
													</td>
												</tr>
												<!-- END | Statuses legend -->

												<tr
													class="is-size-17 has-text-left has-text-weight-semibold"
													v-for="(worker, key) in workersList"
													:key="key"
													:class="{ 'is-active': worker.isOpen }"
												>
													<td colspan="8" class="is-paddingless">
														<table>
															<tr @click="worker.isOpen = !worker.isOpen">
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Kod sklepu</span
																		>
																		<span>{{ worker.ShopNumber }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Imię</span
																		>
																		<span>{{ worker.FirstName }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Nazwisko</span
																		>
																		<span>{{ worker.LastName }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Stanowisko</span
																		>
																		<span>{{ worker.WorkPosition }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Numer telefonu</span
																		>
																		<span>{{ worker.Phone }}</span>
																	</div>
																</td>
																<td>
																	<div
																		class="is-dotted is-flex has-text-centered"
																	>
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Status</span
																		>
																		<i
																			:class="[
																				'is-dot',
																				'is-inline-block',
																				'is-hidden-mobile',
																				getWorkerStatusClass(worker.Status),
																			]"
																		></i>
																		<span class="is-hidden-tablet">{{
																			worker.Status
																		}}</span>
																	</div>
																</td>
																<td class="is-hidden-mobile has-text-centered">
																	<DwnArrowIcon
																		width="12"
																		height="24"
																		viewBox="0 0 12 20"
																		class="dwn-arrow-icon"
																	/>
																</td>
															</tr>
															<transition name="has-fade">
																<tr v-if="worker.isOpen">
																	<td colspan="8" class="is-paddingless">
																		<table
																			:class="{ 'is-editable': worker.edit }"
																		>
																			<thead>
																				<tr>
																					<th
																						class="has-text-grey-light is-size-18 is-uppercase has-text-weight-semibold has-text-left"
																						v-if="!worker.edit"
																					>
																						<div class="is-flex">
																							<p>Dane pracownika</p>
																							<router-link
																								:to="{
																									name: 'Dane użytkownika',
																									params: { id: worker.Id },
																								}"
																								class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
																							>
																								<span class="icon is-small">
																									<CogWheelIcon />
																								</span>
																								<span>Zobacz profil</span>
																							</router-link>
																						</div>
																					</th>
																					<th
																						class="has-background-white"
																						v-else
																					>
																						<div
																							class="columns is-multiline is-flex"
																						>
																							<div class="column is-6">
																								<h3
																									class="is-size-7 has-text-semibold has-text-weight-semibold has-text-black"
																								>
																									Dane pracownika
																								</h3>
																							</div>
																							<div class="column is-6 is-flex">
																								<a
																									href="#"
																									@click.prevent="
																										worker.edit = !worker.edit
																									"
																									class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
																								>
																									<span>Anuluj</span>
																								</a>
																								<a
																									href="#"
																									@click.prevent="
																										submitEditFormHandler(
																											worker
																										)
																									"
																									:class="{
																										'is-loading': loading,
																									}"
																									class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
																								>
																									<span>Zapisz</span>
																								</a>
																							</div>
																							<div
																								class="column is-6 is-offset-6 is-size-8 has-text-weight-light has-text-right"
																								v-if="errorMsg || successMsg"
																							>
																								<p
																									class="has-text-danger"
																									v-if="errorMsg"
																								>
																									{{ errorMsg }}
																								</p>
																								<p
																									class="has-text-primary"
																									v-if="successMsg"
																								>
																									{{ successMsg }}
																								</p>
																							</div>
																						</div>
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr class="is-size-17">
																					<td>
																						<div
																							class="columns is-variable is-8 is-multiline"
																						>
																							<div
																								class="column is-12-tablet is-6-desktop"
																							>
																								<div
																									class="is-dotted has-text-left"
																								>
																									<span>Sieć</span>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container-text has-background-white"
																											>
																												<input
																													class="input"
																													v-model="
																														formData.editWorker
																															.Network
																													"
																													type="text"
																													disabled
																												/>
																											</div>
																										</div>
																									</div>
																									<span
																										class="is-size-3 has-text-weight-light has-text-primary"
																										>{{ worker.Network }}</span
																									>
																								</div>
																								<div class="is-flex has-select">
																									<div
																										class="is-dotted has-text-left"
																									>
																										<span>Kod sklepu</span>
																										<span
																											class="is-size-3 has-text-weight-light has-text-primary"
																											>{{
																												worker.ShopNumber
																											}}</span
																										>
																									</div>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container has-background-white"
																												:class="{
																													'has-error':
																														$v.formData
																															.editWorker
																															.ShopNumber
																															.$error,
																												}"
																											>
																												<v-selector
																													:max-height="'300px'"
																													v-model="
																														formData.editWorker
																															.ShopNumber
																													"
																													@search="
																														searchShopHandler
																													"
																													:options="shopList"
																													placeholder="Kod sklepu"
																													label="Name"
																												>
																													<span
																														slot="no-options"
																														class="has-text-centered is-size-15"
																														>Brak wyników
																														spełniających
																														kryteria
																														wyszukiwania</span
																													>
																												</v-selector>
																											</div>
																										</div>
																									</div>
																								</div>
																								<div
																									class="is-dotted has-text-left"
																								>
																									<span>Imię</span>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container-text has-background-white"
																												:class="{
																													'has-error':
																														$v.formData
																															.editWorker
																															.FirstName.$error,
																												}"
																											>
																												<input
																													class="input"
																													@blur="
																														$v.formData.editWorker.FirstName.$touch()
																													"
																													v-model="
																														formData.editWorker
																															.FirstName
																													"
																													type="text"
																												/>
																												<span
																													class="is-size-8 has-text-weight-light has-text-danger"
																													v-for="(error,
																													index) in validationMessages(
																														$v.formData
																															.editWorker
																															.FirstName
																													)"
																													:key="index"
																													>{{ error }}</span
																												>
																											</div>
																										</div>
																									</div>
																									<span
																										class="is-size-3 has-text-weight-light has-text-primary"
																										>{{
																											worker.FirstName
																										}}</span
																									>
																								</div>
																								<div
																									class="is-dotted has-text-left"
																								>
																									<span>Nazwisko</span>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container-text has-background-white"
																												:class="{
																													'has-error':
																														$v.formData
																															.editWorker
																															.LastName.$error,
																												}"
																											>
																												<input
																													class="input"
																													@blur="
																														$v.formData.editWorker.LastName.$touch()
																													"
																													v-model="
																														formData.editWorker
																															.LastName
																													"
																													type="text"
																												/>
																												<span
																													class="is-size-8 has-text-weight-light has-text-danger"
																													v-for="(error,
																													index) in validationMessages(
																														$v.formData
																															.editWorker
																															.LastName
																													)"
																													:key="index"
																													>{{ error }}</span
																												>
																											</div>
																										</div>
																									</div>
																									<span
																										class="is-size-3 has-text-weight-light has-text-primary"
																										>{{ worker.LastName }}</span
																									>
																								</div>
																							</div>
																							<div
																								class="column is-12-tablet is-6-desktop"
																							>
																								<div class="is-flex has-select">
																									<div
																										class="is-dotted has-text-left"
																									>
																										<span>Stanowisko</span>
																										<span
																											class="is-size-3 has-text-weight-light has-text-primary"
																											>{{
																												worker.WorkPosition
																											}}</span
																										>
																									</div>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container has-background-white"
																											>
																												<v-selector
																													:max-height="'300px'"
																													label="Value"
																													v-model="
																														formData.editWorker
																															.WorkPosition
																													"
																													:options="
																														workPositionsList
																													"
																													placeholder="Wybierz"
																												>
																													<span
																														slot="no-options"
																														>Brak wyników
																														spełniających
																														kryteria
																														wyszukiwania</span
																													>
																												</v-selector>
																											</div>
																										</div>
																									</div>
																								</div>
																								<div
																									class="is-dotted has-text-left"
																								>
																									<span>Adres email</span>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container-text has-background-white"
																												:class="{
																													'has-error':
																														$v.formData
																															.editWorker.Email
																															.$error,
																												}"
																											>
																												<input
																													class="input"
																													@blur="
																														$v.formData.editWorker.Email.$touch()
																													"
																													v-model="
																														formData.editWorker
																															.Email
																													"
																													type="text"
																												/>
																												<span
																													class="is-size-8 has-text-weight-light has-text-danger"
																													v-for="(error,
																													index) in validationMessages(
																														$v.formData
																															.editWorker.Email
																													)"
																													:key="index"
																													>{{ error }}</span
																												>
																											</div>
																										</div>
																									</div>
																									<span
																										class="is-size-3 has-text-weight-light has-text-primary"
																										>{{ worker.Email }}</span
																									>
																								</div>
																								<div
																									class="is-dotted has-text-left"
																								>
																									<span>Numer telefonu</span>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container-text has-background-white"
																												:class="{
																													'has-error':
																														$v.formData
																															.editWorker.Phone
																															.$error,
																												}"
																											>
																												<input
																													class="input"
																													@blur="
																														$v.formData.editWorker.Phone.$touch()
																													"
																													v-model="
																														formData.editWorker
																															.Phone
																													"
																													type="text"
																												/>
																												<span
																													class="is-size-8 has-text-weight-light has-text-danger"
																													v-for="(error,
																													index) in validationMessages(
																														$v.formData
																															.editWorker.Phone
																													)"
																													:key="index"
																													>{{ error }}</span
																												>
																											</div>
																										</div>
																									</div>
																									<span
																										class="is-size-3 has-text-weight-light has-text-primary"
																										>{{ worker.Phone }}</span
																									>
																								</div>
																								<div class="is-flex has-select">
																									<div
																										class="is-dotted has-text-left"
																									>
																										<span>Status</span>
																										<span
																											class="is-size-3 has-text-weight-light has-text-primary"
																											>{{ worker.Status }}</span
																										>
																									</div>
																									<div class="field">
																										<div class="control">
																											<div
																												class="input-container has-background-white"
																											>
																												<v-selector
																													:max-height="'300px'"
																													label="Key"
																													v-model="
																														formData.editWorker
																															.StatusId
																													"
																													:options="
																														statusList.slice(1)
																													"
																													:searchable="false"
																													placeholder="Wybierz"
																												></v-selector>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</td>
																</tr>
															</transition>
														</table>
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<tr>
													<td
														colspan="8"
														class="is-size-17 has-text-centered has-text-weight-semibold"
													>
														{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<a
										class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
										@click="
											filters.Page++;
											loadWorkersHandler();
										"
										v-if="nextPage"
										>Załaduj więcej treści</a
									>
								</div>
							</b-tab-item>
							<b-tab-item label="Statystki">
								<keep-alive>
									<WorkersStats
										:filters="filters"
										@updateSearch="updateSearch"
										v-if="activeTab === 1"
									/>
								</keep-alive>
							</b-tab-item>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ
						<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li v-for="(filterType, key) in filtersList" :key="key">
							<div v-if="filterType.Type === 'select'">
								<b-collapse :open.sync="filterType.isOpen">
									<span
										class="has-arrow is-flex"
										slot="trigger"
										:class="{ 'is-active': filterType.isOpen }"
									>
										<span
											class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
											>{{ filterType.Title }}</span
										>
									</span>
									<div>
										<div class="navbar-content">
											<ul>
												<li
													class="is-flex has-text-left"
													:class="{
														'is-selected':
															filters[filterType.Key] === filter.Key,
													}"
													v-for="filter in filterType.Options"
													:key="filter.Key"
												>
													<a
														@click="
															(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																activeTab === 0 ? loadWorkersHandler() : null
														"
														>{{ filter.Value }}</a
													>
													<button
														class="delete is-small"
														@click="
															clearFiltersHandler(
																filterType.Key,
																activeTab === 0 ? loadWorkersHandler : null
															)
														"
													></button>
												</li>
											</ul>
										</div>
									</div>
								</b-collapse>
							</div>
							<div v-else class="filter-input">
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold has-text-left"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
		<b-modal
			:active.sync="isAddWorkerModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isAddWorkerModalActive = false"
			></button>
			<form>
				<div class="columns is-multiline">
					<div class="column is-full">
						<h2 class="is-size-1 has-text-left has-text-black">
							Dodaj pracownika
						</h2>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{
										'has-error': this.$v.formData.newWorker.ShopNumber.$error,
									}"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											v-model="formData.newWorker.ShopNumber"
											@search="searchShopHandler"
											:options="shopList"
											placeholder="Kod sklepu"
											label="Name"
										>
											<span slot="no-options" class="has-text-left"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.ShopNumber
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Imię',
										height: 56,
										hasContent: formData.newWorker.FirstName ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.FirstName.$error,
									}"
								>
									<input
										v-model="formData.newWorker.FirstName"
										@blur="$v.formData.newWorker.FirstName.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.FirstName
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Nazwisko',
										height: 56,
										hasContent: formData.newWorker.LastName ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.LastName.$error,
									}"
								>
									<input
										v-model="formData.newWorker.LastName"
										@blur="$v.formData.newWorker.LastName.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.LastName
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Adres e-mail',
										height: 56,
										hasContent: formData.newWorker.Email ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Email.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Email"
										@blur="$v.formData.newWorker.Email.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Email
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{
										'has-error': this.$v.formData.newWorker.WorkPosition.$error,
									}"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											label="Value"
											v-model="formData.newWorker.WorkPosition"
											:options="workPositionsList"
											placeholder="Stanowisko"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.WorkPosition
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer telefonu',
										height: 56,
										hasContent: formData.newWorker.Phone ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Phone.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Phone"
										@blur="$v.formData.newWorker.Phone.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Phone
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<a
							href="#"
							@click.prevent="submitAddFormHandler"
							class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
							:class="{ 'is-loading': loading }"
						>
							<span class="icon is-small">
								<svg width="17" height="20" viewBox="0 2 17 16">
									<use :xlink:href="'/assets/2018/svg/buttons/add.svg#add'" />
								</svg>
							</span>
							<span>Dodaj pracownika</span>
						</a>
						<p
							class="is-size-8 has-text-weight-light has-text-danger has-text-centered is-block"
							v-if="errorMsg"
						>
							{{ errorMsg }}
						</p>
						<p
							class="is-size-8 has-text-weight-light has-text-primary has-text-centered is-block"
							v-if="successMsg"
						>
							{{ successMsg }}
						</p>
					</div>
				</div>
			</form>
		</b-modal>
		<b-modal
			:active.sync="isDeleteWorkerModalActive"
			:width="630"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isDeleteWorkerModalActive = false"
			></button>
			<div class="columns">
				<div class="column is-full">
					<p class="is-size-2 has-text-centered has-text-weight-light">
						Czy na pewno chcesz usunąć pracownika?
					</p>
				</div>
			</div>
			<div class="columns">
				<div class="column is-6 has-text-right-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
						@click="
							submitEditFormHandler(null, true),
								(isDeleteWorkerModalActive = false)
						"
					>
						<span>TAK</span>
					</a>
				</div>
				<div class="column is-6 has-text-left-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
						@click="
							(formData.editWorker.StatusId = beforeDeltetedStatus),
								(isDeleteWorkerModalActive = false)
						"
					>
						<span>NIE</span>
					</a>
				</div>
			</div>
		</b-modal>
		<b-modal
			:active.sync="isChangeShopWorkerModalActive"
			:width="630"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isChangeShopWorkerModalActive = false"
			></button>
			<div class="columns">
				<div class="column is-full">
					<p class="is-size-2 has-text-centered has-text-weight-light">
						Czy na&nbsp;pewno pracownik ma&nbsp;zostać przeniesiony
						do&nbsp;innego sklepu?
					</p>
				</div>
			</div>
			<div class="columns">
				<div class="column is-6 has-text-right-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
						@click="
							submitEditFormHandler(null, true, true),
								(isChangeShopWorkerModalActive = false)
						"
					>
						<span>TAK</span>
					</a>
				</div>
				<div class="column is-6 has-text-left-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
						@click="isChangeShopWorkerModalActive = false"
					>
						<span>NIE</span>
					</a>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import global from '@/mixins/global.js';
import { required, email, numeric } from 'vuelidate/lib/validators';
import WorkersStats from './WorkersStats';
import FloatingLabel from '@/components/BaseFloatingLabel';
import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import DwnArrowIcon from '@/assets/svg/dwn-arrow-large.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';

export default {
	name: 'WorkersManagement',
	mixins: [global],
	components: {
		WorkersStats,
		FloatingLabel,
		AddIcon,
		LoupeIcon,
		DwnArrowIcon,
		CogWheelIcon,
	},
	props: {
		search: {
			type: String,
		},
	},
	mounted() {},
	data() {
		return {
			workersList: [],
			statsList: [],
			filtersList: [],
			shopList: [],
			statusList: [
				{ Key: 'DODANY', Value: 0 },
				{ Key: 'AKTYWNY', Value: 1 },
				{ Key: 'UCZESTNIK WARSZTATU', Value: 2 },
				{ Key: 'USUNIĘTY', Value: 3 },
			],
			workPositionsList: [],
			filters: {
				Page: 0,
				ShopNumber: null,
				SearchPhrase: '',
			},
			formData: {
				editWorker: {
					Id: null,
					Network: '',
					FirstName: '',
					LastName: '',
					WorkPosition: '',
					Email: '',
					Phone: '',
					ShopNumber: '',
					StatusId: null,
				},
				newWorker: {
					ShopNumber: '',
					FirstName: '',
					LastName: '',
					Email: '',
					WorkPosition: '',
					Phone: '',
				},
			},
			nextPage: true,
			isAddWorkerModalActive: false,
			isDeleteWorkerModalActive: false,
			isChangeShopWorkerModalActive: false,
			beforeDeltetedStatus: {},
			errorMsg: '',
			successMsg: '',
			loading: false,
			activeTab: 0,
		};
	},
	validations: {
		formData: {
			editWorker: {
				FirstName: {
					required,
				},
				LastName: {
					required,
				},
				WorkPosition: {
					required,
				},
				Phone: {
					numeric,
				},
				Email: {
					email,
					required,
				},
				ShopNumber: {
					required,
				},
				StatusId: {
					required,
				},
			},
			newWorker: {
				ShopNumber: {
					required,
				},
				FirstName: {
					required,
				},
				LastName: {
					required,
				},
				Email: {
					required,
					email,
				},
				WorkPosition: {
					required,
				},
				Phone: {
					numeric,
				},
			},
		},
		editWorkerValidationGroup: [
			'formData.editWorker.FirstName',
			'formData.editWorker.LastName',
			'formData.editWorker.WorkPosition',
			'formData.editWorker.Phone',
			'formData.editWorker.ShopNumber',
			'formData.editWorker.StatusId',
		],
		newWorkerValidationGroup: [
			'formData.newWorker.ShopNumber',
			'formData.newWorker.FirstName',
			'formData.newWorker.LastName',
			'formData.newWorker.Email',
			'formData.newWorker.WorkPosition',
			'formData.newWorker.Phone',
		],
	},
	methods: {
		/**
		 * @description Get the proper classname corresponding with the worker status.
		 * @param {Object} workerStatus The actual status of the worker.
		 * @returns {String} The classname describing the background colour of the status dot.
		 */
		getWorkerStatusClass(workerStatus) {
			switch (workerStatus) {
				case 'AKTYWNY':
					return 'has-background-info';
					break;

				case 'DODANY':
					return 'has-background-warning';
					break;

				case 'UCZESTNIK WARSZTATU':
					return 'has-background-danger';
					break;

				default:
					break;
			}
		},

		loadWorkersHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/managment/shopworkers/list`, self.filters)
				.then((response) => {
					let data = response.data.Data;
					data.forEach((item) => ((item.isOpen = false), (item.edit = false)));
					if (self.filters.Page) {
						self.workersList = [...self.workersList, ...data];
						self.nextPage = response.data.NextPage;
					} else {
						self.workersList = data;
						self.nextPage = response.data.NextPage;
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		loadWorkersFiltersHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/shopworkers/filters`)
				.then((response) => {
					this.filtersList = response.data;
					this.filtersList.forEach(function (item) {
						self.$set(item, 'isOpen', false);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},
		loadWorkPositionsDictionaryHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/shopworkers/getworkpositionsdictionary`)
				.then((response) => {
					self.workPositionsList = response.data;
				})
				.catch(() => {});
		},
		searchShopHandler(search, loading) {
			loading(true);
			this.loadShopsHandler(search, loading, this);
		},
		loadShopsHandler: _.debounce((search, loading, self) => {
			self.$http
				.post(`/spa/managment/shopworkers/getshopdictionary`, {
					Query: search,
					Network:
						self.$store.state.user.Workposition === 'Trener'
							? self.formData.editWorker.Network
							: null,
				})
				.then((response) => {
					self.shopList = response.data;
					loading(false);
				});
		}, 250),
		setWorkerHandler(worker) {
			this.$v.$reset();
			this.workersList.forEach((i) => (i.edit = false));
			worker.edit = true;

			this.formData.editWorker.Id = worker.Id;
			this.formData.editWorker.FirstName = worker.FirstName;
			this.formData.editWorker.LastName = worker.LastName;
			this.formData.editWorker.WorkPosition = this.workPositionsList.find(
				(i) => i.Value === worker.WorkPosition
			);
			this.formData.editWorker.Phone = worker.Phone;
			this.formData.editWorker.ShopNumber = worker.ShopNumber;
			this.formData.editWorker.StatusId = this.statusList.find(
				(i) => i.Value === worker.StatusId
			);
			this.formData.editWorker.Network = worker.Network;
			this.formData.editWorker.Email = worker.Email;
		},
		submitEditFormHandler: function (worker, reload, moveWorker) {
			let self = this,
				submitForm = (worker, reload) => {
					let formData = { ...this.formData.editWorker };
					formData.WorkPosition = this.formData.editWorker.WorkPosition.Value;
					formData.StatusId = this.formData.editWorker.StatusId.Value;
					formData.ShopNumber = this.formData.editWorker.ShopNumber.ShopNumber;
					self.loading = true;
					this.$http
						.post(`/spa/managment/shopworkers/edit`, formData)
						.then((response) => {
							self.loading = false;
							self.errorMsg = '';
							self.successMsg = response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.successMsg = '';
								if (worker) {
									for (let prop of Object.getOwnPropertyNames(worker))
										formData[prop] ? (worker[prop] = formData[prop]) : null;
									worker.edit = false;
									worker.Status = self.formData.editWorker.StatusId.Key;
								}
							}, 1000);
							if (reload) {
								self.filters.Page = 0;
								self.loadWorkersHandler();
							}
						})
						.catch((error) => {
							self.loading = false;
							self.successMsg = '';
							self.errorMsg = error.response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.errorMsg = '';
							}, 3000);
						});
				};
			self.$v.$touch();

			if (!self.$v.editWorkerValidationGroup.$invalid) {
				if (
					typeof self.formData.editWorker.ShopNumber === 'object' &&
					!moveWorker
				) {
					self.isChangeShopWorkerModalActive = true;
				} else {
					if (self.isDeleteWorkerModalActive) {
						submitForm(worker, reload);
					} else if (!self.$v.formData.editWorker.Email.$invalid) {
						submitForm(worker, reload);
					}
				}
			}
		},
		submitAddFormHandler: function () {
			let self = this;
			self.$v.$touch();
			if (!this.$v.newWorkerValidationGroup.$invalid) {
				let formData = { ...this.formData.newWorker };
				formData.WorkPosition = this.formData.newWorker.WorkPosition.Value;
				formData.ShopNumber = this.formData.newWorker.ShopNumber.ShopNumber;
				self.loading = true;
				this.$http
					.post(`/spa/managment/shopworkers/add`, formData)
					.then((response) => {
						self.loading = false;
						self.errorMsg = '';
						self.successMsg = response.data.Message;
						for (let prop of Object.getOwnPropertyNames(
							self.formData.newWorker
						))
							self.formData.newWorker[prop] = '';
						self.$v.$reset();
						setTimeout(() => {
							self.successMsg = '';
							self.filters.Page = 0;
							self.loadWorkersHandler();
							self.isAddWorkerModalActive = false;
						}, 3000);
					})
					.catch((error) => {
						self.loading = false;
						self.successMsg = '';
						setTimeout(() => {
							self.errorMsg = '';
						}, 3000);
						self.errorMsg = error.response.data.Message;
					});
			}
		},
	},
	watch: {
		search: function () {
			this.filters.SearchPhrase = this.search;
			this.filters.Page = 0;
			if (this.activeTab === 0) this.loadWorkersHandler();
		},
		'formData.editWorker.StatusId': function (newVal, oldVal) {
			this.beforeDeltetedStatus = oldVal;
			if (this.formData.editWorker.StatusId.Value === 3) {
				this.isDeleteWorkerModalActive = true;
			}
		},
	},
	created: function () {
		this.loadWorkersHandler();
		this.loadWorkersFiltersHandler();
		this.loadWorkPositionsDictionaryHandler();
	},
	activated: function () {
		switch (this.$route.params.dialog) {
			case 'dodaj-pracownika':
				this.isAddWorkerModalActive = true;
				break;
			case 'statystyki':
				this.activeTab = 1;
				break;
		}

		if (this.$route.params.employeeFirstName) {
			this.filters.SearchPhrase = this.$route.params.employeeFirstName;
		}

		this.loadWorkersHandler();
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.workers-management {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}
	.button.is-info {
		height: 50px;
	}
	.container {
		& > :first-child {
			& > :nth-child(2) {
				@include desktop {
					margin-top: -100px;
				}
			}
		}
	}
	.b-tabs {
		width: 100%;
		& > :first-child {
			padding: 20px 15px 0;
			@include desktop {
				margin-top: -67px;
				padding: 20px 10px 0;
			}
		}
		.tab-item {
			overflow: auto;
		}
	}
	.actions,
	.card-table {
		.button.is-info:not(.has-arrow) {
			& + .button.is-info {
				margin-left: rem(20px);
			}
		}
	}
	.card-table {
		.table {
			&.table-workers {
				& > thead > tr,
				tbody > tr:not(:first-child) > td > table > tr:first-child {
					@include tablet {
						& > *:not(.is-progress-bar-table) {
							width: 17%;
							padding: rem(15px);
							vertical-align: middle;
						}
						& > :nth-child(6),
						& > :nth-child(7) {
							width: 7.5%;
						}
					}
				}
				& > tbody {
					& > :first-child:not(:last-child) {
						border-bottom: 1px solid $grey-lighter;
						& > td {
							padding: 0.3125rem 2%;
							& > div {
								flex-direction: row;
								justify-content: flex-start;
								margin-left: -15px;
								& > :nth-child(even) {
									margin-right: rem(40px);
								}
							}
						}
					}
				}
			}
			&.table-stats {
				& > thead,
				tbody > tr > td > table {
					& > tr:first-child {
						@include tablet {
							& > *:not(.is-progress-bar-table) {
								width: 12%;
								padding: rem(15px);
								vertical-align: middle;
							}
							& & > :nth-child(1) {
								width: 17%;
							}
							& > :nth-child(2),
							& > :nth-child(3),
							& > :nth-child(4) {
								width: 20%;
							}
						}
					}
					& > tr {
						thead tr:last-child:not(:first-child) > th {
							padding: 0;
						}
					}
					& > tr + tr:last-child {
						tbody tr td {
							padding-bottom: rem(20px);
						}
					}
				}
			}
		}
	}
	.columns {
		.menu {
			text-align: left;
			a {
				padding-left: 0;
				width: 100%;
			}
			.control {
				position: relative;
				z-index: 2;
				.icon {
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 3;
					color: $grey-light;
					display: none;
					@include desktop {
						display: block;
					}
				}
			}
			&-list {
				li {
					justify-content: space-between;
					letter-spacing: 1px;
					a {
						padding-right: 5px;
					}
				}
				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
			.filter-input {
				padding: rem(25px 0);
				margin-bottom: 0;
			}
		}
	}

	.filters {
		.menu-list {
			.filter-input {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
		}
	}
	.modal {
		& > :nth-child(2) {
			width: 830px;
			padding: rem(40px);
			overflow: auto;
			@include widescreen {
				overflow: visible;
			}
		}
		.field {
			margin-bottom: rem(30px);
			.control {
				.input__container,
				.input-container {
					&.has-error {
						margin-bottom: 0;
					}
					& + .has-text-danger {
						margin-top: 0;
					}
				}
			}
		}
		.field + .button {
			color: rgba(58, 171, 89, 0.5);
			height: 56px;
			width: 100%;
			max-width: none;
			.icon {
				margin-top: 4px;
			}
			span {
				color: $white;
			}
		}
		.button {
			color: rgba(58, 171, 89, 0.5);
			border-radius: 5px;
			height: 50px;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: rem(20px);
			}
			span {
				color: white;
			}
			.icon {
				margin-right: 5px;
			}
			&.is-danger {
				color: rgba(215, 33, 65, 0.5);
			}
		}
	}
}
</style>
