<template>
	<div class="column is-full is-flex has-text-left">
		<div class="card-table">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<IconCheck />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Lista zrealizowanych warsztatów
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						v-if="expandAvailable"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->
			</div>
			<table class="table">
				<thead class="is-hidden-mobile">
                    <tr class="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
                        <th class="has-text-grey-light">Nazwa warsztatu</th>
                        <th class="has-text-grey-light">Data warsztatu</th>
                        <th class="has-text-grey-light">Miasto</th>
                        <th class="has-text-grey-light">Wynik</th>
                        <th class="has-text-grey-light">Certyfikat</th>
                    </tr>
					<tr v-if="isLoading">
						<th colspan="4" class="is-progress-bar-table">
							<progress class="progress is-primary" max="100"></progress>
						</th>
					</tr>
				</thead>

				<tbody v-if="workshops.length">
                    <tr v-for="(workshop, index) in expanded
							? workshops
							: workshops.slice(0, 5)"
                        :key="index">
                        <td class="has-text-black">
                            <span>{{ workshop.Name }}</span>
                        </td>
                        <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">data warsztatu</span>
                                <span>{{ workshop.Date }}</span>
                            </div>
                        </td>
                        <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">miasto</span>
                                <span>{{ workshop.Place }}</span>
                            </div>
                        </td>
                        <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">realizacja</span>
                                <span>{{ workshop.Points }}</span>
                            </div>
                        </td>
                        <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span>
                                    <a href="#"
                                       @click.prevent="getWorkshopCert(workshop.Id)"
                                       :style="{ textDecoration: 'none', paddingRight: '1rem' }"
                                       class="button is-text has-text-weight-semibold has-text-left has-text-primary">Pobierz ></a>
                                </span>
                            </div>
                        </td>

                    </tr>
				</tbody>

				<tbody v-else-if="!workshops.length && !isLoading">
					<tr>
						<td colspan="5" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';

import IconCheck from '@/assets/svg/check.svg';
    import { generateWorkshopPDF } from '../../../../services/PDFService';
export default {
	components: {
		IconCheck,
	},

	props: {
		userId: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			isLoading: true,
			expanded: false,
		};
	},

	computed: {
		...mapState('currentUser', {
			workshops: state => state.completedWorkshops,
		}),

		/**
		 * @returns {Boolean} Indicates whether the table is expandable.
		 */
		expandAvailable() {
			return this.workshops ? this.workshops.length > 5 : false;
		},
	},

	methods: {
		loadData() {
			this.isLoading = true;

			this.$store
				.dispatch('currentUser/fetchCompletedWorkshops', this.userId)
				.finally(() => {
					this.isLoading = false;
				});
        },

        getWorkshopCert(workshopId) {
            generateWorkshopPDF(this.userId, workshopId);
        },

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.expanded = false;
		},
	},

	beforeMount() {
		this.loadData();
	},

	activated() {
		this.loadData();
	},

	watch: {
		userId() {
			this.loadData();
		},
	},
};
</script>

<style lang="scss" scoped></style>
