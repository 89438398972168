<template>
  <section class="workshops-user-list section">
    <div class="container">
      <div class="columns">
        <div class="column is-one-quarter is-hidden-mobile">
          <aside class="menu">
            <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
              <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': filterType.isOpen }">{{ filterType.Title }}</p>
              <div class="navbar-content">
                <ul class="menu-list">
                  <li class="is-flex" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                    <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadElearningsHandler()" class="is-size-7">{{ filter.Key }}</a>
                    <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadElearningsHandler)"></button>
                  </li>
                </ul>
              </div>
            </b-collapse>
          </aside>
        </div>
        <div class="column">
          <div class="columns is-paddingless is-multiline">
            <div class="column is-full is-block has-text-centered">
                <div class="card-table">
                    <div class="card is-hidden-mobile">
                      <div class="card-header">
                        <div class="field">
                          <div class="control has-icons-left has-icons-right">
                            <input class="input is-size-4" type="text" :placeholder="'Szukaj pracownika'" @change="searchMemberHandler($event)">
                            <span class="icon is-small is-left">
                              <LoupeIcon/>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="table">
                      <thead class="is-hidden-mobile">
                        <tr class="is-size-15 is-uppercase has-text-weight-semibold has-text-left">
                          <th class="has-text-grey-light">NAZWISKO</th>
                          <th class="has-text-grey-light">IMIĘ</th>
                          <th class="has-text-grey-light">NAZWA SKLEPU</th>
                          <th class="has-text-grey-light">NR TELEFONU</th>
                          <th class="has-text-grey-light has-text-centered">AKCJE</th>
                        </tr>
                      </thead>
                      <tbody v-if="participants.length">
                        <tr class="is-size-8 has-text-left has-text-weight-semibold" v-for="participant in participants" :key="participant.MemberId">
                          <td class="has-text-black is-hidden-mobile">
                            <div class="is-dotted is-block">
                              <span class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Nazwisko</span>
                              <span>{{ participant.LastName }}</span>
                            </div>
                          </td>
                          <td class="has-text-black is-hidden-mobile">
                            <div class="is-dotted is-block">
                              <span class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Imię</span>
                              <span>{{ participant.FirstName }}</span>
                            </div>
                          </td>
                          <td class="has-text-black is-hidden-tablet">
                            <div class="is-block">
                              <span>{{ participant.FirstName + ' ' + participant.LastName }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                              <span class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Nazwa sklepu</span>
                              <span>{{ participant.ShopName }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                              <span class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Nr telefonu</span>
                              <span>{{ participant.Phone }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-flex-desktop">
                              <span class="is-size-15 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Akcje:</span>
                              <span><MessageIcon/>Wiadomość</span>
                              <span @click="memberId = participant.MemberId, isDeleteMemberModalActive = true"><button class="delete is-small"></button>Usuń</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="5" class="is-size-8 has-text-centered has-text-weight-semibold">Brak danych</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
        <!--
          <h2 class="title is-2 has-text-weight-light has-text-left" v-if="!elearnings.length">{{ $parent.search ? 'Nie znaleziono wyników dla ' + $parent.search : 'Brak wyników' }}<a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="$emit('update:search', ''); clearFiltersHandler()">Resetuj wyszukiwanie</a></h2>
        -->
      </div>
    </div>
    <nav class="filters is-fixed-top">
      <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
          <span v-touch:tap="showFiltersHandler"></span>
          <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
              <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
              <ul class="menu-list">
                  <li>
                    <b-collapse :open.sync="filterType.isOpen" v-for="(filterType, key) in filtersList" :key="key">
                      <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': filterType.isOpen }">
                        <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">{{ filterType.Title }}</span>
                      </span>
                      <div>
                          <div class="navbar-content">
                              <ul>
                                <li class="is-flex has-text-left" :class="{ 'is-selected': filters[filterType.Key] === filter.Key }" v-for="filter in filterType.Options" :key="filter.Key">
                                  <a @click="filters[filterType.Key] = filter.Key, filters.Page = 0, loadElearningsHandler()">{{ filter.Value }}</a>
                                  <button class="delete is-small" @click="clearFiltersHandler(filterType.Key, loadElearningsHandler)"></button>
                                </li>
                              </ul>
                          </div>
                      </div>
                    </b-collapse>	
                  </li>
              </ul>
          </div>
      </aside>      
    </nav>  
    <b-modal :active.sync="isDeleteMemberModalActive" :width="830" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-dialog">
        <button type="button" class="modal-close is-large" @click="isDeleteMemberModalActive = false"></button>
        <div class="columns">
          <div class="column is-full">
              <p class="is-size-2 has-text-centered has-text-weight-light">Czy na pewno chcesz usunąć użytkownika z warsztatu?</p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6 has-text-right-desktop">
            <a class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button" @click="deleteMemeberHandler(true)"><span>TAK</span></a>
          </div>
          <div class="column is-6 has-text-left-desktop">  
            <a class="button is-small is-danger is-uppercase has-text-weight-bold has-glow-button" @click="isDeleteMemberModalActive = false"><span>NIE</span></a>
          </div>
          <div class="column is-12">  
            <p class="is-size-8 has-text-weight-light has-text-primary has-text-centered" v-if="successMsg">{{ successMsg }}</p>
          </div>
        </div>
    </b-modal>  
  </section>
</template>

<script>
import BaseCardCalendar from "@/components/BaseCardCalendar.vue";
import global from "@/mixins/global.js";
import CalendarSmallIcon from "@/assets/svg/small-calendar.svg";
import AddIcon from "@/assets/svg/buttons/add.svg";
import LoupeIcon from "@/assets/svg/loupe.svg";
import MessageIcon from "@/assets/svg/message.svg";

export default {
  name: "WorkshopsUserList",
  mixins: [global],
  components: {
    BaseCardCalendar,
    CalendarSmallIcon,
    AddIcon,
    LoupeIcon,
    MessageIcon
  },
  props: {
    search: {
      type: String
    }
  },
  mounted() {},
  data() {
    return {
      participants: [],
      filtersList: [],
      filters: {},
      isDeleteMemberModalActive: false,
      memberId: "",
      successMsg: ""
    };
  },
  methods: {
    loadParticipantsHandler() {
      let self = this;
      self.$http
        .get(`spa/trainer/workshop/getparticipants/${self.$route.params.id}`)
        .then(response => {
          self.participants = response.data;
        })
        .catch(() => {});
    },
    searchMemberHandler(e) {
      let self = this;
      self.$http
        .post(`spa/trainer/workshop/searchmember`, {
          WorkshopId: self.$route.params.id,
          Phrase: e.target.value
        })
        .then(response => {
          self.participants = response.data;
        })
        .catch(() => {});
    },
    deleteMemeberHandler() {
      let self = this;
      self.$http
        .post(`spa/trainer/workshop/deletemember`, {
          WorkshopId: self.$route.params.id,
          MemberId: self.memberId
        })
        .then(response => {
          self.successMsg = response.data.Message;
          self.loadParticipantsHandler();
          setTimeout(() => {
            self.successMsg = "";
            self.isDeleteMemberModalActive = false;
          }, 3000);
        })
        .catch(() => {});
    }
  },
  created: function() {
    this.loadParticipantsHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.workshops-user-list {
  padding-bottom: rem(60px);
  padding-top: 0;
  @include desktop {
    padding-top: rem(30px);
  }
  .title {
    margin-left: rem(30px);
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    a {
      margin-left: 15px;
    }
  }
  .button.is-light.is-outlined {
    padding: 25px 50px;
    border-color: $grey-lighter;
    &:hover,
    &:focus {
      background: transparent;
      border-color: $grey-light;
    }
  }
  .card-table {
    margin-top: 10px;
    overflow: visible;
    .card {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      &-header {
        padding: rem(20px);
        .field {
          width: 100%;
        }
        .input {
          border-color: transparent;
        }
      }
    }
    .table {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      tbody tr {
        @include desktop {
          border-bottom: 1px solid $grey-lightest;
        }
        td {
          border: 0;
        }
        td:not(:first-child):not(:last-child):not(:nth-child(3)) {
          padding: rem(10px 20px);
          @include desktop {
            padding: rem(10px 30px);
          }
        }
        td:nth-child(3) {
          padding: rem(30px 20px 10px);
        }
        td:last-child {
          border-bottom: 1px solid $grey-lightest;
          @include desktop {
            border-bottom: none;
          }
        }
        & > :last-child {
          & > .is-dotted {
            justify-content: center;
            &:before {
              display: none;
            }
            & > :first-child {
              margin-bottom: rem(15px);
              @include desktop {
                margin-bottom: 0;
              }
            }
            & > :nth-child(2) {
              color: $green;
              clear: left;
              float: left;
              @include desktop {
                color: $black;
              }
            }
            & > :nth-child(3) {
              color: $red;
              float: left;
              cursor: pointer;
              @include desktop {
                color: $black;
              }
              .delete {
                &:before,
                &:after {
                  background-color: $red;
                }
                @include desktop {
                  &:before,
                  &:after {
                    background-color: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .dropdown {
    padding: 0;
    flex: 2;
    width: 100%;
    @include desktop {
      font-size: 14px;
    }
    @include widescreen {
      font-size: 16px;
    }
    & > :first-child,
    .button {
      width: inherit;
    }
    & > :nth-child(2) {
      right: 0;
      left: auto;
    }
    .button {
      box-shadow: none;
      border-color: $grey-light;
      text-decoration: none;
      padding-left: rem(20px);
    }
    & > :last-child {
      padding: 0;
      width: 100%;
      & > :first-child {
        padding: 0;
      }
    }
    &-item {
      background: $grey-dark;
      color: $white;
      padding: 15px 20px;
      border-bottom: solid 1px $grey;
      &:hover,
      &:focus {
        background: $green-lighter;
        color: white;
      }
    }
    &-item:first-child {
      &:before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 20px;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $grey-dark;
        z-index: 1;
      }
      &:hover,
      &:focus {
        &:before {
          border-bottom: 10px solid $green-lighter;
        }
      }
    }
    .has-arrow {
      height: 50px;
      padding-right: 40px;
      @include widescreen {
        min-width: 224px;
      }
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        border-color: $white;
        background: $grey-light;
        position: absolute;
        right: 20px;
        border-radius: 50%;
        top: 16px;
      }
      &:after {
        transform: rotate(-45deg);
        border-color: $white;
        z-index: 1;
        position: absolute;
        right: 10px;
        margin: -4px 15px 0 0;
      }
    }
  }
  .collapse-trigger {
    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .columns {
    .menu {
      text-align: left;
      a {
        padding-left: 0;
        width: 100%;
      }
      .control {
        position: relative;
        z-index: 2;
        .icon {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 3;
          color: $grey-light;
          display: none;
          @include desktop {
            display: block;
          }
        }
      }
      &-list {
        li {
          justify-content: space-between;
        }
        &:last-child {
          border-bottom: 1px solid $grey-lighter;
          padding: 20px 0 25px 0;
        }
      }
    }
  }
  li {
    letter-spacing: 1px;
    a {
      padding-right: 5px;
    }
  }
  .delete {
    display: none;
    align-self: center;
  }
  .menu-label {
    .delete {
      display: inline-block;
    }
  }
  .is-selected {
    font-weight: bold;
    .delete {
      float: left;
      display: block;
      background-color: #bebebe;
      @include desktop {
        float: right;
      }
    }
    .delete:before,
    .delete:after {
      background-color: $white;
    }
  }
  .is-dotted {
    svg {
      margin-right: rem(10px);
    }
    .delete {
      display: inline-block;
      min-height: 19px;
      min-width: 19px;
      &:before,
      &:after {
        background-color: $black;
      }
    }
    span + span:not(:last-child) {
      margin-right: rem(20px);
    }
  }
  .modal {
    .button {
      height: 50px;
      width: 100%;
    }
  }
}
</style>
