const validationMessages = function (v) {
    const errors = [],
        validations = [];
    for (let prop of Object.keys(v))
        if (prop.charAt(0) !== '$') validations.push(prop)

    if (!v.$dirty) return errors;
    if (validations.indexOf('required') !== -1) {
        !v.required &&
            errors.push('Wypełnij wymagane pole.');
    }
    if (validations.indexOf('minLength') !== -1) {
        !v.minLength &&
            errors.push('Podana wartość jest za krótka.');
    }
    if (validations.indexOf('maxLength') !== -1) {
        !v.maxLength &&
            errors.push('Podana wartość jest za długa.');
    }
    if (validations.indexOf('email') !== -1) {
        !v.email &&
            errors.push('Wpisz poprawny adres e-mail.');
    }
    if (validations.indexOf('numeric') !== -1) {
        !v.numeric &&
            errors.push('Wpisz poprawny format (tylko cyfry)');
    }
    if (validations.indexOf('zipCode') !== -1) {
        !v.zipCode &&
            errors.push('Wpisz poprawny format kodu pocztowego (xx-xxx).');
    }
    if (validations.indexOf('sameAs') !== -1) {
        !v.sameAs &&
            errors.push('Podane hasła nie mogę się różnić.');
    }
    if (validations.indexOf('salesmanNumber') !== -1) {
        !v.salesmanNumber &&
            errors.push('Niepoprawny identyfikator.');
    }
    if (validations.indexOf('cardNumber') !== -1) {
        !v.cardNumber &&
            errors.push('Niepoprawny numer karty.');
    }
    return errors;
}

export default validationMessages;
