<template>
  <article class="card" @click="$emit('goToUrlHandler')">
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="is-size-8 is-uppercase has-text-weight-bold has-text-primary-hover has-text-left">{{ categoryName }}</p>
          <h2 class="is-size-2 has-text-left" v-if="title">{{ title }}</h2>
          <p class="is-size-7 has-text-weight-light has-text-left">{{ subtitle }}</p>
          <RightArrowSmallIcon class="right-arrow-small-icon"/>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";

export default {
  name: "BaseCard",
  components: {
    RightArrowSmallIcon
  },
  props: {
    categoryName: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";

.card {
  cursor: pointer;
  box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
  border: solid 1px #f2f2f2;
  height: 100%;
  .right-arrow-small-icon {
    margin-top: -10px;
  }
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
    }
  }
  &-content {
    padding: rem(30px 20px);
    padding-right: rem(50px);
    p:first-child {
      margin-bottom: rem(30px);
    }
    h2 {
      margin: rem(-20px 0 15px);
    }
  }
}
</style>
