<template>
  <section class="knowledge-base section">
    <div class="container">
      <div class="columns">
        <div class="column is-one-quarter is-hidden-mobile">
          <aside class="menu">
            <b-collapse :open.sync="isOpen[0]">
              <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': isOpen[0] }">KATEGORIE</p>
              <div class="navbar-content">
                <ul class="menu-list">
                  <li v-for="item in dictionary.Categories" :key="item.Id" class="is-flex" :class="{ 'is-selected': filters.CategoryId === item.Key }">
                    <a @click="filters.CategoryId = item.Key, filters.Page = 0, loadKnowledgeHandler()" class="is-size-7">{{ item.Label }}</a>
                    <button class="delete is-small" @click="clearFiltersHandler(false, loadKnowledgeHandler)"></button>
                  </li>
                </ul>
              </div>
            </b-collapse>  
          </aside>
        </div>
        <div class="column">
          <div class="columns is-multiline" v-if="knowledge.length">
            <div class="column is-full"><BaseCardLeftImage @goToUrlHandler="goToUrlHandler('/baza-wiedzy/' + knowledge[0].Id)" :image="knowledge[0].Image" :categoryName="knowledge[0].CategoryName" :title="knowledge[0].Title" :subtitle="knowledge[0].Subtitle"/></div>
            <div class="column is-one-third" v-for="item in knowledge.slice(1, 4)" :key="item.Id"><BaseCard @goToUrlHandler="goToUrlHandler('/baza-wiedzy/' + item.Id)" :categoryName="item.CategoryName" :title="item.Title" :subtitle="item.Subtitle"/></div>
            <div class="column is-full" v-for="item in knowledge.slice(4)" :key="item.Id"><BaseCard @goToUrlHandler="goToUrlHandler('/baza-wiedzy/' + item.Id)" :categoryName="item.CategoryName" :title="item.Title" :subtitle="item.Subtitle"/></div>
            <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadKnowledgeHandler()" v-if="nextPage">Załaduj więcej treści</a>
          </div>
        </div>
      </div>
    </div>
    <nav class="filters is-fixed-top">
      <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
          <span v-touch:tap="showFiltersHandler"></span>
          <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
              <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
              <ul class="menu-list">
                  <li>
                    <b-collapse :open.sync="isOpen[0]">
                        <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': isOpen[0] }">
                          <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">KATEGORIE</span>
                        </span>
                        <div>
                            <div class="navbar-content">
                                <ul>
                                  <li v-for="item in dictionary.Categories" :key="item.Id" class="is-flex has-text-left" :class="{ 'is-selected': filters.CategoryId === item.Key  }">
                                    <a class="navbar-item has-text-left is-size-7 is-uppercase" @click="filters.CategoryId = item.Key, filters.Page = 0, loadKnowledgeHandler()">{{ item.Label }}
                                    </a>
                                    <button class="delete is-small" @click="clearFiltersHandler(false, loadKnowledgeHandler)"></button>
                                  </li>
                                </ul>
                            </div>
                        </div>
                    </b-collapse>
                  </li>
              </ul>
          </div>
      </aside>      
    </nav>  
  </section>
</template>

<script>
import StarRating from "vue-star-rating";
import global from "@/mixins/global.js";
import BaseCard from "@/components/BaseCard.vue";
import BaseCardLeftImage from "@/components/BaseCardLeftImage.vue";

export default {
  name: "KnowledgeBase",
  mixins: [global],
  components: {
    StarRating,
    BaseCard,
    BaseCardLeftImage
  },
  props: [],
  mounted() {},
  data() {
    return {
      knowledge: [],
      dictionary: [],
      filters: {
        Page: 0,
        CategoryId: "",
        Query: ""
      },
      nextPage: true,
      isOpen: [true]
    };
  },
  methods: {
    loadKnowledgeHandler() {
      let self = this;
      self.$http
        .post(`spa/knowledge/list`, self.filters)
        .then(response => {
          if (self.filters.Page) {
            self.knowledge = [...self.knowledge, ...response.data.Data];
            self.nextPage = response.data.NextPage;
          } else {
            self.knowledge = response.data.Data;
            self.nextPage = response.data.NextPage;
          }
        })
        .catch(() => {});
    }
  },
  created: function() {
    let self = this;
    this.loadKnowledgeHandler();
    this.$http.get(`spa/knowledge/dictionary`).then(response => {
      self.dictionary = response.data;
    });
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.knowledge-base {
  padding-bottom: rem(60px);
  .title {
    margin-left: rem(30px);
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }
  .button {
    padding: 25px 50px;
    margin: rem(30px auto 0);
    border-color: $grey-lighter;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
  .menu {
    text-align: left;
    a {
      padding-left: 0;
    }
    &-list .navbar-content ul li:last-child {
      border-bottom: 0;
    }
  }
  li {
    letter-spacing: 1px;
  }
  .delete {
    display: none;
    align-self: center;
  }
  .menu-label {
    .delete {
      display: inline-block;
    }
  }
  .columns {
    .menu {
      text-align: left;
      a {
        padding-left: 0;
        width: 100%;
      }
      &-list {
        li {
          justify-content: space-between;
        }
        &:last-child {
          border-bottom: 1px solid $grey-lighter;
          padding: 20px 0 25px 0;
        }
      }
    }
  }
  .is-selected {
    font-weight: bold;
    .delete {
      float: right;
      display: block;
      background-color: #bebebe;
    }
    .delete:before,
    .delete:after {
      background-color: white;
    }
  }
}
</style>
