<template>
    <form @submit.prevent="submitFormHandler">
        <h2 class="is-size-1 has-text-weight-light has-text-left">Zaloguj się</h2>
        <div class="field">
            <div class="control">
              <FloatingLabel :config="{label: 'Twój Login', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.Login.$error }">
                  <input v-model="formData.Login" @blur="$v.formData.Login.$touch(), touched = true" type="text" class="input" :class="touched ? 'is-touched' : null" required>
                  <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.Login)" :key="index">{{ error }}</span>
              </FloatingLabel>
            </div>
        </div>
        <div class="field">
            <div class="control">
              <FloatingLabel :config="{label: 'Hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.Password.$error }">
                  <input v-model="formData.Password" @blur="$v.formData.Password.$touch(), touched = true" type="Password" class="input" :class="touched ? 'is-touched' : null" required>
                  <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.Password)" :key="index">{{ error }}</span>
              </FloatingLabel>
            </div>
        </div>
        <p class="is-size-8 has-text-weight-light has-text-danger has-text-left" v-if="errorMsg">{{ errorMsg }}</p>
        <div class="field is-grouped">
            <div class="control">
                <button class="button is-danger is-fullwidth is-uppercase has-text-weight-semibold has-glow-button" :class="{ 'is-loading': loading }" type="submit">
                    <span class="icon">
                      <LoginIcon class="icon is-large"/>
                    </span>
                    <span class="is-size-7-em">Zaloguj się</span>
                </button>
            </div>
        </div>
        <ul class="is-size-8 has-text-centered">
            <li><a href="#" @click.prevent="$emit('openResetModalHandler')">Zapomniałeś hasła?</a></li>
        </ul>
    </form>
</template>

<script>
import FloatingLabel from "@/components/BaseFloatingLabel";
import global from "@/mixins/global.js";
import { required } from "vuelidate/lib/validators";
import LoginIcon from "@/assets/svg/log-in.svg";

export default {
  name: "LoginForm",
  mixins: [global],
  components: {
    FloatingLabel,
    LoginIcon
  },
  data() {
    return {
      formData: {
        Login: "",
        Password: ""
      },
      errorMsg: "",
      loading: false,
      touched: false
    };
  },
  validations: {
    formData: {
      Login: {
        required
      },
      Password: {
        required
      }
    }
  },
  methods: {
    submitFormHandler: function() {
      let self = this;
      self.$v.$touch();
      if (!this.$v.$invalid) {
        self.loading = true;
        this.$http
          .post(`spa/user/login`, self.formData)
            .then((response) => {

                if (typeof (response.data.IsRedirect) != 'undefined' && (response.data.IsRedirect != null) && (response.data.IsRedirect == true)) {
                   //redirect gdy adres nie jest do pliku tylko do strony  na umbraco lub 
                    window.location = response.data.Redirect;
                    }
                   // });
                //}
                else { 
                    self.errorMsg = "";
                    this.$emit("closeModalHandler");
                    window.location = "/home";
                }
          })
          .catch(error => {
            self.loading = false;
            self.errorMsg = error.response.data.Message;
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

form {
  max-width: 376px;
  padding: 20px 30px;
  margin: rem(0 auto 80px);
  background: $white;
  border-radius: 4px;
  @include tablet {
    margin: rem(120px auto 80px);
  }
  h2 {
    margin-bottom: rem(25px);
  }
  ul {
    justify-content: space-between;
    li {
      a:hover,
      &:focus {
        text-decoration: underline;
        color: $green-lighter;
      }
    }
  }
  p.has-text-danger {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .field:not(:last-child) {
    margin-bottom: rem(20px);
  }
  .is-grouped {
    margin-bottom: rem(10px);
  }
  .control {
    width: 100%;
  }
  .input__container {
    margin-bottom: 30px;
  }
  .is-loading {
    .icon,
    span {
      visibility: hidden;
    }
  }
  .button.is-danger {
    color: rgba(222, 38, 71, 0.5);
    padding: rem(10px 60px);
    font-size: rem(16px);
    &:hover,
    &.is-danger.is-hovered {
      color: rgba(222, 38, 71, 0.5);
    }
    .icon {
      margin-top: rem(4px);
    }
    span {
      color: $white;
      letter-spacing: 0.5px;
    }
  }
}
</style>