<template>
  <div>
    <section class="knowledge section">
      <div class="container is-container-thinnest">
        <div class="columns is-variable is-8 is-multiline">
          <div class="column is-full">
              <h1 class="title is-1 has-text-weight-light has-text-left has-text-shadow">{{ knowledge.Title }}</h1>
          </div>
          <div class="column is-full">
              <figure class="image" v-if="knowledge.Image">
                  <img v-bind:src="knowledge.Image" :alt="knowledge.Title" :title="knowledge.Title">
              </figure>
          </div>        
        </div>     
      </div>
    </section>
    <section class="section" style="background: white;">
      <div class="container is-container-thinnest">
        <div class="columns is-variable is-8 is-multiline">
          <div class="column is-full">
            <div class="content" v-html="knowledge.ArticleBody"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Knowledge",
        components: {},
        activated: function () {
            let self = this;
            self.$http
                .get(`spa/knowledge/getdetails/` + self.$route.params.id)
                .then(response => {
                    self.knowledge = response.data;
                    self.$set(this.$store.state, "breadcrumb", self.knowledge.Title);
                })
                .catch(() => { });
        },
  mounted() {},
  data() {
    return {
      knowledge: []
    };
  },
  created: function() {
   
  }
};
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .knowledge {
        padding-bottom: rem(50px);
        margin-top: rem(30px);
    }
</style>
