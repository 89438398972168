<template>
	<form>
		<h2 class="is-size-1 has-text-weight-light has-text-left">
			Wypełnij formularz
		</h2>
		<div class="field">
			<div class="control">
				<FloatingLabel
					:hasContent="true"
					:config="{
						label: 'Imię i nazwisko',
						height: 56,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: this.$v.formData.Name.$error,
					}"
				>
					<input
						v-model="formData.Name"
						@blur="$v.formData.Name.$touch()"
						type="text"
						class="input"
						required
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.Name)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Adres email',
						height: 56,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: this.$v.formData.Email.$error,
					}"
				>
					<input
						v-model="formData.Email"
						@blur="$v.formData.Email.$touch()"
						type="text"
						class="input"
						required
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.Email)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<FloatingLabel
					:hasContent="true"
					:config="{
						label: 'Numer telefonu',
						height: 56,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: this.$v.formData.Phone.$error,
					}"
				>
					<input
						v-model="formData.Phone"
						@blur="$v.formData.Phone.$touch()"
						type="text"
						class="input"
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.Phone)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<div
					class="input-container"
					:class="{ 'has-error': this.$v.formData.Subject.$error }"
				>
					<div>
						<v-selector
							:max-height="'300px'"
							v-model="formData.Subject"
							:options="[
								{ label: 'Problemy z logowaniem', value: '1366' },
								{ label: 'Pytanie o rejestracje do programu', value: '1367' },
								{ label: 'Pytanie o współpracę', value: '1368' },
								{ label: 'Inne', value: '1369' },
							]"
							placeholder="Temat wiadomości"
						>
							<span slot="no-options" class="has-text-left"
								>Brak wyników spełniających kryteria wyszukiwania</span
							>
						</v-selector>
					</div>
				</div>
				<span
					class="is-size-8 has-text-weight-light has-text-danger"
					v-for="(error, index) in validationMessages($v.formData.Subject)"
					:key="index"
					>{{ error }}</span
				>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<div
					class="input-container"
					:class="{ 'has-error': this.$v.formData.Message.$error }"
				>
					<textarea
						v-model="formData.Message"
						class="textarea"
						type="text"
						placeholder="Temat wiadomości"
					></textarea>
				</div>
				<span
					class="is-size-8 has-text-weight-light has-text-danger"
					v-for="(error, index) in validationMessages($v.formData.Message)"
					:key="index"
					>{{ error }}</span
				>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<div
					class="input-container is-borderless"
					:class="{ 'has-error': this.$v.formData.Agreement.$error }"
				>
					<b-checkbox
						v-model="formData.Agreement"
						type="is-info"
						size="is-small"
						class="is-size-7 has-text-weight-light"
					>
						Uzupełnienie pól nieobowiązkowych stanowi zgodę na przetwarzanie
						wpisanych danych osobowych przez Akademia Umiejętności Eurocash sp.
						z o.o. Zgodę można wycofać w każdym czasie na przykład poprzez
						wysłanie maila na adres:
						<a href="mailto:kontakt@akademiaeurocash.com.pl"
							>kontakt@akademiaeurocash.com.pl</a
						>. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania
						dokonanego przed jej wycofaniem.
						<br />
						<br />
						Administratorem Pani/Pana danych osobowych jest Akademia
						Umiejętności Eurocash sp. z o.o. z siedzibą w Komornikach (62-052),
						ulica Wiśniowa 11. Pani/Pana dane osobowe będą przetwarzane w celu
						obsługi Pani/Pana zapytania poprzez formularz kontaktowy oraz w
						celach statystycznych i analitycznych. Więcej informacji na temat
						przetwarzania danych osobowych, w tym o przysługujących Pani/Panu
						prawach, znajduje się w naszej
						<a href="../polityka-prywatnosci">Polityce Prywatności</a>.
					</b-checkbox>
				</div>
			</div>
		</div>
		<div class="field is-grouped">
			<div class="control">
				<a
					class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button"
					:class="{ 'is-loading': loading }"
					@click="submitFormHandler()"
				>
					<span class="is-size-7-em has-text-white">Wyślij zgłoszenie</span>
				</a>
			</div>
		</div>
		<p
			class="is-size-8 has-text-weight-light has-text-danger has-text-centered"
			v-if="errorMsg"
		>
			{{ errorMsg }}
		</p>
		<p
			class="is-size-8 has-text-weight-light has-text-primary has-text-centered"
			v-if="successMsg"
		>
			{{ successMsg }}
		</p>
	</form>
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators';
import FloatingLabel from '@/components/BaseFloatingLabel';
import global from '@/mixins/global.js';

export default {
	name: 'HelpContactForm',
	components: {
		FloatingLabel,
	},
	mixins: [global],
	data() {
		return {
			formData: {
				Name: '',
				Email: '',
				Phone: '',
				Subject: {
					label: '',
					value: '',
				},
				Message: '',
				Agreement: '',
			},
			errorMsg: '',
			successMsg: '',
			loading: false,
		};
	},
	validations: {
		formData: {
			Name: {
				required,
			},
			Email: {
				required,
				email,
			},
			Phone: {
				numeric,
			},
			Subject: {
				value: {
					required,
				},
			},
			Message: {
				required,
			},
			Agreement: {
				required,
			},
		},
	},
	methods: {
		submitFormHandler: function () {
			let self = this;
			self.$v.$touch();
			if (!this.$v.$invalid) {
				let formData = { ...self.formData };
				self.loading = true;
				formData.Subject = self.formData.Subject.value;
				this.$http
					.post(`/spa/contact/send`, formData)
					.then((response) => {
						self.loading = false;
						self.errorMsg = '';
						self.successMsg = response.data.Message;
						for (let prop of Object.getOwnPropertyNames(self.formData))
							self.formData[prop] = '';
						self.$v.$reset();
					})
					.catch((error) => {
						self.loading = false;
						self.successMsg = '';
						self.errorMsg = error.response.data.Message;
					});
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

form {
	padding: rem(20px 30px);
	margin: rem(40px auto 30px);
	background: $white;
	border-radius: 4px;
	h2 {
		margin-bottom: rem(25px);
	}
	p.has-text-danger {
		margin-top: -10px;
		margin-bottom: 10px;
	}
	.field:not(:last-child) {
		margin-bottom: rem(20px);
	}
	.is-grouped {
		margin-bottom: rem(10px);
	}
	.textarea {
		resize: none;
		&:focus {
			border: 1px solid $green-lighter;
		}
	}
	.field .control {
		width: 100%;
		.button {
			padding: 15px 0;
			color: rgba(58, 171, 89, 0.5);
			span {
				letter-spacing: 0.5px;
			}
		}
	}
}
</style>
