<template>
    <nav class="navbar is-fixed-top">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img src="./../assets/images/logo.png" alt="Akademia Eurocash">
        </router-link>
        <div class="navbar-burger burger" data-target="navbarExampleTransparentExample" @click="toggleNavbar" :class="{ 'is-active': showNavbar }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': showNavbar }">
        <div class="navbar-start">
            <router-link class="navbar-item is-info has-text-weight-semibold is-size-7-em" to="/kursy-e-learningowe">O akademii</router-link>
            <router-link class="navbar-item is-info has-text-weight-semibold is-size-7-em" to="/kursy-e-learningowe">Oferta edukacyjna</router-link>
            <router-link class="navbar-item is-info has-text-weight-semibold is-size-7-em" to="/konferencja">Konferencja</router-link>
            <a class="navbar-item is-info has-text-weight-semibold is-size-7-em" href="#">Partnerzy</a>
            <a class="navbar-item is-info has-text-weight-semibold is-size-7-em" href="#">Kontakt</a>
            <a class="navbar-item is-info has-text-weight-semibold is-size-7-em" href="#">Pomoc</a>
        </div>
        <div class="navbar-end">
          <div class="navbar-item is-paddingless">
            <a class="button is-danger is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button">
              <span class="icon">
                <LogInIcon class="icon is-large"/>
              </span>
              <span class="is-size-7-em">Zaloguj się</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>
import LogInIcon from "@/assets/svg/log-in.svg";

export default {
  name: "TheNavBarLogin",
  components: {
    LogInIcon
  },
  data() {
    return {
      showNavbar: false
    };
  },
  methods: {
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.navbar-start {
  width: 100%;
  justify-content: space-between;
  max-width: 550px;
  margin: 0 auto;

  .navbar-item {
    & + nav {
      @include widescreen {
        width: 233px;
      }
      .navbar-item {
        width: 100%;
        & > a {
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}

.navbar-end {
  padding-right: 20px;
  .button.is-danger {
    color: rgba(222, 38, 71, 0.5);
    padding: rem(10px 60px);
    &:hover,
    &.is-danger.is-hovered {
      color: rgba(222, 38, 71, 0.5);
    }
    @include desktop {
      font-size: 14px;
    }
    @include widescreen {
      font-size: 16px;
    }
    .icon {
      margin-top: rem(4px);
    }
    span {
      color: $white;
      letter-spacing: 0.5px;
    }
  }
}

a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:hover,
.navbar-link.is-active {
  background: transparent;
}
</style>
