<template>
	<section class="homepage-news section">
		<div class="container">
			<div class="card-table" style="overflow:visible">
				<div class="card has-background-info">
					<div class="card-header">
						<CalendarIcon />
						<p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
							Zobacz wszystkie wydarzenia w kalendarzu
						</p>

						<!-- Collapse toggler -->
						<button @click="toggle()"
								class="card-toggler is-size-2 is-size-4-tablet">
							{{ expanded ? 'Zwiń' : 'Rozwiń' }}
							<span class="chevron" :class="{ active: expanded }"></span>
						</button>
					</div>
					<v-app>
						<v-row class="fill-height">
							<v-col>
								<v-sheet height="64">
									<v-toolbar flat>
										<v-toolbar-title>
											<span class="margin-container">Kalendarz</span>
										</v-toolbar-title>
										<v-btn class="calendar-btn mr-4"
											   @click="setToday"
											   color="secondary">
											Dzisiaj
										</v-btn>
										<v-btn fab text small @click="prev">
											<svg xmlns="http://www.w3.org/2000/svg"
												 viewBox="0 0 26 26"
												 class="svg-icon">
												<path d="M10.595,13l8.584-8.764c0.383-0.392,0.38-1.019-0.008-1.406l-1.536-1.536c-0.391-0.391-1.026-0.39-1.417,0.002L5.293,12.293 C5.098,12.488,5,12.744,5,13c0,0.256,0.098,0.512,0.293,0.707l10.925,10.997c0.39,0.393,1.025,0.394,1.417,0.002l1.536-1.536 c0.387-0.387,0.391-1.015,0.008-1.406L10.595,13z" />
											</svg>
										</v-btn>
										<v-btn fab text small @click="next">
											<svg xmlns="http://www.w3.org/2000/svg"
												 viewBox="0 0 26 26"
												 class="svg-icon">
												<path d="M15.405,13L6.821,4.236C6.438,3.845,6.442,3.217,6.829,2.83l1.536-1.536c0.391-0.391,1.026-0.39,1.417,0.002l10.925,10.997 C20.902,12.488,21,12.744,21,13c0,0.256-0.098,0.512-0.293,0.707L9.782,24.704c-0.39,0.393-1.025,0.394-1.417,0.002L6.829,23.17 c-0.387-0.387-0.391-1.015-0.008-1.406L15.405,13z" />
											</svg>
										</v-btn>
										<v-toolbar-title v-if="$refs.calendar">
											{{ $refs.calendar.title }}
										</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-menu bottom right>
											<template v-slot:activator="{ on, attrs }">
												<v-btn class="calendar-btn"
													   v-bind="attrs"
													   v-on="on"
													   color="secondary">
													<span>{{ typeToLabel[type] }}</span>
												</v-btn>
											</template>
											<span slot="no-options" class="has-text-left">Brak wyników spełniających kryteria wyszukiwania</span>
											<v-list>
												<v-list-item @click="type = 'day'">
													<v-list-item-title>Dzień</v-list-item-title>
												</v-list-item>
												<v-list-item @click="type = 'week'">
													<v-list-item-title>Tydzień</v-list-item-title>
												</v-list-item>
												<v-list-item @click="type = 'month'">
													<v-list-item-title>Miesiąc</v-list-item-title>
												</v-list-item>
												<v-list-item @click="type = '4day'">
													<v-list-item-title>4 dni</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-toolbar>
								</v-sheet>
								<v-sheet>
									<v-calendar ref="calendar"
												v-model="focus"
												color="primary"
												:events="events"
												:event-color="getEventColor"
												:type="type"
												@click:event="redirctToEvent"
												@click:more="viewDay"
												@click:date="viewDay"
												@change="requestData"></v-calendar>
									<v-menu v-model="selectedOpen"
											:close-on-content-click="false"
											:activator="selectedElement"
											offset-x>
										<v-card color="grey lighten-4" min-width="350px" flat>
											<v-toolbar :color="selectedEvent.color" dark>
												<v-btn icon>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
												<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
												<v-spacer></v-spacer>
												<v-btn icon>
													<v-icon>mdi-heart</v-icon>
												</v-btn>
												<v-btn icon>
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</v-toolbar>
											<v-card-text>
												<span v-html="selectedEvent.details"></span>
											</v-card-text>
											<v-card-actions>
												<v-btn text color="secondary" @click="selectedOpen = false">
													Anuluj
												</v-btn>
											</v-card-actions>
										</v-card>
									</v-menu>
								</v-sheet>
							</v-col>
						</v-row>
					</v-app>
				</div>
			</div>
		</div>
	</section>
</template>

<script charset="utf-8">
import CalendarIcon from '@/assets/svg/small-calendar-white.svg';

export default {
	components: {
		CalendarIcon,
	},

	name: 'Calendar',

	data: () => ({
		focus: '',
		type: 'month',
		typeToLabel: {
			month: 'Miesiąc',
			week: 'Tydzień',
			day: 'Dzień',
			'4day': '4 dni',
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		expanded: false,
	}),

	methods: {
		viewDay({ date }) {
			this.focus = date;
			this.type = 'day';
		},

		getEventColor(event) {
			return event.color;
		},

		setToday() {
			this.focus = '';
		},

		prev() {
			this.$refs.calendar.prev();
		},

		next() {
			this.$refs.calendar.next();
		},

		redirctToEvent({ _, event }) {
			if (event.typee == "conference") {
				this.$router.push(`/konferencje/${event.id}`);
			}
			else if (event.typee == "News") {
				//window.location.href = `/aktualności/${event.nameFile}`;
                window.location.href = event.url;
            }
		},

		requestData() {
			this.$http.get('spa/homepage/getcalendardata').then((response) => {
				this.events = response.data.map((event) => {
					const date = new Date(event.Date);

					let days = (date - Date.now()) / 86400000;
					days = parseInt(days);
					days = days + 1;

					const newEvent = {
						id: event.ID,
						url: event.Url,
						name: event.Title,
						start: date,
                        typee: event.Type,
						color: days > 7 ? '#007F23' : '#ffad33',
					};

					return newEvent;
                });
            }).then(() => {
                this.expanded ? this.expand() : this.collapse();
			});
		},

		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		onClassChange(classAttrValue) {
			const classList = classAttrValue.split(' ');
			if (classList.includes('collapsed-calendar')) {
				this.collapse();
			} else {
				this.expand();
			}
		},

		collapse() {
			this.expanded = false;
			if (!$('.v-calendar').hasClass("collapsed-calendar")) {
				$('.v-calendar').addClass("collapsed-calendar");
			}

			document.querySelectorAll('.container .v-calendar-weekly__week').forEach((el) => {
				if (el.querySelector('.v-present') == null) {
					el.classList.add('hide');
				}
			});
		},

		expand() {
			this.expanded = true;
			if ($('.v-calendar').hasClass("collapsed-calendar")) {
				$('.v-calendar').removeClass("collapsed-calendar");
			}

			document.querySelectorAll('.container .v-calendar-weekly__week').forEach((el) => {
				el.classList.remove('hide');
			});
		}
	},

	mounted() {
		this.observer = new MutationObserver(mutations => {
			for (const m of mutations) {
				const newValue = m.target.getAttribute(m.attributeName);
				this.$nextTick(() => {
					this.onClassChange(newValue, m.oldValue);
				});
			}
		});

		this.observer.observe(document.querySelector('.v-calendar'), {
			attributes: true,
			attributeOldValue: true,
			attributeFilter: ['class'],
		});

		this.collapse();
	},

	beforeDestroy() {
		this.observer.disconnect();
	},
};
</script>

<style scoped lang="scss">
@import '../assets/scss/_variables.scss';
@import '~bulma/sass/utilities/mixins';


.margin-container {
	margin-right: 15px;
}

.svg-icon {
	width: 12px;
	height: 12px;
}

.v-calendar {
	.hide {
		display: none !important;
	}

.v-calendar .v-event {
    margin-left: 2.5% !important;
	height: 200px !important;

		div {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.card-toggler.--bottom {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	border-color: #3aab59;//$green-lighter;
	color: $green-lighter;

	.chevron {
		&:first-child {
			margin-left: 0;
			margin-right: 15px;
		}

		&::before,
		&::after {
			background-color: $green-lighter;
		}
	}
}
</style>