<template>
  <div class="column is-full" id="PasswordChangeBox">
    <div class="card-table">
      <b-collapse
        class="card"
        aria-id="password-change-collapse"
        :open.sync="isOpen"
        animation="fade"
      >
        <div
          slot="trigger"
          slot-scope="props"
          class="card-header has-background-info"
          role="button"
          aria-controls="password-change-collapse"
        >
          <IconCheck />
          <h2
            class="card-header-title is-size-2 is-size-4-tablet has-text-weight-light has-text-white"
          >Zmiana hasła</h2>
          <button
            class="card-header-icon card-toggler is-size-2 is-size-4-tablet"
          >
            {{ isOpen ? 'Zwiń' : 'Rozwiń' }}
            <span class="chevron" :class="{ active: isOpen }"></span>
          </button>
        </div>
        <div class="card-content">
          <div class="columns is-centered">
            <div class="column is-one-third">
              <form class="content" ref="form" @submit.prevent="submitForm()">
                <!-- Old password -->
                <div class="field">
                  <label class="label has-text-centered">Wprowadź stare hasło</label>
                  <div class="control">
                    <input
                      name="OldPassword"
                      class="input has-text-centered"
                      type="password"
                      v-model="formData.oldPassword"
                    />
                  </div>
                </div>

                <!-- New password -->
                <div class="field">
                  <label class="label has-text-centered">Wprowadź nowe hasło</label>
                  <div class="control">
                    <input
                      name="NewPassword"
                      class="input has-text-centered"
                      type="password"
                      v-model="formData.newPassword"
                    />
                  </div>
                </div>

                <!-- New password confirmation -->
                <div class="field">
                  <label class="label has-text-centered">Powtórz nowe hasło</label>
                  <div class="control">
                    <input
                      name="NewRePassword"
                      class="input has-text-centered"
                      type="password"
                      v-model="formData.newPasswordConfirmation"
                    />
                  </div>
                </div>

                <!-- Submit button -->
                <div class="field field--submit has-text-centered">
                  <button
                    class="button is-size-7 is-info is-uppercase has-text-weight-bold is-outlined is-outlined-bold"
                    type="submit"
                  >ZAPISZ</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <b-modal
      :active.sync="modal.isActive"
      :width="830"
      :canCancel="['escape', 'x', 'outside']"
      scroll="keep"
      class="modal-dialog"
    >
      <button type="button" class="modal-close is-large" @click="modal.isActive = false"></button>
      <div class="columns">
        <div class="column is-full">
          <p class="is-size-2 has-text-centered has-text-weight-light">{{ modal.message }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <a
            class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
            @click="modal.isActive = false"
          >ZAMKNIJ OKNO</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IconCheck from '@/assets/svg/check.svg';

export default {
	components: {
		IconCheck,
	},

	data() {
		return {
			isOpen: false,

			errors: {
				oldPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
			},

			formData: {
				oldPassword: '',
				newPassword: '',
				newPasswordConfirmation: '',
			},

			modal: {
				isActive: false,
				message: '',
			},
		};
	},

	methods: {
		submitForm() {
			this.$emit('submit', {
				OldPassword: this.formData.oldPassword,
				NewPassword: this.formData.newPassword,
				NewRePassword: this.formData.newPasswordConfirmation,
			});
		},

		onSuccess() {
			this.modal.message = 'Hasło zostało zmienione.';
			this.modal.isActive = true;

			// Empty the inputs.
			Object.keys(this.formData).forEach(key => {
				this.formData[key] = '';
			});
		},

		onError() {
			this.modal.message =
				'Wystąpił błąd. Sprawdź poprawność wprowadzonych danych i spróbuj ponownie.';
			this.modal.isActive = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.field {
	&--submit {
		margin-top: 30px;

		button {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
</style>