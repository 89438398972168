<template>
  <section class="elearning-others section">
    <div class="container is-container-thinnest">
      <div class="columns">
        <div class="column is-full">
            <div class="tile is-ancestor">
                <BaseTile :image="'http://localhost:57142/media/3016/car4044.jpg'" :category="'Zarządzanie'" :title="'Zarządzanie personelem'"/>
                <BaseTile :image="'http://localhost:57142/media/3016/car4044.jpg'" :category="'Zarządzanie'" :title="'Zarządzanie personelem'"/>
                <BaseTile :image="'http://localhost:57142/media/3016/car4044.jpg'" :category="'Zarządzanie'" :title="'Zarządzanie personelem'"/>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTile from "@/components/BaseTile.vue";

export default {
  name: "ElearningOthers",
  components: {
    BaseTile
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.elearning-others {
  padding: rem(50px 0);
}
</style>
