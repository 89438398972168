<template>
    <section class="workshop section">
        <div class="container small">
            <div class="column is-full">
                <h1 class="title is-1 has-text-weight-light has-text-left has-text-shadow">
                    {{ workshop.Title }}
                </h1>
            </div>
        </div>
        <div class="workshop-general-info">
            <div class="container small">
                <div class="workshop-general-info-box">
                    <div class="columns">
                        <div class="column">
                            <div class="single-row">
                                <p class="info-type has-text-weight-semibold is-size-7">Kategoria</p>
                                <p class="info-field">{{ workshop.Category }}</p>
                            </div>
                            <!--<div class="single-row">
                                      <p class="info-type has-text-weight-semibold is-size-7">Typ</p>
                                      <p class="info-field">Internetowe</p>
                        </div>-->
                            <div class="single-row">
                                <p class="info-type has-text-weight-semibold is-size-7">Miejsce</p>
                                <p class="info-field">
                                    {{ workshop.City }}, {{ workshop.Province }}
                                    <br />
                                    {{ workshop.Address }}
                                </p>
                            </div>
                            <div class="single-row">
                                <p class="info-type has-text-weight-semibold is-size-7">Termin</p>
                                <p class="info-field">{{ workshop.Date }}</p>
                            </div>
                            <div class="single-row">
                                <p class="info-type has-text-weight-semibold is-size-7">Pozostało miejsc</p>
                                <p class="info-field">{{ workshop.PersonsLimit - workshop.EntriesNumber }}</p>
                            </div>
                            <!--<div class="single-row">
                                      <p class="info-type has-text-weight-semibold is-size-7">Język</p>
                                      <p class="info-field">Polski</p>
                        </div>-->
                            <div class="single-row">
                                <p class="info-type has-text-weight-semibold is-size-7">Czas</p>
                                <p class="info-field">około {{ workshop.Duration }} godzin</p>
                            </div>
                        </div>
                        <div class="column workshop-buttons">
                            <div class="workshop-buttons-box">
                                <div v-if="!workshop.HasFreeEntries && !workshop.IsMemberSubscibed">
                                    <p>
                                        <NoSpace />Brak wolnych miejsc
                                    </p>
                                    <a href="/home/warsztaty/"
                                       class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                        <span class="icon is-small">
                                            <SmallCalendar />
                                        </span>
                                        <span>Sprawdź inny termin</span>
                                    </a>
                                </div>
                                <div v-if="workshop.IsTerminated">
                                    <p>
                                        <AlreadyIn />Warsztat po terminie
                                    </p>
                                </div>
                                <div v-if="!workshop.IsTerminated && workshop.IsMemberSubscibed && (workshop.ContentType == 1 || workshop.ContentType == 3|| workshop.ContentType == 2|| workshop.ContentType == 7)">
                                    <p>
                                        <AlreadyIn />Jesteś już zapisany na ten warsztat
                                    </p>
                                    <a @click.prevent="isResignationModalActive = true"
                                       href="#"
                                       class="resignation button is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                        <span class="icon is-small">
                                            <RemoveIcon />
                                        </span>
                                        <span>Zrezygnuj</span>
                                    </a>
                                </div>
                                <a @click.prevent="isInfoToAssignYourself = true"
                                   v-if="!workshop.IsTerminated && !workshop.IsMemberSubscibed && workshop.HasFreeEntries && (workshop.ContentType == 1 || workshop.ContentType == 3 || workshop.ContentType == 2 || workshop.ContentType ==7)"
                                   href="#"
                                   class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span class="icon is-small">
                                        <AddIcon />
                                    </span>
                                    <span>Zapisz się</span>
                                </a>
                                <a @click.prevent="isAddWorkerModalActive = true, $v.reset();"
                                   v-if="!workshop.IsTerminated && workshop.HasFreeEntries && (workshop.ContentType == 1 ||workshop.ContentType ==7)"
                                   href="#"
                                   class="button light is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span class="icon is-small">
                                        <AddFull />
                                    </span>
                                    <span>Zapisz pracownika</span>
                                </a>
                                <p v-if="workshop.ContentType == 0"
                                   class="contact-employer button light is-size-8 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span>W celu zapisania się na ten warsztat skontaktuj się ze swoim Kierownikiem Sklepu</span>
                                </p>
                                <a v-if="workshop.ContentType === 2"
                                   :href="'/warsztaty/szczegoly?w=' + workshop.Id + '&p=1521'"
                                   class="button light is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span class="icon is-small">
                                        <Person />
                                    </span>
                                    <span>Lista użytkowników</span>
                                </a>
                                <a v-if="workshop.ContentType === 2"
                                   :href="'/sekcja-trenerska/wiadomość-do-uczestników/?p=1521&amp;p2=' + workshop.Id"
                                   class="button light is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span class="icon is-small">
                                        <Message />
                                    </span>
                                    <span>Wiadomość do uczestników</span>
                                </a>
                                <a v-if="workshop.ContentType === 2"
                                   :href="'/warsztaty/szczegoly?w=' + workshop.Id + '&p=1521'"
                                   class="button light is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span>Wypełnij ankietę</span>
                                </a>
                                <a v-if="workshop.ContentType === 2"
                                   :href="'/sekcja-trenerska/ewaluacja/?p=1521&amp;p2=' + workshop.Id"
                                   class="button light is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span>Ewaluacja</span>
                                </a>
                                <a v-if="workshop.ContentType === 2"
                                   :href="'/umbraco/api/workshop/setworkshopfinished/' + workshop.Id"
                                   class="resignation button is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                    <span>Zamknij szkolenie</span>
                                </a>
                            </div>

                            <div class="workshop-rating">
                                <div>
                                    <div class="workshop-stars">
                                        <star-rating :star-size="14"
                                                     :padding="3"
                                                     :rating="workshop.Rate"
                                                     :read-only="true"
                                                     :increment="0.01"
                                                     :show-rating="false"
                                                     :active-color="'#f8d61c'"
                                                     :inactive-color="'silver'"
                                                     inline></star-rating>
                                        <p class="votes-number">{{ workshop.QuestionnaireCount }} głosów</p>
                                    </div>
                                    <p class="rating-number">
                                        <span class="is-size-9 has-text-weight-light has-text-black-hover">
                                            {{ workshop.Rate }}
                                            <span class="has-text-grey-light-hover">/5</span>
                                        </span>
                                    </p>
                                </div>
                                <!--<a href="#" @click.prevent="rateWorkshop = true" class="rate-training has-text-weight-semibold">Oceń szkolenie <span>></span></a>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="workshop-bck">
                    <img v-bind:src="workshop.Image" :alt="workshop.Title" :title="workshop.Title" />
                </div>
            </div>
        </div>
        <div class="workshop-details">
            <div class="container small">
                <h2 class="workshop-details-header">Opis warsztatu</h2>
                <p class="workshop-details-intro" v-html="workshop.Description"></p>
                <div class="workshop-details-benefits">
                    <h2 class="workshop-details-header">Cele i korzyści</h2>
                    <div v-html="workshop.PurposeAndBenefits"></div>
                </div>
                <div class="workshop-practical-info" v-if="workshop.PracticalInformation">
                    <h2 class="workshop-details-header">Informacje praktyczne</h2>
                    <div v-html="workshop.PracticalInformation"></div>
                </div>
                <div class="workshop-details-lecturer">
                    <h2 class="workshop-details-header">Prowadzący warsztat</h2>
                    <div class="card">
                        <div>
                            <img src="/assets/2018/svg/workshop_trainer.svg" />
                            {{ workshop.TrainerFirstName }} {{ workshop.TrainerLastName }}
                        </div>
                        <a :href="`mailto:${ workshop.TrainerEmail }`">{{ workshop.TrainerEmail }}</a>
                    </div>
                </div>
                <div class="workshop-details-scope">
                    <h2 class="workshop-details-header">Program warsztatu</h2>
                    <div v-html="workshop.SubstantialScope"></div>
                </div>
                <div class="workshop-details-reviews"
                     v-if="workshop.Opinions && workshop.Opinions.length > 0">
                    <h2 class="workshop-details-header">Opinie innych uczestników warsztatu:</h2>
                    <carousel :scrollPerPage="true"
                              :navigationEnabled="true"
                              paginationActiveColor="#3AAB59"
                              :paginationSize="40"
                              paginationColor="#dbdbdb"
                              navigationPrevLabel="<svg xmlns='http://www.w3.org/2000/svg' width='15' height='44' viewBox='0 0 15 44'> <path fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.8' d='M12.917 1.375L2.083 22l10.834 20.625'/></svg>"
                              navigationNextLabel="<svg xmlns='http://www.w3.org/2000/svg' width='15' height='44' viewBox='0 0 15 44'> <path fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.8' d='M2.083 1.375L12.917 22 2.083 42.625'/> </svg> "
                              :perPageCustom="[[0, 1]]">
                        <slide v-for="(opinion, index) in workshop.Opinions" :key="index">
                            <figure class="image is-flex">
                                <img v-bind:src="opinion.Image" :alt="opinion.Author" :title="opinion.Author" />
                            </figure>
                            <div class="has-text-weight-light has-text-centered">
                                <p>{{ opinion.Text }}</p>
                            </div>
                            <p class="is-size-7 has-text-centered has-text-grey">{{ opinion.Author }}</p>
                        </slide>
                    </carousel>
                </div>
                <div class="workshop-details-for" v-if="workshop.ForWhom && workshop.ForWhom.length > 0">
                    <h2 class="workshop-details-header">Dla kogo prowadzone jest to szkolenie?</h2>
                    <div class="columns">
                        <div class="column" v-for="(who, index) in workshop.ForWhom" :key="index">
                            <div class="card" v-if="who === 'Właściciel Sklepu'">
                                <img src="/assets/2018/svg/employee-woman.svg" />
                                Właścicieli sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Kierownik Sklepu'">
                                <img src="/assets/2018/svg/employee-man.svg" />
                                Kierowników sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Pracownik Sklepu'">
                                <img src="/assets/2018/svg/employee-young-woman.svg" />
                                Pracowników sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Operacja'">
                                <img src="/assets/2018/svg/employee-woman.svg" />
                                Operacja
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="workshop-others"
             v-if="workshop.NearByWorkshops && workshop.NearByWorkshops.length > 0">
            <div class="container small">
                <h2 class="workshop-details-header">Inne warsztaty w Twojej okolicy</h2>
                <div v-for="workshopOther in workshop.NearByWorkshops" :key="workshopOther.Id">
                    <BaseCardWorkshop @goToUrlHandler="goToUrlHandler('/warsztaty/' + workshopOther.Id)"
                                      :workShop="workshopOther" />
                </div>
            </div>
        </div>
        <b-modal :active.sync="rateWorkshop"
                 :width="820"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog">
            <button type="button" class="modal-close is-large" @click="rateWorkshop = false"></button>
            <p class="has-text-centered has-text-weight-light">Oceń wiedzę przekazywaną podczas szkolenia czy będzie dla Ciebie użyteczna w wykonywaniu codziennych obowiązków ?</p>
            <div class="rate-box">
                <star-rating :star-size="50"
                             :padding="3"
                             :increment="0.01"
                             :show-rating="false"
                             :active-color="'#f8d61c'"
                             :inactive-color="'silver'"
                             inline></star-rating>
            </div>
        </b-modal>
        <b-modal :active.sync="isSignYourselfModalActive"
                 :width="820"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog">
            <button type="button" class="modal-close is-large" @click="isSignYourselfModalActive = false"></button>
            <p class="has-text-centered has-text-weight-light">Czy chcesz zapisać się na kurs "{{ workshop.Title}}", w dniu{{ workshop.Date }}w{{ workshop.City }}?</p>

            <div class="sign_yourself">
                <a @click="isSignYourselfModalActive = false"
                   class="resign button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold">Rezygnuj</a>
                <a @click="signToWorkshop"
                   class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold">Zapisz się</a>
            </div>
        </b-modal>
        <b-modal :active.sync="isResignationModalActive"
                 :width="820"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog">
            <button type="button" class="modal-close is-large" @click="isResignationModalActive = false"></button>
            <p class="has-text-centered has-text-weight-light">Czy na pewno chcesz zrezygnować z warsztatu "{{ workshop.Title}}" ?</p>
            <div class="resignation">
                <a @click="resigneFromWorkshop"
                   class="resign button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold">Rezygnuje</a>
            </div>
        </b-modal>

        <b-modal :active.sync="workshop.IsWorkshopPopup"
                 :width="870"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog">
            <button type="button" class="modal-close is-large" @click="workshop.IsWorkshopPopup = false"></button>
			<p class="has-text-centered" style="font-size: 22px">
				<b>Koszt całodziennego warsztatu w Twoim sklepie wynosi 250 zł netto.</b><br />
			</p>
			<p class="has-text-centered has-text-weight-light">
				Godziny  trwania  warsztatu  z  trenerem  w  sklepie  są  orientacyjne.  Trener  prowadzi  szkolenie  na pierwszej  lub  drugiej  zmianie  w  pełnym reżimie  sanitarnym.  Ustalenie  zmiany,  na  której  trener prowadzi warsztat będzie ustalone każdorazowo telefonicznie/mailowo z właścicielem/kierownikiem sklepu.
			</p>
       
        </b-modal>


        <b-modal :active.sync="isAddWorkerModalActive"
                 :width="880"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog add-other-worker">
            <button type="button" class="modal-close is-large" @click="isAddWorkerModalActive = false"></button>
            <p class="has-text-centered has-text-weight-light">Wybierz pracownika którego chciałbyś zapisać na szkolenie</p>
            <div class="add_worker_box">
                <div>
                    <b-select placeholder="MIASTO" @input="onCityChange" v-model="currentCity">
                        <option v-for="city in cities" :value="city.Value" :key="city.Key">{{ city.Value }}</option>
                    </b-select>

                    <b-select placeholder="SKLEP" @input="onShopChange" v-model="currentShop">
                        <option v-for="shop in shops" :value="shop.Key" :key="shop.Key">{{ shop.Value }}</option>
                    </b-select>

                    <b-select placeholder="PRACOWNIK" @input="onWorkerChange" v-model="currentWorker">
                        <option v-for="worker in workers"
                                :value="worker.Key"
                                :key="worker.Key">
                            {{ worker.Value }}
                        </option>
                    </b-select>
                </div>
                <a @click="saveWorker"
                   class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
                   v-bind:class="{ isDisabled: !readySave }">Dodaj pracownika</a>
                <div class="success_error column is-full" v-if="errorMsg || successMsg">
                    <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-danger has-text-centered"
                       v-if="errorMsg">{{ errorMsg }}</p>
                    <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-primary has-text-centered"
                       v-if="successMsg">{{ successMsg }}</p>
                </div>
            </div>
        </b-modal>

        <b-modal :active.sync="isInfoToAssignYourself"
                 :width="820"
                 :canCancel="['escape', 'x', 'outside']"
                 scroll="keep"
                 class="modal-dialog">
            <button type="button" class="modal-close is-large" @click="isInfoToAssignYourself = false"></button>
            <p class="has-text-centered has-text-weight-light">
                Administratorem Pani/Pana danych osobowych jest Akademia Umiejętności Eurocash sp. z o.o. z siedzibą w Komornikach (62-052), ulica Wiśniowa 11. Pani/Pana dane będą przetwarzane w celu zapewnienia Pani/Pana uczestnictwa w warsztatach. Więcej informacji na temat przetwarzania danych osobowych, w tym o przysługujących Pani/Panu prawach znajduje się w Polityce Prywatności: <a href="../polityka-prywatnosci">https://akademiaeurocash.com.pl/polityka-prywatnosci</a>
            </p>

            <div class="sign_yourself">
                <a @click="isInfoToAssignYourself = false ; isSignYourselfModalActive = true"
                   class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold">Zrozumiałem/-łam</a>
            </div>
        </b-modal>

    </section>
</template>

<script>
import SmallCalendar from '@/assets/svg/small-calendar.svg';
import RemoveIcon from '@/assets/svg/circle-remove.svg';
import AddIcon from '@/assets/svg/circle-add.svg';
import AddFull from '@/assets/svg/add-full.svg';
import NoSpace from '@/assets/svg/occupied.svg';
import AlreadyIn from '@/assets/svg/already-in.svg';
import Message from '@/assets/svg/message.svg';
import Person from '@/assets/svg/buttons/person.svg';
import StarRating from 'vue-star-rating';
import CalendarSmallIcon from '@/assets/svg/small-calendar.svg';
import BaseCardWorkshop from '@/components/BaseCardWorkshop.vue';
import global from '@/mixins/global.js';
//import { Carousel, Slide } from "vue-carousel";

export default {
	name: 'Workshop',
	mixins: [global],
	components: {
		SmallCalendar,
		RemoveIcon,
		AddIcon,
		AddFull,
		NoSpace,
		AlreadyIn,
		Message,
		Person,
		StarRating,
		CalendarSmallIcon,
		BaseCardWorkshop,
	},
	activated: function() {
		let self = this;
		self.resetData();
		self.$http
			.get(`/spa/workshop/getdetails/` + self.$route.params.id)
			.then(response => {
				self.workshop = response.data;
				self.$set(this.$store.state, 'breadcrumb', self.workshop.Title);
			})
			.catch(() => {});
	},
	data() {
		return {
			workshop: {},
            isResignationModalActive: false,
            isInfoToAssignYourself: false,
			isSignYourselfModalActive: false,
			isAddWorkerModalActive: false,
			rateWorkshop: false,
			cities: [],
			shops: [],
			workers: [],
			currentCity: null,
			currentShop: null,
			currentWorker: null,
			readySave: false,
			errorMsg: null,
            successMsg: null,
           
		};
	},
	methods: {
		resetData() {
			let self = this;
			self.workshop = {};
		},
		signToWorkshop() {
			let self = this;
			this.$http
				.post(
					`spa/workshop/AsignSelfToWorkshop?id=` + self.workshop.Id.toString()
				)
				.then(() => {
					self.isSignYourselfModalActive = false;
					self.workshop.IsMemberSubscibed = true;
					self.workshop.EntriesNumber = self.workshop.EntriesNumber + 1;
					self.workshop.IsMemberSubscibed = true;
				})
				.catch(() => {
					window.alert('błąd');
				});
        },

		resigneFromWorkshop() {
			let self = this;
			this.$http
				.post(
					`/umbraco/api/workshop/singoff`,
					'WorkshopId=' + self.workshop.Id.toString()
				)
				.then(() => {
					self.isResignationModalActive = false;
					self.workshop.IsMemberSubscibed = false;
					self.workshop.EntriesNumber = self.workshop.EntriesNumber - 1;
					self.workshop.IsMemberSubscibed = false;
				})
				.catch(() => {
					window.alert('błąd');
				});
		},
		onCityChange() {
			this.shops = [];
			this.readySave = false;
			let self = this;
			self.$http
				.get(`/spa/workshop/getcityshops/` + self.currentCity)
				.then(response => {
					self.shops = response.data;
				})
				.catch(() => {
					window.alert('błąd');
				});
		},
		onShopChange() {
			this.workers = [];
			this.readySave = false;
			let self = this;
			self.$http
				.get(`/spa/workshop/getshopworkers/` + self.currentShop)
				.then(response => {
					self.workers = response.data;
				})
				.catch(() => {
					window.alert('błąd');
				});
		},
		onWorkerChange() {
			this.readySave = true;
		},
		saveWorker() {
			let self = this;
			let formData = {
				WorkshopId: self.workshop.Id,
				MemberId: self.currentWorker,
			};
			this.$http
				.post(`/umbraco/api/workshop/addmember`, formData)
				.then(response => {
					self.workshop.EntriesNumber = self.workshop.EntriesNumber + 1;
					self.errorMsg = '';
					self.successMsg = response.data.Message;
					setTimeout(() => {
						self.isEdit = false;
						self.successMsg = '';
						self.isAddWorkerModalActive = false;
					}, 5000);
				})
				.catch(error => {
					// self.loading = "";
					self.successMsg = '';
					self.errorMsg = error.response.data.Message;
					setTimeout(() => {
						self.isEdit = false;
						self.errorMsg = '';
					}, 5000);
				});
		},
	},
	//computed: {
	//    dateFormat() {
	//        if (this.workshop.Date) {
	//            let dateArr = this.workshop.Date.split(" ");
	//            return dateArr[2] + '.' + dateArr[1] + '.' + dateArr[0] + ' godz.' + dateArr[4];
	//        }
	//    }
	//},
	watch: {
		isAddWorkerModalActive: function(newVal) {
			if (newVal === true) {
				let self = this;

				self.currentCity = null;
				self.currentShop = null;
				self.currentWorker = null;
				self.$http
					.get(`/spa/workshop/getcities`)
					.then(response => {
						self.cities = response.data;
					})
					.catch(() => {
						window.alert('błąd');
					});
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.workshop {
	padding: 1.5625rem 0;
	padding-bottom: 0;
	color: $black;
	.container.small {
		@include tablet {
			width: 729px;
		}

		.isDisabled {
			color: currentColor;
			display: inline-block; /* For IE11/ MS Edge bug */
			pointer-events: none;
			text-decoration: none;
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	.workshop-details-header {
		text-align: center;
		font-size: 1.5rem;
		color: $black;
		margin: 2.5rem 0;
	}

	.workshop-general-info-box {
		background-color: $white;
		padding: 1.875rem;
		border-radius: 4px;

		.single-row {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin: 0 -10px 10px;
			line-height: 36px;
			position: relative;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;

			&:last-child {
				margin-bottom: 0;
			}

			&::after {
				content: '';
				position: absolute;
				left: 10px;
				top: 27px;
				border-bottom: 2px dotted #dfe0e0;
				width: 94%;
			}

			.info-type {
				padding: 0 10px;
			}

			.info-field {
				text-align: right;
				padding: 0 10px;
				color: $green;
				font-weight: 300;
				font-size: 1.125rem;
			}
		}

		.workshop-buttons {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;

			@include tablet {
				width: 316px;
				margin-left: 40px;
			}

			a:hover {
				opacity: 0.7;
			}

			.button {
				height: 50px;
				margin-bottom: 1.25rem;
				border: 2px solid $green-lighter;
				&.light {
					color: $green-lighter;
				}
			}

			.workshop-buttons-box {
				-webkit-box-ordinal-group: 3;
				-ms-flex-order: 2;
				order: 2;

				@include tablet {
					-webkit-box-ordinal-group: 2;
					-ms-flex-order: 1;
					order: 1;
				}

				.resignation {
					background-color: $red;
					border-color: $red;
					color: $white;
				}

				p {
					margin-bottom: 5px;

					svg {
						vertical-align: middle;
						margin-right: 5px;
					}
				}

				.contact-employer {
					height: auto;
					min-height: 50px;
					white-space: normal;
				}
			}

			.workshop-rating {
				text-align: right;
				-webkit-box-ordinal-group: 2;
				-ms-flex-order: 1;
				order: 1;
				margin-bottom: 1.25rem;

				@include tablet {
					-webkit-box-ordinal-group: 3;
					-ms-flex-order: 2;
					order: 2;
					margin-bottom: 0;
				}

				> div {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: end;
					-ms-flex-pack: end;
					justify-content: flex-end;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;

					.workshop-stars {
						margin-right: -13px;

						.votes-number {
							margin-right: 13px;
							font-weight: 700;
							text-transform: uppercase;
							color: #c0c0c0;
							letter-spacing: 0.83px;
							font-size: 0.625rem;
						}
					}

					.rating-number {
						margin-left: 10px;
					}
				}

				.rate-training {
					color: $green;
					&:hover {
						opacity: 0.7;
					}
					span {
						font-size: 0.75rem;
					}
				}
			}
		}
	}

	.workshop-bck {
		margin: 3.125rem 0;
		img {
			width: 100%;
			height: auto;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.workshop-details {
		padding: 2.5rem 1.25rem;
		background-color: $white;

		.workshop-details-intro {
			font-weight: 300;
			line-height: 1.875rem;
			margin-bottom: 3.125rem;
		}

		.workshop-details-lecturer {
			padding-bottom: 3.125rem;

			.card {
				overflow: hidden;

				@include tablet {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-box-align: center;
					-ms-flex-align: center;
					align-items: center;
				}

				div {
					text-align: center;
					background-color: $green-lighter;
					color: $white;
					padding: 1.5rem 1rem;
					font-size: 1.5rem;
					width: 100%;

					@include tablet {
						max-width: 33%;
						-ms-flex: 0 0 33%;
						flex: 0 0 33%;
					}

					img {
						margin-right: 10px;
						position: relative;
						top: 1px;
					}
				}

				a {
					padding: 1.5rem 1rem;
					color: $black;
					font-size: 1.125rem;
					display: block;
					text-align: center;

					@include tablet {
						padding: 0 1.875rem;
						-webkit-box-flex: 1;
						-ms-flex-positive: 1;
						flex-grow: 1;
					}

					&:hover {
						color: $green-lighter;
					}
				}
			}
		}

		.workshop-details-benefits {
			padding-bottom: 1.875rem;

			div {
				font-weight: 300;
				line-height: 1.875rem;

				ul {
					padding-top: 10px;
					font-weight: 300;
					line-height: 1.875rem;

					li {
						position: relative;
						padding-left: 30px;
						margin-bottom: 1.25rem;

						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: 6px;
							width: 18px;
							height: 18px;
							background-image: url('/assets/2018/svg/plus.svg');
							background-size: 18px 18px;
						}
					}
				}
			}
		}

		.workshop-practical-info {
			padding-bottom: 1.875rem;

			div {
				font-weight: 300;
				line-height: 1.875rem;
			}
		}

		.workshop-details-for {
			margin-bottom: 3.125rem;
			.card {
				font-size: 1.125rem;
				font-weight: 400;
				text-align: center;
				color: $black;
				padding-top: 1.875rem;
				padding-bottom: 1.5rem;
				padding: 1.875rem 1rem 1.5rem;

				img {
					display: block;
					margin: 0 auto 0.75rem;
					border-radius: 0;
				}
			}
		}

		.workshop-details-reviews {
			.image {
				width: 104px;
				height: 104px;
				background-color: #ececec;
				box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.11);
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				margin: 0 auto;
				img {
					width: 86px;
					height: 86px;
					border-radius: 50%;
				}
			}
		}

		.card {
			-webkit-box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
			box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
			border: 1px solid #f2f2f2;
		}
	}

	.workshop-others {
		padding: 0 1.25rem 1.25rem;
		article.card {
			margin-bottom: 1.875rem;
		}
	}

	.modal-dialog {
		color: $black-real;

		p {
			font-size: 1.25rem;
			margin-bottom: 2rem;

			@include tablet {
				font-size: 1.5rem;
			}
		}

		.sign_yourself {
			text-align: center;
			margin: 0 auto;

			@include tablet {
				width: 520px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
			}

			a {
				width: 100%;
				display: block;
				padding: 15px;
				border-radius: 4px;
				border: 2px solid $green-lighter;
				background-color: $green-lighter;
				color: $white;
				margin: 0 auto 20px;
				-webkit-transition: 0.3s all ease-in-out;
				-o-transition: 0.3s all ease-in-out;
				transition: 0.3s all ease-in-out;

				@include tablet {
					width: 240px;
					display: inline-block;
				}

				&:hover {
					color: $green-lighter;
					background-color: $white;
				}

				&.resign {
					border: 2px solid $red;
					background-color: transparent;
					color: $red;
					&:hover {
						background-color: $red;
						color: $white;
					}
				}
			}
		}

		.resignation {
			text-align: center;
			.resign {
				width: 100%;
				display: block;
				padding: 15px;
				border-radius: 4px;
				border: 2px solid $red;
				background-color: $red;
				color: $white;
				margin: 0 auto 20px;
				-webkit-transition: 0.3s all ease-in-out;
				-o-transition: 0.3s all ease-in-out;
				transition: 0.3s all ease-in-out;

				&:hover {
					background-color: $white;
					color: $red;
				}

				@include tablet {
					width: 240px;
					display: inline-block;
				}
			}
		}

		.add_worker_box {
			text-align: center;

			> div {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				margin-bottom: 20px;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;

				@include tablet {
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-ms-flex-direction: row;
					flex-direction: row;
				}
			}

			> a {
				width: 100%;
				max-width: 100%;
				display: block;
				padding: 15px;
				border-radius: 4px;
				background-color: $green-lighter;
				border: 2px solid $green-lighter;
				color: $white;
				margin: 0 15px 20px;
				-webkit-transition: 0.3s all ease-in-out;
				-o-transition: 0.3s all ease-in-out;
				transition: 0.3s all ease-in-out;

				&:hover {
					background-color: $white;
					color: $green-lighter;
				}

				@include tablet {
					width: 240px;
					display: inline-block;
				}
			}

			> .success_error {
				display: block;
				text-align: center;
				p {
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>
