<template>
  <main>
    <Aue/>
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import Aue from "@/components/Containers/ViewAueTvArticle/AueTvArticle.vue";

export default {
  name: "ViewAueTv",
  components: {
    BaseHero,
    Aue
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
