<template>
    <article class="card" @click="isOpen = !isOpen">
        <div class="columns-wrap">
            <div class="workshop-all card-content">
                <div @click="$emit('goToUrlHandler')">
                    <div class="title_rating">
                        <h2 class="is-size-3 is-size-2-tablet">{{ title }}</h2>

                        <p class="is-size-8 has-text-left has-text-grey-light-hover">OCENA: <span class="is-size-6 has-text-left has-text-black-hover"> {{ rate }}<span class="has-text-grey-light-hover">/5</span></span><star-rating :star-size="14" :padding="3" :rating="rate" :read-only="true" :increment="0.01" :show-rating="false" :active-color="'#f8d61c'" :inactive-color="'silver'" inline></star-rating></p>
                    </div>
                    <div class="description">
                        <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc" v-text="isOpen ? description : descriptionLimited"></p>
                    </div>
                    <div class="bottom-info">
                        <button class="show-more" v-text="isOpen ? 'Zwiń' : 'Przeczytaj więcej'"></button>
                    </div>
                </div>
                
                <b-collapse :open.sync="isOpen">
                    <div @click="$emit('goToUrlHandler')" class="hidden-content">
                        <p class="for-whom is-size-7">Przeznaczony dla: <span>pracowników</span>, <span>kierowników</span></p>
                        <div class="bck-learn">
                            <img v-lazy="image" :alt="title" :title="title">
                            <div class="what-learn is-size-7 has-text-weight-light" v-html="benefits"></div>
                        </div>
                        <div class="text-center">
                            <!--potrzebuję aby przeszło pod adres /warsztaty-tematy/:id gdzie id To id tematu.... SubjectId -->
                            <!--goToUrlHandler('$emit('goToUrlHandler')/warsztaty/' + workshop.Id)-->
                            <!--<a href="#" v-on:click.stop="$emit('goToUrlHandler', '/warsztaty-tematy/'+subjectId)" class="see-other is-size-7">Sprawdź dostępne terminy i lokalizacje</a>-->
                            <!--v-on:click.stop="$emit('navigate')"-->
                            <router-link class="see-other is-size-7" :to="url">Sprawdź dostępne terminy i lokalizacje</router-link>
                            
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </article>
   
</template>

<script>
    import StarRating from "vue-star-rating";
    import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";

export default {
        name: "BaseCardMovieAll",
        mixins: [global],
        components: {
        StarRating,
        RightArrowSmallIcon,
        },
        data() {
            return {
                isOpen: false
            }
        },
    props: {
        image: {
            type: String
        },
        category: {
            type: String
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        rate: {
            type: Number
        },
        date: {
            type: String
        },
        city: {
            type: String
        },
        entries: {
            type: Boolean
        },
        benefits: {
            type: String,
        },
        subjectId: {
            type: Number
        },

         url: {
            type: String
        }
    },
    computed: {
        descriptionLimited() {
            if (this.description.length > 180) {
                return this.description.substring(0, 178) + '...'
            } else {
                return this.description
            }
        },
        parseDate() {
            let parseDate = new Date(this.date).toDateString('dd.mm.yyyy');
            return parseDate;
        },
    },
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

    .workshop-all {
        padding: 1.25rem;

        @include tablet {
            padding-top: 1.875rem;
            padding-right: 1.875rem;
        }

        .title_rating { 
            margin-bottom: 1.125rem;

            @include tablet {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
            }

            h2 {
                margin-botto: 20px;
                @include tablet {
                    margin-right: 30px;
                }
            }
        }

        .description {
            margin-bottom: 1rem;
        }

        .bottom-info {
            text-align: right;
        }

        .hidden-content {
            .for-whom {
                margin-bottom: 1.25rem;
                span {
                    font-weight: 700;
                }
            }
            .bck-learn {
                display: block;

                @include tablet {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                }

                > img {
                    width: 100%;
                    height: 250px;
                    margin-right: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    border-radius: 0;
                    margin-bottom: 20px;

                    @include tablet {
                        width: 162px;
                        height: 162px;
                        margin-right: 20px;
                        margin-bottom: 0;
                    }
                }


            }
            .text-center {
                text-align: center;
                .see-other {
                    text-transform: uppercase;
                    font-weight: 700;
                    border: 2px solid $green-lighter;
                    padding: .9375rem 1rem;
                    display: inline-block;
                    margin-top: 2.2rem;
                    -webkit-transition: background 0.3s ease;
                    -o-transition: background 0.3s ease;
                    transition: background 0.3s ease;
                 }
            }
        }
    }

    .card {
        height: 100%;
        flex-wrap: wrap;
        overflow: hidden;
        cursor: pointer;
        color: $black;
        &.bottom-image {
    .card-image {
      order: 2;
      align-self: flex-end;
      img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .card-content {
      order: 1;
      img {
        border-radius: 0;
        max-height: 40px;
      }
    }
  }
  .is-3by1 {
    padding-top: 39.3333%;
  }
  &-image,
  &-content {
    width: 100%;
    .media-content {
      & > div {
        padding-right: 70%;
      }
    }
  }
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
      ul {
          color: $white;
      }
      svg {
          color: $white;
      }
      .alert {
          color: $red;
      }
      strong {
          color: $white;
      }
    }
    .workshop-all .hidden-content .text-center {
        .see-other {
            border-color: $white;
            color: $white;
        }
    }
  }
}
</style>
