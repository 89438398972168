<template>
    <b-modal :active.sync="isResignModalActive" :width="830" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-dialog">
      <button type="button" class="modal-close is-large" @click="$emit('hideModalHandler')"></button>
      <div class="columns">
        <div class="column is-full">
          <p class="is-size-2 has-text-centered has-text-weight-light">Czy na pewno chcesz zrezygnować z warszatatu?</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6 has-text-right-desktop">
          <a class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button" @click="$emit('submitResignFormHandler')"><span>TAK</span></a>
        </div>
        <div class="column is-6 has-text-left-desktop">  
          <a class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold" @click="$emit('hideModalHandler')"><span>NIE</span></a>
        </div>
        <div class="column is-full" v-if="successMsg">
          <p class="is-size-8 has-text-weight-light has-text-primary has-text-centered">{{ successMsg }}</p>
        </div>
      </div>
    </b-modal>
</template>


<script>
import workshops from "@/mixins/workshops.js";

export default {
  name: "HomePageResignModal",
  mixins: [workshops],
  props: {
    isResignModalActive: {
      type: Boolean,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    workshopId: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.modal {
  .columns {
    flex-wrap: wrap;
  }
  .button {
    color: rgba(58, 171, 89, 0.5);
    border-radius: 5px;
    height: 50px;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }
    span {
      color: white;
    }
    .icon {
      margin-right: 5px;
    }
    &.is-info.is-outlined {
      color: rgba(58, 171, 89, 0.5);
      &:hover,
      &:focus {
        span {
          color: $white;
        }
      }
      span {
        color: $green-lighter;
      }
    }
  }
}
</style>

