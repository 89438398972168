<template>
    <main>
        <BaseHero :title="'Ekspert radzi'" />
        <PartnerAdvises :showFilters.sync="showFilters" />
    </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import PartnerAdvises from "@/components/Containers/ViewPartnerAdvises/PartnerAdvises.vue";

export default {
  name: "ViewPartnerAdvises",
  components: {
    BaseHero,
    PartnerAdvises
  },
  data() {
    return {
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
