<template>
  <section class="workshops-user">
    <div class="container">
        <div class="columns">
            <div class="column is-one-quarter">
                <div class="has-text-left">
                    <BookIcon />
                    <h2 class="is-size-1 has-text-weight-light has-text-white">Twoje warsztaty, na&nbsp;które jesteś zapisany</h2>
                </div>
            </div>
            <div class="column">
                <div class="card" v-for="workshop in userData.Workshops" :key="workshop.Id">
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <a class="user-workshop" href="#" @click="goToUrlHandler('/warsztaty/' + workshop.Id)">
                          <h2 class="is-size-4-tablet has-text-left has-text-weight-light has-text-black">{{ workshop.Title }}</h2>
                          <div class="columns">
                            <div class="column is-four-fifths is-flex">
                              <CalendarSmallIcon width="18" height="20" viewBox="0 -6 23 24"/><time datetime="31-10-2018" class="has-text-primary has-text-left has-text-weight-semibold">{{ workshop.Date }}</time>
                              <PinIcon width="30" height="20" viewBox="0 -6 2 24"/><span class="has-text-primary has-text-left has-text-weight-semibold">{{ workshop.City }}</span>
                            </div>
                            <div class="column" v-if="userData.IsAllowToResignFromWorkshop">
                              <p href="#" class="is-size-8 has-text-weight-semibold is-uppercase has-text-grey-light has-text-right" @goToUrlHandler="goToUrlHandler('/warsztaty/' + workshop.Id)"> <!--@click.prevent="isResignModalActive = true, workshopId = workshop.Id"-->REZYGNUJ<button class="delete is-small"></button></p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
              </div>  
              <p class="is-size-4-tablet has-text-left has-text-weight-light" v-if="userData.Workshops" v-show="userData.Workshops.length === 0">Nie jesteś zapisany na żaden warsztat.</p>
            </div>
        </div>
    </div>
    <HomePageResignModal :workshopId="workshopId" :successMsg="successMsg" :isResignModalActive="isResignModalActive" @hideModalHandler="isResignModalActive = false" @submitResignFormHandler="submitResignFormHandler()"/>
  </section>
</template>

<script>
import HomePageResignModal from "@/components/Containers/ViewHomePage/HomePageResignModal.vue";
import BookIcon from "@/assets/svg/book.svg";
import CalendarSmallIcon from "@/assets/svg/small-calendar.svg";
import PinIcon from "@/assets/svg/pin.svg";
import workshops from "@/mixins/workshops.js";
import global from "@/mixins/global.js";

    export default {
        name: "WorkshopsUser",
        mixins: [global, workshops],
        components: {
            HomePageResignModal,
            BookIcon,
            CalendarSmallIcon,
            PinIcon
        },

        activated: function () {
            this.loadWorkshopsHandler();
        },
        data() {
            return {
                userData: "",
                isResignModalActive: false,
                workshopId: 0,
                successMsg: "",
            };
        },
        created: function () {
        }
    };
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.workshops-user {
  margin-top: 1.875rem;

  .media-content {
    .columns {
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
  }

  & > div {
    & > .columns {
      padding: rem(10px);
      & > :first-child {
        background: $green-lighter;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: rem(30px);
        svg {
          opacity: 0.2;
          margin-bottom: rem(20px);
        }
      }
      & > :nth-child(2) {
        padding: rem(30px);
        background: $white;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  .card {
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
    border: solid 1px #f2f2f2;
    background-color: $white;
    &-content {
      padding: rem(30px 30px 30px 40px);
      h2 {
        margin-bottom: rem(15px);
      }
      a {
        color: $green;
      }
    }
  }
  .card:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
