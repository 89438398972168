<template>
	<div
		class="NameBox__data-field"
		:class="{ 'span-2-rows': value && value.length > 40, 'is-grayed-out': isGrayedOut && !isEditable, 'is-clickable': isClickable }"
	>
		<dt>{{ name }}</dt>
		<dd :contenteditable="isEditable" ref="dd" :data-field-id="id">{{ value }}</dd>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},

		id: {
			type: String,
			required: true,
		},

		value: {
			type: [String, Number],
			required: true,
		},

		isEditable: {
			type: Boolean,
			required: false,
			default: false,
		},

		isGrayedOut: {
			type: Boolean,
			required: false,
			default: false,
		},

		isClickable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	methods: {
		refresh() {
			this.$refs.dd.innerText = this.value;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/_variables.scss';

.NameBox__data-field {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;

	&.span-2-rows {
		grid-row: span 2;
	}

	&.is-grayed-out dd {
		color: $grey-light;
	}

	// Prevent the dots' overflow.
	overflow: hidden;

	dt,
	dd {
		font-size: 15px;
		letter-spacing: 0;
		background-color: white;
		z-index: 2;
		border: 2px solid transparent;
	}

	dt {
		text-align: left;
		font-weight: $weight-semibold;
		color: $black-real;
		position: relative;
		white-space: nowrap;

		// Render the spacing dots.
		&::after {
			content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
			position: absolute;
			bottom: 4px;
			left: calc(100% + 10px);
			color: #979797;
			white-space: nowrap;
		}
	}

	dd {
		text-align: right;
		font-weight: $weight-light;
		color: $green;
		padding-left: 10px;
		transition: all 200ms ease;

		&[contenteditable='true'] {
			padding-right: 10px;
			outline: none;
			border-radius: 4px;
			border-color: $green-lighter;
		}
	}
}
</style>