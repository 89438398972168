<template>
  <section class="homepage-conferences section">
        <div class="container">
            <h2 class="title is-2 has-text-weight-light has-text-left">Najbliższa konferencja&emsp;<router-link class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" to="/konferencje">Zobacz wszystkie</router-link></h2>
            <div class="columns">
                <div class="column">
                  <!--<BaseCardBackgroundImage @goToUrlHandler="goToExternalUrlHandler('https://www.akademiaeurocash.com.pl/konferencje/')"/>-->
                  <a :href="rootUrl + 'home/konferencje'"><img v-bind:src="imageSrc" /></a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BaseCardBackgroundImage from "@/components/BaseCardBackgroundImage.vue";
import global from "@/mixins/global.js";

export default {
        name: "HomePageConferences",
        props: {
            imageSrc: String,
        },
    data: function () {
        return {
            rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/')
        };
    },
   mixins: [global],
  components: {
    BaseCardBackgroundImage
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.homepage-conferences {
  padding-bottom: rem(50px);
  .title {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    @include tablet {
      margin-left: rem(30px);
    }
  }
}

.conferencesHomeBanner {
    .container {
        > h2 { 
            display: none;
        }
    }
}
</style>