<template>
    <ul class="unordered-mobile" v-if="options.length>0">
        <li  v-bind:class="{'padding': level>0}" v-for="(filter, i) in options" :key="filter.Key">           
            <div class="is-flex">
                
                <a @click.prevent="filterClick({key:filter.Key, type : type}); roll(i);"
                   v-bind:class="{'active': filter.Key === currentlySelected}" >
                <span @click.prevent="filterClick({key:filter.Key, type : type}); roll(i);"
                      v-if="filter.Branches.length>0" v-html="getIcon(i)"></span>
                {{ filter.Value }}</a>
                <button @click.prevent="clearFilter(type)"
                        v-if="filter.Key === currentlySelected"
                        class="delete is-small"></button>
            </div>
            <FilterMenuMobile v-if="rolls[i].isUnrolled" 
                        :options="filter.Branches"
                        :level="level+1"
                        :type="type"
                        :currentlySelected="currentlySelected"
                        @filterClick="filterClick"
                        @clearFilter="clearFilter"
                        ></FilterMenuMobile>
        </li>
    </ul>
</template>

<script>

  
    export default {
        name: "FilterMenuMobile",
        mixins: [global],
        components: {

        },
        props: {
            options: Array,
            level: Number,
            currentlySelected: {
                type: [Number,String],
                required: false
            },
            type:String
        },
        
        beforeMount() {

            for (var i = 0; i < this.options.length; i++) {
                this.rolls[i] = {
                    isUnrolled: false,
                    treeIcon: '&#9654;'
                }
            }
        },
        mounted() { },
        data() {
            return {            
                 rolls: []
            };
        },
        methods: {
            roll(index) {
                this.rolls[index].isUnrolled = !this.rolls[index].isUnrolled

                if (this.rolls[index].isUnrolled) {
                    this.rolls[index].treeIcon = '&#9660;';
                }
                else {
                    this.rolls[index].treeIcon = '&#9654;';
                }
                this.$forceUpdate();
            },
            filterClick(key) {
                this.$emit('filterClick', key)
            },

            clearFilter(type) {
                this.$emit('clearFilter', type)
            },
            getIcon(i) {
                var res = this.rolls[i].treeIcon;
                return decodeURIComponent(res + '&nbsp');
            }
        },
    };
</script>

<style scoped lang="scss">
    .padding {
    padding-left:15px;
    }

   .filters .navbar-content ul.unordered-mobile {
        text-align: left;
    }

    li > div > span {
        align-self: center;
        cursor:pointer;
    }

    li>div {
        letter-spacing: 1px;
        &:hover {
        background-color: whitesmoke;
    }

    a {
        padding: 0.5em 0.75em;
        padding-right: 5px;
        padding-left: 0;
        width: 100%;
        color: #4a4a4a;
        font-size: 1rem
    }
        a.active {
            font-weight: bold;
        
        }

    .delete {
        /*display: none;*/
        align-self: center;
        float: right;
        display: block;     
        background-color: #bebebe;
    }
        .delete:before, .delete:after {
            background-color: white;
        }
   }


    </style>