import axios from 'axios';

const apiClient = axios.create({
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});

export default {
	getCurrentUserData() {
		return apiClient.get(`/spa/profile/my-data`);
	},

	getProfileData(userId) {
		return apiClient.get(`/spa/profile/member-data/${userId}`);
	},

	getScheduledWorkshops(userId) {
		return apiClient.get(`/spa/profile/planned-workshops/${userId}`);
	},

	getCompletedWorkshops(userId) {
		return apiClient.get(`/spa/profile/completed-workshops/${userId}`);
	},

	getCompletedElearnings(userId) {
		return apiClient.get(`/spa/profile/elearnings/${userId}`);
	},

    getUserImplementationProgramStats(userId) {
        return apiClient.get(`/spa/ImplementationPrograms/userstats/${userId}`);
    },

	getShops(userId) {
		return apiClient.get(`/spa/profile/shops/${userId}`);
	},

	getSubservientEmployees({ userId, userRole }) {
		switch (userRole) {
			case 'KOUser':
				return apiClient.get(`/spa/profile/ca-list/${userId}`);

			case 'RDOUser':
				return apiClient.get(`/spa/profile/ko-list/${userId}`);

			case 'default':
				return apiClient.get(`/spa/profile/shopworkers/${userId}`);

			default:
				break;
		}
	},

	getAgreements() {
		return apiClient.get(`/spa/profile/agreements`);
	},

	updateAgreements(updatedAgreements) {
		return apiClient.post(`/spa/profile/agreements`, updatedAgreements);
	},

	cancelWorkshopAttendance(userId, workshopId) {
		return apiClient.post(`/umbraco/api/workshop/deletemember`, {
			MemberId: userId,
			WorkshopId: workshopId,
		});
	},

	sendActivationLink(userId) {
		return apiClient.get(
			`/umbraco/api/adminpanels/resendactivationemail/${userId}`
		);
	},

	updateUserData(userId, updatedData) {
		return apiClient.post(`/spa/profile/member-data/${userId}`, updatedData);
	},

	changePassword(formData) {
		return apiClient.post(`/umbraco/api/user/changepassword`, formData, {
			responseType: 'json',
		});
	},

	deleteUser(userId) {
		return apiClient.post(`/spa/profile/delete`, { MemberId: userId });
	},

	createUser(userRole, formData) {
		switch (userRole) {
			case 'worker':
				return apiClient.post(`/spa/managment/shopworkers/add`, formData);

			case 'manager':
				return apiClient.post(`/spa/managment/workers/addnoshop`, formData);

			case 'owner':
				return apiClient.post(`/spa/managment/workers/onwers/add`, formData);

			default:
				break;
		}
	},
};
