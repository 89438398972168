<template>
  <section class="homepage-articles section">
        <div class="container">
            <div class="columns">
                <div class="column is-one-fifth">
                  <PaperIcon class="has-text-left is-block"/>
                  <h2 class="title is-2 has-text-weight-light has-text-left">Najnowsze artykuły w&nbsp;dziale ekspert&nbsp;radzi</h2>
                  <router-link to="/ekspert-radzi" class="is-size-8 has-text-weight-semibold has-text-left is-block has-text-primary has-arrow">Zobacz wszystkie</router-link>
                </div>
                <div class="column">
                    <div class="columns is-multiline">
                        <div class="column is-6" v-for="item in articles" :key="item.Id">
                          <BaseCard @goToUrlHandler="goToUrlHandler('/ekspert-radzi/' + item.Id)" :categoryName="item.Title" :subtitle="item.Description"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BaseCard from "@/components/BaseCard.vue";
import PaperIcon from "@/assets/svg/paper.svg";
import global from "@/mixins/global.js";

export default {
  name: "HomePageArticles",
  mixins: [global],
  components: {
    BaseCard,
    PaperIcon
  },
  data() {
    return {
      articles: ""
    };
  },
  created: function() {
    let self = this;
    self.$http
      .get(`spa/homepage/expert-advice`)
      .then(response => {
        self.articles = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.homepage-articles {
  background: white;
  @include tablet {
    padding: rem(60px 0);
  }
  .container > .columns {
    & > .column:first-child {
      @include tablet {
        padding-left: 40px;
      }
    }
    & > .column:nth-child(2) {
      @include tablet {
        padding-left: 20px;
      }
    }
  }
}
svg {
  margin-bottom: rem(25px);
}
</style>