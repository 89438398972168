<template>
    <section class="homepage-news section">
        <div class="container">
            <h2 class="title is-2 has-text-weight-light has-text-left">Aktualności&emsp;<a :href="rootUrl + 'aktualno%C5%9Bci/'" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow">Zobacz wszystkie</a></h2>
            <div class="columns">
                <div class="column" v-for="item in news" :key="item.Id">
                    <BaseCardTopImage @goToUrlHandler="goToExternalUrlHandler(item.Url)" :image="item.Image" :category="item.CategoryName" :title="item.Title" :description="item.Description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BaseCardTopImage from "@/components/BaseCardTopImage.vue";
import global from "@/mixins/global.js";

export default {
  name: "HomePageNews",
  mixins: [global],
  components: {
    BaseCardTopImage
  },
  data() {
    return {
        news: "",
        rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/')
    };
  },
  created: function() {
    let self = this;
    self.$http
      .get(`spa/homepage/articles`)
      .then(response => {
        self.news = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .homepage-news {
        background: $grey-lightest;
        padding-bottom: rem(50px);
    }

    .title {
        padding-bottom: rem(20px);

        @include tablet {
            margin-left: rem(30px);
        }
    }
</style>
