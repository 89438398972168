<template>
  <main>
    <BaseHeroSearch
      :title="'Lista sklepów'"
      :showFilters.sync="showFilters"
      :hasFilters="false"
      :search.sync="search"
      :hasSearch="false"
      :hasSearchMobileOnly="false"
      :placeholder="'Szukaj'"
    />

    <ShopsTable :search.sync="search" :showFilters.sync="showFilters" />
  </main>
</template>

<script>
import BaseHeroSearch from '@/components/BaseHeroSearch.vue';
import ShopsTable from '@/components/Containers/ViewMyShopsList/ShopsTable.vue';

export default {
	components: {
		BaseHeroSearch,
		ShopsTable,
	},

	data() {
		return {
			search: '',
			showFilters: false,
		};
	},
};
</script>

<style lang="scss" scoped>
</style>