<template>
    <main>
        <section class="partner currentLecture-single section">
            <div class="container is-container-thinnest">
                <div class="columns is-variable is-8 is-multiline">
                    <div class="column is-full">
                        <h1 class="title is-size-3  has-text-weight-light has-text-left has-text-shadow">{{currentLecture.Leader}}: {{ currentLecture.Title }}</h1>
                        <div v-if="typeof currentLecture.ClickmeetingUrl !== 'undefined' && currentLecture.ClickmeetingUrl != ''" id="embeded" class="column is-full is-paddingless">   
                                <span style="color: #3aab59;">  Jeśli chcesz oglądać wykład w pełnej jakości, skorzystaj z opcji "Pełny ekran" w prawym górnym rogu okna transmisji.</span>
                                <br />
                                <br />
                            </div>
                            <div v-else>
                                <div class="column is-full is-paddingless" v-if="currentLecture.YTLink">
                                    <div class="has-play-button" @click="playVideoHandler" :class="{ 'is-playing': isVideoPlaying }">
                                        <div>
                                            <img src="/assets/2018/svg/play.svg" />
                                            <!--<p class="is-size-2-tablet has-text-centered has-text-white">Obejrzyj wideo wprowadzające</p>-->
                                        </div>
                                        <youtube :video-id="currentLecture.YTLink" :player-vars="playerVars" @ended="endVideoHandler" resize="true" ref="youtube"></youtube>
                                        <img :src="'http://i3.ytimg.com/vi/' + currentLecture.YTLink +'/hqdefault.jpg'">
                                    </div>
                                </div>
                                <div class="column is-full is-paddingless" v-else>
                                    <figure class="image" v-if="currentLecture.Image">
                                        <!--<img v-lazy="currentLecture.Image" :alt="currentLecture.Title" :title="currentLecture.Title">-->
                                        <img v-bind:src="currentLecture.Image" :alt="currentLecture.Title" :title="currentLecture.Title">
                                    </figure>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="container is-fluid">
                <div class="container is-container-thinnest">
                    <div class="columns is-variable is-8 is-multiline">
                        <div class="column is-full">
                            <iframe v-if="typeof(currentLecture.ChatIframeUrl)!='undefined' && currentLecture.ChatIframeUrl !== ''" allowfullscreen style="margin: 0 auto; display: block; border: 0; width: 100%;" height="460" v-bind:src="currentLecture.ChatIframeUrl">Twoja przeglądarka nie obsługuje ramek. Proszę przejść na stronę: <a v-bind:herf="currentLecture.ChatIframeUrl">{{currentLecture.ChatIframeUrl}}</a></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import BaseHero from "@/components/BaseHero.vue";
    export default {
        name: "ViewLiveLecture",
        components: {
            BaseHero,

        },

        //activated: function () {
        //    this.loadNextLiveLecture();
        //},

        data() {
            return {
                isMemberSubscribedToLiveLecture: false,
                currentLecture: {},
                emb: '<script type="text/javascript" src="https://embed.clickmeeting.pl/embed_conference.html?r=1713864483612941&w=1024&h=768">',

                playerVars: {
                    autoplay: 0,
                    showinfo: 0,
                    rel: 0
                },
                isVideoPlaying: false
            };
        },
        methods: {

            setMemerParticipation() {
                let self = this;
                let timeout = 1000 * 60 * 5
                self.$http
                    .get(`spa/livelecture/SetMemerParticipation/` + self.currentLecture.Id)
                    .then(response => {
                        if (typeof response.data !== 'undefined' && response.data !== null) {
                            self.isMemberSubscribedToLiveLecture = response.data;
                            if (response.data == false) {
                                setTimeout(function () { self.setMemerParticipation(); }, timeout);
                            }
                        }
                        else {
                            self.isMemberSubscribedToLiveLecture = false;
                            setTimeout(function () { self.setMemerParticipation(); }, timeout);
                        }

                    })
                    .catch(() => {
                        self.isMemberSubscribedToLiveLecture = false;
                        setTimeout(function () { self.setMemerParticipation(); }, timeout);

                    });
            },

            async loadNextLiveLecture() {
                let self = this;
                let response = await self.$http
                    .get(`spa/livelecture/nextlivelecture`);


                {
                    if (typeof response.data !== 'undefined' && response.data !== null) {
                        self.currentLecture = response.data;
                        self.isToDisplay = true;
                        self.setMemerParticipation();


                    }
                    else {
                        self.isToDisplay = false;
                    }

                }
            },
            playVideoHandler() {
                this.isVideoPlaying = true;
                this.player.playVideo();
            },
            endVideoHandler() {
                this.isVideoPlaying = false;
            },
            resetData() {
                let self = this;
                self.currentLecture = {};
            }
        },
        created: function () {

        },
        mounted: async function () {
            await this.loadNextLiveLecture();
            if (typeof this.currentLecture.ClickmeetingUrl !== 'undefined' && this.currentLecture.ClickmeetingUrl != '') {
                let recaptchaScript = document.createElement('script')
                recaptchaScript.setAttribute('src', this.currentLecture.ClickmeetingUrl)
                document.getElementById('embeded').appendChild(recaptchaScript)
            }
        },
        computed: {
            player() {
                return this.$refs.youtube.player;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .partner {
        margin-top: rem(30px);
        padding-bottom: 0;
        .is-fluid

    {
        background: $white;
        padding: rem(40px 20px 50px);
        margin: 0 -20px;
    }

    .image {
        margin-bottom: rem(50px);
    }

    .has-play-button {
        margin-bottom: rem(50px);
    }
    }
</style>
