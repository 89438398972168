<template>
	<form @submit.prevent>
		<div
			v-if="isEdit"
			class="columns is-multiline is-variable has-text-centered-mobile has-padding-bottom-10"
		>
			<div class="column is-half has-text-right-tablet">
				Aktualny właściciel:
			</div>

			<!-- Progress bar -->
			<div
				v-if="owner.isBeingLoaded"
				class="column is-one-quarter has-text-weight-bold"
			>
				<progress
					class="progress is-small is-primary has-margin-top-5"
					max="100"
					>15%</progress
				>
			</div>

			<!-- Owner name -->
			<div
				v-if="!owner.isBeingLoaded"
				class="column is-half has-text-weight-bold"
			>
				{{ owner.name }}
			</div>
		</div>

		<template v-if="!isEdit && !this.$route.params.ownerId">
			<div class="field">
				<div class="control has-icons-left">
					<div class="input-container-text">
						<input
							class="input is-paddingless has-text-centered"
							v-model.lazy="search"
							@change="searchOwnerHandler"
							type="text"
							placeholder="Wyszukaj własciciela"
						/>
						<span class="icon is-small is-left">
							<LoupeIcon />
						</span>
					</div>
				</div>
			</div>

			<table class="table table-steps" v-if="ownerList !== ''">
				<thead class="is-hidden-mobile">
					<tr
						class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
					>
						<th class="has-text-grey-light">Imię</th>
						<th class="has-text-grey-light">Nazwisko</th>
						<th class="has-text-grey-light">Email</th>
						<th class="has-text-grey-light">Sieć</th>
						<th class="has-text-grey-light has-text-centered-tablet">
							Wybierz
						</th>
					</tr>
				</thead>
				<tbody v-if="ownerList.length">
					<tr v-for="owner in ownerList" :key="owner.Id">
						<td class="has-text-black is-size-17">
							<div class="is-dotted is-block">
								<span
									class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
									>Imię</span
								>
								<span>{{ owner.FirstName }}</span>
							</div>
						</td>
						<td class="has-text-black is-size-17">
							<div class="is-dotted is-block">
								<span
									class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
									>Nazwisko</span
								>
								<span>{{ owner.LastName }}</span>
							</div>
						</td>
						<td class="has-text-black is-size-17">
							<div class="is-dotted is-block">
								<span
									class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
									>Email</span
								>
								<span>{{ owner.Email }}</span>
							</div>
						</td>
						<td class="has-text-black is-size-17">
							<div class="is-dotted is-block">
								<span
									class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
									>Sieć</span
								>
								<span>{{ owner.Network }}</span>
							</div>
						</td>
						<td class="has-text-black is-size-17 has-text-centered">
							<b-field>
								<b-radio-button
									@input="changeShopOwnerHandler(owner)"
									type="is-info"
									v-model="ownerId"
									:native-value="owner.Id"
									size="is-large"
									class="has-text-centered"
								>
									<CheckedIcon />
								</b-radio-button>
							</b-field>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="4" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>

			<p class="has-text-centered has-margin-top-20">
				Nie znalazłeś właściciela, którego szukasz?
			</p>
			<div class="columns">
				<div class="column has-text-centered">
					<button
						type="button"
						class="button is-outlined is-size-7 is-info is-uppercase has-text-weight-bold has-margin-top-10"
						@click="openModalAddUser('owner')"
					>
						Dodaj właściciela
					</button>
				</div>
			</div>

			<hr />
		</template>

		<div
			v-if="shopManager.hasBeenAdded"
			class="columns is-multiline is-variable has-text-centered-mobile"
		>
			<div class="column is-half has-text-right-tablet">Dodany Kierownik:</div>
			<div class="column is-half has-text-weight-bold">
				{{ shopManager.name }} ({{ shopManager.email }})
			</div>
		</div>

		<div v-if="!$store.state.shop.ManagerName && !isEdit" class="columns">
			<div class="column has-text-centered">
				<button
					type="button"
					class="button is-outlined is-size-7 is-info is-uppercase has-text-weight-bold has-margin-top-10"
					@click="openModalAddUser('manager')"
				>
					Dodaj kierownika
				</button>
			</div>
		</div>

		<BaseModalAddUser
			:is-active="modalAddUser.isActive"
			:user-role="modalAddUser.userRole"
			:fields="modalAddUser.fields"
			@success="onUserAddSuccess"
			@error="onUserAddError"
		/>

		<BaseModalInfo
			:is-active="modalInfo.isActive"
			:message="modalInfo.message"
			:button-label="modalInfo.buttonLabel"
		/>
	</form>
</template>

<script>
// JS imports.
import ShopService from '@/services/ShopService.js';
import UserService from '@/services/UserService.js';

// Vue components.
import LoupeIcon from '@/assets/svg/loupe.svg';
import CheckedIcon from '@/assets/svg/checked.svg';
import BaseModalAddUser from '@/components/BaseModalAddUser.vue';
import BaseModalInfo from '@/components/BaseModalInfo.vue';

export default {
	props: ['clickedNext', 'currentStep', 'submitStatus', 'isEdit'],
	components: {
		LoupeIcon,
		CheckedIcon,
		BaseModalAddUser,
		BaseModalInfo,
	},
	data() {
		return {
			search: '',
			ownerList: '',
			ownerId: '',
			ownerName: '',
			managerName: '',

			shopManager: {
				hasBeenAdded: false,
				name: '',
				email: '',
				isBeingLoaded: true,
			},

			owner: {
				hasBeenAdded: false,
				name: '',
				email: '',
				isBeingLoaded: true,
			},

			modalAddUser: {
				isActive: false,
				userRole: '',
				fields: {},
			},

			modalInfo: {
				isActive: false,
				message: '',
				buttonLabel: '',
			},
		};
	},
	methods: {
		canContinue() {
			this.$emit('can-continue', { value: true });
		},
		searchOwnerHandler() {
			let self = this;

			self.$http
				.post(`spa/managment/shop/searchowner`, {
					Query: this.search,
				})
				.then(response => {
					self.ownerList = response.data;
				})
				.catch(() => {
					self.ownerList = '';
				});
		},

		changeShopOwnerHandler(owner) {
			this.ownerId = owner.Id;
			this.$store.state.shop.OwnerId = owner.Id;
			this.ownerName = owner.FirstName + ' ' + owner.LastName;
			this.$store.state.shop.OwnerName = owner.FirstName + ' ' + owner.LastName;
			this.$store.commit('UPDATE_STATE');
			this.$emit('can-continue', { value: true });
		},

		submitFormHandler() {
			if (this.submitStatus.loading) {
				let self = this,
					formData = {
						MemberId: this.ownerId,
						ShopId: this.$store.state.shop.Id,
					},
					submitStatus = { ...self.submitStatus };

				if (this.isEdit) {
					self.$http
						.post(`spa/managment/shop/changeowner`, formData)
						.then(response => {
							submitStatus = {
								message: response.data.Message,
								loading: false,
								responseType: 'success',
							};

							self.$store.state.shop.OwnerId = self.ownerId;
							self.$store.state.shop.OwnerName = self.ownerName;
							self.$store.commit('UPDATE_STATE');
							self.$emit('update-submit', submitStatus);
						})
						.catch(error => {
							submitStatus = {
								message: error.response.data.Message,
								loading: false,
								responseType: 'error',
							};

							self.$emit('update-submit', submitStatus);
						});
				} else {
					let formData = {};

					self.$store.state.shop.OwnerId = self.ownerId;
					self.$store.state.shop.OwnerName = self.ownerName;
					self.$store.commit('UPDATE_STATE');
					formData = { ...self.$store.state.shop };

					self.$http
						.post(`spa/managment/shop/add`, formData)
						.then(response => {
							submitStatus = {
								message: response.data.Message,
								loading: false,
								responseType: 'success',
							};

							self.$emit('update-submit', submitStatus);
							setTimeout(() => {
								self.$router.push('/panel-zarzadania/sklepy');
							}, 4000);
						})
						.catch(error => {
							submitStatus = {
								message: error.response.data.Message,
								loading: false,
								responseType: 'error',
							};

							self.$emit('update-submit', submitStatus);
						});
				}
				self.$store.commit('UPDATE_STATE');
			}
		},

		openModalAddUser(userRole) {
			this.modalAddUser.userRole = userRole;

			switch (userRole) {
				case 'owner':
					this.modalAddUser.fields = {
						FirstName: {
							validations: ['required'],
						},
						LastName: {
							validations: ['required'],
						},
						Network: {
							validations: ['required'],
						},
						Email: {
							validations: ['required', 'email'],
						},
						Phone: {
							validations: ['required'],
						},
					};
					break;

				case 'manager':
					this.modalAddUser.fields = {
						FirstName: {
							validations: ['required'],
						},
						LastName: {
							validations: ['required'],
						},
						Email: {
							validations: ['required', 'email'],
						},
						Phone: {
							validations: ['required'],
						},
					};
					break;

				default:
					break;
			}

			this.modalAddUser.isActive = true;
		},

		closeModalAddUser() {
			this.modalAddUser.isActive = false;
		},

		closeModalInfo() {
			this.modalInfo.isActive = false;
		},

		onUserAddSuccess(formData) {
			this.modalAddUser.isActive = false;

			let whoseAccount;
			switch (this.modalAddUser.userRole) {
				case 'owner':
					whoseAccount = 'właściciela';
					break;

				case 'manager':
					whoseAccount = 'kierownika';
					break;

				default:
					break;
			}

			this.modalInfo.message = `Konto ${whoseAccount} zostało utworzone i przypisane do sklepu.`;
			this.modalInfo.buttonLabel = 'Zamknij okno';
			this.modalInfo.isActive = true;

			if (this.modalAddUser.userRole === 'owner') {
				const owner = {
					id: formData.OwnerId,
					name: `${formData.Name} ${formData.Surname}`,
				};

				this.ownerId = owner.id;
				this.ownerName = owner.name;

				this.$store.state.shop.OwnerId = owner.id;
				this.$store.state.shop.OwnerName = owner.name;

				this.$emit('can-continue', { value: true });
			}

			if (this.modalAddUser.userRole === 'manager') {
				const name = `${formData.FirstName} ${formData.LastName}`;
				this.managerName = name;
				this.$emit('can-continue', { value: true });
				this.$store.state.shop.ManagerName = name;
				this.$store.state.shop.ShopManagerId = formData.ShopManagerId;
				this.$store.commit('UPDATE_STATE');

				this.shopManager.name = name;
				this.shopManager.email = formData.Email;
				this.shopManager.hasBeenAdded = true;

				this.$emit('create-manager');
			}
		},

        onUserAddError(formData, message) {

            if (typeof (message) === 'undefined' || message === null || message === '') {
                this.modalInfo.message =
                    'Wystąpił nieoczekiwany błąd. Sprawdź poprawność wprowadzonych danych i spróbuj ponownie.';
            }
            else {
                this.modalInfo.message = message;
            }
		
			this.modalInfo.buttonLabel = 'OK';
			this.modalInfo.isActive = true;
		},
	},

	mounted() {
		this.$emit('can-continue', { value: this.ownerId ? true : false });

		// If the component is in the 'edition' state,
		// load it's original data to display the Owner name.
		if (this.isEdit) {
			ShopService.getShopDetails(this.$route.params.shopId).then(response => {
				const shopDetails = response.data;

				this.owner.isBeingLoaded = false;
				this.owner.name = shopDetails.OwnerName;
			});
		}

		if (this.$route.params.ownerId) {
			UserService.getProfileData(this.$route.params.ownerId).then(response => {
				const owner = { ...response.data };
				const { FirstName: firstName, LastName: lastName } = owner;

				if (firstName || lastName) {
					this.changeShopOwnerHandler(owner);
					this.modalInfo.message = `Dodajesz sklep dla właściciela ${firstName} ${lastName}.`;
					this.modalInfo.buttonLabel = 'OK';
					this.modalInfo.isActive = true;
				} else {
					this.$router.push({ name: 'Dodaj sklep' });
				}
			});
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

form {
	& > div:first-child {
		padding-top: rem(10px);
		& > div {
			padding-top: 5px;
			padding-bottom: 0;
		}
	}
	.field {
		max-width: 500px;
		margin: 0 auto;
	}
	.columns {
		margin-bottom: 0;
	}
	.stepper-box {
		box-shadow: none;
	}
	.input-container-text {
		.icon.is-left {
			margin-top: rem(10px);
			margin-left: rem(10px);
		}
		input {
			height: 56px;
		}
	}
	.control.has-icons-left {
		input {
			padding-left: rem(50px);
		}
	}
}
</style>
