<template>
    <main>
        <BaseHero :title="'AUE TV'" />
        <BaseHeroSearch :title="'Wszystkie filmy video'" :placeholder="'Szukaj filmu'" :search.sync="search" :showFilters.sync="showFilters" :hasFilters="true" :hasSearch="true" />
        <AueTv :search.sync="search" :showFilters.sync="showFilters" />
    </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import BaseHeroSearch from "@/components/BaseHeroSearch.vue";
import AueTv from "@/components/Containers/ViewAueTv/AueTv.vue";

export default {
  name: "ViewAueTv",
  components: {
      BaseHero,
      BaseHeroSearch,
      AueTv
  },
  data() {
     return {
      search: "",
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
