<template>
  <section class="elearning-target educational-offer section">
    <div class="container is-container-thinnest">
      <h2 class="title is-1 has-text-centered">Dla kogo przygotowane jest szkolenie?</h2>
      <div class="columns is-flex">
            <div class="column">
                <div class="card is-flex">
                    <div class="card-content">
                        <YoungWomanIcon />
                        <h3 class="is-size-4 has-text-centered">Kierownicy zespołów</h3>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card is-flex">
                    <div class="card-content">
                        <EmployeeWomanIcon />
                        <h3 class="is-size-4 has-text-centered">Kierownicy sklepów</h3>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="card is-flex">
                    <div class="card-content">
                        <EmployeeManIcon />
                        <h3 class="is-size-4 has-text-centered">Właściciele sklepów</h3>
                    </div>
                </div>
            </div>
        </div>      
    </div>
  </section>
</template>

<script>
import YoungWomanIcon from "@/assets/svg/employee-young-woman.svg";
import EmployeeWomanIcon from "@/assets/svg/employee-woman.svg";
import EmployeeManIcon from "@/assets/svg/employee-man.svg";

export default {
  name: "ElearningTarget",
  components: {
    YoungWomanIcon,
    EmployeeWomanIcon,
    EmployeeManIcon
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.elearning-target {
  background: $white;

  h2 {
    margin-top: rem(20px);
    margin-bottom: 0;
  }
}
</style>
