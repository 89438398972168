<template>
<div class="notification is-flex" v-touch:tap="acceptCookiesHandler" v-if="!$store.state.cookie">
  <CookieIcon width="210" height="70" viewBox="0 0 60 70"/>
  <p class="is-size-15 has-text-white">
      Korzystając z tej strony internetowej, bez zmian konfiguracji przeglądarki, wyraża Pani/Pan zgodę na zapisywanie plików cookie w Pani/Pana urządzeniu końcowym. Za pomocą plików cookie zbierane są informacje, które mogą stanowić dane osobowe. W takim wypadku są one przetwarzane w celu udzielenia Pani/Panu dostępu do strony oraz w celach analitycznych i statystycznych. Należy pamiętać, że może Pani/Pan zawsze zmienić ustawienia dotyczące plików cookie. Więcej informacji o przetwarzaniu danych osobowych, w tym o przysługujących Pani/Panu prawach, znajduje się w naszej <a href="../polityka-prywatnosci">Polityce Prywatności</a>.
  </p>
  <button class="delete" @click="acceptCookiesHandler"></button>
</div>
</template>

<script>
import CookieIcon from "@/assets/svg/cookie.svg";

export default {
  name: "TheCookieBox",
  components: {
    CookieIcon
  },
  methods: {
    acceptCookiesHandler: function() {
      this.$set(this.$store.state, "cookie", true);
      this.$store.commit("UPDATE_STATE");
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.notification,
.notification:not(:last-child) {
  background: $black-bis;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 32;
  margin-bottom: 0;
  padding: 0;
  border-radius: 0;
  @include tablet {
    padding: rem(15px);
  }
  svg {
    display: none;
    @include tablet {
      display: block;
    }
  }
  p {
    padding: 15px 0 15px 20px;
    @include tablet {
      padding: rem(0 60px);
    }
  }
  .delete {
    max-width: 35px;
    min-height: 35px;
    width: 50px;
    border-radius: 50%;
    border: 2px solid $grey;
    position: relative;
    top: auto;
    &:before {
      height: 2px;
      background-color: $grey;
    }
    &:after {
      width: 2px;
      background-color: $grey;
    }
    &:hover,
    &:focus {
      border: 2px solid $white;
      &:before,
      &:after {
        background-color: $white;
      }
    }
  }
}
</style>
