<template>
	<main>
		<!--<BaseHero >-->
		<div class="live-lecture-form container small">
			<div class="task-form-form column">
				<div class="header">{{form.Title}}</div>
				<div class="card">
					<form class="form-wrap" novalidate>
						<div v-for="task in  form.OpenQuestionTasks" :key="task.Id">
							<p class=" has-text-left">{{task.Text}}</p>
							<textarea v-model="task.Response" :id="task.Id" maxlength="1000" required />
							<span class="errorValidity" aria-live="polite"></span>
							<br />
						</div>
						<div v-if="errorMessage">
							<p class="error">
								{{errorMessage}}
							</p>
							<br />
						</div>

						<div class="center">
							<button type="button" @click="processForm"
									class="button is-info is-size-6 is-fullwidth">
								Wyślij do swojego przełożonego
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import ViewOnboardingWeek from "@/components/Containers/ViewOnboarding/ViewOnboardingWeek.vue";
import global from "@/mixins/global.js";

export default {
	name: "ViewOnboardingForm",

	mixins: [global],

	components: {
		BaseHero,
		ViewOnboardingWeek
	},

	data() {
		return {
			form: {},
			errorMessage: '',

		};
	},

	methods: {
		loadForm() {
			let self = this;
			self.$http
				.get(`spa/Onboarding/Form/${self.$route.params.formId}/user/${null}`)
				.then(response => {

					self.form = response.data;

					self.$nextTick(() => {
						self.initForm();
					});
				})
				.catch((e) => { console.log(e); });
		},

		submitForm() {
			let self = this;
			self.errorMessage = "";
			self.$http.post(`spa/Onboarding/Form`, self.form).then(() => {
				var url = `/onboardings/${self.$route.params.programId}/day/${self.$route.params.dayId}`;
				self.goToUrlHandler(url);

			})
			.catch((e) => {
				console.log(e);
				self.errorMessage = "Wystąpił błąd";
			});
		},

		processForm() {
			const textareas = document.getElementsByTagName('textarea');
			let notValid = false;

			for (let i = 0; i < textareas.length; i++) {
				if (!textareas[i].validity.valid) {
					notValid = true;
					const responseError = $(textareas[i]).next('span.errorValidity');
					this.showError(textareas[i], responseError);
				}
			}

			if (!notValid) {
				this.submitForm();
			}
		},

		showError(repsonse, errorField) {
			if (repsonse.validity.valueMissing) {
				errorField[0].textContent = 'Uzupełnij pole';
			} else if (repsonse.validity.tooLong) {
				errorField[0].textContent = `Długość tekstu nie może przekraczać ${repsonse.maxLength} znaków`;
			}
			errorField[0].className = 'errorValidity active';
			repsonse.className = 'areaError';
		},

		initForm() {
			const textareas = document.getElementsByTagName('textarea');
			let self = this;

			for (let i = 0; i < textareas.length; i++) {
				textareas[i].addEventListener('input', function () {
					const responseError = $(textareas[i]).next('span.errorValidity');

					if (textareas[i].validity.valid) {
						responseError[0].innerHTML = '';
						responseError[0].className = 'errorValidity';
						textareas[i].className = '';
					} else {
						self.showError(textareas[i], responseError);
					}
				});
			}
		}
	},

	created: function () {
		this.loadForm();
	}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.header {
	background-color: #3AAB59;
	color: $white;
	min-height: 56px;
	text-align: center;
	font-size: 1.1rem;
	font-weight: bold;
	padding: 1.25rem;
	margin-bottom: 15px;
}

.errorValidity {
	border-radius: 8px 8px 8px 8px;
	-moz-border-radius: 8px 8px 8px 8px;
	-webkit-border-radius: 8px 8px 8px 8px;
	background-color: #DE0C1B;
	color: #FFFFFF;
	font-size: 14px;
	padding: 3px 30px;
	margin: auto;
	display: none;
	width: 160px;
	margin-top: -35px;
	overflow: overlay;
}

.areaError {
	border: 1px solid #DE0C1B !important;
}

.active {
	display: block;
}

.task-form-form {
	background-color: transparent;
	margin: 30px 120px 0 120px;

	.card {
		@include tablet {
			padding: 2.5rem 2.5rem 1.85rem;
		}

		p {
			color: #4a4a4a;
		}

		.error {
			color: red;
			text-align: center;
		}

		.form-wrap {
			margin: 0 170px;

			p {
				  font-size: 16px;
				  font-weight: bold;
				  margin: 10px 0;
			}

			input {
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				color: #686868;
			}

			textarea {
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 170px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				color: #686868;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type=number] {
				-moz-appearance: textfield;
			}

			button[type='submit'], button[type='button'] {
				color: $white;
				width: 45%;
				display: block;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				margin-left: auto;
				margin-right: auto;
				box-shadow: 0px 12px 30px #00000029;
			}
		}
	}
 }
</style>
