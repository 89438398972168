<template>
  <main>
    <BaseHero :title="'Polityka Prywatności'"/>
    <section class="privacy-policy regulations section">
      <div class="container is-container-thin">
        <div class="columns is-full">
            <div class="column">
              <ul class="has-text-weight-light has-text-left">
                  <li>
                      <h2 class="is-size-2 has-text-shadow">I. Wstęp</h2>
                      <p>
                          W&nbsp;trosce o&nbsp;prywatność osób odwiedzających stronę internetową <a href="http://www.eurocash.com.pl" target="_blank">www.eurocash.com.pl</a> na&nbsp;zasadach określonych w&nbsp;Ustawie z&nbsp;dnia 29&nbsp;sierpnia&nbsp;1997&nbsp;r. o&nbsp;ochronie danych osobowych (Dz.&nbsp;U.&nbsp;z&nbsp;2002&nbsp;r.&nbsp;Nr&nbsp;101 poz.&nbsp;926 ze&nbsp;zm. – dalej „Ustawa"). Prosimy o&nbsp;zapoznanie się z&nbsp;zasadami, według których przetwarzamy Państwa dane osobowe.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">II. Informacje uzyskiwane o&nbsp;użytkowniku</h2>
                      <p>
                          Udostępniają Państwo spółce Eurocash S.A. wyłącznie te&nbsp;dane, które posłużą spółce Eurocash S.A do&nbsp;świadczenia żądanej usługi. Państwa dane osobowe nie&nbsp;będą przez spółkę Eurocash S.A przechowywane dłużej niż to&nbsp;konieczne w&nbsp;celu świadczenia usługi bądź dłużej, niż jest to&nbsp;wymagane w&nbsp;świetle obowiązujących przepisów prawa.
                          Dane wpisywane przez Państwa w&nbsp;formularzu online chronione są&nbsp;poprzez szyfrowanie z&nbsp;użyciem protokołu&nbsp;SSL.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">III. Odnośniki do&nbsp;innych stron</h2>
                      <p>
                          Strona internetowa <a href="http://www.eurocash.com.pl" target="_blank">www.eurocash.com.pl</a> może zawierać odnośniki do&nbsp;innych stron internetowych www.&nbsp;Spółka Eurocash S.A. nie&nbsp;ponosi, i&nbsp;nie może ponosić odpowiedzialności za&nbsp;zasady zachowania prywatności obowiązujące na&nbsp;tych stronach. Spółka Eurocash S.A. rekomenduje, zapoznanie się z&nbsp;polityka prywatności po&nbsp;przejściu na&nbsp;inne strony&nbsp;www.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">IV. Przekazywanie  danych użytkownika osobom trzecim</h2>
                      <p>
                          Państwa dane mogą zostać przekazane przedstawicielom spółki Eurocash S.A.  oraz spółkom w&nbsp;ramach grupy kapitałowej Eurocash S.A. w&nbsp;celach związanych z&nbsp;obsługą Państwa zgłoszenia oraz w&nbsp;celu udzielania Państwu kompleksowej informacji o&nbsp;oferowanych produktach i&nbsp;usługach. Państwa dane nie&nbsp;zostaną ujawnione żadnym innym osobom, o&nbsp;ile nie będzie to&nbsp;wymagane obowiązującymi przepisami prawa lub o ile nie zostanie zawarta stosowna umowa gwarantująca ochronę Państwa praw. Ponadto, dane na temat użytkowników naszej witryny oraz ich preferencji mogą być ujmowane i&nbsp;ujawniane w&nbsp;formie statystyk, przy czym statystyki takie pozostają anonimowe i&nbsp;nie&nbsp;zawierają danych identyfikujących poszczególnych użytkowników.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">V. Inne informacje</h2>
                      <p>
                          Podczas wizyt na naszej stronie, na&nbsp;Państwa komputerze zapisywane są&nbsp;fragmenty kodu zwane cookies. Służą one do&nbsp;zapewnienia optymalnej obsługi Państwa wizyty na&nbsp;naszej stronie oraz umożliwiają szybszy i&nbsp;łatwiejszy dostęp do&nbsp;informacji. Pliki Cookies nie służą do&nbsp;przetwarzania danych osobowych a&nbsp;ich zawartość nie umożliwiała identyfikacji użytkownika. Cookies stosowane są&nbsp;na wielu witrynach internetowych W&nbsp;przypadku wyłączenia funkcji cookies, niektóre elementy naszej witryny internetowej mogą być niedostępne.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">V. Badanie zgodności danych</h2>
                      <p>
                          W&nbsp;celu uzyskania informacji na&nbsp;temat Państwa danych osobowych przechowywanych przez nas bądź w&nbsp;celu uzyskania dostępu lub możliwości poprawienia takich danych zgodnie z&nbsp;przepisami Ustawy, prosimy o&nbsp;kontakt z&nbsp;administratorem strony pod&nbsp;adresem <a href="mailto:info.www@eurocash.com.pl">info.www@eurocash.com.pl</a>.
                      </p>
                  </li>
                  <li>
                      <h2 class="is-size-2 has-text-shadow">VI. Zmiana Polityki Prywatności</h2>
                      <p>
                          Spółka Eurocash S.A.&nbsp;zastrzega sobie możliwość zmiany Polityki prywatności w&nbsp;każdym czasie poprzez opublikowanie aktualnej treści na&nbsp;tej stronie.
                          Niniejsza Polityka Prywatności obowiązuje od&nbsp;dnia&nbsp;1.11.2012
                      </p>
                  </li>
              </ul>                  
            </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";

export default {
  name: "ViewPrivacyPolicy",
  components: {
    BaseHero
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>

