const ShopType = {
    Own: "Own",
    Franchise: "Franchise"
}

export const enumMixin = {
    data() {
        return {
            ShopType
        }
    }
}