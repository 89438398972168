<template>
  <main>
  
    <ImplementationProgramTasks :search.sync="search" :showFilters.sync="showFilters" class="top"/>
  </main>
</template>

<script>

    import ImplementationProgramTasks from "@/components/Containers/ViewImplementationProgramTasks/ImplementationProgramTasks.vue";

export default {
  name: "ViewImplementationProgramTasks",
  components: {
      ImplementationProgramTasks
  },
  data() {
    return {
      search: "",
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
    .top {
    margin-top:90px;
    
    }
</style>
