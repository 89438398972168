<template>
  <main>
    <BaseHeroSearch
      :title="'Sklepy'"
      :showFilters.sync="showFilters"
      :hasFilters="true"
      :search.sync="search"
      :hasSearch="true"
      :hasSearchMobileOnly="true"
      :placeholder="'Szukaj'"
    />
    <ShopsManagement :search.sync="search" :showFilters.sync="showFilters" />
  </main>
</template>

<script>
import BaseHeroSearch from '@/components/BaseHeroSearch.vue';
import ShopsManagement from '@/components/Containers/ViewShopsManagement/ShopsManagement.vue';

export default {
	name: 'ViewShopsManagement',

	components: {
		BaseHeroSearch,
		ShopsManagement,
	},

	data() {
		return {
			search: '',
			showFilters: false,
		};
	},

	mounted() {
		if (this.$route.params.shopId) {
			this.search = this.$route.params.shopId;
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';
</style>
