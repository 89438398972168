<template>
  <section class="partner section">
    <div class="container is-container-thinnest">
      <div class="columns is-variable is-8 is-multiline">
        <div class="column is-full">
          <h1 class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow">{{ partner.Title }}</h1>
          <div class="column is-full is-paddingless" v-if="partner.YTMovieId">
              <div class="has-play-button" @click="playVideoHandler" :class="{ 'is-playing': isVideoPlaying }">
                  <div>
                    <img src="/assets/2018/svg/play.svg" />
                    <p class="is-size-2-tablet has-text-centered has-text-white">Obejrzyj wideo wprowadzające</p>
                  </div>
                  <youtube :video-id="partner.YTMovieId" :player-vars="playerVars" @ended="endVideoHandler" resize="true" ref="youtube"></youtube>
                  <img :src="'http://i3.ytimg.com/vi/' + partner.YTMovieId +'/hqdefault.jpg'">
              </div>
          </div>
          <div class="column is-full is-paddingless" v-else>
              <figure class="image" v-if="partner.Image">
                  <!--<img v-lazy="partner.Image" :alt="partner.Title" :title="partner.Title">-->
                  <img v-bind:src="partner.Image" :alt="partner.Title" :title="partner.Title">
              </figure>
          </div>
        </div>
      </div>   
    </div>
    <div class="container is-fluid">
      <div class="container is-container-thinnest">
        <div class="columns is-variable is-8 is-multiline">
          <div class="column is-full">
            <div class="content" v-html="partner.ArticleBody"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Partner",
        components: {},
    activated: function () {
        let self = this;
        self.resetData();
        self.$http
            .get(`/spa/partneradvises/getdetails/` + self.$route.params.id)
            .then(response => {
                self.partner = response.data;
                self.$set(this.$store.state, "breadcrumb", self.partner.Title);
            })
            .catch(() => { });
    }, 
  mounted() {},
  data() {
    return {
      partner: {},
      playerVars: {
        autoplay: 0,
        showinfo: 0,
        rel: 0
      },
      isVideoPlaying: false
    };
  },
  methods: {
    playVideoHandler() {
      this.isVideoPlaying = true;
      this.player.playVideo();
    },
    endVideoHandler() {
      this.isVideoPlaying = false;
      },
      resetData() {
          let self = this;
          self.partner = {};
    }

  },
  created: function() {
   
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.partner {
  margin-top: rem(30px);
  padding-bottom: 0;
  .is-fluid {
    background: $white;
    padding: rem(40px 20px 50px);
    margin: 0 -20px;
  }
  .image {
    margin-bottom: rem(50px);
  }
  .has-play-button {
    margin-bottom: rem(50px);
  }
}
</style>
