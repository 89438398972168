<template>
    <div class="workers-stats column is-full is-block has-text-centered">
      <div class="card-table card-table-has-expand">
        <div class="card is-hidden-mobile">
          <div class="card-header">
            <div class="field">
              <div class="control has-icons-left has-icons-right">
                <input class="input is-size-4" type="text" v-model.lazy="filters.SearchPhrase" :placeholder="'Szukaj pracownika'">
                <span class="icon is-small is-left">
                  <LoupeIcon/>
                </span>
              </div>
            </div>
          </div>
        </div>
        <table class="table table-stats">
          <thead class="is-hidden-mobile">
            <tr class="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
              <th class="has-text-grey-light">Kod sklepu</th>
              <th class="has-text-grey-light">Nazwa sklepu</th>
              <th class="has-text-grey-light">Imię</th>
              <th class="has-text-grey-light">Nazwisko</th>
              <th class="has-text-grey-light">Liczba szkoleń</th>
              <th class="has-text-grey-light">Liczba warsztatów</th>
              <th class="has-text-grey-light"></th>
            </tr>
            <tr v-if="loading">
              <th colspan="7" class="is-progress-bar-table">
                <progress class="progress is-primary" max="100"></progress>
              </th>
            </tr>
          </thead>
          <tbody v-if="statsList.length">
            <tr class="is-size-17 has-text-left has-text-weight-semibold" v-for="(stat, key) in statsList" :key="key" :class="{ 'is-active': stat.isOpen }">
              <td colspan="8" class="is-paddingless">
                <table>
                  <tr @click="stat.isOpen = !stat.isOpen, loadMemberIncomingWorkshopsHandler(1, stat), loadMemberWorkshopStatsHandler(1, stat), loadMemberElearningStatsHandler(1, stat)">
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Kod sklepu</span>
                        <span>{{ stat.ShopNumber }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Imię</span>
                        <span>{{ stat.ShopName | truncate(50) }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Nazwisko</span>
                        <span>{{ stat.FirstName }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Stanowisko</span>
                        <span>{{ stat.LastName }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Numer telefonu</span>
                        <span>{{ stat.CoursesCount }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="is-dotted is-block">
                        <span class="is-size-17 is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet">Numer telefonu</span>
                        <span>{{ stat.WorkshopCount }}</span>
                      </div>
                    </td>
                    <td class="is-hidden-mobile has-text-centered">
                      <DwnArrowIcon width="12" height="24" viewBox="0 0 12 20" class="dwn-arrow-icon"/>
                    </td>
                  </tr>
                  <transition name="has-fade">
                    <tr v-if="stat.isOpen">
                      <td colspan="8" class="is-paddingless">
                        <table v-if="stat.memberIncomingWorkshops">
                          <thead>
                            <tr class="is-size-18 is-uppercase">
                              <th class="has-text-grey-light has-text-weight-semibold has-text-left">Zaplanowany udział w warsztatach</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Data warsztatu</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Akcje</th>
                            </tr>
                            <tr v-if="stat.loading[0] === true">
                              <th colspan="3" class="is-progress-bar-table">
                                <progress class="progress is-primary" max="100"></progress>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="stat.memberIncomingWorkshops.Data.length">
                            <tr v-for="(incomingWorkshop, key) in stat.memberIncomingWorkshops.Data" :key="key">
                              <td class="has-text-left">{{ incomingWorkshop.Name }}</td>
                              <td class="has-text-centered">{{ incomingWorkshop.Date }}</td>
                              <td class="has-text-centered">
                                <a href="#" class="has-text-primary"></a>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td class="has-text-centered" colspan="3"><p>{{ stat.loading[0] === true ? 'Wczytywanie...' : 'Brak danych' }}</p></td>
                            </tr>
                          </tbody>
                          <tfoot v-if="stat.memberIncomingWorkshops.Data.length && stat.memberIncomingWorkshops.PageTotal !== 1">
                             <tr>
                              <td colspan="3">
                                <b-pagination
                                  :total="stat.memberIncomingWorkshops.TotalRecords"
                                  :current="stat.memberIncomingWorkshops.CurrentPageNumber"
                                  :order="'is-centered'"
                                  :per-page="stat.memberIncomingWorkshops.PageSize"
                                  @change="(page) => loadMemberIncomingWorkshopsHandler(page, stat)">
                                </b-pagination>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>                             
                  </transition>
                  <transition name="has-fade">
                    <tr v-if="stat.isOpen">
                      <td colspan="8" class="is-paddingless">
                        <table v-if="stat.memberWorkshopStats">
                          <thead>
                            <tr class="is-size-18 is-uppercase">
                              <th class="has-text-grey-light has-text-weight-semibold has-text-left">Zrealizowane warsztaty</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Data realizacji</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Wynik Przed/Po</th>
                            </tr>
                            <tr v-if="stat.loading[1] === true">
                              <th colspan="3" class="is-progress-bar-table">
                                <progress class="progress is-primary" max="100"></progress>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="stat.memberWorkshopStats.Data.length">
                            <tr v-for="(workshopStat, key) in stat.memberWorkshopStats.Data" :key="key">
                              <td class="has-text-left">{{ workshopStat.Name }}</td>
                              <td class="has-text-centered">{{ workshopStat.Date }}</td>
                              <td class="has-text-centered">{{ workshopStat.Points }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td class="has-text-centered" colspan="3"><p>{{ stat.loading[1] === true ? 'Wczytywanie...' : 'Brak danych' }}</p></td>
                            </tr>
                          </tbody>
                          <tfoot v-if="stat.memberWorkshopStats.Data.length && stat.memberWorkshopStats.PageTotal !== 1">
                             <tr>
                              <td colspan="3">
                                <b-pagination
                                  :total="stat.memberWorkshopStats.TotalRecords"
                                  :current="stat.memberWorkshopStats.CurrentPageNumber"
                                  :order="'is-centered'"
                                  :per-page="stat.memberWorkshopStats.PageSize"
                                  @change="(page) => loadMemberWorkshopStatsHandler(page, stat)">
                                </b-pagination>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>                             
                  </transition>
                  <transition name="has-fade">
                    <tr v-if="stat.isOpen">
                      <td colspan="8" class="is-paddingless">
                        <table v-if="stat.memberElearningStats">
                          <thead>
                            <tr class="is-size-18 is-uppercase">
                              <th class="has-text-grey-light has-text-weight-semibold has-text-left">Zrealizowane e-learningi</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Data realizacji</th>
                              <th class="has-text-grey-light has-text-weight-semibold has-text-centered">Wynik</th>
                            </tr>
                            <tr v-if="stat.loading[2] === true">
                              <th colspan="3" class="is-progress-bar-table">
                                <progress class="progress is-primary" max="100"></progress>
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="stat.memberElearningStats.Data.length">
                            <tr v-for="(memberStat, key) in stat.memberElearningStats.Data" :key="key">
                              <td class="has-text-left">{{ memberStat.Name }}</td>
                              <td class="has-text-centered">{{ memberStat.PassedDate }}</td>
                              <td class="has-text-centered">{{ memberStat.Scale + '%' }}</td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td class="has-text-centered" colspan="3"><p>{{ stat.loading[2] === true ? 'Wczytywanie...' : 'Brak danych' }}</p></td>
                            </tr>
                          </tbody>
                          <tfoot v-if="stat.memberElearningStats.Data.length && stat.memberElearningStats.PageTotal !== 1">
                             <tr>
                              <td colspan="3">
                                <b-pagination
                                  :total="stat.memberElearningStats.TotalRecords"
                                  :current="stat.memberElearningStats.CurrentPageNumber"
                                  :order="'is-centered'"
                                  :per-page="stat.memberElearningStats.PageSize"
                                  @change="(page) => loadMemberElearningStatsHandler(page, stat)">
                                </b-pagination>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </td>
                    </tr>                             
                  </transition>
                </table>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8" class="is-size-17 has-text-centered has-text-weight-semibold">{{ loading ? 'Wczytywanie...' : 'Brak danych' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <a class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold" @click="filters.Page++; loadStatsListHandler()" v-if="nextPage">Załaduj więcej treści</a>
    </div>
</template>

<script>
import DwnArrowIcon from "@/assets/svg/dwn-arrow-large.svg";
import LoupeIcon from "@/assets/svg/loupe.svg";

export default {
  name: "WorkersStats",
  components: {
    DwnArrowIcon,
    LoupeIcon
  },
  props: {
    filters: {
      type: Object
    }
  },
  data() {
    return {
      statsList: [],
      filtersList: [],
      nextPage: true,
      loading: false
    };
  },
  methods: {
    loadStatsListHandler() {
      let self = this;

      self.loading = true;
      self.$http
        .post(`spa/managment/shopworkers/statslist`, self.filters)
        .then(response => {
          let data = response.data.Data;
          data.forEach(
            item => (
              (item.isOpen = false),
              (item.memberIncomingWorkshops = { Data: [] }),
              (item.memberWorkshopStats = { Data: [] }),
              (item.memberElearningStats = { Data: [] }),
              (item.loading = [false, false, false])
            )
          );
          if (self.filters.Page) {
            self.statsList = [...self.statsList, ...data];
            self.nextPage = response.data.NextPage;
          } else {
            self.statsList = data;
            self.nextPage = response.data.NextPage;
          }
          self.loading = false;
        })
        .catch(() => {});
    },
    loadMemberIncomingWorkshopsHandler(page, item) {
      let self = this;

      item.loading[0] = true;
      self.$http
        .post(`spa/managment/shopworkers/memberincomimgworkshops`, {
          Page: page - 1,
          Uid: item.Id
        })
        .then(response => {
          item.memberIncomingWorkshops = response.data;
          item.loading[0] = false;
        });
    },
    loadMemberWorkshopStatsHandler(page, item) {
      let self = this;

      item.loading[1] = true;
      self.$http
        .post(`spa/managment/shopworkers/memberworkshopstats`, {
          Page: page - 1,
          Uid: item.Id
        })
        .then(response => {
          item.memberWorkshopStats = response.data;
          item.loading[1] = false;
        });
    },
    loadMemberElearningStatsHandler(page, item) {
      let self = this;

      item.loading[2] = true;
      self.$http
        .post(`spa/managment/shopworkers/memberelearningstats`, {
          Page: page - 1,
          Uid: item.Id
        })
        .then(response => {
          item.memberElearningStats = response.data;
          item.loading[2] = false;
        });
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.loadStatsListHandler();
      },
      deep: true
    },
    "filters.SearchPhrase": function() {
      this.$emit("updateSearch", this.filters.SearchPhrase);
    }
  },
  created: function() {
    this.loadStatsListHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.workers-stats {
  .b-tabs {
    width: 100%;
    & > :first-child {
      padding: 20px 15px 0;
      @include desktop {
        margin-top: -67px;
        padding: 20px 10px 0;
      }
    }
    .tab-item {
      overflow: auto;
    }
  }
  .actions,
  .card-table {
    .button.is-info:not(.has-arrow) {
      & + .button.is-info {
        margin-left: rem(20px);
      }
    }
  }
  .card-table {
    .table {
      &.table-stats {
        & > thead,
        tbody > tr > td > table {
          & > tr:first-child {
            @include tablet {
              & > *:not(.is-progress-bar-table) {
                width: 12%;
                padding: rem(15px);
                vertical-align: middle;
              }
              & & > :nth-child(1) {
                width: 17%;
              }
              & > :nth-child(2),
              & > :nth-child(3),
              & > :nth-child(4) {
                width: 20%;
              }
            }
          }
          & > tr {
            thead tr:last-child:not(:first-child) > th {
              padding: 0;
            }
          }
          & > tr + tr:last-child {
            tbody tr td {
              padding-bottom: rem(20px);
            }
          }
        }
      }
    }
  }
}
</style>
