<template>
  <!-- BaseModalConfirmation -->
  <b-modal
    :active.sync="isActive"
    :width="830"
    scroll="keep"
    class="BaseModalConfirmation modal-dialog"
  >
    <!-- Message -->
    <div class="columns">
      <div class="column is-full">
        <p class="is-size-2 has-text-centered has-text-weight-light">{{ message }}</p>
      </div>
    </div>
    <!-- END | Message -->

    <!-- Buttons -->
    <div class="columns is-centered">
      <!-- Confirm -->
      <div class="column is-half button--confirm">
        <button
          @click="$emit('confirm')"
          :class="['button', 'is-fullwidth', confirmButtonClass]"
          :style="{ maxWidth: 'unset' }"
        >{{ buttons.confirm.label }}</button>
      </div>

      <!-- Cancel -->
      <div class="column is-half button--decline">
        <button
          @click="$emit('cancel')"
          :class="['button', 'is-fullwidth', cancelButtonClass]"
          :style="{ maxWidth: 'unset' }"
        >{{ buttons.cancel.label }}</button>
      </div>
    </div>
    <!-- END | Buttons -->
  </b-modal>
  <!-- END | BaseModalConfirmation -->
</template>

<script>
export default {
	props: {
		message: {
			type: String,
			required: true,
		},

		buttons: {
			type: Object,
			required: true,
		},

		isActive: {
			type: Boolean,
			required: true,
		},
	},

	computed: {
		cancelButtonClass() {
			switch (this.buttons.cancel.type) {
				case 'info':
					return 'is-info';

				case 'danger':
					return 'is-danger';

				case 'success':
					return 'is-success';

				default:
					break;
			}
		},

		confirmButtonClass() {
			switch (this.buttons.confirm.type) {
				case 'info':
					return 'is-info';

				case 'danger':
					return 'is-danger';

				case 'success':
					return 'is-success';

				default:
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
