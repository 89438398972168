<template>
  <section class="admin-panel section">
    <div class="container is-trainer">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="card" v-for="(box, index) in management.Boxes" :key="index">
            <div class="card-content is-flex">
              <div class="column is-one-third content is-pulled-left has-text-left is-size-2 has-text-weight-light is-flex">
                <TelemarketerlIcon class="icon is-large telemarketer" />
                <h2 class="title is-12 has-text-weight-light has-text-left">{{ box.Name }}</h2>
              </div>
              <div class="column is-flex">
                <div v-for="(button, index) in box.Buttons" :key="index" class="is-flex">
                  <a :href="button.Url" class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold" v-if="button.IsExternalUrl">
                    <span class="icon is-small">
                      <svg width="17" height="20" viewBox="0 0 19 18">
                        <use :xlink:href="'/assets/2018/svg/buttons/' + button.Icon + '.svg#' + button.Icon"></use>
                      </svg>
                    </span>
                    <span>{{ button.Label }}</span>
                  </a>
                  <router-link class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold" :to="'/' + button.Url" v-else>
                     <span class="icon is-small">
                      <svg width="17" height="20" viewBox="0 0 19 18">
                        <use :xlink:href="'/assets/2018/svg/buttons/' + button.Icon + '.svg#' + button.Icon"></use>
                      </svg>
                    </span>
                    <span>{{ button.Label }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>     
        </div>
        <div class="column is-half" v-if="management.InfoList">
            <div class="card">
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <h2 class="title is-2 has-text-weight-light has-text-left">{{ management.InfoList.Title }}&emsp;<a :href="management.InfoList.ViewUrl.Url" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow">{{ management.InfoList.ViewUrl.Label }}</a></h2>
                      <div class="is-dotted is-block" v-for="(row, index) in management.InfoList.List" :key="index">
                        <span class="is-size-4 has-text-left has-text-weight-light">{{ row.Row1 }}</span>
                        <span class="is-size-2 has-text-primary has-text-left has-text-weight-light"><time :datetime="row.Row2">{{ row.Row2 }}</time></span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import FloatingLabel from "@/components/BaseFloatingLabel";
import BaseCardBackgroundImage from "@/components/BaseCardBackgroundImage.vue";
import StarRating from "vue-star-rating";
import TelemarketerlIcon from "@/assets/svg/telemarketer.svg";
import PersonSmallIcon from "@/assets/svg/person-small.svg";
import KeyIcon from "@/assets/svg/key.svg";
import StoreIcon from "@/assets/svg/store.svg";
import FoldedIcon from "@/assets/svg/single-folded.svg";
import PlusWhiteIcon from "@/assets/svg/circle-add.svg";
import PinSmallIcon from "@/assets/svg/pin-small.svg";
import global from "@/mixins/global.js";

export default {
  name: "AdminPanel",
  mixins: [global],
  components: {
    FloatingLabel,
    StarRating,
    BaseCardBackgroundImage,
    TelemarketerlIcon,
    PersonSmallIcon,
    KeyIcon,
    StoreIcon,
    FoldedIcon,
    PlusWhiteIcon,
    PinSmallIcon
  },
  props: {
    management: Object
  },
  mounted() {},
  data() {
    return {
      isAddWorkerModalActive: false,
      isAddShopModalActive: false,
      successMsg: "",
      formData: {
        employee: {
          ShopId: "",
          FirstName: "",
          LastName: "",
          Email: "",
          ShopStatus: "",
          ShopNo: "",
          WorkPositionId: "",
          Phone: ""
        },
        shop: {
          Franchise: "",
          ShopStatus: ""
        }
      }
    };
  },
  validations: {
    formData: {
      employee: {
        ShopId: {
          required
        },
        FirstName: {
          required
        },
        LastName: {
          required
        },
        Email: {
          required,
          email
        },
        ShopStatus: {
          required
        },
        ShopNo: {
          required
        },
        WorkPositionId: {
          required
        },
        Phone: {
          required
        }
      },
      shop: {
        Franchise: {
          required
        },
        ShopStatus: {
          required
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
.admin-panel {
  padding-top: 0;
  .is-trainer {
    & > div > :nth-child(2) {
      .card {
        height: 100%;
      }
    }
    .is-full {
      margin-top: rem(20px);
    }
    .telemarketer {
      margin-top: rem(37px);
      margin-bottom: rem(5px);
    }
    .button.is-info.is-outlined {
      color: rgba(58, 171, 89, 0.5);
      height: 50px;
      &:hover,
      &:focus {
        span {
          color: $white;
        }
      }
      span {
        color: $green-lighter;
      }
    }
    .icon {
      margin-top: 3px;
    }
    .card {
      .title {
        margin-bottom: rem(10px);
        a {
          float: right;
          margin-top: 10px;
        }
      }
      &-content {
        padding: rem(20px 30px);
        &.is-flex {
          padding: 0;
          & > :first-child {
            margin-bottom: 0;
            border-right: 1px solid $grey-lightest;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding-left: 20px;
            h2 {
              margin-top: 20px;
            }
            svg {
              width: 100%;
            }
          }
          & > :nth-child(2) {
            padding: rem(40px 30px);
            flex-wrap: wrap;
            align-items: center;
          }
        }
        .column > div {
          width: 100%;
          &:not(:last-child) {
            margin-bottom: rem(15px);
          }
        }
      }
      & + .card {
        margin-top: 25px;
      }
    }
    .is-dotted {
      margin-bottom: 0;
      span {
        &:first-child {
          margin-top: 14px;
        }
        & + span {
          margin-top: 10px;
        }
      }
      &:before {
        bottom: 10px;
      }
    }
  }
  .is-ca {
    .card {
      overflow: hidden;
      .columns {
        & > :first-child > div {
          background: $green-lighter;
          padding: rem(30px 20px);
        }
        & > :nth-child(2) > div {
          height: 100%;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          padding: rem(40px 30px);
        }
      }
      .is-dotted {
        width: 100%;
      }
    }
  }
  .modal {
    & > :nth-child(2) {
      width: 830px;
      padding: rem(40px);
      overflow: auto;
      @include widescreen {
        overflow: visible;
      }
    }
    .field {
      margin-bottom: rem(30px);
      .control {
        .input__container,
        .input-container {
          &.has-error {
            margin-bottom: 0;
          }
        }
      }
    }
    .button {
      color: rgba(58, 171, 89, 0.5);
      height: 56px;
      width: 100%;
      max-width: none;
      .icon {
        margin-top: 4px;
      }
    }
  }
  .button {
    border-radius: 5px;
    max-width: none;
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }
    span {
      color: white;
    }
  }
}
</style>
