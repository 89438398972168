<template>
  <article class="card is-flex" :class="{ 'bottom-image' : imagePosition === 'bottom' }" @click="$emit('goToUrlHandler')">
    <div class="card-image">
        <figure class="image is-3by1">
            <img v-lazy="image" :alt="title" :title="title">
            <!--<img src="/assets/2018/images/test.jpg" />-->
        </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content has-text-left">
          <div>
            <img :src="partnerLogo" :alt="title" :title="title" v-if="partnerLogo">
          </div>
          <h2 class="is-size-3 is-size-2-tablet">{{ title }}</h2>
          <p class="is-size-7 has-text-weight-light">{{ description }}</p>
          <RightArrowSmallIcon class="right-arrow-small-icon"/>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";

export default {
  name: "BaseCardTopImage",
  components: {
    RightArrowSmallIcon
  },
  props: {
    partnerLogo: {
      type: String
    },
    image: {
      type: String
    },
    imagePosition: {
      type: String
    },
    category: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

h2 {
  margin: rem(10px 0 15px);
  color: $black;
}

.card {
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  cursor: pointer;
  &.bottom-image {
    .card-image {
      order: 2;
      align-self: flex-end;
      img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .card-content {
      order: 1;
      img {
        border-radius: 0;
        max-height: 40px;
      }
    }
  }
  .is-3by1 {
    padding-top: 39.3333%;
    img {
       -o-object-fit: cover;
       object-fit: cover;
    }
  }
  &-image,
  &-content {
    width: 100%;
    .media-content {
      & > div {
        padding-right: 70%;
      }
    }
  }
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
    }
  }
}
</style>
