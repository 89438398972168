<template>
	<section id="ViewMyAccountData" class="ViewMyAccountData section">
		<div class="container">
			<!-- Heading -->
			<div class="columns is-mobile">
				<div class="column">
					<!-- Main title -->
					<h1
						class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow"
					>
						Mój profil
					</h1>
				</div>
			</div>
			<!-- END | Heading -->

			<NameBox
				ref="NameBox"
				:is-data-editable="isUserDataEditable"
				:user-data="user"
			/>

			<div class="columns is-multiline">
				<ShopsList v-if="isUser(['ShopManager', 'ShopOwner'])"
						   :user-id="user.Id" />
				<SubservientEmployees v-if="isUser(['ShopManager', 'ShopOwner', 'KOUser', 'RDOUser'])"
									  :user-id="user.Id" />
				<ScheduledWorkshops v-if="user.Id" :user-id="user.Id" />
				<CompletedElearnings v-if="user.Id" :user-id="user.Id" />
				<CompletedWorkshops v-if="user.Id" :user-id="user.Id" />
				<ImplementationProgramState v-if="user.Id" :user-id="user.Id" />
				<OnboardingState v-if="user.Id" :user-id="user.Id" />
				<Agreements />
				<PasswordChange ref="passwordChange"
								v-if="user.Id"
								:user-id="user.Id"
								@submit="changePassword($event)" />
			</div>
		</div>

		<RemovalModal
			:is-active="removalModal.isActive"
			:subject="removalModal.subject"
			:is-loading="removalModal.isLoading"
			:messages="removalModal.messages"
			@close="removalModal.isActive = false"
			@close-modal="removalModal.isActive = false"
			@confirm-deletion="deleteUser()"
		/>
	</section>
</template>

<script>
// JS modules
import $ from 'jquery';
import { mapState } from 'vuex';

// Vue components
import NameBox from '@/components/Containers/ViewMyAccountData/cards/NameBox.vue';
import ActionsDropdown from '@/components/Containers/ViewMyAccountData/ActionsDropdown.vue';
import RemovalModal from '@/components/Containers/ViewMyAccountData/RemovalModal.vue';
import ScheduledWorkshops from '@/components/Containers/ViewMyAccountData/tables/ScheduledWorkshops.vue';
import CompletedWorkshops from '@/components/Containers/ViewMyAccountData/tables/CompletedWorkshops.vue';
import CompletedElearnings from '@/components/Containers/ViewMyAccountData/tables/CompletedElearnings.vue';
import ImplementationProgramState from '@/components/Containers/ViewImplementationProgram/ImplementationProgramState.vue';
import OnboardingState from '@/components/Containers/ViewOnboarding/OnboardingState.vue';
import ShopsList from '@/components/Containers/ViewMyAccountData/tables/ShopsList.vue';
import SubservientEmployees from '@/components/Containers/ViewMyAccountData/tables/SubservientEmployees.vue';
import Agreements from '@/components/Containers/ViewMyAccountData/tables/Agreements.vue';
import PasswordChange from '@/components/Containers/ViewMyAccountData/tables/PasswordChange.vue';

export default {
	name: 'ViewMyAccountData',

	components: {
		NameBox,
		ActionsDropdown,
		RemovalModal,
		ScheduledWorkshops,
		CompletedWorkshops,
        CompletedElearnings,
		ImplementationProgramState,
        OnboardingState,
		ShopsList,
		SubservientEmployees,
		Agreements,
		PasswordChange,
	},

	data() {
		return {
			secondaryActionButton: {
				isVisible: false,
				text: '',
				currentProcess: '',
			},

			isUserDataEditable: false,

			removalModal: {
				isActive: false,
				subject: 'user',
				isLoading: '',
			},
		};
	},

	beforeMount() {
		this.$store.dispatch('currentUser/fetchData');
	},

	activated() {
		this.$store.dispatch('currentUser/fetchData');
	},

	methods: {
		/**
		 * @description This function will allow the edition of the
		 * user data present in the <NameBox /> component.
		 * This is a 1st of the actions available in the <ActionsDropdown /> component.
		 *
		 * @returns {Void}
		 */
		allowUserDataEdition() {
			this.isUserDataEditable = true;
			this.secondaryActionButton.isVisible = true;
			this.secondaryActionButton.text = 'Zapisz';
			this.secondaryActionButton.currentProcess = 'edition';
		},

		/**
		 * @description This function will scroll the view to the <PasswordChange/> component (box).
		 * This is a 2nd of the actions available in the <ActionsDropdown /> component.
		 *
		 * @returns {Void}
		 */
		goToPasswordChange() {
			$([document.documentElement, document.body]).animate(
				{
					scrollTop: $('#PasswordChangeBox').offset().top - 200,
				},
				2000
			);
		},

		/**
		 * @description This function will save the user data
		 * (present in the <NameBox /> component) in its current state.
		 * @param {String} userId The ID of a user that should be removed.
		 *
		 * @returns {Void}
		 */
		saveUserData() {
			this.secondaryActionButton.isVisible = false;
			this.isUserDataEditable = false;
			this.$refs.NameBox.saveUserData();
		},

		/**
		 * @description This function will handle the secondary action button click
		 * and perform the action based on the currently active process
		 * (defined within this.secondaryActionButton.currentProcess).
		 * @returns {Void}
		 */
		handleSecondaryActionButtonClick() {
			switch (this.secondaryActionButton.currentProcess) {
				// The case where the active process is user data edition.
				case 'edition':
					// In this scenario, the button click will invoke the function
					// that will save the current data state.

					this.saveUserData();
					break;

				default:
					break;
			}
		},

		/**
		 * @description This function will open the modal dialog
		 * asking User to confirm the selected user removal.
		 * @param {String} subject The subject of removal. Could be "user" or "shop".
		 * @returns {Void}
		 */
		openModal(subject) {
			this.removalModal.subject = subject;
			this.removalModal.isActive = true;
		},

		/**
		 * @description This method will tell you if the user is part of a given users group.
		 * @param {String|Array} roles The role(s) to check the user against.
		 * @returns {Boolean}
		 */
		isUser(roles) {
			if (this.user.Roles) {
				if (typeof roles === 'string') {
					return this.user.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.user.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},

		changePassword(formData) {
			this.$store
				.dispatch('currentUser/changePassword', formData)
				.then(() => {
					this.$refs.passwordChange.onSuccess();
				})
				.catch(() => {
					this.$refs.passwordChange.onError();
				});
		},
	},

	computed: {
		...mapState('currentUser', {
			user: state => state.data,
		}),
	},
};
</script>

<style lang="scss">
@import 'sass-rem';
@import '../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Style the tables.
.card-table {
	.card {
		&-header {
			padding: 10px 30px;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
		}

		&-toggler {
			background: transparent;
			border: 2px solid white;
			border-radius: 5px;
			color: white;
			padding: 5px 15px;
			margin: auto;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;

			&:hover {
				cursor: pointer;
			}

			// Create the chevron icon.
			.chevron {
				position: relative;
				width: 25px;
				margin-left: 15px;

				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					background-color: white;
					width: 15px;
					height: 2px;
					border-radius: 1px;
					transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
				}

				&::before {
					right: 44%;
					transform: rotate(30deg);
				}

				&::after {
					left: 44%;
					transform: rotate(-30deg);
				}

				// Animate the chevron on click.
				&.active {
					&::before {
						transform: rotate(-30deg);
					}

					&::after {
						transform: rotate(30deg);
					}
				}
			}
		}
	}
}
</style>
