<template>
	<section class="elearnings section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu">
						<b-collapse
							:open.sync="filterType.isOpen"
							v-for="(filterType, key) in filtersList"
							:key="key"
						>
							<p
								class="menu-label is-size-7 has-text-black has-border-bottom"
								slot="trigger"
								:class="{ 'is-active': filterType.isOpen }"
							>
								{{ filterType.Title }}
							</p>
							<div class="navbar-content">
								<ul class="menu-list">
									<li class="is-flex"
										:class="{'is-selected': filters[filterType.Key] === filter.Key,}"
										v-for="filter in filterType.Options"
										:key="filter.Key">
										<!-- FIXME: The below v-if statement is only temporary and SHOULD be replaced with more versatile solution -->
										<!--
                    This v-if statement was placed here to complete the EC-299 task
                    (https://havaswarsawdigital.atlassian.net/browse/EC-299).
                    The 'Kursy multimedialne' category should not be visible
                    and it's impossible to remove it from the Umbraco CMS,
                    so unfortunately, this had to be done here, on front-end.
                  -->
										<template v-if="filter.Value.toLowerCase() !== 'kursy multimedialne'">
											<div v-if="filter.Value.toLowerCase().indexOf('kursy ogólne/') == -1">
												<button class="delete is-small"
														@click="clearFiltersHandler(
															filterType.Key,
															loadElearningsHandler)" />
												<div style="display: flex">
													<span v-if="filter.Value.toLowerCase().indexOf('kursy ogólne/') == -1 && filter.Value.toLowerCase().indexOf('kursy ogólne') !== -1"
														  @click="isSubcategoryOpen = !isSubcategoryOpen" v-html="decodeURIComponent(isSubcategoryOpen ? '&#9660;' : '&#9660;')"
														  style="cursor: pointer; margin-top: 4px; margin-right: 10px;">
														

													</span>
													<a @click="(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																loadElearningsHandler()"
														class="is-size-7">

														{{filter.Value }}
													</a>
												</div>
												<ul v-if="isSubcategoryOpen && filter.Value.toLowerCase().indexOf('kursy ogólne/') == -1 && filter.Value.toLowerCase().indexOf('kursy ogólne') !== -1"
													style="margin-top:0">

													<li class="is-flex"
														:class="{'is-selected': filters[filterType.Key] === filter1.Key,
																'not-selected': filters[filterType.Key] !== filter1.Key}"
														v-for="filter1 in filterType.Options.filter(x => (Network.toLowerCase().indexOf('eurocash') !== -1 || Network.toLowerCase().indexOf('cashandcarry') !== -1 || Network.toLowerCase().indexOf('detal+') !== -1) ?
																x.Value.toLowerCase().indexOf('kursy ogólne/') !== -1 : x.Value.toLowerCase().indexOf('dedykowane dla sklepu') !== -1)"
														:key="filter1.Key">

														<a @click="filters[filterType.Key] = filter1.Key,
																	(filters.Page = 0),
																	loadElearningsHandler()"
														   class="is-size-8">

															- {{filter1.Value.substring(13)}}
														</a>
														<button class="delete is-small"
																@click="clearFiltersHandler(
																	filterType.Key,
																	loadElearningsHandler)" />
													</li>
												</ul>
											</div>
										</template>
									</li>
								</ul>
							</div>
						</b-collapse>
					</aside>
				</div>
				<div class="column has-text-centered">
					<div
						class="tile is-ancestor"
						v-for="(item, index) in elearnings"
						v-if="index % 3 === 0"
						:key="index"
					>
						<BaseTile
							@goToUrlHandler="
								goToUrlHandler('/kursy-e-learningowe/' + item.Id)
							"
							v-for="item in elearnings.slice(index, index + 3)"
							:key="item.Id"
							:image="item.Image"
							:category="item.CategoryName"
							:title="item.Title"
							:col="4"
						/>
					</div>
					<h2
						class="title is-2 has-text-weight-light has-text-left"
						v-if="!elearnings.length"
					>
						{{
							$parent.search
								? 'Nie znaleziono wyników dla ' + $parent.search
								: 'Brak wyników'
						}}<a
							href="#"
							class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow"
							@click.prevent="
								$emit('update:search', '');
								clearFiltersHandler(false, loadElearningsHandler);
							"
							>Resetuj wyszukiwanie</a
						>
					</h2>
					<a
						class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold"
						@click="
							filters.Page++;
							loadElearningsHandler();
						"
						v-if="nextPage"
						>Załaduj więcej treści</a
					>
				</div>
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li>
							<b-collapse
								:open.sync="filterType.isOpen"
								v-for="(filterType, key) in filtersList"
								:key="key"
							>
								<span
									class="has-arrow is-flex"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									<span
										class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
										>{{ filterType.Title }}</span
									>
								</span>
								<div>
									<div class="navbar-content">
										<ul>
											<li class="is-flex has-text-left"
												:class="{'is-selected': filters[filterType.Key] === filter.Key}"
												v-for="filter in filterType.Options"
												:key="filter.Key">

												<div v-if="filter.Value.indexOf('Kursy ogólne/') == -1">
													<a @click="(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																loadElearningsHandler()">
														{{ filter.Value }}
													</a>
													<p>check</p>
													<button
														class="delete is-small"
														@click="clearFiltersHandler(
																filterType.Key,
																loadElearningsHandler)" />
												</div>
											</li>
										</ul>
									</div>
								</div>
							</b-collapse>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
	</section>
</template>

<script>
import BaseTile from '@/components/BaseTile.vue';
import StarRating from 'vue-star-rating';
import global from '@/mixins/global.js';

export default {
	name: 'Elearnings',
	mixins: [global],
	components: {
		StarRating,
		BaseTile,
	},
	props: {
		search: {
			type: String,
		},
	},
	mounted() {},
	data() {
		return {
			elearnings: [],
			filtersList: {},
			filters: {
				Page: 0,
			},
			nextPage: true,
			Network: "",
			isSubcategoryOpen: false
		};
	},
	methods: {
		loadElearningsHandler() {
			let self = this;
			self.$http
				.post(`spa/elearning/list`, self.filters)
				.then(response => {
					if (self.filters.Page) {
						self.elearnings = [...self.elearnings, ...response.data.Data];
						self.nextPage = response.data.NextPage;
					} else {
						self.elearnings = response.data.Data;
						self.nextPage = response.data.NextPage;
					}
				})

				/* FIXME: The below use of Array.filter() is only temporary and SHOULD be replaced with more versatile solution.
										
					This Array.filter() was placed here to complete the EC-316 task
					(https://havaswarsawdigital.atlassian.net/browse/EC-316).
					The 'Kursy multimedialne' category should not be visible
					and it's impossible to remove it from the Umbraco CMS,
					so unfortunately, this had to be done here, on front-end.
				*/
				.finally(() => {
					this.elearnings = this.elearnings.filter(
						elearning => elearning.CategoryName !== 'Kursy multimedialne'
					);
				});
		},
		loadElearningsFiltersHandler() {
			let self = this;
			self.$http
				.get(`spa/elearning/filters`)
				.then(response => {
					this.filtersList = response.data;
					this.filtersList.forEach(function(item) {
						self.$set(item, 'isOpen', false);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},
	},
	computed: {
		getIcon() {
			return decodeURIComponent(this.isSubcategoryOpen ? '&#9660;' : '&#9660;');
		},
	},
	watch: {
		search: function() {
			this.filters.Query = this.search;
			this.filters.Page = 0;
			this.loadElearningsHandler();
		},
		},
	created: function() {
		this.loadElearningsHandler();
		this.loadElearningsFiltersHandler();
        let self = this;
        self.$http
            .get(`spa/user/profile`)
            .then(response => {
                this.Network = response.data.Network;
            })
            .catch(() => { });
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.elearnings {
	padding-bottom: rem(60px);
	.title {
		a {
			margin-left: 15px;
		}
	}
	.title {
		margin-left: rem(30px);
		padding-top: rem(10px);
		padding-bottom: rem(10px);
	}
	.button.is-light.is-outlined {
		padding: 25px 50px;
		border-color: $grey-lighter;
		&:hover,
		&:focus {
			background: transparent;
			border-color: $grey-light;
		}
	}
	.columns {
		.menu {
			text-align: left;
			a {
				padding-left: 0;
				width: 100%;
			}
			&-list {
				li {
					justify-content: space-between;
				}
				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
		}
	}
	li {
		letter-spacing: 1px;
		a {
			padding-right: 5px;
		}
	}
	.delete {
		display: none;
		align-self: center;
	}
	.menu-label {
		.delete {
			display: inline-block;
		}
	}
	.is-selected {
		font-weight: bold;
		.delete {
			float: right;
			display: block;
			background-color: #bebebe;
		}
		.delete:before,
		.delete:after {
			background-color: white;
		}
	}
	.not-selected {
		font-weight: normal;
	}
}
</style>
