<template>
  <main>  
	<OnboardingTasks :search.sync="search" :showFilters.sync="showFilters" class="top"/>
  </main>
</template>

<script>
import OnboardingTasks from "@/components/Containers/ViewOnboardingTasks/OnboardingTasks.vue";

export default {
	name: "ViewOnboardingTasks",

	components: {
		OnboardingTasks
	},

	data() {
		return {
			search: "",
			showFilters: false
		};
	}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.top {
	margin-top:90px;
}
</style>
