<template>
  <article class="card" @click="$emit('goToUrlHandler')">
    <div class="columns-wrap">
      <div class="mov-prev-col">
        <div class="movie-screen">
          <img v-lazy="image" :alt="title" :title="title" />
          <PlayBtn class="play-icon" />
        </div>
      </div>
      <div class="movie card-content">
        <p class="is-size-8 has-text-left has-text-grey-light-hover cat-name">
          <PuzzleIcon class="puzzle-icon has-text-left" />
          {{ category }}
        </p>
        <h2 class="is-size-3 is-size-2-tablet">{{ title }}</h2>
        <p
          class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc"
          v-text="descriptionLimited"
        ></p>
        <p
          class="is-uppercase has-text-weight-bold has-text-left has-text-primary-hover find_out_header"
        >Obejrzyj film i dowiedz się:</p>
        <div class="find_out">
          <ul class="has-text-black-hover">
            <li v-for="item in column1" :key="item">{{ item }}</li>
          </ul>
          <ul>
            <li v-for="item in column2" :key="item">{{ item }}</li>
          </ul>
        </div>
        <RightArrowSmallIcon class="right-arrow-small-icon" />
      </div>
    </div>
  </article>
</template>

<script>
import PuzzleIcon from '@/assets/svg/puzzle.svg';
import PlayBtn from '@/assets/svg/play-border.svg';
import RightArrowSmallIcon from '@/assets/svg/right-arrow-small.svg';

export default {
	name: 'BaseCardMovie',
	components: {
		PuzzleIcon,
		PlayBtn,
		RightArrowSmallIcon,
	},
	props: {
		partnerLogo: {
			type: String,
		},
		image: {
			type: String,
		},
		imagePosition: {
			type: String,
		},
		category: {
			type: String,
		},
		title: {
			type: String,
		},
		description: {
			type: String,
		},
		column1: {
			type: Array,
		},
		column2: {
			type: Array,
		},
	},
	computed: {
		descriptionLimited() {
			if (this.description.length > 180) {
				return this.description.substring(0, 178) + '...';
			} else {
				return this.description;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.columns-wrap {
	display: block;
	@include desktop {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

.mov-prev-col {
	display: block;
	max-width: 100%;
	@include desktop {
		-ms-flex: 0 0 35%;
		flex: 0 0 35%;
		max-width: 35%;
	}

	.movie-screen {
		position: relative;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			border-top-right-radius: 0px;
			-o-object-fit: cover;
			object-fit: cover;
		}
		.play-icon {
			width: 83px;
			height: 83px;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}

.movie.card-content {
	padding: 1.5rem 3rem 1.5rem 1.9rem;

	@include widescreen {
		padding: 1.5rem 5rem 1.5rem 1.9rem;
	}

	h2 {
		margin: 0.625rem 0 0.625rem;
	}

	.cat-name {
		text-transform: uppercase;
		margin-bottom: 1.7em;
		font-weight: 600;
		svg {
			margin-right: 10px;
			position: relative;
			top: 1px;
		}
	}
	.short-desc {
		margin-bottom: 1.25rem;
	}
	.right-arrow-small-icon {
		margin-top: -10px;
	}
	.find_out_header {
		font-size: 0.7rem;
		margin-bottom: 0.5rem;
		font-weight: 700;
	}
	.find_out {
		display: block;
		font-size: 0.65rem;

		@include tablet {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin: 0 -10px;
		}

		> ul {
			max-width: 100%;
			@include tablet {
				-ms-flex: 0 0 50%;
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 10px;
				li {
					position: relative;
					padding-left: 18px;
					margin-bottom: 5px;
					&::before {
						content: '';
						position: absolute;
						left: 0;
						top: 2px;
						width: 12px;
						height: 12px;
						background-image: url('/assets/2018/svg/plus.svg');
						background-size: 12px 12px;
					}
				}
			}
		}
	}
}

.card {
	height: 100%;
	flex-wrap: wrap;
	overflow: hidden;
	cursor: pointer;
	&.bottom-image {
		.card-image {
			order: 2;
			align-self: flex-end;
			img {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		.card-content {
			order: 1;
			img {
				border-radius: 0;
				max-height: 40px;
			}
		}
	}
	.is-3by1 {
		padding-top: 39.3333%;
	}
	&-image,
	&-content {
		width: 100%;
		.media-content {
			& > div {
				padding-right: 70%;
			}
		}
	}
	&:hover,
	&:focus {
		background: $green-lighter;
		transition: background 0.3s ease;
		.card-content {
			h2,
			p {
				color: $white;
			}
			.right-arrow-small-icon {
				color: $white;
			}
			ul {
				color: $white;
			}
		}
	}
}
</style>
