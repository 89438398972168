<template>
  <section class="partners section" id="first-partner">
    <div class="container">
      <div class="columns">
        <div class="column is-full">
          <article class="card" v-for="partner in partners" :key="partner.Id">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <div class="columns">
                    <div class="column is-one-fifth is-paddingless">
                      <figure class="image is-flex">
                        <img
                          v-lazy="partner.Logo"
                          :alt="partner.PartnerName"
                          :title="partner.PartnerName"
                        />
                      </figure>
                    </div>
                    <div class="column">
                      <h2
                        class="is-size-2 has-text-primary has-text-weight-bold"
                      >{{ partner.PartnerName }}</h2>
                      <p
                        class="is-size-7 has-text-weight-light has-text-left"
                        v-html="partner.ShortDescription"
                      ></p>
                      <b-collapse :open="false" :aria-id="partner.Id">
                        <div
                          class="is-size-7 has-text-weight-light has-text-left"
                          v-html="partner.HiddenDescription"
                        ></div>
                        <svg
                          width="9"
                          height="22"
                          viewBox="0 0 9 22"
                          class="collapse-trigger"
                          slot-scope="props"
                          v-bind:class="props.open ? 'active' : 'non'"
                          slot="trigger"
                          :aria-controls="partner.Id"
                        >
                          <path
                            fill="none"
                            fill-rule="evenodd"
                            stroke="#111111"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.4"
                            d="M.75.688L8.25 11 .75 21.313"
                            id="right-arrow-small-icon"
                          />
                        </svg>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Partners",
  components: {},
  data() {
    return {
      partners: []
    };
  },
  methods: {
    loadPartners() {
      let self = this;
      self.$http
        .get(`spa/partners/getlist`)
        .then(response => {
          self.partners = response.data.Data;
        })
        .catch(() => {});
    }
  },
  created: function() {
    this.loadPartners();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.partners.section {
  .collapse-trigger {
    transform: rotate(90deg);
    transition: 0.5s all ease-in-out;

    &.active {
      transform: rotate(-90deg);
    }
  }
}
</style>
