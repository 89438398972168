<template>
	<div class="column is-full is-flex has-text-left">
		<div class="card-table card-table-shop">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<StoreIcon />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Lista sklepów
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						v-if="expandAvailable"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->
			</div>
			<table class="table">
				<thead class="is-hidden-mobile">
					<tr
						class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
					>
						<th class="has-text-grey-light">Kod Sklepu</th>
						<th class="has-text-grey-light">Nazwa sklepu</th>
						<th class="has-text-grey-light">Ulica</th>
						<th class="has-text-grey-light">Miasto</th>
						<th class="has-text-grey-light">Województwo</th>
						<th
							v-if="showActions || showEmployeesListLink"
							class="has-text-grey-light"
						>
							Zarządzaj
						</th>
					</tr>
					<tr v-if="isLoading">
						<th
							:colspan="showActions || showEmployeesListLink ? 6 : 5"
							class="is-progress-bar-table"
						>
							<progress class="progress is-primary" max="100"></progress>
						</th>
					</tr>
				</thead>

				<tbody v-if="shops.length">
					<tr
						v-for="shop in expanded ? shops : shops.slice(0, 5)"
						:key="shop.Id"
					>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Kod Sklepu</span
								>
								<span>{{ shop.ShopNumber }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Nazwa sklepu</span
								>
								<span>{{ shop.Name }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Ulica</span
								>
								<span>{{ shop.Address }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Miasto</span
								>
								<span>{{ shop.City }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Województwo</span
								>
								<span>{{ shop.Province }}</span>
							</div>
						</td>
						<td v-if="showEmployeesListLink" class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Zarządzaj</span
								>
								<router-link
									:to="{
										name: 'Lista pracowników',
										params: { shopNumber: shop.ShopNumber },
									}"
									:style="{ textDecoration: 'none' }"
									class="button is-text has-text-weight-semibold has-text-left has-text-primary"
									>Lista pracowników ></router-link
								>
							</div>
						</td>
						<td v-if="showActions" class="has-text-black">
							<div
								class="is-dotted is-block has-text-centered-tablet"
								@click="$emit('toggle-shop-modal', shop.Id)"
							>
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>Zarządzaj</span
								>
								<b-dropdown class="is-right" aria-role="list">
									<button class="button is-info" slot="trigger">
										<span>Szczegóły</span>
										<b-icon icon="menu-down"></b-icon>
									</button>

									<b-dropdown-item class="is-uppercase" aria-role="listitem"
										>Edytuj dane</b-dropdown-item
									>
									<b-dropdown-item class="is-uppercase" aria-role="listitem">
										<router-link
											:to="{
												name: 'Lista pracowników',
												params: { shopNumber: shop.ShopNumber },
											}"
											:style="{ textDecoration: 'none' }"
											class="button is-text has-text-weight-semibold has-text-left has-text-primary"
											>Lista pracowników</router-link
										>
									</b-dropdown-item>
									<b-dropdown-item class="is-uppercase" aria-role="listitem"
										>Usuń sklep</b-dropdown-item
									>
								</b-dropdown>
							</div>
						</td>
					</tr>
				</tbody>

				<tbody v-else-if="!shops.length && !isLoading">
					<tr>
						<td colspan="6" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';

// Vue components
import StoreIcon from '@/assets/svg/store-outline.svg';

export default {
	components: {
		StoreIcon,
	},

	props: {
		userId: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			isLoading: true,
			expanded: false,
		};
	},

	computed: {
		...mapState('currentUser', {
			shops: state => state.shops,
			currentUser: state => state.data,
		}),

		/**
		 * @description Indicates whether the table is expandable.
		 * @returns {Boolean}
		 */
		expandAvailable() {
			return this.shops ? this.shops.length > 5 : false;
		},

		/**
		 * @description This property will tell you whether
		 * the actions dropdown is to be shown.
		 * @returns {Boolean}
		 */
		showActions() {
			return this.userId !== this.currentUser.Id;
		},

		showEmployeesListLink() {
			return (
				this.isCurrentUser(['ShopOwner', 'ShopManager']) &&
				this.userId == this.currentUser.Id
			);
		},
	},

	methods: {
		loadData() {
			this.isLoading = true;

			this.$store
				.dispatch('currentUser/fetchShops', this.userId)
				.finally(() => {
					this.isLoading = false;
				});
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.expanded = false;
		},

		isCurrentUser(roles) {
			if (this.currentUser.Roles) {
				if (typeof roles === 'string') {
					return this.currentUser.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.currentUser.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},
	},

	beforeMount() {
		this.loadData();
	},

	activated() {
		this.loadData();
	},

	watch: {
		userId() {
			this.loadData();
		},
	},
};
</script>

<style lang="scss">
@import '../../../../assets/scss/_variables.scss';

.card-table-shop {
	.dropdown {
		span {
			background: none;
			margin-top: 0;

			&.icon {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 5px 0 5px;
				border-color: white transparent transparent transparent;
			}
		}

		&-menu,
		&-content {
			padding: 0;
		}

		&-content {
			background-color: white !important;
			border: 2px solid $green-lighter;
			border-radius: 4px;
		}

		&-item {
			text-align: center;
			padding: 5px 10px;
			&:not(:last-child) {
				border-bottom: 2px solid $green-lighter;
			}
		}
	}
}

// Enable the dropdown opening.
@media (min-width: 920px) {
	.card-table-shop {
		&,
		.is-dotted {
			overflow: visible !important;
		}
	}
}
</style>
