<template>
	<form @submit.prevent="submitFormHandler">
		<template v-if="!hasManagerBeenCreated && !isEdit">
			<p class="has-text-centered">Wybierz istniejący sklep:</p>
			<br />

			<div class="field">
				<div class="control">
					<div class="input-container">
						<div>
							<v-selector
								:max-height="'300px'"
								v-model="existingShopNumber"
								@search="searchShopHandler"
								:options="shopsList"
								placeholder="Kod sklepu"
								label="Name"
								:disabled="isEdit || didProvideAnyData"
								@input="onExistingShopSelect($event)"
							>
								<span slot="no-options" class="has-text-left"
									>Brak wyników spełniających kryteria wyszukiwania</span
								>
							</v-selector>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<p class="has-text-centered">lub utwórz nowy:</p>
			<br />
		</template>

		<div class="field">
			<div class="control">
				<div
					class="input-container"
					:class="{ 'has-error': $v.formData.Network.$error }"
				>
					<div>
						<v-selector
							:max-height="'300px'"
							:disabled="isEdit || didChooseExistingShop"
							label="Value"
							v-model="formData.Network"
							:options="networkList"
							placeholder="Wybierz sieć"
							@input="onNewDataInput($event, 'select')"
							class="form-select"
						>
							<span slot="no-options"
								>Brak wyników spełniających kryteria wyszukiwania</span
							>
						</v-selector>
					</div>
				</div>
				<span
					class="is-size-8 has-text-weight-light has-text-danger"
					v-for="(error, index) in validationMessages($v.formData.Network)"
					:key="index"
					>{{ error }}</span
				>
			</div>
		</div>

		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Kod sklepu',
						height: 56,
						hasContent: formData.NewShopNumber ? true : false,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
					}"
				>
					<input
						v-model="formData.NewShopNumber"
						type="text"
						class="input"
						required
						:disabled="didChooseExistingShop"
						@input="onNewDataInput($event, 'text')"
					/>
				</FloatingLabel>
			</div>
		</div>

		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Nazwa sklepu',
						height: 56,
						hasContent: formData.Name ? true : false,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: $v.formData.Name.$error,
					}"
				>
					<input
						v-model="formData.Name"
						@blur="$v.formData.Name.$touch()"
						type="text"
						class="input"
						:disabled="didChooseExistingShop"
						required
						@input="onNewDataInput($event, 'text')"
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.Name)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>

		<div class="field">
			<div class="control">
				<div
					class="input-container"
					:class="{ 'has-error': $v.formData.Province.$error }"
				>
					<div>
						<v-selector
							:max-height="'300px'"
							v-model="formData.Province"
							:options="provinceList"
							placeholder="Województwo"
							:disabled="didChooseExistingShop"
							label="Value"
							@input="onNewDataInput($event, 'select')"
							class="form-select"
						>
							<span slot="no-options" class="has-text-left"
								>Brak wyników spełniających kryteria wyszukiwania</span
							>
						</v-selector>
					</div>
				</div>
				<span
					class="is-size-8 has-text-weight-light has-text-danger"
					v-for="(error, index) in validationMessages($v.formData.Province)"
					:key="index"
					>{{ error }}</span
				>
			</div>
		</div>

		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Miasto',
						height: 56,
						hasContent: formData.City ? true : false,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: $v.formData.City.$error,
					}"
				>
					<input
						v-model="formData.City"
						@blur="$v.formData.City.$touch()"
						type="text"
						class="input"
						:disabled="didChooseExistingShop"
						required
						@input="onNewDataInput($event, 'text')"
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.City)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>

		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Ulica',
						height: 56,
						hasContent: formData.Street ? true : false,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: $v.formData.Street.$error,
					}"
				>
					<input
						v-model="formData.Street"
						@blur="$v.formData.Street.$touch()"
						type="text"
						class="input"
						:disabled="didChooseExistingShop"
						required
						@input="onNewDataInput($event, 'text')"
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages($v.formData.Street)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>
		<div class="columns">
			<div class="column">
				<div class="field">
					<div class="control">
						<FloatingLabel
							:config="{
								label: 'Numer domu',
								height: 56,
								hasContent: formData.BuildingNumber ? true : false,
								hasClearButton: false,
								line: false,
								color: {
									focusColor: '#3AAB59',
									errorColor: '#de2647',
									lineColor: '#b5b5b5',
									blurredColor: '#b5b5b5',
								},
								hasError: $v.formData.BuildingNumber.$error,
							}"
						>
							<input
								v-model="formData.BuildingNumber"
								@blur="$v.formData.BuildingNumber.$touch()"
								type="text"
								class="input"
								:disabled="didChooseExistingShop"
								required
								@input="onNewDataInput($event, 'text')"
							/>
							<span
								class="is-size-8 has-text-weight-light has-text-danger"
								v-for="(error, index) in validationMessages(
									$v.formData.BuildingNumber
								)"
								:key="index"
								>{{ error }}</span
							>
						</FloatingLabel>
					</div>
				</div>
			</div>
			<div class="column">
				<div class="field">
					<div class="control">
						<FloatingLabel
							:config="{
								label: 'Kod pocztowy',
								height: 56,
								hasContent: formData.PostalCode ? true : false,
								hasClearButton: false,
								line: false,
								color: {
									focusColor: '#3AAB59',
									errorColor: '#de2647',
									lineColor: '#b5b5b5',
									blurredColor: '#b5b5b5',
								},
								hasError: $v.formData.PostalCode.$error,
							}"
						>
							<the-mask
								:mask="['##-###']"
								:masked="true"
								v-model="formData.PostalCode"
								@blur="$v.formData.PostalCode.$touch()"
								type="text"
								class="input"
								:disabled="didChooseExistingShop"
								required
								@input="onNewDataInput($event, 'text')"
							/>
							<span
								class="is-size-8 has-text-weight-light has-text-danger"
								v-for="(error, index) in validationMessages(
									$v.formData.PostalCode
								)"
								:key="index"
								>{{ error }}</span
							>
						</FloatingLabel>
					</div>
				</div>
			</div>
		</div>
		<div class="field">
			<div class="control">
				<FloatingLabel
					:config="{
						label: 'Numer telefonu',
						height: 56,
						hasContent: formData.PhoneNumber ? true : false,
						hasClearButton: false,
						line: false,
						color: {
							focusColor: '#3AAB59',
							errorColor: '#de2647',
							lineColor: '#b5b5b5',
							blurredColor: '#b5b5b5',
						},
						hasError: $v.formData.PhoneNumber.$error,
					}"
				>
					<input
						v-model="formData.PhoneNumber"
						@blur="$v.formData.PhoneNumber.$touch()"
						type="text"
						class="input"
						:disabled="didChooseExistingShop"
						required
						@input="onNewDataInput($event, 'text')"
					/>
					<span
						class="is-size-8 has-text-weight-light has-text-danger"
						v-for="(error, index) in validationMessages(
							$v.formData.PhoneNumber
						)"
						:key="index"
						>{{ error }}</span
					>
				</FloatingLabel>
			</div>
		</div>
	</form>
</template>

<script>
/* eslint-disable */
import { debounce } from 'lodash';
import FloatingLabel from '@/components/BaseFloatingLabel';
import global from '@/mixins/global.js';
import { required, requiredIf, email, numeric } from 'vuelidate/lib/validators';

import ShopService from '@/services/ShopService.js';

export default {
	props: [
		'clickedNext',
		'currentStep',
		'submitStatus',
		'isEdit',
		'hasManagerBeenCreated',
	],
	mixins: [global],
	components: {
		FloatingLabel,
	},
	data() {
		return {
			networkList: [],
			shopsList: [],
			provinceList: [],

			statusList: [
				{ Key: 'Aktywny', Value: 1 },
				{ Key: 'Nieaktywny', Value: 2 },
			],

			search: '',

			formData: {
				Network: '',
				NewShopNumber: '',
				Name: '',
				Province: '',
				City: '',
				Street: '',
				BuildingNumber: '',
				PostalCode: '',
				PhoneNumber: '',
			},

			existingShopNumber: '',

			didChooseExistingShop: false,
			didProvideAnyData: false,
		};
	},

	validations: {
		formData: {
			Network: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			Name: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			Province: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			City: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			Street: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			BuildingNumber: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			PostalCode: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
			PhoneNumber: {
				numeric,
			},
			NewShopNumber: {
				required: requiredIf(function () {
					return this.didChooseExistingShop ? false : true;
				}),
			},
		},

		existingShopNumber: {
			required: requiredIf(function () {
				return this.didProvideAnyData ? false : true;
			}),
		},
	},

	methods: {
		onExistingShopSelect(event) {
			// If the select is cleared, the event is not being passed
			// so the below ternary operator returns false.
			if (event) {
				ShopService.getShopDetails(event.Id).then((response) => {
					const shopDetails = response.data;

					this.$store.state.shop.existingShop = {
						...shopDetails,
						Id: event.Id,
						PostalCode: `${shopDetails.PostalCode1}-${shopDetails.PostalCode2}`,
						Province: shopDetails.ProvinceId,
						ShopNumber: shopDetails.ShopNo,
						Street: shopDetails.Address,
						StatusId: shopDetails.ShopStatus,
					};

					this.didChooseExistingShop = true;
					this.$emit('existing-shop-select');
				});
			} else {
				this.didChooseExistingShop = false;
			}
		},

		onNewDataInput(event, inputType) {
			switch (inputType) {
				case 'select':
					if (event) {
						this.didProvideAnyData = true;
						this.$emit('new-data-input');
					}
					break;

				case 'text':
					if (event.data) {
						this.didProvideAnyData = true;
						this.$emit('new-data-input');
					}
					break;

				default:
					break;
			}

			// This timeout here is neccessary, because the span.selected-tag that the hasFormAnyData() method leans on
			// is not created at the very moment of evaluationg the onNewDataInput() method. 200 ms seems as a good timeout
			// that does not assume fast computer and should at once not irritate the user.
			setTimeout(() => {
				if (!this.hasFormAnyData()) {
					this.didProvideAnyData = false;
				}
			}, 200);
		},

		/**
		 * @description This method will tell you if the shop creation form has any data in any of its inputs.
		 * @returns {Boolean}
		 */
		hasFormAnyData() {
			// Catch all the v-selects (dropdowns) and text inputs.
			const formControls = {
				selects: [
					...document.querySelectorAll('.form-select .vs__selected-options'),
				],
				inputs: [...document.querySelectorAll('.input')],
			};

			// If the dropdown has any option selected, it contains the span.selected-tag
			// so its abundance means that no option has been selected.
			if (
				formControls.selects.find((select) =>
					select.querySelector('.selected-tag')
				)
			) {
				return true;
			} else if (formControls.inputs.find((input) => input.value !== '')) {
				return true;
			} else {
				return false;
			}
		},

		searchShopHandler(search, loading) {
			loading(true);
			this.loadShopsHandler(search, loading, this);
		},

		loadShopsHandler: _.debounce((search, loading, self) => {
			self.$http
				.post(`/spa/managment/shopworkers/getshopdictionary`, { Query: search })
				.then((response) => {
					self.shopsList = response.data;
					loading(false);
				});
		}, 250),

		submitFormHandler() {
			if (this.submitStatus.loading) {
				this.$v.$touch();
				if (!this.$v.$invalid) {
					let self = this,
						formData = { ...self.formData },
						submitStatus = { ...self.submitStatus };

					formData.Network =
						self.formData.Network.Value || self.formData.Network;
					formData.Province =
						self.formData.Province.Value || self.formData.Province;

					formData.Id = this.$route.params.shopId;

					formData.ShopNumber = formData.NewShopNumber
						? formData.NewShopNumber
						: formData.ExistingShopNumber.ShopNumber;
					delete formData.NewShopNumber;
					delete formData.ExistingShopNumber;

					if (this.isEdit) {
						self.$http
							.post(`spa/managment/shop/edit`, formData)
							.then((response) => {
								submitStatus = {
									message: response.data.Message,
									loading: false,
									responseType: 'success',
								};

								self.$emit('update-submit', submitStatus);
							})
							.catch((error) => {
								submitStatus = {
									message: error.response.data.Message,
									loading: false,
									responseType: 'error',
								};

								self.$emit('update-submit', submitStatus);
							});
					} else {
						submitStatus.loading = false;
						self.$emit('update-submit', submitStatus);
					}
					for (let prop of Object.getOwnPropertyNames(formData))
						self.$store.state.shop[prop] = formData[prop];
					self.$store.commit('UPDATE_STATE');
				} else {
					this.$nextTick(() => {
						let submitStatus = { ...this.submitStatus };

						submitStatus.loading = false;
						this.$emit('update-submit', submitStatus);
					});
				}
			}
		},
	},
	watch: {
		$v: {
			handler: function (val) {
				if (!val.$invalid) {
					this.$emit('can-continue', { value: true });
				} else {
					this.$emit('can-continue', { value: false });
				}
			},
			deep: true,
		},

		'submitStatus.loading': function () {
			this.submitFormHandler();
		},

		clickedNext(val) {
			if (val === true) {
				this.$v.formData.$touch();
			}
		},

		existingShopNumber(self) {
			if (self) {
				this.$emit('can-continue', { value: true });
			}
		},
	},

	mounted() {
		if (!this.$v.$invalid) {
			this.$emit('can-continue', { value: true });
		} else {
			this.$emit('can-continue', { value: false });
		}

		if (this.isEdit) {
			ShopService.getShopDetails(this.$route.params.shopId).then((response) => {
				const shopDetails = response.data;

				this.formData = {
					...shopDetails,
					NewShopNumber: shopDetails.ShopNo,
					Street: shopDetails.Address,
					PostalCode: shopDetails.PostalCode1 + '-' + shopDetails.PostalCode2,
					Province: shopDetails.ProvinceId,
					StatusId: shopDetails.ShopStatus,
					Network: this.networkList.find(
						(network) => network.Key === shopDetails.Network
					),
				};

				this.$http
					.get(`spa/managment/workers/getnetworkfilters`)
					.then((response) => {
						this.formData = {
							...this.formData,
							Network: response.data.find(
								(network) => network.Key === shopDetails.Network
							),
						};
					});
			});
		}
	},

	created() {
		let self = this;

		self.$http
			.get(`spa/managment/shop/getselectors`)
			.then((response) => {
				self.networkList = response.data.Networks;
				self.provinceList = response.data.Provinces;
			})
			.catch(() => {});
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

form {
	max-width: 500px;
	margin: 0 auto;
	.columns {
		margin-bottom: 0;
	}
	.stepper-box {
		box-shadow: none;
	}

	.has-error {
		margin-bottom: 30px !important;
	}
}
</style>
