<template>
    <article class="card" @click="$emit('goToUrlHandler')">
        <div class="columns-wrap">
            <div class="img-conf">
                <img v-lazy="confPast.ListImage" :alt="confPast.Title" :title="confPast.Title">
            </div>
            <div class="card-content">
                <div class="main-lector" v-if="confPast.ConferenceKind">
                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-primary-hover">{{ confPast.ConferenceKind }}</p>
                </div>
                <div class="title-description">
                    <h2 class="is-size-3 is-size-2-tablet">{{ confPast.Title }}</h2>
                    <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc">
                        {{ confPast.Subtitle }}
                    </p>
                </div>
                <RightArrowSmallIcon class="right-arrow-small-icon" />
            </div>

        </div>
    </article>
   
</template>

<script>
    import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
    import global from "@/mixins/global.js";


export default {
        name: "BaseCardConference",
        mixins: [global],
        components: {
        RightArrowSmallIcon,
    },
    props: ['confPast'],
    computed: {
        //descriptionLimited() {
        //    if (this.confPast) {
        //        if (this.confPast.SubTitle.length > 180) {
        //            return this.confPast.SubTitle.substring(0, 178) + '...'
        //        } else {
        //            return this.confPast.SubTitle;
        //        }
        //    } else {
        //        return '';
        //    }
        //},
        //parseDate() {
        //    let parseDate = new Date(this.date).toDateString('dd.mm.yyyy');
        //    return parseDate;
        //},
        //dateArray() {
        //    return this.workShop.Date.split(" ");
        //},

    },

        

};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

    .columns-wrap {
        display: block;
        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }

    .img-conf {
        width: 100%;
        min-width: 100%;
        position: relative;
        height: 200px;

        @include tablet {
            width: 27%;
            min-width: 27%;
            height: auto;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0;

            @include tablet {
                border-top-right-radius: 0;
                border-bottom-left-radius: 5px;
            }
        }
    }

    .card-content {
        padding: 1.5rem 1.125rem 1.5rem 1.125rem;

        @include tablet {
            padding: 2.375rem 3rem 0.875rem 1.9rem;
        }

        @include fullhd {
            padding: 2.375rem 10rem 0.875rem 1.9rem;
        }

        .title-description {
            padding-right: 2rem;

            @include tablet {
                padding-right: 3rem;
            }
        }

        h2 {
                margin: 0 0 0.625rem;
            }

        .main-lector {
            margin-bottom: 0.625rem;
        }

            .cat-name {
                text-transform: uppercase;
                margin-bottom: 1.7em;
                font-weight: 600;
                svg {
                margin-right: 10px;
                position: relative;
                top: 1px;
                }
            }
    .short-desc {
        margin-bottom: 0;

        @include tablet {
            margin-bottom: 1.5625rem;
        }
    }
        .right-arrow-small-icon {
            margin-top: -10px;
            right: 20px;

            @include tablet {
                right: 30px;
            }
        }
    }

.category-full {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5625rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @include tablet {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.card {
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  cursor: pointer;
  color: $black;
  border-radius: 4px;
  box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
  border: solid 1px #f2f2f2;
  &.bottom-image {
    .card-image {
      order: 2;
      align-self: flex-end;
      img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .card-content {
      order: 1;
      img {
        border-radius: 0;
        max-height: 40px;
      }
    }
  }
  .is-3by1 {
    padding-top: 39.3333%;
  }
  &-image,
  &-content {
    width: 100%;
    .media-content {
      & > div {
        padding-right: 70%;
      }
    }
  }
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
      ul {
          color: $white;
      }
      svg {
          color: $white;
      }
      .alert {
          color: $red;
      }
    }
  }
}
</style>
