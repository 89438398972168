<template>
	<section class="owners-management section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu">
						<div v-for="(filterType, key) in filtersList" :key="key">
							<b-collapse
								:open.sync="filterType.isOpen"
								v-if="filterType.Type === 'select'"
							>
								<p
									class="menu-label is-size-7 has-text-black has-border-bottom"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<ul class="menu-list">
										<li
											class="is-flex"
											:class="{
												'is-selected': filters[filterType.Key] === filter.Key,
											}"
											v-for="filter in filterType.Options"
											:key="filter.Key"
										>
											<a
												@click="
													(filters[filterType.Key] = filter.Key),
														(filters.Page = 0),
														activeTab === 0
															? loadOwnersHandler()
															: loadStatsListHandler()
												"
												class="is-size-7"
												>{{ filter.Value }}</a
											>
											<button
												class="delete is-small"
												@click="
													clearFiltersHandler(
														filterType.Key,
														activeTab === 0
															? loadOwnersHandler
															: loadStatsListHandler
													)
												"
											></button>
										</li>
									</ul>
								</div>
							</b-collapse>
							<div v-else>
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold filter-input"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0
															? loadOwnersHandler()
															: loadStatsListHandler()
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full">
							<div class="columns is-variable is-3 actions">
								<div class="column is-half is-offset-half">
									<div class="columns">
										<div
											class="column is-12-tablet is-8-desktop is-offset-4-desktop is-6-widescreen is-offset-6-widescreen"
										>
											<router-link
												:to="{ name: 'Dodaj sklep' }"
												class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
											>
												<span class="icon is-small">
													<AddIcon />
												</span>
												<span>Dodaj właściciela</span>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<b-tabs class="b-tabs-owners" v-model="activeTab">
							<b-tab-item label="Dane">
								<div class="column is-full is-block has-text-centered">
									<div class="card-table card-table-has-expand">
										<div class="card is-hidden-mobile">
											<div class="card-header">
												<div class="field">
													<div class="control has-icons-left has-icons-right">
														<input
															class="input is-size-4"
															type="text"
															v-model="filters.SearchPhrase"
															:placeholder="'Szukaj pracownika'"
															@change="
																$emit('update:search', $event.target.value)
															"
														/>
														<span class="icon is-small is-left">
															<LoupeIcon />
														</span>
													</div>
												</div>
											</div>
										</div>
										<table class="table table-owners">
											<thead class="is-hidden-mobile">
												<tr
													class="is-size-18 is-uppercase has-text-weight-semibold has-text-left valign-cells-middle"
												>
													<th class="has-text-grey-light">Sieć</th>
													<th class="has-text-grey-light">Imię</th>
													<th class="has-text-grey-light">Nazwisko</th>
													<th class="has-text-grey-light">Stanowisko</th>
													<th class="has-text-grey-light">Email</th>
													<th class="has-text-grey-light has-text-centered">
														Status
													</th>
													<th class="has-text-grey-light">Szczegóły</th>
												</tr>
												<tr v-if="loading">
													<th colspan="7" class="is-progress-bar-table">
														<progress
															class="progress is-primary"
															max="100"
														></progress>
													</th>
												</tr>
											</thead>
											<tbody v-if="ownersList.length">
												<!-- Statuses legend -->
												<tr class="statuses-legend">
													<td class="has-text-black is-size-18" colspan="6">
														<div class="is-flex">
															<i
																class="is-dot is-inline-block has-background-info"
															></i>
															<span>Aktywny</span>

															<i
																class="is-dot is-inline-block has-background-warning"
															></i>
															<span>Dodany</span>

															<i
																class="is-dot is-inline-block has-background-danger"
															></i>
															<span>Uczestnik warsztatu</span>
														</div>
													</td>
												</tr>
												<!-- END | Statuses legend -->

												<template v-for="worker in ownersList">
													<tr
														class="is-size-17 has-text-left has-text-weight-semibold valign-cells-middle worker-row"
														:class="{ 'is-active': worker.isOpen }"
														:key="worker.Email"
													>
														<td>
															<div class="is-dotted is-block">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Sieć</span
																>
																<span>{{ worker.Network }}</span>
															</div>
														</td>

														<td>
															<div class="is-dotted is-block">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Imię</span
																>
																<span>{{ worker.FirstName }}</span>
															</div>
														</td>

														<td>
															<div class="is-dotted is-block">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Nazwisko</span
																>
																<span>{{ worker.LastName }}</span>
															</div>
														</td>
														<td>
															<div class="is-dotted is-block">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Stanowisko</span
																>
																<span>{{ worker.WorkPosition }}</span>
															</div>
														</td>
														<td>
															<div class="is-dotted is-block">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Email</span
																>
																<span>{{ worker.Email }}</span>
															</div>
														</td>
														<td>
															<div class="is-dotted has-text-centered">
																<span
																	class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																	>Status</span
																>
																<i
																	class="is-dot is-inline-block is-hidden-mobile"
																	:class="{
																		'has-background-info':
																			worker.Status === 'AKTYWNY',
																		'has-background-warning':
																			worker.Status === 'DODANY',
																		'has-background-danger':
																			worker.Status === 'UCZESTNIK WARSZTATU',
																	}"
																></i>
																<span class="is-hidden-tablet">{{
																	worker.Status
																}}</span>
															</div>
														</td>
														<td
															class="is-hidden-mobile has-text-centered is-clickable"
															@click="worker.isOpen = !worker.isOpen"
														>
															<DwnArrowIcon
																width="12"
																height="24"
																viewBox="0 0 12 20"
																class="dwn-arrow-icon"
															/>
														</td>
													</tr>
													<transition
														name="has-fade"
														:key="worker.Email + '-details'"
													>
														<tr
															class="is-size-17 has-text-left has-text-weight-semibold"
															v-if="worker.isOpen"
														>
															<td
																colspan="7"
																class="is-paddingless is-relative b-tabs-container"
															>
																<b-tabs
																	v-model="worker.activeTab"
																	@input="loadDirectorsOwnersHandler(worker)"
																>
																	<b-tab-item disabled>
																		<template slot="header">
																			<strong>Dane pracownika</strong>
																		</template>

																		<!-- Shop details -->
																		<dl class="worker-details">
																			<Field
																				title="Sieć"
																				:value="
																					worker.Network
																						? worker.Network
																						: 'brak'
																				"
																			/>

																			<Field
																				title="Imię"
																				:value="
																					worker.FirstName
																						? worker.FirstName
																						: 'brak'
																				"
																			/>

																			<Field
																				title="Nazwisko"
																				:value="
																					worker.LastName
																						? worker.LastName
																						: 'brak'
																				"
																			/>

																			<Field
																				title="Stanowisko"
																				:value="
																					worker.WorkPosition
																						? worker.WorkPosition
																						: 'brak'
																				"
																			/>

																			<Field
																				title="E-mail"
																				:value="
																					worker.Email ? worker.Email : 'brak'
																				"
																			/>

																			<Field
																				title="Telefon"
																				:value="
																					worker.Phone ? worker.Phone : 'brak'
																				"
																			/>

																			<Field
																				title="Status"
																				:value="
																					worker.Status ? worker.Status : 'brak'
																				"
																			/>
																		</dl>
																		<!-- END | Shop details -->
																	</b-tab-item>
																</b-tabs>
																<router-link
																	class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold is-outlined is-outlined-bold has-glow-button"
																	:to="
																		'/panel-zarzadania/dane-uzytkownika/' +
																		worker.Id
																	"
																>
																	<span class="icon is-small">
																		<CogWheelIcon />
																	</span>
																	<span>Edycja danych</span>
																</router-link>
															</td>
														</tr>
													</transition>
												</template>
											</tbody>
											<tbody v-else>
												<tr>
													<td
														colspan="8"
														class="is-size-17 has-text-centered has-text-weight-semibold"
													>
														{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<a
										class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
										@click="
											filters.Page++;
											loadOwnersHandler();
										"
										v-if="nextPage"
										>Załaduj więcej treści</a
									>
								</div>
							</b-tab-item>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ
						<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li v-for="(filterType, key) in filtersList" :key="key">
							<div v-if="filterType.Type === 'select'">
								<b-collapse :open.sync="filterType.isOpen">
									<span
										class="has-arrow is-flex"
										slot="trigger"
										:class="{ 'is-active': filterType.isOpen }"
									>
										<span
											class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
											>{{ filterType.Title }}</span
										>
									</span>
									<div>
										<div class="navbar-content">
											<ul>
												<li
													class="is-flex has-text-left"
													:class="{
														'is-selected':
															filters[filterType.Key] === filter.Key,
													}"
													v-for="filter in filterType.Options"
													:key="filter.Key"
												>
													<a
														@click="
															(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																activeTab === 0
																	? loadOwnersHandler()
																	: loadStatsListHandler()
														"
														>{{ filter.Value }}</a
													>
													<button
														class="delete is-small"
														@click="
															clearFiltersHandler(
																filterType.Key,
																activeTab === 0
																	? loadOwnersHandler
																	: loadStatsListHandler
															)
														"
													></button>
												</li>
											</ul>
										</div>
									</div>
								</b-collapse>
							</div>
							<div v-else class="filter-input">
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold has-text-left"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0
															? loadOwnersHandler()
															: loadStatsListHandler()
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
		<b-modal
			:active.sync="isAddWorkerModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isAddWorkerModalActive = false"
			></button>
			<form @submit.prevent="submitAddFormHandler" novalidate>
				<div class="columns is-multiline">
					<div class="column is-full">
						<h2 class="is-size-1 has-text-left has-text-black">
							Dodaj pracownika
						</h2>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Imię',
										height: 56,
										hasContent: formData.Name ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.Name.$error,
									}"
								>
									<input
										v-model="formData.Name"
										@blur="$v.formData.Name.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages($v.formData.Name)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Nazwisko',
										height: 56,
										hasContent: formData.Surname ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.Surname.$error,
									}"
								>
									<input
										v-model="formData.Surname"
										@blur="$v.formData.Surname.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.Surname
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{ 'has-error': this.$v.formData.Network.$error }"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											label="Value"
											v-model="formData.Network"
											:options="networkList"
											placeholder="Wybierz sieć"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.Network
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Adres e-mail',
										height: 56,
										hasContent: formData.Email ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.Email.$error,
									}"
								>
									<input
										v-model="formData.Email"
										@blur="$v.formData.Email.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.Email
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer telefonu',
										height: 56,
										hasContent: formData.PhoneNumber ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.PhoneNumber.$error,
									}"
								>
									<input
										v-model="formData.PhoneNumber"
										@blur="$v.formData.PhoneNumber.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.PhoneNumber
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<button
							href="#"
							class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
							:class="{ 'is-loading': loading }"
							type="submit"
							@click="submitAddFormHandler"
						>
							<span class="icon is-small">
								<svg width="17" height="20" viewBox="0 2 17 16">
									<use :xlink:href="'/assets/2018/svg/buttons/add.svg#add'" />
								</svg>
							</span>
							<span>Dodaj pracownika</span>
						</button>
						<p
							class="is-size-8 has-text-weight-light has-text-danger has-text-centered is-block"
							v-if="errorMsg"
						>
							{{ errorMsg }}
						</p>
						<p
							class="is-size-8 has-text-weight-light has-text-primary has-text-centered is-block"
							v-if="successMsg"
						>
							{{ successMsg }}
						</p>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script>
/* eslint-disable */

import { debounce } from 'lodash';
import global from '@/mixins/global.js';
import { required, email, numeric } from 'vuelidate/lib/validators';
import FloatingLabel from '@/components/BaseFloatingLabel';
import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import DwnArrowIcon from '@/assets/svg/dwn-arrow-large.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';
import Field from './Field.vue';

export default {
	name: 'DirectorsManagement',

	mixins: [global],

	components: {
		FloatingLabel,
		AddIcon,
		LoupeIcon,
		DwnArrowIcon,
		CogWheelIcon,
		Field,
	},

	props: {
		search: {
			type: String,
		},
	},

	data() {
		return {
			ownersList: [],
			filtersList: [],
			networkList: [],
			filters: {
				Page: 0,
				ShopNumber: null,
				SearchPhrase: '',
			},
			formData: {
				Name: '',
				Surname: '',
				Email: '',
				PhoneNumber: '',
				Network: '',
			},
			nextPage: true,
			isAddWorkerModalActive: false,
			errorMsg: '',
			successMsg: '',
			loading: false,
			activeTab: 0,
			submit: false,
		};
	},
	validations: {
		formData: {
			Name: {
				required,
			},
			Surname: {
				required,
			},
			Network: {
				required,
			},
			Email: {
				required,
				email,
			},
			PhoneNumber: {
				numeric,
			},
		},
	},
	methods: {
		/**
		 * @description Get the proper classname corresponding with the worker status.
		 * @param {Object} workerStatus The actual status of the worker.
		 * @returns {String} The classname describing the background colour of the status dot.
		 */
		getWorkerStatusClass(workerStatus) {
			switch (workerStatus) {
				case 'AKTYWNY':
					return 'has-background-info';
					break;

				case 'DODANY':
					return 'has-background-warning';
					break;

				case 'UCZESTNIK WARSZTATU':
					return 'has-background-danger';
					break;

				default:
					break;
			}
		},

		loadOwnersHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/managment/workers/onwers/list`, self.filters)
				.then((response) => {
					let data = response.data.Data;
					data.forEach((item) => ((item.isOpen = false), (item.edit = false)));
					if (self.filters.Page) {
						self.ownersList = [...self.ownersList, ...data];
						self.nextPage = response.data.NextPage;
					} else {
						self.ownersList = data;
						self.nextPage = response.data.NextPage;
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		loadOwnersFiltersHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/workers/onwers/filters`)
				.then((response) => {
					this.filtersList = response.data;
					this.filtersList.forEach(function (item) {
						self.$set(item, 'isOpen', false);
						self.$set(item, 'activeTab', 0);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},
		submitAddFormHandler: function () {
			let self = this;
			if (this.submit) {
				return;
			}
			this.submit = true;
			setTimeout(() => (this.submit = false), 1000);

			self.formData.NetworkId = self.formData.Network.Key;

			self.$v.$touch();
			if (!this.$v.$invalid) {
				self.loading = true;

				self.$http
					.post(`/spa/managment/workers/onwers/add`, self.formData)
					.then((response) => {
						self.loading = false;
						self.errorMsg = '';
						self.successMsg = response.data.Message;
						for (let prop of Object.getOwnPropertyNames(self.formData))
							self.formData[prop] = '';
						self.$v.$reset();
						setTimeout(() => {
							self.successMsg = '';
							self.filters.Page = 0;
							self.loadOwnersHandler();
							self.isAddWorkerModalActive = false;
						}, 3000);
					})
					.catch((error) => {
						self.loading = false;
						self.successMsg = '';
						setTimeout(() => {
							self.errorMsg = '';
						}, 3000);
						self.errorMsg = error.response.data.Message;
					});
			}
		},
	},
	created() {
		let self = this;
		self.$http
			.get(`spa/managment/workers/getnetworkfilters`)
			.then((response) => {
				self.networkList = response.data;
			})
			.catch(() => {});
	},
	watch: {
		search: function () {
			this.filters.SearchPhrase = this.search;
			this.filters.Page = 0;
			this.loadOwnersHandler();
		},
		activeTab: function () {
			this.filters.Page = 0;
			this.loadOwnersHandler();
		},
	},
	activated: function () {
		this.loadOwnersHandler();
		this.loadOwnersFiltersHandler();
		switch (this.$route.params.dialog) {
			case 'dodaj-pracownika':
				this.isAddWorkerModalActive = true;
				break;
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Vertically align the content
// in <td>s within the selected <tr>.
.valign-cells-middle {
	& > td,
	& > th {
		vertical-align: middle;
	}
}

// Hide the dots for the cell with the
// "Lista pracowników >>" router-link on mobile.
td:nth-last-child(2) .is-dotted::before {
	@include mobile {
		display: none !important;
	}
}

.worker-row {
	border: 1px solid #dfe0e0;
	transition: all 300ms ease-in-out;

	// Remove the globally defined green borders.
	td {
		border: none !important;
	}

	@include tablet {
		// Remove the white background from the <span>s with text content.
		span,
		a {
			background: transparent;
		}
	}

	&.is-active {
		background-color: #3aab59;
		border-color: #3aab59;

		span {
			color: white;
		}

		// Rotate the chevron-down icon (b-collapse toggler).
		.dwn-arrow-icon {
			transform: rotate(270deg);
			transition: all 300ms ease-in-out;
		}
	}
}

table tbody td {
	padding: 15px !important;
}

.statuses-legend {
	border-bottom: 1px solid $grey-lighter;

	> td {
		padding: 10px !important;

		.is-flex > :nth-child(even) {
			margin-right: rem(40px);
		}
	}
}

.owners-management {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}
	.button.is-info {
		color: rgba(58, 171, 89, 0.5);
		height: 50px;
		margin-bottom: rem(25px);
		span {
			color: $white;
		}
	}
	.container {
		& > :first-child {
			& > :nth-child(2) {
				@include desktop {
					margin-top: -100px;
				}
			}
		}
	}
	.b-tabs-owners {
		width: 100%;
		@include desktop {
			margin-top: -92px;
		}
		& > :first-child {
			padding: 0 15px 0;
			@include desktop {
				padding: 20px 10px 0;
			}
		}
		& > :nth-child(2) {
			& > .tab-item {
				overflow: auto;
			}
		}
	}
	.actions,
	.card-table {
		.button.is-info:not(.has-arrow) {
			& + .button.is-info {
				margin-left: rem(20px);
			}
		}
	}
	.card-table {
		.table {
			&.table-owners {
				& > thead tr,
				tbody > tr > td > table > tr:first-child {
					@include tablet {
						& > *:not(.is-progress-bar-table) {
							width: 10%;
							padding: rem(15px);
							vertical-align: middle;
						}
						& > :nth-child(3) {
							width: 25%;
						}
						& > :nth-child(4) {
							width: 35%;
						}
					}
				}
				tbody > tr > td > table > tr:last-child {
					& > td {
						position: relative;
					}
				}
				.tab-item {
					.is-dotted span + span {
						float: right;
					}
				}
				.b-tabs {
					& + .button {
						margin-bottom: 0;
						@include desktop {
							position: absolute;
							right: 0;
							top: 0;
							margin: 20px;
						}
					}
				}
			}
		}
	}
	.columns {
		.menu {
			text-align: left;
			a {
				padding-left: 0;
				width: 100%;
			}
			.control {
				position: relative;
				z-index: 2;
				.icon {
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 3;
					color: $grey-light;
					display: none;
					@include desktop {
						display: block;
					}
				}
			}
			&-list {
				li {
					justify-content: space-between;
					letter-spacing: 1px;
					a {
						padding-right: 5px;
					}
				}
				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
			.filter-input {
				padding: rem(25px 0);
				margin-bottom: 0;
			}
		}
	}

	.filters {
		.menu-list {
			.filter-input {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
		}
	}
	.modal {
		& > :nth-child(2) {
			width: 830px;
			padding: rem(40px);
			overflow: auto;
			@include widescreen {
				overflow: visible;
			}
		}
		.field {
			margin-bottom: rem(30px);
			.control {
				.input__container,
				.input-container {
					&.has-error {
						margin-bottom: 0;
					}
					& + .has-text-danger {
						margin-top: 0;
					}
				}
			}
		}
		.field + .button {
			color: rgba(58, 171, 89, 0.5);
			height: 56px;
			width: 100%;
			max-width: none;
			.icon {
				margin-top: 4px;
			}
			span {
				color: $white;
			}
		}
		.button {
			color: rgba(58, 171, 89, 0.5);
			border-radius: 5px;
			height: 50px;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: rem(20px);
			}
			span {
				color: white;
			}
			.icon {
				margin-right: 5px;
			}
			&.is-danger {
				color: rgba(215, 33, 65, 0.5);
			}
		}
	}
}

.b-tabs-container {
	border: 1px solid #3aab59 !important;
}

.worker-details {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-row-gap: 15px;

	// For larger screens.
	@media (min-width: 480px) {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;

		grid-auto-flow: column;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(4, 1fr);
		grid-column-gap: 70px;
	}
}
</style>
