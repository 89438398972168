<template>
	<section class="add-edit-shop section">
		<div class="container is-container-thin">
			<div class="columns is-multiline">
				<div class="column">
					<div class="card">
						<div class="card-content">
							<h1 class="is-size-1 has-text-centered has-text-black">
								{{ isEdit ? 'Edycja danych sklepu' : 'Dodaj sklep' }}
							</h1>
							<div class="stepper-box" :class="{ 'is-edit': isEdit }">
								<div class="top" v-if="isEdit">
									<div class="steps-wrapper">
										<template v-for="(step, index) in steps">
											<div
												:class="['step', isStepActive(index, step)]"
												:key="index"
												:style="{ width: `${100 / steps.length}%` }"
											>
												<div class="step-title" @click="goToStep(index)">
													<h4 class="is-size-3">{{ step.title }}</h4>
												</div>
											</div>
										</template>
									</div>
								</div>
								<div class="top" v-else>
									<div
										class="divider-line"
										:style="{
											width: `${(100 / steps.length) * (steps.length - 1) -
												10}%`,
										}"
									></div>
									<div class="steps-wrapper">
										<template v-for="(step, index) in steps">
											<div
												:class="['step', isStepActive(index, step)]"
												:key="index"
												:style="{ width: `${100 / steps.length}%` }"
											>
												<div class="circle">
													<span>{{ step.number }}</span>
												</div>
												<div class="step-title">
													<h4 class="is-size-3">{{ step.title }}</h4>
												</div>
											</div>
										</template>
									</div>
								</div>
								<hr />
								<div>
									<transition
										:enter-active-class="enterAnimation"
										:leave-active-class="leaveAnimation"
										mode="out-in"
									>
										<!--If keep alive-->
										<keep-alive v-if="true">
											<component
												:is="steps[currentStep.index].component"
												:clickedNext="nextButton[currentStep.name]"
												@can-continue="proceed"
												@update-submit="changeSubmitStatus"
												@change-next="changeNextBtnValue"
												:submitStatus="submitStatus"
												:isEdit="isEdit"
												:current-step="currentStep"
												@existing-shop-select="onExistingShopSelect"
												@new-data-input="onNewDatainput"
												@create-manager="onManagerCreate"
												:has-manager-been-created="hasManagerBeenCreated"
												@select-ca="onCaSelect"
												:did-select-existing-shop="didSelectExistingShop"
												:existing-shop-change-indicator="
													existingShopChangeIndicator
												"
											></component>
										</keep-alive>
										<!--If not show component and destroy it in each step change-->
										<component
											v-else
											:is="steps[currentStep.index].component"
											:clickedNext="nextButton[currentStep.name]"
											@can-continue="proceed"
											@update-submit="changeSubmitStatus"
											@change-next="changeNextBtnValue"
											:submitStatus="submitStatus"
											:isEdit="isEdit"
											:current-step="currentStep"
										></component>
									</transition>
								</div>
								<div v-if="isEdit">
									<div
										:class="[
											'columns is-multiline bottom',
											currentStep.index > 0 ? '' : 'only-next',
										]"
									>
										<div
											class="stepper-button column is-6 is-12-mobile previous"
										>
											<router-link
												class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button is-outlined is-outlined-bold"
												:to="cancelURL"
											>
												<span class="is-size-7-em">Anuluj</span>
											</router-link>
										</div>
										<div class="stepper-button column is-6 is-12-mobile next">
											<button
												class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button"
												:class="{ 'is-loading': submitStatus.loading }"
												@click="submitFormHandler($event)"
											>
												<span class="is-size-7-em has-text-white">Zapisz sklep</span
												>
											</button>
										</div>
									</div>
								</div>
								<div v-else>
									<div
										:class="[
											'columns is-multiline bottom',
											currentStep.index > 0 ? '' : 'only-next',
										]"
									>
										<div
											v-if="currentStep.index === 0"
											class="stepper-button column is-6 is-12-mobile previous"
										>
											<router-link
												class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button is-outlined is-outlined-bold"
												:to="cancelURL"
											>
												<span class="is-size-7-em">Anuluj</span>
											</router-link>
										</div>
										<div
											v-if="currentStep.index > 0"
											class="stepper-button column is-6 is-12-mobile previous"
											@click="backStep()"
										>
											<button
												class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button is-outlined is-outlined-bold"
											>
												<span class="is-size-7-em">{{ 'Poprzedni krok' }}</span>
											</button>
										</div>
										<div
											class="stepper-button column is-6 is-12-mobile next"
											:class="{ deactivated: !canContinue && !isEdit }"
										>
											<button
												class="button is-info is-fullwidth is-uppercase has-text-weight-semibold has-glow-button"
												@click="submitFormHandler($event, true)"
												:class="{ 'is-loading': submitStatus.loading }"
												:disabled="canContinue ? false : true"
											>
												<span class="is-size-7-em has-text-white">{{
													finalStep ? 'Dodaj sklep' : 'Następny krok'
												}}</span>
											</button>
										</div>
									</div>
								</div>
								<div>
									<p
										class="is-size-8 has-text-weight-light has-text-primary has-text-centered"
										v-if="submitStatus.responseType === 'success'"
									>
										{{ submitStatus.message }}
									</p>
									<p
										class="is-size-8 has-text-weight-light has-text-danger has-text-centered"
										v-if="submitStatus.responseType === 'error'"
									>
										{{ submitStatus.message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<BaseModalInfo
			:is-active="modalInfo.isActive"
			:message="modalInfo.message"
			:button-label="modalInfo.buttonLabel"
		/>
	</section>
</template>

<script>
import global from '@/mixins/global.js';
import FloatingLabel from '@/components/BaseFloatingLabel';
import ShopDetails from './steps/ShopDetails.vue';
import CustomerAdvisor from './steps/CustomerAdvisor.vue';
import ShopOwner from './steps/ShopOwner.vue';
import BaseModalInfo from '@/components/BaseModalInfo.vue';
import router from '@/router.js';

export default {
	name: 'AddEditShop',
	mixins: [global],
	components: {
		FloatingLabel,
		BaseModalInfo,
	},
	data() {
		return {
			modalInfo: {
				isActive: false,
				message: '',
				buttonLabel: '',
			},

			steps: [
				{
					number: 1,
					name: 'first',
					title: 'Właściciel',
					component: ShopOwner,
					completed: false,
				},
				{
					number: 2,
					name: 'second',
					title: 'Dane sklepu',
					component: ShopDetails,
					completed: false,
				},
				{
					number: 3,
					name: 'third',
					title: 'Doradca',
					component: CustomerAdvisor,
					completed: false,
				},
			],
			topButtons: false,
			keepAlive: true,
			reset: false,
			cancelURL: '/panel-zarzadania/sklepy',
			isEdit: this.$route.params.shopId ? true : false,
			currentStep: {},
			previousStep: {},
			nextButton: {},
			canContinue: false,
			finalStep: false,
			keepAliveData: this.keepAlive,
			submitStatus: {
				message: '',
				loading: false,
				responseType: false,
			},

			hasShopBeenCreated: false,
			hasManagerBeenCreated: false,
			hasCaBeenSelected: false,

			didSelectExistingShop: false,
			existingShopChangeIndicator: false,
			didProvideNewData: false,
		};
	},

	beforeMount() {
		this.$store.state.shop = {};
	},

	activated() {
		this.$store.state.shop = {};
	},

	methods: {
		onCaSelect() {
			this.hasCaBeenSelected = true;
		},

		onManagerCreate() {
			this.hasManagerBeenCreated = true;
		},

		onExistingShopSelect() {
			this.didSelectExistingShop = true;
			this.didProvideNewData = false;
			this.existingShopChangeIndicator = !this.existingShopChangeIndicator;
		},

		onNewDatainput() {
			this.didProvideNewData = true;
			this.didSelectExistingShop = false;
		},

		// Executed ,when @completed-step event is triggered
		completeStep(payload) {
			this.addEditShopSteps.forEach(step => {
				if (step.name === payload.name) {
					step.completed = true;
				}
			});
		},
		alert() {},
		isStepActive(index, step) {
			if (this.currentStep.index === index) {
				return 'activated';
			} else if (step.completed && !this.isEdit) {
				return 'completed';
			} else if (!this.isEdit) {
				return 'deactivated';
			}
		},

		activateStep(index, back = false) {
			if (this.steps[index]) {
				this.previousStep = this.currentStep;
				this.currentStep = {
					name: this.steps[index].name,
					index: index,
				};

				if (index + 1 === this.steps.length) {
					this.finalStep = true;
				} else {
					this.finalStep = false;
				}

				if (!back) {
					this.$emit('completed-step', this.previousStep);
				}
			}
			this.$emit('active-step', this.currentStep);
		},

		nextStepAction() {
			this.nextButton[this.currentStep.name] = true;
			if (this.canContinue) {
				if (this.finalStep) {
					this.$emit('stepper-finished', this.currentStep);
				}
				let currentIndex = this.currentStep.index + 1;

				this.activateStep(currentIndex);
				this.submitStatus = {
					message: '',
					loading: false,
					responseType: false,
				};
			}
			this.$forceUpdate();
		},

		goToStep(step) {
			this.activateStep(step);
			this.$forceUpdate();
			this.submitStatus = {
				message: '',
				loading: false,
				responseType: false,
			};
		},

		nextStep() {
			if (!this.$listeners || !this.$listeners['before-next-step']) {
				this.nextStepAction();
			}
			this.$emit(
				'before-next-step',
				{ currentStep: this.currentStep },
				(next = true) => {
					this.canContinue = true;
					if (next) {
						this.nextStepAction();
					}
				}
			);
		},
		backStep() {
			this.$emit('clicking-back');
			this.submitStatus = {
				message: '',
				loading: false,
				responseType: false,
			};
			let currentIndex = this.currentStep.index - 1;
			if (currentIndex >= 0) {
				this.activateStep(currentIndex, true);
			}
		},

		proceed(payload) {
			this.canContinue = payload.value;
		},

		submitFormHandler(e, nextStep) {
			this.submitStatus.loading = true;
			if (this.canContinue && this.currentStep.index < this.steps.length - 1) {
				this.$nextTick(() => (nextStep ? this.nextStep() : null));
			} else if (this.didSelectExistingShop) {
				const updateOwner = () =>
					this.$http.post(`spa/managment/shop/changeowner`, {
						MemberId: this.$store.state.shop.OwnerId,
						ShopId: this.$store.state.shop.existingShop.Id,
					});

				const updateCa = () =>
					this.$http.post(`spa/managment/shop/changeca`, {
						MemberId: this.$store.state.shop.CustomerAdvisorId,
						ShopId: this.$store.state.shop.existingShop.Id,
					});

				const onSuccess = () => {
					this.modalInfo.message = `Sklep został zaktualizowany.`;
					this.modalInfo.buttonLabel = 'Zamknij okno';
					this.modalInfo.isActive = true;
					this.hasShopBeenCreated = true;
				};

				const onError = () => {
					this.modalInfo.message = `Wystąpił nieoczekiwany błąd. Spróbuj ponownie.`;
					this.modalInfo.buttonLabel = 'Zamknij okno';
					this.modalInfo.isActive = true;
				};

				updateOwner()
					.then(() => {
						if (this.hasCaBeenSelected) {
							updateCa()
								.then(() => {
									onSuccess();
								})
								.catch(() => {
									onError();
								});
						} else {
							onSuccess();
						}
					})
					.catch(() => {
						onError();
					});
			} else if (!this.isEdit) {
				const formData = { ...this.$store.state.shop };

				this.$http
					.post(`spa/managment/shop/add`, formData)
					.then(response => {
						this.submitStatus = {
							message: response.data.Message,
							loading: false,
							responseType: 'success',
						};

						this.modalInfo.message = `Sklep został utworzony`;
						this.modalInfo.buttonLabel = 'Zamknij okno';
						this.modalInfo.isActive = true;
						this.hasShopBeenCreated = true;
					})
					.catch((error) => {
                        this.modalInfo.message = error.response.data.Message;
						this.modalInfo.buttonLabel = 'Zamknij okno';
						this.modalInfo.isActive = true;
					});
			}
		},

		changeSubmitStatus(status) {
			this.submitStatus = status;
		},

		changeNextBtnValue(payload) {
			this.nextButton[this.currentStep.name] = payload.nextBtnValue;
			this.$forceUpdate();
		},

		init() {
			// Initiate stepper
			this.activateStep(0);
			this.steps.forEach(step => {
				this.nextButton[step.name] = false;
			});
		},

		closeModalInfo() {
			this.modalInfo.isActive = false;

			// Go back to the previous view.
			if (this.hasShopBeenCreated) {
				router.push('/panel-zarzadania/sklepy/');
			}
		},
	},
	created: function() {
		this.init();
		this.$route.params.shopId
			? this.$set(
					this.$store.state,
					'breadcrumb',
					this.$store.state.shop.Name + ' > Edycja danych'
					// eslint-disable-next-line
			  )
			: null;
	},
	watch: {
		reset(val) {
			if (!val) {
				return;
			}
			this.keepAliveData = false;
			this.init();
			this.previousStep = {};

			this.$nextTick(() => {
				this.keepAliveData = this.keepAlive;
				this.$emit('reset', true);
			});
		},
	},
	computed: {
		enterAnimation() {
			if (this.currentStep.index < this.previousStep.index) {
				return 'animated quick fadeInLeft';
			} else {
				return 'animated quick fadeInRight';
			}
		},
		leaveAnimation() {
			if (this.currentStep.index > this.previousStep.index) {
				return 'animated quick fadeOutLeft';
			} else {
				return 'animated quick fadeOutRight';
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.add-edit-shop {
	.card {
		margin: rem(70px 0 20px);
		&-content {
			padding: rem(20px);
			h2 {
				margin: -20px;
				background: $green-lighter;
				padding: 20px;
				margin-bottom: 10px;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				color: $white;
				margin-bottom: rem(20px);
				@include tablet {
					background: none;
					margin: 0;
					margin-bottom: 20px;
					padding: 0;
					color: $black;
				}
			}
			.stepper-box {
				background-color: white;
				min-height: 200px;
				box-shadow: none;

				&.is-edit {
					.top {
						.steps-wrapper {
							padding-bottom: 0;
							display: flex;
							flex-wrap: wrap;
							.step {
								.step-title {
									position: relative;
									@include tablet {
										position: absolute;
									}
									h4 {
										text-decoration: underline;
										cursor: pointer;
										&:hover,
										&:focus {
											color: $green-lighter;
										}
									}
								}
							}
						}
					}
				}

				@keyframes fadeInLeft {
					from {
						opacity: 0;
						transform: translate3d(-100%, 0, 0);
					}

					to {
						opacity: 1;
						transform: translate3d(0, 0, 0);
					}
				}

				@keyframes fadeInRight {
					from {
						opacity: 0;
						transform: translate3d(100%, 0, 0);
					}

					to {
						opacity: 1;
						transform: translate3d(0, 0, 0);
					}
				}

				.animated {
					animation-duration: 0.4s;
					animation-fill-mode: both;
				}

				.fadeInLeft {
					animation-name: fadeInLeft;
				}

				.fadeInRight {
					animation-name: fadeInRight;
				}

				.top {
					max-width: 510px;
					margin: 0 auto;
					.stepper-button-top {
						z-index: 20;
						padding: 0.4rem;
						border-radius: 100rem;
						cursor: pointer;
						position: absolute;
						display: flex;
						align-items: center;
						justify-content: space-between;
						&.next {
							border: 2px solid #3383c8;
							color: #3383c8;
							right: 1%;
							&.deactivated {
								a {
									background: $grey;
									cursor: not-allowed;
								}
							}
						}
						&.previous {
							color: #333333;
							left: 1%;
						}
					}
					display: flex;
					align-items: center;
					position: relative;
					justify-content: center;
					.divider-line {
						height: 2px;
						position: absolute;
						&:after {
							content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
							width: 0;
							letter-spacing: 3px;
							left: 0;
							bottom: 10px;
							color: $grey-light;
							position: absolute;
							white-space: nowrap;
							overflow: hidden;
							width: 100%;
							font-size: 10px;
						}
						@include mobile {
							width: 90%;
						}
					}
					.steps-wrapper {
						display: flex;
						align-items: center;
						justify-content: space-between;
						position: relative;
						width: 100%;
						left: 0;
						padding: rem(40px 0);
						margin-bottom: rem(20px);
						@include mobile {
							width: 90%;
							justify-content: center;
						}
						.step {
							position: relative;
							display: flex;
							flex-direction: column;
							align-items: center;
							text-align: center;
							@include mobile {
								width: 100% !important;
							}
							&.deactivated {
								@include mobile {
									display: none;
								}
								.step-title {
									opacity: 0.35;
								}
								.circle {
									span {
										color: $grey-lighter;
										border: 2px solid $grey-lighter;
									}
								}
							}
							&.activated {
								.step-title {
									h4 {
										color: $green-lighter;
									}
								}
							}
							&.completed {
								.circle {
									span {
										background: $green-lighter;
										color: $white;
									}
								}
								.step-title {
									h4 {
										color: $green-lighter;
									}
								}
							}
							.circle {
								margin-bottom: 1rem;
								padding: 0 1rem;
								background-color: white;
								span {
									width: 60px;
									height: 60px;
									display: flex;
									align-items: center;
									justify-content: center;
									border-radius: 50%;
									border: 2px solid $green-lighter;
									color: $green-lighter;
								}
							}
							.step-title {
								position: absolute;
								top: 90%;
								width: 100%;
								h1,
								h2,
								h3,
								h4,
								h5 {
									margin: 0 0 0.2rem 0;
									color: #333333;
								}
								.step-subtitle {
									font-weight: lighter;
									margin: 0;
									color: #555555;
								}
							}
						}
					}
				}
				.content {
					margin: 1.5rem 0;
				}
				.bottom {
					position: relative;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: rem(15px 0);
					@include tablet {
						max-width: 620px;
						margin: rem(20px auto 0);
						padding-bottom: 0;
					}
					&.only-next {
						justify-content: flex-end;
					}
					.stepper-button {
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.button {
							color: rgba(58, 171, 89, 0.5);
							height: 56px;
							&.is-info.is-outlined {
								&:hover,
								&:focus {
									span {
										color: $white;
									}
								}
								span {
									color: $green-lighter;
								}
							}
						}
						&.next {
							&.deactivated {
								.button {
									color: transparent;
									background-color: $grey-light;
									cursor: not-allowed;
								}
							}
						}
						&.previous {
							color: #333333;
						}
					}
				}
			}
			.is-dotted {
				margin-bottom: rem(20px);
				align-items: flex-end;
				&:before {
					bottom: -2px;
				}
				span:first-child {
					margin-top: rem(7px);
					text-transform: uppercase;
					font-weight: 600;
					color: $grey;
					max-width: 100px;
					@include tablet {
						color: $black;
						text-transform: none;
						font-weight: 300;
						max-width: none;
					}
					& + span {
						color: $black;
						font-weight: 600;
						@include tablet {
							color: $green;
							font-weight: 300;
						}
					}
				}
			}
		}
	}
}
</style>
