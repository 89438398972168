<template>
    <nav class="navbar is-fixed-top" v-if="$store.state.user">
        <div class="navbar-brand">
            <a :href="'/home'" class="navbar-item" v-if="isOutside">
                <img src="./../assets/images/logo.png" alt="Akademia Eurocash" />
            </a>
            <router-link class="navbar-item" to="/" v-else>
                <img src="./../assets/images/logo.png" alt="Akademia Eurocash" />
            </router-link>
            <div class="navbar-burger burger"
                 data-target="navbarExampleTransparentExample"
                 @click="toggleNavbar"
                 :class="{ 'is-active': showSidbar }">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="navbar-menu">
            <div class="navbar-start">
                <template v-for="(link, index) in $store.state.user.Main" :to="link.Url">
                    <a :key="index"
                       :href="link.Url"
                       class="navbar-item is-info has-text-weight-semibold is-size-7-em"
                       v-if="link.IsExternalUrl"
                       v-html="link.Label"></a>
                    <a :key="index"
                       :href="'/home' + link.Url"
                       class="navbar-item is-info has-text-weight-semibold is-size-7-em"
                       v-else-if="isOutside"
                       v-html="link.Label"></a>
                    <router-link :key="index"
                                 :to="link.Url"
                                 class="navbar-item is-info has-text-weight-semibold is-size-7-em"
                                 v-else-if="!isOutside && !link.IsPicture"
                                 v-html="link.Label"></router-link>
                    <router-link :key="index"
                                 :to="link.Url"
                                 class="navbar-item is-info has-text-weight-semibold is-size-7-em"
                                 v-else-if="!isOutside && link.IsPicture"
                                 v-html="link.Label">
                        <!--<img class="aue-deskt-img" src="/assets/2018/svg/logo_AUE.svg" alt="AUE TV" />-->
                    </router-link>
                </template>
                <nav class="navbar" role="navigation" aria-label="dropdown navigation">
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-item has-text-weight-semibold is-size-7-em">
                            <PersonIcon class="person-icon" />
                            {{ $store.state.user.Login | truncate(15) }}
                            <DwnArrowIcon class="dwn-arrow-icon" />
                        </a>
                        <div class="navbar-dropdown">
                            <a class="navbar-item has-arrow has-text-left has-text-weight-semibold is-size-7-em"
                               v-for="(link, index) in $store.state.user.User"
                               :key="index"
                               :to="link.Url"
                               @click.prevent="goToUrlHandler(link)">{{ link.Label }}</a>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="navbar-end">
                <div class="navbar-item is-paddingless">
                    <div class="field is-grouped">
                        <p class="control">
                            <a class="button is-info" :href="'/home/kontakt'" v-if="isOutside">
                                <span class="icon">
                                    <QuestionIcon />
                                </span>
                                <span class="is-size-7-em">Pomoc i kontakt</span>
                            </a>
                            <router-link class="button is-info" to="/kontakt" v-else>
                                <span class="icon">
                                    <QuestionIcon />
                                </span>
                                <span class="is-size-7-em">Pomoc i kontakt</span>
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <aside class="menu is-hidden-desktop" :class="{ 'is-active': showSidbar }">
            <span v-touch:tap="toggleNavbar"></span>
            <div v-touch:swipe.left="toggleNavbar" v-touch:swipe.right="toggleNavbar">
                <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
                   @click="toggleNavbar">
                    ZAMKNIJ
                    <button class="delete is-small"></button>
                </p>
                <ul class="menu-list">
                    <li v-for="(link, index) in $store.state.user.Main" :key="index">
                        <a :key="index"
                           :href="'/home' + link.Url"
                           class="navbar-item is-info has-text-weight-semibold is-size-7-em"
                           v-if="isOutside">
                            <span class="icon">
                                <svg width="30" height="24" viewBox="0 0 30 24">
                                    <use :xlink:href="'/assets/2018/svg/labels/' + link.Icon + '.svg#' + link.Icon" />
                                </svg>
                            </span>
                            <span class="is-size-7 is-info has-text-weight-semibold is-uppercase"
                                  v-html="link.Label"></span>
                        </a>
                        <router-link class="navbar-item is-flex"
                                     :to="link.Url"
                                     @click.native="showSidbar = false"
                                     v-else-if="!isOutside && !link.IsPicture">
                            <span class="icon">
                                <svg width="30" height="24" viewBox="0 0 30 24">
                                    <use :xlink:href="'/assets/2018/svg/labels/' + link.Icon + '.svg#' + link.Icon" />
                                </svg>
                            </span>
                            <span class="is-size-7 is-info has-text-weight-semibold is-uppercase"
                                  v-html="link.Label">}</span>
                        </router-link>
                        <router-link class="navbar-item is-flex"
                                     :to="link.Url"
                                     @click.native="showSidbar = false"
                                     v-else-if="!isOutside && link.IsPicture">
                            <span class="icon"></span>
                            <span class="is-size-7 is-info has-text-weight-semibold is-uppercase"
                                  v-html="link.Label"></span>
                        </router-link>
                    </li>
                    <li>
                        <b-collapse :open.sync="isOpen">
                            <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': isOpen }">
                                <span class="icon is-paddingless">
                                    <svg width="20" height="23" viewBox="0 0 20 23" class="is-paddingless">
                                        <use xlink:href="/assets/2018/svg/labels/login.svg#login" />
                                    </svg>
                                </span>
                                <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">{{ $store.state.user.Login }}</span>
                            </span>
                            <div>
                                <div class="navbar-content">
                                    <ul>
                                        <li v-for="(link, index) in $store.state.user.User"
                                            :key="index"
                                            :class="{ 'is-active': showSidbar }">
                                            <a class="navbar-item has-text-left has-text-weight-semibold is-size-7 is-uppercase"
                                               :to="link.Url"
                                               @click.prevent="goToUrlHandler(link), showSidbar = false">{{ link.Label }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <li class="is-paddingless">
                        <a class="button is-info is-flex"
                           :href="'/home/kontakt'"
                           @click.native="showSidbar = false"
                           v-if="isOutside">
                            <span class="icon">
                                <QuestionIcon />
                            </span>
                            <span class="is-size-7 has-text-white">Pomoc i kontakt</span>
                        </a>
                        <router-link class="green-menu is-info is-flex"
                                     :to="'/kontakt'"
                                     @click.native="showSidbar = false"
                                     v-else>
                            <span class="icon">
                                <QuestionIcon />
                            </span>
                            <span class="is-size-7 has-text-white has-text-left has-text-weight-semibold is-uppercase">Pomoc i kontakt</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </aside>

        <b-modal :active.sync="showEBookPopup"
                 :canCancel="['escape', '', 'outside']"
                 class="modal-form modal-dialog ebook-popup"
                 :width="856"
                 scroll="keep">
            <div class="container-pop-up-ebookpoint">
                <img class="image-ebookpoint" src="/assets/2018/images/logo_e-biblio.png">

                <p v-if="codeview.IsValidUserDomain">
                    Aby skorzystać z systemu ebookpoint BIBLIO<br />
                    zarejestruj się używając <b>służbowego adresu e-mail.</b><br />
                    Aktywuj konto klikając w link, który dotarł na Twoją skrzynkę pocztową.
                </p>
                <p v-if="!codeview.IsValidUserDomain">
                    Aby skorzystać z systemu ebookpoint BIBLIO<br />
                    zarejestruj się używając swojego adresu e-mail oraz <b>indywidualnego kodu.</b><br />
                    Aktywuj konto klikając w link, który dotarł na Twoją skrzynkę pocztową.
                </p>
                <label v-if="!codeview.IsValidUserDomain" class="code-label-ebookpoint">Twój indywidualny kod: <b>{{ codeview.Code }}</b></label>
                <button v-on:click="redirect" id="button-ebook" class="button is-info is-uppercase has-text-weight-semibold has-glow-button button-ebook" style="max-width: 29rem; margin-top: 5px;">
                    Zarejestruj się w ebookpoint BIBLIO
                </button>
                <p class="admin-license-ebookpoint">Administratorem Pani/Pana danych osobowych jest Akademia Umiejętności Eurocash sp. z o.o. z siedzibą przy ul. Wiśniowej 11, 62-052 Komorniki. Pani/Pana dane osobowe będą przetwarzane w celu zapewnienia dostępu do zasobów e-biblioteki oraz w celach statystycznych. Więcej informacji na temat przetwarzania danych osobowych, w tym o przysługujących Pani/Panu prawach, znajduje się <a target="_blank" href="/assets/pdf/klauzula_rodo.pdf" style="color: #3AAB59;">TUTAJ</a></p>
                <a v-if="codeview.IsValidUserDomain" style="color: #3AAB59; display: block; text-decoration: underline; margin-top: 10px;" href="/assets/pdf/ebookpoint_bez_kodu.pdf" target="_blank">
                    Pobierz instrukcę logowania w PDF
                </a>
                <a v-if="!codeview.IsValidUserDomain" style="color: #3AAB59; display: block; text-decoration: underline; margin-top: 10px;" href="/assets/pdf/ebookpoint_z_kodu.pdf" target="_blank">
                    Pobierz instrukcę logowania w PDF
                </a>
                <a style="color: #3AAB59; display: block; text-decoration: underline; margin-top: 10px;" href="/assets/pdf/jak-korzystać-z-biblioteki-online.pdf" target="_blank">
                    Jak korzystać z biblioteki online?
                </a>
            </div>
        </b-modal>

        <b-modal :active.sync="IsShowCookiePopup"
                 :canCancel="[]"
                 class="modal-form modal-dialog"
                 :width="830"
                 scroll="keep">
            <div class="modal-cookie">
                <img src="/img/icon-cookie.svg" />
                <h2>Nowa polityka gromadzenia plików cookie</h2>
                <p>
                    Witamy! Stosujemy pliki cookie w celu zapewnienia prawidłowego funkcjonowania serwisu.<br />
                    Możemy również wykorzystywać pliki cookie własne <a href="/partnerzy/">oraz naszych partnerów</a> w celach<br />
                    analitycznych i marketingowych, w szczególności dopasowania treści reklamowych do Twoich<br />
                    preferencji. Korzystanie z analitycznych, marketingowych i funkcjonalnych plików cookie<br />
                    wymaga zgody. Jeżeli chcesz zaakceptować wszystkie pliki cookie, kliknij „Zaakceptuj<br />
                    wszystkie”. Jeżeli nie wyrażasz zgody na korzystanie przez nas z plików cookie innych niż<br />
                    niezbędne pliki cookie, kliknij „Odrzuć wszystkie”. Jeżeli chcesz dostosować swoje zgody dla<br />
                    nas i naszych partnerów, kliknij „Zarządzaj cookies”. Pamiętaj, że każdą z wyrażonych zgód<br />
                    możesz wycofać w każdym momencie, zmieniając wybrane ustawienia.<br />
                </p>
                <p>
                    Korzystanie z plików cookie we wskazanych powyżej celach związane jest z przetwarzaniem<br />
                    Twoich danych osobowych. <a href="/o-akademii/#akademia">Administratorem Twoich danych osobowych jest […].</a> W pewnych<br />
                    przypadkach administratorami danych mogą być również nasi partnerzy. Więcej informacji<br />
                    o korzystaniu przez nas i naszych partnerów z plików cookie oraz o przetwarzaniu Twoich danych<br />
                    osobowych, w tym o przysługujących Ci uprawnieniach, znajdziesz w naszej <a href="/polityka-prywatnosci/">Polityce prywatności.</a>
                </p>
                <div class="control-space">
                    <button @click="createCookie(false, true)" class="control-space-btn" style="color: #6C6C6C; ">ZARZĄDZAJ COOKIES</button>
                    <button @click="createCookie(false, false)" class="control-space-btn" style="color: #FF3333;">ODRZUĆ WSZYSTKIE</button>
                    <button @click="createCookie(true, false)" style="color: white;" class="cookie-button">ZAAKCEPTUJ WSZYSTKIE</button>
                </div>
            </div>
        </b-modal>
    </nav>
</template>

<script>
    import DwnArrowIcon from "@/assets/svg/dwn-arrow.svg";
    import PersonIcon from "@/assets/svg/person.svg";
    import QuestionIcon from "@/assets/svg/question.svg";
    import global from "@/mixins/global.js";
    export default {
        name: "TheNavBar",
        mixins: [global],
        components: {
            DwnArrowIcon,
            PersonIcon,
            QuestionIcon
        },
        props: {
            isOutside: {
                type: Boolean
            }
        },
        data() {
            return {
                showSidbar: false,
                isOpen: false,
                showEBookPopup: false,
                isActive: false,
                IsShowCookiePopup: false,
                codeview: {
                    Code: null,
                    IsValidUserDomain: false
                },
                cookieData: {
                    AnalyticalCookies: false,
                    FunctionalCookies: false,
                    AdsCookies: false,
                    SocialMediaCookies: false,
                },
            };
        },
        methods: {
            redirect() {
                window.open('https://biblio.ebookpoint.pl/rejestracja');
            },
            toggleNavbar() {
                this.showSidbar = !this.showSidbar;
            },

            goToUrlHandler(link) {
                let self = this;
                if (link.Url) {
                    link.IsExternalUrl
                        ? (window.location = link.Url)
                        : self.isOutside
                            ? (window.location = "/home" + link.Url)
                            : self.$router.push(link.Url);
                } else {
                    self.logOutHandler();
                }
            },
            openEbookPopup() {
                this.showEBookPopup = true;
            },
            createCookie(allprop, redirect) {
                this.loading = true;
                if (allprop == true) {
                    this.cookieData.AdsCookies = true;
                    this.cookieData.AnalyticalCookies = true;
                    this.cookieData.SocialMediaCookies = true;
                    this.cookieData.FunctionalCookies = true;
                }
                this.$http.post(`spa/cookies/createcookieproperty`, this.cookieData)
                    .then(() => {
                        if (redirect == true) {
                            document.location.href = 'zarządzaj-cookies'
                        }
                        this.loading = false;
                        this.IsShowCookiePopup = false;
                    }).catch(() => {
                        this.loading = false
                    });
            }
        },
        created: function () {
            let self = this;
            self.$http
				.get(`/spa/navigation/menu`)
                .then(response => {
                    self.$set(self.$store.state, "user", response.data);
                    self.$store.commit("UPDATE_STATE");
                }),
                self.$http
                    .get(`spa/user/userdomain`)
                    .then(response => {
                        this.codeview = response.data;
                    })
                    .catch(() => { });
            self.$http.get(`/spa/user/profile`).then(response => {
                this.IsShowCookiePopup = response.data.IsShowCookiePopup;
                console.log(response.data.IsShowCookiePopup)
            })

        },
        mounted: function () {
            var OEPfunc = this.openEbookPopup;

            var checkExist = setInterval(function () {
                if (document.querySelector(".ebookpoint-link")) {
                    document.querySelector(".ebookpoint-link").parentElement.addEventListener("click", OEPfunc);
                    clearInterval(checkExist);
                }
            }, 100);
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "sass-rem";
    @import "./../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .navbar {
        .navbar-brand {
            img {
                max-width: 140px;
                max-height: none;
            }
        }

        .navbar-start {
            width: 100%;
            justify-content: space-between;

            .navbar-item {
                & + nav {
                    @include widescreen {
                        width: 250px;
                    }

                    .navbar-item {
                        width: 100%;

                        & > a {
                            width: 100%;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }

        .navbar-end {
            .navbar-item {
                background: $green-lighter;

                .field.is-grouped {
                    height: 100%;
                    align-items: center;

                    p {
                        height: 100%;

                        a {
                            height: 100%;
                            padding: 0 25px 0 35px;

                            & > span {
                                @include widescreen {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                }
            }

            .icon {
                & + span {
                    display: none;

                    @include widescreen {
                        display: block;
                    }
                }
            }
        }

        .menu > div {
            overflow: auto;

            .menu-list {
                li: last-child {
                    position: relative;
                }
            }
        }
    }

    a.navbar-item:hover,
    a.navbar-item.is-active,
    .navbar-link:hover,
    .navbar-link.is-active {
        background: transparent;
    }

    .navbar-dropdown {
        padding: 0;
        flex: 2;

        @include desktop {
            font-size: 14px;
        }

        @include widescreen {
            font-size: 16px;
        }

        .navbar-item

    {
        background: $grey-dark;
        color: $white;
        padding: 15px 20px;
        border-bottom: solid 1px $grey;
        &:hover, &:focus

    {
        background: $green-lighter;
        color: white;
    }

    }

    .navbar-item:first-child {
        &:before

    {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 20px;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $grey-dark;
        z-index: 1;
    }

    &:hover,
    &:focus {
        &:before

    {
        border-bottom: 10px solid $green-lighter;
    }

    }
    }
    }

    .has-arrow {
        display: block;
        &:hover, &:focus

    {
        text-decoration: none;
    }

    &:after {
        height: 0.6em;
        width: 0.6em;
        right: 25px;
        border-color: white;
    }

    }

    .dwn-arrow-icon {
        opacity: 0.5;
        margin-left: 30px;
        margin-right: 10px;
    }

    .person-icon {
        width: 26px;
        height: 26px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        padding: 5px 5px 5px 6px;
        margin-right: 15px;
    }

    .aue-deskt-img {
        margin-top: -8px;
    }

    .container-pop-up-ebookpoint{
        max-width: 670px;
        text-align: center;
    }
    .image-ebookpoint {
        width: 155.5px;
        height: 60px;
    }
    .admin-license-ebookpoint {
        font-size: 10px;
        color: #B8B8B8;
        margin-top: 22px;
    }
    .code-label-ebookpoint {
        padding-bottom: 10px;
        margin-top: 10px; 
        display: block;
    }
    .label-ebook {
        font-size: 14px;
    }
    .label-ebook a {
        color: #3AAB59;
    }


    .modal-cookie {
        justify-content: center;
    }

    .modal-cookie img {
        margin-left: 280px;
        margin-top: 30px;
        width: 136px;
        height: 136px;
    }

    .modal-cookie h2 {
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        color: #000000;
        margin-left: 70px;
        padding-right: 70px;
    }

    .modal-cookie p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        color: #4A4A4A;
        margin-left: 70px;
    }

    .control-space {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-left: 70px;
    }

    .control-space button {
        font-size: 14px;
    }

    .control-space-btn {
        margin-right: 50px;
        margin-top: 15px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
    }

    .cookie-button {
        padding: 12px;
        background-color: #3AAB59;
        border-radius: 3px;
    }

    .cookie-button:hover {
        padding: 12px;
        background-color: #34974f;
        border-radius: 3px;
    }
</style>