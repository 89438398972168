<template>
	<section class="workshops section">
		<div class="container">
			<div class="workshops__header">
				<vue-ellipse-progress :progress="day.PercentDone"
									  :angle="-90"
									  color="white"
									  emptyColor="#6ddd8c"
									  :size="65"
									  :thickness="5"
									  emptyThickness="5"
									  lineMode="normal"
									  :legend="true"
									  :legendValue="0"
									  legendClass="legend-custom-style"
									  animation="reverse 700 400"
									  :noData="false"
									  :loading="false"
									  fontColor="white"
									  :half="false"
									  :gap="10"
									  dot="0 green"
									  fontSize="1rem">

					<span slot="legend-value">%</span>
				</vue-ellipse-progress>

				<h2>{{day.Title}}</h2>
				<input type="button" class="back" value="WRÓĆ DO PROGRAMU SZKOLENIOWEGO" @click="goBack()"/>
				<div class="workshops__header--line"></div>
				<p class="workshops__header--title">Programy szkoleniowe on-line</p>
				<p class="workshops__header--text">{{day.Text}}</p>
			</div>
			<div class="columns">
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full" v-for="task in day.Tasks" :key="task.Id">
							<BaseCardOnboardingTask @goToUrlHandler="goToTaskView(task)" :task="task" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BaseCardOnboardingTask from "@/components/BaseCardOnboardingTask.vue";
import global from "@/mixins/global.js";

export default {
	name: "OnboardingTasks",

	mixins: [global],

	components: {
		BaseCardOnboardingTask
	},

	props: {
		search: {
			type: String
		}
	},

	activated: function () { },

	data() {
		return {
			day: {},
		};
	},

	methods: {
		goBack() {
			let url = `/onboardings/${this.$route.params.programId}/week/${this.day.WeekId}`;
			this.goToUrlHandler(url);
		},

		goToTaskView(task) {
			let self = this;
			let url = '';

			switch (task.TastTypeDescription) {
				case 'ArticleToReadTask':
					if (task.Document) {
						self.setTaskAsDone(task, () => { window.open(task.Document, '_blank'); });
					}

					if (task.ArticleUrl) {
						self.setTaskAsDone(task, () => { window.open(task.ArticleUrl, '_blank'); });
					}
					break;

				case 'DescriptionTask':
					self.setTaskAsDone(task, () => { self.goToUrlHandler(`/onboardings/${self.$route.params.programId}/day/${self.$route.params.dayId}/descriptionTask/${task.Id}`); })
					break;

				case 'ElearningTask':
					url = `/kursy-e-learningowe/${task.ElearningCourseId}/program`;
					break;

				case 'FormTask':
					if (!task.IsDone) {
						url = `/onboardings/${self.$route.params.programId}/day/${self.$route.params.dayId}/form/${task.Id}`;
					}
					break;

				case 'VideoTask':
					url = `/kursy-e-learningowe/${task.VideoId}/program`;
					break;

				case 'Exam':
					if (!task.IsDone) {
						url = `/onboardings/${self.$route.params.programId}/day/${self.$route.params.dayId}/exam/${task.Id}`;
					}
					break;
			}

			if (url !== '') {
				var routeData = this.$router.resolve(this.$router.currentRoute.path);
			 
				localStorage.setItem('prevUrl', routeData.href);
						
				routeData = this.$router.resolve(url);
				window.open(routeData.href, '_blank');
			}    
		},

		loadTasksHandler() {
			let self = this;
			self.$http
				.get(`spa/Onboarding/${self.$route.params.programId}/Day/${self.$route.params.dayId}`)
				.then(response => {
					self.day = response.data;
				})
				.catch((e) => { console.log(e);});
		},

		setTaskAsDone(task, callback) {

			let self = this;
			self.$http
				.get(`spa/Onboarding/${self.$route.params.programId}/SetTaskAsDone/${task.Id}`)
				.then(() => {
					callback();
				})
				.catch((e) => { console.log(e); });
			}
		},

	watch: { },

	created: function () {
		let self = this;
		document.addEventListener("visibilitychange", function () {
			if (!document.hidden) {
				self.loadTasksHandler();
			}
		});

		this.loadTasksHandler();
	}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.workshops {
	padding-bottom: rem(60px);

	&__header {
		background-color: $green-lighter;
		padding: 35px 75px;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		p{
			width: 100%;
			font-size: 14px;
			color: white;
		}

		&--title{
			font-weight: bold;
			margin: 5px 0 10px 0;
		}

			&--line{
				border-bottom: 1px solid white;
				width: 100%;
				margin: 10px 0;
			}
	}

	input.back {
		color: white;
		background-color: $green-lighter;
		border: 1px solid white;
		font-size: 14px;
		font-weight: bold;
		margin-left: auto;
		height: 43px;
		cursor: pointer;
	 
		&:focus {
			outline: none;
		}
	}

	.title {
		a {
			margin-left: 15px;
		}
	}

	h2 {
		display: inline-block;
		color: white;
		text-transform: uppercase;
		font-weight: bold;
		margin-left: 25px;
	}

	.title {
		margin-left: rem(30px);
		padding-top: rem(10px);
		padding-bottom: rem(10px);
	}

	.button.is-light.is-outlined {
		padding: 25px 50px;
		border-color: $grey-lighter;

		&:hover, &:focus {
			background: transparent;
			border-color: $grey-light;
		}
	}

	.columns:first-child {
		margin-top: 10px;
	}

	.columns {
		.column.is-full {
			padding: 0.4rem 0.75rem;
		}
	
		.menu {
			text-align: left;

			a {
				padding-left: 0;
				width: 100%;
			}

			&-list {
				li {
					justify-content: space-between;
				}

				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
		}
	}

	li {
		letter-spacing: 1px;

		a {
			padding-right: 5px;
		}
	}

	.delete {
		display: none;
		align-self: center;
	}

	.menu-label {
		.delete {
			display: inline-block;
		}
	}

	.is-selected {
		font-weight: bold;

		.delete {
			float: right;
			display: block;
			background-color: #bebebe;
		}

		.delete:before,
		.delete:after {
			background-color: white;
		}
	}

	.control {
		position: relative;
		z-index: 2;

		.icon {
			position: absolute;
			right: 10px;
			top: 10px;
			z-index: 3;
			color: $grey-light;
			display: none;

			@include desktop {
				display: block;
			}
		}
	}

	.workshops-modal {
		.intro-close {
			width: 240px;
			display: block;
			padding: 15px;
			border-radius: 4px;
			border: 2px solid $green-lighter;
			background-color: $green-lighter;
			color: $white;
			margin: 20px auto 0;
			-webkit-transition: 0.3s all ease-in-out;
			-o-transition: 0.3s all ease-in-out;
			transition: 0.3s all ease-in-out;
			cursor: pointer;

			&:hover {
				background-color: $white;
				color: $green-lighter;
			}

		}
	}
}
</style>
