
<template>
    <section class="hero hero-image section">
      <div class="hero-body is-flex">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <h1 class="title is-size-1 is-size-9-tablet has-text-weight-light has-text-left has-text-centered-mobile has-text-shadow">Skorzystaj ze szkoleń <p>w <strong class="has-text-weight-semibold">Akademii Umiejętności Eurocash</strong></p></h1>
                    <div class="columns is-multiline other-brands is-flex-mobile">
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://sklepyabc.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/abc.png" alt="ABC"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://www.delikatesy.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/delikatesy_centrum.png" alt="Delikatesy Centrum"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://www.duzyben.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/duzy_ben.png" alt="Duży Ben"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://www.eurosklep.eu/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/euro_sklep.png" alt="Euro sklep"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://sklepygama.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/gama.png" alt="Gama"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://www.groszek.com.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/groszek.png" alt="Groszek"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://drogeriekoliber.pl" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/koliber.png" alt="Koliber"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://kontigo.com.pl" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/kontigo.png" alt="Kontigo"></figure></a></div>
                      <div class="column is-4 is-6-mobile is-flex"><a href="https://lewiatan.pl/" target="_blank"><figure class="image is-flex"><img src="/assets/2018/images/sieci/lewiatan.png" alt="Lewiatan"></figure></a></div>
                    </div>
                </div>
                <div class="column is-half">
                  <LoginForm @openResetModalHandler="isResetModalActive = true" id="login"/>
                </div>
            </div>
        </div>
      </div>   
      <BaseModalNav v-if="isResetModalActive || isNewPasswordModalActive" @closeModalHandler="isResetModalActive = false, isNewPasswordModalActive = false" :hasBackBtn="true"/>
      <b-modal :active.sync="isResetModalActive" :width="470" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-fullscreen">
        <div class="columns is-paddingless is-marginless">
            <div class="column">
              <LoginResetPasswordForm @closeModalHandler="isResetModalActive = false"/>
            </div>
        </div>
      </b-modal>    
      <b-modal :active.sync="isNewPasswordModalActive" :width="470" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-fullscreen">
        <div class="columns is-paddingless is-marginless">
            <div class="column">
              <LoginNewPassword @closeModalHandler="isNewPasswordModalActive = false" :secret="secret"/>
            </div>
        </div>
      </b-modal>    
    </section>
</template>

<script>
import BaseModalNav from "@/components/BaseModalNav.vue";
import LoginForm from "@/components/Containers/ViewLogin/LoginForm.vue";
import LoginResetPasswordForm from "@/components/Containers/ViewLogin/LoginResetPasswordForm.vue";
import LoginNewPassword from "@/components/Containers/ViewLogin/LoginNewPassword.vue";
import global from "@/mixins/global.js";

export default {
  name: "Login",
  components: {
    BaseModalNav,
    LoginForm,
    LoginResetPasswordForm,
    LoginNewPassword
  },
  mixins: [global],
  data() {
    return {
      isResetModalActive: false,
      isNewPasswordModalActive: false,
      secret: ""
    };
  },
  created: function() {
    let secret = this.getUrlParameter("secretsetpass");
    if (secret) {
      this.secret = secret;
      this.isNewPasswordModalActive = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.hero-image {
  min-height: 80vh;
  background-image: url("./../../../assets/images/home-visual.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  @include desktop {
    background-position: center bottom;
    background-size: 100%;
    padding-bottom: 70px;
  }
  @include widescreen {
    background-position: center bottom -110px;
  }
  .hero-body {
    align-items: center;
  }
  .title {
    margin-top: rem(130px);
    color: $white;
  }
}
</style>