<template>
    <form>
        <h2 class="is-size-1 has-text-weight-light has-text-left">Resetowanie hasła</h2>
        <div class="field">
            <div class="control">
              <FloatingLabel :config="{label: 'Wpisz nowe hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.NewPassword.$error }">
                  <input v-model="formData.NewPassword" @blur="$v.formData.NewPassword.$touch()" type="password" class="input" required>
                  <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.NewPassword)" :key="index">{{ error }}</span>
              </FloatingLabel>
              <FloatingLabel :config="{label: 'Potwierdź hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.NewRePassword.$error }">
                  <input v-model="formData.NewRePassword" @blur="$v.formData.NewRePassword.$touch()" type="password" class="input" required>
                  <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.NewRePassword)" :key="index">{{ error }}</span>
              </FloatingLabel>
            </div>
        </div>
        <p class="is-size-8 has-text-weight-light has-text-danger has-text-left" v-if="errorMsg">{{ errorMsg }}</p>
        <p class="is-size-8 has-text-weight-light has-text-primary has-text-left" v-if="successMsg">{{ successMsg }}</p>
        <div class="field is-grouped">
            <div class="control">
                <a class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" :class="{ 'is-loading': loading }"  @click="submitFormHandler()">
                    <span class="is-size-7-em">Zatwierdź i zaloguj</span>
                </a>
            </div>
        </div>
    </form>
</template>

<script>
import FloatingLabel from "@/components/BaseFloatingLabel";
import global from "@/mixins/global.js";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "LoginNewPassword",
  mixins: [global],
  components: {
    FloatingLabel
  },
  props: {
    secret: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formData: {
        NewPassword: "",
        NewRePassword: "",
        Secret: this.secret
      },
      errorMsg: "",
      successMsg: "",
      loading: false
    };
  },
  validations: {
    formData: {
      NewPassword: {
        required
      },
      NewRePassword: {
        required,
        sameAsPassword: sameAs("NewPassword")
      }
    }
  },
  methods: {
    submitFormHandler: function() {
      let self = this;
      self.$v.$touch();
      if (!this.$v.$invalid) {
        self.loading = true;
        this.$http
          .post(`/spa/user/resetpassword`, self.formData)
          .then(response => {
            self.successMsg = response.data.Message;
            self.loading = false;
            for (let prop of Object.getOwnPropertyNames(self.formData))
              self.formData[prop] = "";
            self.$v.$reset();
            setTimeout(() => {
              self.$emit("closeModalHandler");
            }, 3000);
          })
          .catch(error => {
            self.loading = false;
            self.errorMsg = error.response.data.Message;
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

form {
  max-width: 376px;
  padding: 0;
  margin: rem(0 auto);
  background: $white;
  border-radius: 4px;
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: rem(10px);
  }
  p.has-text-danger {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .field:not(:last-child) {
    margin-bottom: rem(20px);
    margin-top: rem(40px);
  }
  .is-grouped {
    margin-bottom: rem(10px);
  }
  .control {
    width: 100%;
  }
  .field .control .input__container {
    margin-bottom: 30px;
    & + .input__container.has-error {
      margin-bottom: 50px;
    }
  }
  .is-loading {
    .icon,
    span {
      visibility: hidden;
    }
  }
  .button.is-info {
    color: rgba(58, 171, 89, 0.5);
    padding: rem(10px 60px);
    font-size: rem(16px);
    max-width: none;
    margin-top: 0;
    &:hover,
    &.is-danger.is-hovered {
      color: rgba(58, 171, 89, 0.5);
    }
    span {
      color: $white;
      letter-spacing: 0.5px;
    }
  }
}
</style>