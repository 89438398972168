<template>
  <b-modal
    :active.sync="isActive"
    :width="830"
    :canCancel="['escape', 'x', 'outside']"
    :on-cancel="$parent.closeModalInfo"
    scroll="keep"
    class="modal-dialog"
    id="ViewUserData__ModalInfo"
  >
    <button type="button" class="modal-close is-large" @click="$parent.closeModalInfo"></button>

    <!-- Dialog -->
    <div class="columns">
      <div class="column is-full">
        <p class="is-size-2 has-text-centered has-text-weight-light">{{ message }}</p>
      </div>
    </div>

    <!-- Button -->
    <div class="columns is-centered">
      <div class="column is-full has-text-centered">
        <button
          @click="$parent.closeModalInfo"
          class="button is-info has-padding-left-20 has-padding-right-20"
          :style="{ maxWidth: 'unset' }"
        >{{ buttonLabel }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
	props: {
		message: {
			type: String,
			required: false,
		},
		buttonLabel: {
			type: String,
			required: false,
		},
		isActive: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
