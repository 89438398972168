<template>
  <section class="account section">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <h2 class="is-size-2 has-text-left has-text-weight-light">Dane użytkownika</h2>
                    <transition name="has-fade">
                        <div v-if="edit">
                          <form>
                            <div class="field">
                              <div class="control" :class="{ 'disabled': formData.FirstName }">
                                <FloatingLabel :config="{label: 'Imię', height: 56, hasContent: formData.FirstName ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.FirstName.$error }">
                                    <input :disabled="formData.FirstName" v-model="formData.FirstName" @blur="$v.formData.FirstName.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.FirstName)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field">
                              <div class="control" :class="{ 'disabled': formData.LastName }">
                                <FloatingLabel :config="{label: 'Nazwisko', height: 56, hasContent: formData.LastName ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.LastName.$error }">
                                    <input :disabled="formData.LastName" v-model="formData.LastName" @blur="$v.formData.LastName.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.LastName)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field">
                              <div class="control" :class="{ 'disabled': formData.WorkPosition }">
                                <FloatingLabel :config="{label: 'Stanowisko', heighWorkPositiont: 56, hasContent: formData.WorkPosition ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.WorkPosition.$error }">
                                    <input :disabled="formData.WorkPosition" v-model="formData.WorkPosition" @blur="$v.formData.WorkPosition.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.WorkPosition)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field">
                              <div class="control" :class="{ 'disabled': formData.Network }">
                                <FloatingLabel :config="{label: 'Sieć', height: 56, hasContent: formData.Network ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.Network.$error }">
                                    <input :disabled="formData.Network"  v-model="formData.Network" @blur="$v.formData.Network.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.Network)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field">
                              <div class="control" :class="{ 'disabled': formData.Email }">
                                <FloatingLabel :config="{label: 'Adres e-mail', height: 56, hasContent: formData.Email ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.Email.$error }">
                                    <input :disabled="formData.Email" v-model="formData.Email" @blur="$v.formData.Email.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.Email)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field">
                              <div class="control">
                                <FloatingLabel :config="{label: 'Telefon', height: 56, hasContent: formData.Phone ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.Phone.$error }">
                                    <input v-model="formData.Phone" @blur="$v.formData.Phone.$touch()" type="text" class="input">
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.Phone)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field" v-if="formData.EditableFields.indexOf('CardNumber') !== -1">
                              <div class="control">
                                <FloatingLabel :config="{label: 'Numer karty pracownika', height: 56, hasContent: formData.CardNumber ? true : false, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.CardNumber.$error }">
                                    <input v-model="formData.CardNumber" @blur="$v.formData.CardNumber.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.CardNumber)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="field" v-if="formData.EditableFields.indexOf('SalesmanNumber') !== -1">
                              <div class="control">
                                <FloatingLabel :config="{label: 'Identyfikator', height: 56, hasClearButton: false, hasContent: formData.SalesmanNumber ? true : false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formData.SalesmanNumber.$error }">
                                    <input v-model="formData.SalesmanNumber" @blur="$v.formData.SalesmanNumber.$touch()" type="text" class="input" required>
                                </FloatingLabel>
                                <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formData.SalesmanNumber)" :key="index">{{ error }}</span>
                              </div>
                            </div>  
                            <div class="is-flex">
                              <a class="button is-danger is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" :class="{ 'is-loading': loading }"  @click="edit = false">
                                <span class="is-size-7-em has-text-white">ANULUJ</span>
                              </a>
                              <a class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" @click="submitProfileFormHandler()" :class="{ 'is-loading': loading }">
                                <span class="is-size-7-em has-text-white">ZAPISZ</span>
                              </a>
                            </div>
                            <p class="is-size-8 has-text-weight-light has-text-danger has-text-centered" v-if="errorMsg">{{ errorMsg }}</p>
                            <p class="is-size-8 has-text-weight-light has-text-primary has-text-centered" v-if="successMsg">{{ successMsg }}</p>
                          </form>
                        </div>
                      </transition>
                      <transition name="has-fade">
                        <div v-if="!edit">
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Imię</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.FirstName }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Nazwisko</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.LastName }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Stanowisko</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.WorkPosition }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Sieć</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.Network }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Adres email</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.Email }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Telefon</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.Phone }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Numer karty pracownika</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.CardNumber }}</span>
                          </div>
                          <div class="is-dotted is-flex has-text-left">
                            <span class="is-size-8 is-size-4-tablet">Identyfikator</span>
                            <span class="is-size-8 is-size-2-tablet">{{ formData.SalesmanNumber }}</span>
                          </div>
                          <a class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" @click="edit = true">
                            <span class="is-size-7-em has-text-white">Edytuj dane</span>
                          </a>
                        </div>
                    </transition>
                  </div>
                </div>
              </div> 
            </div>
        </div>
        <div class="column is-half">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <h2 class="is-size-2 has-text-left has-text-weight-light">Zmiana hasła</h2>
                    <form>
                      <div class="field">
                        <div class="control">
                          <FloatingLabel :config="{label: 'Stare hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formDataPassword.OldPassword.$error }">
                              <input v-model="formDataPassword.OldPassword" @blur="$v.formDataPassword.OldPassword.$touch()" type="text" class="input" required>
                          </FloatingLabel>
                          <span class="is-size-8 has-text-weight-light has-text-danger" v-for="(error, index) in validationMessages($v.formDataPassword.OldPassword)" :key="index">{{ error }}</span>
                        </div>
                      </div>  
                      <div class="field">
                        <div class="control">
                          <FloatingLabel :config="{label: 'Nowe hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formDataPassword.NewPassword.$error }">
                              <input v-model="formDataPassword.NewPassword" @blur="$v.formDataPassword.NewPassword.$touch()" type="text" class="input" required>
                          </FloatingLabel>
                          <span class="is-size-8 has-text-weight-light has-text-danger is-block" v-for="(error, index) in validationMessages($v.formDataPassword.NewPassword)" :key="index">{{ error }}</span>
                        </div>
                      </div>  
                      <div class="field">
                        <div class="control">
                          <FloatingLabel :config="{label: 'Powtórz nowe hasło', height: 56, hasClearButton: false, line: false, color: { focusColor: '#3AAB59', errorColor: '#de2647', lineColor: '#b5b5b5', blurredColor: '#b5b5b5'}, hasError: this.$v.formDataPassword.NewRePassword.$error }">
                              <input v-model="formDataPassword.NewRePassword" @blur="$v.formDataPassword.NewRePassword.$touch()" type="text" class="input" required>
                          </FloatingLabel>
                          <span class="is-size-8 has-text-weight-light has-text-danger is-block" v-for="(error, index) in validationMessages($v.formDataPassword.NewRePassword)" :key="index">{{ error }}</span>
                        </div>
                      </div>  
                      <a class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" :class="{ 'is-loading': loading }">
                        <span class="is-size-7-em has-text-white">Zmień hasło</span>
                      </a>
                      <p class="is-size-8 has-text-weight-light has-text-danger has-text-centered" v-if="errorMsg">{{ errorMsg }}</p>
                      <p class="is-size-8 has-text-weight-light has-text-primary has-text-centered" v-if="successMsg">{{ successMsg }}</p>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-full">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-content">
                    <h2 class="is-size-2 has-text-left has-text-weight-light">Twoja karta</h2>
                    <div class="field">
                      <div class="control">
                        <div class="input-container is-borderless is-size-7 has-text-weight-light">
                          <b-checkbox type="is-info" size="is-small">Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu kontaktowym zgodnie z ustawą z dnia 29 sierpnia 1997 roku o ochronie danych osobowych (Dz. U. z 2014 r., poz.1182 z późn. zm.), a od 25 maja 2018 zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) w celu realizacji przesłanego zgłoszenia. Jednocześnie oświadczam, iż przyjmuję do wiadomości, że: 1. Administratorem zebranych danych osobowych jest Akademia Umiejętności Eurocash sp. z o.o z siedzibą w Komornikach ul. Wiśniowa 11, 62-052 Komorniki. 2. Moje dane osobowe będą przetwarzane w celu przygotowania i przekazania odpowiedzi na przesłaną przeze mnie wiadomość. 3. Mam prawo dostępu do treści swoich danych oraz ich poprawiania. 4. Podanie danych osobowych jest dobrowolne. 5. Mam prawo do wycofania powyższej zgody w każdym momencie.</b-checkbox>
                          <b-checkbox type="is-info" size="is-small">Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu kontaktowym zgodnie z ustawą z dnia 29 sierpnia 1997 roku o ochronie danych osobowych (Dz. U. z 2014 r., poz.1182 z późn. zm.), a od 25 maja 2018 zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) w celu realizacji przesłanego zgłoszenia. Jednocześnie oświadczam, iż przyjmuję do wiadomości, że: 1. Administratorem zebranych danych osobowych jest Akademia Umiejętności Eurocash sp. z o.o z siedzibą w Komornikach ul. Wiśniowa 11, 62-052 Komorniki. 2. Moje dane osobowe będą przetwarzane w celu przygotowania i przekazania odpowiedzi na przesłaną przeze mnie wiadomość. 3. Mam prawo dostępu do treści swoich danych oraz ich poprawiania. 4. Podanie danych osobowych jest dobrowolne. 5. Mam prawo do wycofania powyższej zgody w każdym momencie.</b-checkbox>
                          <b-checkbox type="is-info" size="is-small">Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu kontaktowym zgodnie z ustawą z dnia 29 sierpnia 1997 roku o ochronie danych osobowych (Dz. U. z 2014 r., poz.1182 z późn. zm.), a od 25 maja 2018 zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.</b-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { required, sameAs, email, numeric } from "vuelidate/lib/validators";
import global from "@/mixins/global.js";
import { helpers } from "vuelidate/lib/validators";
import FloatingLabel from "@/components/BaseFloatingLabel";

const salesmanNumber = helpers.regex("salesmanNumber", /^[0-9]{1,5}$/),
  cardNumber = helpers.regex("cardNumber", /^9981[0-9]{9}$/);

export default {
  name: "Account",
  mixins: [global],
  components: {
    FloatingLabel
  },
  props: {},
  mounted() {},
  data() {
    return {
      formData: {
        FirstName: "",
        LastName: "",
        WorkPosition: "",
        Network: "",
        Email: "",
        Phone: "",
        CardNumber: "",
        SalesmanNumber: "",
        Agreement1GettingInfo: "",
        Agreement2SharingData: "",
        Agreement3StatisticData: ""
      },
      formDataPassword: {
        OldPassword: "",
        NewPassword: "",
        NewRePassword: ""
      },
      errorMsg: "",
      successMsg: "",
      loading: false,
      nextPage: false,
      edit: false
    };
  },
  validations: {
    formData: {
      FirstName: {
        required
      },
      LastName: {
        required
      },
      WorkPosition: {
        required
      },
      Network: {
        required
      },
      Email: {
        required,
        email
      },
      Phone: {
        numeric
      },
      CardNumber: {
        numeric,
        cardNumber,
        required
      },
      SalesmanNumber: {
        numeric,
        salesmanNumber
      }
    },
    formDataPassword: {
      OldPassword: {
        required
      },
      NewPassword: {
        required
      },
      NewRePassword: {
        required,
        sameAs: sameAs("NewPassword")
      }
    },
    validationGroupProfile: [
      "formData.FirstName",
      "formData.LastName",
      "formData.WorkPosition",
      "formData.Network",
      "formData.Email",
      "formData.Phone",
      "formData.CardNumber",
      "formData.SalesmanNumber"
    ]
  },
  methods: {
    submitProfileFormHandler() {
      let self = this;
      self.$v.formData.for;

      /*
      if (!this.$v.$invalid) {
        let formData = {};
        self.loading = true;
        self.formData.EditableFields.forEach(function(item) {
          formData[item] = self.formData[item];
        });
        formData.Agreement1GettingInfo = self.formData.Agreement1GettingInfo;
        formData.Agreement2SharingData = self.formData.Agreement2SharingData;
        formData.Agreement3StatisticData =
          self.formData.Agreement3StatisticData;

        self.$http
          .post(`spa/user/updateprofile`, formData)
          .then(response => {
            self.loading = false;
            self.successMsg = response.data.Message;
            setTimeout(() => {
              self.successMsg = "";
            }, 3000);
          })
          .catch(error => {
            self.loading = false;
            self.successMsg = "";
            self.errorMsg = error.response.data.Message;
          });
      }
      */
    }
  },
  created: function() {
    let self = this;
    self.$http
      .get(`/spa/user/profile`)
      .then(response => {
        self.formData = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.account {
  .card {
    &-content {
      padding-right: rem(20px);
      overflow: hidden;
      h2 {
        margin: -20px;
        background: $green-lighter;
        padding: 20px;
        margin-bottom: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: $white;
        margin-bottom: rem(20px);
        @include tablet {
          background: none;
          margin: 0;
          margin-bottom: 20px;
          padding: 0;
          color: $black;
        }
      }
      .is-dotted {
        margin-bottom: rem(20px);
        align-items: flex-end;
        &:before {
          bottom: -2px;
        }
        span:first-child {
          margin-top: rem(7px);
          text-transform: uppercase;
          font-weight: 600;
          color: $grey;
          max-width: 100px;
          @include tablet {
            color: $black;
            text-transform: none;
            font-weight: 300;
            max-width: none;
          }
          & + span {
            color: $black;
            font-weight: 600;
            @include tablet {
              color: $green;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .button {
    color: rgba(58, 171, 89, 0.5);
    border-radius: 5px;
    height: 50px;
    width: 100%;
    margin-top: rem(15px);
    &:not(:last-child) {
      margin-bottom: rem(20px);
    }
    &.is-danger {
      color: rgba(222, 38, 71, 0.5);
    }
    span {
      color: white;
      letter-spacing: 0.5px;
    }
    & + .button {
      margin-left: rem(20px);
    }
  }
  .field {
    margin-bottom: rem(20px);
    .control {
      width: 100%;
      & > span {
        display: block;
      }
      .input__container {
        margin-bottom: 0;
      }
    }
  }
  .b-checkbox {
    margin-bottom: rem(20px);
  }
}
</style>
