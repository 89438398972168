<template>
  <main>
    <Conference/>
  </main>
</template>

<script>
import Conference from "@/components/Containers/ViewConference/Conference.vue";

export default {
  name: "ViewConference",
  components: {
    Conference,
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

</style>
