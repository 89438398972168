<template>
  <main>
    <ConferenceGuest/>
  </main>
</template>

<script>
import ConferenceGuest from "@/components/Containers/ViewConferenceGuest/ConferenceGuest.vue";

export default {
  name: "ViewConferenceGuest",
  components: {
      ConferenceGuest,
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

</style>
