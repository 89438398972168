<template>
  <main>
    <BaseHero :title="'Regulamin platformy edukacyjnej'"/>
    <section class="regulations section">
      <div class="container is-container-thin">
        <div class="columns is-full">
            <div class="column">
              <ul class="has-text-weight-light has-text-left">
                <li>
                    <h2 class="is-size-2 has-text-shadow">I. Postanowienia ogólne</h2>
                    <ol>
                        <li>Niniejszy Regulamin określa zasady i&nbsp;warunki korzystania ze&nbsp;szkoleń i&nbsp;zasobów, udostępnionych za&nbsp;pośrednictwem publicznej sieci Internet w&nbsp;oparciu o&nbsp;platformę edukacyjną, dostępną pod adresem: <a :href="rootUrl" target="_blank">www.akademiaeurocash.com.pl</a>, zwaną dalej „Platformą&nbsp;edukacyjną”.</li>
                        <li>Właścicielem platformy edukacyjnej, o&nbsp;której mowa w&nbsp;pkt 1.powyżej jest: Eurocash S.A. z&nbsp;siedzibą w&nbsp;Komornikach ul.&nbsp;Wiśniowa 11, 62-052 Komorniki wpisaną przez Sąd Rejonowy Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy KRS, do rejestru przedsiębiorców pod numerem KRS 0000125373, o kapitale zakładowym w wysokości 137.486.636,00 zł, NIP 779-19-06-082 dalej Usługodawca lub Eurocash.</li>
                        <li>Akceptacja niniejszego Regulaminu jest warunkiem korzystania ze szkoleń oraz zasobów Serwisu.</li>
                        <li>Zasoby – materiały o charakterze edukacyjno-informacyjnym, udostępnione na Platformie edukacyjnej w postaci prezentacji multimedialnych, szkoleń, testów, e-learningów, itp. </li>
                        <li>Platforma edukacyjna - strona www, znajdująca się pod adresem www.akademiaeurocash.com.pl (wraz ze wszystkimi podstronami, stanowiącymi integralną część Serwisu), umożliwiająca Użytkownikom korzystanie z zasobów Serwisu, w szczególności przeglądanie zasobów Serwisu, dostępu do kursów i szkoleń w systemie e-learning, udostępnionych dokumentów, grafik i innych materiałów, uczestnictwo w kursach w systemie e-learning, udostępnionych z wykorzystaniem systemu informatycznego lms (learning management system) za pośrednictwem publicznej sieci Internet</li>
                        <li>Szkolenie e-learningowe - zestaw prezentacji, ćwiczeń, testów, materiałów poglądowych, zapisów audio i video, połączonych w merytoryczną całość, obejmujący zakres wiedzy i praktyki, dostępne poprzez platformę edukacyjną.</li>
                        <li>Szkolenie warsztatowe – szkolenie realizowane na sali szkoleniowej poprzez trenera. </li>
                        <li>Partnerem handlowym jest samodzielny przedsiębiorca, któremu spółka Eurocash S.A. z siedzibą w Komornikach lub spółka zależna od Eurocash S.A.. udzieliła prawa do posługiwania się nazwą Sieci Handlowej np: „Delikatesy Centrum, Lewiatan, Groszek, Gama, Eurosklep, abc” jednocześnie obejmując opieką organizacyjną, handlową i logistyczną prowadzony/e przez Partnera sklep/y dalej zwany „Partnerem Handlowym Eurocash”, „Partnerem” lub „Franczyzobiorcą”.</li>
                        <li>Pracownicy to pełnoletnie, zamieszkałe na terytorium Polski, osoby fizyczne, zatrudnione przez Partnerów, niezależnie od rodzaju stosunku prawnego stanowiącego podstawę zatrudnienia (np. umowa o pracę, umowa o dzieło, umowa zlecenie), pracujące w sklepie Partnera należącym np. do sieci Delikatesy Centrum, Lewiatan, Groszek, Gama, Eurosklep, abc, zwane w niniejszym Regulaminie „Pracownikami”. Dla uniknięcia wątpliwości Pracownikami w rozumieniu niniejszego Regulaminu nie są osoby zatrudnione przez Partnera w sklepach nienależących do sieci franczyzowej lub partnerskiej.</li>
                        <li>Użytkownik - osoba, która po dokonaniu rejestracji i uzyskaniu loginu oraz hasła uzyskała dostęp do Platformy edukacyjnej.</li>
                        <li>Login - ciąg znaków alfanumerycznych (jawny) lub indywidualny adres e-mail.</li>
                        <li>Hasło - ciąg znaków alfanumerycznych (niejawny, znany tylko Uczestnikowi), niezbędny do autoryzowanego dostępu do Serwisu i korzystania z jego zasobów, w tym z udziału w udostępnionych w Serwisie szkoleniach.</li>
                        <li>Formularz – formularz rejestracyjny oraz formularze zapisów na udostępnione w Serwisie szkoleniach.</li>
                        <li>„Sklep pracownika” to np. sklep Delikatesy Centrum, Lewiatan, Groszek, Gama, Eurosklep, abc należący do Partnera, w którym Pracownik wykonuje w sposób stały i ciągły pracę. </li>
                        <li>„Akademia Umiejętności Eurocash” to program szkoleniowy skierowany do Partnerów Handlowych Eurocash i innych kontrahentów spółek należących do grupy kapitałowej Eurocash S.A. oraz pracowników tych kontrahentów i Partnerów. </li>
                        <li>Usługodawca zastrzega sobie prawo do ustalenia lub zmiany wysokości opłaty rocznej za korzystanie z zasobów platformy edukacyjnej.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">II. Warunki techniczne</h2>
                    <ol>
                        <li>Do korzystania z zasobów platformy, w tym uczestnictwa w organizowanych za pośrednictwem serwisu szkoleniach, niezbędne jest posiadanie dostępu do Internetu oraz komputera/tabletu/telefonu komórkowego, umożliwiających prawidłowe wyświetlanie zawartości serwisu. Usługodawca dopełni należytej staranności, aby Serwis wyświetlał się poprawnie na wszystkich wymienionych urządzeniach oraz aby korzystając z tych urządzeń Użytkownik mogli bez niedogodności korzystać z zasobów serwisu.</li>
                        <li>Usługodawca nie ponosi odpowiedzialności za brak możliwości korzystania z części lub całości zasobów Serwisu, jeśli niemożność ta spowodowana jest brakami technicznymi po stronie Użytkownika w szczególności z powodu niewłaściwej konfiguracji przeglądarki internetowej czy niedostatecznej wydajności sprzętu.</li>
                        <li>Usługodawca gwarantuje, iż korzystanie z zasobów Serwisu nie powoduje po stronie Użytkownika instalacji jakiegokolwiek oprogramowania bez jego wiedzy i zgody. Do korzystania z zasobów Serwisu nie jest wymagane instalowanie oprogramowania dedykowanego.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">III. Zasady uzyskiwania dostępu do szkoleń, zasobów i platformy edukacyjnej</h2>
                    <ol>
                        <li>
                            Szkolenia, Zasoby i Platforma edukacyjna dostępne są dla Pracowników upoważnionych przez Partnera Handlowego czyli w szczególności:
                            <ol>
                                <li>Kierowników regionalnych </li>
                                <li>Doradców klienta</li>
                                <li>Właścicieli Sklepów</li>
                                <li>Wszystkich aktualnie zatrudnionych pracowników Sklepów </li>
                            </ol>
                        </li>
                        <li>
                            Aby uzyskać status Użytkownika i dostęp do Serwisu, Szkoleń i innych zasobów Portalu edukacyjnego konieczne jest przejście przez osobę następującej procedury przystąpienia do projektu:
                            <ol>
                                <li>
                                    Partnerzy, którzy chcą zgłosić swój udział muszą zalogować się poprzez serwis internetowy www.akademiaeurocash.com.pl, aby otrzymać login i hasło do serwisu zgłaszają taką informację na adres mailowy kontakt@akademiaeurocash.com.pl, podając:
                                    <ol>
                                        <li>Nazwę sieci oraz nazwę sklepu </li>
                                        <li>Unikatowy numer sklepu oraz NIP</li>
                                        <li>Adres sklepu </li>
                                        <li>Telefon do sklepu </li>
                                        <li>Imię i nazwisko Partnera wraz z telefonem i adresem mailowym </li>
                                        <li>Imię i nazwisko Kierownika sklepu wraz z telefonem i adresem mailowym</li>
                                    </ol>
                                </li>
                                <li>Partnerzy/Kierownik Sklepu na podany adres mailowy otrzymają link rejestrujący i instrukcję rejestracji Pracowników na Stronie </li>
                                <li>
                                    Po otrzymaniu linku rejestracyjnego, Partner bądź Kierownik Sklepu Partnera powinien założyć konta wszystkim Pracownikom, którzy mogą korzystać z platformy. Pracownik logując się pierwszy raz na Stronie powinien wypełnić formularz rejestracyjny zawierający następujące dane:
                                    <ol>
                                        <li>imię i nazwisko Pracownika dokonującego rejestracji</li>
                                        <li>adres poczty elektronicznej Pracownika,</li>
                                        <li>nazwa firmy Partnera (sklepu), w której zatrudniony jest Pracownik oraz jej dokładny adres,</li>
                                        <li>kod sklepu, w którym zatrudniony jest Pracownik.</li>
                                        <li>adres korespondencyjny Pracownika</li>
                                    </ol>
                                </li>
                                <li>Dostęp do Szkoleń oraz innych Zasobów udostępnianych za pośrednictwem Platformy edukacyjnej wymaga od Użytkownika podania Loginu i hasła. Użytkownik może zmienić Hasło w dowolnym momencie po zakończeniu procesu Rejestracji.</li>
                            </ol>
                        </li>
                        <li>Z platformy mogą korzystać Pracownicy Partnerów Handlowych, którzy otrzymali zgodę Partnera handlowego </li>
                        <li>Korzystanie ze szkoleń warsztatowych podlega opłacie rocznej ponoszonej przez Partnera Handlowego, której wysokość wynosi 250 zł netto za każdy sklep Partnera Handlowego. Usługodawca zastrzega sobie prawo do zmiany wysokości w/w opłat. </li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">IV. Obowiązki użytkownika</h2>
                    <ol>
                        <li>Użytkownik jest zobowiązany do korzystania z zasobów Platformy w sposób zgodny z obowiązującym prawem, normami społecznymi i obyczajowymi oraz postanowieniami Regulaminu.</li>
                        <li>Korzystanie ze wszystkich Zasobów Platformy edukacyjnej wymaga logowania.</li>
                        <li>Rejestrując się do Portalu edukacyjnego oraz podając dane w zakładce „moje konto” Użytkownik potwierdza autentyczność i zgodność ze stanem faktycznym danych podanych w toku rejestracji oraz wyraża zgodę na postanowienia niniejszego Regulaminu, uznając jego wiążący charakter.</li>
                        <li>
                            Użytkownik zobowiązany jest w szczególności:
                            <ol>
                                <li>podać prawdziwe dane podczas Rejestracji oraz w zakładce „moje konto”</li>
                                <li>powiadomić Usługodawcę o wszelkich nieprawidłowościach w pracy, usterkach oraz o przerwach w dostępie do Szkoleń</li>
                                <li>do ochrony oraz nieudostępniania osobom trzecim swojego Loginu i Hasła</li>
                            </ol>
                        </li>
                        <li>Użytkownik nie może przenieść praw i obowiązków wynikających z udziału w Szkoleniu na osoby trzecie.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">V. Usunięcie konta użytkownika i inne ograniczenia w dostępie do platformy edukacyjnej</h2>
                    <ol>
                        <li>
                            Usługodawca jest uprawniony do usunięcia Loginu Użytkownika lub innego ograniczenia dostępu do zasobów Platformy edukacyjnej - według uznania Usługodawcę - w przypadku, gdy:
                            <ol>
                                <li>Użytkownik nie dokonał logowania do Szkolenia przez okres kolejnych 365 dni,</li>
                                <li>Użytkownik wykorzystuje konto niezgodnie z postanowieniami obowiązującego prawa lub Regulaminu,</li>
                                <li>Login zdefiniowany przez Użytkownik jest wyrażeniem powszechnie uznanym za obraźliwe lub niezgodnym z dobrymi obyczajami,</li>
                                <li>Użytkownik nie jest już pracownikiem Partnera Handlowego </li>
                                <li>dane Użytkownika są niekompletne, niezgodne z prawdą, uniemożliwiające skuteczny kontakt</li>
                            </ol>
                        </li>
                        <li>Usługodawca nie ma obowiązku informować Użytkownika o okolicznościach powodujących usunięcie Loginu lub ograniczenie dostępu Uczestnika do Zasobów Portalu edukacyjnego.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">VI. Odpowiedzialność</h2>
                    <ol>
                        <li>Jeżeli inaczej nie określono Platforma edukacyjna, Szkolenia oraz poszczególne elementy w nich użyte stanowią własność Usługodawcy i są objęte ochroną wynikającą z ustawy z dnia 4 lutego 1994 r. O prawie autorskim i prawach pokrewnych (dz. U. Z 2000 r. Nr. 80, poz. 904 z późn. Zm.).</li>
                        <li>Informacje zawarte na Platformie edukacyjnej, Szkoleniach i innych Zasobach służą wyłącznie celem informacyjnym i edukacyjnym.</li>
                        <li>Autorzy Szkoleń, a także wszelkich informacji publikowanych w Serwisie lub Platformie edukacyjnej i jej Zasobach dołożyli starań, aby treści w nich zawarte były zgodne z obowiązującymi przepisami prawa. Podane informacje nie stanowią jednak źródła prawa, urzędowej interpretacji przepisów prawa, a także nie są oficjalnym stanowiskiem właściwych organów lub władz. </li>
                        <li>Usługodawca zastrzega sobie prawo do dokonywania zmian w Szkoleniach i innych Zasobach Platformy edukacyjnej portalu bez wcześniejszego powiadomienia Uczestników.</li>
                        <li>Żaden element Serwisu oraz Platformy edukacyjnej nie może być kopiowany lub rozpowszechniany w jakiejkolwiek formie lub w jakikolwiek sposób, w tym poprzez fotokopiowanie, drukowanie, nagrywanie na dyskietki, dyski CD/DVD bądź inne środki służące przechowywaniu danych, bez zgody Usługodawcy. </li>
                        <li>
                            Usługodawca nie ponosi odpowiedzialności za:
                            <ol>
                                <li>Działania osób trzecich obsługujących sprzęt i oprogramowanie zapewniające prawidłowe i nieprzerwane działanie sieci publicznej Internet i stacji roboczej Uczestnika,</li>
                                <li>Przypadki braku dostępności Serwisu, Platformy edukacyjnej, Szkoleń i innych Zasobów zaistniałe w związku z awarią systemu łączności telekomunikacyjnej oraz za inne skutki wadliwego działania łączy telekomunikacyjnych i szkód przez nie wywołanych, bez względu na przyczyny szkody powstałe w wyniku korzystania bądź niemożności korzystania z Serwisu, Platformy edukacyjnej, Szkoleń i innych Zasobów, jak również wynikłych z bezpośredniego lub pośredniego stosowania zawartych w nich informacji, zastosowania w praktyce prezentowanych w nich przykładów, dokładność i kompletność przedstawionych w nich informacji,</li>
                                <li>Szkody powstałe w wyniku uszkodzenia sprzętu komputerowego Uczestnika bądź jego zasobów danych w trakcie lub w związku z korzystaniem z Serwisu, Platformy edukacyjnej, Szkoleń i innych Zasobów, w szczególności na skutek przedostania się do systemu informatycznego uczestnika wirusów komputerowych,</li>
                            </ol>
                        </li>
                        <li>Korzystanie z Serwisu oraz Platformy edukacyjnej odbywa się na wyłączne ryzyko Uczestnika i na jego koszt związany z połączeniem z Internetem. Usługodawca nie gwarantuje niewadliwego i nieprzerwanego dostępu.</li>
                        <li>Usługodawca nie odpowiada za szkody spowodowane wadliwym działaniem systemu transmisji, w tym awariami sprzętu, opóźnieniami i zakłóceniami przesyłu informacji.</li>
                        <li>Usługodawca nie gwarantuje, że informacje przesyłane za pomocą Internetu dotrą do Uczestnika w bezbłędnej, kompletnej i pełnej formie.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">VII. Informacje o użytkownikach</h2>
                    <ol>
                        <li>
                            Użytkownik akceptując postanowienia Regulaminu wyraża zgodę na:
                            <ol>
                                <li>przetwarzanie udostępnionych dobrowolnie przez Uczestnika danych osobowych w „moim profilu” oraz uzyskanych przez Usługodawcę w trakcie korzystania przez Uczestnika z Portalu edukacyjnego oraz Szkoleń, w rozumieniu ustawy z dnia 29 Sierpnia 1997 r. o ochronie danych osobowych (dz. U. Z 2002 r. Nr 101, poz. 926 z późn. Zm.). Administratorem danych osobowych jest Usługodawca. Przetwarzanie danych osobowych odbywa się w celu realizacji usług określonych niniejszym Regulaminem oraz dla spełnienia zadość ustawowym ce,</li>
                                <li>otrzymywanie informacji związanych z udziałem w szkoleniach i korzystaniem z Serwisu i Platformy edukacyjnej oraz innych Zasobów, a także informacji handlowych w rozumieniu ustawy z dnia 18 Lipca 2002 r. O świadczeniu usług droga elektroniczną (dz. U. Nr 144, poz. 1204 z późn. Zm) na podany podczas Rejestracji lub w zakładce „mój profil” adres poczty elektronicznej.</li>
                                <li>poprawianie przez Usługodawcę drobnych błędów/oczywistych pomyłek pisarskich we wprowadzonych przez Uczestnika danych, w szczególności takich błędów jak tzw. literówki w treści imienia lub miejscowości.</li>
                            </ol>
                        </li>
                        <li>Administratorem danych osobowych jest Usługodawca. </li>
                        <li>Uczestnik akceptuję fakt, iż przetwarzanie danych osobowych może zostać powierzone przez Usługodawcę podmiotowi zewnętrznemu. </li>
                        <li>Przetwarzanie danych osobowych odbywa się w celu realizacji usług określonych niniejszym Regulaminem oraz dla spełnienia celów ustawowych.</li>
                        <li>Dane osobowe Użytkownik są niezbędne dla celów rejestracji w Portalu edukacyjnym. </li>
                        <li>Uczestnik ma prawo wglądu, weryfikacji zmian oraz usunięcia swoich danych osobowych.</li>
                        <li>Przekazywane przez Użytkownik podczas Rejestracji lub w zakładce „moje konto” dane są wykorzystywane w celu zapewnienia dostępu do Szkoleń i innych Zasobów Platformy edukacyjnej i nie są ujawniane osobom trzecim, z wyjątkiem przypadków, kiedy ujawnienie takich informacji wymagane jest przepisami prawa. </li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">VIII. Polityka restartowania i aktualizacji platformy edukacyjnej</h2>
                    <ol>
                        <li>
                            Usługodawca zastrzega sobie prawo do:
                            <ol>
                                <li>okresowego wyłączania Serwisu lub Platformy edukacyjnej bez uprzedniego powiadomienia Uczestników, w sytuacji, gdy będzie to konieczne z ważnych przyczyn technicznych i organizacyjnych, bądź z ważnych przyczyn wynikających z niezależnych od Usługodawcę i niezawinionych przez Usługodawcę czynników zewnętrznych,</li>
                                <li>natychmiastowego skasowania każdej treści bez uprzedniego powiadomienia Uczestnika, w wypadku naruszenia przez daną treść postanowień niniejszego Regulaminu lub obowiązujących przepisów</li>
                            </ol>
                        </li>
                        <li>Wszelkie inne komunikaty o ewentualnych włączeniach Serwisu lub Platformy edukacyjnej będą wcześniej publikowane na stronie www lub wysyłane w formie e-maili przynajmniej na 12 godzin przez wyłączeniem.</li>
                        <li>W trakcie restartu bądź wyłączenia Serwisu lub Platformy edukacyjnej, odwołanie do strony głównej ze wszystkich domen, powoduje wyświetlenie informacji o chwilowej niedostępności.</li>
                    </ol>
                </li>
                <li>
                    <h2 class="is-size-2 has-text-shadow">IX. Postanowienia końcowe</h2>
                    <ol>
                        <li>Usługodawca zastrzega sobie prawo do wprowadzania zmian w regulaminie </li>
                        <li>Wszelkie zmiany wprowadzone do Regulaminu stają się obowiązujące w momencie ich opublikowania na stronie www.akademiaeurocash.com.pl. O każdej zmianie regulaminu Uczestnik Szkolenia zostanie powiadomiony pocztą elektroniczną wysłaną na adres konta e-mail podany przy rejestracji na Platformę edukacyjną lub dostępnym w profilu Uczestnika.</li>
                        <li>W sprawach nieuregulowanych w Regulaminie zastosowanie mają przepisy prawa polskiego.</li>
                        <li>Wszelkie spory będą rozstrzygane przez sąd właściwy dla siedziby Usługodawcy.</li>
                    </ol>
                </li>
              </ul>                  
            </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";

export default {
        name: "ViewRegulations",
        data() {
            return {
                rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/'),
            };
        },
  components: {
    BaseHero
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>

