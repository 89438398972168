import Vue from 'vue';

Vue.filter('truncate', function(text, limit, tail) {
	text = text || '';
	limit = limit || 30;
	tail = tail || '...';

	if (text.length > limit) {
		text = text.substring(0, limit) + tail;
	}

	return text;
});

Vue.filter('tailing', function(text, tail) {
	return text + tail;
});
