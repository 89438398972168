<template>
    <main>
        <BaseHero :title="'Konferencje'" />
        <BannerConference v-if="!showComponent" ref="bannerConfComponent" />
        <HomeBannerConference imageSrc="/assets/2018/images/baner_kongres_FINAL.jpg" v-else class="conferencesHomeBanner"/>
        <BannerLiveLecture />
        <Conferences :search.sync="search" :showFilters.sync="showFilters" />
    </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import BannerConference from "@/components/BannerConference.vue";
import Conferences from "@/components/Containers/ViewConferences/Conferences.vue";
import HomeBannerConference from "@/components/Containers/ViewHomePage/HomePageConferences.vue";
import BannerLiveLecture from "@/components/BannerLiveLecture.vue";
export default {
  name: "ViewConferences",
  components: {
    BaseHero,
    BannerConference,
    Conferences,
    HomeBannerConference,
    BannerLiveLecture
  },
  data() {
    return {
      search: "",
      showFilters: false,
      showComponent: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

</style>
