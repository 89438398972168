<template>
	<main>
		<!--<BaseHero >-->
		<ViewOnboardingWeek />
	</main>
</template>

<script>
//import BaseHero from "@/components/BaseHero.vue";
import ViewOnboardingWeek from "@/components/Containers/ViewOnboarding/ViewOnboardingWeek.vue";

export default {
	name: "ViewOnboarding",
	components: {
		//BaseHero,
		ViewOnboardingWeek
	},

	data() {
		return {
			title: "",
			text:""
		};
	}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
