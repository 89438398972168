<template>
      <section class="statistics section">
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-full is-flex has-text-left" v-if="motivations.ShowTable">
                <div class="card-table">
                    <div class="card">
                      <div class="card-header">
                          <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
                              Systemy motywacyjne
                          </p>
                      </div>
                    </div>
                    <table class="table">
                      <thead class="is-hidden-mobile">
                        <tr class="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
                          <th class="has-text-grey-light">Nazwa szkolenia</th>
                          <th class="has-text-grey-light">Realizacja</th>
                          <th class="has-text-grey-light">Data zakończenia</th>
                          <th class="has-text-grey-light">Certyfikat</th>
                        </tr>
                      </thead>
                      <tbody v-if="motivations.Data.length">
                        <tr class="is-size-17 has-text-left has-text-weight-semibold" v-for="(motivation, index) in motivations.Data" :key="index">
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">edycja systemu</span>
                                <span>{{ motivation.Name }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">realizacja</span>
                                <span>{{ motivation.Scale + '%' }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">data zakończenia</span>
                                <span>{{ motivation.PassedDate }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">certyfikat</span>
                                <span><a href="#" @click.prevent="generatePDF(motivation.ElearingResultId)" class="has-text-weight-semibold has-text-left has-text-primary has-arrow">Pobierz</a></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="5" class="is-size-8 has-text-centered">Brak danych</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="motivations.Page++; loadMotivationsHandler()" v-if="motivations.NextPage">Załaduj więcej treści</a>
            </div>
            <div class="column is-full is-flex has-text-left" v-if="elearnings.ShowTable">
                <div class="card-table">
                    <div class="card">
                      <div class="card-header">
                          <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
                              Lista zrealizowanych szkoleń
                          </p>
                      </div>
                    </div>
                    <table class="table">
                      <thead class="is-hidden-mobile">
                        <tr class="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
                          <th class="has-text-grey-light">Nazwa szkolenia</th>
                          <th class="has-text-grey-light">Realizacja</th>
                          <th class="has-text-grey-light">Data zakończenia</th>
                          <th class="has-text-grey-light">Certyfikat</th>
                        </tr>
                      </thead>
                      <tbody v-if="elearnings.Data.length">
                        <tr class="is-size-17 has-text-left has-text-weight-semibold" v-for="(elearning, index) in elearnings.Data" :key="index">
                          <td class="has-text-black">
                            <span>{{ elearning.Name }}</span>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">realizacja</span>
                                <span>{{ elearning.Scale + '%' }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">data zakończenia</span>
                                <span>{{ elearning.PassedDate }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">certyfikat</span>
                                <span><a href="#" @click.prevent="generatePDF(elearning.ElearingResultId)" class="has-text-weight-semibold has-text-left has-text-primary has-arrow">Pobierz</a></span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="5" class="is-size-8 has-text-centered">Brak danych</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="elearnings.Page++; loadElearningsHandler()" v-if="elearnings.NextPage">Załaduj więcej treści</a>
            </div>
            <div class="column is-full is-flex has-text-left" v-if="workshops.ShowTable">
                <div class="card-table">
                    <div class="card">
                      <div class="card-header">
                          <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
                              Lista zrealizowanych warsztatów
                          </p>
                      </div>
                    </div>
                    <table class="table table-workshops">
                      <thead class="is-hidden-mobile">
                        <tr class="is-size-18 is-uppercase has-text-weight-semibold has-text-left">
                          <th class="has-text-grey-light">Nazwa warsztatu</th>
                          <th class="has-text-grey-light">Data warsztatu</th>
                          <th class="has-text-grey-light">Miejsce szkolenia</th>
                          <th class="has-text-grey-light has-text-centered">Obecność</th>
                          <th class="has-text-grey-light">Wynik</th>
                        </tr>
                      </thead>
                      <tbody v-if="workshops.Data.length">
                        <tr class="is-size-17 has-text-left has-text-weight-semibold" v-for="(workshop, index) in workshops.Data" :key="index">
                          <td class="has-text-black">
                            <span>{{ workshop.Name }}</span>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">data zakończenia</span>
                                <span>{{ workshop.Date }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">Gdzie?</span>
                                <span>{{ workshop.Place }}</span>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">Obecność</span>
                                <p class="has-text-right has-text-centered-tablet">
                                  <CheckedIcon class="checked" v-show="workshop.Attendance"/>
                                  <NotCheckedIcon v-show="!workshop.Attendance"/>
                                </p>
                            </div>
                          </td>
                          <td class="has-text-black">
                            <div class="is-dotted is-block">
                                <span class="is-uppercase has-text-grey-light is-hidden-tablet">Rezygnuj?</span>
                                <span>{{ workshop.Points }}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="5" class="has-text-centered">Brak danych</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="workshops.Page++; loadWorkshopsHandler()" v-if="workshops.NextPage">Załaduj więcej treści</a>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
import CheckedIcon from "@/assets/svg/checked.svg";
import NotCheckedIcon from "@/assets/svg/notchecked.svg";

export default {
  name: "Statistics",
  components: {
    CheckedIcon,
    NotCheckedIcon
  },
  data() {
    return {
      motivations: {
        Data: [],
        NextPage: false,
        ShowTable: false,
        Page: 0
      },
      elearnings: {
        Data: [],
        NextPage: false,
        ShowTable: false,
        Page: 0
      },
      workshops: {
        Data: [],
        NextPage: false,
        ShowTable: false,
        Page: 0
      },
      workshopId: "",
      successMsg: ""
    };
  },
  methods: {
    loadMotivationsHandler() {
      let self = this;
      self.$http
        .post(`spa/myaccount/motivation`, { Page: self.motivations.Page })
        .then(response => {
          self.motivations.Data = [
            ...self.motivations.Data,
            ...response.data.Data
          ];
          self.motivations.NextPage = response.data.NextPage;
          self.motivations.ShowTable = response.data.ShowTable;
        })
        .catch(() => {});
    },
    loadElearningsHandler() {
      let self = this;
      self.$http
        .post(`spa/myaccount/elearning`, { Page: self.elearnings.Page })
        .then(response => {
          self.elearnings.Data = [
            ...self.elearnings.Data,
            ...response.data.Data
          ];
          self.elearnings.NextPage = response.data.NextPage;
          self.elearnings.ShowTable = response.data.ShowTable;
        })
        .catch(() => {});
    },
    loadWorkshopsHandler() {
      let self = this;
      self.$http
        .post(`spa/myaccount/workshops`, { Page: self.workshops.Page })
        .then(response => {
          self.workshops.Data = [...self.workshops.Data, ...response.data.Data];
          self.workshops.NextPage = response.data.NextPage;
          self.workshops.ShowTable = response.data.ShowTable;
        })
        .catch(() => {});
    },
    generatePDF(id) {
      const $script = require("scriptjs");
      let self = this;
      $script(["/scripts/pdfmake.js"], function() {
        $script(["/scripts/vfs_fonts.js"], function() {
          self.$http
            .get(`/scripts/certificate.txt`)
            .then(response => {
              const certImage = response.data;
              self.$http
                .get(`spa/myaccount/elearning/getcert/${id}`)
                .then(response => {
                  let user = response.data.UserName,
                    title = "„" + response.data.CourseName + "”",
                    date = response.data.PassDate,
                    marginUser = [],
                    marginTitle = [],
                    marginDate = [];
                  if (user.length < 30) {
                    marginUser = [70, 295, 60, 0];
                  } else {
                    marginUser = [70, 300, 0, 0];
                  }
                  if (title.length < 30) {
                    marginTitle = [70, 80, 140, 0];
                  } else {
                    marginTitle = [70, 60, 140, 0];
                  }
                  if (title.length < 30) {
                    marginDate = [145, 35, 0, 0];
                  } else {
                    marginDate = [145, 30, 0, 0];
                  }
                  let docDefinition = {
                    pageSize: "A4",
                    pageMargins: [0, 0, 0, 0],
                    info: {
                      title: "Certyfikat"
                    },
                    background: {
                      image: certImage,
                      width: 595,
                      height: 841.5
                    },
                    content: [
                      {
                        fontSize: 22,
                        text: user,
                        margin: marginUser,
                        color: "#017b2f"
                      },
                      {
                        fontSize: 22,
                        text: title,
                        margin: marginTitle,
                        color: "#017b2f"
                      },
                      {
                        fontSize: 14,
                        text: date,
                        margin: marginDate,
                        color: "#017b2f"
                      }
                    ]
                  };
                  // eslint-disable-next-line
                  pdfMake.createPdf(docDefinition).download("certyfikat");
                });
            })
            .catch(() => {});
        });
      });
    }
  },
  created: function() {
    this.loadMotivationsHandler();
    this.loadElearningsHandler();
    this.loadWorkshopsHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.statistics {
  .column.is-flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .card-table {
    .table {
      th {
        letter-spacing: 0.9px;
      }
      &:not(.table-workshops) {
        thead {
          tr {
            & > :nth-child(1) {
              width: 55%;
            }
            & > :nth-child(2),
            & > :nth-child(3) {
              width: 15%;
            }
            & > :nth-child(4) {
              width: 10%;
              min-width: 100px;
            }
          }
        }
      }
      &.table-workshops {
        thead {
          tr {
            & > :nth-child(1) {
              width: 20%;
            }
            & > :nth-child(2),
            & > :nth-child(4),
            & > :nth-child(5) {
              width: 15%;
            }
            & > :nth-child(3) {
              width: 40%;
            }
            & > :nth-child(5) {
              min-width: 130px;
            }
          }
        }
      }
    }
  }
  .button {
    padding: 25px 50px;
    border-color: $grey-lighter;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
  .button.is-light.is-outlined {
    padding: 25px 50px;
    margin: rem(30px 0);
    border-color: $grey-lighter;
    width: 100%;
    max-width: 320px;
    &:hover,
    &:focus {
      background: transparent;
      border-color: $grey-light;
    }
    @include tablet {
      width: auto;
    }
  }
  .checked {
    color: $green-lighter;
  }
  .card {
    border-bottom: 1px solid #ececec;
    border-radius: 0;
    &:first-child {
      background: $green-lighter;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      .card-header-title {
        padding: 25px 20px;
        letter-spacing: 0.5px;
      }
    }
  }
}
</style>