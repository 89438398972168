<template>
  <main>
    <OfferEduMain />
  </main>
</template>

<script>
import OfferEduMain from "@/components/Containers/ViewOfferEdu/OfferEdu.vue";

export default {
  name: "ViewOfferEdu",
  components: {
    OfferEduMain
  },
};
</script>
