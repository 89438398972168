import Vue from 'vue';
import App from './App.vue';
import Login from './components/Containers/ViewLogin/Login.vue';
import LoginSlider from './components/Containers/ViewLogin/LoginSlider.vue';
import LoginModal from './components/Containers/ViewLogin/LoginModal.vue';
import LoginMedia from './components/Containers/ViewLogin/LoginMedia.vue';
import HelpContact from './components/Containers/ViewHelpContact/HelpContact.vue';
import OfferEdu from './components/Containers/ViewOfferEdu/OfferEdu.vue';
import OfferEduWorkshops from './components/Containers/ViewOfferEdu/OfferEduWorkshops.vue';
import ConferenceGuest from './components/Containers/ViewConferenceGuest/ConferenceGuest.vue';
import ConferenceThanks from './components/Containers/ViewConference/ConferenceThanks.vue';
import Partners from './components/Containers/ViewPartners/Partners.vue';
import TheCookieBox from './components/TheCookieBox.vue';
import TheNavBar from '@/components/TheNavBar.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import Buefy from 'buefy';
import Vuelidate from 'vuelidate';
import VueCarousel from 'vue-carousel';
import VueLazyload from 'vue-lazyload';
import vSelect from 'vue-select';
import Vue2TouchEvents from 'vue2-touch-events';
import VueTheMask from 'vue-the-mask';
import VueYoutube from 'vue-youtube';
import './filters';
import moment from 'moment-timezone';
import jQuery from 'jquery';
import jsPDF from 'jspdf';
import VueEllipseProgress from 'vue-ellipse-progress';

window.jsPDF = jsPDF;
window.$ = jQuery;

import './assets/scss/app.scss';
import vuetify from './plugins/vuetify';
moment.tz.setDefault('Europe/Warsaw');
Vue.prototype.$moment = moment;
Vue.use(Vuelidate);
Vue.use(Buefy);
Vue.use(VueEllipseProgress);
// Vue.component(Buefy.Collapse.name, Buefy.Collapse);
// Vue.component(Buefy.Icon.name, Buefy.Icon);
// Vue.component(Buefy.Checkbox.name, Buefy.Checkbox);
// Vue.component(Buefy.CheckboxButton.name, Buefy.CheckboxButton);
// Vue.component(Buefy.RadioButton.name, Buefy.RadioButton);
// Vue.component(Buefy.Modal.name, Buefy.Modal);
// Vue.component(Buefy.Datepicker.name, Buefy.Datepicker);
// Vue.component(Buefy.Field.name, Buefy.Field);
// Vue.component(Buefy.Dropdown.name, Buefy.Dropdown);
// Vue.component(Buefy.DropdownItem.name, Buefy.DropdownItem);
// Vue.component(Buefy.Select.name, Buefy.Select);
// Vue.component(Buefy.Tabs.name, Buefy.Tabs);
// Vue.component(Buefy.TabItem.name, Buefy.TabItem);
// Vue.component(Buefy.Pagination.name, Buefy.Pagination);
Vue.component('v-selector', vSelect);
Vue.use(VueCarousel);
Vue.use(VueYoutube);
Vue.use(VueTheMask);
Vue.use(Vue2TouchEvents);

const loaderSpinnerImage =
	'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwcHgiICBoZWlnaHQ9IjIwMHB4IiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIGNsYXNzPSJsZHMtcm9sbGluZyIgc3R5bGU9ImJhY2tncm91bmQ6IG5vbmU7Ij48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBuZy1hdHRyLXN0cm9rZT0ie3tjb25maWcuY29sb3J9fSIgbmctYXR0ci1zdHJva2Utd2lkdGg9Int7Y29uZmlnLndpZHRofX0iIG5nLWF0dHItcj0ie3tjb25maWcucmFkaXVzfX0iIG5nLWF0dHItc3Ryb2tlLWRhc2hhcnJheT0ie3tjb25maWcuZGFzaGFycmF5fX0iIHN0cm9rZT0iIzNhYWI1OSIgc3Ryb2tlLXdpZHRoPSIyIiByPSIxMCIgc3Ryb2tlLWRhc2hhcnJheT0iNDcuMTIzODg5ODAzODQ2ODkgMTcuNzA3OTYzMjY3OTQ4OTY2IiB0cmFuc2Zvcm09InJvdGF0ZSgxNDkuNzQ5IDUwIDUwKSI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGNhbGNNb2RlPSJsaW5lYXIiIHZhbHVlcz0iMCA1MCA1MDszNjAgNTAgNTAiIGtleVRpbWVzPSIwOzEiIGR1cj0iMXMiIGJlZ2luPSIwcyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT48L2NpcmNsZT48L3N2Zz4=';
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loaderSpinnerImage,
	loading: loaderSpinnerImage,
	attempt: 1,
});
Vue.config.productionTip = false;
axios.defaults.baseURL = window.location.origin;
Vue.prototype.$http = axios;
Vue.prototype.$http.interceptors.response.use(
	(response) => {
		// intercept the global error
		return response;
	},
	function (error) {
		let originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			window.location.href = '/';
			return;
		}
		return Promise.reject(error);
	}
);

if (document.getElementById('app')) {
	new Vue({
		router,
		store,
		vuetify,
		render: (h) => h(App),
	}).$mount('#app');
}

if (document.getElementById('login')) {
	new Vue({
		router,
		store,
		render: (h) => h(Login),
	}).$mount('#login');
}

if (document.getElementById('carousel')) {
	new Vue({
		render: (h) => h(LoginSlider),
	}).$mount('#carousel');
}

if (document.getElementById('modal')) {
	new Vue({
		router,
		render: (h) => h(LoginModal),
	}).$mount('#modal');
}

if (document.getElementById('media')) {
	new Vue({
		router,
		render: (h) => h(LoginMedia),
	}).$mount('#media');
}

if (document.getElementById('contact')) {
	new Vue({
		render: (h) => h(HelpContact),
	}).$mount('#contact');
}

if (document.getElementById('offerEdu')) {
	new Vue({
		router,
		render: (h) => h(OfferEdu),
	}).$mount('#offerEdu');
}

if (document.getElementById('offerEduWorkshops')) {
	new Vue({
		router,
		render: (h) => h(OfferEduWorkshops),
	}).$mount('#offerEduWorkshops');
}

if (document.getElementById('partners')) {
	new Vue({
		render: (h) => h(Partners),
	}).$mount('#partners');
}

if (document.getElementById('conferenceGuest')) {
	new Vue({
		router,
		render: (h) => h(ConferenceGuest),
	}).$mount('#conferenceGuest');
}

if (document.getElementById('conferenceThanks')) {
	new Vue({
		router,
		render: (h) => h(ConferenceThanks),
	}).$mount('#conferenceThanks');
}

if (document.getElementById('cookie')) {
	new Vue({
		store,
		render: (h) => h(TheCookieBox),
	}).$mount('#cookie');
}

if (document.getElementById('nav')) {
	new Vue({
		router,
		store,
		render(h) {
			return h(TheNavBar, {
				props: {
					isOutside: true,
				},
			});
		},
	}).$mount('#nav');
}
