<template>
  <div class="breadcrumbs">
    <nav class="breadcrumb has-arrow-separator container" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link class="is-size-7" to="/">
            <span class="icon is-small">
              <HomeIcon width="17" height="14" viewBox="0 0 17 14" />
            </span>
            <span>Strona główna</span>
          </router-link>
        </li>
        <li v-for="(breadcrumb, index) in $route.meta.breadcrumb" :key="index">
          <!-- In case there is no possibility to inject the proper :to field -->
          <a
            v-if="breadcrumb.link === 'go-back'"
            class="is-size-7"
            @click.prevent="goBack()"
          >{{ breadcrumb.name }}</a>
          <a v-else-if="breadcrumb.link === 'go-back-full'" 
             class="is-size-7"
              @click="goBackFull()"
             >{{ breadcrumb.name }}</a>
          <!-- END -->

          <router-link v-else class="is-size-7" :to="breadcrumb.link">
            <span>{{ breadcrumb.name }}</span>
          </router-link>
        </li>
        <li>
          <span
            class="is-size-7"
          >{{ $route.name ? $route.name : $store.state.breadcrumb | truncate(30) }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import router from '../router';
import HomeIcon from '@/assets/svg/home.svg';

export default {
	name: 'Breadcrumb',

	components: {
		HomeIcon,
	},

	data() {
		return {};
	},

	methods: {
		goBack() {
			router.go(-1);
        },
        goBackFull() {
            if (localStorage.getItem('prevUrl') !== null) {
                let prevUrl = localStorage.getItem('prevUrl');
                localStorage.removeItem('prevUrl');
                window.location.href = prevUrl;
            }
        }
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';
</style>
