<template>
  <article class="card" @click="$emit('goToUrlHandler')" :class="background">
    <div class="card-content is-paddingless">
      <div class="media">
        <div class="media-content">
          <div class="columns is-gapless">
            <div class="column is-one-quarter">
                <div v-if="dateArray" class="has-text-centered">
                    <h2 class="is-size-11 has-text-weight-light has-text-white">{{ dateArray[0] }}</h2>
                    <p class="has-text-white is-uppercase is-size-4">{{ dateArray[1] }}</p>
                    <p class="has-text-white is-uppercase is-size-4">{{ dateArray[2] }}</p>
                </div>
            </div>
            <div class="column">
                <div>
                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-primary-hover">{{ category }}</p>
                    <h2 class="is-size-2 has-text-left">{{ title }}</h2>
                    <p class="is-size-8 has-text-left has-text-grey-light-hover"><PinIcon width="20" height="20" viewBox="0 -8 23 24"/>{{ city }}</p>
                    <p class="is-size-8 has-text-left has-text-grey-light-hover">OCENA: <span class="is-size-6 has-text-left has-text-black-hover"> {{ rate }}<span class="has-text-grey-light-hover">/5</span></span><star-rating :star-size="14" :padding="3" :rating="rate" :read-only="true" :increment="0.01" :show-rating="false" :active-color="'#f8d61c'" :inactive-color="'silver'" inline></star-rating></p>
                    <p class="is-size-7 has-text-weight-light has-text-left">{{ description | truncate(200) }}</p>
                    <RightArrowSmallIcon class="right-arrow-small-icon"/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import StarRating from "vue-star-rating";
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
import PinIcon from "@/assets/svg/pin.svg";

export default {
  name: "BaseCardCalendar",
  components: {
    StarRating,
    RightArrowSmallIcon,
    PinIcon
  },
  props: {
    category: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    rate: {
      type: Number
    },
    date: {
      type: String
    },
    city: {
      type: String
    },
    background: {
      type: String
    }
  },
  computed: {
    dateArray() {
      return this._props.date.split(" ");
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.card {
  cursor: pointer;
  box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
  border: solid 1px #f2f2f2;
  height: 100%;
  .card-content,
  .media,
  .media-content,
  .media-content > div {
    height: inherit;
  }
  &.is-grey {
    .columns {
      & > :first-child {
        transition: background 0.3s ease;
        background: #6e6e6e;
      }
    }
    &:hover,
    &:focus {
      .columns {
        & > :first-child {
          background: $green-lighter;
        }
      }
    }
  }

  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p,
      span {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
    }
  }
  .columns {
    & > :first-child {
      background: $green-lighter;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      & > div {
        padding: 15px 0;
        @include tablet {
          padding: 20%;
        }
      }
      p {
        opacity: 0.5;
      }
    }
    & > :nth-child(2) {
      & > div {
        padding: rem(24px 40px 24px 20px);
        @include tablet {
          padding: rem(24px 80px 24px 30px);
        }
        h2 {
          margin: rem(10px 0 5px);
        }
        & > :nth-child(4) {
          margin: rem(10px 0);
        }
      }
    }
  }
  .right-arrow-small-icon {
    margin-top: 60px;
    @include tablet {
      margin-top: -10px;
    }
  }
}
</style>
