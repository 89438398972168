<template>
    <div class="conference-counter">
        <p class="is-size-1 has-text-weight-bold"><span class="is-size-10">dni</span>{{ days }}</p>
        <p class="is-size-1 has-text-weight-bold"><span class="is-size-10">godzin</span>{{ hours }}</p>
        <p class="is-size-1 has-text-weight-bold"><span class="is-size-10">minut</span>{{ minutes }}</p>
        <p class="is-size-1 has-text-weight-bold"><span class="is-size-10">sekund</span>{{ seconds }}</p>
    </div>
</template>

<script>
    import global from "@/mixins/global.js";

export default {
    name: "CounterToDate",
    mixins: [global],
    props: ['starttime', 'endtime', 'trans'],
    components: {},
    data: function () {
        return {
            timer: "",
            start: "",
            end: "",
            interval: "",
            days: "",
            minutes: "",
            hours: "",
            seconds: "",
        };
    },
        mounted() {
            this.start = new Date(this.starttime).getTime();
            this.end = new Date(this.endtime).getTime();
        // Update the count down every 1 second
        this.timerCount(this.start, this.end);
        this.interval = setInterval(() => {
            this.timerCount(this.start, this.end);
        }, 1000);
    },
    methods: {
        timerCount: function (start, end) {
            // Get todays date and time
            var now = new Date().getTime();

            // Find the distance between now an the count down date
            var distance = start - now;
            var passTime = end - now;

            if (distance < 0 && passTime < 0) {
      
                this.statusType = "expired";

                clearInterval(this.interval);
                return;

            } else if (distance < 0 && passTime > 0) {
                this.calcTime(passTime);
    
                this.statusType = "running";
    

            } else if (distance > 0 && passTime > 0) {
                this.calcTime(distance);
 
                this.statusType = "upcoming";
          
            }
        },
        calcTime: function (dist) {
            // Time calculations for days, hours, minutes and seconds
            this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
        }
    }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.conference-counter {
    margin: 8px -4px 0;

    @include tablet {
        display: block;
    }

    p {
        display: inline-block;
        background-color: $green;
        color: $white;
        border-radius: 5px;
        margin: 0 4px;
        width: 52px;
        height: 52px;
        
        text-align: center;

        @include tablet {
            width: 58px;
            height: 58px;
        }

        span {
            display: block;
            font-weight: 400;
            margin-bottom: -4px;
            position: relative;
            top: 2px;
        }
    }
}

</style>
