import { render, staticRenderFns } from "./Elearnings.vue?vue&type=template&id=04b309c1&scoped=true&"
import script from "./Elearnings.vue?vue&type=script&lang=js&"
export * from "./Elearnings.vue?vue&type=script&lang=js&"
import style0 from "./Elearnings.vue?vue&type=style&index=0&id=04b309c1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b309c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Touch from 'vuetify/lib/directives/touch'
installDirectives(component, {Touch})
