<template>
  <main>
    <BaseHeroSearch :title="'Warsztaty'" :showFilters.sync="showFilters" :hasFilters="true" :hasSearch="true" :hasSearchMobileOnly="true" :placeholder="'Szukaj'"/>
    <WorkshopsManagement :showFilters.sync="showFilters"/>
  </main>
</template>

<script>
import BaseHeroSearch from "@/components/BaseHeroSearch.vue";
import WorkshopsManagement from "@/components/Containers/ViewWorkshopsManagement/WorkshopsManagement.vue";

export default {
  name: "ViewWorkshopsManagement",
  components: {
    BaseHeroSearch,
    WorkshopsManagement
  },
  data() {
    return {
      showFilters: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
