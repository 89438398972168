<template>
	<b-modal
		:active="isActive"
		:width="830"
		:canCancel="['escape', 'x', 'outside']"
		:on-cancel="$parent.closeModalAddUser"
		scroll="keep"
		class="modal-dialog"
	>
		<button
			type="button"
			class="modal-close is-large"
			@click="$parent.closeModalAddUser"
		></button>

		<form @submit.prevent="submitForm">
			<div class="columns is-multiline">
				<div class="column is-full">
					<h2 class="is-size-1 has-text-left has-text-black">
						Dodaj pracownika
					</h2>
				</div>

				<div class="column is-full">
					<div class="form-grid">
						<!-- ShopNumber -->
						<div v-if="shouldShow('ShopNumber')" class="field --ShopNumber">
							<div class="control">
								<div
									class="input-container"
									:class="{ 'has-error': $v.formData.ShopNumber.$error }"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											v-model="formData.ShopNumber"
											@search="searchShopHandler"
											:options="shopsNumbersList"
											placeholder="Kod sklepu"
											:disabled="fields.ShopNumber.disabled ? true : false"
											label="Value"
										>
											<span slot="no-options" class="has-text-left"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.ShopNumber)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | ShopNumber -->

						<!-- FirstName -->
						<div v-if="shouldShow('FirstName')" class="field --FirstName">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Imię',
										hasContent: formData.FirstName ? true : false,
										hasError: $v.formData.FirstName.$error,
										...floatingLabelConfig,
									}"
								>
									<input
										v-model="formData.FirstName"
										@blur="$v.formData.FirstName.$touch()"
										type="text"
										class="input"
									/>
								</FloatingLabel>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.FirstName)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | FirstName -->

						<!-- LastName -->
						<div v-if="shouldShow('LastName')" class="field --LastName">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Nazwisko',
										hasContent: formData.LastName ? true : false,
										hasError: $v.formData.LastName.$error,
										...floatingLabelConfig,
									}"
								>
									<input
										v-model="formData.LastName"
										@blur="$v.formData.LastName.$touch()"
										type="text"
										class="input"
									/>
								</FloatingLabel>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.LastName)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | LastName -->

						<!-- Network -->
						<div v-if="shouldShow('Network')" class="field --Network">
							<div class="control">
								<div
									class="input-container"
									:class="{ 'has-error': $v.formData.Network.$error }"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											v-model="formData.Network"
											:options="networksList"
											placeholder="Wybierz sieć"
											:disabled="fields.Network.disabled ? true : false"
											label="Value"
										>
											<span slot="no-options" class="has-text-left"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.Network)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | Network -->

						<!-- Email -->
						<div v-if="shouldShow('Email')" class="field --Email">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Adres e-mail',
										hasContent: formData.Email ? true : false,
										hasError: $v.formData.Email.$error,
										...floatingLabelConfig,
									}"
								>
									<input
										v-model="formData.Email"
										@blur="$v.formData.Email.$touch()"
										type="email"
										class="input"
									/>
								</FloatingLabel>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.Email)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | Email -->

						<!-- WorkPosition -->
						<div v-if="shouldShow('WorkPosition')" class="field --WorkPosition">
							<div class="control">
								<div
									class="input-container"
									:class="{ 'has-error': $v.formData.WorkPosition.$error }"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											v-model="formData.WorkPosition"
											:options="workPositionsList"
											placeholder="Stanowisko"
											:disabled="fields.WorkPosition.disabled ? true : false"
										>
											<span slot="no-options" class="has-text-left"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.WorkPosition)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | WorkPosition -->

						<!-- Phone -->
						<div v-if="shouldShow('Phone')" class="field --Phone">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer telefonu',
										hasContent: formData.Phone ? true : false,
										hasError: $v.formData.Phone.$error,
										...floatingLabelConfig,
									}"
								>
									<input
										v-model="formData.Phone"
										@blur="$v.formData.Phone.$touch()"
										type="tel"
										class="input"
									/>
								</FloatingLabel>

								<!-- Error message -->
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="error in validationMessages($v.formData.Phone)"
									:key="error"
									>{{ error }}</span
								>
							</div>
						</div>
						<!-- END | Phone -->

						<!-- Submit button -->
						<button
							type="submit"
							class="form-grid__submit-button button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
							:class="{ 'is-loading': isLoading }"
						>
							<span class="icon is-small">
								<svg width="17" height="20" viewBox="0 2 17 16">
									<use :xlink:href="'/assets/2018/svg/buttons/add.svg#add'" />
								</svg>
							</span>
							<span>Dodaj pracownika</span>
						</button>
						<!-- END | Submit button -->

						<!-- Feedback message -->
						<p
							class="form-grid__feedback-message is-size-8 has-text-weight-light has-text-centered is-block"
							:class="{
								'has-text-danger': feedbackMessage.type === 'error',
								'has-text-primary': feedbackMessage.type === 'success',
							}"
							v-if="feedbackMessage"
						>
							{{ feedbackMessage.text }}
						</p>
						<!-- END | Feedback message -->
					</div>
				</div>
			</div>
		</form>
	</b-modal>
</template>

<script>
// JS imports
import global from '@/mixins/global.js';
import { required, email, numeric } from 'vuelidate/lib/validators';
import UserService from '@/services/UserService.js';

// Vue components
import FloatingLabel from '@/components/BaseFloatingLabel';

export default {
	name: 'BaseModalAddUser',

	mixins: [global],

	components: {
		FloatingLabel,
	},

	props: {
		userRole: {
			type: String,
			required: true,
		},
		fields: {
			type: Object,
			required: true,
		},
		isActive: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			formData: {
				ShopNumber: '',
				FirstName: '',
				LastName: '',
				Network: '',
				Email: '',
				WorkPosition: '',
				Phone: '',
			},
			floatingLabelConfig: {
				hasClearButton: false,
				line: false,
				height: 56,
				color: {
					focusColor: '#3AAB59',
					errorColor: '#de2647',
					lineColor: '#b5b5b5',
					blurredColor: '#b5b5b5',
				},
			},
			isLoading: false,
			feedbackMessage: {},
			shopsNumbersList: [],
			networksList: [],
			workPositionsList: [],
		};
	},

	validations() {
		switch (this.userRole) {
			case 'owner':
				return {
					formData: {
						FirstName: {
							required,
						},
						LastName: {
							required,
						},
						Network: {
							required,
						},
						Email: {
							required,
							email,
						},
						Phone: {
							numeric,
						},
					},
				};

			case 'worker':
				return {
					formData: {
						FirstName: {
							required,
						},
						LastName: {
							required,
						},
						ShopNumber: {
							required,
						},
						Email: {
							required,
							email,
						},
						WorkPosition: {
							required,
						},
						Phone: {
							numeric,
						},
					},
				};

			case 'manager':
				return {
					formData: {
						FirstName: {
							required,
						},
						LastName: {
							required,
						},
						Email: {
							required,
							email,
						},
						Phone: {
							numeric,
						},
					},
				};

			default:
				break;
		}
	},

	methods: {
		/**
		 * @description This method will tell you whether
		 * the form field for a given property should be rendered.
		 * @param {Number} fieldName The name of a field you want to check for.
		 * @returns {Boolean}
		 */
		shouldShow(fieldName) {
			return Object.keys(this.fields).includes(fieldName);
		},

		/**
		 * @description This method will tell you whether the form field is required.
		 * @param {Number} fieldName The name of a field you want to check for.
		 * @returns {Boolean}
		 */
		isRequired(fieldName) {
			return Object.keys(this.fields[fieldName].validations).includes(
				'required'
			);
		},

		/**
		 * @description This method handles the submission of the form.
		 * @returns {Void}
		 */
		submitForm() {
			this.isLoading = true;

			// Clone the formData object.
			const formData = { ...this.formData };

			// Correct the WorkPosition field, as it consists of the name-value pair.
			if (this.formData.WorkPosition) {
				formData.WorkPosition = this.formData.WorkPosition.Value;
			}

			if (this.userRole === 'owner') {
				formData['Name'] = formData['FirstName'];
				delete formData['FirstName'];

				formData['Surname'] = formData['LastName'];
				delete formData['LastName'];

				formData['PhoneNumber'] = formData['Phone'];
				delete formData['Phone'];

				formData.NetworkId = formData.Network.Key;
			}

			if (this.userRole === 'manager') {
				formData.WorkPosition = 'Kierownik Sklepu';
			}

			// Make the API call.
			UserService.createUser(this.userRole, formData)
				.then((response) => {
					this.feedbackMessage.type = 'success';

					// Clear the 'formData' object.
					setTimeout(() => {
						Object.keys(this.formData).forEach((key) => {
							this.formData[key] = '';
							this.$v.$reset();
						});
					}, 3000);

					switch (this.userRole) {
						case 'owner':
							formData.OwnerId = response.data.MemberId;
							break;

						case 'manager':
							formData.ShopManagerId = response.data.MemberId;
							break;

						default:
							break;
					}

					// Call the callback function.
					this.$emit('success', formData);
				})
				.catch((ex) => {
					this.feedbackMessage.type = 'error';
					let message = '';
					if (
						typeof ex.response.data !== 'undefined' &&
						typeof ex.response.data.Message !== 'undefined'
					) {
						message = ex.response.data.Message;
					}

					// Call the callback function.
					this.$emit('error', formData, message);
				})
				.finally(() => {
					this.isLoading = false;
				});
		},

		searchShopHandler(search, loading) {
			loading(true);

			this.$http
				.post(`/spa/managment/shopworkers/getshopdictionary`, {
					Query: search,
					Network:
						this.$store.state.user.Workposition === 'Trener'
							? this.formData.Network
							: null,
				})
				.then((response) => {
					this.shopsNumbersList = response.data;
				})
				.finally(() => {
					loading(false);
				});
		},
	},

	beforeMount() {
		// Get networks list.
		this.$http
			.get(`spa/managment/workers/getnetworkfilters`)
			.then((response) => {
				this.networksList = response.data;
			});

		// Get work positions list.
		this.$http
			.get(`spa/managment/shopworkers/getworkpositionsdictionary`)
			.then((response) => {
				this.workPositionsList = response.data;
			});
	},
};
</script>

<style lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

// Lower the befault 30px bottom margin.
.has-error {
	margin-bottom: 0 !important;
}

.form-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: 1fr;

	// Remove the default max-width setting.
	&__submit-button {
		max-width: unset !important;
	}

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;

		&__submit-button {
			&:nth-child(odd) {
				grid-column: span 2;
				width: 50% !important;
				justify-self: center;
				margin-top: 20px;
			}
		}

		&__feedback-message {
			grid-column: span 2;
		}
	}
}
</style>
