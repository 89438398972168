<template>
  <main>
    <BaseHero :title="'Pomoc i kontakt'"/>
    <HelpContact/>
  </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
import HelpContact from "@/components/Containers/ViewHelpContact/HelpContact.vue";

export default {
  name: "ViewHelpContact",
  components: {
    BaseHero,
    HelpContact
  }
};
</script>
