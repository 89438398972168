<template>
    <main>
        <div class="live-lecture-form small">
            <div class="task-form-form column">
                <div class="header">Odpowiedzi użytkownika</div>
                <div class="card">
                    <form class="form-wrap" @submit.prevent="submitForm">
                        <div v-for="task in  form.OpenQuestionTasks" :key="task.Id">
                            <p class=" has-text-left">{{task.Text}}</p>
                            <textarea v-model="task.Response" :maxlength="1000" disabled/>
                            <br />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
   
    import ViewImplementationProgramWeek from "@/components/Containers/ViewImplementationProgram/ViewImplementationProgramWeek.vue";
    import global from "@/mixins/global.js";
    export default {
        name: "FormDisplay",
        mixins: [global],
        components: {
            ViewImplementationProgramWeek
        },
        data() {
            return {
                form: {},
            };
        },
        props: {
            userId: {
                type: Number
            },
            formId: {
                type: Number
            }

        },


        methods: {

            loadForm() {
                let self = this;
                self.$http
                    .get(`spa/ImplementationPrograms/Form/${self.formId}/user/${self.userId}`)
                    .then(response => {

                        self.form = response.data;

                    })
                    .catch((e) => { console.log(e); });
            },
        },
        created: function () {
            this.loadForm();
        }
    };
</script>

<style scoped lang="scss">
    @import "sass-rem";
    @import "./../../../assets/scss/variables";
    @import "~bulma/sass/utilities/mixins";

    .header {
        background-color: #3AAB59;
        color: $white;
        min-height: 56px;
        text-align: center;
        font-size: 1.1rem;
        font-weight: bold;
        padding: 1.25rem;
        margin-bottom: 15px;
    }


    .task-form-form {
        background-color: transparent;
        margin: 30px 0 0 0;
        .card
        {
            

            @include tablet {
                padding: 2.5rem 2.5rem 1.85rem;
            }

        p
        {
            color: #4a4a4a;
        }

        .error {
            color: red;
            text-align: center;
        }

        .form-wrap {
            
            p {
                  font-size: 16px;
                  font-weight: bold;
                  margin: 10px 0;
            }

            input
            {
                width: 100%;
                display: block;
                border: 1px solid #c8c8c8;
                border-radius: 4px;
                height: 56px;
                margin-bottom: 1.25rem;
                padding: 1rem;
                font-size: 1rem;
                color: #686868;
            }

            textarea {
                width: 100%;
                display: block;
                border: 1px solid #c8c8c8;
                border-radius: 4px;
                height: 170px;
                margin-bottom: 1.25rem;
                padding: 1rem;
                font-size: 1rem;
                color: #686868;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }



            button[type='submit'] {
                color: $white;
                /*margin-top: 5rem;*/
                width: 20%;
                display: block;
                border: 1px solid #c8c8c8;
                border-radius: 4px;
                height: 56px;
                margin-bottom: 1.25rem;
                padding: 1rem;
                font-size: 1rem;
                margin-left: auto;
                margin-right: auto;
            }

        }
    }
 }
</style>
