<template>
	<section class="operation-management section">
		<div class="container">
			<div class="columns">
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu">
						<div v-for="(filterType, key) in filtersList" :key="key">
							<b-collapse
								:open.sync="filterType.isOpen"
								v-if="filterType.Type === 'select'"
							>
								<p
									class="menu-label is-size-7 has-text-black has-border-bottom"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<ul class="menu-list">
										<li
											class="is-flex"
											:class="{
												'is-selected': filters[filterType.Key] === filter.Key,
											}"
											v-for="filter in filterType.Options"
											:key="filter.Key"
										>
											<a
												@click="
													(filters[filterType.Key] = filter.Key),
														(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
												class="is-size-7"
												>{{ filter.Value }}</a
											>
											<button
												class="delete is-small"
												@click="
													clearFiltersHandler(
														filterType.Key,
														activeTab === 0 ? loadWorkersHandler : null
													)
												"
											></button>
										</li>
									</ul>
								</div>
							</b-collapse>
							<div v-else>
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold filter-input"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<div class="column is-full">
							<div class="columns is-variable is-3 actions">
								<div class="column is-half is-offset-half">
									<div class="columns">
										<div
											class="column is-12-tablet is-8-desktop is-offset-4-desktop is-6-widescreen is-offset-6-widescreen"
										>
											<a
												href="#"
												@click.prevent="
													(isAddWorkerModalActive = true), $v.$reset()
												"
												class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
											>
												<span class="icon is-small">
													<AddIcon />
												</span>
												<span>Dodaj pracownika</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<b-tabs class="b-tabs-operation" v-model="activeTab">
							<b-tab-item label="Dane">
								<div class="column is-full is-block has-text-centered">
									<div class="card-table card-table-has-expand">
										<div class="card is-hidden-mobile">
											<div class="card-header">
												<div class="field">
													<div class="control has-icons-left has-icons-right">
														<input
															class="input is-size-4"
															type="text"
															v-model="filters.SearchPhrase"
															:placeholder="'Szukaj pracownika'"
															@change="
																$emit('update:search', $event.target.value)
															"
														/>
														<span class="icon is-small is-left">
															<LoupeIcon />
														</span>
													</div>
												</div>
											</div>
										</div>
										<table class="table table-workers">
											<thead class="is-hidden-mobile">
												<tr
													class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
												>
													<th class="has-text-grey-light">Imię</th>
													<th class="has-text-grey-light">Nazwisko</th>
													<th class="has-text-grey-light">Stanowisko</th>
													<th class="has-text-grey-light">Email</th>
													<th
														class="has-text-grey-light"
														v-if="
															!filters.WorkpositionType ||
															filters.WorkpositionType === 3
														"
													>
														Liczba sklepów
													</th>
													<th
														class="has-text-grey-light"
														v-if="filters.WorkpositionType === 4"
													>
														Liczba podwładnych
													</th>
													<th class="has-text-grey-light"></th>
												</tr>
												<tr v-if="loading">
													<th colspan="6" class="is-progress-bar-table">
														<progress
															class="progress is-primary"
															max="100"
														></progress>
													</th>
												</tr>
											</thead>
											<tbody v-if="workersList.length">
												<tr
													class="is-size-17 has-text-left has-text-weight-semibold"
													v-for="(worker, key) in workersList"
													:key="key"
													:class="{ 'is-active': worker.isOpen }"
												>
													<td colspan="6" class="is-paddingless">
														<table>
															<tr @click="worker.isOpen = !worker.isOpen">
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Imię</span
																		>
																		<span>{{ worker.FirstName }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Nazwisko</span
																		>
																		<span>{{ worker.LastName }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Stanowisko</span
																		>
																		<span>{{ worker.WorkPosition }}</span>
																	</div>
																</td>
																<td>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Email</span
																		>
																		<span>{{ worker.Email }}</span>
																	</div>
																</td>
																<td
																	v-if="
																		!filters.WorkpositionType ||
																		filters.WorkpositionType === 3 ||
																		filters.WorkpositionType === 8
																	"
																>
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Liczba sklepów</span
																		>
																		<span>{{
																			worker.ShopCount ? worker.ShopCount : 0
																		}}</span>
																	</div>
																</td>
																<td v-if="filters.WorkpositionType === 4">
																	<div class="is-dotted is-block">
																		<span
																			class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
																			>Liczba podwładnych</span
																		>
																		<span>{{ worker.WorkersCount }}</span>
																	</div>
																</td>
																<td class="is-hidden-mobile has-text-centered">
																	<DwnArrowIcon
																		width="12"
																		height="24"
																		viewBox="0 0 12 20"
																		class="dwn-arrow-icon"
																	/>
																</td>
															</tr>
															<transition name="has-fade">
																<tr v-if="worker.isOpen">
																	<td colspan="8" class="is-paddingless">
																		<table
																			:class="{ 'is-editable': worker.edit }"
																		>
																			<tbody>
																				<tr class="is-size-17">
																					<td>
																						<b-tabs
																							v-model="worker.activeTab"
																							@input="
																								worker.WorkPosition ===
																								'Kierownik Obszaru'
																									? loadOperationWorkersHandler(
																											worker
																									  )
																									: loadOperationShopsHandler(
																											worker
																									  )
																							"
																						>
																							<b-tab-item>
																								<template slot="header">
																									<strong
																										>Dane pracownika</strong
																									>
																								</template>
																								<div
																									class="columns is-variable is-8 is-multiline"
																								>
																									<div
																										class="column is-12-tablet is-6-desktop"
																									>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Sieć</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																													>
																														<input
																															class="input"
																															v-model="
																																formData
																																	.editWorker
																																	.Network
																															"
																															type="text"
																															disabled
																														/>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.Network
																														? worker.Network
																														: 'brak'
																												}}</span
																											>
																										</div>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Imię</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																														:class="{
																															'has-error':
																																$v.formData
																																	.editWorker
																																	.Name.$error,
																														}"
																													>
																														<input
																															class="input"
																															@blur="
																																$v.formData.editWorker.Name.$touch()
																															"
																															v-model="
																																formData
																																	.editWorker
																																	.Name
																															"
																															type="text"
																														/>
																														<span
																															class="is-size-8 has-text-weight-light has-text-danger"
																															v-for="(error,
																															index) in validationMessages(
																																$v.formData
																																	.editWorker
																																	.Name
																															)"
																															:key="index"
																															>{{ error }}</span
																														>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.FirstName
																														? worker.FirstName
																														: 'brak'
																												}}</span
																											>
																										</div>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Nazwisko</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																														:class="{
																															'has-error':
																																$v.formData
																																	.editWorker
																																	.Surname
																																	.$error,
																														}"
																													>
																														<input
																															class="input"
																															@blur="
																																$v.formData.editWorker.Surname.$touch()
																															"
																															v-model="
																																formData
																																	.editWorker
																																	.Surname
																															"
																															type="text"
																														/>
																														<span
																															class="is-size-8 has-text-weight-light has-text-danger"
																															v-for="(error,
																															index) in validationMessages(
																																$v.formData
																																	.editWorker
																																	.Surname
																															)"
																															:key="index"
																															>{{ error }}</span
																														>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.LastName
																														? worker.LastName
																														: 'brak'
																												}}</span
																											>
																										</div>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Stanowisko</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																													>
																														<input
																															class="input"
																															v-model="
																																formData
																																	.editWorker
																																	.WorkPosition
																															"
																															type="text"
																															disabled
																														/>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.WorkPosition
																														? worker.WorkPosition
																														: 'brak'
																												}}</span
																											>
																										</div>
																									</div>
																									<div
																										class="column is-12-tablet is-6-desktop"
																									>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Email</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																													>
																														<input
																															class="input"
																															v-model="
																																formData
																																	.editWorker
																																	.Email
																															"
																															type="text"
																															disabled
																														/>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.Email
																														? worker.Email
																														: 'brak'
																												}}</span
																											>
																										</div>
																										<div
																											class="is-dotted has-text-left"
																										>
																											<span>Telefon</span>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container-text has-background-white"
																														:class="{
																															'has-error':
																																$v.formData
																																	.editWorker
																																	.PhoneNumber
																																	.$error,
																														}"
																													>
																														<input
																															class="input"
																															@blur="
																																$v.formData.editWorker.PhoneNumber.$touch()
																															"
																															v-model="
																																formData
																																	.editWorker
																																	.PhoneNumber
																															"
																															type="text"
																														/>
																														<span
																															class="is-size-8 has-text-weight-light has-text-danger"
																															v-for="(error,
																															index) in validationMessages(
																																$v.formData
																																	.editWorker
																																	.PhoneNumber
																															)"
																															:key="index"
																															>{{ error }}</span
																														>
																													</div>
																												</div>
																											</div>
																											<span
																												class="is-size-3 has-text-weight-light has-text-primary"
																												>{{
																													worker.Phone
																														? worker.Phone
																														: 'brak'
																												}}</span
																											>
																										</div>
																										<div
																											class="is-flex has-select"
																										>
																											<div
																												class="is-dotted has-text-left"
																											>
																												<span>Status</span>
																												<span
																													class="is-size-3 has-text-weight-light has-text-primary"
																													>{{
																														worker.Status
																													}}</span
																												>
																											</div>
																											<div class="field">
																												<div class="control">
																													<div
																														class="input-container has-background-white"
																													>
																														<v-selector
																															:max-height="'300px'"
																															label="Key"
																															v-model="
																																formData
																																	.editWorker
																																	.Status
																															"
																															:options="
																																statusList.slice(
																																	1
																																)
																															"
																															:searchable="
																																false
																															"
																															placeholder="Wybierz"
																															disabled
																														></v-selector>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</b-tab-item>
																							<b-tab-item>
																								<template slot="header">
																									<strong>{{
																										worker.WorkPosition ===
																										'Kierownik Obszaru'
																											? 'Lista podwładnych'
																											: 'Lista sklepów'
																									}}</strong>
																								</template>
																								<div
																									class="columns is-variable is-8 is-multiline"
																								>
																									<div class="column is-full">
																										<table
																											class="table"
																											v-if="
																												worker.WorkPosition ===
																												'Kierownik Obszaru'
																											"
																										>
																											<thead
																												class="is-hidden-mobile"
																											>
																												<tr
																													class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
																												>
																													<th
																														class="has-text-grey-light"
																													>
																														IMIĘ
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														NAZWISKO
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														STANOWISKO
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														E-MAIL
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														NR TELEFONU
																													</th>
																												</tr>
																												<tr
																													v-if="worker.loading"
																												>
																													<th
																														colspan="5"
																														class="is-progress-bar-table"
																													>
																														<progress
																															class="progress is-primary"
																															max="100"
																														></progress>
																													</th>
																												</tr>
																											</thead>
																											<tbody
																												v-if="
																													worker.workers
																														? worker.workers
																																.length
																														: false
																												"
																											>
																												<tr
																													class="has-text-left has-text-weight-semibold"
																													v-for="worker in worker.workers"
																													:key="worker.Id"
																												>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																worker.FirstName
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																worker.LastName
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																worker.WorkPosition
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																worker.Email
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																worker.Phone
																															}}</span>
																														</div>
																													</td>
																												</tr>
																											</tbody>
																											<tbody v-else>
																												<tr>
																													<td
																														colspan="6"
																														class="has-text-centered has-text-weight-semibold"
																													>
																														{{
																															worker.loading
																																? 'Wczytywanie...'
																																: 'Brak danych'
																														}}
																													</td>
																												</tr>
																											</tbody>
																										</table>
																										<table class="table" v-else>
																											<thead
																												class="is-hidden-mobile"
																											>
																												<tr
																													class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
																												>
																													<th
																														class="has-text-grey-light"
																													>
																														Kod sklepu
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														Nazwa sklepu
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														Miasto
																													</th>
																													<th
																														class="has-text-grey-light"
																													>
																														Województwo
																													</th>
																													<th
																														class="has-text-grey-light has-text-centered-tablet"
																													>
																														Status
																													</th>
																												</tr>
																												<tr
																													v-if="worker.loading"
																												>
																													<th
																														colspan="5"
																														class="is-progress-bar-table"
																													>
																														<progress
																															class="progress is-primary"
																															max="100"
																														></progress>
																													</th>
																												</tr>
																											</thead>
																											<tbody
																												v-if="
																													worker.shops
																														? worker.shops
																																.length
																														: false
																												"
																											>
																												<tr
																													class="has-text-left has-text-weight-semibold"
																													v-for="shop in worker.shops"
																													:key="shop.Id"
																												>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																shop.ShopNumber
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																shop.Name
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																shop.City
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block"
																														>
																															<span>{{
																																shop.Province
																															}}</span>
																														</div>
																													</td>
																													<td
																														class="has-text-black"
																													>
																														<div
																															class="is-block has-text-centered"
																														>
																															<i
																																class="is-dot is-inline-block is-hidden-mobile"
																																:class="{
																																	'has-background-info':
																																		shop.Status ===
																																		'ActiveUsers',
																																	'has-background-warning':
																																		shop.Status ===
																																			'Active' ||
																																		(shop.StatusId ===
																																			1 &&
																																			shop.Status !==
																																				'ActiveUsers'),
																																	'has-background-danger':
																																		shop.Status ===
																																			'InActive' ||
																																		(shop.StatusId ===
																																			2 &&
																																			shop.StatusId !==
																																				1),
																																}"
																															></i>
																														</div>
																													</td>
																												</tr>
																											</tbody>
																											<tbody v-else>
																												<tr>
																													<td
																														colspan="6"
																														class="has-text-centered has-text-weight-semibold"
																													>
																														{{
																															worker.loading
																																? 'Wczytywanie...'
																																: 'Brak danych'
																														}}
																													</td>
																												</tr>
																											</tbody>
																										</table>
																										<a
																											class="button is-info is-uppercase has-text-weight-semibold has-glow-button is-outlined is-outlined-bold is-pulled-right"
																											:href="
																												'/spa/managment/workers/subworkersexport/' +
																												worker.Id
																											"
																											v-if="
																												worker.WorkPosition ===
																													'Kierownik Obszaru' &&
																												worker.workers.length
																											"
																										>
																											<span class="is-size-7-em"
																												>Eksport listy
																												pracowników</span
																											>
																										</a>
																										<a
																											class="button is-info is-uppercase has-text-weight-semibold has-glow-button is-outlined is-outlined-bold is-pulled-right"
																											:href="
																												'/spa/managment/workers/cashopsexport/' +
																												worker.Id
																											"
																											v-else-if="
																												worker.shops.length
																											"
																										>
																											<span class="is-size-7-em"
																												>Eksport listy
																												sklepów</span
																											>
																										</a>
																									</div>
																								</div>
																							</b-tab-item>
																						</b-tabs>
																						<div
																							class="columns is-multiline is-flex worker-edit"
																						>
																							<div class="column is-12 is-flex">
																								<button
																									class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold is-outlined is-outlined-bold has-glow-button"
																									@click="
																										setWorkerHandler(worker)
																									"
																									v-if="!worker.edit"
																								>
																									<span class="icon is-small"
																										><CogWheelIcon
																									/></span>
																									<span>Edycja danych</span>
																								</button>
																								<button
																									@click.prevent="
																										worker.edit = !worker.edit
																									"
																									class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold is-outlined is-outlined-bold has-glow-button"
																									v-if="worker.edit"
																								>
																									<span>Anuluj</span>
																								</button>
																								<button
																									@click.prevent="
																										submitEditFormHandler(
																											worker
																										)
																									"
																									:class="{
																										'is-loading': loading,
																									}"
																									class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
																									v-if="worker.edit"
																								>
																									<span>Zapisz</span>
																								</button>
																							</div>
																							<div
																								class="column is-6 is-offset-6 is-size-8 has-text-weight-light has-text-right is-paddingless"
																							>
																								<p
																									class="has-text-danger"
																									v-if="errorMsg"
																								>
																									{{ errorMsg }}
																								</p>
																								<p
																									class="has-text-primary"
																									v-if="successMsg"
																								>
																									{{ successMsg }}
																								</p>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</td>
																</tr>
															</transition>
														</table>
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<tr>
													<td
														colspan="8"
														class="is-size-17 has-text-centered has-text-weight-semibold"
													>
														{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<a
										class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
										@click="
											filters.Page++;
											loadWorkersHandler();
										"
										v-if="nextPage"
										>Załaduj więcej treści</a
									>
								</div>
							</b-tab-item>
						</b-tabs>
					</div>
				</div>
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li v-for="(filterType, key) in filtersList" :key="key">
							<div v-if="filterType.Type === 'select'">
								<b-collapse :open.sync="filterType.isOpen">
									<span
										class="has-arrow is-flex"
										slot="trigger"
										:class="{ 'is-active': filterType.isOpen }"
									>
										<span
											class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
											>{{ filterType.Title }}</span
										>
									</span>
									<div>
										<div class="navbar-content">
											<ul>
												<li
													class="is-flex has-text-left"
													:class="{
														'is-selected':
															filters[filterType.Key] === filter.Key,
													}"
													v-for="filter in filterType.Options"
													:key="filter.Key"
												>
													<a
														@click="
															(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																activeTab === 0 ? loadWorkersHandler() : null
														"
														>{{ filter.Value }}</a
													>
													<button
														class="delete is-small"
														@click="
															clearFiltersHandler(
																filterType.Key,
																activeTab === 0 ? loadWorkersHandler : null
															)
														"
													></button>
												</li>
											</ul>
										</div>
									</div>
								</b-collapse>
							</div>
							<div v-else class="filter-input">
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold has-text-left"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="
													(filters.Page = 0),
														activeTab === 0 ? loadWorkersHandler() : null
												"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
		<b-modal
			:active.sync="isAddWorkerModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isAddWorkerModalActive = false"
			></button>
			<form @submit.prevent="submitAddFormHandler" novalidate>
				<div class="columns is-multiline">
					<div class="column is-full">
						<h2 class="is-size-1 has-text-left has-text-black">
							Dodaj pracownika
						</h2>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Imię',
										height: 56,
										hasContent: formData.newWorker.Name ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Name.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Name"
										@blur="$v.formData.newWorker.Name.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Name
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Nazwisko',
										height: 56,
										hasContent: formData.newWorker.Surname ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Surname.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Surname"
										@blur="$v.formData.newWorker.Surname.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Surname
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Adres e-mail',
										height: 56,
										hasContent: formData.newWorker.Email ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.Email.$error,
									}"
								>
									<input
										v-model="formData.newWorker.Email"
										@blur="$v.formData.newWorker.Email.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.Email
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
					</div>
					<div class="column">
						<div class="field">
							<div class="control">
								<div
									class="input-container"
									:class="{
										'has-error': this.$v.formData.newWorker.WorkPositionId
											.$error,
									}"
								>
									<div>
										<v-selector
											:max-height="'300px'"
											label="Value"
											@input="formData.newWorker.WorkPositionId = $event.Key"
											:options="workPositionsList"
											placeholder="Stanowisko"
										>
											<span slot="no-options"
												>Brak wyników spełniających kryteria wyszukiwania</span
											>
										</v-selector>
									</div>
								</div>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.WorkPositionId
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div class="field">
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Numer telefonu',
										height: 56,
										hasContent: formData.newWorker.PhoneNumber ? true : false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
										hasError: this.$v.formData.newWorker.PhoneNumber.$error,
									}"
								>
									<input
										v-model="formData.newWorker.PhoneNumber"
										@blur="$v.formData.newWorker.PhoneNumber.$touch()"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
								<span
									class="is-size-8 has-text-weight-light has-text-danger"
									v-for="(error, index) in validationMessages(
										$v.formData.newWorker.PhoneNumber
									)"
									:key="index"
									>{{ error }}</span
								>
							</div>
						</div>
						<div
							class="field"
							v-if="formData.newWorker.WorkPositionId === 'Hurtownia'"
						>
							<div class="control">
								<FloatingLabel
									:config="{
										label: 'Kod Hurtowni',
										height: 56,
										hasContent: formData.newWorker.StorehouseCode
											? true
											: false,
										hasClearButton: false,
										line: false,
										color: {
											focusColor: '#3AAB59',
											errorColor: '#de2647',
											lineColor: '#b5b5b5',
											blurredColor: '#b5b5b5',
										},
									}"
								>
									<input
										v-model="formData.newWorker.StorehouseCode"
										type="text"
										class="input"
										required
									/>
								</FloatingLabel>
							</div>
						</div>
						<button
							href="#"
							class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
							:class="{ 'is-loading': loading }"
							type="submit"
						>
							<span class="icon is-small">
								<svg width="17" height="20" viewBox="0 2 17 16">
									<use
										:xlink:href="'/assets/2018/svg/buttons/add.svg#add'"
									></use>
								</svg>
							</span>
							<span>Dodaj pracownika</span>
						</button>
						<p
							class="is-size-8 has-text-weight-light has-text-danger has-text-centered is-block"
							v-if="errorMsg"
						>
							{{ errorMsg }}
						</p>
						<p
							class="is-size-8 has-text-weight-light has-text-primary has-text-centered is-block"
							v-if="successMsg"
						>
							{{ successMsg }}
						</p>
					</div>
				</div>
			</form>
		</b-modal>
	</section>
</template>

<script>
/* eslint-disable */

import { debounce } from 'lodash';
import global from '@/mixins/global.js';
import { required, email, numeric } from 'vuelidate/lib/validators';
import WorkersStats from './../ViewWorkersManagement/WorkersStats';
import FloatingLabel from '@/components/BaseFloatingLabel';
import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import DwnArrowIcon from '@/assets/svg/dwn-arrow-large.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';

export default {
	name: 'OperationManagement',
	mixins: [global],
	components: {
		WorkersStats,
		FloatingLabel,
		AddIcon,
		LoupeIcon,
		DwnArrowIcon,
		CogWheelIcon,
	},
	props: {
		search: {
			type: String,
		},
	},
	mounted() {},
	data() {
		return {
			workersList: [],
			filtersList: [],
			workPositionsList: [],
			filters: {
				Page: 0,
				ShopNumber: null,
				SearchPhrase: '',
			},
			statusList: [
				{ Key: 'DODANY', Value: 0 },
				{ Key: 'AKTYWNY', Value: 1 },
				{ Key: 'UCZESTNIK WARSZTATU', Value: 2 },
				{ Key: 'USUNIĘTY', Value: 3 },
			],
			formData: {
				editWorker: {
					Id: null,
					Network: '',
					Name: '',
					Surname: '',
					WorkPosition: '',
					Email: '',
					PhoneNumber: '',
					ShopNumber: '',
					Status: null,
				},
				newWorker: {
					SuperiorId: this.$store.state.user.Id,
					Name: '',
					Surname: '',
					Email: '',
					WorkPositionId: '',
					PhoneNumber: '',
				},
			},
			nextPage: true,
			isAddWorkerModalActive: false,
			errorMsg: '',
			successMsg: '',
			loading: false,
			activeTab: 0,
		};
	},
	validations: {
		formData: {
			editWorker: {
				Name: {
					required,
				},
				Surname: {
					required,
				},
				WorkPosition: {
					required,
				},
				PhoneNumber: {
					numeric,
				},
				Email: {
					email,
					required,
				},
				Status: {
					required,
				},
			},
			newWorker: {
				Name: {
					required,
				},
				Surname: {
					required,
				},
				Email: {
					required,
					email,
				},
				WorkPositionId: {
					required,
				},
				PhoneNumber: {
					numeric,
				},
			},
		},
		editWorkerValidationGroup: [
			'formData.editWorker.Name',
			'formData.editWorker.Surname',
			'formData.editWorker.WorkPosition',
			'formData.editWorker.PhoneNumber',
			'formData.editWorker.Status',
		],
		newWorkerValidationGroup: [
			'formData.newWorker.Name',
			'formData.newWorker.Surname',
			'formData.newWorker.Email',
			'formData.newWorker.WorkPositionId',
			'formData.newWorker.PhoneNumber',
		],
	},
	methods: {
		loadWorkersHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/managment/workers/list`, self.filters)
				.then((response) => {
					let data = response.data.Data;

					data.forEach(
						(item) => (
							(item.isOpen = false),
							(item.edit = false),
							(item.loading = false),
							(item.workers = []),
							(item.shops = [])
						)
					);
					if (self.filters.Page) {
						self.workersList = [...self.workersList, ...data];
						self.nextPage = response.data.NextPage;
					} else {
						self.workersList = data;
						self.nextPage = response.data.NextPage;
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		loadOperationWorkersHandler(worker) {
			if (!worker.workers.length) {
				let self = this;

				worker.loading = true;
				self.$http
					.get(`/spa/managment/workers/subworkers/${worker.Id}`)
					.then((response) => {
						worker.workers = response.data;
						worker.loading = false;
					})
					.catch(() => {});
			}
		},
		loadOperationShopsHandler(worker) {
			if (!worker.shops.length) {
				let self = this;

				worker.loading = true;
				self.$http
					.get(`/spa/managment/workers/cashops/${worker.Id}`)
					.then((response) => {
						worker.shops = response.data;
						worker.loading = false;
					})
					.catch(() => {});
			}
		},
		loadWorkersFiltersHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/workers/filters`)
				.then((response) => {
					this.filtersList = response.data;
					this.filtersList.forEach(function (item) {
						self.$set(item, 'isOpen', false);
						self.$set(item, 'activeTab', 0);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},
		loadWorkPositionsDictionaryHandler() {
			let self = this;

			self.$http
				.get(`spa/managment/workers/operationselectors`)
				.then((response) => {
					self.workPositionsList = response.data.Workers;
				})
				.catch(() => {});
		},
		setWorkerHandler(worker) {
			this.$v.$reset();
			worker.activeTab = 0;
			this.workersList.forEach((i) => (i.edit = false));
			worker.edit = true;

			this.formData.editWorker.Id = worker.Id;
			this.formData.editWorker.Network = worker.Network;
			this.formData.editWorker.Name = worker.FirstName;
			this.formData.editWorker.Surname = worker.LastName;
			this.formData.editWorker.WorkPosition = worker.WorkPosition;
			this.formData.editWorker.PhoneNumber = worker.Phone;
			this.formData.editWorker.Email = worker.Email;
			this.formData.editWorker.Status = worker.Status;
		},
		submitEditFormHandler: function (worker, reload) {
			let self = this,
				submitForm = (worker, reload) => {
					self.loading = true;
					self.$http
						.post(`/spa/managment/workers/edit`, self.formData.editWorker)
						.then((response) => {
							self.loading = false;
							self.errorMsg = '';
							self.successMsg = response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.successMsg = '';
								if (worker) {
									worker.Id = self.formData.editWorker.Id;
									worker.Network = self.formData.editWorker.Network;
									worker.FirstName = self.formData.editWorker.Name;
									worker.LastName = self.formData.editWorker.Surname;
									worker.WorkPosition = self.formData.editWorker.WorkPosition;
									worker.Phone = self.formData.editWorker.PhoneNumber;
									worker.LoginName = self.formData.editWorker.Email;
									worker.Status = self.formData.editWorker.Status;
									worker.edit = false;
								}
								if (reload) {
									self.filters.Page = 0;
									self.loadWorkersHandler();
								}
							}, 1000);
						})
						.catch((error) => {
							self.loading = false;
							self.successMsg = '';
							self.errorMsg = error.response.data.Message;
							self.$v.$reset();
							setTimeout(() => {
								self.errorMsg = '';
							}, 3000);
						});
				};
			self.$v.$touch();

			if (!self.$v.editWorkerValidationGroup.$invalid) {
				submitForm(worker, reload);
			}
		},
		submitAddFormHandler: function () {
			let self = this;

			self.$v.$touch();
			if (!this.$v.newWorkerValidationGroup.$invalid) {
				self.loading = true;
				this.$http
					.post(`/spa/managment/workers/add`, self.formData.newWorker)
					.then((response) => {
						self.loading = false;
						self.errorMsg = '';
						self.successMsg = response.data.Message;
						for (let prop of Object.getOwnPropertyNames(
							self.formData.newWorker
						))
							self.formData.newWorker[prop] = '';
						self.$v.$reset();
						setTimeout(() => {
							self.successMsg = '';
							self.filters.Page = 0;
							self.loadWorkersHandler();
							self.isAddWorkerModalActive = false;
						}, 3000);
					})
					.catch((error) => {
						self.loading = false;
						self.successMsg = '';
						setTimeout(() => {
							self.errorMsg = '';
						}, 3000);
						self.errorMsg = error.response.data.Message;
					});
			}
		},
	},
	watch: {
		search: function () {
			this.filters.SearchPhrase = this.search;
			this.filters.Page = 0;
			if (this.activeTab === 0) this.loadWorkersHandler();
		},
	},
	created: function () {
		this.loadWorkersHandler();
		this.loadWorkersFiltersHandler();
		this.loadWorkPositionsDictionaryHandler();
	},
	activated: function () {
		switch (this.$route.params.dialog) {
			case 'dodaj-pracownika':
				this.isAddWorkerModalActive = true;
				break;
			case 'statystyki':
				this.activeTab = 1;
				break;
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.operation-management {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}
	.button.is-info {
		height: 50px;
		margin-bottom: rem(25px);
	}
	.container {
		& > :first-child {
			& > :nth-child(2) {
				@include desktop {
					margin-top: -100px;
				}
			}
		}
	}
	.b-tabs-operation {
		width: 100%;
		@include desktop {
			margin-top: -92px;
		}
		& > :first-child {
			padding: 0 15px 0;
			@include desktop {
				padding: 20px 10px 0;
			}
		}
		& > :nth-child(2) {
			& > .tab-item {
				overflow: auto;
			}
		}
	}
	.actions,
	.card-table {
		.button.is-info:not(.has-arrow) {
			& + .button.is-info {
				margin-left: rem(20px);
			}
		}
	}
	.card-table {
		.table {
			&.table-workers {
				width: 100%;
				table-layout: fixed;
				& > thead,
				& > tbody > tr > td > table {
					table-layout: fixed;
					& > tr:first-child {
						@include tablet {
							& > *:not(.is-progress-bar-table) {
								width: 13%;
								padding: rem(15px);
								vertical-align: middle;
							}
							& > :nth-child(3) {
								width: 25%;
							}
							& > :nth-child(4) {
								width: 35%;
							}
							& > :nth-child(6) {
								width: 11%;
							}
						}
					}
				}
				.tab-item {
					.is-dotted span + span {
						float: right;
					}
				}
				tbody > tr > td > table > tr:last-child {
					& > td {
						position: relative;
					}
				}
				.table + .button {
					margin-right: 20px;
					margin-bottom: 0;
					max-width: none;
				}
				.b-tabs {
					overflow: unset;
					& > :first-child > :first-child {
						padding-bottom: 50px;
					}
				}
				.worker-edit {
					justify-content: flex-end;
					& > div {
						justify-content: flex-end;
						padding-right: 0;
					}
					p {
						margin-right: 10px;
					}
					button {
						margin-bottom: 0;
					}
					@include desktop {
						width: 50%;
						position: absolute;
						right: 0;
						top: 0;
						margin: 10px 20px;
					}
				}
			}
		}
	}
	.columns {
		.menu {
			text-align: left;
			a {
				padding-left: 0;
				width: 100%;
			}
			.control {
				position: relative;
				z-index: 2;
				.icon {
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 3;
					color: $grey-light;
					display: none;
					@include desktop {
						display: block;
					}
				}
			}
			&-list {
				li {
					justify-content: space-between;
					letter-spacing: 1px;
					a {
						padding-right: 5px;
					}
				}
				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
			.filter-input {
				padding: rem(25px 0);
				margin-bottom: 0;
			}
		}
	}

	.filters {
		.menu-list {
			.filter-input {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
		}
	}
	.modal {
		& > :nth-child(2) {
			width: 830px;
			padding: rem(40px);
			overflow: auto;
			@include widescreen {
				overflow: visible;
			}
		}
		.field {
			margin-bottom: rem(30px);
			.control {
				.input__container,
				.input-container {
					&.has-error {
						margin-bottom: 0;
					}
					& + .has-text-danger {
						margin-top: 0;
					}
				}
			}
		}
		.field + .button {
			color: rgba(58, 171, 89, 0.5);
			height: 56px;
			width: 100%;
			max-width: none;
			.icon {
				margin-top: 4px;
			}
			span {
				color: $white;
			}
		}
		.button {
			color: rgba(58, 171, 89, 0.5);
			border-radius: 5px;
			height: 50px;
			width: 100%;
			&:not(:last-child) {
				margin-bottom: rem(20px);
			}
			span {
				color: white;
			}
			.icon {
				margin-right: 5px;
			}
			&.is-danger {
				color: rgba(215, 33, 65, 0.5);
			}
		}
	}
}
</style>
