<template>
  <b-modal
    :active="isActive"
    :width="830"
    :canCancel="['escape', 'x', 'outside']"
    scroll="keep"
    class="modal-dialog"
  >
    <button type="button" class="modal-close is-large" @click="$emit('close-modal')"></button>

    <!-- Dialog -->
    <div class="columns">
      <div class="column is-full">
        <p
          class="is-size-2 has-text-centered has-text-weight-light"
        >Czy na pewno chcesz usunąć {{ subject === 'user' ? 'użytkownika' : 'sklep' }}?</p>
      </div>
    </div>

    <div class="columns">
      <!-- Buttons -->
      <div class="column is-6 has-text-right-desktop">
        <a
          class="button is-fullwidth is-small is-danger is-uppercase has-text-weight-bold has-glow-button"
          :class="{ 'is-loading': isLoading }"
          @click="$emit('confirm-removal')"
        >
          <span>TAK</span>
        </a>
      </div>
      <div class="column is-6 has-text-left-desktop">
        <a
          class="button is-fullwidth is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
          @click="$emit('close-modal')"
        >
          <span>NIE</span>
        </a>
      </div>

      <!-- Error messages -->
      <div class="column is-full" v-if="messages.success || messages.error">
        <p
          class="is-size-8 has-text-weight-light has-text-danger has-text-centered"
          v-if="messages.error"
        >{{ messages.error }}</p>
        <p
          class="is-size-8 has-text-weight-light has-text-primary has-text-centered"
          v-if="messages.success"
        >{{ messages.success }}</p>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    },

    subject: {
      type: String,
      required: false,
      default: "user"
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    messages: {
      type: Object,
      required: false,
      default: () => ({
        success: "",
        error: ""
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>