var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"NameBox card"},[_c('div',{staticClass:"card-content is-paddingless"},[_c('div',{staticClass:"columns is-marginless"},[_c('div',{staticClass:"NameBox__username column is-one-quarter"},[_c('PersonOvalIcon'),_c('h1',[_c('span',{staticClass:"NameBox__username-work-title"},[_vm._v(_vm._s(_vm.workTitle))]),_c('br'),_c('span',{staticClass:"NameBox__username-name"},[_vm._v(_vm._s((_vm.userFirstName + " " + _vm.userLastName)))])])],1),_c('dl',{staticClass:"NameBox__data column"},[_c('Field',{attrs:{"value":_vm.get('FirstName'),"name":"Imię","id":"FirstName"}}),_c('Field',{attrs:{"value":_vm.get('LastName'),"name":"Nazwisko","id":"LastName"}}),_c('Field',{attrs:{"value":_vm.get('Email'),"name":"Adres e-mail","id":"Email"}}),_c('Field',{attrs:{"value":_vm.get('Phone'),"name":"Numer telefonu","id":"Phone"}}),_c('Field',{attrs:{"value":_vm.get('WorkTitle'),"name":"Stanowisko","id":"WorkTitle"}}),_c('Field',{attrs:{"value":_vm.get('Status'),"name":"Status","id":"Status"}}),_c('Field',{attrs:{"value":_vm.get('Network'),"name":"Sieć","id":"Network"}}),(_vm.shouldShowShopDetails)?_c('Field',{attrs:{"value":_vm.get('ShopNumber'),"name":"Kod sklepu","id":"ShopNumber"}}):_vm._e(),(_vm.shouldShowShopDetails)?_c('Field',{attrs:{"value":_vm.get('ShopName'),"name":"Adres sklepu","id":"ShopName"}}):_vm._e(),(
						!_vm.isUser([
							'ShopManager',
							'ShopOwner',
							'TrainerUser',
							'NetworkAdminUser',
							'PartnerAdminUser',
							'KOUser',
							'RDOUser',
							'CAUser' ])
					)?_c('Field',{attrs:{"value":_vm.supervisor,"name":"Przełożony","id":"Supervisor"}}):_vm._e(),(
						!_vm.isUser([
							'NetworkAdminUser',
							'TrainerUser',
							'PartnerAdminUser',
							'KOUser',
							'RDOUser',
							'CAUser' ])
					)?_c('Field',{attrs:{"value":_vm.get('Id'),"name":"Numer ID pracownika","id":"Id"}}):_vm._e(),(_vm.userData.Network == 'Detal+' ||
					   _vm.userData.Network == 'Eurocash' ||
					   _vm.userData.Network == 'CashAndCarry')?_c('Field',{attrs:{"value":_vm.get('SapNo'),"name":"Sap","id":"Sap"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }