<template>
	<section class="shops-management section">
		<div class="container">
			<div class="columns">
				<!-- Filters -->
				<div class="column is-one-quarter is-hidden-mobile">
					<aside class="menu">
						<div v-for="(filterType, key) in filtersList" :key="key">
							<b-collapse
								:open.sync="filterType.isOpen"
								v-if="filterType.Type === 'list'"
							>
								<p
									class="menu-label is-size-7 has-text-black has-border-bottom"
									slot="trigger"
									:class="{ 'is-active': filterType.isOpen }"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<ul class="menu-list">
										<li
											class="is-flex"
											:class="{
												'is-selected': filters[filterType.Key] === filter.Key,
											}"
											v-for="filter in filterType.Options"
											:key="filter.Key"
										>
											<a
												@click="
													(filters[filterType.Key] = filter.Key),
														(filters.Page = 0),
														loadShopsHandler()
												"
												class="is-size-7"
												>{{ filter.Value }}</a
											>
											<button
												class="delete is-small"
												@click="
													clearFiltersHandler(filterType.Key, loadShopsHandler)
												"
											></button>
										</li>
									</ul>
								</div>
							</b-collapse>
							<div v-else-if="filterType.Type === 'select'">
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold filter-input"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div class="input-container">
												<v-selector
													:max-height="'300px'"
													@input="
														(filters[filterType.Key] = $event.Value),
															(filters.Page = 0),
															loadShopsHandler()
													"
													label="Key"
													class="filter"
													:options="filterType.Options"
													placeholder="Wybierz"
												>
													<span slot="no-options"
														>Brak wyników spełniających kryteria
														wyszukiwania</span
													>
												</v-selector>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold filter-input"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="(filters.Page = 0), loadShopsHandler()"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</aside>
				</div>
				<!-- END | Filters -->

				<div class="column">
					<div class="columns is-paddingless is-multiline">
						<!-- 'Dodaj sklep' button -->
						<div
							class="column is-full"
							v-if="
								$store.state.user.Workposition !== 'Kierownik Regionalny' &&
								$store.state.user.Workposition !== 'Właściciel'
							"
						>
							<div class="columns is-variable is-3 actions">
								<div class="column is-half is-offset-half">
									<div class="columns">
										<div
											class="column is-12-tablet is-8-desktop is-offset-4-desktop is-6-widescreen is-offset-6-widescreen"
										>
											<button
												class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
												@click="addEditShopHandler()"
											>
												<span class="icon is-small">
													<AddIcon />
												</span>
												<span>Dodaj sklep</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- ENd | 'Dodaj sklep' button -->

						<div class="column is-full is-block has-text-centered">
							<div class="card-table card-table-has-expand">
								<!-- Search box -->
								<div class="card is-hidden-mobile">
									<div class="card-header">
										<div class="field">
											<div class="control has-icons-left has-icons-right">
												<input
													class="input is-size-4"
													type="text"
													v-model="filters.SearchQuery"
													:placeholder="'Szukaj sklepu'"
													@change="$emit('update:search', $event.target.value)"
												/>
												<span class="icon is-small is-left">
													<LoupeIcon />
												</span>
											</div>
										</div>
									</div>
								</div>
								<!-- END | Search box -->

								<!-- Table -->
								<table class="table table-shops">
									<thead class="is-hidden-mobile">
										<!-- Table / Headings -->
										<tr
											class="is-size-18 is-uppercase has-text-weight-semibold has-text-left valign-cells-middle"
										>
											<th class="has-text-grey-light">Sieć</th>
											<th class="has-text-grey-light">Kod sklepu</th>
											<th class="has-text-grey-light">Nazwa sklepu</th>
											<th class="has-text-grey-light">Miasto</th>
											<th class="has-text-grey-light">Województwo</th>
											<th class="has-text-grey-light">Szczegóły</th>
											<th class="has-text-grey-light"></th>
										</tr>
										<!-- END | Table / Headings -->

										<!-- Progress bar -->
										<tr v-if="loading">
											<th colspan="7" class="is-progress-bar-table">
												<progress
													class="progress is-primary"
													max="100"
												></progress>
											</th>
										</tr>
										<!-- END | Progress bar -->
									</thead>
									<tbody>
										<!-- Single shop instance -->
										<template v-for="(shop, index) in shopsList">
											<!-- Single shop instance / Base data -->
											<tr
												:key="index"
												class="shop-row valign-cells-middle is-size-17 has-text-left has-text-weight-semibold"
												:class="{ 'is-active': shop.isOpen }"
											>
												<td>
													<div
														class="is-dotted is-block has-text-centered-desktop"
													>
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Sieć</span
														>
														<span>{{ shop.Network }}</span>
													</div>
												</td>

												<td>
													<div
														class="is-dotted is-block has-text-centered-desktop"
													>
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Kod sklepu</span
														>
														<span>{{ shop.ShopNumber }}</span>
													</div>
												</td>

												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Nazwa sklepu</span
														>
														<span>{{ shop.Name }}</span>
													</div>
												</td>

												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Miasto</span
														>
														<span>{{ shop.City }}</span>
													</div>
												</td>

												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Województwo</span
														>
														<span>{{ shop.Province | truncate(4, ' ') }}</span>
													</div>
												</td>

												<td>
													<div class="is-dotted is-block">
														<span
															class="is-uppercase has-text-weight-semibold has-text-left has-text-grey-light is-hidden-tablet"
															>Szczegóły</span
														>
														<router-link
															:to="{
																name: 'Lista pracowników',
																params: { shopNumber: shop.ShopNumber },
															}"
															class="is-clickable has-text-weight-semibold has-text-left is-clickable"
															:class="{
																'has-text-white': shop.isOpen,
																'has-text-primary': !shop.isOpen,
															}"
															>Lista pracowników >></router-link
														>
													</div>
												</td>

												<td
													class="is-hidden-mobile has-text-centered is-clickable"
													@click="shop.isOpen = !shop.isOpen"
												>
													<DwnArrowIcon
														width="12"
														height="24"
														viewBox="0 0 12 20"
														class="dwn-arrow-icon"
													/>
												</td>
											</tr>
											<!-- END | Single shop instance / Base data -->

											<!-- Single shop instance / Details -->
											<transition name="has-fade" :key="index">
												<tr v-if="shop.isOpen" class="b-collapse-container">
													<td colspan="7" class="is-relative">
														<b-tabs
															v-model="shop.activeTab"
															@input="loadShopWorkersHandler(shop)"
														>
															<b-tab-item>
																<template slot="header">
																	<strong>Dane sklepu</strong>
																</template>

																<dl class="shop-details">
																	<Field
																		title="Nazwa sklepu"
																		:value="shop.Name ? shop.Name : 'brak'"
																	/>
																	<Field
																		title="Ulica"
																		:value="shop.Street ? shop.Street : 'brak'"
																	/>
																	<Field
																		title="Kod pocztowy"
																		:value="
																			shop.PostalCode ? shop.PostalCode : 'brak'
																		"
																	/>
																	<Field
																		title="Miasto"
																		:value="shop.City ? shop.City : 'brak'"
																	/>
																	<Field
																		title="Województwo"
																		:value="
																			shop.Province ? shop.Province : 'brak'
																		"
																	/>
																	<Field
																		title="Telefon"
																		:value="
																			shop.PhoneNumber
																				? shop.PhoneNumber
																				: 'brak'
																		"
																	/>
																	<Field
																		title="Numer sklepu"
																		:value="
																			shop.ShopNumber ? shop.ShopNumber : 'brak'
																		"
																	/>
																	<Field
																		title="Sieć sklepów"
																		:value="
																			shop.Network ? shop.Network : 'brak'
																		"
																	/>
																	<Field
																		title="Status"
																		:value="shop.Status ? shop.Status : 'brak'"
																	/>
																	<Field
																		title="Właściciel"
																		:value="
																			shop.OwnerName ? shop.OwnerName : 'brak'
																		"
																	/>
																	<Field
																		title="Doradca klienta"
																		:value="
																			shop.CustomerAdvisorName
																				? shop.CustomerAdvisorName
																				: 'brak'
																		"
																	/>
																</dl>
															</b-tab-item>
														</b-tabs>

														<!-- Edit button -->
														<div
															class="shop-edit"
															v-if="
																$store.state.user.Workposition !==
																	'Kierownik Regionalny' &&
																$store.state.user.Workposition !== 'Właściciel'
															"
														>
															<button
																class="button is-size-7 is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold is-outlined is-outlined-bold has-glow-button"
																@click="addEditShopHandler(shop)"
															>
																<span class="icon is-small">
																	<CogWheelIcon />
																</span>
																<span>Edycja danych</span>
															</button>
														</div>
														<!-- END | Edit button -->
													</td>
												</tr>
											</transition>
											<!-- END | Single shop instance / Details -->
										</template>

										<!-- Empty table / loading indicator -->
										<tr v-if="!shopsList.length">
											<td
												colspan="7"
												class="is-size-17 has-text-centered has-text-weight-semibold"
											>
												{{ loading ? 'Wczytywanie...' : 'Brak danych' }}
											</td>
										</tr>
										<!-- END | Empty table / loading indicator -->
									</tbody>
								</table>
							</div>

							<!-- Load more button -->
							<a
								class="button is-light is-rounded is-light is-outlined is-size-8-em is-uppercase has-text-weight-semibold"
								@click="
									filters.Page++;
									loadShopsHandler();
								"
								v-if="nextPage"
								>Załaduj więcej treści</a
							>
							<!-- END | Load more button -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<nav class="filters is-fixed-top">
			<aside
				class="menu is-hidden-desktop"
				:class="{ 'is-active': $parent.showFilters }"
			>
				<span v-touch:tap="showFiltersHandler"></span>
				<div
					v-touch:swipe.left="showFiltersHandler"
					v-touch:swipe.right="showFiltersHandler"
				>
					<p
						class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close"
						@click.prevent="$emit('update:showFilters', false)"
					>
						ZAMKNIJ
						<button class="delete is-small"></button>
					</p>
					<ul class="menu-list">
						<li v-for="(filterType, key) in filtersList" :key="key">
							<div v-if="filterType.Type === 'list'">
								<b-collapse :open.sync="filterType.isOpen">
									<span
										class="has-arrow is-flex"
										slot="trigger"
										:class="{ 'is-active': filterType.isOpen }"
									>
										<span
											class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless"
											>{{ filterType.Title }}</span
										>
									</span>
									<div>
										<div class="navbar-content">
											<ul>
												<li
													class="is-flex has-text-left"
													:class="{
														'is-selected':
															filters[filterType.Key] === filter.Key,
													}"
													v-for="filter in filterType.Options"
													:key="filter.Key"
												>
													<a
														@click="
															(filters[filterType.Key] = filter.Key),
																(filters.Page = 0),
																loadShopsHandler()
														"
														>{{ filter.Value }}</a
													>
													<button
														class="delete is-small"
														@click="
															clearFiltersHandler(
																filterType.Key,
																loadShopsHandler
															)
														"
													></button>
												</li>
											</ul>
										</div>
									</div>
								</b-collapse>
							</div>
							<div v-else-if="filterType.Type === 'select'">
								<p
									class="is-size-7 is-info has-text-weight-semibold is-uppercase"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div class="input-container">
												<v-selector
													:max-height="'300px'"
													label="Key"
													class="filter"
													@input="
														(filters[filterType.Key] = $event.Value),
															(filters.Page = 0),
															loadShopsHandler()
													"
													:options="filterType.Options"
													placeholder="Wybierz"
												></v-selector>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else class="filter-input">
								<p
									class="menu-label is-size-7 has-text-black has-text-weight-semibold has-text-left"
								>
									{{ filterType.Title }}
								</p>
								<div class="navbar-content">
									<div class="field">
										<div class="control">
											<div
												class="input-container-text"
												@change="(filters.Page = 0), loadShopsHandler()"
											>
												<input
													class="input"
													type="text"
													v-model.lazy="filters[filterType.Key]"
													@keyup.enter="
														filters[filterType.Key] = $event.target.value
													"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</aside>
		</nav>
	</section>
</template>

<script>
/* eslint-disable */

import { debounce } from 'lodash';
import global from '@/mixins/global.js';
import AddIcon from '@/assets/svg/circle-add.svg';
import LoupeIcon from '@/assets/svg/loupe.svg';
import DwnArrowIcon from '@/assets/svg/dwn-arrow-large.svg';
import CogWheelIcon from '@/assets/svg/cog-wheel.svg';
import PersonIcon from '@/assets/svg/person-small.svg';
import Field from './Field.vue';

export default {
	name: 'ShopsManagement',
	mixins: [global],
	components: {
		AddIcon,
		LoupeIcon,
		DwnArrowIcon,
		CogWheelIcon,
		PersonIcon,
		Field,
	},
	props: {
		search: {
			type: String,
		},
	},
	data() {
		return {
			shopsList: '',
			statsList: [],
			filtersList: [],
			filters: {
				Page: 0,
			},
			nextPage: true,
			loading: false,
		};
	},
	methods: {
		loadShopsHandler() {
			let self = this;

			self.loading = true;
			self.$http
				.post(`spa/managment/shops/getlist`, self.filters)
				.then((response) => {
					let data = response.data.Data;

					data.forEach(
						(item) => (
							(item.isOpen = false),
							(item.edit = false),
							(item.workers = ''),
							(item.loading = false)
						)
					);
					if (self.filters.Page) {
						self.shopsList = [...self.shopsList, ...data];
						self.nextPage = response.data.NextPage;
					} else {
						self.shopsList = data;
						self.nextPage = response.data.NextPage;
					}
					if (!self.$store.state.shop) {
						self.$set(self.$store.state, 'shop', self.shopsList[0]);
						self.$store.commit('UPDATE_STATE');
					}
					self.loading = false;
				})
				.catch(() => {});
		},
		loadShopsFiltersHandler() {
			let self = this;
			self.$http
				.post(`/spa/managment/shops/getfilters`, {
					SearchProvince: '',
				})
				.then((response) => {
					this.filtersList = response.data;
					this.filtersList.forEach(function (item) {
						self.$set(item, 'isOpen', false);
						self.$set(item, 'activeTab', 0);
						self.$set(self.filters, item.Key, null);
					});
				})
				.catch(() => {});
		},
		loadShopWorkersHandler(shop) {
			if (shop.activeTab === 1 && !shop.workers.length) {
				let self = this;

				shop.loading = true;
				self.$http
					.post(`/spa/managment/shops/workers`, {
						Id: shop.Id,
					})
					.then((response) => {
						shop.workers = response.data;
						shop.loading = false;
					})
					.catch(() => {});
			}
		},
		addEditShopHandler(shop) {
			let self = this;

			self.$set(self.$store.state, 'shop', {});
			self.$store.commit('UPDATE_STATE');
			if (shop) {
				for (let prop of Object.getOwnPropertyNames(shop))
					self.$store.state.shop[prop] = shop[prop];
				self.$store.commit('UPDATE_STATE');
				self.$router.push('/panel-zarzadania/edytuj-sklep/' + shop.Id);
			} else {
				for (let prop of Object.getOwnPropertyNames(self.$store.state.shop))
					self.$store.state.shop[prop] = '';
				self.$store.commit('UPDATE_STATE');
				self.$router.push('/panel-zarzadania/dodaj-sklep');
			}
		},
	},
	watch: {
		search: function () {
			this.filters.SearchQuery = this.search;
			this.filters.Page = 0;
			this.loadShopsHandler();
		},
	},
	created: function () {
		this.loadShopsHandler();
		this.loadShopsFiltersHandler();
	},
	activated: function () {
		if (this.shopsList.length) {
			this.shopsList.forEach((item, key) => {
				if (item.Id === this.$store.state.shop.Id) {
					for (let prop of Object.getOwnPropertyNames(item))
						item[prop] = this.$store.state.shop[prop] || item[prop];
					return false;
				} else if (!this.$store.state.shop.Id) {
					this.loadShopsHandler();
				}
			});
		}
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.shops-management {
	padding-bottom: rem(60px);
	padding-top: 0;
	@include desktop {
		padding-top: rem(30px);
	}
	.button.is-info {
		height: 50px;
		min-width: 226px;
		color: rgba(58, 171, 89, 0.5);
		span {
			color: $white;
		}
		&:hover,
		&:focus {
			color: rgba(58, 171, 89, 0.5);
		}
	}
	.container {
		& > :first-child {
			& > :nth-child(2) {
				@include desktop {
					margin-top: -100px;
				}
			}
		}
	}
	.actions,
	.card-table {
		.button.is-info:not(.has-arrow) {
			& + .button.is-info {
				margin-left: rem(20px);
			}
		}
	}

	.shop-row {
		border: 1px solid #dfe0e0;
		transition: all 300ms ease-in-out;

		// Remove the globally defined green borders.
		td {
			border: none !important;
		}

		@include tablet {
			// Remove the white background from the <span>s with text content.
			span,
			a {
				background: transparent;
			}
		}

		&.is-active {
			background-color: #3aab59;
			border-color: #3aab59;

			span {
				color: white;
			}

			// Rotate the chevron-down icon (b-collapse toggler).
			.dwn-arrow-icon {
				transform: rotate(270deg);
				transition: all 300ms ease-in-out;
			}
		}
	}

	// Vertically align the content
	// in <td>s within the selected <tr>.
	.valign-cells-middle {
		> td,
		> th {
			vertical-align: middle;
		}
	}

	.b-collapse-container {
		border: 1px solid #3aab59 !important;
	}

	.shop-edit {
		button {
			margin-bottom: 20px;
			width: 100%;
			max-width: none;
			@include tablet {
				width: auto;
				max-width: 224px;
			}
		}
		@include desktop {
			position: absolute;
			right: 0;
			top: 0;
			margin: 20px;
			button {
				margin-bottom: 0;
			}
		}
	}

	.columns {
		.menu {
			text-align: left;
			a {
				padding-left: 0;
				width: 100%;
			}
			.control {
				position: relative;
				.icon {
					position: absolute;
					right: 10px;
					top: 10px;
					z-index: 3;
					color: $grey-light;
					display: none;
					@include desktop {
						display: block;
					}
				}
			}
			&-list {
				li {
					justify-content: space-between;
					letter-spacing: 1px;
					a {
						padding-right: 5px;
					}
				}
				&:last-child {
					border-bottom: 1px solid $grey-lighter;
					padding: 20px 0 25px 0;
				}
			}
			.filter-input {
				padding: rem(25px 0);
				margin-bottom: 0;
			}
		}
	}
	.filters {
		.menu-list {
			& > :nth-child(3),
			& > :nth-child(4) {
				padding: rem(20px);
				padding-bottom: rem(10px);
				background: $grey-lightest;
				p {
					margin-bottom: rem(15px);
				}
			}
			& > :last-child {
				background: $grey-lightest;
				padding: rem(0 20px 20px);
				p {
					padding: rem(15px 20px 0 0);
				}
			}
		}
	}
	.v-select {
		font-weight: normal;
	}

	.shop-details {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-row-gap: 15px;

		// For larger screens.
		@media (min-width: 480px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;

			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-column-gap: 70px;
		}
	}
}
</style>
