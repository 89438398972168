<template>
    <article class="card" @click="$emit('goToUrlHandler')">
        <div class="columns-wrap">
            <div class="mov-prev-col">
                <div class="movie-screen">
                    <img v-lazy="workShop.Image" :alt="workShop.Title" :title="workShop.Title">
                </div>
            </div>
            <div class="workshop card-content">
                <div class="category-full">
                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-primary-hover">{{ workShop.Category }}</p>
                    <p class="is-size-8 available" v-if="workShop.HasFreeEntries">Dostępnych miejsc: {{  workShop.PersonsCount -  workShop.EntriesNumber }}</p>
                    <p class="is-size-8 alert" v-else><AlertSymbol/> <span>Brak wolnych miejsc</span></p>
                </div>
                <div class="title-description">
                    <h2 class="is-size-3 is-size-2-tablet">{{ workShop.Title }}</h2>
                    <p class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc" v-text="descriptionLimited"></p>
                </div>
                <div class="bottom-info">
                    <div class="date-city-info">
                        <p v-if="dateArray" class="has-text-weight-semibold"><SmallCalendar /><span>{{ dateArray[2] }}.{{ dateArray[1] }}.{{ dateArray[0] }}</span></p>
                        <p class="has-text-weight-semibold"><PinIcon /> <span v-text="workShop.Localization" /></p>
                    </div>
                    <p class="is-size-8 has-text-left has-text-grey-light-hover">OCENA: <span class="is-size-6 has-text-left has-text-black-hover"> {{ workShop.Rate }}
                        <span class="has-text-grey-light-hover">/5</span></span><star-rating :star-size="14" :padding="3" :rating="workShop.Rate" :read-only="true" :increment="0.01" 
                                                                                             :show-rating="false" :active-color="'#f8d61c'" :inactive-color="'silver'" inline>
                             </star-rating></p>
                </div>
                <RightArrowSmallIcon class="right-arrow-small-icon" />
            </div>

        </div>
    </article>
   
</template>

<script>
    import StarRating from "vue-star-rating";
    import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
    import SmallCalendar from "@/assets/svg/small-calendar.svg";
    import PinIcon from "@/assets/svg/pin.svg";
    import AlertSymbol from "@/assets/svg/alert_symbol.svg";
    import global from "@/mixins/global.js";


export default {
        name: "BaseCardWorkshop",
        mixins: [global],
        components: {
        StarRating,
        RightArrowSmallIcon,
        SmallCalendar,
        PinIcon,
        AlertSymbol
    },
    props: ['workShop'],
    computed: {
        descriptionLimited() {
            if (this.workShop) {
                if (this.workShop.SubTitle.length > 180) {
                    return this.workShop.SubTitle.substring(0, 178) + '...'
                } else {
                    return this.workShop.SubTitle;
                }
            } else {
                return '';
            }
        },
        //parseDate() {
        //    let parseDate = new Date(this.date).toDateString('dd.mm.yyyy');
        //    return parseDate;
        //},
        dateArray() {
            return this.workShop.Date.split(" ");
        },

    },

        

};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

    .columns-wrap {
        display: block;
        @include desktop {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }

    .mov-prev-col {
        display: block;
        max-width: 100%;
        height: 200px;

        @include desktop {
            -ms-flex: 0 0 30%;
            flex: 0 0 30%;
            max-width: 30%;
            height: inherit;
        }

        .movie-screen {
        position: relative;
        height: 100%;
            img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-top-right-radius: 0px;
            -o-object-fit: cover;
            object-fit: cover;
            }
        }
    }

    .workshop.card-content {
        padding: 1.5rem 1.125rem 1.5rem 1.125rem;

        @include widescreen {
            padding: 1.5rem 1.125rem 0.875rem 1.9rem;
        }

        .title-description {
            padding-right: 3rem;
        }

        h2 {
                margin: 0.625rem 0 0.625rem;
            }

            .cat-name {
                text-transform: uppercase;
                margin-bottom: 1.7em;
                font-weight: 600;
                svg {
                margin-right: 10px;
                position: relative;
                top: 1px;
                }
            }
    .short-desc {
        margin-bottom: 1.5625rem;
    }
        .right-arrow-small-icon {
            margin-top: -10px;
        }
    }

    .bottom-info {

        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
        }

        .date-city-info
    {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        margin-bottom: 0.9375rem;

        @include tablet {
            margin-bottom: 0
        }

        p {
            svg {
                -webkit-transition: color .3s ease;
                -o-transition: color .3s ease;
                transition: color .3s ease;
                color: $green;
                margin-right: 8px;
                vertical-align: middle;
            }
            span {
                vertical-align: middle;
            }
        }
        p:first-child {
            margin-right: 25px;
        }
    }
}

.category-full {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.5625rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    @include tablet {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    

    p:first-child
    {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;

        @include tablet {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
    }

    .alert {
        color: $red;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 0.9375rem;

        @include tablet {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            margin-bottom: 0;
        }

        svg {
            vertical-align: middle;
            margin-right: 8px;
         
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .available {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;

        @include tablet {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
            order: 2;
            margin-bottom: 0;
        }
    }

}

.card {
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  cursor: pointer;
  color: $black;
  &.bottom-image {
    .card-image {
      order: 2;
      align-self: flex-end;
      img {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .card-content {
      order: 1;
      img {
        border-radius: 0;
        max-height: 40px;
      }
    }
  }
  .is-3by1 {
    padding-top: 39.3333%;
  }
  &-image,
  &-content {
    width: 100%;
    .media-content {
      & > div {
        padding-right: 70%;
      }
    }
  }
  &:hover,
  &:focus {
    background: $green-lighter;
    transition: background 0.3s ease;
    .card-content {
      h2,
      p {
        color: $white;
      }
      .right-arrow-small-icon {
        color: $white;
      }
      ul {
          color: $white;
      }
      svg {
          color: $white;
      }
      .alert {
          color: $red;
      }
    }
  }
}
</style>
