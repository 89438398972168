<template>
  <main>
    <BaseModalNav/>
    <Error/>
  </main>
</template>

<script>
import BaseModalNav from "@/components/BaseModalNav.vue";
import Error from "@/components/Containers/ViewError/Error.vue";

export default {
  name: "ViewError",
  components: {
    BaseModalNav,
    Error
  }
};
</script>
