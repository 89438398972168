/* eslint-disable */
<template>
	<article class="NameBox card">
		<div class="card-content is-paddingless">
			<div class="columns is-marginless">
				<!-- User name and surname -->
				<div class="NameBox__username column is-one-quarter">
					<PersonOvalIcon />
					<h1>
						<span class="NameBox__username-work-title">{{ workTitle }}</span>
						<br />
						<span class="NameBox__username-name">{{
							`${userFirstName} ${userLastName}`
						}}</span>
					</h1>
				</div>

				<!-- User data -->
				<dl class="NameBox__data column">
					<!-- First name -->
					<Field
						:value="
							userData.FirstName
								? userData.FirstName
								: editableFields.includes('FirstName')
								? 'podaj imię'
								: ''
						"
						:is-grayed-out="
							userData.FirstName
								? false
								: editableFields.includes('FirstName')
								? true
								: false
						"
						name="Imię"
						:is-editable="isFieldEditable('FirstName')"
						id="FirstName"
						ref="FirstName"
						:is-clickable="false"
					/>

					<!-- Last name -->
					<Field
						:value="
							userData.LastName
								? userData.LastName
								: editableFields.includes('LastName')
								? 'podaj nazwisko'
								: ''
						"
						:is-grayed-out="
							userData.LastName
								? false
								: editableFields.includes('LastName')
								? true
								: false
						"
						name="Nazwisko"
						:is-editable="isFieldEditable('LastName')"
						id="LastName"
						ref="LastName"
						:is-clickable="false"
					/>

					<!-- E-mail address -->
					<Field
						:value="
							userData.Email
								? userData.Email
								: editableFields.includes('Email')
								? 'podaj adres e-mail'
								: ''
						"
						:is-grayed-out="
							userData.Email
								? false
								: editableFields.includes('Email')
								? true
								: false
						"
						name="Adres e-mail"
						:is-editable="
							isFieldEditable('Email') && userData.Email.length === 0
						"
						id="Email"
						ref="Email"
						@click.native="$emit('email-address-click')"
						:is-clickable="
							userData.Email ? false : isDataEditable ? false : true
						"
					/>

					<!-- Phone number -->
					<Field
						:value="
							userData.Phone
								? userData.Phone
								: editableFields.includes('Phone')
								? 'podaj numer telefonu'
								: ''
						"
						:is-grayed-out="
							userData.Phone
								? false
								: editableFields.includes('Phone')
								? true
								: false
						"
						name="Numer telefonu"
						:is-editable="isFieldEditable('Phone')"
						id="Phone"
						ref="Phone"
						:is-clickable="false"
					/>

					<!-- Position -->
					<Field
						:value="get('WorkTitle')"
						name="Stanowisko"
						:is-editable="isFieldEditable('WorkTitle')"
						id="WorkTitle"
					/>

					<!-- Status -->
					<Field
						:value="get('Status')"
						name="Status"
						:is-editable="isFieldEditable('Status')"
						id="Status"
					/>

					<!-- Branch -->
					<Field
						:value="get('Network')"
						name="Sieć"
						:is-editable="isFieldEditable('Network')"
						id="Network"
					/>

					<!-- Shop code -->
					<Field
						v-if="shouldShowShopDetails"
						:value="get('ShopNumber')"
						name="Kod sklepu"
						:is-editable="isFieldEditable('ShopNumber')"
						id="ShopNumber"
					/>

					<!-- Shop sddress -->
					<Field
						v-if="shouldShowShopDetails"
						:value="get('ShopName')"
						name="Adres sklepu"
						:is-editable="isFieldEditable('ShopName')"
						id="ShopName"
					/>

					<!-- Supervisor -->
					<Field
						v-if="
							!isUser([
								'ShopManager',
								'ShopOwner',
								'TrainerUser',
								'NetworkAdminUser',
								'PartnerAdminUser',
								'KOUser',
								'RDOUser',
								'CAUser',
							])
						"
						:value="supervisor"
						name="Przełożony"
						:is-editable="isFieldEditable('Supervisor')"
						id="Supervisor"
					/>

					<!-- ID number -->
					<Field
						v-if="
							!isUser([
								'NetworkAdminUser',
								'TrainerUser',
								'PartnerAdminUser',
								'KOUser',
								'RDOUser',
								'CAUser',
							])
						"
						:value="get('Id')"
						name="Numer ID pracownika"
						:is-editable="isFieldEditable('Id')"
						id="Id"
					/>
					<Field v-if="userData.Network == 'Detal+' ||
						   userData.Network == 'Eurocash' ||
						   userData.Network == 'CashAndCarry'"
						   :value="get('SapNo')" name="Sap" id="Sap" />
				</dl>
			</div>
		</div>
	</article>
</template>

<script>
import PersonOvalIcon from '@/assets/svg/person-oval.svg';
import Field from '@/components/Containers/ViewUserData/components/Field.vue';

export default {
	components: {
		PersonOvalIcon,
		Field,
	},

	props: {
		userData: {
			type: Object,
			required: true,
		},
		isDataEditable: {
			type: Boolean,
			required: false,
			default: false,
		},
		editableFields: {
			type: Array,
			required: false,
			default: () => [],
		},
	},

	computed: {
		userFirstName() {
			return this.userData.FirstName;
		},

		userLastName() {
			return this.userData.LastName;
		},

		workTitle() {
			return this.userData.WorkTitle;
		},

		supervisor() {
			return this.userData.SupervisorFirstName
				? `${this.userData.SupervisorFirstName} ${this.userData.SupervisorLastName}`
				: '';
		},

		shouldShowShopDetails() {
			const isRegularWorker = !this.isUser([
				'ShopOwner',
				'ShopManager',
				'NetworkAdminUser',
				'TrainerUser',
				'PartnerAdminUser',
				'KOUser',
				'RDOUser',
				'CAUser',
			]);

			const isShopOwnerOrManager = this.isUser(['ShopOwner', 'ShopManager']);

			const isOperations = this.isUser([
				'NetworkAdminUser',
				'TrainerUser',
				'PartnerAdminUser',
				'KOUser',
				'RDOUser',
				'CAUser',
			]);

			if (isRegularWorker) {
				return true;
			} else if (isShopOwnerOrManager) {
				return this.userData.ShopNumber ? true : false;
			} else if (isOperations) {
				return false;
			} else {
				return false;
			}
		},
	},

	methods: {
		/**
		 * @description This method will tell you whether the field should be printed
		 * or not. This depends on whether the passed-in field exists in the {userData} object.
		 * @param {String} field The field name which presence
		 * within the {userData} object should be checked.
		 * @returns {Boolean}
		 */
		shouldShow(field) {
			return this.userData[field] !== undefined;
		},

		/**
		 * @description This method will tell you if the user is part of a given users group.
		 * @param {String|Array} roles The role(s) to check the user against.
		 * @returns {Boolean}
		 */
		isUser(roles) {
			if (this.userData.Roles) {
				if (typeof roles === 'string') {
					return this.userData.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.userData.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},

		/**
		 * @description This method will return the string value of the passed-in field from
		 * the {userData} object. If it will encounter a 'null', the empty string will be returned.
		 * @param {String} field The field name which value is to be returned.
		 * @returns {String|Number}
		 */
		get(field) {
			return this.userData[field] ?? '';
		},

		/**
		 * @description This method will return the boolean value indicating whether
		 * the field should be in the editable mode (have [contenteditable] atribute).
		 * @param {String} fieldName Name of the field to check.
		 * @returns {Boolean}
		 */
		isFieldEditable(fieldName) {
			return this.isDataEditable && this.editableFields.includes(fieldName);
		},

		revertChanges() {
			this.$refs.FirstName.refresh();
			this.$refs.LastName.refresh();
			this.$refs.Email.refresh();
			this.$refs.Phone.refresh();
		},

		refresh() {
			this.revertChanges();
		},
	},

	watch: {
		userData: {
			handler() {
				setTimeout(() => {
					this.$refs.FirstName.refresh();
					this.$refs.LastName.refresh();
					this.$refs.Email.refresh();
					this.$refs.Phone.refresh();
				}, 1);
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/_variables.scss';

.NameBox {
	border-radius: 4px;
	margin-bottom: calc(1.875rem + 24px);

	&__username {
		background-color: $green-lighter;
		padding: 30px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		h1 {
			color: white;
			font-size: 30px;
			font-weight: $weight-light;
			letter-spacing: 0;
			text-align: center;
		}

		@media (min-width: 920px) {
			border-top-left-radius: 4px;
			border-top-right-radius: 0;
			border-bottom-left-radius: 4px;
			align-items: flex-start;
			justify-content: space-around;

			h1 {
				text-align: left;
			}
		}
	}

	&__data {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: white;
		padding: 30px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-row-gap: 15px;
		// For larger screens.
		@media (min-width: 480px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-column-gap: 70px;
		}
	}
}
</style>
