<template>
  <div class="dropdown is-right" :class="{ 'is-active': isActive }">
    <!-- Dropdown trigger -->
    <div class="dropdown-trigger">
      <button
        class="button is-size-7 is-info is-fullwidth is-uppercase has-text-weight-bold is-outlined is-outlined-bold"
        aria-haspopup="true"
        aria-controls="ActionsDropdown-content"
        @click="isActive = !isActive"
      >
        <span>Akcje</span>
      </button>
    </div>
    <!-- END | Dropdown trigger -->

    <!-- Dropdown content -->
    <div class="dropdown-menu" id="ActionsDropdown-content" role="menu">
      <div class="dropdown-content is-paddingless">
        <button
          class="dropdown-item button is-fullwidth"
          @click="isActive = !isActive; $emit('edit-data');"
        >Edytuj dane</button>

        <button
          class="dropdown-item button is-fullwidth"
          @click="isActive = !isActive; $emit('change-password');"
        >Zmień hasło</button>

        <button
          class="dropdown-item button is-fullwidth"
          @click="isActive = !isActive; $emit('send-activation-link');"
        >Wyślij link aktywacyjny</button>

        <button
          class="dropdown-item button is-fullwidth"
          @click="isActive = !isActive; $emit('delete-user');"
        >Usuń użytkownika</button>
      </div>
    </div>
    <!-- END | Dropdown content -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";

.dropdown {
  width: 100%;

  &-trigger {
    width: 100%;
  }

  &-content {
    background: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &-menu {
    min-width: unset;
    width: 100%;
    padding-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &-item {
    text-transform: uppercase;
    font-weight: $weight-bold;
    color: #8e8e8e;
    transition: all 100ms ease-in-out;

    border: 2px solid $info;
    &:not(:last-child) {
      border-bottom: none;
    }

    border-radius: 0;
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background-color: $info;
      color: white;
      border-color: $info;
    }
  }

  &.is-active {
    .dropdown {
      &-trigger {
        button {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
</style>