<template>
    <section class="homepage-user section" v-if="userData">
        <div class="container">
            <div v-if="UserWelcome" class="columns is-gapless homepage-user-welcome">
                <div class="column is-one-third">
                    <div>
                        <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-white">
                            Witaj ponownie
                        </p>
                        <h2 class="is-size-3 is-size-2-tablet has-text-left has-text-weight-light has-text-white">
                            {{ userData.UserName }}
                        </h2>
                    </div>
                </div>
                <div class="column">
                    <div>
                        <h2 class="is-size-3 is-size-2-tablet has-text-left has-text-weight-light"
                            v-html="userData.Message.Title"></h2>
                    </div>
                </div>
                <button type="button"
                        class="modal-close is-large"
                        @click="UserWelcome = false"></button>
            </div>

        </div>
        <b-modal :active.sync="formData.IsNewRegulationPopupToShow"
                 :canCancel="[]"
                 class="modal-form modal-dialog rodo-else-pop"
                 :width="830"
                 scroll="keep">
            <br />
            <br />
            <p>
                Dzień dobry,
                <br />informujemy, że wprowadziliśmy istotne zmiany w Regulaminie
                Platformy Edukacyjnej Akademii Umiejętności Eurocash. Zapoznaj się z
                Regulaminem
                <a href="/regulamin/" target="_blank">TUTAJ>></a>
            </p>
            <p>
                Partnerzy Akademii Umiejętności chcieliby informować Cię o atrakcyjnych
                konkursach i promocjach, które organizują. Jeśli wyrażasz zgodę na
                otrzymywanie informacji handlowych naszych partnerów - zaznacz
                odpowiednią zgodę w zakładce Moje dane
                <a v-on:click.stop.prevent="goToMyAccountMyData"
                   href="#"
                   target="_blank">TUTAJ>></a>
            </p>
            <button class="is-size-7-em has-text-weight-semibold is-uppercase"
                    @click="newRegulationPopup">
                Zrozumiałem/-łam i przechodzę dalej
            </button>
        </b-modal>
        <b-modal :active.sync="formData.IsAgreementPopupToShow"
                 class="modal-form modal-dialog"
                 :width="830"
                 :canCancel="[]"
                 scroll="keep">
            <div class="columns is-paddingless is-marginless">
                <div class="column is-full">
                    <form @submit.prevent="validateBeforeSubmit">
                        <div class="input_wraps">
                            <h2 class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow">
                                Uzupełnij dane konta
                            </h2>
                            <p class="is-size-8 is-size-6-tablet has-text-left has-text-primary">
                                Pomoże nam to dopasować system do Twoich potrzeb
                            </p>
                            <div class="field">
                                <div class="control" :class="{ disabled: formData.FirstName }">
                                    <FloatingLabel :config="{
											label: 'Imię',
											height: 56,
											hasContent: formData.FirstName ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.FirstName.$error,
										}">
                                        <input :disabled="formData.FirstName"
                                               v-model="formData.FirstName"
                                               @blur="$v.formData.FirstName.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.FirstName
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control" :class="{ disabled: formData.LastName }">
                                    <FloatingLabel :config="{
											label: 'Nazwisko',
											height: 56,
											hasContent: formData.LastName ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.LastName.$error,
										}">
                                        <input :disabled="formData.LastName"
                                               v-model="formData.LastName"
                                               @blur="$v.formData.LastName.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.LastName
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control"
                                     :class="{ disabled: formData.WorkPosition }">
                                    <FloatingLabel :config="{
											label: 'Stanowisko',
											height: 56,
											hasContent: formData.WorkPosition ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.WorkPosition.$error,
										}">
                                        <input :disabled="formData.WorkPosition"
                                               v-model="formData.WorkPosition"
                                               @blur="$v.formData.WorkPosition.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.WorkPosition
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control" :class="{ disabled: formData.Network }">
                                    <FloatingLabel :config="{
											label: 'Sieć',
											height: 56,
											hasContent: formData.Network ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.Network.$error,
										}">
                                        <input :disabled="formData.Network"
                                               v-model="formData.Network"
                                               @blur="$v.formData.Network.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.Network
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <FloatingLabel :config="{
											label: 'Adres e-mail',
											height: 56,
											hasContent: formData.Email ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.Email.$error,
										}">
                                        <input v-model="formData.Email"
                                               @blur="$v.formData.Email.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.Email
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <FloatingLabel :config="{
											label: 'Powtórz adres e-mail',
											height: 56,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.ConfirmEmail.$error,
										}">
                                        <input v-model="formData.ConfirmEmail"
                                               @blur="$v.formData.ConfirmEmail.$touch()"
                                               type="text"
                                               class="input"
                                               required />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.ConfirmEmail
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-if="WrongEmail">
                                        Adres e-mail różni się od podanego wyżej
                                    </p>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control">
                                    <FloatingLabel :config="{
											label: 'Telefon',
											height: 56,
											hasContent: formData.Phone ? true : false,
											hasClearButton: false,
											line: false,
											color: {
												focusColor: '#3AAB59',
												errorColor: '#de2647',
												lineColor: '#b5b5b5',
												blurredColor: '#b5b5b5',
											},
											hasError: this.$v.formData.Phone.$error,
										}">
                                        <input v-model="formData.Phone"
                                               @blur="$v.formData.Phone.$touch()"
                                               type="text"
                                               class="input" />
                                    </FloatingLabel>
                                    <p class="is-size-8 has-text-weight-light has-text-danger"
                                       v-for="(error, index) in validationMessages(
											$v.formData.Phone
										)"
                                       :key="index">
                                        {{ error }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="rodo-wrap">
                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-if="formData.RegulationWorkPosition === 'Partner'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności
                                    Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów
                                    edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim
                                    zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy
                                    Edukacyjnej i prosimy o zapoznanie się z informacjami na temat
                                    przetwarzania Twoich danych osobowych:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulamin/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Zapoznaj się z pozostałymi informacjami na temat przetwarzania
                                    Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail:
                                    eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000
                                    lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła
                                    Inspektora Ochrony Danych, z którym Pani / Pan może
                                    skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod
                                    numerem telefonicznym (0-61) 3322277 lub na adres siedziby
                                    administratora. Z inspektorem ochrony danych można się
                                    kontaktować we wszystkich sprawach dotyczących przetwarzania
                                    danych osobowych oraz korzystania z praw związanych z
                                    przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z
                                        Platformy Edukacyjnej - podstawą prawną przetwarzania danych
                                        jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami – podstawą
                                        prawną przetwarzania danych jest uzasadniony interes
                                        administratora polegający na możliwości ochrony interesów
                                        Administratora w postępowaniu sądowym lub innym.
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom
                                    przetwarzającym dane osobowe na zlecenie Akademii Umiejętności
                                    sp. z o.o., w szczególności dostawcom IT oraz spółkom z Grupy
                                    Kapitałowej Eurocash. Ponadto, Pani / Pana dane osobowe oraz
                                    dane dotyczące Pani/Pana aktywności na platformie mogą być
                                    udostępnione Pani/Pana pracodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres
                                    korzystania przez Panią / Pana z usług Platformy Edukacyjnej
                                    lub przez okres trwania prawnie uzasadnionego interesu
                                    Administratora istniejącego do czasu cofnięcia zgody na
                                    otrzymywanie informacji marketingowych. Okres przechowywania
                                    danych osobowych może zostać każdorazowo przedłużony o okres
                                    przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych
                                    będzie niezbędne dla dochodzenia ewentualnych roszczeń lub
                                    obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych
                                    oraz prawo żądania ich sprostowania, ich usunięcia lub
                                    ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób
                                    zautomatyzowany na podstawie umowy – przysługuje Pani / Panu
                                    także prawo do przenoszenia danych osobowych, które
                                    dostarczyła Pani / dostarczył Pan administratorowi tj. do
                                    otrzymania od administratora Pani/Pana danych osobowych, w
                                    ustrukturyzowanym, powszechnie używanym formacie nadającym się
                                    do odczytu maszynowego. Może Pani / Pan przesłać te dane
                                    innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                                    osobowych jest przesłanka prawnie uzasadnionego interesu
                                    administratora, przysługuje Pani / Panu prawo wniesienia
                                    sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj.
                                    przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania
                                    danych na potrzeby dochodzenia i ochrony przed roszczeniami
                                    oraz w celu komunikacji marketingowej, w zakresie, w jakim
                                    podstawą przetwarzania jest udzielona zgoda – przysługuje Pani
                                    / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na
                                    zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do
                                    organu nadzorczego zajmującego się ochroną danych osobowych w
                                    państwie członkowskim Pani / Pana zwykłego pobytu, miejsca
                                    pracy lub miejsca popełnienia domniemanego naruszenia. W
                                    Polsce organem tym jest Prezes Urzędu Ochrony Danych
                                    Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania
                                    korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                            </div>









                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.Network === 'Niezrzeszeni' && formData.RegulationWorkPosition==='Owner'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów edukacyjnych, jakie dla Ciebie przygotowaliśmy.
                                    Jednak zanim zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy Edukacyjnej i prosimy o zapoznanie się z informacjami na temat przetwarzania Twoich danych osobowych
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span type="is-info"
                                          size="is-small"
                                          class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii Umiejętności Eurocash,
                                        <a href="/regulaminniezrzeszeni/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Masz możliwość przekazania dostępu do platformy edukacyjnej Akademia Umiejętności Eurocash swoim pracownikom.
                                    Pamiętaj jednak, że zanim założysz konta swoim pracownikom, powinieneś najpierw odebrać od nich zgodę na przekazanie danych do Akademii Umiejętności.
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsAccountAgreementDefinition" />
                                    <span type="is-info"
                                          size="is-small"
                                          class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        <span class="req-check">*</span>Oświadczam, że będę definiował/a konta dla moich pracowników na Platformie Edukacyjnej Akademii Umiejętności Eurocash
                                        wyłącznie wtedy, kiedy uzyskałem/uzyskałam od nich zgody na przekazanie danych do Akademii Umiejętności Eurocash Sp. z o.o.
                                        W celu uzyskania zgody od pracowników skorzystaj z naszego wzoru
                                        <a href="/assets/pdf/zgoda_pracownika.pdf" target="_blank">TUTAJ>></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać informacje o aktualnej ofercie Akademii,
                                    to prosimy kliknij w poniższą zgodę:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności Eurocash Sp. z o.o.
                                    informacji o najnowszych ofertach interesujących promocjach oraz konkursach organizowanych przez Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS.</span>
                                </label>
                                <p>
                                    Niezaznaczenie powyższej opcji oznacza, że nie będziesz na bieżąco z naszymi najnowszymi ofertami handlowymi.
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez przesłanie nam wiadomości na adres e-mail na adres
                                    [kontakt@akademiaeurocash.com.pl]
                                    lub poprzez przycisk Wypisz się>> w otrzymanej od nas informacji o najnowszych ofertach, interesujących promocjach oraz konkursach. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Prosimy również, zapoznaj się z pozostałymi informacjami na
                                    temat przetwarzania Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail: eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000 lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła Inspektora Ochrony Danych, z którym Pani / Pan może skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod numerem telefonicznym (0-61) 3322277 lub na adres siedziby administratora. Z inspektorem ochrony danych można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z Platformy Edukacyjnej – podstawą prawną przetwarzania danych jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami - podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora polegający na możliwości ochrony interesów Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej Akademii Umiejętności Eurocash - podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora, polegający na marketingu bezpośrednim produktów i usług oferowanych przez Administratora
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w szczególności dostawcom IT.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres korzystania przez Panią / Pana z usług Platformy Edukacyjnej lub przez okres trwania prawnie uzasadnionego interesu Administratora istniejącego do czasu cofnięcia zgody na otrzymywanie informacji marketingowych. Okres przechowywania danych osobowych może zostać każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie niezbędne dla dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych oraz prawo żądania ich sprostowania, ich usunięcia lub ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób zautomatyzowany na podstawie umowy – przysługuje Pani / Panu także prawo do przenoszenia danych osobowych, które dostarczyła Pani / dostarczył Pan administratorowi tj. do otrzymania od administratora Pani/Pana danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Może Pani / Pan przesłać te dane innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych osobowych jest przesłanka prawnie uzasadnionego interesu administratora, przysługuje Pani / Panu prawo wniesienia sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj. przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania danych na potrzeby dochodzenia i ochrony przed roszczeniami oraz w celu komunikacji marketingowej. W zakresie, w jakim podstawą przetwarzania jest udzielona zgoda – przysługuje Pani / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w państwie członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia. W Polsce organem tym jest Prezes Urzędu Ochrony Danych Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>
                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.Network === 'Niezrzeszeni' &&  formData.RegulationWorkPosition === 'ShopManager'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności
                                    Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy Edukacyjnej i prosimy o zapoznanie się z informacjami na temat przetwarzania Twoich danych osobowych:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulaminniezrzeszeni/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Jednocześnie informujemy, że z poziomu swojego konta masz możliwość definiowania kont dla swoich współpracowników pod warunkiem uzyskania przez Twojego pracodawcę zgód od tych osób na przekazanie danych do Akademii Umiejętności Eurocash Sp. z o.o. – przed podaniem danych osobowych współpracowników upewnij się zatem, czy Twój Pracodawca pozyskał takie zgody. Jeżeli Twój Pracodawca nie dysponuje takimi zgodami, nie możesz podawać danych swoich współpracowników celem definiowania dla nich kont.
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsAccountAgreementDefinition" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Oświadczam, że będę definiował/a konta dla moich współpracowników na Platformie Edukacyjnej Akademii Umiejętności Eurocash wyłącznie wtedy, kiedy mój pracodawca uzyska od nich zgody na przekazanie danych do Akademii Umiejętności Eurocash Sp. z o.o. - zobacz wzór zgody
                                        <a href="/assets/pdf/zgoda_pracownika.pdf" target="_blank">TUTAJ>></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać informacje o aktualnej ofercie Akademii, to prosimy kliknij w poniższą zgodę:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności Eurocash Sp. z o.o. informacji o najnowszych ofertach interesujących promocjach oraz konkursach organizowanych przez Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS.</span>
                                </label>

                                <p>
                                    Niezaznaczenie powyższej opcji oznacza, że nie będziesz na bieżąco z naszymi najnowszymi ofertami handlowymi.
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez przesłanie nam wiadomości na adres e-mail na adres [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz się>> w otrzymanej od nas informacji o najnowszych ofertach, interesujących promocjach oraz konkursach. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Zapoznaj się z pozostałymi informacjami na temat przetwarzania Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail: eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000 lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła Inspektora Ochrony Danych, z którym Pani / Pan może skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod numerem telefonicznym (0-61) 3322277 lub na adres siedziby administratora. Z inspektorem ochrony danych można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z Platformy Edukacyjnej - podstawą prawną przetwarzania danych jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami – podstawą prawną przetwarzania danych jest uzasadniony interes administratora polegający na możliwości ochrony interesów Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej Akademii Umiejętności Eurocash - podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora, polegający na marketingu bezpośrednim produktów i usług oferowanych przez Administratora
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w szczególności dostawcom IT. Ponadto, Pani / Pana dane osobowe oraz dane dotyczące Pani/Pana aktywności na platformie mogą być udostępnione Pani/Pana Pracodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres korzystania przez Panią / Pana z usług Platformy Edukacyjnej lub przez okres trwania prawnie uzasadnionego interesu Administratora istniejącego do czasu cofnięcia zgody na otrzymywanie informacji marketingowych. Okres przechowywania danych osobowych może zostać każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie niezbędne dla dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych oraz prawo żądania ich sprostowania, ich usunięcia lub ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób zautomatyzowany na podstawie umowy – przysługuje Pani / Panu także prawo do przenoszenia danych osobowych, które dostarczyła Pani / dostarczył Pan administratorowi tj. do otrzymania od administratora Pani/Pana danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Może Pani / Pan przesłać te dane innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych osobowych jest przesłanka prawnie uzasadnionego interesu administratora, przysługuje Pani / Panu prawo wniesienia sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj. przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania danych na potrzeby dochodzenia i ochrony przed roszczeniami oraz w celu komunikacji marketingowej, w zakresie, w jakim podstawą przetwarzania jest udzielona zgoda – przysługuje Pani / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w państwie członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia. W Polsce organem tym jest Prezes Urzędu Ochrony Danych Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>

                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.Network === 'Niezrzeszeni' && formData.RegulationWorkPosition === 'Worker'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy Edukacyjnej i prosimy o zapoznanie się z informacjami na temat przetwarzania Twoich danych osobowych:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulaminniezrzeszeni/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać informacje o aktualnej ofercie Akademii, to prosimy kliknij w poniższą zgodę:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności Eurocash Sp. z o.o. informacji o najnowszych ofertach interesujących promocjach oraz konkursach organizowanych przez Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS</span>
                                </label>
                                <p>
                                    Niezaznaczenie powyższej opcji oznacza, że nie będziesz na bieżąco z naszymi najnowszymi ofertami.
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez przesłanie nam wiadomości na adres e-mail na adres [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz się>> w otrzymanej od nas informacji o najnowszych ofertach, interesujących promocjach oraz konkursach. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Zapoznaj się z pozostałymi informacjami na temat przetwarzania Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail: eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000 lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła Inspektora Ochrony Danych, z którym Pani / Pan może skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod numerem telefonicznym (0-61) 3322277 lub na adres siedziby administratora. Z inspektorem ochrony danych można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z Platformy Edukacyjnej - podstawą prawną przetwarzania danych jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami – podstawą prawną przetwarzania danych jest uzasadniony interes administratora polegający na możliwości ochrony interesów Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej Akademii Umiejętności Eurocash - podstawą prawną przetwarzania danych jest prawnie uzasadniony interes Administratora, polegający na marketingu bezpośrednim produktów i usług oferowanych przez Administratora
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom przetwarzającym dane osobowe na zlecenie Akademii Umiejętności sp. z o.o., w szczególności dostawcom IT. Ponadto, Pani / Pana dane osobowe oraz dane dotyczące Pani/Pana aktywności na platformie mogą być udostępnione Pani/Pana Pracodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres korzystania przez Panią / Pana z usług Platformy Edukacyjnej lub przez okres trwania prawnie uzasadnionego interesu Administratora istniejącego do czasu cofnięcia zgody na otrzymywanie informacji marketingowych. Okres przechowywania danych osobowych może zostać każdorazowo przedłużony o okres przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych będzie niezbędne dla dochodzenia ewentualnych roszczeń lub obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych oraz prawo żądania ich sprostowania, ich usunięcia lub ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób zautomatyzowany na podstawie umowy – przysługuje Pani / Panu także prawo do przenoszenia danych osobowych, które dostarczyła Pani / dostarczył Pan administratorowi tj. do otrzymania od administratora Pani/Pana danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Może Pani / Pan przesłać te dane innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych osobowych jest przesłanka prawnie uzasadnionego interesu administratora, przysługuje Pani / Panu prawo wniesienia sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj. przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania danych na potrzeby dochodzenia i ochrony przed roszczeniami oraz w celu komunikacji marketingowej, w zakresie, w jakim podstawą przetwarzania jest udzielona zgoda – przysługuje Pani / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych w państwie członkowskim Pani / Pana zwykłego pobytu, miejsca pracy lub miejsca popełnienia domniemanego naruszenia. W Polsce organem tym jest Prezes Urzędu Ochrony Danych Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>




                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.RegulationWorkPosition === 'Worker'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności
                                    Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów
                                    edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim
                                    zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy
                                    Edukacyjnej i prosimy o zapoznanie się z informacjami na temat
                                    przetwarzania Twoich danych osobowych:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulamin/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać
                                    informacje o aktualnej ofercie Akademii, o promocjach i
                                    konkursach organizowanych przez nas, spółki z Grupy Eurocash i
                                    naszych Partnerów (tj. podmiotów współpracujących z Akademią
                                    Umiejętności Eurocash Sp. z o.o., lista aktualnych Partnerów
                                    dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco), to prosimy kliknij w poniższe
                                    zgody:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    spółki z Grupy Eurocash – których aktualna lista dostępna jest
                                    <a href="https://grupaeurocash.pl/public/assets/media/eurocash_spolki.pdf"
                                       target="_blank">TUTAJ>></a>
                                    – lista ta jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashGroupGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Partnerów Akademii, których aktualna lista dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashPartnersGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Niezaznaczenie którejkolwiek z powyższych opcji oznacza, że
                                    nie będziesz na bieżąco z naszymi najnowszymi ofertami
                                    handlowymi, jak również z ofertami handlowymi spółek z Grupy
                                    Eurocash oraz z ofertami Partnerów Akademii, które mogą okazać
                                    się dla Ciebie bardzo korzystne
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć
                                    każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez
                                    przesłanie nam wiadomości na adres e-mail na adres
                                    [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz
                                    się>> w otrzymanej od nas informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach. Wycofanie zgody nie
                                    wpływa na zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Zapoznaj się z pozostałymi informacjami na temat przetwarzania
                                    Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail:
                                    eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000
                                    lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła
                                    Inspektora Ochrony Danych, z którym Pani / Pan może
                                    skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod
                                    numerem telefonicznym (0-61) 3322277 lub na adres siedziby
                                    administratora. Z inspektorem ochrony danych można się
                                    kontaktować we wszystkich sprawach dotyczących przetwarzania
                                    danych osobowych oraz korzystania z praw związanych z
                                    przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z
                                        Platformy Edukacyjnej - podstawą prawną przetwarzania danych
                                        jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami – podstawą
                                        prawną przetwarzania danych jest uzasadniony interes
                                        administratora polegający na możliwości ochrony interesów
                                        Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej – podstawą prawną
                                        przetwarzania danych jest uzasadniony interes administratora
                                        polegający na marketingu bezpośredni produktów i usług
                                        oferowanych przez Administratora oraz podmioty trzecie w
                                        związku z wyrażoną zgodą na otrzymywanie informacji
                                        marketingowych wybranym kanałem komunikacji.
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom
                                    przetwarzającym dane osobowe na zlecenie Akademii Umiejętności
                                    sp. z o.o., w szczególności dostawcom IT oraz spółkom z Grupy
                                    Kapitałowej Eurocash. Ponadto, Pani / Pana dane osobowe oraz
                                    dane dotyczące Pani/Pana aktywności na platformie mogą być
                                    udostępnione Pani/Pana Pracodawcy i jego Franczyzodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres
                                    korzystania przez Panią / Pana z usług Platformy Edukacyjnej
                                    lub przez okres trwania prawnie uzasadnionego interesu
                                    Administratora istniejącego do czasu cofnięcia zgody na
                                    otrzymywanie informacji marketingowych. Okres przechowywania
                                    danych osobowych może zostać każdorazowo przedłużony o okres
                                    przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych
                                    będzie niezbędne dla dochodzenia ewentualnych roszczeń lub
                                    obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych
                                    oraz prawo żądania ich sprostowania, ich usunięcia lub
                                    ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób
                                    zautomatyzowany na podstawie umowy – przysługuje Pani / Panu
                                    także prawo do przenoszenia danych osobowych, które
                                    dostarczyła Pani / dostarczył Pan administratorowi tj. do
                                    otrzymania od administratora Pani/Pana danych osobowych, w
                                    ustrukturyzowanym, powszechnie używanym formacie nadającym się
                                    do odczytu maszynowego. Może Pani / Pan przesłać te dane
                                    innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                                    osobowych jest przesłanka prawnie uzasadnionego interesu
                                    administratora, przysługuje Pani / Panu prawo wniesienia
                                    sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj.
                                    przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania
                                    danych na potrzeby dochodzenia i ochrony przed roszczeniami
                                    oraz w celu komunikacji marketingowej, w zakresie, w jakim
                                    podstawą przetwarzania jest udzielona zgoda – przysługuje Pani
                                    / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na
                                    zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do
                                    organu nadzorczego zajmującego się ochroną danych osobowych w
                                    państwie członkowskim Pani / Pana zwykłego pobytu, miejsca
                                    pracy lub miejsca popełnienia domniemanego naruszenia. W
                                    Polsce organem tym jest Prezes Urzędu Ochrony Danych
                                    Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania
                                    korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>
                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.RegulationWorkPosition === 'Owner'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności
                                    Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów
                                    edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim
                                    zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy
                                    Edukacyjnej i prosimy o zapoznanie się z informacjami na temat
                                    przetwarzania Twoich danych osobowych
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span type="is-info"
                                          size="is-small"
                                          class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulamin/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Masz możliwość przekazania dostępu do platformy edukacyjnej
                                    Akademia Umiejętności Eurocash swoim pracownikom. Pamiętaj
                                    jednak, że zanim założysz konta swoim pracownikom, powinieneś
                                    najpierw odebrać od nich zgodę na przekazanie danych do
                                    Akademii Umiejętności.
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsAccountAgreementDefinition" />
                                    <span type="is-info"
                                          size="is-small"
                                          class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        <span class="req-check">*</span>Oświadczam, że będę
                                        definiował/a konta dla moich pracowników na Platformie
                                        Edukacyjnej Akademii Umiejętności Eurocash wyłącznie wtedy,
                                        kiedy uzyskałem/uzyskałam od nich zgody na przekazanie
                                        danych do Akademii Umiejętności Eurocash Sp. z o.o. W celu
                                        uzyskania zgody od pracowników skorzystaj z naszego wzoru
                                        <a href="/assets/pdf/zgoda_pracownika.pdf" target="_blank">TUTAJ>></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać
                                    informacje o aktualnej ofercie Akademii, o promocjach i
                                    konkursach organizowanych przez nas, spółki z Grupy Eurocash i
                                    naszych Partnerów (tj. podmiotów współpracujących z Akademią
                                    Umiejętności Eurocash Sp. z o.o., lista aktualnych Partnerów
                                    dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco), to prosimy kliknij w poniższe
                                    zgody:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS.</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    spółki z Grupy Eurocash – których aktualna lista dostępna jest
                                    <a href="https://grupaeurocash.pl/public/assets/media/eurocash_spolki.pdf"
                                       target="_blank">TUTAJ>></a>
                                    – lista ta jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashGroupGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Partnerów Akademii, których aktualna lista dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashPartnersGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Niezaznaczenie którejkolwiek z powyższych opcji oznacza, że
                                    nie będziesz na bieżąco z naszymi najnowszymi ofertami
                                    handlowymi, jak również z ofertami handlowymi spółek z Grupy
                                    Eurocash oraz z ofertami Partnerów Akademii, które mogą okazać
                                    się dla Ciebie bardzo korzystne
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć
                                    każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez
                                    przesłanie nam wiadomości na adres e-mail na adres
                                    [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz
                                    się>> w otrzymanej od nas informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach. Wycofanie zgody nie
                                    wpływa na zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Prosimy również, zapoznaj się z pozostałymi informacjami na
                                    temat przetwarzania Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail:
                                    eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000
                                    lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła
                                    Inspektora Ochrony Danych, z którym Pani / Pan może
                                    skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod
                                    numerem telefonicznym (0-61) 3322277 lub na adres siedziby
                                    administratora. Z inspektorem ochrony danych można się
                                    kontaktować we wszystkich sprawach dotyczących przetwarzania
                                    danych osobowych oraz korzystania z praw związanych z
                                    przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z
                                        Platformy Edukacyjnej – podstawą prawną przetwarzania danych
                                        jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami - podstawą
                                        prawną przetwarzania danych jest prawnie uzasadniony interes
                                        Administratora polegający na możliwości ochrony interesów
                                        Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej Akademii Umiejętności
                                        Eurocash oraz podmiotów trzecich (spółek z Grupy Eurocash
                                        oraz Partnerów Akademii) - podstawą prawną przetwarzania
                                        danych jest prawnie uzasadniony interes Administratora,
                                        polegający na marketingu bezpośrednim produktów i usług
                                        oferowanych przez Administratora oraz podmioty trzecie w
                                        związku z wyrażoną zgodą na otrzymywanie informacji
                                        marketingowych wybranym kanałem komunikacji.
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom
                                    przetwarzającym dane osobowe na zlecenie Akademii Umiejętności
                                    sp. z o.o., w szczególności dostawcom IT oraz spółkom z Grupy
                                    Kapitałowej Eurocash. Ponadto, Pani / Pana dane osobowe oraz
                                    dane dotyczące Pani/Pana aktywności na platformie mogą być
                                    udostępnione Pani/Pana Franczyzodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres
                                    korzystania przez Panią / Pana z usług Platformy Edukacyjnej
                                    lub przez okres trwania prawnie uzasadnionego interesu
                                    Administratora istniejącego do czasu cofnięcia zgody na
                                    otrzymywanie informacji marketingowych. Okres przechowywania
                                    danych osobowych może zostać każdorazowo przedłużony o okres
                                    przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych
                                    będzie niezbędne dla dochodzenia ewentualnych roszczeń lub
                                    obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych
                                    oraz prawo żądania ich sprostowania, ich usunięcia lub
                                    ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób
                                    zautomatyzowany na podstawie umowy – przysługuje Pani / Panu
                                    także prawo do przenoszenia danych osobowych, które
                                    dostarczyła Pani / dostarczył Pan administratorowi tj. do
                                    otrzymania od administratora Pani/Pana danych osobowych, w
                                    ustrukturyzowanym, powszechnie używanym formacie nadającym się
                                    do odczytu maszynowego. Może Pani / Pan przesłać te dane
                                    innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                                    osobowych jest przesłanka prawnie uzasadnionego interesu
                                    administratora, przysługuje Pani / Panu prawo wniesienia
                                    sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj.
                                    przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania
                                    danych na potrzeby dochodzenia i ochrony przed roszczeniami
                                    oraz w celu komunikacji marketingowej. W zakresie, w jakim
                                    podstawą przetwarzania jest udzielona zgoda – przysługuje Pani
                                    / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na
                                    zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do
                                    organu nadzorczego zajmującego się ochroną danych osobowych w
                                    państwie członkowskim Pani / Pana zwykłego pobytu, miejsca
                                    pracy lub miejsca popełnienia domniemanego naruszenia. W
                                    Polsce organem tym jest Prezes Urzędu Ochrony Danych
                                    Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania
                                    korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>
                            <div class="is-size-15 has-text-left has-text-black has-text-weight-light"
                                 v-else-if="formData.RegulationWorkPosition === 'ShopManager'">
                                <p>
                                    Witamy na platformie edukacyjnej Akademia Umiejętności
                                    Eurocash!
                                </p>
                                <p>
                                    Cieszymy się, że chcesz korzystać z bogatych zasobów
                                    edukacyjnych, jakie dla Ciebie przygotowaliśmy. Jednak zanim
                                    zaczniesz, potrzebujemy Twojej akceptacji regulaminu Platformy
                                    Edukacyjnej i prosimy o zapoznanie się z informacjami na temat
                                    przetwarzania Twoich danych osobowych:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsRegulation" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Akceptuję regulamin Platformy Edukacyjnej Akademii
                                        Umiejętności Eurocash,
                                        <a href="/regulamin/" target="_blank">Zobacz tutaj >></a>
                                    </span>
                                </label>
                                <p>
                                    Jednocześnie informujemy, że z poziomu swojego konta masz
                                    możliwość definiowania kont dla swoich współpracowników pod
                                    warunkiem uzyskania przez Twojego pracodawcę zgód od tych osób
                                    na przekazanie danych do Akademii Umiejętności Eurocash Sp. z
                                    o.o. – przed podaniem danych osobowych współpracowników
                                    upewnij się zatem, czy Twój Pracodawca pozyskał takie zgody.
                                    Jeżeli Twój Pracodawca nie dysponuje takimi zgodami, nie
                                    możesz podawać danych swoich współpracowników celem
                                    definiowania dla nich kont.
                                </p>
                                <label>
                                    <input type="checkbox"
                                           required
                                           v-model="formData.Agreements.IsAccountAgreementDefinition" />
                                    <span class="req-check">*</span>
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">
                                        Oświadczam, że będę definiował/a konta dla moich
                                        współpracowników na Platformie Edukacyjnej Akademii
                                        Umiejętności Eurocash wyłącznie wtedy, kiedy mój pracodawca
                                        uzyska od nich zgody na przekazanie danych do Akademii
                                        Umiejętności Eurocash Sp. z o.o. - zobacz wzór zgody
                                        <a href="/assets/pdf/zgoda_pracownika.pdf" target="_blank">TUTAJ>></a>
                                    </span>
                                </label>
                                <p>
                                    Jeśli chcesz pozostawać z nami w stałym kontakcie i otrzymywać
                                    informacje o aktualnej ofercie Akademii, o promocjach i
                                    konkursach organizowanych przez nas, spółki z Grupy Eurocash i
                                    naszych Partnerów (tj. podmiotów współpracujących z Akademią
                                    Umiejętności Eurocash Sp. z o.o., lista aktualnych Partnerów
                                    dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco), to prosimy kliknij w poniższe
                                    zgody:
                                </p>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Spółkę:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsEmailEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej,</span>
                                </label>
                                <label>
                                    <input type="checkbox"
                                           v-model="formData.Agreements.IsSmsEurocashGettingInfo" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem SMS/MMS.</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    spółki z Grupy Eurocash – których aktualna lista dostępna jest
                                    <a href="https://grupaeurocash.pl/public/assets/media/eurocash_spolki.pdf"
                                       target="_blank">TUTAJ>></a>
                                    – lista ta jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashGroupGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Wyrażam zgodę na przesyłanie mi przez Akademię Umiejętności
                                    Eurocash Sp. z o.o. informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach organizowanych przez
                                    Partnerów Akademii, których aktualna lista dostępna jest
                                    <a href="/partnerzy/" target="_blank">TUTAJ>></a> – lista ta
                                    jest aktualizowana na bieżąco:
                                </p>
                                <label>
                                    <input type="checkbox"
                                           v-model="
											formData.Agreements.IsEmailEurocashPartnersGettingInfo
										" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">za pośrednictwem poczty elektronicznej.</span>
                                </label>
                                <p>
                                    Niezaznaczenie którejkolwiek z powyższych opcji oznacza, że
                                    nie będziesz na bieżąco z naszymi najnowszymi ofertami
                                    handlowymi, jak również z ofertami handlowymi spółek z Grupy
                                    Eurocash oraz z ofertami Partnerów Akademii, które mogą okazać
                                    się dla Ciebie bardzo korzystne.
                                </p>
                                <p>
                                    Informujemy jednocześnie, że w każdej chwili możesz cofnąć
                                    każdą z udzielonych nam zgód w zakładce Moje Dane lub poprzez
                                    przesłanie nam wiadomości na adres e-mail na adres
                                    [kontakt@akademiaeurocash.com.pl] lub poprzez przycisk Wypisz
                                    się>> w otrzymanej od nas informacji o najnowszych ofertach,
                                    interesujących promocjach oraz konkursach. Wycofanie zgody nie
                                    wpływa na zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Zapoznaj się z pozostałymi informacjami na temat przetwarzania
                                    Twoich danych osobowych:
                                </p>
                                <p>
                                    Administratorem danych jest Akademia Umiejętności sp. z o.o. z
                                    siedzibą w Komornikach (62-052), ulica Wiśniowa 11.
                                </p>
                                <p>
                                    Z administratorem można skontaktować się poprzez adres e-mail:
                                    eurocash@eurocash.pl, pod numerem telefonicznym (0-61) 6583000
                                    lub pisemnie na adres siedziby.
                                </p>
                                <p>
                                    Akademia Umiejętności Eurocash sp. z o.o. wyznaczyła
                                    Inspektora Ochrony Danych, z którym Pani / Pan może
                                    skontaktować się poprzez e-mail iod_aue@eurocash.pl, pod
                                    numerem telefonicznym (0-61) 3322277 lub na adres siedziby
                                    administratora. Z inspektorem ochrony danych można się
                                    kontaktować we wszystkich sprawach dotyczących przetwarzania
                                    danych osobowych oraz korzystania z praw związanych z
                                    przetwarzaniem danych.
                                </p>
                                <p>Pani / Pana dane będą przetwarzane:</p>
                                <ol>
                                    <li>
                                        w celu wykonania umowy i umożliwienia korzystania z
                                        Platformy Edukacyjnej - podstawą prawną przetwarzania danych
                                        jest niezbędność do wykonania umowy,
                                    </li>
                                    <li>
                                        w celu dochodzenia i ochrony przed roszczeniami – podstawą
                                        prawną przetwarzania danych jest uzasadniony interes
                                        administratora polegający na możliwości ochrony interesów
                                        Administratora w postępowaniu sądowym lub innym,
                                    </li>
                                    <li>
                                        w celu komunikacji marketingowej – podstawą prawną
                                        przetwarzania danych jest uzasadniony interes administratora
                                        polegający na marketingu bezpośredni produktów i usług
                                        oferowanych przez Administratora oraz podmioty trzecie w
                                        związku z wyrażoną zgodą na otrzymywanie informacji
                                        marketingowych wybranym kanałem komunikacji.
                                    </li>
                                </ol>
                                <p>
                                    Pani / Pana dane osobowe mogą być przekazane podmiotom
                                    przetwarzającym dane osobowe na zlecenie Akademii Umiejętności
                                    sp. z o.o., w szczególności dostawcom IT oraz spółkom z Grupy
                                    Kapitałowej Eurocash. Ponadto, Pani / Pana dane osobowe oraz
                                    dane dotyczące Pani/Pana aktywności na platformie mogą być
                                    udostępnione Pani/Pana Pracodawcy i jego Franczyzodawcy.
                                </p>
                                <p>
                                    Pani / Pana dane osobowe będą przetwarzane przez okres
                                    korzystania przez Panią / Pana z usług Platformy Edukacyjnej
                                    lub przez okres trwania prawnie uzasadnionego interesu
                                    Administratora istniejącego do czasu cofnięcia zgody na
                                    otrzymywanie informacji marketingowych. Okres przechowywania
                                    danych osobowych może zostać każdorazowo przedłużony o okres
                                    przedawnienia roszczeń, jeżeli przetwarzanie danych osobowych
                                    będzie niezbędne dla dochodzenia ewentualnych roszczeń lub
                                    obrony przed takimi roszczeniami przez Administratora.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu prawo dostępu do Pani / Pana danych
                                    oraz prawo żądania ich sprostowania, ich usunięcia lub
                                    ograniczenia ich przetwarzania.
                                </p>
                                <p>
                                    Jako że Pani / Pana dane przetwarzane są w sposób
                                    zautomatyzowany na podstawie umowy – przysługuje Pani / Panu
                                    także prawo do przenoszenia danych osobowych, które
                                    dostarczyła Pani / dostarczył Pan administratorowi tj. do
                                    otrzymania od administratora Pani/Pana danych osobowych, w
                                    ustrukturyzowanym, powszechnie używanym formacie nadającym się
                                    do odczytu maszynowego. Może Pani / Pan przesłać te dane
                                    innemu administratorowi danych.
                                </p>
                                <p>
                                    W zakresie, w jakim podstawą przetwarzania Pani / Pana danych
                                    osobowych jest przesłanka prawnie uzasadnionego interesu
                                    administratora, przysługuje Pani / Panu prawo wniesienia
                                    sprzeciwu wobec przetwarzania Pani / Pana danych osobowych tj.
                                    przysługuje Pani / Panu prawo sprzeciwu wobec przetwarzania
                                    danych na potrzeby dochodzenia i ochrony przed roszczeniami
                                    oraz w celu komunikacji marketingowej, w zakresie, w jakim
                                    podstawą przetwarzania jest udzielona zgoda – przysługuje Pani
                                    / Panu prawo do jej wycofania. Wycofanie zgody nie wpływa na
                                    zgodność z prawem przetwarzania dokonanego przed jej
                                    wycofaniem.
                                </p>
                                <p>
                                    Przysługuje Pani / Panu również prawo wniesienia skargi do
                                    organu nadzorczego zajmującego się ochroną danych osobowych w
                                    państwie członkowskim Pani / Pana zwykłego pobytu, miejsca
                                    pracy lub miejsca popełnienia domniemanego naruszenia. W
                                    Polsce organem tym jest Prezes Urzędu Ochrony Danych
                                    Osobowych.
                                </p>
                                <p>
                                    Podanie danych jest dobrowolne, jednak bez ich podania
                                    korzystanie z Platformy Edukacyjnej będzie niemożliwe.
                                </p>
                                <label>
                                    <input @change="selectAll()"
                                           type="checkbox"
                                           v-model="allSelected" />
                                    <span class="is-size-15 has-text-left has-text-black has-text-weight-light">zaznacz wszystkie zgody i oświadczenia</span>
                                </label>
                            </div>
                        </div>
                        <p class="req-check">*Pola obowiązkowe.</p>
                        <div class="is-flex form-actions">
                            <button type="submit"
                                    class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button"
                                    :class="{ 'is-loading': loading }">
                                <span class="is-size-7-em has-text-white">AKCEPTUJĘ</span>
                            </button>
                            <a class="button is-danger is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button"
                               @click="logOutHandler()">
                                <span class="is-size-7-em has-text-white">Anuluj i wyjdź</span>
                            </a>
                        </div>
                    </form>
                    <p class="is-size-8 has-text-weight-light has-text-danger has-text-centered"
                       v-if="errorMsg">
                        {{ errorMsg }}
                    </p>
                    <p class="is-size-8 has-text-weight-light has-text-primary has-text-centered"
                       v-if="successMsg">
                        {{ successMsg }}
                    </p>
                </div>
            </div>
        </b-modal>

        <b-modal :active.sync="formData.IsActiveTenYearsPopup"
                 :canCancel="['escape', 'x', 'outside']"
                 class="modal-form modal-dialog"
                 :width="830"
                 scroll="keep">
            <button type="button" class="modal-close is-large" @click="disableTenYearsPopupfile"></button>
            <br />
            <img src="/assets/2018/images/10latEurocash.gif" />
        </b-modal>

    </section>
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import FloatingLabel from '@/components/BaseFloatingLabel';
import HomePageResignModal from './HomePageResignModal.vue';
import CalendarSmallIcon from '@/assets/svg/small-calendar.svg';
import PinIcon from '@/assets/svg/pin.svg';
import global from '@/mixins/global.js';
import workshops from '@/mixins/workshops.js';
const salesmanNumber = helpers.regex('salesmanNumber', /^[0-9]{1,5}$/),
	cardNumber = helpers.regex('cardNumber', /^9981[0-9]{9}$/);

export default {
	name: 'HomePageUser',
	mixins: [global, workshops],
	components: {
		HomePageResignModal,
		CalendarSmallIcon,
		PinIcon,
		FloatingLabel,
	},

	beforeMount() {
		this.loadWorkshopsHandler();

		this.$http.get(`/spa/user/profile`).then(response => {
			this.formData = {
				...this.formData,
				...response.data,
			};
		});
	},

	data() {
		return {
			userData: '',
			UserWelcome: true,
			isResignModalActive: false,
			workshopId: 0,
			allSelected: false,
            WrongEmail: false,
            rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/'),
			formData: {
				FirstName: '',
				LastName: '',
				WorkPosition: '',
				Network: '',
				Email: '',
				ConfirmEmail: '',
				Phone: '',
				CardNumber: '',
				SalesmanNumber: '',
				Agreement1GettingInfo: false,
				Agreement2SharingData: false,
				Agreement3StatisticData: false,
				IsAgreementPopupToShow: false,
                IsNewRegulationPopupToShow: false,
                IsActiveTenYearsPopup: false,
				Agreements: {
					IsAccountAgreementDefinition: false,
					IsEmailEurocashGettingInfo: false,
					IsEmailEurocashGroupGettingInfo: false,
					IsEmailEurocashPartnersGettingInfo: false,
					IsRegulation: false,
					IsSmsEurocashGettingInfo: false,
				},
				MyAccountUrl: '',
            },
			successMsg: '',
			errorMsg: '',
            loading: false,
            allproperty: false,
		};
	},
	validations: {
		formData: {
			FirstName: {
				required,
			},
			LastName: {
				required,
			},
			WorkPosition: {
				required,
			},
			Network: {
				required,
			},
			Email: {
				required,
				email,
			},
			ConfirmEmail: {
				required,
				email,
			},
			Phone: {
				numeric,
			},
			CardNumber: {
				numeric,
				cardNumber,
			},
			SalesmanNumber: {
				numeric,
				salesmanNumber,
			},
		},
	},
	methods: {
		validateBeforeSubmit() {
			if (this.formData.Email !== this.formData.ConfirmEmail) {
				this.WrongEmail = true;
			} else {
				this.submitProfileFormHandler();
				return;
			}
		},

		goToMyAccountMyData() {
			window.location = this.formData.MyAccountUrl;
		},

		submitProfileFormHandler() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loading = true;

				const formData = { ...this.formData };

				this.$http
					.post(`spa/user/updateprofile`, formData)
					.then(response => {
						this.loading = false;
						this.successMsg = response.data.Message;
						this.formData.IsAgreementPopupToShow = false;

						setTimeout(() => {
							self.successMsg = '';
						}, 3000);
					})
					.catch(error => {
						this.loading = false;
						this.successMsg = '';
						this.errorMsg = error.response.data.Message;
					});
			}
		},
		selectAll() {
			this.formData.Agreements.IsAccountAgreementDefinition = this.allSelected;
            this.formData.Agreements.IsEmailEurocashGettingInfo = this.allSelected;
            if (!(this.formData.Network === 'Niezrzeszeni')){
                this.formData.Agreements.IsEmailEurocashGroupGettingInfo = this.allSelected;
                this.formData.Agreements.IsEmailEurocashPartnersGettingInfo = this.allSelected;
            }
			this.formData.Agreements.IsRegulation = this.allSelected;
			this.formData.Agreements.IsSmsEurocashGettingInfo = this.allSelected;
		},
		newRegulationPopup() {
			let self = this;
			self.formData.IsNewRegulationPopupToShow = false;
			self.$http
				.post(`spa/user/NewRegulationPopupAccept`)
				.then(() => {})
				.catch(() => {});
        },

        disableTenYearsPopupfile() {

            let self = this;
            this.formData.IsActiveTenYearsPopup = false;
            self.$http
                .get(`spa/user/disabletenyearspopup`)
                .then(() => { })
                .catch(() => { });
        }
	},
	created: function() {},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

    .homepage-user {
        margin-top: 6.25rem;
        padding-bottom: rem(40px);

        .homepage-user-welcome {
            position: relative;

            & + .columns {
                margin-top: rem(30px);
            }

            button {
                position: absolute;
                right: -5px;
                top: -5px;
                background-color: rgba(10, 10, 10, 0.7);

                &:hover {
                    background-color: rgba(10, 10, 10, 0.5);
                }
            }
        }

        .button {
            padding: rem(20px 50px);
            float: left;
        }

        .is-dotted {
            &:before {
                bottom: 7px;

                @include tablet {
                    bottom: 12px;
                }
            }
        }

        .card {
            height: 100%;

            &-content {
                overflow: hidden;
                padding: rem(30px 20px 20px);

                @include tablet {
                    padding: rem(30px 40px);
                }

                .media-content > p {
                    clear: both;

                    &:first-child {
                        margin-bottom: rem(25px);
                        float: left;

                        & + a {
                            float: right;

                            @include tablet {
                                margin-top: -6px;
                            }
                        }
                    }
                }

                .is-small.delete {
                    height: 21px;
                    max-height: 21px;
                    max-width: 21px;
                    min-height: 21px;
                    min-width: 21px;
                    width: 21px;
                    margin-top: -2px;
                }

                h2 {
                    margin-bottom: rem(30px);
                }

                h3 {
                    margin-bottom: rem(20px);
                    clear: left;
                }

                p {
                    cursor: pointer;
                }

                svg {
                    color: $green;
                }
            }
        }

        .progress {
            margin: rem(15px 0 35px);
        }

        &-welcome {
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.03);

            & > :first-child {
                background: $green-lighter;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;

                @include tablet {
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 4px;
                }

                & > div {
                    padding: rem(20px 25px);

                    @include tablet {
                        padding: rem(25px 40px);
                    }

                    p {
                        margin-bottom: 10px;
                        letter-spacing: 1px;
                    }
                }
            }

            & > :nth-child(2) {
                position: relative;

                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 14px solid transparent;
                    border-right: 20px solid white;
                    border-bottom: 14px solid transparent;
                    position: absolute;
                    margin-top: -15px;
                    top: -5px;
                    left: 50%;
                    transform: rotate(90deg);
                    margin-left: -15px;
                }

                @include tablet {
                    &:before {
                        top: 50%;
                        left: -5px;
                        transform: rotate(0deg);
                    }
                }

                & > div {
                    background: $white;
                    padding: rem(25px 20px);
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;

                    @include tablet {
                        padding: rem(40px);
                        border-top-right-radius: 4px;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }

        &-workshop {
            border-bottom: solid 1px $grey-lightest;
            padding-bottom: rem(15px);
            padding-top: rem(13px);
            clear: both;
            width: 100%;
            display: block;

            &:last-of-type {
                padding-bottom: 0;
                border-bottom: 0;
            }

            p {
                cursor: pointer;
            }
        }

        .modal {
            &-form {
                & > :nth-child(2) {
                    padding: rem(10px 10px 20px);

                    @include tablet {
                        padding: rem(10px 30px 30px);
                    }
                }

                .control {
                    margin-bottom: rem(30px);

                    .input__container {
                        margin-bottom: 0;
                    }
                }

                .form-actions {
                    justify-content: center;
                    margin-top: rem(50px);

                    & > :first-child {
                        margin-right: rem(20px);
                    }

                    .button {
                        padding: 0;

                        @include tablet {
                            padding: rem(0 30px);
                        }
                    }
                }

                h2 {
                    margin-bottom: rem(5px);
                }

                p {
                    line-height: 1.36;
                    letter-spacing: 0.3px;
                }

                label + ol {
                    padding-left: rem(50px);
                    margin: rem(10px 0);
                }

                ol {
                    padding-left: rem(20px);
                    margin-bottom: rem(10px);
                }

                .input_wraps {
                    padding: rem(10px 0);

                    @include tablet {
                        padding: rem(10px 150px);
                    }

                    .control {
                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                .rodo-wrap {
                    label {
                        display: block;
                        margin: -10px 0 15px;

                        input {
                            margin-right: 10px;
                            vertical-align: middle;
                        }

                        span {
                            vertical-align: middle;
                        }
                    }
                }

                .b-checkbox.checkbox.is-small {
                    & > :nth-child(2) {
                        border: 1px solid $grey-light;
                        border-radius: 0;
                    }
                }
            }

            .columns {
                flex-wrap: wrap;
            }

            .button {
                color: rgba(58, 171, 89, 0.5);
                border-radius: 5px;
                height: 50px;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: rem(20px);
                }

                span {
                    color: white;
                }

                .icon {
                    margin-right: 5px;
                }

                &.is-danger {
                    color: rgba(215, 33, 65, 0.5);
                }
            }
        }

        .req-check {
            font-size: 11px;
            color: $red;
        }
    }
</style>
