<template>
	<div class="column is-full is-flex has-text-left">
		<div class="card-table">
			<div class="card has-background-info">
				<!-- Card header -->
				<div class="card-header">
					<IconCap />
					<p
						class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white"
					>
						Lista zrealizowanych kursów e-learningowych
					</p>

					<!-- Collapse toggler -->
					<button
						@click="toggle()"
						v-if="expandAvailable"
						class="card-toggler is-size-2 is-size-4-tablet"
					>
						{{ expanded ? 'Zwiń' : 'Rozwiń' }}
						<span class="chevron" :class="{ active: expanded }"></span>
					</button>
				</div>
				<!-- END | Card header -->
			</div>
			<table class="table">
				<thead class="is-hidden-mobile">
					<tr
						class="is-size-18 is-uppercase has-text-weight-semibold has-text-left"
					>
						<th class="has-text-grey-light">Temat</th>
						<th class="has-text-grey-light">Data realizacji</th>
						<th class="has-text-grey-light">Wynik</th>
						<th class="has-text-grey-light">Certyfikat</th>
					</tr>
					<tr v-if="isLoading">
						<th colspan="4" class="is-progress-bar-table">
							<progress class="progress is-primary" max="100"></progress>
						</th>
					</tr>
				</thead>

				<tbody v-if="elearnings.length">
					<tr
						v-for="(elearning, index) in expanded
							? elearnings
							: elearnings.slice(0, 5)"
						:key="index"
					>
						<td class="has-text-black">
							<span>{{ elearning.Name }}</span>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>data zakończenia</span
								>
								<span>{{ elearning.PassedDate }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>realizacja</span
								>
								<span>{{ elearning.Scale + '%' }}</span>
							</div>
						</td>
						<td class="has-text-black">
							<div class="is-dotted is-block">
								<span class="is-uppercase has-text-grey-light is-hidden-tablet"
									>certyfikat</span
								>
								<span>
									<a
										href="#"
										@click.prevent="generatePDF(elearning.ElearingResultId)"
										:style="{ textDecoration: 'none', paddingRight: '1rem' }"
										class="button is-text has-text-weight-semibold has-text-left has-text-primary"
										>Pobierz ></a
									>
								</span>
							</div>
						</td>
					</tr>
				</tbody>

				<tbody v-else-if="!elearnings.length && !isLoading">
					<tr>
						<td colspan="5" class="has-text-centered">Brak danych</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
// JS modules
import { mapState } from 'vuex';
import generatePDF from '../../../../services/PDFService';

// Vue components
import IconCap from '@/assets/svg/cap.svg';

export default {
	components: {
		IconCap,
	},

	data() {
		return {
			userId: null,
			isLoading: true,
			expanded: false,
		};
	},

	computed: {
		...mapState('screenedUser', {
			elearnings: state => state.completedElearnings,
		}),

		/**
		 * @returns {Boolean} Indicates whether the table is expandable.
		 */
		expandAvailable() {
			return this.elearnings ? this.elearnings.length > 5 : false;
		},
	},

	methods: {
		loadData() {
			this.userId = this.$route.params.id;
			this.isLoading = true;

			this.$store
				.dispatch('screenedUser/fetchCompletedElearnings', this.userId)
				.finally(() => {
					this.isLoading = false;
				});
		},

		toggle() {
			this.expanded ? this.collapse() : this.expand();
		},

		expand() {
			this.expanded = true;
		},

		collapse() {
			this.expanded = false;
		},

		generatePDF(elearningId) {
			generatePDF(this.userId, elearningId);
		},
	},

	beforeMount() {
		this.loadData();
	},

	activated() {
		this.loadData();
	},
};
</script>

<style lang="scss" scoped>
.card {
	&-header {
		padding: 10px 30px;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	&-toggler {
		background: transparent;
		border: 2px solid white;
		border-radius: 5px;
		color: white;
		padding: 5px 15px;
		margin: auto;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		// Create the chevron icon.
		.chevron {
			position: relative;
			width: 25px;
			margin-left: 15px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				background-color: white;
				width: 15px;
				height: 2px;
				border-radius: 1px;
				transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			}

			&::before {
				right: 44%;
				transform: rotate(30deg);
			}

			&::after {
				left: 44%;
				transform: rotate(-30deg);
			}

			// Animate the chevron on click.
			&.active {
				&::before {
					transform: rotate(-30deg);
				}

				&::after {
					transform: rotate(30deg);
				}
			}
		}
	}
}
</style>
