<template>
	<section class="elearning section">
		<div class="container is-container-thinnest">
			<div class="columns is-variable is-8 is-multiline">
				<div class="column is-full">
					<h1
						class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow"
					> 
						{{ elearning.Title }}
					</h1>
					<div class="card">
						<div class="card-content">
							<div class="columns">
								<div class="column">
									<!-- Course category -->
									<div class="is-dotted is-block">
										<span
											class="is-size-8 has-text-left has-text-weight-semibold has-text-black"
											>Kategoria kursu</span
										>
										<span
											class="is-size-4 has-text-info has-text-left has-text-weight-light"
											>{{
												elearning.CategoryName ? elearning.CategoryName : 'brak'
											}}</span
										>
									</div>
									<!-- END | Course category -->

									<!-- Course duration -->
									<div v-if="elearning.Duration" class="is-dotted is-block">
										<span
											class="is-size-8 has-text-left has-text-weight-semibold has-text-black"
											>Czas</span
										>
										<span
											class="is-size-4 has-text-info has-text-left has-text-weight-light"
											>około {{ elearning.Duration }} minut</span
										>
									</div>
									<!-- END | Course duration -->
								</div>

								<div class="column is-flex">
									<a
										v-if="
											elearning.IframeUrl == null || elearning.IframeUrl == ''
										"
										class="button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button"
										:href="elearning.ExternalUrl"
										target="_blank"
									>
										<span class="icon is-small">
											<BookIcon
												width="23"
												height="20"
												viewBox="5 -5 54 70"
												class="icon is-large"
											/>
										</span>
										<span>Rozpocznij kurs</span>
									</a>
									<button
										@click="RateElearning = true"
										class="rate-training button is-small is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-bold has-glow-button has-text-white"
									>
										Oceń szkolenie
									</button>

									<div class="elearn-rating">
										<div>
											<div class="elearn-stars">
												<star-rating
													:star-size="14"
													:padding="3"
													:rating="elearning.StarRating"
													:read-only="true"
													:increment="0.01"
													:show-rating="false"
													:active-color="'#f8d61c'"
													:inactive-color="'silver'"
													inline
												></star-rating>

												<p class="votes-number">
													{{ elearning.VotesCount }} głosów
												</p>
											</div>

											<p class="rating-number">
												<span
													class="is-size-9 has-text-weight-light has-text-black-hover"
												>
													{{ elearning.StarRating }}

													<span class="has-text-grey-light-hover">/5</span>
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-full iframe-learn-wrap">
					<iframe
						v-if="elearning.IframeUrl"
						:src="elearning.IframeUrl"
						scrolling="no"
						frameborder="0"
						allowfullscreen="allowfullscreen"
					></iframe>
					<div v-else>
						<figure class="image" v-if="elearning.ImageUrl">
							<img
								v-bind:src="elearning.ImageUrl"
								:alt="elearning.Title"
								:title="elearning.Title"
							/>
						</figure>
					</div>
					<div class="content" v-html="elearning.Desc"></div>
				</div>
			</div>
		</div>
		<div
			class="elearning-details-for"
			v-if="elearning.ForWho && elearning.ForWho.length > 0"
		>
			<div class="container is-container-thinnest">
				<h2 class="is-size-2 has-text-centered">
					Dla kogo przygotowany jest kurs?
				</h2>
				<div class="columns">
					<div class="column" v-for="who in elearning.ForWho" :key="who">
						<div class="card" v-if="who === 'Właściciel Sklepu'">
							<img src="/assets/2018/svg/employee-woman.svg" />
							Właścicieli sklepów
						</div>
						<div class="card" v-else-if="who === 'Kierownik Sklepu'">
							<img src="/assets/2018/svg/employee-man.svg" />
							Kierowników sklepów
						</div>
						<div class="card" v-else-if="who === 'Pracownik Sklepu'">
							<img src="/assets/2018/svg/employee-young-woman.svg" />
							Pracowników sklepów
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="elearnings-others"
			v-if="elearning.NearElearnings && elearning.NearElearnings.length > 0"
		>
			<div class="container is-container-thinnest">
				<div class="tile is-ancestor">
					<BaseTile
						v-for="item in elearning.NearElearnings"
						@goToUrlHandler="goToUrlHandler('/kursy-e-learningowe/' + item.Id)"
						:key="item.Id"
						:image="item.Image"
						:category="item.CategoryName"
						:title="item.Title"
						:col="4"
						:lang="item.Language"
					/>
				</div>
			</div>
		</div>

		<b-modal
			:active.sync="RateElearning"
			:width="820"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog rate-modal"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="RateElearning = false"
			></button>
			<p
				class="is-size-3 is-size-2-tablet has-text-centered has-text-weight-light"
			>
				Jak oceniasz, czy wiedza pozyskana podczas zrealizowanego kursu będzie
				przydatna w Twojej codziennej pracy?
				<br />
				<small>(w skali: 1 - nieprzydatna, 5 - bardzo przydatna)</small>
			</p>
			<div @click="setRating" class="rate-box">
				<star-rating
					v-model="rating"
					:star-size="50"
					:padding="3"
					:increment="1.0"
					:show-rating="false"
					:active-color="'#f8d61c'"
					:inactive-color="'silver'"
					inline
				></star-rating>
			</div>
		</b-modal>

		<b-modal
			:active.sync="isSurveyModalActive"
			:width="830"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-dialog"
		>
			<button
				type="button"
				class="modal-close is-large"
				@click="isSurveyModalActive = false"
			></button>
			<div class="columns">
				<div class="column is-full">
					<p class="is-size-2 has-text-centered has-text-weight-light">
						{{ questionnaire.Message }}
					</p>
				</div>
			</div>
			<div class="columns" v-if="questionnaire.Show">
				<div class="column is-6 has-text-right-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
						@click="submitFormHandler(true)"
					>
						<span>TAK</span>
					</a>
				</div>
				<div class="column is-6 has-text-left-desktop">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button is-outlined is-outlined-bold"
						@click="submitFormHandler(false)"
					>
						<span>NIE</span>
					</a>
				</div>
			</div>
			<div class="columns" v-else>
				<div class="column is-full has-text-centered">
					<a
						class="button is-small is-info is-uppercase has-text-weight-bold has-glow-button"
						@click="isSurveyModalActive = false"
					>
						<span>ZAMKNIJ</span>
					</a>
				</div>
			</div>
		</b-modal>

		<BaseModalNav
			v-if="isIframeModalActive"
			@closeModalHandler="isIframeModalActive = false"
			:hasBackBtn="true"
		/>
		<b-modal
			:active.sync="isIframeModalActive"
			:width="900"
			:canCancel="['escape', 'x', 'outside']"
			scroll="keep"
			class="modal-fullscreen"
		>
			<div class="columns is-paddingless is-marginless">
				<div class="column is-full">
					<BaseIframe
						:src="
							elearning.IsExternalUrl
								? elearning.ExternalUrl
								: '/' + elearning.ExternalUrl
						"
					/>
				</div>
			</div>
		</b-modal>
	</section>
</template>

<script>
import BaseTile from '@/components/BaseTile.vue';
import BaseModalNav from '@/components/BaseModalNav.vue';
import BaseIframe from '@/components/BaseIframe.vue';
import global from '@/mixins/global.js';
import HeartIcon from '@/assets/svg/heart.svg';
import BookIcon from '@/assets/svg/book.svg';
import StarRating from 'vue-star-rating';

export default {
	name: 'Elearning',
	mixins: [global],
	components: {
		BaseTile,
		BaseModalNav,
		BaseIframe,
		HeartIcon,
		BookIcon,
		StarRating,
	},
	//mounted: function() {alert('mounted');},

        activated() {
		
	},

	//destroyed: function(){alert('destroyed');},

	data() {
		return {
			elearning: { IsUserAllowedToRate: false },
			questionnaire: '',
			isSurveyModalActive: false,
			isIframeModalActive: false,
			RateElearning: false,
			rating: 0,
		};
	},
	methods: {
		getQuestionnaireHandler() {
			let self = this;
			self.$http
				.get(`spa/elearning/getquestionnaire/` + self.elearning.Id)
				.then(response => {
					self.questionnaire = response.data;
				});
		},
		submitFormHandler(answer) {
			let self = this,
				formData = {
					Id: self.elearning.Id,
					Answer: answer,
				};
			self.$http
				.post(`spa/elearning/updatequestionnaire`, formData)
				.then(() => {
					self.isSurveyModalActive = false;
					self.getQuestionnaireHandler();
				})
				.catch(() => {});
		},

		resetData() {
			let self = this;
			self.elearning = {};
		},

		setRating() {
			let self = this;
			var formData = {
				ElearningSubjectId: this.$route.params.id,
				Rating: self.rating,
			};
			self.$http.post(`spa/elearning/StarRating`, formData).then(res => {
				self.isSurveyModalActive = true;
				self.questionnaire = res.data;
				self.elearning.VotesCount = res.data.VotesCount;
				self.elearning.StarRating = res.data.Rating;
			});
			self.RateElearning = false;
		},
	},
        created: function () {
            let self = this;
            self.resetData();
            self.$http
                .get(`spa/elearning/` + self.$route.params.id)
                .then(response => {
                    self.elearning = response.data;
                    self.$set(this.$store.state, 'breadcrumb', self.elearning.Title);
                    self.getQuestionnaireHandler();
                })
                .catch(() => { });
        },
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.elearning {
	padding-bottom: rem(50px);
	margin-top: rem(30px);
	.is-dotted {
		margin-bottom: rem(15px);
		&:before {
			bottom: 1px;
		}
		span + span {
			margin-top: 7px;
		}
	}
	.button {
		color: rgba(58, 171, 89, 0.5);
		border-radius: 5px;
		height: 50px;
		width: 100%;
		&:not(:last-child) {
			margin-bottom: rem(20px);
		}
		span {
			color: white;
		}
		.icon {
			margin-right: 5px;
		}
		&.is-info.is-outlined {
			color: rgba(58, 171, 89, 0.5);
			&:hover,
			&:focus {
				span {
					color: $white;
				}
			}
			span {
				color: $green-lighter;
			}
		}
	}
	.card-content {
		padding: rem(20px 40px 20px 30px);
	}
	.column.is-flex {
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		& > a {
			width: 100%;
		}
	}

	.iframe-learn-wrap {
		iframe {
			margin: rem(40px 0);
			width: 100%;
			height: 730px;
		}
	}

	.image {
		margin: rem(40px 0);

		img {
			width: 100%;
			height: 220px;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}
	.modal-fullscreen {
		.columns > :first-child {
			padding: 0;
		}
		& > :nth-child(2) {
			box-shadow: none;
			background-color: transparent;
			border: 0;
			overflow: hidden;
		}
	}

	.vue-iframe {
		height: 640px;
		box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
		overflow: auto;
		& > :first-child {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.elearn-rating {
		text-align: right;
		width: 100%;
		margin-bottom: 1.25rem;

		@include tablet {
			margin-bottom: 0;
		}

		> div {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;

			.elearn-stars {
				margin-right: -13px;

				.votes-number {
					margin-right: 13px;
					font-weight: 700;
					text-transform: uppercase;
					color: #c0c0c0;
					letter-spacing: 0.83px;
					font-size: 0.625rem;
				}
			}

			.rating-number {
				margin-left: 10px;
			}
		}

		.rate-training {
			color: $green;
			&:hover {
				opacity: 0.7;
			}
			span {
				font-size: 0.75rem;
			}
		}
	}

	.elearning-details-for {
		background-color: $white;
		margin: 3rem -1.25rem 0;
		padding: 3rem 1.25rem;
		color: $black-real;

		.columns {
			margin-top: 2rem;
		}

		.card {
			font-size: 1.125rem;
			font-weight: 400;
			text-align: center;
			color: $black;
			padding-top: 1.875rem;
			padding-bottom: 1.5rem;
			padding: 1.875rem 1rem 1.5rem;
			border: 1px solid #f2f2f2;
			height: 100%;

			img {
				display: block;
				margin: 0 auto 0.75rem;
				border-radius: 0;
			}
		}
	}

	.rate-box {
		text-align: center;
	}

	.elearnings-others {
		padding: 3.5rem 0 1rem;
	}
}
</style>
