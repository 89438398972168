<template>
  <main>
    <BaseHeroSearch
      title="Lista pracowników"
      :showFilters.sync="showFilters"
      :hasFilters="true"
      :search.sync="search"
      :hasSearch="true"
      :hasSearchMobileOnly="true"
      :placeholder="'Szukaj'"
    />
    <EmployeesTable :search.sync="search" :showFilters.sync="showFilters" />
  </main>
</template>

<script>
import BaseHeroSearch from '@/components/BaseHeroSearch.vue';
import EmployeesTable from '@/components/Containers/ViewStoreEmployees/EmployeesTable.vue';

export default {
	name: 'ViewStoreEmployees',

	components: {
		BaseHeroSearch,
		EmployeesTable,
	},

	data() {
		return {
			search: '',
			showFilters: false,
		};
	},
};
</script>

<style scoped lang="scss">
@import 'sass-rem';
@import './../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';
</style>
