<template>
	<section :class="`__sets`">
		<h2 class="-title is-size-2 has-text-centered">
			{{ title }}
		</h2>

		<ul class="__grid">
			<li v-for="set in sets" :key="set.Title" class="__grid-item">
				<!-- The [download] attribute won't be present if the isAvailableToDownload key is set to 'false' -->
				<a
					:href="
						set.FileUrl
							? getUrl(set.Title)
							: `/Elearning/Getlinkpathurl/${set.PathId}`
					"
					class="__tile"
				>
					<h3 class="-title is-size-4">
						{{ set.Title }}
					</h3>
					<p class="-description has-text-weight-light">
						{{ set.Text }}
					</p>
				</a>
			</li>
		</ul>
	</section>
</template>

<script>
export default {
	props: ['title', 'sets'],

	methods: {
		// DELETE ME
		/**
		 * @description This method will get you the hardcoded URL address for the set with a given title.
		 * This is absolutely NOT a good solution, but it's only TEMPORARY.
		 *
		 * @param {String} title The title of the set.
		 * @returns {String|Boolean} The URL address that the <a> should point to
		 * or plain 'false' boolean if the set's title didn't match any of the switch cases.
		 */
		getUrl(title) {
			switch (title) {
				case 'AKADEMIA LIDERA':
					return 'https://www.eurocash.elearning.edu.pl/akademia-lidera.html';

				case 'SPRZEDAWCA – PROFESJONALNA OBSŁUGA KLIENTA':
					return 'https://www.eurocash.elearning.edu.pl/profesjonalny-sprzedawca-kasjer.html';

				case 'EKSPERT DZIAŁÓW ŚWIEŻYCH':
					return 'https://www.eurocash.elearning.edu.pl/ekspert-dzialow-swiezych.html';

				default:
					return false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';
@import '~bulma/sass/utilities/mixins';

.__sets {
	margin-bottom: 50px;
	@include tablet {
		margin-bottom: 57px;
	}

	> .-title {
		margin-bottom: 20px;
	}
}

.__grid {
	// Mobile:
	// - 1 column
	// - flexible height rows
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: auto;
	grid-row-gap: 20px;

	// Tablet:
	// - 2 columns
	// - equal height rows
	@include tablet {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		grid-column-gap: 20px;
	}
}

.__tile {
	display: flex;
	height: 100%; // To fill the parent <li> element
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;

	padding-top: 24px;
	padding-bottom: 26px;
	padding-left: 21px;
	padding-right: 60px;

	background-color: white;
	color: $grey-dark;
	border-radius: 4px;
	box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
	transition: all 200ms ease-in-out;

	&:hover {
		color: white;
		background-color: $green-lighter;

		&::before,
		&::after {
			background-color: white;
		}
	}

	position: relative;
	// The ::before and ::after pseudoelements are used to create the chevron pointing right.
	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 20px;
		top: 50%;
		transform-origin: center right;

		width: 15px;
		height: 1.4px;

		background-color: $green;
		transition: all 200ms ease-in-out;
	}

	// The amount of degrees that the ::before
	// and ::after pseudoelements are to be rotated.
	$rotate-degree: 50deg;
	// Rotate the ::before pseudoelement by the POSITIVE value.
	&::before {
		transform: rotate($rotate-degree);
	}
	// Rotate the ::after pseudoelement by the NEGATIVE value.
	&::after {
		transform: rotate(-$rotate-degree);
	}

	.-title {
		margin-bottom: 20px;
	}
}
</style>
