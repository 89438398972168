<template>
    <div class="is-flex">
        <BaseCardDownload v-for="(item, key) in media" :key="key" :title="item.Title" :date="item.Date" :image="item.Image" :description="item.Description" :file="item.File" :id="item.Id" />
        <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="page++; loadMediaHandler()" v-if="nextPage">Załaduj więcej treści</a>
    </div>
</template>

<script>
import BaseCardDownload from "@/components/BaseCardDownload.vue";

export default {
  name: "LoginMedia",
  components: {
    BaseCardDownload
  },
  data() {
    return {
      page: 0,
      nextPage: true,
      media: {}
    };
  },
  methods: {
    loadMediaHandler() {
      let self = this;
      self.$http
        .get(`/spa/about/media/${self.page}`)
        .then(response => {
          if (self.page) {
            self.media = [...self.media, ...response.data.Data];
            self.nextPage = response.data.NextPage;
          } else {
            self.media = response.data.Data;
            self.nextPage = response.data.NextPage;
          }
        })
        .catch(() => {});
    }
  },
  created: function() {
    this.loadMediaHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.is-flex {
  flex-wrap: wrap;
  justify-content: center;
}

.button.is-light.is-outlined {
  padding: 25px 50px;
  border-color: $grey-lighter;
  &:hover,
  &:focus {
    background: transparent;
    border-color: $grey-light;
  }
}
</style>