<template>
    <main>
        <!--<BaseHero >-->

        <ViewImplementationProgramWeek     />
    </main>
</template>

<script>
import BaseHero from "@/components/BaseHero.vue";
    import ViewImplementationProgramWeek from "@/components/Containers/ViewImplementationProgram/ViewImplementationProgramWeek.vue";
export default {
  name: "ViewImplementationProgram",
  components: {
    BaseHero,
      ViewImplementationProgramWeek
  },
  data() {
    return {
      title: "",
      text:""
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

</style>
