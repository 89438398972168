import validationMessages from "@/validations.js";


export default {
    methods: {
        
        getUrlParameter: function (name) {
            name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
            let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
                results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        },
        goToExternalUrlHandler(url) {
            window.location = url;
        },
        goToUrlHandler(route) {
            this.$router.push(route);
        },
        logOutHandler() {
            let self = this;
            this.$http
                .get(`/spa/user/logout`)
                .then(() => {
                    self.$set(self.$store.state, "user", "");
                    self.$store.commit("UPDATE_STATE");
                    window.location = "/";
                })
                .catch(() => { });
        },
        showFiltersHandler() {
            this.$emit("update:showFilters", false);
        },
        updateSearch(search) {
            this.$emit("update:search", search);
        },
        clearFiltersHandler(filter, callback) {
            if (filter) {
                this.filters[filter] = "";
            } else {
                for (let prop of Object.getOwnPropertyNames(this.filters))
                    this.filters[prop] = "";
            }
            this.filters.Page = 0;
            if (callback) { 
                callback();
            }
        },
        validationMessages(input) {
            return validationMessages(input);
        }
    }
}
