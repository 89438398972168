<template>
  <main>
    <Knowledge/>
  </main>
</template>

<script>
import Knowledge from "@/components/Containers/ViewKnowledge/Knowledge.vue";

export default {
  name: "ViewKnowledge",
  components: {
    Knowledge
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";
</style>
