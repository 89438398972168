<template>
    <carousel :scrollPerPage="true" :navigationEnabled="true" paginationActiveColor="#3AAB59" :paginationSize="40" paginationColor="#dbdbdb" navigationPrevLabel="<svg xmlns='http://www.w3.org/2000/svg' width='15' height='44' viewBox='0 0 15 44'> <path fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.8' d='M12.917 1.375L2.083 22l10.834 20.625'/></svg>" navigationNextLabel="<svg xmlns='http://www.w3.org/2000/svg' width='15' height='44' viewBox='0 0 15 44'> <path fill='none' fill-rule='evenodd' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.8' d='M2.083 1.375L12.917 22 2.083 42.625'/> </svg> " :perPageCustom="[[0, 1]]">
        <slide>
            <figure class="image is-flex">
                <img src="/img/experts/mruk.png" alt="prof. dr hab. Henryk Mruk" title="prof. dr hab. Henryk Mruk" class="is-rounded">
            </figure>
            <div class="has-text-weight-light has-text-centered">
                <p>Akademia to&nbsp;jedyna na polskim rynku instytucja szkoleniowa, która posiada tak bogatą i&nbsp;kompleksową ofertę edukacyjną dla&nbsp;sklepów detalicznych. Zachęcam do&nbsp;korzystania, wiedza na&nbsp;zawsze pozostanie z&nbsp;Wami.</p>
            </div>
            <p class="is-size-7 has-text-centered has-text-grey-lighter">prof.&nbsp;dr&nbsp;hab.&nbsp;Henryk Mruk</p>
        </slide>
        <slide>
            <figure class="image is-flex">
                <img src="/img/experts/zuber.png" alt="Marek Zuber" title="Marek Zuber" class="is-rounded">
            </figure>
            <div class="has-text-weight-light has-text-centered">
                <p>Edukacja jest najlepszą inwestycja, której nigdy nie&nbsp;stracimy. Wiedza i&nbsp;poparte nią&nbsp;doświadczenie dają siłę w&nbsp;przetrwaniu nawet największego kryzysu gospodarczego.</p>
            </div>
            <p class="is-size-7 has-text-centered has-text-grey-lighter">Marek Zuber</p>
        </slide>
        <slide>
            <figure class="image is-flex">
                <img src="/img/experts/sonik.png" alt="Rafał Sonik" title="Rafał Sonik" class="is-rounded">
            </figure>
            <div class="has-text-weight-light has-text-centered">
                <p>Uważam, że&nbsp;sukces to 20&nbsp;proc. talentu, 10&nbsp;proc. szczęścia, a&nbsp;cała reszta to&nbsp;pilność i&nbsp;pracowitość oraz ciągłe pogłębianie wiedzy. Dzięki wiedzy i&nbsp;pracowitości każdy może odnieść sukces.</p>
            </div>
            <p class="is-size-7 has-text-centered has-text-grey-lighter">Rafał Sonik</p>
        </slide>
        <slide>
            <figure class="image is-flex">
                <img src="/img/experts/grzesiak.png" alt="dr Mateusz Grzesiak" title="dr Mateusz Grzesiak" class="is-rounded">
            </figure>
            <div class="has-text-weight-light has-text-centered">
                <p>Nie ma&nbsp;nic złego w&nbsp;tym, że&nbsp;chcesz więcej. Im więcej masz, tym więcej możesz dać&nbsp;innym.</p>
            </div>
            <p class="is-size-7 has-text-centered has-text-grey-lighter">dr Mateusz Grzesiak</p>
        </slide>
        <slide>
            <figure class="image is-flex">
                <img src="/img/experts/walczak.png" alt="Krzysztof Walczak" title="Krzysztof Walczak" class="is-rounded">
            </figure>
            <div class="has-text-weight-light has-text-centered">
                <p>Na&nbsp;sukces przedsiębiorstwa przekłada się&nbsp;przede wszystkim, wiedza i&nbsp;zaangażowanie pracujących w&nbsp;nim osób. Akademii Umiejętności zapewnia Właścicielom sklepów nieograniczony dostęp do&nbsp;edukacji.</p>
            </div>
            <p class="is-size-7 has-text-centered has-text-grey-lighter">Krzysztof Walczak</p>
        </slide>
    </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "LoginSlider",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.image {
  width: 104px;
  height: 104px;
  background-color: #ececec;
  box-shadow: 0 11px 18px 0 rgba(0, 0, 0, 0.11);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
  img {
    width: 86px;
    height: 86px;
  }
}
a {
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $green-lighter;
  }
}
</style>