<template>
  <section class="partner-advises section">
    <div class="container">
      <div class="columns">
        <div class="column is-one-quarter is-hidden-mobile">
            <aside class="menu">
                <b-collapse :open.sync="isOpen[0]">
                    <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': isOpen[0] }">Wybierz partnera</p>
                    <div class="navbar-content">
                        <ul class="menu-list">
                            <li class="is-flex" :class="{ 'is-selected': filters.CompanyId === filter.Key }" v-for="filter in filtersList.Companies" :key="filter.Key">
                                <a @click="filters.CompanyId = filter.Key, filters.Page = 0, showNoResult = false, loadPartnerAdvisesHandler()" class="is-size-7 img">
                                    <img :src="'/assets/2018/images/partners/' + filter.Logo.toLowerCase() + '.png'" :alt="filter.Label">
                                </a>
                                <button class="delete is-small" @click="clearFiltersHandler('CompanyId', loadPartnerAdvisesHandler)"></button>
                            </li>
                        </ul>
                    </div>
                </b-collapse>
                <b-collapse :open.sync="isOpen[1]">
                    <p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': isOpen[1] }">Kategorie</p>
                    <div class="navbar-content">
                        <ul class="menu-list">
                            <li v-for="item in filtersList.Categories" :key="item.Id" class="is-flex" :class="{ 'is-selected': filters.CategoryId === item.Key }">
                                <a @click="filters.CategoryId = item.Key, filters.Page = 0, loadPartnerAdvisesHandler()" class="is-size-7">{{ item.Label }}</a>
                                <button class="delete is-small" @click="clearFiltersHandler(false, loadPartnerAdvisesHandler)"></button>
                            </li>
                        </ul>
                    </div>
                </b-collapse>
                  <!--<p class="menu-label is-size-7 has-text-black has-border-bottom" slot="trigger" :class="{ 'is-active': isOpen[1] }">Kategorie</p>-->
                <div class="navbar-content">
                    <ul class="menu-list">
                        <li class="is-flex has-text-left" :class="{ 'is-selected':  filters.IsDedicatedForNetwork === true}">
                            <a class="navbar-item has-text-left is-size-7"   :class="{ 'has-border-top': isOpen[1] }"   @click="filters.IsDedicatedForNetwork = true, filters.Page = 0, loadPartnerAdvisesHandler()">
                                Dedykowane dla sieci
                            </a>
                            <button class="delete is-small" @click="filters.IsDedicatedForNetwork = false, filters.Page = 0, loadPartnerAdvisesHandler()"></button>
                        </li>
                    </ul>
                </div>


            </aside>
        </div>
        <div class="column">
            <div class="columns is-multiline">
                <div class="column is-half" v-for="partner in partnerAdvises" :key="partner.Id">
                    <BaseCardTopImage @goToUrlHandler="goToUrlHandler('/ekspert-radzi/' + partner.Id)" :partnerLogo="partnerLogo(partner)" :image="partner.Image" :imagePosition="'bottom'" :title="partner.Title" :description="partner.Subtitle"/>
                </div>
                <a class="button is-light is-rounded is-light is-outlined is-size-8 is-uppercase has-text-weight-semibold" @click="filters.Page++; loadPartnerAdvisesHandler()" v-if="nextPage">Załaduj więcej treści</a>
                <h2 class="title is-2 has-text-weight-light has-text-left" v-if="showNoResult">
                    {{ 'Brak wyników' }}
                    <a href="#" class="is-size-8 has-text-weight-semibold has-text-left has-text-primary has-arrow" @click.prevent="clearFiltersHandler(false, loadPartnerAdvisesHandler)">Resetuj wyszukiwanie</a>
                </h2>
            </div>
        </div>
      </div>
    </div>
    <nav class="filters is-fixed-top">
      <aside class="menu is-hidden-desktop" :class="{ 'is-active': $parent.showFilters }">
          <span v-touch:tap="showFiltersHandler"></span>
          <div v-touch:swipe.left="showFiltersHandler" v-touch:swipe.right="showFiltersHandler">
              <p class="menu-label is-size-7 has-text-weight-semibold is-uppercase has-text-black has-text-right navbar-close" @click.prevent="$emit('update:showFilters', false)">ZAMKNIJ<button class="delete is-small"></button></p>
              <ul class="menu-list first">
                  <li>
                    <b-collapse :open.sync="isOpen[0]">
                      <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': isOpen[0] }">
                        <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">Partnerzy</span>
                      </span>
                      <div>
                          <div class="navbar-content">
                              <ul class="menu-list">
                                  <li class="is-flex" :class="{ 'is-selected': filters.CompanyId === filter.Key }" v-for="filter in filtersList.Companies" :key="filter.Key">
                                      <a @click="filters.CompanyId = filter.Key, filters.Page = 0, loadPartnerAdvisesHandler()" class="is-size-7 has-text-left img">
                                          <img :src="'/assets/2018/images/partners/' + filter.Logo.toLowerCase() + '.png'" :alt="filter.Label">
                                      </a>
                                      <button class="delete is-small" @click="clearFiltersHandler('CompanyId', loadPartnerAdvisesHandler)"></button>
                                  </li>
                              </ul>
                              <a href="#" class="check_partners_adv is-uppercase has-glow-button has-text-weight-bold" @click.prevent="$emit('update:showFilters', false)">Sprawdź</a>
                              <a href="#" class="clear_partners_adv" @click.prevent="clearFiltersHandler(false, loadPartnerAdvisesHandler)">Wyczyść wszystkie filtry</a>
                          </div>
                      </div>
                    </b-collapse>                    
                  </li>
                  <li>
                      <b-collapse :open.sync="isOpen[1]">
                          <span class="has-arrow is-flex" slot="trigger" :class="{ 'is-active': isOpen[1] }">
                              <span class="is-size-7 is-info has-text-weight-semibold is-uppercase is-paddingless">Kategorie</span>
                          </span>
                          <div>
                              <div class="navbar-content">
                                  <ul>
                                      <li v-for="item in filtersList.Categories" :key="item.Id" class="is-flex has-text-left" :class="{ 'is-selected':  filters.CategoryId === item.Key}">
                                          <a class="navbar-item has-text-left is-size-7 is-uppercase" @click="filters.CategoryId = item.Key, filters.Page = 0, loadPartnerAdvisesHandler()">
                                              {{ item.Label }}
                                          </a>
                                          <button class="delete is-small" @click="clearFiltersHandler(false, loadPartnerAdvisesHandler)"></button>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </b-collapse>
                  </li>
                  <li>
                      <ul>
                          <li class="is-flex has-text-left" :class="{ 'is-selected':  filters.IsDedicatedForNetwork === true}">
                              <a class="navbar-item has-text-left is-size-7 is-uppercase" @click="filters.IsDedicatedForNetwork = true, filters.Page = 0, loadPartnerAdvisesHandler()">
                                  Dedykowane dla sieci
                              </a>
                              <button class="delete is-small" @click="filters.IsDedicatedForNetwork = false, filters.Page = 0, loadPartnerAdvisesHandler()"></button>
                          </li>
                      </ul>

                  </li>

              </ul>
          </div>
      </aside>      
    </nav>  
  </section>
</template>

<script>
import global from "@/mixins/global.js";
import BaseCardTopImage from "@/components/BaseCardTopImage.vue";

export default {
  name: "PartnerAdvises",
  mixins: [global],
  components: {
    BaseCardTopImage
  },
  props: [],
  mounted() {},
  data() {
    return {
      partnerAdvises: {},
      filtersList: {},
        counter: 5,
        showNoResult: false,
        isOpen: [false, false],
      filters: {
        Page: 0,
        CompanyId: "",
        Query: "",
        IsDedicatedForNetwork :false
      },
      nextPage: true
    };
  },
  methods: {
    loadPartnerAdvisesHandler() {
      let self = this;
      self.$http
        .post(`spa/partneradvises/list`, self.filters)
          .then(response => {
              if (self.filters.Page) {
                self.partnerAdvises = [
                    ...self.partnerAdvises,
                    ...response.data.Data
                    ];
                 self.counter = self.partnerAdvises.length;
                 self.nextPage = response.data.NextPage;
              } else {
                self.partnerAdvises = response.data.Data;
                self.nextPage = response.data.NextPage;
              }

              self.showNoResult = self.partnerAdvises.length === 0;
        })
            .catch(() => { });  
    },
    loadPartnerAdvisesFiltersHandler() {
      let self = this;
      self.$http
        .get(`spa/partneradvises/dictionary`)
        .then(response => {
          self.filtersList = response.data;
          //self.$set(self.filtersList, "isOpen", false);
        })
        .catch(() => {});
      },

     partnerLogo: function (partner) {
          // `this` points to the vm instance
          //let self = this;
          let res = '';
          if (typeof partner.Company != 'undefined' && partner.Company != '') {

              res = '/assets/2018/images/partners/' + partner.Company.toLowerCase() + '.png';
          }
          return res
      }

  },
  created: function() {
    this.loadPartnerAdvisesHandler();
    this.loadPartnerAdvisesFiltersHandler();
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.partner-advises {
  padding-bottom: rem(60px);
  .title {
    margin-left: rem(30px);
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }
  .menu {
    text-align: left;
    a {
      padding-left: 0;
    }
    img {
      margin: rem(10px 0);
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      max-height: 40px;
    }
    &-list {
      .navbar-content {
        ul {
          li {
            a {
              padding-right: 40%;
              &:hover,
              &:focus {
                background: transparent;
              }
            }
          }
        }
      }
    }
    &-label {
      text-transform: none;
      .delete {
        display: inline-block;
      }
    }
  }
  li {
    letter-spacing: 1px;
  }
  .button.is-light.is-outlined {
    margin: 20px auto 0;
    padding: 20px 60px;
    border-color: $grey-lighter;
    &:hover,
    &:focus {
      background: transparent;
      border-color: $grey-light;
    }
  }
  .delete {
    display: none;
    align-self: center;
  }
  .columns {
      text-align: center;
    .menu {
      text-align: left;
      &-list {
        li {
          justify-content: space-between;
          position: relative;
          a {
            padding-left: 0;
            width: 100%;
            &:hover,
            &:focus {
              background-color: transparent;
            }
            img {
              max-height: 40px;
            }
           
          }
    .img {
        padding-right: 65%;
    }

          .delete {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
    }
  }
  .is-selected {
    font-weight: bold;
    img {
      -webkit-filter: none;
      filter: none;
    }
    .delete {
      float: right;
      display: block;
      background-color: #bebebe;
    }
    .delete:before,
    .delete:after {
      background-color: white;
    }
  }
    .menu-list.first {
        margin-top: 30px;
        > li {
                 border-bottom: none;
             }
    }
    .filters .menu-list {
        .check_partners_adv {
        background-color: #3aab59;
        color: #fff;
        text-align: center;
        display: block;
        padding: 16px;
        margin: 20px auto 0;
        font-size: .875rem;
        border-radius: 4px;
        width: 90%;
        max-width: 300px;
    }
    .clear_partners_adv {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #4a4a4a;
        display: block;
        text-align: center;
        font-weight: 600;
        font-size: .875rem;
        padding: 0;
        margin: 20px 0;
        border: none;
        &:hover, &:focus {
                background-color: transparent;
                color: #3AAB59;
            }
        }
    }
}
</style>
