<template>
    <section class="homepage-user section" v-if="userData">
        <div class="container">
            <div class="columns is-flex-tablet">
                <div class="column is-three-fifths">
                    <div class="card">
                        <div class="card-content">
                            <div class="media">
                                <div class="media-content">
                                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-grey-light">
                                        warsztaty, na które jesteś zapisany
                                    </p>
                                    <a class="homepage-user-workshop"
                                       :href="workshop.ExternalUrl"
                                       v-for="workshop in userData.Workshops"
                                       :key="workshop.Id">
                                        <h3 class="is-size-4-tablet has-text-left has-text-weight-light has-text-black">
                                            {{ workshop.Title }}
                                        </h3>
                                        <div class="columns">
                                            <div class="column is-four-fifths is-flex">
                                                <CalendarSmallIcon width="18"
                                                                   height="20"
                                                                   viewBox="0 -6 23 24" />
                                                <time datetime="31-10-2018"
                                                      class="has-text-primary has-text-left has-text-weight-semibold">{{ workshop.Date }}</time>
                                                <PinIcon width="30" height="20" viewBox="0 -6 2 24" />
                                                <span class="has-text-primary has-text-left has-text-weight-semibold">{{ workshop.City }}</span>
                                            </div>
                                            <div class="column"
                                                 v-if="userData.IsAllowToResignFromWorkshop">
                                                <p class="is-size-8 has-text-weight-semibold is-uppercase has-text-grey-light has-text-right"
                                                   @click.prevent="
														(isResignModalActive = true),
															(workshopId = workshop.Id)
													">
                                                    REZYGNUJ
                                                    <button class="delete is-small"></button>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    <p class="is-size-4-tablet has-text-left has-text-weight-light"
                                       v-show="userData.Workshops.length === 0">
                                        Nie jesteś zapisany na żaden warsztat.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="card">
                        <div class="card-content">
                            <div class="media">
                                <div class="media-content">
                                    <p class="is-size-8 is-uppercase has-text-weight-bold has-text-left has-text-grey-light">
                                        Twoje statystyki
                                    </p>
                                    <a href="rootUrl + '/moja-akademia/moja-kariera-statystyki/'"
                                       class="is-size-8 has-text-weight-semibold has-text-left is-block has-text-primary has-arrow">przejdź do profilu</a>
                                    <div class="is-dotted is-block">
                                        <span class="is-size-4-tablet has-text-left has-text-weight-light">Zrealizowane kursy e-learningowe</span>
                                        <span class="is-size-1 is-size-9-tablet has-text-primary has-text-left">{{ userData.Stats.Training }}</span>
                                    </div>
                                    <div class="is-dotted is-block">
                                        <span class="is-size-4-tablet has-text-left has-text-weight-light">Zrealizowane warsztaty</span>
                                        <span class="is-size-1 is-size-9-tablet has-text-primary has-text-left">{{ userData.Stats.Worskshops }}</span>
                                    </div>
                                    <!--
                                    <div class="is-dotted is-block">
                                      <span class="is-size-4 has-text-left has-text-weight-light">Konferencja</span>
                                      <span class="is-size-9 has-text-primary has-text-left">{{ userData.Stats.Conferences }}</span>
                                    </div>
                                                    -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HomePageResignModal :workshopId="workshopId"
                             :successMsg="successMsg"
                             :isResignModalActive="isResignModalActive"
                             @hideModalHandler="isResignModalActive = false"
                             @submitResignFormHandler="submitResignFormHandler()" />
    </section>
</template>

<script>
    import { required, email, numeric } from 'vuelidate/lib/validators';
    import { helpers } from 'vuelidate/lib/validators';
    import FloatingLabel from '@/components/BaseFloatingLabel';
    import HomePageResignModal from './HomePageResignModal.vue';
    import CalendarSmallIcon from '@/assets/svg/small-calendar.svg';
    import PinIcon from '@/assets/svg/pin.svg';
    import global from '@/mixins/global.js';
    import workshops from '@/mixins/workshops.js';

    const salesmanNumber = helpers.regex('salesmanNumber', /^[0-9]{1,5}$/),
        cardNumber = helpers.regex('cardNumber', /^9981[0-9]{9}$/);

    export default {
        name: 'HomePageStats',
        mixins: [global, workshops],
        components: {
            HomePageResignModal,
            CalendarSmallIcon,
            PinIcon,
            FloatingLabel,
        },

        beforeMount() {
            this.loadWorkshopsHandler();

            this.$http.get(`/spa/user/profile`).then(response => {
                this.formData = {
                    ...this.formData,
                    ...response.data,
                };
            });
        },

        data() {
            return {
                userData: '',
                UserWelcome: true,
                isResignModalActive: false,
                workshopId: 0,
                allSelected: false,
                WrongEmail: false,
                rootUrl: (window.root_path || 'https://akademiaeurocash.com.pl/'),
                formData: {
                    FirstName: '',
                    LastName: '',
                    WorkPosition: '',
                    Network: '',
                    Email: '',
                    ConfirmEmail: '',
                    Phone: '',
                    CardNumber: '',
                    SalesmanNumber: '',
                    Agreement1GettingInfo: false,
                    Agreement2SharingData: false,
                    Agreement3StatisticData: false,
                    IsAgreementPopupToShow: false,
                    IsNewRegulationPopupToShow: false,
                    IsActiveTenYearsPopup: false,
                    Agreements: {
                        IsAccountAgreementDefinition: false,
                        IsEmailEurocashGettingInfo: false,
                        IsEmailEurocashGroupGettingInfo: false,
                        IsEmailEurocashPartnersGettingInfo: false,
                        IsRegulation: false,
                        IsSmsEurocashGettingInfo: false,
                    },
                    MyAccountUrl: '',
                },
                successMsg: '',
                errorMsg: '',
                loading: false,
            };
        },
        validations: {
            formData: {
                FirstName: {
                    required,
                },
                LastName: {
                    required,
                },
                WorkPosition: {
                    required,
                },
                Network: {
                    required,
                },
                Email: {
                    required,
                    email,
                },
                ConfirmEmail: {
                    required,
                    email,
                },
                Phone: {
                    numeric,
                },
                CardNumber: {
                    numeric,
                    cardNumber,
                },
                SalesmanNumber: {
                    numeric,
                    salesmanNumber,
                },
            },
        },
        methods: {
            validateBeforeSubmit() {
                if (this.formData.Email !== this.formData.ConfirmEmail) {
                    this.WrongEmail = true;
                } else {
                    this.submitProfileFormHandler();
                    return;
                }
            },

            goToMyAccountMyData() {
                window.location = this.formData.MyAccountUrl;
            },

            submitProfileFormHandler() {
                this.$v.$touch();

                if (!this.$v.$invalid) {
                    this.loading = true;

                    const formData = { ...this.formData };

                    this.$http
                        .post(`spa/user/updateprofile`, formData)
                        .then(response => {
                            this.loading = false;
                            this.successMsg = response.data.Message;
                            this.formData.IsAgreementPopupToShow = false;

                            setTimeout(() => {
                                self.successMsg = '';
                            }, 3000);
                        })
                        .catch(error => {
                            this.loading = false;
                            this.successMsg = '';
                            this.errorMsg = error.response.data.Message;
                        });
                }
            },
            selectAll() {
                this.formData.Agreements.IsAccountAgreementDefinition = this.allSelected;
                this.formData.Agreements.IsEmailEurocashGettingInfo = this.allSelected;
                this.formData.Agreements.IsEmailEurocashGroupGettingInfo = this.allSelected;
                this.formData.Agreements.IsEmailEurocashPartnersGettingInfo = this.allSelected;
                this.formData.Agreements.IsRegulation = this.allSelected;
                this.formData.Agreements.IsSmsEurocashGettingInfo = this.allSelected;
            },
            newRegulationPopup() {
                let self = this;
                self.formData.IsNewRegulationPopupToShow = false;
                self.$http
                    .post(`spa/user/NewRegulationPopupAccept`)
                    .then(() => { })
                    .catch(() => { });
            },

            disableTenYearsPopupfile() {

                let self = this;
                this.formData.IsActiveTenYearsPopup = false;
                self.$http
                    .get(`spa/user/disabletenyearspopup`)
                    .then(() => { })
                    .catch(() => { });


            }
        },
        created: function () { },
    };
</script>

<style scoped lang="scss">
    @import 'sass-rem';
    @import './../../../assets/scss/variables';
    @import '~bulma/sass/utilities/mixins';

    .homepage-user {
        margin-top: 1.25rem;
        padding-bottom: rem(40px);
        .homepage-user-welcome

    {
        position: relative;
        & + .columns

    {
        margin-top: rem(30px);
    }

    button {
        position: absolute;
        right: -5px;
        top: -5px;
        background-color: rgba(10, 10, 10, 0.7);
        &:hover

    {
        background-color: rgba(10, 10, 10, 0.5);
    }

    }
    }

    .button {
        padding: rem(20px 50px);
        float: left;
    }

    .is-dotted {
        &:before

    {
        bottom: 7px;

        @include tablet {
            bottom: 12px;
        }
    }

    }

    .card {
        height: 100%;
        &-content

    {
        overflow: hidden;
        padding: rem(30px 20px 20px);

        @include tablet {
            padding: rem(30px 40px);
        }

        .media-content > p

    {
        clear: both;
        &:first-child

    {
        margin-bottom: rem(25px);
        float: left;
        & + a

    {
        float: right;

        @include tablet {
            margin-top: -6px;
        }
    }

    }
    }

    .is-small.delete {
        height: 21px;
        max-height: 21px;
        max-width: 21px;
        min-height: 21px;
        min-width: 21px;
        width: 21px;
        margin-top: -2px;
    }

    h2 {
        margin-bottom: rem(30px);
    }

    h3 {
        margin-bottom: rem(20px);
        clear: left;
    }

    p {
        cursor: pointer;
    }

    svg {
        color: $green;
    }

    }
    }

    .progress {
        margin: rem(15px 0 35px);
    }

    &-welcome {
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.03);
        & > :first-child

    {
        background: $green-lighter;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;

        @include tablet {
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
        }

        & > div

    {
        padding: rem(20px 25px);

        @include tablet {
            padding: rem(25px 40px);
        }

        p

    {
        margin-bottom: 10px;
        letter-spacing: 1px;
    }

    }
    }

    & > :nth-child(2) {
        position: relative;
        &:before

    {
        content: '';
        width: 0;
        height: 0;
        border-top: 14px solid transparent;
        border-right: 20px solid white;
        border-bottom: 14px solid transparent;
        position: absolute;
        margin-top: -15px;
        top: -5px;
        left: 50%;
        transform: rotate(90deg);
        margin-left: -15px;
    }

    @include tablet {
        &:before {
            top: 50%;
            left: -5px;
            transform: rotate(0deg);
        }
    }

    & > div {
        background: $white;
        padding: rem(25px 20px);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        @include tablet {
            padding: rem(40px);
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0;
        }
    }

    }
    }

    &-workshop {
        border-bottom: solid 1px $grey-lightest;
        padding-bottom: rem(15px);
        padding-top: rem(13px);
        clear: both;
        width: 100%;
        display: block;
        &:last-of-type

    {
        padding-bottom: 0;
        border-bottom: 0;
    }

    p {
        cursor: pointer;
    }

    }

    .modal {
        &-form

    {
        & > :nth-child(2)

    {
        padding: rem(10px 10px 20px);

        @include tablet {
            padding: rem(10px 30px 30px);
        }
    }

    .control {
        margin-bottom: rem(30px);
        .input__container

    {
        margin-bottom: 0;
    }

    }

    .form-actions {
        justify-content: center;
        margin-top: rem(50px);
        & > :first-child

    {
        margin-right: rem(20px);
    }

    .button {
        padding: 0;

        @include tablet {
            padding: rem(0 30px);
        }
    }

    }

    h2 {
        margin-bottom: rem(5px);
    }

    p {
        line-height: 1.36;
        letter-spacing: 0.3px;
    }

    label + ol {
        padding-left: rem(50px);
        margin: rem(10px 0);
    }

    ol {
        padding-left: rem(20px);
        margin-bottom: rem(10px);
    }

    .input_wraps {
        padding: rem(10px 0);

        @include tablet {
            padding: rem(10px 150px);
        }

        .control

    {
        p

    {
        margin-bottom: 0;
    }

    }
    }

    .rodo-wrap {
        label

    {
        display: block;
        margin: -10px 0 15px;
        input

    {
        margin-right: 10px;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }

    }
    }

    .b-checkbox.checkbox.is-small {
        & > :nth-child(2)

    {
        border: 1px solid $grey-light;
        border-radius: 0;
    }

    }
    }

    .columns {
        flex-wrap: wrap;
    }

    .button {
        color: rgba(58, 171, 89, 0.5);
        border-radius: 5px;
        height: 50px;
        width: 100%;
        &:not(:last-child)

    {
        margin-bottom: rem(20px);
    }

    span {
        color: white;
    }

    .icon {
        margin-right: 5px;
    }

    &.is-danger {
        color: rgba(215, 33, 65, 0.5);
    }

    }
    }

    .req-check {
        font-size: 11px;
        color: $red;
    }
    }
</style>
