<template>
        <div class="column is-full has-text-left" v-if="implementationprograms.length && implementationprograms.length > 0">
            <div class="card has-background-info">
                <!-- Card header -->
                <div class="card-header">
                    <IconList />
                    <p class="card-header-title is-size-2 is-size-4-tablet has-text-left has-text-weight-light has-text-white">
                        Realizowany program wdrożeniowy
                    </p>
                </div>
                <!-- END | Card header -->
            </div>
            <div class="card-table">
                <div class="table-container" v-for="(program, index) in implementationprograms" v-bind:key="index">
                    <table class="table">
                        <thead class="is-hidden-mobile">
                            <tr class="is-size-18 has-text-weight-semibold has-text-left">
                                <th class="has-text-grey-light">
                                    <div class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left pw__container">
                                        <div class="pw__progress">
                                            <vue-ellipse-progress :progress="program.PercentDone"
                                                                  :angle="-90"
                                                                  color="#6ddd8c"
                                                                  emptyColor="white"
                                                                  :size="100"
                                                                  :thickness="10"
                                                                  emptyThickness="10%"
                                                                  lineMode="normal"
                                                                  :legend="true"
                                                                  :legendValue="0"
                                                                  legendClass="legend-custom-style"
                                                                  animation="reverse 700 400"
                                                                  :noData="false"
                                                                  :loading="false"
                                                                  fontColor="white"
                                                                  :half="false"
                                                                  :gap="10"
                                                                   dot="0 green"
                                                                  fontSize="1rem">

                                                <span slot="legend-value">%</span>


                                            </vue-ellipse-progress>
                                        </div>
                                        <div class="pw__data-text">
                                            <div class="conferences-title-first">
                                                Programy szkoleniowe on-line
                                            </div>
                                            <div class="conferences-title">
                                                {{ program.Title }}
                                            </div>
                                            <div class="conferences-intro">
                                                <p class="has-text-weight-light">
                                                    {{program.Text}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </th>

                            </tr>
                            <tr v-if="isLoading">
                                <th colspan="4" class="is-progress-bar-table">
                                    <progress class="progress is-primary" max="100"></progress>
                                </th>
                            </tr>
                        </thead>

                        <tbody v-if="program.Weeks.length">
                            <tr v-for="(week, index) in program.Weeks"
                                :key="index">
                                <td class="has-text-black">
                                    <div class="row-container" :class="{ 'selected-week': week.ShowDays }">
                                        <vue-ellipse-progress :progress="week.PercentDone"
                                                              :angle="-90"
                                                              color="#3AAB59"
                                                              emptyColor="#90C89F"
                                                              :size="65"
                                                              :thickness="8"
                                                              emptyThickness="10%"
                                                              lineMode="normal"
                                                              :legend="true"
                                                              :legendValue="0"
                                                              legendClass="legend-custom-style"
                                                              animation="reverse 700 400"
                                                              :noData="false"
                                                              :loading="false"
                                                              fontColor="black"
                                                              :half="false"
                                                              fontSize="0.8rem">

                                            <span slot="legend-value">%</span>
                                        </vue-ellipse-progress>


                                        <span class="row-title">{{ week.Title }}</span>

                                        <button @click="showDays(week)"
                                                class="week-toggler is-size-2 is-size-4-tablet">

                                            <span class="chevron" :class="{ active: week.ShowDays }"></span>
                                        </button>
                                    </div>
                                    <br />
                                    <table class="full-width" v-if="week.ShowDays">
                                        <tbody v-if="week.Days.length">
                                            <tr v-for="(day, index) in week.Days"
                                                :key="index">
                                                <td class="has-text-black">
                                                    <div class="row-container">
                                                        <vue-ellipse-progress :progress="day.PercentDone"
                                                                              :angle="-90"
                                                                              color="#3AAB59"
                                                                              emptyColor="#E9F5EC"
                                                                              :size="65"
                                                                              :thickness="8"
                                                                              emptyThickness="10%"
                                                                              lineMode="normal"
                                                                              :legend="true"
                                                                              :legendValue="0"
                                                                              legendClass="legend-custom-style"
                                                                              animation="reverse 700 400"
                                                                              :noData="false"
                                                                              :loading="false"
                                                                              fontColor="black"
                                                                              :half="false"
                                                                              fontSize="0.8rem">

                                                            <span slot="legend-value">%</span>
                                                        </vue-ellipse-progress>


                                                        <div class="row-title">
                                                            <span>{{ day.Title }}</span>
                                                            <span>Progress {{ day.PercentDone }}%</span>
                                                        </div>
                                                        <button @click="showTasks(day)"
                                                                class="week-toggler is-size-2 is-size-4-tablet">

                                                            <span class="chevron" :class="{ active:  day.ShowTasks }"></span>
                                                        </button>
                                                    </div>
                                                    <br />

                                                    <table class="full-width" v-if="day.ShowTasks">
                                                        <tbody v-if="day.Tasks.length">
                                                            <tr v-for="(task, index) in day.Tasks"
                                                                :key="index">
                                                                <td class="has-text-black is-flex">
                                                                    <div class="task is-flex">
                                                                        <template v-if="task.IsDone">
                                                                            <div v-if="task.IsCorrect" class="done-container">
                                                                                <div class="done"></div>
                                                                            </div>
                                                                            <div v-else class="failed-container">
                                                                                <div class="failed"></div>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else>
                                                                            <div class="unspecified-container">
                                                                                <div class="any"></div>
                                                                            </div>
                                                                        </template>

                                                                        <span>{{ task.Title }}</span>

                                                                        <button v-if="task.TastTypeDescription == 'Exam' && task.IsDone" @click="showExam(task)"
                                                                                class=" is-size-2 is-size-4-tablet">
                                                                            Zobacz odpowiedzi
                                                                        </button>
                                                                        <button v-if="task.TastTypeDescription == 'FormTask' && task.IsDone"
                                                                                @click="showForm(task)"
                                                                                class=" is-size-2 is-size-4-tablet">
                                                                            Zobacz odpowiedzi
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </td>

                            </tr>
                        </tbody>

                        <tbody v-else-if="!elearnings.length && !isLoading">
                            <tr>
                                <td colspan="5" class="has-text-centered">Brak danych</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <b-modal :active.sync="isExamDialog"
                     :width="820"
                     :canCancel="['escape', 'x', 'outside']"
                     scroll="keep"
                     class="modal-dialog exam">
                <button type="button" class="modal-close is-large" @click="isExamDialog=false;"></button>
                <br />
                <ExamDisplay :examId="examId" :userId="userId" />

            </b-modal>



            <b-modal :active.sync="isFormDialog"
                     :width="820"
                     :canCancel="['escape', 'x', 'outside']"
                     scroll="keep"
                     class="modal-dialog exam">
                <button type="button" class="modal-close is-large" @click="isFormDialog=false"></button>
                <br />
                <FormDisplay :formId="formId" :userId="userId" />

            </b-modal>



        </div>
</template>

<script>
    // JS modules
    //import { mapState } from 'vuex';
    import generatePDF from '../../../services/PDFService';

    // Vue components
    import IconCap from '@/assets/svg/cap.svg';
    import IconList from '@/assets/svg/list.svg';

    import ExamDisplay from '@/components/Containers/ViewImplementationProgram/ExamDisplay.vue';
    import FormDisplay from '@/components/Containers/ViewImplementationProgram/FormDisplay.vue';
    export default {
        components: {
            IconCap,
            IconList,
            ExamDisplay,
            FormDisplay,
        },

        props: {
            userId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                implementationprograms:[],
                isLoading: true,
                isExamDialog: false,
                isFormDialog: false,
                formId: 0,
                examId: 0,

            };
        },

        computed: {
         
        },

        methods: {
            loadData() {
                this.isLoading = true;
                let self = this;
               
                self.$http
                    .get(`/spa/ImplementationPrograms/userstats/${self.userId}`)
                    .then(response => {
                        self.implementationprograms = response.data;
                        self.isLoading = false;
                    })
                    .catch(() => { });
            },




            getTaskStateImage(task) {
                let imgPath = '';
                switch (task.TastTypeDescription) {
                    case 'ArticleToReadTask':                      
                    case 'DescriptionTask':                      
                    case 'ElearningTask':                     
                    case 'FormTask':                    
                    case 'VideoTask':

                        if (task.IsDone) {
                            imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
                        }
                        else {
                            imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
                        }

                        break;
                    case 'Exam':

                        if (task.IsDone) {

                            if (task.IsCorrect) {
                                imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
                            }
                            else {
                                imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
                            }

                        }
                        else {
                            imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
                        }

                      
                        break;
                    default:
                }

                return imgPath;
            },

            showExam(exam) {
                this.examId = exam.Id;
                this.isExamDialog = true;
            },
            showForm(form) {
                this.formId = form.Id;
                this.isFormDialog = true;
            },  
            showDays(week) {
                week.ShowDays = !week.ShowDays;
            },
            showTasks(day) {
                day.ShowTasks = !day.ShowTasks;
            },

            generatePDF(elearningId) {
                generatePDF(this.userId, elearningId);
            },
        },

        created() {
            this.loadData();
        },

        watch: {
            userId() {
                this.loadData();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .full-width {
    width:100%;
    }

    td.is-flex {
        align-items: center;
    }

    .task.is-flex {
        align-items: center;
        width: 100%;
        border-top: 1px solid #F2F2F2;
        padding: 10px 0 10px 0;
    }

    .done-container {
        margin-left: 100px;
        display: inline-block;
        background: rgba(58, 171, 89, 0.1);
        width: 39px;
        height: 39px;
        position: relative;
        margin: 0;
        .done
        {
            position: absolute;
            top: 15%;
            left: 40%;
            transform: translate(-50%, -50%);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            height: 20px;
            width: 10px;
            border-bottom: 3px solid #3AAB59;
            border-right: 3px solid #3AAB59;
        }
    }

    .unspecified-container {
        margin-left: 100px;
        display: inline-block;
        background: rgba(74, 74, 74, 0.18);
        width: 39px;
        height: 39px;
        position: relative;
        margin: 0;
        .any
        {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 0px;
            width: 18px;
            border-bottom: 3px solid #707070;
        }
    }

    .failed-container {
        margin-left: 100px;
        display: inline-block;
        background: rgba(255, 68, 81, 0.2);
        width: 39px;
        height: 39px;
        position: relative;
        margin: 0;
        .failed
        {
            position: absolute;
            right: 3px;
            top: 9px;
            width: 32px;
            height: 32px;
            
            &:before, &:after
            {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 20px;
                width: 2px;
                background-color: #ff4451;
            }

            &:before {
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:after {
                -ms-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
    }
    

    .selected-week {
        background: rgba(58, 171, 89, 0.1);
        transition: background 0.3s ease;
    }

    .card {
        .card-header
        {
            padding: 10px 30px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .card-table .table thead tr th{
        padding: 0;
    }

    .card-table .table {
        border-collapse: collapse;
        > tbody > tr {
            line-height: 0;
        > td
        {
            border-top: 10px solid #F1F3F4;
            border-bottom: 10px solid #F1F3F4;
            background-clip: padding-box;
            padding: 0;
            > table{
                > tbody{
                         > tr{
                               > td
    {
        border-top: 10px solid #F1F3F4;
        border-bottom: 10px solid #F1F3F4;
        background-clip: padding-box;
        padding: 0;
    }
                         }
                     }
            }
            .row-container
                {
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    padding: 0 45px;
                }
            

                span.row-title, div.row-title{
                    margin-left: 40px;
                    span{
                       display:block;
                    }
                    span:nth-child(2){
                        font-weight: bold;
                    }
                }
    span.row-title{
        text-transform: uppercase;
        font-weight: bold;
    }
    div.row-title {
        line-height: 20px;
    }
    button {
        margin-left: auto;
        margin-right: 0;
        outline: none;
        .chevron:before, .chevron:after
                    {
                        background-color: #3AAB59;
                    }
                 }
            }
        }
        .ep-container{
            margin: 7px 0;
        }

        table{
            table{
                margin-top: 10px;
                td{
                    border: none;
                    box-sizing: padding-box;
                    padding: 0 20px 0 100px;
                    span{
                        display: inline-block;
                        margin-left: 15px;
                        line-height: 25px;
                    }
                    button {
                        float: right;
                        font-weight: bold;
                        color: white;
                        background-color: #3AAB59;
                        border: none;
                        font-size: 14px !important;
                        padding: 10px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .table-container {
        margin: 40px 60px;
        .pw__container
        {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
        }
    .pw__progress {
        width: 210px;
        height: 210px;
        position: relative;
        background-color: #3AAB59;
        display: inline-block;
        flex: 0 1 auto;
        .ep-container
            {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .pw__data-text {
            display: inline-block;
            background-color: white;
            flex: 1;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 20px 45px;
            > div{
                     width: 100%;
                 }
            .conferences-title-first
            {
                font-size: 14px;
                font-weight: bold;
                color: #3AAB59;
            }
            .conferences-title {
                font-size: 24px;
                font-weight: bold;
                color: #4A4A4A;
            }
            .conferences-intro {
                font-size: 16px;
                color: #4A4A4A;
            }
        }
    }

    .week {
        &-header

    {
        padding: 10px 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    &-toggler {
        background: transparent;
        border: none;
        border-radius: 5px;
        color: black;
        padding: 5px 15px;
        margin: auto;
        float: right;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        &:hover

    {
        cursor: pointer;
    }

    // Create the chevron icon.
    .chevron {
        position: relative;
        width: 25px;
        margin-left: 15px;
        color: black;
        &::before, &::after

    {
        content: '';
        position: absolute;
        top: 50%;
        background-color: black;
        width: 15px;
        height: 2px;
        border-radius: 1px;
        transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &::before {
        right: 44%;
        transform: rotate(30deg);
    }

    &::after {
        left: 44%;
        transform: rotate(-30deg);
    }

    // Animate the chevron on click.
    &.active {
        &::before

    {
        transform: rotate(-30deg);
    }

    &::after {
        transform: rotate(30deg);
    }

    }
    }
    }
    }
</style>
