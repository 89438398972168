<template>
    <section class="conference guest section">
        <div class="conference-main-banner">
            <div class="conference-main-banner-mask"></div>
            <div class="container">
                <h1 class="is-size-13-tablet is-size-1 has-text-weight-light">
                    {{conference.Title}}
                    <br />
                    <span v-text="dateFormat"></span>
                    <span>{{conference.Localization}}</span>
                </h1>
            </div>
        </div>
        <div class="container">
            <a class="scroll-section has-scroll-to is-hidden-mobile" href="tresc">
                <img src="/assets/2018/svg/scroll-arrow.svg" />
            </a>
        </div>
        <div class="conference-intro" id="tresc">
            <div class="container small">
                <!--<div class="employer-red-info">
                        <p>INFORMACJE <span>DLA PRZEDSIĘBIORCÓW</span> PROWADZĄCYCH PRZEDSIĘBIORSTWO HANDLU DETALICZNEGO I <span>NIEZRZESZONYCH</span> W SIECIACH FRANCZYZOWYCH I PARTNERSKICH GRUPY EUROCASH</p>
                </div>-->
                <div class="employer-green-info">
                    <p>
                        <span>Jesteś przedsiębiorcą jednej z sieci franczyzowych/partnerskich Grupy Eurocash</span>
                        (ABC, Groszek, Euro Sklep, Lewiatan, Gama) zaloguj się na swoje konto na platformie edukacyjnej AUE  <a href="/#login">(link)</a>


                    </p>
                    <br />
                    <p>
                        Jeśli nie masz konta na platformie AUE, skontaktuj się z naszą infolinią  <a href="/kontakt/">(kliknij tutaj)</a>.
                    </p>
                </div>
                <div class="congress-participation">
                    <p>
                        Jesteś przedsiębiorcą prowadzącym sklep spożywczy <span>niezrzeszony</span> w sieci franczyzowej/partnerskiej Grupy Eurocash zgłoś swój udział w Kongresie wypełniając poniższy formularz rejestracyjny. W 2021 roku udział w wydarzeniu jest bezpłatny.
                    </p>

                    <p v-if="conference.ConferencePaymentEnabled">
                        <br />
                        Płatność za udział w Kongresie
                        <span>pobierana z góry</span> poprzez system PayU (płatność przelewem/kartą kredytową, itd.). Faktury będą wystawiane i wysyłane na adres podany w zgłoszeniu w ciągu 21 dni od dokonanej płatności.
                    </p>
                </div>
                <h2 class="is-size-2">{{conference.DescriptionTitle}}</h2>
                <div class="conference-start" v-if="!conference.IsCompleted">
                    <p class="is-size-2 has-text-weight-bold">Do konferencji pozostało:</p>
                    <counterToDate v-if="conference.Id > 0"
                                   :starttime="Date.now()"
                                   :endtime="conference.Date" />
                </div>
                <div class="conference-bck">
                    <img v-lazy="conference.ListImage" :alt="conference.Title" :title="conference.Title" />
                </div>
                <div class="conference-intro-txt" v-html="conference.Description"></div>
            </div>
        </div>
        <div v-if="conference && conference.Agenda && conference.Agenda.length>0" class="conference-schedule">
            <div class="container">
                <h2 class="is-size-2" v-if="conference.IsCompleted">Materiały z konferencji</h2>
                <h2 class="is-size-2" v-else>Program konferencji</h2>
                <div class="conference-schedule-wrap">
                    <div class="day-block" v-for="lectureDay in conference.Agenda" :key="lectureDay.Title">
                        <h3 class="has-text-weight-light is-size-1 has-text-centered">{{lectureDay.Title}}</h3>
                        <div class="card single-lecture"
                             v-for="(lectureBlock, index) in lectureDay.ConferenceDayPoints"
                             :key="index">
                            <div class="lecture-header">
                                <p class="lecture-time is-size-12 is-size-9-tablet" v-text="time(lectureBlock)"></p>
                                <p class="lecture-title has-text-weight-bold">{{lectureBlock.Description}}</p>
                            </div>
                            <div class="lecture-content" v-if="lectureBlock.Speaker.NameAndSurname">
                                <div class="lecture-img">
                                    <img v-lazy="lectureBlock.Speaker.Image"
                                         :alt="lectureBlock.Speaker.NameAndSurname"
                                         :title="lectureBlock.Speaker.NameAndSurname" />
                                </div>
                                <div class="lecture-txt">
                                    <h3 class="is-size-12">{{lectureBlock.Speaker.NameAndSurname}}</h3>
                                    <p class="is-size-7 has-text-weight-light"
                                       v-html="lectureBlock.Speaker.Description"></p>
                                    <!--<button class="show-more" @click="isOpen = !isOpen" v-text="isOpen ? 'Zwiń' : 'Przeczytaj więcej'"></button>
                                                  <b-collapse :open.sync="isOpen">
                                                      <p>{{lectureBlock.Speaker.Description}}</p>
                                    </b-collapse>-->
                                    <div class="download-see is-size-4">
                                        <p v-if="lectureBlock.Presentation">
                                            <a v-bind:href="lectureBlock.Presentation" download>Pobierz prezentację</a>
                                        </p>
                                        <p v-if="lectureBlock.Video">
                                            <a v-bind:href="lectureBlock.Video"
                                               target="_blank">Obejrzyj video z wystąpienia</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="conf-sign" id="Zapisz">
            <div class="container small">
                <div class="conf-for-who"
                     v-if="conference.ForWorkersOptions && conference.ForWorkersOptions.length > 0">
                    <h2 class="is-size-2">
                        <!--Udział w konferencji dla Franczyzobiorców, Kierowników Sklepów, Zastępców Kierowników oraz Pracowników Sklepów<br />
        jest BEZPŁATNY.<br />-->
                        Rejestracja na kongres trwa do <b>{{dateFormatFormTo}} r.</b>
                    </h2>

                    <h2 class="is-size-2">Do udziału w kongresie zapraszamy:</h2>
                    <div class="columns">
                        <div class="column" v-for="(who, index) in conference.ForWorkersOptions" :key="index">
                            <div class="card" v-if="who === 'Właściciel Sklepu'">
                                <img src="/assets/2018/svg/employee-woman.svg" />
                                Właścicieli sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Kierownik Sklepu'">
                                <img src="/assets/2018/svg/employee-man.svg" />
                                Kierowników sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Pracownik Sklepu'">
                                <img src="/assets/2018/svg/employee-young-woman.svg" />
                                Pracowników sklepów
                            </div>
                            <div class="card" v-else-if="who === 'Operacja'">
                                <img src="/assets/2018/svg/employee-woman.svg" />
                                Operacja
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 class="is-size-2 has-text-danger">
                        ZAPISU NA KONGRES MOŻE DOKONAĆ JEDYNIE WŁAŚCICIEL/KIEROWNIK SKLEPU.
                    </h2>
                </div>



                <div class="conf-sign-form">
                    <div class="card">
                        <form class="form-wrap" @submit="submitForm">
                            <div class="sign-you">
                                <p class="is-size-16 is-size-1-tablet has-text-left">Zapisz się</p>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.Name.$error }"
                                       title="Imię"
                                       placeholder="Imię"
                                       value
                                       v-model="guest.Name"
                                       @blur="$v.guest.Name.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.Name)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.Surname.$error }"
                                       title="Nazwisko"
                                       placeholder="Nazwisko"
                                       value
                                       v-model="guest.Surname"
                                       @blur="$v.guest.Surname.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.Surname)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <select :class="{ 'has-error': $v.guest.WorkPosition.$error }"
                                        title="Stanowisko"
                                        v-model="guest.WorkPosition"
                                        @blur="$v.guest.WorkPosition.$touch()">
                                    <option value disabled selected>Stanowisko</option>
                                    <option>Właściciel</option>
                                    <option>Kierownik sklepu</option>
                                </select>
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.WorkPosition)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input numeric
                                       :class="{ 'has-error': $v.guest.PhoneNumber.$error }"
                                       title="Numer telefonu"
                                       placeholder="Numer telefonu"
                                       value
                                       v-model="guest.PhoneNumber"
                                       @blur="$v.guest.PhoneNumber.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.PhoneNumber)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input type="email"
                                       :class="{ 'has-error': $v.guest.Email.$error }"
                                       title="Adres email"
                                       placeholder="Adres email"
                                       value
                                       v-model="guest.Email"
                                       @blur="$v.guest.Email.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.Email)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <p class="is-size-16 is-size-1-tablet has-text-left">Twoja firma</p>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.CompanyName.$error }"
                                       title="Nazwa przedsiębiorstwa"
                                       placeholder="Nazwa przedsiębiorstwa"
                                       value
                                       v-model="guest.CompanyName"
                                       @blur="$v.guest.CompanyName.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.CompanyName)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input numeric
                                       :class="{ 'has-error': $v.guest.NIP.$error }"
                                       title="NIP firmy"
                                       min="0"
                                       placeholder="NIP firmy"
                                       value
                                       v-model="guest.NIP"
                                       @blur="$v.guest.NIP.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.NIP)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.CompanyStreet.$error }"
                                       title="Ulica"
                                       placeholder="Ulica"
                                       value
                                       v-model="guest.CompanyStreet"
                                       @blur="$v.guest.CompanyStreet.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.CompanyStreet)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.CompanyStreetNumber.$error }"
                                       title="Numer"
                                       placeholder="Numer"
                                       value
                                       v-model="guest.CompanyStreetNumber"
                                       @blur="$v.guest.CompanyStreetNumber.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.CompanyStreetNumber)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input :class="{ 'has-error': $v.guest.CompanyZIP.$error }"
                                       type="text"
                                       title="Kod pocztowy"
                                       placeholder="Kod pocztowy"
                                       value
                                       v-model="guest.CompanyZIP"
                                       @blur="$v.guest.CompanyZIP.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.CompanyZIP)"
                                          :key="index">{{ error }}</span>
                                </div>
                                <input type="text"
                                       :class="{ 'has-error': $v.guest.CompanyTown.$error }"
                                       title="Miasto"
                                       placeholder="Miasto"
                                       value
                                       v-model="guest.CompanyTown"
                                       @blur="$v.guest.CompanyTown.$touch()" />
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger"
                                          v-for="(error, index) in validationMessages($v.guest.CompanyTown)"
                                          :key="index">{{ error }}</span>
                                </div>

                                <p>Czy jesteś przedsiębiorcą jednej z sieci Grupy Eurocash</p>
                                <div class="guest-group">
                                    <label>
                                        <input type="radio"
                                               value="checked"
                                               v-model="guest.CompanyEurocash"
                                               name="eurogroup"
                                               @change="OnOptChange" />
                                        <span>Tak - podaj nazwę sieci</span>
                                    </label>
                                    <label>
                                        <input type="radio"
                                               value="unchecked"
                                               v-model="guest.CompanyEurocash"
                                               name="eurogroup"
                                               @change="OnOptChange" />
                                        <span>Nie</span>
                                    </label>
                                    <br />
                                    <br />
                                    <div class="input-msg" v-if="guest.CompanyEurocash==true">
                                        <span class="is-size-8 has-text-weight-light has-text-danger">Wypełnij wymagane pole.</span>
                                    </div>


                                    <select v-if="guest.CompanyEurocash=='checked'" v-model="guest.AffiliatedNetwork" name="cars" id="cars" form="carform"
                                            @blur="$v.guest.AffiliatedNetwork.$touch()">
                                        <!--<option value="Delikatesy Centrum">Delikatesy Centrum</option>-->
                                        <option value="Lewiatan">Lewiatan</option>
                                        <option value="Gama">Gama</option>
                                        <option value="Groszek">Groszek</option>

                                        <option value="Euro Sklep">Euro Sklep</option>
                                        <option value="ABC">ABC</option>
                                        <!--<option value="DuzyBen">Duży Ben</option>-->
                                        <!--<option value="Kontigo">Kontigo</option>-->
                                        <!--<option value="Koliber">Koliber</option>
                <option value="Detal+">Detal+</option>-->
                                    </select>
                                    <div v-if="guest.CompanyEurocash=='checked'" class="input-msg">
                                        <span class="is-size-8 has-text-weight-light has-text-danger"
                                              v-for="(error, index) in validationMessages($v.guest.AffiliatedNetwork)"
                                              :key="index">{{ error }}</span>
                                    </div>


                                    <!--<input v-if="guest.CompanyEurocash == true"
                   type="text"
                   :class="{ 'has-error': $v.guest.AffiliatedNetwork.$error }"
                   title="Podaj nazwę sieci"
                   placeholder="Podaj nazwę sieci"
                   value
                   v-model="guest.AffiliatedNetwork"
                   @blur="$v.guest.AffiliatedNetwork.$touch()" />
            <div v-if="guest.CompanyGroup == 'Outside'" class="input-msg">
                <span class="is-size-8 has-text-weight-light has-text-danger"
                      v-for="(error, index) in validationMessages($v.guest.AffiliatedNetwork)"
                      :key="index">{{ error }}</span>
            </div>-->
                                </div>





                                <!--<p>Czy firma jest zrzeszona w sieci franczyzowej innej niż sieci Grupy Eurocash?</p>
        <div class="guest-group">
            <label>
                <input type="radio"
                       value="Outside"
                       v-model="guest.CompanyGroup"
                       name="eurogroup"
                       @change="OnOptChange" />
                <span>Tak - podaj nazwę sieci</span>
            </label>
            <label>
                <input type="radio"
                       value="Inside"
                       v-model="guest.CompanyGroup"
                       name="eurogroup"
                       @change="OnOptChange" />
                <span>Nie</span>
            </label>
            <br />
            <br />
            <div class="input-msg" v-if="!companyGroupRadio">
                <span class="is-size-8 has-text-weight-light has-text-danger">Wypełnij wymagane pole.</span>
            </div>
            <input v-if="guest.CompanyGroup == 'Outside'"
                   type="text"
                   :class="{ 'has-error': $v.guest.AffiliatedNetwork.$error }"
                   title="Podaj nazwę sieci"
                   placeholder="Podaj nazwę sieci"
                   value
                   v-model="guest.AffiliatedNetwork"
                   @blur="$v.guest.AffiliatedNetwork.$touch()" />
            <div v-if="guest.CompanyGroup == 'Outside'" class="input-msg">
                <span class="is-size-8 has-text-weight-light has-text-danger"
                      v-for="(error, index) in validationMessages($v.guest.AffiliatedNetwork)"
                      :key="index">{{ error }}</span>
            </div>
        </div>-->
                            </div>
                            <div class="sing-worker">
                                <p class="is-size-16 is-size-1-tablet has-text-left">
                                    Zapisz pracowników
                                    <br />
                                    <!--<span
              class="is-size-4-tablet is-size-7"
            >(każda dodatkowo zapisana osoba + 250 zł netto/os.)</span>-->
                                </p>
                                <div class="input-msg">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">
                                        Ważne! Jedna zgłoszona osoba = unikatowy adres email i unikatowy numer telefonu
                                        <br />Prosimy nie wpisywać tych samych adresów email i numerów telefonów do różnych zgłoszeń, ponieważ na podane dane wysyłane będą unikatowe hasła dostępu dla każdej osoby.
                                        <br />Podanie unikatowych danych kontaktowych dla pojedynczego uczestnika (imię, nazwisko, adres e-mail, telefon) jest warunkiem koniecznym do prawidłowego zarejestrowania się na kongres.
                                        <br />Adres email jest automatycznie loginem potrzebnym do udziału w konferencji online. Zapamiętaj go.
                                    </span>
                                </div>


                                <div v-for="(worker, index) in signworkers" class="worker-row" :key="index">
                                    <input type="text"
                                           :name="index"
                                           title="Imię"
                                           placeholder="Imię"
                                           value
                                           v-model="signworkers[index].Name"
                                           required />
                                    <input type="text"
                                           :name="index"
                                           title="Nazwisko"
                                           placeholder="Nazwisko"
                                           value
                                           v-model="signworkers[index].Surname"
                                           required />
                                    <select :name="index"
                                            v-model="signworkers[index].WorkPosition"
                                            required
                                            title="Stanowisko">
                                        <option value disabled selected>Stanowisko</option>
                                        <option>Właściciel</option>
                                        <option>Kierownik sklepu</option>
                                        <option>Zastępca kierownika</option>
                                        <option>Pracownik sklepu</option>
                                    </select>
                                    <!--:class="{ 'has-error': $v.guest.PhoneNumber.$error }"-->  <!--@blur="$v.guest.PhoneNumber.$touch()"-->
                                    <input type="number"
                                           maxlength="9"
                                           title="Numer telefonu"
                                           placeholder="Numer telefonu"
                                           value
                                           required
                                           v-model="signworkers[index].PhoneNumber" />
                                    <!--<div class="input-msg">
                <span class="is-size-8 has-text-weight-light has-text-danger"
                      v-for="(error, index) in validationMessages($v.guest.PhoneNumber)"
                      :key="index">{{ error }}</span>
            </div>-->
                                    <!--:class="{ 'has-error': $v.guest.Email.$error }"--> <!--@blur="$v.guest.Email.$touch()"-->
                                    <input type="email"
                                           title="Adres email"
                                           placeholder="Adres email"
                                           value
                                           required
                                           v-model="signworkers[index].Email" />
                                    <!--<div class="input-msg">
                <span class="is-size-8 has-text-weight-light has-text-danger"
                      v-for="(error, index) in validationMessages($v.guest.Email)"
                      :key="index">{{ error }}</span>
            </div>-->





                                </div>
                                <div class="worker-btns">
                                    <button type="button"
                                            class="button add-worker light is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
                                            v-on:click="signNewWorker">
                                        <AddFull />
                                        <span>Dodaj pracownika</span>
                                    </button>
                                    <button type="button"
                                            class="button remove-worker light is-size-7 is-fullwidth is-uppercase has-text-weight-bold"
                                            v-if="signworkers.length"
                                            v-on:click="removeNewWorkers">
                                        <RemoveIcon />
                                        <span>Usuń pracownika</span>
                                        an
                                    </button>
                                </div>
                                <p class="signworkers-clause is-size-7" v-if="signworkers && signworkers.length">
                                    Osoba zgłaszająca pracowników (dalej „Pracodawca”) zobowiązuje się do wykonania względem pracowników zgłaszanych do udziału w Kongresie, obowiązku informacyjnego zgodnie z art. 14 Rozporządzenia nr 679/2016 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/4/WE, w imieniu Spółki.
                                    <br />W celu wykonania zobowiązania, o którym mowa powyżej, Pracodawca niezwłocznie po wypełnieniu zgłoszenia, lecz nie później niż w terminie miesiąca przekaże wszystkim osobom, o których mowa w akapicie pierwszym, informacje określone w pełnej klauzuli informacyjnej, znajdującej się w Regulaminie Kongresu.
                                </p>
                            </div>
                            <div class="guest-regulation">
                                <label>
                                    <input type="checkbox"
                                           v-model="guest.IsRegulationsAgreement"
                                           @change="regulClick" />
                                    <span class="is-size-7">
                                        Akceptuję Regulamin V Kongresu Przedsiębiorców Polskiego Handlu (zapoznaj się
                                        <a href="/assets/pdf/RegulaminKongresAUE.pdf"
                                           target="_blank">tutaj>></a>)
                                    </span>
                                </label>
                                <br />
                                <div class="input-msg" v-if="!regulationsVal">
                                    <span class="is-size-8 has-text-weight-light has-text-danger">Wypełnij wymagane pole.</span>
                                </div>
                            </div>
                    
                            <div class="guest-regulation regulation-second">
                                <label>
                                    <input type="checkbox"
                                           v-model="guest.IsProcessingDataAgreement"
                                           />
                                    <span class="is-size-7">
                                        Wyrażam zgodę na przekazywanie przez Akademię Umiejętności Sp. z o.o. z siedzibą w Komornikach (62-052) przy ul. Wiśniowej 11 za pośrednictwem poczty elektronicznej (adres email podany w formularzu powyżej) jednorazowej informacji podsumowującej kongres.
                                        <br />Niezaznaczenie zgody oznacza, że nie otrzymasz materiału podsumowującego kongres, w którym znajdzie się wiele przydanych biznesowo informacji. Informujemy jednocześnie, że w każdej chwili możesz cofnąć udzieloną zgodę na przykład poprzez przesłanie mail na adres: iod_ec@eurocash.pl. Wycofanie zgody pozostaje bez wpływu na zgodność z prawem wysyłanych dotychczas informacji marketingowych.
                                    </span>
                                </label>
                            </div>
                            <br />
                            <p class="is-size-7">
                                Współadministratorami Pani/Pana danych osobowych są Akademia Umiejętności sp. z o.o. z siedzibą w Komornikach oraz inne spółki z Grupy Eurocash, których lista znajduje się w Regulaminie (dalej „Współadministratorzy”). Pani/Pana dane osobowe będą przetwarzane w celach związanym z prawidłową organizacją i przeprowadzeniem Kongresu Przedsiębiorców Polskiego Handlu (dalej „Kongres”).
                                Uzupełnienie pola z numerem telefonu oraz adresem email stanowi zgodę na przetwarzanie podanych danych osobowych. Zgodę można wycofać w każdym czasie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
                                Dodatkowe informacje o zasadach przetwarzania i ochrony Pani/Pana danych osobowych przez Współadministratorów oraz o przysługujących Pani/Panu prawach dostępne są w Regulaminie Kongresu pod adresem: zapoznaj się
                                <a href="/assets/pdf/RegulaminKongresAUE.pdf"
                                   target="_blank">tutaj>></a>
                            </p>

                            <button :disabled="isSending"
                                    type="submit"
                                    class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold">
                                Wyślij zgłoszenie
                            </button>
                            <p class="is-size-8 has-text-weight-light has-text-danger"
                               v-if="submitStatus === 'ERROR'">Nie wszystkie wymagane pola zostały wypełnione poprawnie.</p>
                            <div v-if="isSending" class="loading">Wysyłanie...</div>

                            <div class="success_error column is-full" v-if="errorMsg || successMsg">
                                <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-danger has-text-centered"
                                   v-if="errorMsg">{{ errorMsg }}</p>
                                <p class="is-size-7 has-text-weight-bold has-text-weight-light has-text-primary has-text-centered"
                                   v-if="successMsg">{{ successMsg }}</p>
                            </div>
                        </form>
                    </div>
                    <!--<div class="conf-reg-info has-text-weight-light">
                        <p class="has-text-left">
                            Kiedy tylko otrzymamy autoryzację Twojej płatności, wyślemy na Twój adres e-mail bilety w wersji elektronicznej wraz z innymi szczegółami organizacyjnymi.
                            Fakturę otrzymasz na adres określony w formularz rejestracyjnym w ciągu 21 dni od dokonania płatności.
                        </p>
                    </div>-->
                </div>
            </div>
        </div>
        <b-modal :active.sync="ConferenceGuestSummary"
                 :canCancel="[]"
                 class="modal-form modal-dialog conference-summary"
                 :width="830"
                 scroll="keep">
            <button type="button" class="modal-close is-large" @click="ConferenceGuestSummary = false"></button>
            <p class="has-text-centered has-text-weight-bold is-size-1">Podsumowanie</p>
            <div class="guest-info-wrap">
                <div class="guest-info">
                    <p class="has-text-weight-bold">Dane zgłaszającego:</p>
                    <p>
                        Imię:
                        <span>{{summary.Name}}</span>
                    </p>
                    <p>
                        Nazwisko:
                        <span>{{summary.Surname}}</span>
                    </p>
                    <p>
                        Stanowisko:
                        <span>{{summary.WorkPosition}}</span>
                    </p>
                    <p>
                        Adres email:
                        <span>{{summary.Email}}</span>
                    </p>
                    <p>
                        Telefon:
                        <span>{{summary.PhoneNumber}}</span>
                    </p>
                </div>
                <div class="guest-company-info">
                    <p class="has-text-weight-bold">Firma:</p>
                    <p>
                        Nazwa:
                        <span>{{summary.CompanyName}}</span>
                    </p>
                    <p>
                        NIP:
                        <span>{{summary.NIP}}</span>
                    </p>
                    <p>
                        Ulica:
                        <span>{{summary.CompanyStreet}}</span>
                    </p>
                    <p>
                        Numer:
                        <span>{{summary.CompanyStreetNumber}}</span>
                    </p>
                    <p>
                        Kod pocztowy:
                        <span>{{summary.CompanyZIP}}</span>
                    </p>
                    <p>
                        Miasto:
                        <span>{{summary.CompanyTown}}</span>
                    </p>
                    <p>
                        Sieć:
                        <span>{{summary.AffiliatedNetwork}}</span>
                    </p>
                </div>
            </div>

            <p class="has-text-centered has-text-weight-semibold">
                Liczba zgłoszonych osób:
                <span class="has-text-weight-bold is-size-3">{{summary.PersonsCount}}</span>
            </p>
            <div v-if="conference.ConferencePaymentEnabled">
                <p class="has-text-centered has-text-weight-semibold">Suma zamówienia:</p>
                <p class="has-text-centered has-text-weight-semibold">
                    Łączna kwota netto:
                    <span class="has-text-weight-bold is-size-3">{{summary.NetPrice}} złotych</span>
                </p>
                <p class="has-text-centered has-text-weight-semibold">
                    Łączna kwota brutto:
                    <span class="has-text-weight-bold is-size-3">{{summary.GrossPrice}} złotych</span>
                </p>
                <button @click="guestPay"
                        class="guest-pay is-size-7-em has-text-weight-semibold is-uppercase">
                    Przejdź do płatności
                </button>
            </div>
            <div v-else>
                <button @click="sendFormToServer"
                        v-if="!isSending"
                        class="guest-pay is-size-7-em has-text-weight-semibold is-uppercase">
                   Wyślij
                </button>

            </div>

        </b-modal>
        <b-modal :active.sync="ConferenceGuestPayThx"
                 :canCancel="[]"
                 class="modal-form modal-dialog conference-summary"
                 :width="830"
                 scroll="keep">
            <button type="button" class="modal-close is-large" @click="ConferenceGuestPayThx = false"></button>
            <p v-if="conference.ConferencePaymentEnabled" class="has-text-centered has-text-weight-bold is-size-1">Dziękujemy za rejestrację !</p>
            <p v-if="conference.ConferencePaymentEnabled">
                Kiedy tylko otrzymamy autoryzację Twojej płatności, wyślemy na Twój adres e-mail bilety w wersji elektronicznej wraz z innymi szczegółami organizacyjnymi.
                Fakturę otrzymasz na adres określony w formularz rejestracyjnym w ciągu 21 dni od dokonania płatności.
            </p>
            <p v-else>
                Dziękujemy za potwierdzenie udziału w V Kongresie Przedsiębiorców Polskiego Handlu. Kongres odbędzie się 29 września 2021 roku w formule online. Na wskazany adres mail podczas zgłoszenia, zostanie wysłane potwierdzenie rejestracji.
                <!--Kiedy tylko otrzymamy autoryzację Twojej płatności, wyślemy na Twój adres e-mail bilety w wersji elektronicznej wraz z innymi szczegółami organizacyjnymi.
    Fakturę otrzymasz na adres określony w formularz rejestracyjnym w ciągu 21 dni od dokonania płatności.-->
            </p>
            <button @click="ConferenceGuestPayThx = false"
                    class="guest-pay thx is-size-7-em has-text-weight-semibold is-uppercase">
                Zamknij
            </button>
        </b-modal>

        <b-modal :active.sync="IsPayUError"
                 :canCancel="[]"
                 class="modal-form modal-dialog conference-summary"
                 :width="830"
                 scroll="keep">
            <button type="button" class="modal-close is-large" @click="IsPayUError = false"></button>
            <p class="has-text-centered has-text-weight-bold is-size-1">Wystąpił błąd!</p>
            <p class="has-text-centered">Rejestracja nie powiodła się. Prawdopodobnie Twoja płatność została anulowana.</p>
            <p class="has-text-centered">Spróbuj ponownie później lub skontaktuj się z infolinią.</p>
            <button @click="IsPayUError = false"
                    class="guest-pay thx is-size-7-em has-text-weight-semibold is-uppercase">
                Zamknij
            </button>
        </b-modal>
    </section>
</template>

<script>
    import NoSpace from '@/assets/svg/occupied.svg';
    import Material from '@/assets/svg/single-folded.svg';
    import BaseCardConference from '@/components/BaseCardConference.vue';
    import CounterToDate from '@/components/CounterToDate.vue';
    import AddIcon from '@/assets/svg/circle-add.svg';
    import RemoveIcon from '@/assets/svg/circle-remove.svg';
    import AddFull from '@/assets/svg/add-full.svg';
    import global from '@/mixins/global.js';
    import VueLazyLoad from 'vue-lazyload';

    import {
        required,
        email,
        numeric,
        minLength,
        maxLength,
    } from 'vuelidate/lib/validators';
    import { helpers } from 'vuelidate/lib/validators';

    const zipCode = helpers.regex('zipCode', /[0-9]{2}-[0-9]{3}/);

    export default {
        name: 'ConferenceGuest',
        mixins: [global],
        components: {
            NoSpace,
            Material,
            BaseCardConference,
            CounterToDate,
            AddIcon,
            AddFull,
            RemoveIcon,
            VueLazyLoad,
        },
        mounted: function () {
            let self = this;
            self.resetData();
            self.$http
                .get(`spa/unauthorized/getNextConference`)
                .then(response => {
                    self.conference = response.data;
                    var payUError = self.$route.query.error;

                    var stringValue = self.$route.query.ConferenceGuestPayThx;
                    if (stringValue) {
                        var conferenceGuestPayThx =
                            stringValue.toLowerCase() == 'true' ? true : false;
                        if (payUError != null) {
                            self.IsPayUError = true;
                        } else {
                            self.ConferenceGuestPayThx = conferenceGuestPayThx;
                        }
                    }
                })
                .catch(() => { });
        },
        data() {
            return {
                IsPayUError: false,
                isOpen: false,
                isSending: false,
                ConferenceGuestSummary: false,
                ConferenceGuestPayThx: false,
                conference: {},
                summary: {},
                submitStatus: null,
                output: null,
                companyGroupRadio: true,
                regulationsVal: true,
                url: '',
                playerVars: {
                    autoplay: 0,
                    showinfo: 0,
                    rel: 0,
                },
                isVideoPlaying: false,
                guest: {
                    Name: '',
                    Surname: '',
                    WorkPosition: '',
                    PhoneNumber: '',
                    Email: '',
                    CompanyName: '',
                    NIP: '',
                    CompanyStreet: '',
                    CompanyStreetNumber: '',
                    CompanyZIP: '',
                    CompanyTown: '',
                    CompanyGroup: false,
                    CompanyEurocash:'',
                    IsRegulationsAgreement: false,
                    IsProcessingDataAgreement:false,
                    AffiliatedNetwork: '',
                    ConferenceId: this.$route.params.id,
                    IsSubscriber: true
                },
                signworkers: [],
                allWorkers: [],
                errorMsg: '',
                successMsg: '',
            };
        },
        validations() {
            if (this.guest.CompanyGroup === 'Outside' || this.guest.CompanyEurocash == 'checked') {
                return {
                    guest: {
                        Name: {
                            required,
                            minLength: minLength(2),
                        },
                        Surname: {
                            required,
                            minLength: minLength(2),
                        },
                        WorkPosition: {
                            required,
                        },
                        PhoneNumber: {
                            numeric,
                            minLength: minLength(9),
                            maxLength: maxLength(9),
                            required,
                        },
                        Email: {
                            required,
                            email,
                        },
                        CompanyName: {
                            required,
                            minLength: minLength(2),
                        },
                        NIP: {
                            required,
                            minLength: minLength(10),
                            maxLength: maxLength(10),
                            numeric,
                        },
                        CompanyStreet: {
                            required,
                            minLength: minLength(2),
                        },
                        CompanyStreetNumber: {
                            required,
                        },
                        CompanyZIP: {
                            required,
                            zipCode,
                            minLength: minLength(6),
                        },
                        CompanyTown: {
                            required,
                            minLength: minLength(2),
                        },
                        AffiliatedNetwork: {
                            required,
                            minLength: minLength(2),
                        },
                    },
                };
            } else {
                return {
                    guest: {
                        Name: {
                            required,
                            minLength: minLength(2),
                        },
                        Surname: {
                            required,
                            minLength: minLength(2),
                        },
                        WorkPosition: {
                            required,
                        },
                        PhoneNumber: {
                            numeric,
                            minLength: minLength(9),
                            maxLength: maxLength(9),
                            required,
                        },
                        Email: {
                            required,
                            email,
                        },
                        CompanyName: {
                            required,
                            minLength: minLength(2),
                        },
                        NIP: {
                            required,
                            minLength: minLength(10),
                            maxLength: maxLength(10),
                            numeric,
                        },
                        CompanyStreet: {
                            required,
                            minLength: minLength(2),
                        },
                        CompanyStreetNumber: {
                            required,
                        },
                        CompanyZIP: {
                            required,
                            zipCode,
                            minLength: minLength(6),
                        },
                        CompanyTown: {
                            required,
                            minLength: minLength(2),
                        },
                    },
                };
            }
        },
        methods: {
            regulClick() {
                if (this.guest.IsRegulationsAgreement) {
                    this.regulationsVal = true;
                } else {
                    this.regulationsVal = false;
                }
            },
            OnOptChange() {
                this.companyGroupRadio = true;
            },
            playVideoHandler() {
                this.isVideoPlaying = true;
                this.player.playVideo();
            },
            endVideoHandler() {
                this.isVideoPlaying = false;
            },
            resetData() {
                let self = this;
                self.conference = {};
            },
            time(cdp) {
                let dateArray = cdp.Hour.split(' ');
                let timeArray = dateArray[1].split(':');
                let str = '';
                return str.concat(timeArray[0], ':', timeArray[1]);
            },
            clearForm() {
                for (let field in this.guest) {
                    this.guest[field] = '';
                }
            },
            signNewWorker: function () {
                let worker = {
                    Name: '',
                    Surname: '',
                    WorkPosition: '',
                    PhoneNumber: '',
                    Email: '',
                    CompanyName: '',
                    NIP: '',
                    CompanyStreet: '',
                    CompanyStreetNumber: '',
                    CompanyZIP: '',
                    CompanyTown: '',
                    CompanyGroup: false,
                    IsRegulationsAgreement: false,
                    AffiliatedNetwork: '',
                    ConferenceId: this.$route.params.id,
                };
                this.signworkers.push(worker);
            },
            removeNewWorkers: function () {
                if (this.signworkers.length > 0) {
                    this.signworkers.pop();
                }
            },
            guestPay() {
                this.$http
                    .get(
                        'spa/ConferenceSubscription/Payment?groupGuid=' +
                        this.summary.GroupGuid
                    )
                    .then(response => {
                        self.url = response.data.RedirectUrl;
                        window.location.href = self.url;
                    })

                    .catch(ex => {
                        console.log(ex);
                        this.errorMsg = 'Wystąpił błąd';
                    });
            },
            submitForm(evt) {
                evt.preventDefault();
                this.submitStatus = '';
                this.$v.$touch();
                //if (!this.guest.CompanyGroup) {
                //    this.companyGroupRadio = false;
                //    this.submitStatus = 'ERROR';
                //}
                if (!this.guest.IsRegulationsAgreement) {
                    this.regulationsVal = false;
                    this.submitStatus = 'ERROR';
                }
                if (this.$v.$invalid || this.submitStatus === 'ERROR') {
                    this.submitStatus = 'ERROR';
                } else {
                    this.submitStatus = '';
                    this.allWorkers = [];
                    this.allWorkers.push(this.guest);
                    this.allWorkers = [...this.allWorkers, ...this.signworkers];


                    if (this.conference.ConferencePaymentEnabled) {
                      
                        this.sendFormToServer();
                    }
                    else {

                        var subscriber = this.allWorkers[0];
                        this.summary = {
                            Name: subscriber.Name,
                            Surname: subscriber.Surname,
                            WorkPosition: subscriber.WorkPosition,
                            Email: subscriber.Email,
                            PhoneNumber: subscriber.PhoneNumber,
                            NIP: subscriber.NIP,
                            CompanyName: subscriber.CompanyName,
                            CompanyStreet: subscriber.CompanyStreet,
                            CompanyStreetNumber: subscriber.CompanyStreetNumber,
                            CompanyZIP: subscriber.CompanyZIP,
                            CompanyTown: subscriber.CompanyTown,
                            AffiliatedNetwork: subscriber.AffiliatedNetwork,
                            PersonsCount: this.allWorkers.length,
                            GrossPrice: 0,
                            NetPrice: 0,
                            GroupGuid: ""
                        }
                        this.ConferenceGuestSummary = true;
                    }




                    
                }
            },



            sendFormToServer() {
                this.isSending = true;
                let self = this;
                // Send form to server
                this.$http
                    .post('spa/ConferenceSubscription/AddUnauthorized', this.allWorkers)

                    .then(response => {
                       
                        self.clearForm();
                        self.$v.$reset();
                        self.companyGroupRadio = true;
                        self.regulationsVal = true;
                        self.isSending = false;

                        if (response.data.IsError) {
                            this.errorMsg = response.data.Message;
                        } else {
                            this.successMsg = response.data.Message;
                        }

                        if (this.conference.ConferencePaymentEnabled) {
                            self.summary = response.data.Data;
                            (this.ConferenceGuestSummary = true), console.log(response);
                        } else {
                            this.ConferenceGuestSummary = false;
                            this.ConferenceGuestPayThx = true;
                        }


                        setTimeout(() => {
                            this.successMsg = '';
                            this.errorMsg = '';
                        }, 5000);
                    })

                    .catch(ex => {
                        console.log(ex);
                        this.errorMsg = 'Wystąpił błąd';
                        setTimeout(() => {
                            this.successMsg = '';
                            this.errorMsg = '';
                        }, 5000);
                    });
            }

        },
        computed: {
            player() {
                return this.$refs.youtube.player;
            },
            dateFormat() {
                if (this.conference.Date) {
                    let dateArr = this.conference.Date.slice(0, -9).split('-');
                    return dateArr[2] + '.' + dateArr[1] + '.' + dateArr[0];
                }
            },
            dateFormatFormTo() {
                if (this.conference)
                    if (this.conference.FormVisibilityTo) {
                        let t = this.conference.FormVisibilityTo.indexOf('T')
                        let date = this.conference.FormVisibilityTo.slice(0, t);
                        let dateArr = date.split('-');
                        return dateArr[2] + '.' + dateArr[1] + '.' + dateArr[0];
                    }
            },

        },
    };
</script>

<style scoped lang="scss">
    @import 'sass-rem';
    @import './../../../assets/scss/variables';
    @import '~bulma/sass/utilities/mixins';

    .conference.guest {
        padding-top: 0;
        padding-bottom: 0;
        background-color: $white;
        .conference-main-banner

    {
        position: relative;
        background-image: url(/assets/2018/images/conference_guest.jpg);
        background-size: cover;
        height: 430px;
        background-position: bottom;
        margin: 5rem -1.25rem 2rem;
        padding: 0 2rem;

        @include desktop {
            padding: 0;
        }

        .conference-main-banner-mask

    {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.25);
    }

    .container {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    h1 {
        color: $white;
        max-width: 550px;
        span

    {
        font-weight: 600;
        font-size: 24px;

        @include tablet {
            font-size: 30px;
        }
    }

    }
    }

    .container.small {
        @include tablet {
            width: 729px;
        }
    }

    h2 {
        color: $black-real;
        text-align: center;
    }

    .employer-red-info {
        color: $white;
        background-color: $red;
        border-radius: 15px;
        font-size: 18px;
        font-weight: 300;
        padding: 20px 25px;
        margin-bottom: 30px;

        @include tablet {
            font-size: 25px;
            padding: 30px 35px;
        }

        span

    {
        font-weight: 700;
    }

    }

    .employer-green-info {
        border: 5px solid $green;
        border-radius: 15px;
        padding: 20px 25px;
        font-size: 25px;
        margin-bottom: 45px;
        font-size: 18px;

        @include tablet {
            font-size: 25px;
            padding: 25px 30px;
        }

        span

    {
        font-weight: 700;
        color: $green-lighter;
    }

    }

    .congress-participation {
        background-color: $green-lighter;
        border-radius: 13px;
        padding: 20px 25px 35px;
        color: $white;
        font-size: 18px;
        margin-bottom: 80px;

        @include tablet {
            font-size: 24px;
            padding: 25px 32px 45px;
        }

        span

    {
        font-weight: 700;
    }

    }

    .conference-intro {
        background-color: $white;
        padding: 2.5rem 1.25rem 2rem;
        margin: 0 -1.25rem;

        @include tablet {
            padding: 3rem 1.25rem 2rem;
        }

        .conference-bck

    {
        margin: 1.5rem 0;
        img

    {
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
    }

    }

    .conference-intro-txt {
        color: $black-real;
        font-weight: 300;
        line-height: 1.875rem;
    }

    .conference-start {
        text-align: center;
        margin-top: 1.5rem;
        > p

    {
        color: $black-real;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }

    .conference-counter {
        display: inline-block;
        vertical-align: middle;
    }

    }
    }

    .conference-schedule {
        padding: 2.25rem 0;
        margin: 0 -1.25rem;
        background-color: $grey-lightest;
        .conference-schedule-wrap

    {
        margin-top: 5rem;
        .day-block

    {
        margin-bottom: 2 rem;
        > h3

    {
        color: $black-real;
        margin-bottom: 2rem;
    }

    }

    .single-lecture {
        overflow: hidden;
        margin-bottom: 2.25rem;
        .lecture-header

    {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;

        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }

        .lecture-time

    {
        background-color: $green-lighter;
        color: $white;
        text-align: center;
        padding: 0.5rem;

        @include tablet {
            width: 146px;
            padding: 1rem 0;
        }
    }

    .lecture-title {
        color: $black-real;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0.5rem 1.8rem;
        text-align: center;

        @include tablet {
            text-align: left;
            padding: 0 2.5rem;
        }
    }

    }

    .lecture-content {
        color: $black-real;
        padding: 0 1.25rem 40px;
        margin-top: 1.25rem;

        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }

        @include widescreen {
            padding: 0 1.875rem 40px 146px;
        }

        .lecture-img

    {
        margin: 0 auto 2rem;
        width: 270px;
        height: 200px;
        max-width: 100%;

        @include tablet {
            margin-right: 40px;
            min-width: 270px;
        }

        img

    {
        border-radius: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    }

    .lecture-txt {
        text-align: center;

        @include tablet {
            text-align: left;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
    }

    .download-see {
        margin-top: 1.125rem;
        a

    {
        text-decoration: underline;
        color: $green-lighter;
        letter-spacing: 0.5px;
        margin-bottom: 0.4rem;
        display: inline-block;
    }

    }
    }
    }
    }

    .conf-video-wrap {
        margin-top: 50px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .single-conf-video

    {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0;
        margin-bottom: 40px;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;

        @include tablet {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 2rem;
        }

        @include widescreen {
            padding: 0 3.25rem;
        }

        > p

    {
        background-color: $white;
        padding: 1rem;
    }

    }

    .has-play-button {
        font-size: 0;
        margin-bottom: 2rem;
        > div

    {
        bottom: auto;
        height: auto;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        img

    {
        max-width: 100px;
    }

    }
    }
    }
    }

    .conf-sign {
        background-color: $white;
        margin: 0 -1.25rem;
        padding: 5rem 1.25rem 2rem;
        .conf-for-who

    {
        margin-bottom: 3.125rem;
        > h2

    {
        margin-bottom: 2rem;
    }

    .card {
        font-size: 1.125rem;
        font-weight: 400;
        text-align: center;
        color: $black;
        padding-top: 1.875rem;
        padding-bottom: 1.5rem;
        padding: 1.875rem 1rem 1.5rem;
        border: 1px solid #f2f2f2;
        img

    {
        display: block;
        margin: 0 auto 0.75rem;
        border-radius: 0;
    }

    }
    }

    .conf-sign-form {
        .card

    {
        margin: 4rem 0;
        border: 1px solid #f2f2f2;
        padding: 2.5rem 1rem 1.85rem;

        @include tablet {
            padding: 2.5rem 2.5rem 1.85rem;
        }

        p

    {
        color: $black-real;
    }

    .form-wrap {
        input

    {
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        padding: 1rem;
        font-size: 1rem;
        color: #686868;
    }

    select {
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        font-size: 1rem;
        padding: 1rem;
        color: #686868;
    }

    button[type='submit'] {
        color: $white;
        margin-top: 5rem;
        width: 100%;
        display: block;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        height: 56px;
        margin-bottom: 1.25rem;
        padding: 1rem;
        font-size: 1rem;
    }

    .sign-you {
        p

    {
        margin-bottom: 2rem;
    }

    }

    .sing-worker {
        margin-top: 2rem;
        > p

    {
        margin-bottom: 2rem;
    }

    .worker-row {
        margin: 2rem 0;
    }

    .worker-btns {
        @include tablet {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
        }

        button

    {
        display: inline-block;
        width: 100%;
        height: 50px;
        padding: 0 1rem;
        line-height: 44px;
        margin-top: 1rem;
        max-width: 100%;
        transition: 0.5s all ease-in-out;
        cursor: pointer;

        @include tablet {
            width: 290px;
            margin-top: 0.5rem;
        }

        &:hover

    {
        opacity: 0.7;
    }

    &.add-worker {
        color: $green-lighter;
        border: 2px solid $green-lighter;
    }

    &.remove-worker {
        color: $white;
        border: 2px solid $red;
        background-color: $red;
    }

    svg {
        vertical-align: middle;
        margin-right: 0.6rem;
    }

    span {
        vertical-align: middle;
        display: inline-block;
    }

    }
    }
    }

    .guest-group {
        label

    {
        display: inline-block;
        width: 45%;
        input

    {
        height: 14px;
        width: auto;
        margin-right: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    }

    > input {
        margin-top: 30px;
    }

    }

    .signworkers-clause {
        margin-top: 30px;
    }

    .guest-regulation {
        margin-top: 30px;
        label

    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        input

    {
        height: 14px;
        width: auto;
        margin-right: 15px;
        margin-top: 5px;
        margin-bottom: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    }
    }
    }
    }
    }

    .conf-reg-info {
        text-align: center;
        margin: 4rem 0 2rem;
        color: $black-real;
        strong

    {
        color: $black-real;
    }

    }
    }

    .input-msg {
        position: relative;
        top: -20px;
    }

    input.has-error,
    select.has-error {
        border-color: $red !important;
    }
    }
</style>
