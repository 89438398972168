/* eslint-disable */
<template>
	<article class="NameBox card">
		<div class="card-content is-paddingless">
			<div class="columns is-marginless">
				<!-- User name and surname -->
				<div class="NameBox__username column is-one-quarter">
					<PersonOvalIcon />
					<h1>
						<span class="NameBox__username-work-title">{{ workTitle }}</span>
						<br />
						<span class="NameBox__username-name">{{
							`${userFirstName} ${userLastName}`
						}}</span>
					</h1>
				</div>

				<!-- User data -->
				<dl class="NameBox__data column">
					<!-- First name -->
					<Field :value="get('FirstName')" name="Imię" id="FirstName" />

					<!-- Last name -->
					<Field :value="get('LastName')" name="Nazwisko" id="LastName" />

					<!-- E-mail address -->
					<Field :value="get('Email')" name="Adres e-mail" id="Email" />

					<!-- Phone number -->
					<Field :value="get('Phone')" name="Numer telefonu" id="Phone" />

					<!-- Position -->
					<Field :value="get('WorkTitle')" name="Stanowisko" id="WorkTitle" />

					<!-- Status -->
					<Field :value="get('Status')" name="Status" id="Status" />

					<!-- Branch -->
					<Field :value="get('Network')" name="Sieć" id="Network" />

					<!-- Shop code -->
					<Field
						v-if="shouldShowShopDetails"
						:value="get('ShopNumber')"
						name="Kod sklepu"
						id="ShopNumber"
					/>

					<!-- Shop sddress -->
					<Field
						v-if="shouldShowShopDetails"
						:value="get('ShopName')"
						name="Adres sklepu"
						id="ShopName"
					/>

					<!-- Supervisor -->
					<Field
						v-if="
							!isUser([
								'ShopManager',
								'ShopOwner',
								'TrainerUser',
								'NetworkAdminUser',
								'PartnerAdminUser',
								'KOUser',
								'RDOUser',
								'CAUser',
							])
						"
						:value="supervisor"
						name="Przełożony"
						id="Supervisor"
					/>

					<!-- ID number -->
					<Field
						v-if="
							!isUser([
								'NetworkAdminUser',
								'TrainerUser',
								'PartnerAdminUser',
								'KOUser',
								'RDOUser',
								'CAUser',
							])
						"
						:value="get('Id')"
						name="Numer ID pracownika"
						id="Id"
					/>
					<Field v-if="userData.Network == 'Detal+' ||
						   userData.Network == 'Eurocash' ||
						   userData.Network == 'CashAndCarry'"
						   :value="get('SapNo')" name="Sap" id="Sap" />
				</dl>
			</div>
		</div>
	</article>
</template>

<script>
import PersonOvalIcon from '@/assets/svg/person-oval.svg';
import Field from '@/components/Containers/ViewMyAccountData/components/Field.vue';

export default {
	components: {
		PersonOvalIcon,
		Field,
	},

	props: {
		userData: {
			type: Object,
			required: true,
		},
		isDataEditable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	computed: {
		userFirstName() {
			return this.userData.FirstName;
		},

		userLastName() {
			return this.userData.LastName;
		},

		workTitle() {
			return this.userData.WorkTitle;
		},

		supervisor() {
			return this.userData.SupervisorFirstName
				? `${this.userData.SupervisorFirstName} ${this.userData.SupervisorLastName}`
				: '';
		},

		shouldShowShopDetails() {
			const isRegularWorker = !this.isUser([
				'ShopOwner',
				'ShopManager',
				'NetworkAdminUser',
				'TrainerUser',
				'PartnerAdminUser',
				'KOUser',
				'RDOUser',
				'CAUser',
			]);

			const isShopOwnerOrManager = this.isUser(['ShopOwner', 'ShopManager']);

			const isOperations = this.isUser([
				'NetworkAdminUser',
				'TrainerUser',
				'PartnerAdminUser',
				'KOUser',
				'RDOUser',
				'CAUser',
			]);

			if (isRegularWorker) {
				return true;
			} else if (isShopOwnerOrManager) {
				return this.userData.ShopNumber ? true : false;
			} else if (isOperations) {
				return false;
			} else {
				return false;
			}
		},
	},

	methods: {
		/**
		 * @description This method will tell you whether the field should be printed
		 * or not. This depends on whether the passed-in field exists in the {userData} object.
		 * @param {String} field The field name which presence
		 * within the {userData} object should be checked.
		 * @returns {Boolean}
		 */
		shouldShow(field) {
			return this.userData[field] !== undefined;
		},

		/**
		 * @description This method will tell you if the user is part of a given users group.
		 * @param {String|Array} roles The role(s) to check the user against.
		 * @returns {Boolean}
		 */
		isUser(roles) {
			if (this.userData.Roles) {
				if (typeof roles === 'string') {
					return this.userData.Roles.includes(roles);
				} else if (Array.isArray(roles)) {
					for (let i = 0; i < roles.length; i++) {
						if (this.userData.Roles.includes(roles[i])) {
							return true;
						}
					}
					return false;
				}
			} else {
				return false;
			}
		},

		/**
		 * @description This method will return the string value of the passed-in field from
		 * the {userData} object. If it will encounter a 'null', the empty string will be returned.
		 * @param {String} field The field name which value is to be returned.
		 * @returns {String|Number}
		 */
		get(field) {
			return this.userData[field] ?? '';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/_variables.scss';

.NameBox {
	border-radius: 4px;
	margin-bottom: calc(1.875rem + 24px);

	&__username {
		background-color: $green-lighter;
		padding: 30px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;

		h1 {
			color: white;
			font-size: 30px;
			font-weight: $weight-light;
			letter-spacing: 0;
			text-align: center;
		}

		@media (min-width: 920px) {
			border-top-left-radius: 4px;
			border-top-right-radius: 0;
			border-bottom-left-radius: 4px;
			align-items: flex-start;
			justify-content: space-around;

			h1 {
				text-align: left;
			}
		}
	}

	&__data {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		background-color: white;
		padding: 30px;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-row-gap: 15px;
		// For larger screens.
		@media (min-width: 480px) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;
			grid-auto-flow: column;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(6, 1fr);
			grid-column-gap: 70px;
		}
	}
}
</style>
