<template>
  <section class="offer-edu section">
    <div class="offer-main-banner">
      <div class="offer-main-banner-mask"></div>
      <div class="container">
        <h1 class="is-size-13 has-text-weight-light">
          Oferta edukacyjna
          <br />Akademii
          <span>EUROCASH</span>
        </h1>
      </div>
    </div>
    <div class="container">
      <a class="scroll-section has-scroll-to is-hidden-mobile" href="tresc">
        <img src="/assets/2018/svg/scroll-arrow.svg" />
      </a>
    </div>
    <div class="offer-workshop-list">
      <div class="container">
        <h2 class="is-size-1 has-text-centered">{{eduworkshops.Title}}</h2>
        <div class="offer-intro has-text-weight-light has-text-centered">
          <p v-html="eduworkshops.Text"></p>
        </div>
        <div class="workshop-list" id="tresc">
          <div v-for="suboffer in eduworkshops.Data" :key="suboffer.Title">
            <div class="card has-text-centered">
              <WorkshopIcon />
              <h3 class="is-size-1">{{ suboffer.Title }}</h3>
              <div v-html="suboffer.Text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WorkshopIcon from "@/assets/svg/workshops.svg";

export default {
  name: "OfferEduWorkshops",
  components: {
    WorkshopIcon
  },
  mounted() {
    this.fetchWorkshops();
  },
  activated: function() {
    this.fetchWorkshops();
  },
  data() {
    return {
      eduworkshops: {}
    };
  },
  methods: {
    resetData() {
      let self = this;
      self.eduworkshops = {};
    },
    fetchWorkshops() {
      let self = this;
      self.resetData();
      let id = self.$route.hash;
      id = id.substring(1, id.length);
      self.$http
        .get(`/spa/eduoffer/getdetails/` + id)
        .then(response => {
          self.eduworkshops = response.data.Data;
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.offer-edu {
  background-color: $white;
  color: $black-real;
  padding-top: 0;

  .offer-main-banner {
    position: relative;
    background-image: url(/assets/2018/images/oferta_edukacyjna.jpg);
    background-size: cover;
    height: 430px;
    background-position: bottom;
    margin: 5rem -1.25rem 2rem;
    padding: 0 2rem;

    @include desktop {
      padding: 0;
    }

    .offer-main-banner-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }

    .container {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    h1 {
      color: $white;

      span {
        font-weight: 600;
      }
    }
  }

  .offer-tiles {
    margin: 3rem -0.875rem 0;

    @include tablet {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    > div {
      padding: 0 0.875rem 2rem;
    }

    .card {
      padding: 2rem;
      border: 1px solid #f2f2f2;
      color: $black-real;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      @include tablet {
        width: 160px;
        height: 100%;
        padding: 1rem;
      }

      @include desktop {
        width: 215px;
      }

      @include widescreen {
        width: 250px;
        height: 260px;
      }

      @include fullhd {
        width: 276px;
        height: 280px;
      }

      p {
        margin-top: 2rem;

        @include tablet {
          margin-top: 2rem;
        }

        @include desktop {
          margin-top: 3rem;
        }
      }
    }
  }

  .offer-tiles-long {
    margin-top: 6rem;

    @include widescreen {
      padding: 0 3rem;
    }

    article {
      color: $black-real;
      border: 1px solid #f2f2f2;
      margin-bottom: 2.25rem;

      @include tablet {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      .offer-bck {
        height: 200px;

        @include tablet {
          min-width: 250px;
          width: 250px;
        }

        @include desktop {
          min-height: 250px;
          min-width: 300px;
          width: 300px;
        }

        @include widescreen {
          min-height: 270px;
          min-width: 340px;
          width: 340px;
        }

        img {
          -o-object-fit: cover;
          object-fit: cover;
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0px;
          width: 100%;
          height: 100%;

          @include tablet {
            border-top-right-radius: 0px;
            border-bottom-left-radius: 5px;
          }
        }
      }

      .offer-content {
        padding: 1.5rem 1rem;

        @include tablet {
          padding: 1.5rem;
        }

        @include desktop {
          padding: 1.5rem 2.75rem;
        }

        > p {
          margin-bottom: 1.25rem;
        }

        > div {
          a {
            text-decoration: underline;
            text-transform: uppercase;
            color: $green;
          }
        }
      }

      &:hover,
      &:focus {
        background: $green-lighter;
        transition: background 0.3s ease;
        .offer-content {
          h2,
          p {
            color: $white;
          }
          .right-arrow-small-icon {
            color: $white;
          }
          ul {
            color: $white;
          }
          svg {
            color: $white;
          }
          .alert {
            color: $red;
          }
          a {
            color: $white;
          }
        }
      }
    }
  }
}

.offer-intro {
  margin: 1.5rem auto 0;

  @include desktop {
    width: 780px;
  }
}

.offer-workshop-list {
  color: $black-real;

  .workshop-list {
    margin: 1.5rem -1rem 0;

    @include tablet {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    > div {
      padding: 0 1rem;
    }

    .card {
      color: $black-real;
      border: 1px solid #f2f2f2;
      padding: 2rem 1rem;
      margin-bottom: 2.5rem;

      @include tablet {
        padding: 2rem 1.5rem;
        width: 350px;
      }

      @include desktop {
        padding: 3rem;
        width: 450px;
      }

      @include widescreen {
        padding: 3rem 4rem;
        width: 520px;
      }

      @include fullhd {
        padding: 3rem 5.5rem;
      }

      h3 {
        margin: 1rem 0 1.25rem;
      }

      ul {
        font-weight: 300;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
