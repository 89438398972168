<template>
	<article class="card" @click="$emit('goToUrlHandler')"  @mouseover="hover = true"
			@mouseleave="hover = false" :class="{ 'disabled': task.IsDone}">
		<div class="columns-wrap">
			<div class="mov-prev-col">
				<div class="movie-screen">
					<!--W zależności od typyu tasku trzeba dopasować ikony obrazki--> 
					<img :src="getTaskImage(task)" :alt="task.Title" :title="task.Title">
				</div>
			</div>
			<div class="task card-content">
				<div class="title-description">
					<span class="is-begin" v-if="!task.IsDone">Nierozpoczęte</span>
					<span class="is-finish" v-else>Zakończone</span>
					<h2 class="is-size-3 is-size-2-tablet">{{ task.Title }}</h2>
					<p class="has-text-left has-text-black-hover has-text-weight-light is-size-7 short-desc" v-html="descriptionLimited"></p>
				</div>
				<RightArrowSmallIcon class="right-arrow-small-icon" />
			</div>
		</div>
	</article>
</template>

<script>
import RightArrowSmallIcon from "@/assets/svg/right-arrow-small.svg";
import SmallCalendar from "@/assets/svg/small-calendar.svg";
import AlertSymbol from "@/assets/svg/alert_symbol.svg";
import global from "@/mixins/global.js";

export default {
	name: "BaseCardImplementationProgramTask",

	mixins: [global],

	components: {
		RightArrowSmallIcon,
		SmallCalendar,
		AlertSymbol
	},

	data() {
		return {
			hover: false
		};
	},

	props: ['task'],

	computed: {
		descriptionLimited() {
			return (this.task) ? this.task.Text : '';
		},
	},

	methods: {
		getTaskImage(task) {
			let imgPath = '';

			if (this.hover && !task.IsDone ) {
				switch (task.TastTypeDescription) {
					case 'ArticleToReadTask':
						if (task.Document) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%20127White.svg';
						}
						if (task.ArticleUrl) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%20129White.svg';
						}
						break;

					case 'DescriptionTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2097White.svg';
						break;

					case 'ElearningTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%20128White.svg';
						break;

					case 'FormTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2088-1White.svg';
						break;

					case 'VideoTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Icon%20awesome-videoWhite.svg';
						break;

					case 'Exam':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2088White.svg';
						break;
				}
			} else if (task.IsDone) {
				switch (task.TastTypeDescription) {
					case 'ArticleToReadTask':
						if (task.Document) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%20100.svg';
						}

						if (task.ArticleUrl) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%2048.svg';
						}                         
						break;

					case 'DescriptionTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2097.svg';
						break;

					case 'ElearningTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%20102.svg';
						break;

					case 'FormTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2093.svg';
						break;

					case 'VideoTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Icon%20awesome-video.svg';
						break;

					case 'Exam':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2088.svg';
						break;
				}
			} else {
				switch (task.TastTypeDescription) {
					case 'ArticleToReadTask':
						if (task.Document) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%20101.svg';
						}

						if (task.ArticleUrl) {
							imgPath = '/assets/2018/svg/implementationPrograms/Group%2086.svg';
						}                            
						break;

					case 'DescriptionTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2097-1.svg';
						break;

					case 'ElearningTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%20104.svg';
						break;

					case 'FormTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2096.svg';
						break;

					case 'VideoTask':
						imgPath = '/assets/2018/svg/implementationPrograms/Icon%20awesome-video-1.svg';
						break;

					case 'Exam':
						imgPath = '/assets/2018/svg/implementationPrograms/Group%2092.svg';
						break;
				}
			}

			return imgPath;
		}
	}
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.columns-wrap {
	display: block;

	@include desktop {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

.mov-prev-col {
	display: block;
	max-width: 100%;
	height: 200px;

	@include desktop {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
		height: inherit;
	}

	.movie-screen {
		height: 100%;
		position: relative;

		img {
			width: 80px;
			height: 60px;
			position: absolute;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

   
.task.card-content {
	padding: 1.5rem 1.125rem 1.5rem 1.125rem;

	@include widescreen {
		padding: 1.5rem 1.125rem 0.875rem 1.9rem;
	}

	.title-description {
		padding-right: 3rem;

		h2.is-size-2-tablet {
			text-transform: uppercase;
			font-size: 16px !important;
			font-weight: bold;
		}

		span {
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			padding: 10px;		
		}

		span.is-begin {
			color: white;
			background-color: $green-lighter;
		}

		span.is-finish {
			color: $green-lighter;
			background-color: rgba(58, 171, 89, 0.18)
		}
	}

	.right-arrow-small-icon {
		margin: 0 20px 0 0;
	}

	h2 {
		margin: 0.625rem 0 0.625rem;
		text-transform: uppercase;
		font-size: 16px;
	}

	.cat-name {
		text-transform: uppercase;
		margin-bottom: 1.7em;
		font-weight: 600;
	
		svg {
			margin-right: 10px;
			position: relative;
			top: 1px;
		}
	}
	
	.short-desc {
		margin-bottom: 1.5625rem;
	}
	
	.right-arrow-small-icon {
		margin-top: -10px;
	}
}

.card:not(.disabled):hover {
	span.is-begin, span.is-finish {
		color: $green-lighter !important;
		background-color: white !important;
	}
}

.bottom-info {
	@include tablet {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.date-city-info {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		margin-bottom: 0.9375rem;

		@include tablet {
			margin-bottom: 0
		}

		p {
			svg {
				-webkit-transition: color .3s ease;
				-o-transition: color .3s ease;
				transition: color .3s ease;
				color: $green;
				margin-right: 8px;
				vertical-align: middle;
			}
			
			span {
				vertical-align: middle;
			}
		}
		
		p:first-child {
			margin-right: 25px;
		}
	}
}

.category-full {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.5625rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;

	@include tablet {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	
	p:first-child
	{
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;

		@include tablet {
			-webkit-box-ordinal-group: 2;
			-ms-flex-order: 1;
			order: 1;
			-webkit-box-flex: 1;
			-ms-flex-positive: 1;
			flex-grow: 1;
		}
	}

	.alert {
		color: $red;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
		margin-bottom: 0.9375rem;

		@include tablet {
			-webkit-box-ordinal-group: 3;
			-ms-flex-order: 2;
			order: 2;
			margin-bottom: 0;
		}

		svg {
			vertical-align: middle;
			margin-right: 8px;	 
		}

		span {
			display: inline-block;
			vertical-align: middle;
		}
	}

	.available {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;

		@include tablet {
			-webkit-box-ordinal-group: 3;
			-ms-flex-order: 2;
			order: 2;
			margin-bottom: 0;
		}
	}
}

.card {
	height: 100%;
	flex-wrap: wrap;
	overflow: hidden;
	cursor: pointer;
	color: $black;

	&.bottom-image {
		.card-image {
			order: 2;
			align-self: flex-end;

			img {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
		
		.card-content {
			order: 1;
	  
			img {
				border-radius: 0;
				max-height: 40px;
			}
		}
	}
  
	.is-3by1 {
		padding-top: 39.3333%;
	}

	&-image,
	&-content {
		width: 100%;
	
		.media-content {
			& > div {
				padding-right: 70%;
			}
		}
	}
	
	&:not(.disabled):hover,
	&:not(.disabled):focus {
		background: $green-lighter;
		transition: background 0.3s ease;
	
		.card-content {
			h2, p {
				color: $white;
			}

			.right-arrow-small-icon {
				color: $white;
			}
			ul {
				color: $white;
			}

			svg {
				color: $white;
			}

			.alert {
				color: $red;
			}
		}
	}
}
</style>
