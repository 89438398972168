<template>
    <div>
        <a class="button is-info is-uppercase has-text-weight-semibold has-glow-button is-flex" @click="isContactModalActive = true">
            <span class="is-size-7 has-text-white">Formularz kontaktowy</span>
        </a>
        <BaseModalNav v-if="isContactModalActive" @closeModalHandler="isContactModalActive = false;" :hasBackBtn="true"/>
        <b-modal :active.sync="isContactModalActive" :width="720" :canCancel="['escape', 'x', 'outside']" scroll="keep" class="modal-fullscreen">
            <div class="columns is-paddingless is-marginless">
                <div class="column is-full">
                    <HelpContactForm/>
                </div>
            </div>
        </b-modal>       
    </div>
</template>

<script>
import BaseModalNav from "@/components/BaseModalNav.vue";
import HelpContactForm from "@/components/Containers/ViewHelpContact/HelpContactForm.vue";
import ShopWorkerIcon from "@/assets/svg/shop-worker.svg";

export default {
  name: "LoginModal",
  components: {
    BaseModalNav,
    HelpContactForm,
    ShopWorkerIcon
  },
  data() {
    return {
      isContactModalActive: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.button.is-danger {
  padding: rem(8px 60px);
}

.modal {
  .columns {
    & > :first-child {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      svg {
        margin-bottom: rem(30px);
      }
    }
  }
  form {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>