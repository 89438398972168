<template>
  <article class="card">
      <div class="card-content is-paddingless">
          <div class="media">
              <div class="media-content">
                  <div class="columns is-flex" :class="{ 'has-no-file' : !file }">
                      <div class="column is-12-mobile is-6-tablet">
                          <h2 class="is-size-2">{{ title }}</h2>
                          <p class="has-text-primary is-flex">
                              <SmallCalendarIcon/>
                              <time datetime="31-10-2018" class="has-text-primary has-text-left has-text-weight-bold is-size-8">{{ date }}</time>
                          </p>
                          <div class="content" v-html="description"></div>
                      </div>
                      <div class="column is-12-mobile is-3-tablet is-flex" v-if="file">
                          <a class="button is-info is-fullwidth is-pulled-right is-uppercase has-text-weight-semibold has-glow-button" :href="file">
                              <span class="is-size-7-em has-text-white">Pobierz</span>
                          </a>
                      </div>
                      <div class="column is-12-mobile is-3-tablet is-paddingless">
                          <figure class="image is-square" :style="'background-image: url(' + image +  '); background-repeat: no-repeat;'">
                              <img v-lazy="image" :alt="title" :title="title">
                          </figure>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </article>
</template>

<script>
import SmallCalendarIcon from "@/assets/svg/small-calendar.svg";

export default {
  name: "BaseCardDownload",
  components: {
    SmallCalendarIcon
  },
  props: {
    title: {
      type: String
    },
    date: {
      type: String
    },
    image: {
      type: String
    },
    description: {
      type: String
    },
    file: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.card {
  overflow: hidden;
  box-shadow: 0 12px 15px 0 rgba(183, 201, 216, 0.1);
  background-color: $white;
  border: solid 1px $grey-lightest;
  margin-bottom: rem(20px);
  width: 100%;
  figure {
    margin-left: 20px;
    margin-right: -40px;
    background-size: 100% auto;
    max-height: 600px;
    @include tablet {
      height: 100%;
      margin: 0;
    }
    img {
      visibility: hidden;
    }
  }
  .columns {
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: rem(20px);
    padding-bottom: 0;
    @include tablet {
      padding: rem(30px);
    }
    a {
      height: rem(50px);
      align-self: flex-end;
      color: rgba(58, 171, 89, 0.5);
    }
    & > :nth-child(1) {
      margin-left: 0;
      margin-right: 0;
      @include tablet {
        order: 2;
        margin-left: rem(50px);
      }
    }
    & > :nth-child(2) {
      border-top: 1px solid $grey-lightest;
      @include tablet {
        order: 3;
        padding-left: rem(30px);
        border-top: 0;
        border-left: 1px solid $grey-lightest;
      }
    }
    & > :nth-child(3) {
      margin: rem(5px -30px -18px);
      margin-bottom: 0;
      @include tablet {
        order: 1;
        margin: rem(-30px);
        margin-bottom: rem(-30px);
      }
    }
    &.has-no-file {
      & > :nth-child(1) {
        margin-left: 0;
        margin-right: 0;
        @include tablet {
          width: 75%;
          order: 2;
          margin-left: rem(50px);
        }
      }
      & > :nth-child(2) {
        margin: rem(5px -30px -18px);
        margin-bottom: 0;
        @include tablet {
          order: 1;
          margin: rem(-30px);
          margin-bottom: rem(-30px);
        }
      }
    }
  }
}
</style>
