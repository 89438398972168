<template>
  <section class="hero section" :class="nameClass">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1
              class="title is-size-2 is-size-1-tablet has-text-weight-light has-text-left has-text-shadow"
            >{{ title }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FilterIcon from "@/assets/svg/filter.svg";

export default {
  name: "BaseHero",
  components: {
    FilterIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    nameClass: ""
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.hero {
  padding-bottom: rem(40px);
  &.section {
    padding-bottom: 0;
  }
}
.hero-body {
  padding-top: 0;
}

.hero.workshop {
  margin-top: 1rem;

  .container {
    @include tablet {
      width: 729px;
    }
  }
}

// This class adds additional bottom padding to the section.
.user-bottom-padding {
  padding-bottom: 1rem !important;
}
</style>