var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('TheNavBar'),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(
					_vm.$route.name !== 'view-home-page' &&
						_vm.$route.name !== 'Oferta edukacyjna' &&
						_vm.$route.name !== 'Konferencja' && 
                        _vm.$route.name !== 'Dzień programu wdrożeniowego'
				)?_c('TheBreadcrumb'):_vm._e()],1),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('keep-alive',{attrs:{"exclude":[
					'ViewAddEditShop',
					'AddEditShop',
					'ViewUserData',
					'ViewShopsManagement',
                    'ViewImplementationProgram',
                    'ViewImplementationProgramTasks',
					'ViewImplementationProgramExam',
					'ViewImplementationProgramForm',
                    'ViewOnboarding',
                    'ViewOnboardingTasks',
					'ViewOnboardingExam',
					'ViewOnboardingForm',
					'ViewElearning' ]}},[_c('router-view',{key:_vm.$route.path})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }