<template>
	<main>
		<!--<BaseHero >-->
		<div class="live-lecture-form container small">
			<div class="task-form-form column">
				<div class="card">
					<div class="header">{{exam.Title}}</div>

					<form class="form-wrap" novalidate>
						<div class="taskContainer" v-for="task in  exam.ExamQueries" :key="task.Id">
							<p class=" has-text-left" v-html="task.Text"></p>
							<div class="answerContainer">
								<input class="radio" :name="task.Id" required type="radio" :id="task.Id+'first'" :value="task.FirstAnswer" v-model="task.Response">
								<label :for="task.Id+'first'">{{task.FirstAnswer}}</label>
							</div>
							<div class="answerContainer">
								<input class="radio" :name="task.Id" required type="radio" :id="task.Id+'second'" :value="task.SecondAnswer" v-model="task.Response">
								<label :for="task.Id+'second'">{{task.SecondAnswer}}</label>
							</div>
							<div class="answerContainer">
								<input class="radio" :name="task.Id" required type="radio" :id="task.Id+'third'" :value="task.ThirdAnswer" v-model="task.Response">
								<label :for="task.Id+'third'">{{task.ThirdAnswer}}</label>
							</div>
							<span class="errorValidity" aria-live="polite"></span>
							<br />
						</div>
						<div v-if="errorMessage">
							<p class="error">
								{{errorMessage}}
							</p>
							<br />
						</div>

						<div class="center">
							<button type="button"
									class="button is-info is-size-7 is-fullwidth is-uppercase has-text-weight-bold " @click="processForm">
								Wyślij
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<b-modal :active.sync="isExamDialog"
				 :width="960"
				 :canCancel="['escape', 'x', 'outside']"
				 scroll="keep"
				 class="modal-dialog">
			<button type="button" class="modal-close is-large" @click="closeAndRedirect">
			   
			</button>
			<br />

			<div class="modal-dialog--content" v-if="examResult.IsExamPassed">
				<img src="/assets/img/layout/modal/pass.svg" />

				<p class="modal-dialog--result">Twój wynik testu to: {{examResult.PercentDone}}%</p>
				<p class="modal-dialog--result">Próg zaliczenia to: {{examResult.PassPercent}}%</p>
				<p class="modal-dialog--info"> Zaliczyłeś test, gratulacje!</p>
			</div>

			<div class="modal-dialog--content" v-else>
				<img src="/assets/img/layout/modal/failed.svg" />

				<p class="modal-dialog--result">Twój wynik testu to: {{examResult.PercentDone}}%</p>
				<p class="modal-dialog--result">Próg zaliczenia to: {{examResult.PassPercent}}%</p>
				<p class="modal-dialog--info">Przykro nam, nie zaliczyłeś testu.</p>
			</div>

			<button :class="{crbutton : true, success: examResult.IsExamPassed }" type="button" @click="closeAndRedirect">Zamknij</button>
		</b-modal>

	</main>
</template>

<script>
	import BaseHero from "@/components/BaseHero.vue";
	import ViewOnboardingWeek from "@/components/Containers/ViewOnboarding/ViewOnboardingWeek.vue";
	import global from "@/mixins/global.js";

	export default {
		name: "ViewOnboardingExam",

		mixins: [global],

		components: {
			BaseHero,
			ViewOnboardingWeek
		},

		data() {
			return {
				exam: {},
				errorMessage: '',
				isExamDialog: false,
				examResult: {
					IsExamPassed: false,
					PercentDone: 0,
					PassPercent: 100
				},

			};
		},

		methods: {
			loadExam() {
				let self = this;
				self.$http
					.get(`spa/Onboarding/Exam/${self.$route.params.examId}/user/${null}`)
					.then(response => {
						self.exam = response.data;

						self.$nextTick(() => {
							self.initForm();
						});
					})
					.catch((e) => { console.log(e); });
			},

			submitForm() {
				let self = this;
				self.errorMessage = "";

				self.$http.post(`spa/Onboarding/Exam`, self.exam)
					.then((res) => {
						self.examResult = res.data;
						self.isExamDialog = true;
					}).catch((e) => {
						console.log(e);
						self.errorMessage = "Wystąpił błąd";
					});
			},

			closeAndRedirect() {
				let self = this;
				self.isExamDialog = false;
				var url = `/onboardings/${self.$route.params.programId}/day/${self.$route.params.dayId}`;
				self.goToUrlHandler(url);
			},

			processForm() {
				const radios = document.querySelectorAll("input[type='radio']")
				let notValid = false;

				for (let i = 0; i < radios.length; i++) {
					if (!radios[i].validity.valid) {
						notValid = true;
						const taskContainer = $(radios[i]).parent().parent();
						const responseError = taskContainer.find('span.errorValidity');
						this.showError(taskContainer[0], radios[i], responseError);
					}
				}

				if (!notValid) {
					this.submitForm();
				}
			},

			showError(repsonse, elemToValidate, errorField) {
				if (elemToValidate.validity.valueMissing) {
					errorField[0].textContent = 'Uzupełnij pole';
				}

				errorField[0].className = 'errorValidity active';
				repsonse.className = 'taskContainer areaError';
			},

			initForm() {
				const radios = document.querySelectorAll("input[type='radio']")
			  
				for (let i = 0; i < radios.length; i++) {
					radios[i].addEventListener('change', function () {
						const taskContainer = $(radios[i]).parent().parent();
						const responseError = taskContainer.find('span.errorValidity');
						responseError[0].innerHTML = '';
						responseError[0].className = 'errorValidity';
						taskContainer[0].className = 'taskContainer';
					});
				}
			}
		},

		created: function () {
			this.loadExam();
		}
	};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.modal-dialog {
	.modal-close {
		right: 20px !important;
		top: 15px !important;
	}
			
	.modal-close:before {
		height: 4px !important;
		width: 85% !important;
		background-color: $grey-dark !important;
	}

	.modal-close:after {
		width: 4px !important;
		height: 85% !important;
		background-color: $grey-dark !important;
	}

	img {
			margin-bottom: 1rem;
	}

	&--content {
		width: 800px;
		text-align: center;
	}

	&--info {
		font-weight: bold;
		font-size: 1.5rem;
	}

	&--result {
		margin-bottom: 2.5rem !important;
	}

	button.crbutton {
		display: block;
		margin: auto;
		background-color: $grey-dark;
		color: white;
		font-size: 1rem;
		padding: 18px 92px;
		border:none;
		font-weight:lighter;
		text-transform:uppercase;
	}

	button.crbutton.success {
		background-color: $green-lighter;
	}
}

.errorValidity {
	border-radius: 8px 8px 8px 8px;
	-moz-border-radius: 8px 8px 8px 8px;
	-webkit-border-radius: 8px 8px 8px 8px;
	background-color: #DE0C1B;
	color: #FFFFFF;
	font-size: 14px;
	padding: 3px 30px;
	margin: auto;
	display: none;
	width: 160px;
	overflow: overlay;
	margin-bottom: -58px;
	margin-top: 20px;
}

.areaError {
	border: 1px solid #DE0C1B !important;
	margin-bottom: 20px !important;
}

.active {
	display: block;
}

.taskContainer {
	padding: 20px 120px;
	background-color: #FFFFFF;
	margin-bottom: 15px;
	border-radius: 10px 10px 10px 10px;
	-moz-border-radius: 10px 10px 10px 10px;
	-webkit-border-radius: 10px 10px 10px 10px;
		
	p {
		font-size: 16px;
		font-weight: bold;
	}

	> p{
		margin-bottom: 25px;
	} 
}

.answerContainer {
	margin: auto;
	overflow: auto;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	position: relative;

	label {
		font-size: 14px;
		margin-left: 18px;
	}

	label:before {
		content: " ";
		display: inline-block;
		position: relative;
		top: 4px;
		margin: 0 18px 0 0;
		width: 18px;
		height: 18px;
		border-radius: 11px;
		border: 1px solid #4A4A4A80;
		background-color: transparent;
	}

	input[type="radio"]:checked + label:after {
		border-radius: 11px;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 7px;
		left: 21px;
		content: " ";
		display: block;
		background: $green-lighter;
	}

	input[type="radio"] {
		display: none;
	}
}

.header {
	background-color: #3AAB59;
	color: $white;
	min-height: 56px;
	text-align: center;
	font-size: 1.5rem;
	font-weight: bold;
	padding: 1.25rem;
	margin-bottom: 15px;
}


.task-form-form {
	.card {
		margin: 4rem 0;
		border: 1px solid #f2f2f2;
		padding: 2.5rem 1rem 1.85rem;
		background-color: transparent;
		box-shadow: none;

		@include tablet {
			padding: 2.5rem 2.5rem 1.85rem;
		}

		p {
			color: #4a4a4a;
		}

		.error {
			color: red;
			text-align: center;
		}

		.form-wrap {
			textarea {
				width: 100%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				color: #686868;
			}

			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[type=number] {
				-moz-appearance: textfield;
			}

			button[type='submit'], button[type="button"].button {
				color: $white;
				/*margin-top: 5rem;*/
				width: 20%;
				display: block;
				border: 1px solid #c8c8c8;
				border-radius: 4px;
				height: 56px;
				margin-bottom: 1.25rem;
				padding: 1rem;
				font-size: 1rem;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
</style>
