<template>
  <main>
    <OfferEduWorkshops />
  </main>
</template>

<script>
import OfferEduWorkshops from "@/components/Containers/ViewOfferEdu/OfferEduWorkshops.vue";

export default {
  name: "ViewOfferEduWorkshops",
  components: {
    OfferEduWorkshops
  },
};
</script>
