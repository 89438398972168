<template>
  <b-modal
    :active.sync="isActive"
    :width="830"
    :canCancel="['escape', 'x', 'outside']"
    scroll="keep"
    class="modal-dialog"
  >
    <!-- Dialog -->
    <div class="columns">
      <div class="column is-full">
        <p
          class="is-size-2 has-text-centered has-text-weight-light"
        >Czy na pewno chcesz usunąć użytkownika {{ firstName }} {{ lastName }}?</p>
      </div>
    </div>

    <!-- Button -->
    <div class="columns">
      <div class="column is-half">
        <button @click="confirm" class="button is-fullwidth is-danger">TAK</button>
      </div>
      <div class="column is-half">
        <button @click="cancel" class="button is-fullwidth is-success">NIE</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
	props: {
		firstName: {
			type: String,
			default: '',
		},
		lastName: {
			type: String,
			default: '',
		},
	},

	data() {
		return {
			isActive: false,
		};
	},

	methods: {
		open() {
			this.isActive = true;
		},

		close() {
			this.isActive = false;
		},

		confirm() {
			this.$emit('confirm');
			this.close();
		},

		cancel() {
			this.close();
		},
	},
};
</script>

<style lang="scss" scoped>
</style>