<template>
  <section class="homepage-elearnings section" v-if="elearnings">
    <div class="container">
      <h2 class="title is-2 has-text-weight-light has-text-left">Polecane dla Ciebie kursy e-learningowe&emsp;
        <router-link class="is-size-8 has-text-weight-semibold has-text-left has-arrow has-text-primary" to="/kursy-e-learningowe">Zobacz wszystkie</router-link></h2>
      <div class="tile is-ancestor">
        <BaseTile @goToUrlHandler="goToUrlHandler('/kursy-e-learningowe/' + elearnings[0].Id)" :size="'big'" :image="elearnings[0].Image" :category="elearnings[0].CategoryName" :title="elearnings[0].Title" :description="''"/>
        <BaseTile @goToUrlHandler="goToUrlHandler('/kursy-e-learningowe/' + item.Id)" v-for="item in elearnings.slice(1, 3)" :key="item.Id" :image="item.Image" :category="item.CategoryName" :title="item.Title" :description="''" :col="3"/>
      </div>
      <div class="tile is-ancestor">    
        <BaseTile @goToUrlHandler="goToUrlHandler('/kursy-e-learningowe/' + item.Id)" v-for="item in elearnings.slice(3, 7)" :key="item.Id" :image="item.Image" :category="item.CategoryName" :title="item.Title" :description="''" :col="3"/>
      </div>
    </div>
  </section>
</template>

<script>
import BaseTile from "@/components/BaseTile.vue";
import global from "@/mixins/global.js";

export default {
  name: "HomePageElearnings",
  mixins: [global],
  components: {
    BaseTile
  },
  props: [],
  mounted() {},
  data() {
    return {
      elearnings: ""
    };
  },
  created: function() {
    let self = this;
    self.$http
      .get(`spa/homepage/elearnings`)
      .then(response => {
        self.elearnings = response.data;
      })
      .catch(() => {});
  }
};
</script>

<style scoped lang="scss">
@import "sass-rem";
@import "./../../../assets/scss/variables";
@import "~bulma/sass/utilities/mixins";

.homepage-elearnings {
  padding-bottom: rem(60px);
  .title {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    @include tablet {
      margin-left: rem(30px);
    }
  }
  .is-ancestor {
    flex-wrap: wrap;
  }
}
</style>
